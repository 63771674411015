import React from "react";
import { withRouter } from "react-router";

import styles from "../../apps/Profile/Profile.module.css";
import FollowButton from "./FollowButton";
import IconTitle from "./IconTitle";

// import { fetchPeopleYouMayKnow } from "../../redux/actions/peopleyoumayknow";
import { viewMyConnections } from "../../redux/actions/connections";
import { GET_PEOPLE_YOU_MAY_KNOW } from "../../redux/constants/actionTypes";
import { MY_CONNECTIONS } from "../../redux/constants/actionTypes";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

class ConnectionRightFeedComp extends React.Component {
  componentDidMount() {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    // this.props.fetchPeopleYouMayKnow(GET_PEOPLE_YOU_MAY_KNOW, token).then((res) => {
    //   if (res.val == false) {
    //     this.setState({ error: true, message: res.message });
    //   }
    // });
    this.props.viewMyConnections(MY_CONNECTIONS, token).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }

  render() {
    return (
      <div
        id="People You May Know"
        className="people-div"
        style={{
          scrollMargin: 120,
        }}
      >
        {/* <IconTitle title="People You May Know" img="/Image/apps/profile-resume-edit-view/about.svg" /> */}
        {/* <br /> */}

        <div
          // className={styles.desc}
          style={
            {
              // boxShadow: '0px 0px 20px #5555551a',
              // padding: 10,
              // borderRadius: 10,
            }
          }
        >
          <div className={styles.sidebar_stats} >
            {this.props.myConnections.length == 0
              ? 
              <p style={{ margin: "10px",}}>{this.props.t("dashboard.connectPeople")}</p>
              : this.props.myConnections.map((item, idx) => {
                  return (
                    <div key={idx} className={`${styles.col} ${styles.interestIndividual}`}>
                      <div className="hovme" style={{ display: "flex", justifyContent: "space-between", alignItems: 'center',  }}>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <div>
                            {item.profile_pic != null ? (
                              <img
                                src={item.profile_pic}
                                height="45px"
                                width="45px"
                                style={{ borderRadius: 40, margin: "5px 10px 5px 10px" }}
                              />
                            ) : (
                              <img
                                src={"/Image/apps/profile-resume-edit-view/default-user.png"}
                                height="45px"
                                width="45px"
                                style={{ borderRadius: 40, margin: "5px 10px 5px 10px" }}
                              />
                            )}
                          </div>
                          <div className={styles.mar0} style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "left" }}>
                            <p className={`${styles.hovmep} ${styles.section_title}`}>{item.name}</p>
                            <p className={styles.followers}>{item.designation}</p>
                            <p className={styles.followers}>{item.organisation}</p>
                          </div> 
                        </div>
                        
                        {this.props.showIcon && <img  onClick={()=>this.props.history.push(`/profile/${item.id}`)} src="/Image/otw/view profile.svg" style={{width: '2.25rem', height: '2.25rem', aspectRatio: '1/1', cursor: 'pointer', paddingRight: '0.5rem'}} />  }

                        {/* <FollowButton id={item.id} /> */}
                      </div>
                    </div>
                  );
                })}

            <hr className={styles.Hr} />

            <div className={styles.sidebar_stat1} onClick={() => this.props.history.push("/connections")}>
              <p>
                {/* <AddIcon style={{ width: 24, height: 24 }} /> */}
                <span style={{ marginLeft: 5 }}>{this.props.t("dashboard.seeConnections")}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //U can destructure here to get only the required attributes from the state
  return {
    peopleYouMayKnowContent: state.profile?.peopleYouMayKnow || [],
    myConnections: state.profile?.myConnections || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // fetchPeopleYouMayKnow: (action, token) => dispatch(fetchPeopleYouMayKnow(action, token)),
    viewMyConnections: (action, token) => dispatch(viewMyConnections(action, token)),
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectionRightFeedComp)));
