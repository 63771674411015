import React from "react";
import desc from "../../templates/desc";
import styles from "../../../Profile.module.css";
import InfoCard from "../../../../../components/commons/InfoCard";
import { connect } from "react-redux";
import EditProfile from "./EditProfile";
import Modal from "../../../../../components/commons/Modal";
import { withTranslation } from "react-i18next";

class EditDescFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      editcontact: false,
      showMenu: false,

      profileLink: desc.website,
      // phone: desc.phone,
      // address: desc.residencecity + " " + desc.residencestate,
      // email: desc.email,
      // dob: desc.dob,

      // username: this.props?.user?.first_name && this.props?.user?.last_name ? this.props.user.first_name + " " + this.props.user.last_name : "",
      // designation: this.props?.user?.profile_id?.designation ? this.props.user.profile_id.designation:"Director-Corporate Affairs| J kakehasti Services Pvt Ltd.",
      // location: "Kolkata, West Bengal, India",
      phone: "",
      email: "",
      username: "",
      name: "",
      address: "",
      designation: "",
      dob: "",
      location: "",
      organisation: "",
    };
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }
  // changeEdit() {
  //     this.setState({ edit: false })
  // }

  // changeStates(username, designation, location, edit, phone, email, address, dob) {
  //     this.setState({ username: username, designation: designation, location: location, edit: edit, phone: phone, email: email, address: address, dob: dob })
  // }

  // changeEdit(editcontact) {
  //     this.setState({ editcontact: editcontact })
  // }

  componentDidMount() {
    this.setState({
      designation: this.props.designation,
      organisation: this.props.organisation,
      location: this.props.location,
      address: this.props.address,
      connections: this.props.connections,
    });
  }

  render() {
    const {t} = this.props
    return (
      <div
        className={styles.desc}
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          padding: 10,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          paddingBottom: 0,
        }}
      >
        {/* <InfoCard /> */}
        <InfoCard
          mobileView={this.props.mobileView}
          editpage={this.props.editpage}
          designation={this.props.designation}
          location={this.props.location}
          organisation={this.props.organisation}
          connections={this.props.connections}
        />

        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.setState({ edit: true });
            document.body.style.overflow = "hidden";
          }}
        >
          <p style={{ textAlign: "center", fontSize: 12, textTransform: "uppercase", fontWeight: 600, color: "#555555", margin: 23 }}>{t("profile.editDetails")}</p>
        </div>

        {this.state.edit ? (
          <Modal
            card={
              <EditProfile
                // id={this.state.id}
                title={t("profile.editDetails")}
                // username={this.state.username}
                location={this.state.location}
                // designation={this.state.designation}
                // phone={this.state.phone}
                // email={this.state.email}
                // address={this.state.address}
                dob={this.state.dob}
                organisation={this.state.organisation}
                name={this.state.name}
                address={this.state.address}
                designation={this.state.designation}
                changeEdit={this.changeEdit.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state?.profile?.user || "",
    // dob:state?.profile?.about?.dob ||"",
    // organisation:state?.profile?.about?.organisation || "",
    // designation:state?.profile?.about?.designation || "",
    // location:state?.profile?.about?.location || "",
    connections: state?.profile?.about?.connections || "",

    name: state?.profile?.name || "",
    designation: state?.profile?.designation || "",
    address: state?.profile?.address || "",
    dob: state?.profile?.dob || "",
    location: state?.profile?.location || "",
    organisation: state?.profile?.organisation || "",
  };
};

export default withTranslation()(connect(mapStateToProps, null)(EditDescFeed));
