import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Country } from "country-state-city";

import {
  Theme,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";

const SelectCountryDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const options = Country.getAllCountries();

  const colortheme = createTheme({
    palette: {
      primary: { main: "#ED1E24" },
    },
  });

  const menuItemStyles = {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    fontWeight: 400,
    // lineHeight: "24px"
    letterSpacing: "0.024px",
    textTransform: "capitalize",
  };

  const [selectedOption, setSelectedOption] = useState([]);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    props.getCountry(event.target.value);
  };

  console.log("country dropdown", selectedOption)

  return (
    <div>
      <ThemeProvider theme={colortheme}>
        <Select
          MenuProps={{
            style: {
              maxHeight: "30rem",
              // boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.15)",
              borderRadius: "0.625remnp",
            },
          }}
          value={selectedOption}
          // label={props.categoryName}
          onChange={handleChange}
        //   renderValue={(selected) =>
        //     `${props.categoryName}`
        //   }
          defaultValue={"label"}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          // IconComponent={ExpandMoreIcon}
          sx={{
            borderRadius: "25px",
            height: "1.875rem",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "600",
            color: selectedOption?.length > 0 ? "#fff" : "#4D4D4D",
            textTransform: "capitalize",
            backgroundColor: selectedOption?.length > 0 ? "#ED1E24" : "#fff",
            "& .MuiSvgIcon-root": {
              color: selectedOption?.length > 0 ? "#fff" : "#4D4D4D",
              // backgroundColor: selectedOption?.length > 0 ? "#4D4D4D"  : "#FFCFD0",
              // borderRadius: "50px",
              // "@media (max-width:580px)":{
              //   top: "calc(50% - 0.34em)"
              // }
            },
            "@media (max-width:580px)": {
              height: "1.5rem",
              fontSize: "0.875rem",
              fontWeight: "500",
              lineHeight: "1.5",
              width: "100px",
            },
          }}
        >
          <MenuItem sx={menuItemStyles} disabled value="label">
            <em>{props.categoryName}</em>
          </MenuItem>

          {options?.map((item, index) => (
            <MenuItem
              key={item?.isoCode}
              value={item?.isoCode}
            >
              {item?.name}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>
    </div>
  );
};

export default SelectCountryDropdown;
