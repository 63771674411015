import React from "react";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AddIcon from "@material-ui/icons/Add";

import CorouselFeatured from "./CorouselFeatured";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getPosts } from "../../../../../redux/actions/dashboard";
class FeaturedFeed extends React.Component {
  state = {
    edit: false,
    editLink: false,
    editFeatured: false,
    featuredImage: [
      "/Image/apps/profile-resume-edit-view/dummy/maths.png",
      "/Image/apps/profile-resume-edit-view/dummy/featured1.png",
      "/Image/apps/profile-resume-edit-view/dummy/featured4.png",
    ],
  };

  changeState(img) {
    let imgList = [...img, ...this.state.featuredImage];
    this.setState({ featuredImage: imgList });
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  changeEditLink(editLink) {
    this.setState({ editLink: editLink });
  }

  changeEditFeaturedState(img) {
    this.setState({ featuredImage: img });
  }

  changeEditFeatured(editFeatured) {
    this.setState({ editFeatured: editFeatured });
  }

  scrollLeft() {
    const scrollElm = document.getElementById("featured-scroll");
    scrollElm.scrollLeft -= 200;
  }

  scrollRight() {
    const scrollElm = document.getElementById("featured-scroll");
    scrollElm.scrollLeft += 200;
  }

  componentDidMount() {
    this.props.getPosts().then((res) => {
     if (res) {
       //
       return;
     }
     this.setState({ error: true, posts: "" });
   });
 }

  render() {
    const {t} = this.props;
    return (
      <div className={styles.featured_div}>
        <div className={styles.profile_title}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // padding: "0px 20px",
              alignItems: "center",
            }}
          >
            <IconTitle img="/Image/Chalkmate_Featured.svg" title={t("profile.featured")} />
            <div
              style={{
                display: "flex",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: 20 }}>
                <div
                  style={{
                    background: "#E5E5E5 0% 0% no-repeat padding-box",
                    borderRadius: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => this.scrollLeft()}
                >
                  <KeyboardArrowLeftIcon style={{ width: 22, height: 22 }} />
                </div>
                <div
                  onClick={() => this.scrollRight()}
                  style={{ cursor: "pointer", backgroundColor: "#E5E5E5", borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center", marginRight: 15 }}
                >
                  <KeyboardArrowRightIcon style={{ width: 22, height: 22 }} />
                </div>
                <div className={styles.dropdown_featured}>
                  <div className={styles.dropdown_content_featured}>
                    <a
                      onClick={() => {
                        this.setState({ title: "Add Featured Post", edit: true });
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      Post
                    </a>
                    <a
                      onClick={() => {
                        this.setState({ title: "Add Featured Post", editLink: true });
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      Link
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <div id="featured-scroll" className={styles.scroll_div} style={{ display: "flex", padding: 0, maxWidth: 600, overflow: "scroll" }}>
              {this.props.posts.slice(0, 6).map((item, idx) => {
                if (item.image_url != null) {
                  return (
                    <div key={idx} style={{ margin: 5 }}>
                      <div>
                        <img className={styles.card_img_top} src={item.image_url} alt="Card image cap" style={{ height: 128, width: "250px" }} />
                        <div style={{ margin: "10px 0px 10px 0px" }}>
                          <p className={styles.section_title}>{item.title}</p>
                          <p className={styles.featured_desc}>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}

              {/* <CorouselFeatured content={this.state.featuredImage} /> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    posts: state.dashboard.posts,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    getPosts: () => dispatch(getPosts()),
  };
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FeaturedFeed));
