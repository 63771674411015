import React, { useState, useEffect } from "react";
import styles from "./css/AttemptAndPreviewQuizPage.module.css";
import { useTranslation } from "react-i18next";
import AttemptQuizComponent from "./Components/AttemptQuizComponent";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import {
  getIndividualQuiz,
  getIndividualQuizTemplate,
} from "../../redux/actions/enhancedQuiz";
import { CircularProgress } from "@mui/material";

/**
 * In case of (isPreview : true), we'll use this page to display a quiz template,
 * And in case of (isGrading: true), it'll display the questions attempted by the student to the quizOwner for grading,
 * (In case of objective only questions, quizOwner will only view the questions since there is auto-grading for objective questions,
 * but in case of subjective questions, the quizOwner will explicitly grade only subjective questions)
 * 
 * Else, it'll be used to attempt a quiz, either from a class/group or from a course.
 */

const AttemptAndPreviewQuizPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  // const currentQuiz =
  //   useSelector((state) => state?.enhancedQuiz?.individualQuiz) ?? [];

  const storedQuiz = useSelector((state) => state?.enhancedQuiz?.individualQuiz) ?? [];

  const currentQuiz = location?.state?.isGrading ? location?.state?.currentQuiz: storedQuiz;

  const currentQuizTemplate =
    useSelector((state) => state?.enhancedQuiz?.individualQuizTemplate) ?? []; //Only in case of isPreview:true

    const classOrGroupId = location?.state?.classOrGroupId;
    const quizID = location?.state?.quizID;
    const templateID = location?.state?.templateId ?? ""; // Only in case of isPreview:true
    const isPreview = location?.state?.isPreview;
    const isGrading = location?.state?.isGrading;
    const userAnswers = location?.state?.userAnswers || []; // Only in case of isGrading:true
    const userMarks = location?.state?.marks || 0; // Only in case of isGrading:true
    const userSubmissionId = location?.state?.userSubmissionId;
   
    const routeType = location?.state?.routeType || "class";
    const currentClassOrGroupName = useSelector((state) =>
    routeType == "class"
      ? state?.classes?.class?.name
      : state?.groups?.group?.name
  );

  useEffect(() => {
    if (isPreview) {
      dispatch(getIndividualQuizTemplate(templateID, true)).then(() =>
        setIsLoading(false)
      );
    } else if (isGrading) {
      setIsLoading(false);
    } else {
      dispatch(getIndividualQuiz(quizID)).then(() => setIsLoading(false));
    }
  }, [quizID, templateID]);

  if (isLoading) {
    return (
      <div className={styles.loader_style}>
        <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
      </div>
    );
  } else {
    return (
      <div className={styles.main_container}>
        {console.log("currentQuiz on AttemptPage", currentQuiz)}
        {console.log("isGrading", isGrading)}
        <h5
          className={styles.back_btn}
          onClick={() => {
            if(location?.state?.isEnterprise) {
              history.goBack();
            } else if(classOrGroupId){
            history.push({
              pathname:`/${routeType}/${classOrGroupId}`
            })
            } else {
              history.push({
              pathname:`/user`
            })
            }
          }}
        >
          <img src="/Image/assignment/back_arrow.svg" alt="" />
          &nbsp; &nbsp;{currentClassOrGroupName ?? t("courseMonetisation.goBackBtn")}
        </h5>
        <AttemptQuizComponent
          currentQuiz={currentQuiz}
          isPreview={isPreview}
          currentQuizTemplate={currentQuizTemplate} // Only in case of isPreview:true
          classOrGroupId = {classOrGroupId}
          routeType={routeType}
          isGrading={isGrading}
          userAnswers={userAnswers} // Only in case of isGrading: true
          userMarks={userMarks}
          userSubmissionId={userSubmissionId}
          isEnterprise={location?.state?.isEnterprise || false}
        />
      </div>
    );
  }
};

export default AttemptAndPreviewQuizPage;
