import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import styles from "./AnswerTextArea.module.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// props used: isLong (expected value: true or false), if false then it would be a short answer
// placeholder and disabled

const AnswerTextArea = (props) => {
  // const [answerText, setAnswerText] = useState(props.value ?? "");
  const [answerText, setAnswerText] = useState();

  const handleOnChange = (event) => {
    setAnswerText(event.target.value);
    props.getUserText(event.target.value)
  }

  const colorTheme = createTheme({
    palette: {
      primary: { main: "#ED1E24" },
    },
  });

  useEffect(() => {
    setAnswerText(props.value);
  }, [props.questionID, props.value])
  

  return (
    <>
      {props?.isLong ? (
        <ThemeProvider theme={colorTheme}>
          <TextField
            className={styles.text}
            id="standard-textarea"
            placeholder={props?.placeholder}
            variant="standard"
            multiline
            maxRows={14}
            fullWidth
            disabled={props?.disabled}
            value={answerText || ''}
            onChange={handleOnChange}
          />
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={colorTheme}>
          <TextField
            className={styles.text}
            id="standard-basic"
            placeholder={props?.placeholder}
            variant="standard"
            fullWidth
            disabled={props?.disabled}
            value={answerText || ''}
            onChange={handleOnChange}
          />
        </ThemeProvider>
      )}
    </>
  );
};

export default AnswerTextArea;
