import React, { useState, useEffect} from 'react';
import styles from './css/MyCourses.module.css';
import { AiOutlinePlus } from 'react-icons/ai';
import MyCourseCard from '../Components/MyCourseCard';
import PickPublicPrivateCourseModal from '../Modals/PickPublicPrivateCourseModal';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearCourses, getAllCourses } from "../../../redux/actions/courses";
import { CircularProgress } from "@mui/material";
import { thumbnailList } from '../../../redux/constants/commonFunctions';

const MyCourses = () => {

    const { t } = useTranslation();
    // const isFaculty = useSelector((state)=>state?.profile?.user?.user_subtype === "FACULTY");
    // const isFaculty = useSelector((state) => state?.classes?.class?.hasAccess);
    const isFaculty = JSON.parse(localStorage.getItem("userData")).user_category === "TEACHER";
    const courses = useSelector((state)=>state?.courses?.allCourses) || [];
    const enrolledCourses = useSelector((state)=>state?.courses?.enrolledCourses) || [];
    const createdCourses = useSelector((state)=>state?.courses?.createdCourses) || [];
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      if(!isFaculty){
        dispatch(clearCourses());
        dispatch(getAllCourses(null, null, true)).then(()=>setIsLoading(false));
      } else {
        dispatch(clearCourses());
        dispatch(getAllCourses(null,null,null,true)).then(()=>setIsLoading(false));
      }

      return () => {
        dispatch(clearCourses())
        }
    }, []);

    const [showModal, setShowModal] = useState(false);
    const handleAddCourse = () => {
        setShowModal(!showModal);
    }

    const [icon, setIcon] = useState('Image/Icons/dropUp.svg');
    const [hideContainer, setHideContainer] = useState(styles.hideMyCoursesMobile);
    const [loadEnrolledCardsMobile, setLoadEnrolledCardsMobile] = useState(false);

    const showEnrolledCourseContainer = () => {
            setLoadEnrolledCardsMobile(true);
            setIcon('Image/Icons/dropDown.svg');
            setHideContainer(styles.myCoursesMobile);   
        // instead of "created courses", load "enrolled courses"
    }

    const hideEnrolledCourseContainer = () => {
        setLoadEnrolledCardsMobile(false);
        setHideContainer(styles.hideMyCoursesMobile);
        // display "created courses"
    }


    return <div className={styles.myCoursesContainer}>
        {showModal && (
            <PickPublicPrivateCourseModal
                handleClose={handleAddCourse}
            />
        )}
        
        {isFaculty && <div className={styles.myCoursesTop}>
            <div className={styles.myCoursesTopHeading}>{t("courseMonetisation.createdCourses")}</div>
            { enrolledCourses?.length>0 && <img src={icon} onClick={showEnrolledCourseContainer} /> }
        </div>}
        {isFaculty && <div className={hideContainer}>
            <div className={styles.myCoursesMobileHeading}>{t("courseMonetisation.enrolledCourses")}</div>
            <img src='Image/Icons/dropUp.svg' onClick={hideEnrolledCourseContainer} />
        </div>}
        <div className={styles.mobileEnCourses}>
            { !isFaculty && <div className={styles.CourseCardsScrollMobileStudent}>
                { isLoading === true ? ( 
                <div className={styles.loader_style}>
                    <CircularProgress sx={{color: "#ED1E24"}} size={50} />
                </div>
                ) :  <> 
                { enrolledCourses?.length>0 && enrolledCourses?.map((course, ind) => 
                    (<MyCourseCard ind={ind} id={course?.id} showProgress={true} progress={course?.progress_bar} thumbnail={course?.thumbnail ?? thumbnailList[ind % 13]} public={course?.public} name={course?.name} price={course?.price} ownerName={course?.owner?.name} memberCount={course?.memberCount} classes={course?.classes} isEnrolled={true} />)) 
                }    </>
            }
            </div> }
            { loadEnrolledCardsMobile === true ? <div className={styles.CourseCardsScrollMobile}>
                { isLoading === true ? ( 
                <div className={styles.loader_style}>
                    <CircularProgress sx={{color: "#ED1E24"}} size={50} />
                </div>
                ) :  <> 
                { enrolledCourses?.length>0 && enrolledCourses?.map((course, ind) => 
                    (<MyCourseCard ind={ind} id={course?.id} showProgress={true} progress={course?.progress_bar} thumbnail={course?.thumbnail ?? thumbnailList[ind % 13]} public={course?.public} name={course?.name} price={course?.price} ownerName={course?.owner?.name} memberCount={course?.memberCount} classes={course?.classes} isEnrolled={true} />)) 
                }    </>
            }
                </div> : 
                <div className={styles.CourseCardsScrollMobile}>{ isLoading === true ? ( 
                    <div className={styles.loader_style}>
                        <CircularProgress sx={{color: "#ED1E24"}} size={50} />
                    </div>
                ) :   <> 
                    { createdCourses?.length>0 && createdCourses?.map((course, ind) => 
                        (<MyCourseCard ind={ind} id={course?.id} showProgress={false} thumbnail={course?.thumbnail ?? thumbnailList[ind % 13]} public={course?.public} name={course?.name} price={course?.price} ownerName={course?.owner?.name} memberCount={course?.memberCount} classes={course?.classes} isOwner={true} isDraft={course?.is_draft} />)) 
                    } </>
                }
                </div>
            }
        </div>

        {isFaculty && <div className={styles.CreatedCardsBox}>
            <div className={styles.CreateCardContainer} onClick={handleAddCourse}>
                <AiOutlinePlus className={styles.myCoursesPlusSign} />
                <div className={styles.CreateCardText}>{t("courseMonetisation.createNewCourse")}</div>
            </div>
            <div className={styles.CourseCardsHorizontalScroll}>{ isLoading === true ? ( 
                <div className={styles.loader_style}>
                    <CircularProgress sx={{color: "#ED1E24"}} size={50} />
                </div>
            ) :   <> 
                { createdCourses?.length>0 && createdCourses?.map((course, ind) => 
                    (<MyCourseCard key={course?.id} ind={ind} showProgress={false} id={course?.id} thumbnail={course?.thumbnail ?? thumbnailList[ind % 13]} public={course?.public} name={course?.name} price={course?.price} ownerName={course?.owner?.name} memberCount={course?.members_count} classes={course?.classes} isOwner={true} isDraft={course?.is_draft} />)) 
                } </>
            }
            </div>
        </div>}


        <div className={enrolledCourses?.length>0 ? styles.DisplayEnCourseDisplay : styles.hideEnCourse}>
            {isFaculty && <div className={styles.myCoursesSecondHeading}>{t("courseMonetisation.enrolledCourses")}</div>}
            <div className={isFaculty ? styles.CourseCardsVerticalScroll : styles.CourseCardsVerticalScrollMobile}>
                { isLoading === true ? ( 
                <div className={styles.loader_style}>
                    <CircularProgress sx={{color: "#ED1E24"}} size={50} />
                </div>
                ) :  <> 
                { enrolledCourses?.length>0 && enrolledCourses?.map((course, ind) => 
                    (<MyCourseCard key={course?.id} ind={ind} showProgress={true} progress={course?.progress_bar} id={course?.id} thumbnail={course?.thumbnail ?? thumbnailList[ind % 13]} public={course?.public} name={course?.name} price={course?.price} ownerName={course?.owner?.name} memberCount={course?.members_count} classes={course?.classes} isEnrolled={true} />)) 
                }    </>
            }
            </div>
        </div>
    </div>
}

export default MyCourses;