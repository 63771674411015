import React from "react";
import StudentCount from "../../CourseMonetization/Components/StudentCount";
import InstructorName from "../../CourseMonetization/Components/InstructorName";
import styles from "./ExploreCourseCard.module.css";
import { useHistory, useLocation } from "react-router";

const ExploreCourseCard = (props) => {
  const history = useHistory();

  return (
    <div className={styles.main} onClick={()=>history.push(`/course/view/${props.id}`)}>
      <div className={styles.left_container}>
        <img src={props?.thumbnail} />
        <span>
          {props.courseLength < 60
            ? `${Math.floor(props.courseLength)}m`
            : `${Math.floor(props.courseLength / 60)}h ${Math.floor(
                props.courseLength % 60
              )}m`}
        </span>
      </div>
      <div className={styles.right_container}>
        <span className={styles.title}>{props?.name ?? "Blockchain 101"}</span>
        <InstructorName instructor={props?.ownerName ?? "Prof. X"} />
        <StudentCount count={props?.memberCount ?? 0} />
      </div>
    </div>
  );
};

export default ExploreCourseCard;
