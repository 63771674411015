import React, { useState, useEffect, useRef } from "react";
import styles from "./LiveChat.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Pusher from "pusher-js";
import debounce from "lodash.debounce";
import ChatContactListItem from "./Components/ChatContactListItem";
import Avatar from "@mui/material/Avatar";
import {
  getChatList,
  getChatMessages,
  getChatSearchResult,
  sendChatMessage,
  createChannel,
  updateChatList,
} from "../../redux/actions/liveChat";
import ChatBubble from "./Components/ChatBubble";
import moment from "moment";

Pusher.logToConsole =
  process.env.NODE_ENV === "production" ? false : true;

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER,
});

const LiveChat = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSearchResult, setShowSearchResult] = useState(false);

  const chatList = useSelector((state) => state?.liveChat?.chatList);
  const searchResult = useSelector((state) => state?.liveChat?.searchResult);
  let storedList = showSearchResult ? searchResult : chatList;
  
  const currentChatMessages = useSelector(
    (state) => state?.liveChat?.chatMessages
    );
    const loggedInUserID =
    useSelector((state) => state?.profile?.user?.user_id) || "";
    const [message, setMessage] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    // const [contactList, setContactList] = useState();
    const [selectedContact, setSelectedContact] = useState(null);
    
    useEffect(() => {
      dispatch(getChatList());
      
    }, []);
    
    let chatRef = useRef();
    const updateScroll = debounce(() => {
      let element = chatRef.current;
      element.scrollTop = element.scrollHeight;
    },250);
    
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(()=>{   
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  },[])

  useEffect(() => {
    if (!showSearchResult) {

      if (storedList?.length > 0) {
        let channels = storedList?.map((user) =>
          pusher.subscribe(user?.channel)
        );

        channels.forEach((item, index, arr) =>
          item.bind("message", (data) => {
            dispatch(updateChatList({
              message: data?.message,
              username: data?.username,
              channel: item?.name,
              timestamp: new Date().toISOString()
            }))           
          })
        );
      }
    }

    return () => storedList?.forEach((user) =>
      pusher.unsubscribe(user?.channel)
    )
  }, [storedList?.length]);

  useEffect(() => {

    if (selectedContact?.channel) {
      const channel = pusher.subscribe(selectedContact?.channel);
      channel.bind("message", (data) => {
        dispatch(getChatMessages(selectedContact?.channel)).then(() => {
          updateScroll();
        });
      });
    }
  }, [selectedContact]);

  const handleSend = (event) => {
    if (event.keyCode === 13) {
      if (event.target.value?.trim().length > 0) {
        dispatch(
          sendChatMessage(selectedContact?.channel, event.target.value?.trim())
        ).then(() => dispatch(getChatMessages(selectedContact?.channel)));
        setMessage("");
      }
    }
  };

  // data = {channel, name, profilePic}
  const handleContactSelection = (data) => {
    if (showSearchResult === true && data?.userID) {
      dispatch(createChannel(data?.userID)).then(() => {
        dispatch(getChatList());
        setShowSearchResult(false);
      });
    }

    if (data?.channel && data?.channel != selectedContact?.channel) {
      dispatch(getChatMessages(data?.channel));
      setSelectedContact({ ...data });
    }
  };

  const handleSearch = (event) => {
    if (event?.target?.value?.trim().length > 0) {
      setShowSearchResult(true);
      dispatch(getChatSearchResult(event?.target?.value.trim()));
    } else {
      setShowSearchResult(false);
    }
  };

  const debouncedSearch = debounce(handleSearch, 500);

  return (
    <div className={styles.main}>
      <div className={(windowWidth <= 800 && selectedContact?.channel) ? styles.hide_sidebar : styles.sidebar}>
        <div className={styles.sidebar_header}>
          <p>{t("liveChat.chats")}</p>
        </div>
        <div className={styles.searchbar}>
          <img
            src={
              showSearchResult
                ? "/Image/lecturePlan/arrow-left.svg"
                : "/Image/liveChat/chat_search.svg"
            }
            className={showSearchResult ? styles.back_icon : styles.search_icon}
            onClick={showSearchResult ? () => setShowSearchResult(false) : null}
          />
          {/* {showSearchResult ? (
            <p className={styles.search_label}>{t("common.searchResults")}</p>
          ) : (
            )} */}
          <input
            type="text"
            // value={searchQuery || ""}
            className={styles.search_input}
            placeholder={t("common.search")}
            // onKeyDown={handleSearch}
            // onChange={(e) => setSearchQuery(e.target.value)}
            onChange={debouncedSearch}
            // disabled={showSearchResult}
          />
          {showSearchResult && (
            <p className={styles.search_label}>{t("common.searchResults")}</p>
          )}
        </div>

        {/* ------ DM list ------- */}

        <div className={styles.contact_list}>
          {storedList?.length > 0 &&
            storedList?.map((item) => (
              <ChatContactListItem
                key={item?.id}
                name={item?.dm_info?.dm_name || item?.name}
                profilePic={item?.dm_info?.profile_pic || item?.profile_pic}
                timestamp={item?.dm_info?.msg_timestamp}
                lastMessage={item?.dm_info?.last_msg || ""}
                channel={item?.channel}
                recipient={item?.recipient || item?.user_id}
                getSelectedContact={handleContactSelection}
                isSelected={
                  item?.channel === selectedContact?.channel &&
                  !showSearchResult
                }
              />
            ))}
        </div>
      </div>

      {/* -------- Right Panel ---------- */}

      {!selectedContact?.channel ? (
        <div className={styles.right_blank_panel}></div>
      ) : (
        <div className={styles.chat_window}>
          <div className={styles.chat_header}>
            {windowWidth <= 800 && <img src="/Image/arrow_back_black.svg" onClick={()=>setSelectedContact(null)} alt="show_contact_list" className={styles.back_arrow} />}
            <div className={styles.current_contact}>
              <Avatar
                src={selectedContact?.profilePic || ""}
                sx={{ width: 45, height: 45 }}
              />
              <p style={{ marginLeft: "0.8rem" }}>
                {selectedContact?.name || ""}
              </p>
            </div>
          </div>

          <div className={styles.messages} ref={chatRef}>
            {currentChatMessages?.length > 0 &&
              currentChatMessages?.map((item, index) => (
                <>
                  {moment(item?.timestamp).format("ll") !=
                    moment(currentChatMessages[index - 1]?.timestamp).format(
                      "ll"
                    ) && (
                    <p className={styles.date_split}>
                      {moment(item?.timestamp).format("ll")}
                    </p>
                  )}
                  <ChatBubble
                    key={item?.id}
                    messsage={item?.content || ""}
                    isSender={item?.sender === loggedInUserID || false}
                    timestamp={item?.timestamp}
                  />
                </>
              ))}
              {currentChatMessages?.length > 0 && updateScroll()}
              
          </div>

          <div className={styles.textbox}>
            <input
              type="text"
              value={message || ""}
              className={styles.text_input}
              placeholder={t("liveChat.typeMessage")}
              onKeyDown={handleSend}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LiveChat;
