import React, { Component } from "react";
import { ModalWithHeader } from "../../Dashboard/shared/ui/Modal/Modal";
import CourseMiddleContainer from "./CourseMiddleContainer";
import AgendaMiddleContainer from "./AgendaMiddleContainer";
import { FeedsContext } from "../CreateClass/FeedsContext";
// import { FeedsContext } from "../../dashboard/CreateClass/FeedsContext";
import { withTranslation } from "react-i18next";

class CourseModal extends Component {
  state = {
    post: {
      name: "",
      photoUrl: null,
      message: "",
      photoUrl: null,
      link: "",
      video: false,
    },
    file: null,
  };
  onPost = () => {
    let post = {};
    post.name = this.state.post.name;
    post.message = this.state.post.message;
    post.type = this.props.routeType.toUpperCase();
    post.message += this.state.post.link ? "(Link:" + this.state.post.link + ")" : "";
    post.photoUrl = this.state.post.photoUrl;
    post.video = this.state.post.video;
    post.tags = "AGENDA";
    this.context.addPost(post);
    this.setState({
      post: { ...post, message: "", photoUrl: null, video: false, name: "" },
    });
    this.props.closeModal();
  };
  onChange = (e) => {
    this.setState({ message: e.target.value });
  };
  onFileUpload = (e) => {
    this.setState({ file: e.target.value });
  };
  onPicInput = (e) => {
    this.setState({
      post: {
        ...this.state.post,
        photoUrl: e.target.files[0],
      },
    });
  };

  videoInput = (e) => {
    this.setState({
      post: {
        ...this.state.post,
        video: true,
        photoUrl: URL.createObjectURL(e.target.files[0]),
      },
    });
  };
  changeActiveElement = (item) => {
    this.setState({ selectedOption: item });
  };
  updateTitle = (e) => {
    this.setState({ post: { ...this.state.post, name: e.target.value } });
  };
  updateMessage = (e) => {
    this.setState({ post: { ...this.state.post, message: e.target.value } });
  };
  updateLink = (e) => {
    this.setState({ post: { ...this.state.post, link: e.target.value } });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <ModalWithHeader title={this.props.title} show={this.props.show} closeHandler={this.props.closeModal} maxWidth="605px" minWidth="350px">
          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: "0 0 15px 15px",
              overflowY: "hidden",
            }}
          >
            {this.props.title == t("classGroup.addAgenda") ? (
              <AgendaMiddleContainer
                value={4}
                onPost={this.onPost}
                updateTitle={this.updateTitle}
                updateLink={this.updateLink}
                updateMessage={this.updateMessage}
                onPicInput={this.onPicInput}
                videoInput={this.videoInput}
                post={this.state.post}
                routeType = {this.props.routeType}
              />
            ) : (
              <CourseMiddleContainer getActiveClassDetails={this.props.getActiveClassDetails} closeModal={this.props.closeModal} />
            )}
          </div>
        </ModalWithHeader>
      </div>
    );
  }
}
CourseModal.contextType = FeedsContext;
export default withTranslation()(CourseModal)
