import { React, Component } from "react";
import { ModalWithoutHeader } from "../../Dashboard/shared/ui/Modal/Modal";
import styles from "./classcss/DownloadInviteeListModal.module.css";

export default class DownloadInviteeListModal extends Component {
  render() {
    return (
      <div>
        <ModalWithoutHeader maxWidth={this.props.maxWidth} closeHandler={this.props.InviteeModalCloser} show={this.props.show}>
          <div className={styles.outerContainer}>
            <div className={styles.comingSoon}>
              <p>You have added student to your classroom</p>
            </div>
            <span onClick={this.props.InviteeModalCloser} className={styles.close}>
              X
            </span>
            <div className={styles.bottomContainer}>
              <p style={{ font: "normal normal normal 24px/29px Montserrat", textAlign: "left", color: "#000000", paddingBottom: 10 }}>What's next?</p>
              <p style={{ font: "normal normal normal 14px/18px Montserrat", textAlign: "left", color: "#707070", paddingBottom: 30 }}>
                You have created new Chalkmates account for your student. Download a printable handout with their individual login information below.
              </p>

              <div className={styles.bottom_details}>
                <button className={styles.groupinviteButton} onClick={this.InviteeModalOpener}>
                  DOWNLOAD AND CONTINUE
                </button>
                <button className={styles.groupinviteButton} onClick={this.InviteeModalOpener}>
                  CONTINUE WITHOUT DOWNLOADING
                </button>
              </div>

              {/* <Link to="/class" style={{textDecoration:'none',color:'inherit'}}>
                    <div className={styles.bottom_details}>
                        <img src="./Image/DashImage/explore.svg" style={{height:26,width:26,paddingRight:10}}/>
                        <div>
                          <p style={{font:"normal normal normal 18px/22px Montserrat",textAlign:"left",color:"#000000"}}>Explore class</p>
                          <p style={{font:"normal normal normal 14px/18px Montserrat",textAlign:"left",color:"#707070",paddingBottom:30}}>Check out class features before adding your students.</p>
                        </div>
                    </div>
                    </Link> */}
            </div>
            <div className={styles.bottom} style={{ backgroundColor: "#ED1E24", height: 54 }}></div>
          </div>
        </ModalWithoutHeader>
      </div>
    );
  }
}
