import {
  GET_ALL_QUIZ_TEMPLATES,
  GET_INDIVIDUAL_QUIZ_TEMPLATE,
  DELETE_QUIZ_TEMPLATE,
  DELETE_QUIZ,
  GET_ALL_QUIZZES,
  GET_INDIVIDUAL_QUIZ,
  ATTEMPT_QUIZ,
  GET_ALL_QUIZ_SUBMISSIONS
} from "../constants/actionTypes";
import cloneDeep from "lodash.clonedeep";

const defaultState = {
  quizTemplates: [],
  individualQuizTemplate: {},
  quizzes: [],
  individualQuiz: {},
  quizSubmissions: [],
  unassignedStudents: []
};

let data = {};
let index = 0;

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALL_QUIZ_TEMPLATES:
      data = { ...state };
      data.quizTemplates = action.payload;
      return data;
    
    case GET_ALL_QUIZZES:
      data = { ...state };
      data.quizzes = action.payload;
      return data;

    case GET_INDIVIDUAL_QUIZ_TEMPLATE:
      data = { ...state };
      data.individualQuizTemplate = action.payload;
      return data;

    case GET_INDIVIDUAL_QUIZ:
      data = { ...state };
      data.individualQuiz = action.payload;
      return data;

    case DELETE_QUIZ_TEMPLATE:
      data = { ...state };
      index = data.quizTemplates.findIndex((template)=>template.id === action.payload);
      if(index !== -1){
         data.quizTemplates.splice(index, 1);
        }
      return data;

    case DELETE_QUIZ:
      data = { ...state };
      index = data.quizzes.findIndex((quiz)=>quiz.id === action.payload);
      if(index !== -1){
         data.quizzes.splice(index, 1);
        }
      return data;

    case ATTEMPT_QUIZ:
      data = { ...state};
      data.individualQuiz.user_submission.attempt = action.payload.attempt;
      data.individualQuiz.user_submission.marks = action.payload.marks;
      data.individualQuiz.user_submission.submission_status = action.payload.submission_status;
      data.individualQuiz.user_submission.is_returned = action.payload.is_returned;
      data.individualQuiz.user_submission.is_submitted = action.payload.is_submitted;
      return data;

    case GET_ALL_QUIZ_SUBMISSIONS:
      data = cloneDeep(state);
      data.quizSubmissions = action.payload.submissions;
      data.unassignedStudents = action.payload.un_assigned_list;
      data.individualQuiz = action.payload.quiz;
      return data;

    default:
      return { ...state };
  }
};
