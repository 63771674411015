import React, { useState } from "react";
import CourseTitle from "./CourseTitle";
import styles from "./css/CourseFeedPost.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import InstructorName from "./InstructorName";
import StudentCount from "./StudentCount";
import ClassTag from "./ClassTag";
import Modal from "../../../components/commons/Modal";
import CourseActionModal from "../Modals/CourseActionModal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Button from "../../../../src/components/assignmentAndQuiz/Button/Button";
import CreateEditCourseModal from "../Modals/CreateEditCourseModal";
import { useDispatch } from "react-redux";
import {
  deleteCourse,
  updateCourseStatus,
} from "../../../redux/actions/courses";
import { handleErrorMessage } from "../../../redux/actions/classes";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import CourseLength from "./CourseLength";
import TooltipWrapper from "./TooltipWrapper";

const CourseFeedPost = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const courseName = props.title ?? "";
  const courseSubtitle = props.subtitle ?? "";
  const coursePriceValue = props.price ?? 0;
  const courseCoverImage = props.coverImage;
  const courseDescription = props.courseDescription ?? "";
  const courseClasses = props.classes?.map((item) => item.name) || [""];

  const profileId = useSelector((state) => state?.profile?.profileId) || 0;
  // const classes = useSelector((state) => state.classes.classes).filter(
  //   (item) => item.class_owner == profileId
  // );
  const classes = useSelector((state) => state.classes.classes);

  const statusType = props.isActive ? "active" : "inactive";
  const actionsObject = {
    edit: "common.edit",
    delete: "common.delete",
    active: "courseMonetisation.makeItActive",
    inactive: "courseMonetisation.makeItInactive",
  };

  const [showActionModal, setShowActionModal] = useState(false);
  const [courseAction, setCourseAction] = useState("active");
  const { t } = useTranslation();
  const isInstructor = props?.isInstructor;

  const handleActionModal = () => {
    // setShowActionModal(!showActionModal)
    if (courseAction == "delete") {
      if (props?.enrolledStudentsCount == 0)
        dispatch(deleteCourse(props?.id)).then(() => setShowActionModal(false));
      else {
        dispatch(
          handleErrorMessage(t("courseMonetisation.cannotDeleteCourse"))
        );
        setShowActionModal(false);
      }
    } else {
      dispatch(updateCourseStatus(props.id, !props.isActive));
      setShowActionModal(false);
    }
  };

  const handleCancel = () => {
    setShowActionModal(false);
  };

  // --- Dropdown Menu section ---
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCourseAction = (act) => {
    handleClose();
    if (act === "edit") {
      // setShowModal(!showModal)
      history.push({
        pathname: "/course/create",
        state: {
          edit: true,
          courseID: props.id,
          currentCourse: props.currentCourse,
          courseType: props.isPublic ? "Public" : "Private",
          courseTitleType: "Edit",
          classes: classes,
        },
      });
    } else {
      setCourseAction(act);
      setShowActionModal(!showActionModal);
    }
  };

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);
  };

  const handleEnrollOrView = () => {
    history.push({
      pathname: `/course/view/${props?.id}`,
    });
  };

  return (
    <div className={styles.course_card_container}>
      {showActionModal && (
        <Modal
          card={
            <CourseActionModal
              type={courseAction}
              onCancel={handleCancel}
              onConfirm={handleActionModal}
            />
          }
        ></Modal>
      )}
      {showModal && (
        <CreateEditCourseModal
          courseId={props?.id}
          currentMediaFiles={props?.mediaFiles ?? []}
          currentUrls={props?.urls ?? []}
          courseTitleType={t("common.edit")}
          coursePriceValue={coursePriceValue}
          isCourseFreeEdit={coursePriceValue === 0 ? "FREE" : "PAID"}
          courseName={courseName}
          courseSubtitle={courseSubtitle}
          courseDescription={courseDescription}
          classTags={props.classes}
          courseType={props.isPublic ? t("common.Public") : t("common.Private")}
          closeModal={handleModal}
          show={showModal}
          isActive={props?.isActive}
        />
      )}
      <div
        className={styles.image_container}
        onClick={() => {
          handleEnrollOrView();
        }}
      >
        <img src={courseCoverImage} alt="" className={styles.course_image} />
      </div>

      <div className={styles.bottom_container}>
        <div className={styles.course_header}>
          <CourseTitle title={props?.title} />
          {isInstructor && (
            <div className={styles.dropdown}>
              <MoreHorizIcon
                style={{ cursor: "pointer", opacity: "0.6" }}
                onClick={handleClick}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  style: {
                    boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                    borderRadius: "10px",
                    fontFamily: "Montserrat",
                  },
                }}
              >
                {Object.keys(actionsObject).map((element) => {
                  if (element.includes("active")) {
                    if (statusType === element) return null;
                  }
                  return (
                    <MenuItem
                      onClick={() => handleCourseAction(element)}
                      sx={{
                        fontFamily: "Montserrat",
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#000000",
                      }}
                    >
                      {t(actionsObject[element])}
                    </MenuItem>
                  );
                })}
                {/* <MenuItem onClick={()=>handleCourseAction("edit")}>
                    {t("common.edit")}
                  </MenuItem>
                  <MenuItem onClick={()=> handleCourseAction("delete")}>
                    {t("common.delete")}
                  </MenuItem>
                  <MenuItem onClick={()=> handleCourseAction("inactive")}>
                    {t("courseMonetisation.makeItInactive")}
                  </MenuItem> */}
              </Menu>
            </div>
          )}
        </div>


        <div className={styles.text_container}>
          <span className={styles.description}>
            {ReactHtmlParser(courseDescription)}
          </span>
          {isInstructor && (
            <span className={styles.price}>
              {!props?.isFree ? `$${props?.price}` : t("common.free")}
            </span>
          )}
          <InstructorName instructor={props?.instructorName} />
          <StudentCount count={props?.enrolledStudentsCount} />
          <CourseLength
            courseLength={props.courseLength ?? 0}
            lectureCount={props.lectureCount ?? 0}
          />
        </div>

        <div className={styles.tag_label_container}>
        <div className={styles.class_tag_container}>
          {isInstructor ? (
            <ClassTag curbList={true} courseClasses={courseClasses} />
          ) : (
            <div className={styles.enroll_btn_container}>
              {!props.isEnrolled ? (
                <span className={styles.course_price_for_learner}>
                  {props?.price > 0 ? `$${props?.price}` : t("common.free")}
                </span>
              ) : (
                <span>{""}</span>
              )}
              <Button
                type="solid"
                label={
                  props.isEnrolled
                    ? t("common.view")
                    : t("courseMonetisation.enroll")
                }
                clickHandler={handleEnrollOrView}
                disable={!props.isActive && !props.isEnrolled ? true : false}
              />
            </div>
          )}
          
        </div>

        {props.isDraft ? <TooltipWrapper title={t("courseMonetisation.thisDraft")}>
        <p className={styles.draft}>{t("courseMonetisation.draft")}</p>
        </TooltipWrapper> : <p className={styles.published}>{t("courseMonetisation.published")}</p>}


        </div>

        

      </div>
    </div>
  );
};

export default CourseFeedPost;
