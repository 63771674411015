import React, { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./AnswerChoiceDropDown.module.css";
import MenuList from "@mui/material/MenuList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Theme,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useTranslation } from "react-i18next";

// NOTE:  handleChaneg function isn't complete, it needs modification to capture the selected option, to be done at the time of integration.

// Expected Props: option
// options - An array of strings
// minWidth - an integer value for width size (eg: 120)

const menuItemStyles = {
  color: "#000",
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 400,
  lineHeight: "24px" /* 150% */,
  letterSpacing: "0.024px",
  textTransform: "capitalize",
  "@media(max-width:580px)":{
    fontSize: "13px",
    fontWeight: 400,
  }
};

const menuListStyles = {
  borderRadius: "16px"
};

const AnswerChoiceDropDown = (props) => {
  // const [selection, setSelection] = useState("");
  const { t } = useTranslation();

  const handleChange = (event, type) => {
    console.log("targetInside",props.choiceID, event.target.value);
    // setSelection(event.target.value);
    props.getSelection(props.choiceID, event.target.value );
  };

  const colorTheme = createTheme({
    palette: {
      primary: { main: "#ED1E24" },
    },
  });

  return (
    <>
      {console.log('value inside DropDown component', props.value, props.choiceID, props.options )}
      <ThemeProvider theme={colorTheme}>
        <Select
          value={props.value}
          fullWidth
          onChange={handleChange}
          disabled = {props.isDisabled}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          IconComponent={ExpandMoreIcon}
          sx={{
            borderRadius: "25px",
            height: "30px",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "400",
            color: "#4D4D4D",
            textTransform: "capitalize",
            "& .MuiSvgIcon-root": {
              color: "#ED1E24",
              backgroundColor: "#FFCFD0",
              borderRadius: "50px",  
              "@media (max-width:580px)":{
                top: "calc(50% - 0.34em)"
              }        
            },
            "@media (max-width:580px)":{
              height: "40px",
              fontSize: "14px",
              fontWeight: "500",
              lineHeight: "1.5",
              width: "100px"
            }            
          }}
        >
          <MenuItem sx={menuItemStyles} disabled value="">
            <em>{t("common.select")}</em>
          </MenuItem>
          {/* <MenuList sx={{ borderRadius: "50px", }}> */}
            {props.options?.map((item, index) => (
              <MenuItem sx={menuItemStyles} value={item}>
                {item}
              </MenuItem>
            ))}
          {/* </MenuList> */}
        </Select>
      </ThemeProvider>
    </>
  );
};

export default AnswerChoiceDropDown;
