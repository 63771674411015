import React from "react";

import { connect } from "react-redux";

import HandleApiError from "../../components/commons/HandleApiError";
import { updateProfileData } from "../../redux/actions/profile";
import { updateProfilePic } from "../../redux/actions/user_profile";
import { EDIT_PROFILE_DATA, UPDATE_PROFILE_PIC } from "../../redux/constants/actionTypes";

class DpicFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // file: "/Image/apps/profile-resume-edit-view/default-user.png'
      // file: this.props.props.dpfileloc || '/Image/apps/profile-resume-edit-view/default-user.png'
      // prevfile : this.props.updateProfilePic
      profilePicUrl: this.props.profilePicUrl || "/Image/apps/profile-resume-edit-view/default-user.png",
      pictureAsFile: null,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
  }

  // handleImageChange(event) {
  //     this.props.updateProfilePic(UPDATE_PROFILE_PIC,event.target.files[0]).then(res=>{
  //         if (res) {
  //             //
  //             return
  //         }
  //         // this.setState({error: true, url: ""})
  //     })
  //     // this.props.props.updateDp(URL.createObjectURL(event.target.files[0]))
  // }

  handleImageChange(event) {
    // this.setState({
    //     // upload_img: URL.createObjectURL(event.target.files[0]),
    //     pictureAsFile: event.target.files[0]

    // })
    if (event.target.files[0] != null)
      this.props.updateProfilePic( event.target.files[0], "UPDATE_PROFILE_PIC").then((res) => {
        if (res) {
          //
          return;
        }
        // this.setState({error: true, url: ""})
      });
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: 15,
            height: 160,
            width: 160,
            borderRadius: 100,
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 30,
          }}
        >
          {this.props.editpage ? (
            <>
            <div style={{ position: "absolute", width: 160, height: 160, overflow: "hidden", borderRadius: 100 }}>
              <img src={this.props.profilePicUrl ?? "/Image/apps/profile-resume-edit-view/default-user.png"} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </div>
            {this.props.showOTW && <div style={{ position: "absolute", width: 160, height: 160, overflow: "hidden", borderRadius: 100 }}>
              <img src={"/Image/otw/otw_frame_160x160_v6.svg"} style={{ width: "100%", height: "100%", objectFit: "none", top: 0, left: 0, opacity: 1 }} />
            </div>}
            </>
          ) : (
            <>
            <div style={{ position: "absolute", width: 160, height: 160, overflow: "hidden", borderRadius: 100 }}>
              <img src={this.props.profilePicUrlview ?? "/Image/apps/profile-resume-edit-view/default-user.png"} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
            </div>

            {this.props.showOTWForView && <div style={{ position: "absolute", width: 160, height: 160, overflow: "hidden", borderRadius: 100 }}>
              <img src={"/Image/otw/otw_frame_160x160_v6.svg"} style={{ width: "100%", height: "100%", objectFit: "none", top: 0, left: 0, opacity: 1 }} />
            </div>}
            </>
          )}

          {this.props.editpage ? (
            <div
              style={{
                padding: 8,
                backgroundColor: "#fff",
                borderRadius: 100,
                cursor: "pointer",
                height: 15,
                width: 15,
                margin: 5,
                position: "absolute",
                boxShadow: "0 10px 20px rgba(0, 0, 0, 0.2)",
              }}
            >
              <label for="iconimage-dp">
                <div>
                  <img src="/Image/apps/profile-resume-edit-view/camera.svg" alt="" height="15px" width="15px" style={{ cursor: "pointer" }} />
                </div>
              </label>
              <input
                type="file"
                // className="inputfield-dp"
                id="iconimage-dp"
                onChange={this.handleImageChange}
                style={{ display: "none", cursor: "pointer" }}
                accept="image/png, image/jpeg, image/svg"
              />
            </div>
          ) : null}

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", paddingBottom: 20 }}>
            <div
              style={{
                border: "5px solid #fff",
                padding: 8,
                borderRadius: 20,
                backgroundColor: "#00FF80",
                zIndex: 2,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    profilePicUrl: state.profile.userProfilePic || "/Image/apps/profile-resume-edit-view/default-user.png",
    profilePicUrlview: state.profile.userProfilePicView || "/Image/apps/profile-resume-edit-view/default-user.png",
    showOTW: state.profile?.open_to_work?.visibility || false, 
    showOTWForView: state.profile?.open_to_work_view?.visibility || false, 
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateProfileData: (action ,profilePic) => dispatch(updateProfileData(action,profilePic)),
    updateProfilePic: (action, profilePic ) => dispatch(updateProfilePic(action, profilePic)),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DpicFeed);
