import { React, Component } from "react";
import styles from "./classcss/class.module.css";
import ClassSidebar from "./classsidebar";
import { FeedsProvider } from "./FeedsContext";
import ClassRightSidebar from "./classrightsidebar";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import CourseModal from "./CourseModal";
import { ChatProvider } from "../../../components/chat/ChatContext";
import Chat from "../../../components/chat/Chat";
import ImagechangeCover from "../CreateGroup/Imagechangecover";
import styles1 from "./classcss/classfeed.module.css";
import Feed from "./Feed";
import MembersList from "./MembersList";
import Recordings from "./Recordings";
import { connect } from "react-redux";
import { changeActiveSection } from "../../../redux/actions/classes";
import { withTranslation } from "react-i18next";
import AssignmentFeed from "../../AssignmentAndQuiz/AssignmentFeed";
import { getAllAssignments } from "../../../redux/actions/assignment";
// import { getAllQuizzes } from "../../../redux/actions/quiz";
import { getAllCourses, clearCourses} from "../../../redux/actions/courses"
import { CircularProgress } from "@mui/material";
import CourseFeed from "../../CourseMonetization/Components/CourseFeed";
import QuizFeed from "../../AssignmentAndQuiz/QuizFeed";
import QuizTemplateFeed from "../../AssignmentAndQuiz/QuizTemplateFeed";
import { getAllQuizTemplates, getAllQuizzes  } from "../../../redux/actions/enhancedQuiz";

class ClassGroupComponent extends Component {
  constructor() {
    super();

    this.state = {
      showQrModal: false,
      activeSection: "Post",
      showCareerModal: false,
      showShareModal: false,
      showCourseModal: false,
      samplePosts: [],
      isLoading: true,
      isFaculty: false,
    };
  }
  componentDidMount() {
    this.setState({
      samplePosts: this.props.samplePosts,
      members: this.props.members,
      meetings: this.props.meetings,
      isFaculty: this.props.isFaculty
    });
    if(this.props?.activeSectionState == 'Assignments'){
        this.props.changeActiveSection("Assignments");
    } else {
      this.props.changeActiveSection("Post");
    }
    this.props.getAllAssignments(this.props.class_id, window.location?.pathname?.split('/')[1]).then(()=>this.setState({isLoading: false}))
    this.props.getAllQuizzes(this.props.class_id, window.location?.pathname?.split('/')[1]).then(()=>this.setState({isLoading: false}))
    this.props.getAllQuizTemplates().then(()=>this.setState({isLoading: false}))
    this.props.clearCourses()
    .then(()=>this.props.getAllCourses(this.props.class_id, null, null, this.props.isFaculty, true)).then(()=>this.setState({isLoading: false}));
    // this.props.getAllCourses(this.props.class_id, null, null, null, true).then(()=>this.setState({isLoading: false}));
  }
  QRModalCloser = () => {
    this.setState({ showQrModal: false });
  };

  QRModalOpener = () => {
    this.setState({ showQrModal: true });
  };

  CareerModalCloser = () => {
    this.setState({ showCareerModal: false });
  };

  CourseModalOpener = () => {
    this.setState({ showCourseModal: true });
  };
  CourseModalCloser = () => {
    this.setState({ showCourseModal: false });
  };

  CareerModalOpener = () => {
    this.setState({ showCareerModal: true });
  };

  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };
  changeActiveSection = (selectedSection) => {
    if (selectedSection == "Post") {
      this.setState({ activeSection: "Post" });
      this.props.changeActiveSection("Post");
    } else if (selectedSection == "Members") {
      this.setState({ activeSection: "Members" });
      this.props.changeActiveSection("Members");
    } else if (selectedSection == "Recordings") {
      this.setState({ activeSection: "Recordings" });
      this.props.changeActiveSection("Recordings");
    } else if (selectedSection == "Assignments") {
      this.setState({ activeSection: "Assignments" });
      this.props.changeActiveSection("Assignments");
    } else if (selectedSection == "Courses") {
      this.setState({ activeSection: "Courses" });
      this.props.changeActiveSection("Courses");
    }else if (selectedSection == "Quiz") {
      this.setState({ activeSection: "Quiz" });
      this.props.changeActiveSection("Quiz");
    }else if (selectedSection == "quizTemplates") {
      this.setState({ activeSection: "quizTemplates" });
      this.props.changeActiveSection("quizTemplates");
    }
  };

  componentWillUnmount(){
    // this.props.clearCourses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isFaculty !== prevState.isFaculty) {
      console.log('didUpdate called')
      this.setState({isLoading: true});
      this.props.clearCourses()
      .then(()=>this.props.getAllCourses(this.props.class_id, null, null, this.props.isFaculty, true))
      .then(()=>this.setState({isLoading: false}));
        
    }
}

  

  
  
  render() {
    const { t } = this.props;
    return (
      <div className={styles.app} id="classComponent">
        {console.log('inside class', this.props.isFaculty)}
        <FeedsProvider
          routeType={this.props.routeType}
          class_id={this.props.class_id}
          subjectName={this.props.subjectName}
          getActiveClassDetails={this.props.getActiveClassDetails}
        >
          <CourseModal
            title={t("class.addCourse")}
            closeModal={this.CourseModalCloser}
            show={this.state.showCourseModal}
            class_id={this.props.class_id}
            showWelcome={this.WelcomeModalOpener}
          />
          <div className={styles.app_body}>
            <div className="container">
              <div className="row">
                <div className="col-xs-0 col-sm-0 col-md-3">
                  <ClassSidebar
                    // numOfPosts={this.props.numOfPosts}
                    numOfMembers={this.props.members.length}
                    meetings={this.props.meetings}
                    subjectName={this.props.subjectName}
                    myCourses={this.props.myCourses}
                    class_id={this.props.class_id}
                    group_id={this.props.group_id}
                    myClasses={this.props.myClasses}
                    changeActiveSection={this.changeActiveSection}
                    getActiveClassDetails={this.props.getActiveClassDetails}
                    activeSection={this.props.activeSection}
                    routeType={this.props.routeType}
                  />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-9">
                  <div className="row">
                    <div className="col">
                      <div className={styles1.classfeed}>
                        <div className={styles1.cover}>
                          <ImagechangeCover
                            className1={this.props.subjectName}
                            classCode={this.props.subjectCode}
                            routeType={this.props.routeType}
                            class_id={this.props.class_id}
                            getActiveClassDetails={
                              this.props.getActiveClassDetails
                            }
                            imageCover={this.props.imageCover}
                            userName={this.props.userName}
                            grade={this.props.grade}
                            subject={this.props.subject}
                          />
                        </div>
                        <div className={styles1.class_discussion}></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-8">
                      {this.props.activeSection == "Post" ? (
                        <Feed
                          shareModalOpener={this.shareModalOpener}
                          class_id={this.props.class_id}
                          routeType={this.props.routeType}
                          samplePosts={this.state.samplePosts}
                        />
                      ) : this.props.activeSection == "Members" ? (
                        <MembersList
                          members={this.props.members}
                          class_id={this.props.class_id}
                        />
                      ) : // 
                      this.props.activeSection == "Recordings" ? (
                        <Recordings meetings={this.props.meetings} />
                      ) :
                      this.props.activeSection == "Assignments" ? (
                        <>
                        {
                          this.state.isLoading ? <div className={styles1.loader}> <CircularProgress sx={{color: "#ED1E24"}} size={50} /></div> :
                          <AssignmentFeed class_id={this.props.class_id} />
                        }
                        </>
                      ) :
                      this.props.activeSection == "Courses" ? (
                        <>
                          {this.state.isLoading ? <div className={styles1.loader}> <CircularProgress sx={{color: "#ED1E24"}} size={50} /></div> :
                          <CourseFeed classId={this.props.class_id}/>}
                        </>
                      ) :
                      this.props.activeSection == "Quiz" ? (
                        <>
                          {this.state.isLoading ? <div className={styles1.loader}> <CircularProgress sx={{color: "#ED1E24"}} size={50} /></div> :
                          <QuizFeed/>}
                        </>
                      ) :
                      this.props.activeSection == "quizTemplates" ? (
                        <>
                          {this.state.isLoading ? <div className={styles1.loader}> <CircularProgress sx={{color: "#ED1E24"}} size={50} /></div> :
                          <QuizTemplateFeed/>}
                        </>
                      ) :
                      null
                      }
                    </div>
                    <div className="col-xs-0 col-sm-0 col-md-4">
                      <ClassRightSidebar
                        getActiveClassDetails={this.props.getActiveClassDetails}
                        subjectCode={this.props.subjectCode}
                        routeType={this.props.routeType}
                        class_id={this.props.class_id}
                        group_id={this.props.group_id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <MobileBottom /> */}
          <div className="col-xs-0 col-sm-0 col-md-3">
            <ChatProvider>
              <Chat
                toggleChatList={this.props.toggleChatList}
                isChatListOpen={this.props.isChatListOpen}
              />
            </ChatProvider>
          </div>
        </FeedsProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeSection: state?.classes?.classesActiveSection || "Post",
    isFaculty: state?.profile?.profileId == state?.classes?.class?.class_owner,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeActiveSection: (sectionName) =>
      dispatch(changeActiveSection(sectionName)),
    getAllAssignments: (id, type) => dispatch(getAllAssignments(id, type)),
    getAllQuizzes: (id, type) => dispatch(getAllQuizzes(id,type)),
    getAllQuizTemplates: () => dispatch(getAllQuizTemplates()),
    clearCourses: () => dispatch(clearCourses()),
    getAllCourses: (classId,isPublic,isEnrolled,isFaculty,isFeed) => dispatch(getAllCourses(classId,isPublic,isEnrolled,isFaculty,isFeed)),
    
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ClassGroupComponent)
);
