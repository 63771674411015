import React from "react";
import styles from "../../apps/Profile/Profile.module.css";
import SearchGlobalInvitations from "./SearchGlobalInvitations";
import SearchGlobalSuggestions from "./SearchGlobalSuggestions";
import { withRouter } from "react-router";
import { HeaderContext } from "../../apps/Profile/contexts/HeaderProvider";
class SearchGlobal extends React.Component {
  render() {
    return (
      <div className={styles.root_search} id="root-search">
        <div className={`${styles.mains_search}`} >
          <SearchGlobalInvitations {...this.props} />
          <br />
          {/* <SearchGlobalSuggestions {...this.props} /> */}
        </div>
      </div>
    );
  }
}

export default withRouter(SearchGlobal);
