import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./OuterQuestionContainer.module.css";
import QuestionInputField from "./QuestionInputField";
import Switch from "@mui/material/Switch";
import FillInBlankContainer from "./FillInBlankContainer";

const OuterQuestionContainer = (props) => {
  const isMobile = window.innerWidth < 768;
  const { t } = useTranslation();
  const [marks, setMarks] = useState(
    props?.actionType == "edit" ? props?.question?.maxMarks : ''
  );
  const questionType = props.questionType ?? "Multiple choice question";
  const questionTypeIcon =
    props.questionTypeIcon ?? "/Image/AssignmentAndQuizzes/questionType.svg";

  const switchStyle = {
    borderRadius: 2,
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ED1E24",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#ED1E24",
    },
    // "&:hover .MuiSwitch-switchBase": {
    //   color: '#ED1E24'
    // },
  };

  function handleUpIcon() {
    console.log("Up Icon was clicked");
  }

  function handleDownIcon() {
    console.log("Down Icon was clicked");
  }

  const handleQuestionInput = (text) => {
    props.getQuestionInput(props.questionIndex, text);
  };

  return (
    <div className={styles.boxContainer}>
      <div className={styles.partOne}>
        {
          isMobile ? 
          (
            <>
              <div className={styles.rightChild}>
                <img
                  onClick={props?.questionIndex == 0 ? null : props?.moveUp}
                  src="/Image/AssignmentAndQuizzes/upwardsarrow.svg"
                  alt="upwards"
                />
                <img
                  onClick={
                    props?.questionIndex == props?.totalQuestionCount - 1
                      ? null
                      : props?.moveDown
                  }
                  src="/Image/AssignmentAndQuizzes/downwardsarrow.svg"
                  alt="downwards"
                />
                {/* <img src="/Image/AssignmentAndQuizzes/imageIcon.svg" alt="gallery" /> */}
                <img
                  onClick={props.onRemove}
                  src="/Image/AssignmentAndQuizzes/deleteIcon.svg"
                  alt="delete"
                />
                <span className={styles.maxMarksSpan}>
                  {t("assignment.maxMarks")}
                </span>
                <input
                  type="number"
                  value={marks}
                  onChange={(e) => {
                    setMarks(parseInt(e.target.value));
                    props.getMaxMarks(props.questionIndex, e.target.value);
                  }}
                />
                <Switch
                  checked={props.isMandatory}
                  onChange={props.changeMandatoryStatus}
                  sx={switchStyle}
                />
                <span>{t("quizTwo.Required")}</span>
              </div>
              <div className={styles.leftChild}>
              <img src={questionTypeIcon} alt="qt" />
              <span>{t(`enhancedQuiz.${questionType}`)}</span>
              </div>
            </>
          )
          :
          ( 
            <>
              <div className={styles.leftChild}>
                <img src={questionTypeIcon} alt="qt" />
                <span>{t(`enhancedQuiz.${questionType}`)}</span>
              </div>
              <div className={styles.rightChild}>
                <img
                  onClick={props?.questionIndex == 0 ? null : props?.moveUp}
                  src="/Image/AssignmentAndQuizzes/upwardsarrow.svg"
                  alt="upwards"
                />
                <img
                  onClick={
                    props?.questionIndex == props?.totalQuestionCount - 1
                      ? null
                      : props?.moveDown
                  }
                  src="/Image/AssignmentAndQuizzes/downwardsarrow.svg"
                  alt="downwards"
                />
                {/* <img src="/Image/AssignmentAndQuizzes/imageIcon.svg" alt="gallery" /> */}
                <img
                  onClick={props.onRemove}
                  src="/Image/AssignmentAndQuizzes/deleteIcon.svg"
                  alt="delete"
                />
                <span className={styles.maxMarksSpan}>
                  {t("assignment.maxMarks")}
                </span>
                <input
                  type="number"
                  value={marks}
                  onChange={(e) => {
                    setMarks(parseInt(e.target.value));
                    props.getMaxMarks(props.questionIndex, e.target.value);
                  }}
                />
                <Switch
                  checked={props.isMandatory}
                  onChange={props.changeMandatoryStatus}
                  sx={switchStyle}
                />
                <span>{t("quizTwo.Required")}</span>
              </div>
            </>
          )
        }
      </div>
      {questionType == "fillInBlanks" ? (
        <FillInBlankContainer getQuestionInput={handleQuestionInput} actionType={props?.actionType} question={props?.question} />
      ) : (
        <QuestionInputField getQuestionInput={handleQuestionInput} actionType={props?.actionType} question={props?.question} />
      )}
      <div className={styles.choices}>{props.children}</div>
    </div>
  );
};

export default OuterQuestionContainer;
