import React, { useState } from "react";
import styles from "./AddSectionLectureForm.module.css";
import { useTranslation } from "react-i18next";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import ButtonMUI from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CircularProgress } from "@mui/material";
import AddArticleVideoComponent from "./AddArticleVideoComponent";
import ContentTypeButtonMenu from "./ContentTypeButtonMenu";
import {
  createLecture,
  createSection,
  getCourseDetails,
  updateLecture,
  updateSection,
} from "../../../redux/actions/courses";
import { useDispatch } from "react-redux";
import { handleErrorMessage } from "../../../redux/actions/groups";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const AddSectionLectureForm = (props) => {
  const { t } = useTranslation();
  const params = useParams()
  const dispatch = useDispatch();
  const [showAddArticleVideoComponent, setShowAddArticleVideoComponent] =
    useState(false);
  const [contentType, setContentType] = useState(null);
  const [title, setTitle] = useState(props.isEditing ? props.title : "");
  const [lectureData, setLectureData] = useState({
    fileLink: null,
    url: null,
    fileType: null,
    duration: null,
  });
  const [disableAddLectureButton, setDisableAddLectureButton] = useState(true);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const getLectureData = (obj) => {
    console.log("input-lecture-data", obj);
    setLectureData(obj);
  };

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    '@media (max-width: 500px)': {
      height: "20px",
      padding: "5px 11px 6px 11px",
    },
  };

  const disableLectureHandler = (status) => {
    setDisableAddLectureButton(status);
  };

  const handleAddSection = () => {
    const data = { title: title };
    if (data.title == "") {
      dispatch(handleErrorMessage(t("courseMonetisation.addSectionTitle")));
    } else {
      if (props.isEditing) {
        data.sectionID = props.sectionID;
        dispatch(updateSection(data)).then(() => {
          setIsLoadingBtn(false);
          props.onCancel();
        });
      } else {
        data.courseID = props.courseID;
        dispatch(createSection(data)).then(() => {
          setIsLoadingBtn(false);
          props.onCancel();
        });
      }
    }
  };

  const handleAddLecture = () => {
    const data = { ...lectureData };
    data.title = title;

    // while creating send isPublished as false, while editing send whatever came from backend.
    data.isPublished = props.isEditing ? props.lecture?.isPublished : false;
    data.description = props.isEditing
      ? props.lecture?.description
      : "no description";

    if (data.title == "") {
      dispatch(
        handleErrorMessage(t("courseMonetisation.addLectureTitle"))
      ).then(() => setIsLoadingBtn(false));
    } else if (props.isEditing) {
      console.log("EDIT LECTURE FILE DATA", data);
      dispatch(updateLecture(props.lectureID, props.sectionID, data))
      .then(() => {
        setIsLoadingBtn(false);
        props.onSuccessfulUpdate();
      });
    } else {
      dispatch(createLecture(data, props.sectionID)).then(() => {
        setIsLoadingBtn(false);
        dispatch(getCourseDetails(params.id));
        props.onCancel();
      });
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.input_container}>
        <span>
          {props.isSection
            ? t(
                `courseMonetisation.${
                  props.isEditing ? "sectionTitle" : "newSection"
                }`
              )
            : t(
                `courseMonetisation.${
                  props.isEditing ? "lectureTitle" : "newLecture"
                }`
              )}
          :
        </span>
        <input
          type="text"
          placeholder={t("common.enterTitle")}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      {showAddArticleVideoComponent && (
        <AddArticleVideoComponent
          isVideo={contentType == "video"}
          disableAddLectureButton={disableLectureHandler}
          getLectureData={getLectureData}
          contentType={contentType}
          onClose={() => {
            setContentType(null);
            setShowAddArticleVideoComponent(false);
          }}
          isEditing={props.isEditing}
        />
      )}

      <div className={styles.btn_div}>
        <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={props.onCancel}
          sx={styleButtonMUI}
        >
          <span className={styles.btn_label}>{t("common.cancel")}</span>
        </ButtonMUI>

        {!props.isSection && ( // user mui PopOver on this for the content menu
          <ContentTypeButtonMenu
            disabled={!title ? true : contentType ? true : false}
            onClickVideo={() => {
              setContentType("video");
              setShowAddArticleVideoComponent(true);
            }}
            onClickArticle={() => {
              setContentType("pdf");
              setShowAddArticleVideoComponent(true);
            }}
          />
        )}

        {console.log("contentType", contentType)}

        {/* if(lecture) then enable button only if file/url has been added,
        else if (section) enable only if section title has been added.  */}
        <ButtonMUI
          variant="outlined"
          disableRipple
          disabled={
            (props.isSection && !title) ||
            (!props.isSection && disableAddLectureButton)
          }
          onClick={
            props.isSection
              ? () => {
                  setIsLoadingBtn(true);
                  handleAddSection();
                }
              : () => {
                  setIsLoadingBtn(true);
                  handleAddLecture();
                }
          }
          sx={styleButtonMUI}
        >
          {isLoadingBtn ? (
            <CircularProgress color="inherit" size={15} />
          ) : (
            <span className={styles.btn_label}>
              {props.isSection
                ? t(
                    `courseMonetisation.${
                      props.isEditing ? "updateSection" : "addSection"
                    }`
                  )
                : t(
                    `courseMonetisation.${
                      props.isEditing ? "updateLecture" : "addLecture"
                    }`
                  )}
            </span>
          )}
          {/* <CircularProgress color="inherit" size={15} /> */}
        </ButtonMUI>
      </div>
    </div>
  );
};

export default AddSectionLectureForm;
