import React from "react";

import styles from "../../apps/Profile/Profile.module.css";

import SaveButton from "./SaveButton";
import ModalHeader from "./ModalHeader";
import ScrollDivBody from "./ScrollDivBody";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
class ContactModal extends React.Component {
  
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      contacts: [
        {
          icon: "/Image/apps/profile-resume-edit-view/contact-info-chalkMatesLink.svg",
          heading: t("profile.yourProfile"),
          // subheading: "www.yucampus.com"+"/profile/"+this.props.profileId
          // subheading: window.location.protocol+"//" + window.location.host+"/profile/"+this.props.profileId+"/"
          subheading: window.location.href.replace(/edit/i, ""),
        },

        // {
        //     icon: '/Image/apps/profile-resume-edit-view/contact-info-phone.svg',
        //     heading: 'Phone',
        //     subheading: this.props.phone
        // },

        {
          icon: "/Image/apps/profile-resume-edit-view/contact-info-address.svg",
          heading: t("common.address"),
          subheading: this.props.address,
        },

        {
          icon: "/Image/apps/profile-resume-edit-view/contact-info-mail.svg",
          heading: t("common.email"),
          subheading: this.props.email,
        },

        {
          icon: "/Image/apps/profile-resume-edit-view/contact-info-dob.svg",
          heading: t("common.dob"),
          subheading: this.props.dob,
        },
      ],

      contactsView: [
        {
          icon: "/Image/apps/profile-resume-edit-view/contact-info-chalkMatesLink.svg",
          heading: t("profile.yourProfile"),
          // subheading: this.props.profileLink+"/profile/"+this.props.profileIdView
          // link: window.location.href.split('/view'||'/edit'),
          subheading: window.location.href.replace(/view/i, ""),
        },

        // {
        //     icon: '/Image/apps/profile-resume-edit-view/contact-info-phone.svg',
        //     heading: 'Phone',
        //     subheading: this.props.phone
        // },

        {
          icon: "/Image/apps/profile-resume-edit-view/contact-info-address.svg",
          heading: t("common.address"),
          subheading: this.props.addressView,
        },

        {
          icon: "/Image/apps/profile-resume-edit-view/contact-info-mail.svg",
          heading: t("common.email"),
          // subheading: this.props.emailView,
          subheading: this.props.user_email,
          // subheading: this.props?.user?.profile?.user_email
          // ? this.props.user.profile.user_email
          // : "other user",
        },

        {
          icon: "/Image/apps/profile-resume-edit-view/contact-info-dob.svg",
          heading: t("common.dob"),
          subheading: this.props.dobView,
        },
      ],
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    this.props.changeEdit(false);
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    this.props.changeEdit(false);
    document.body.style.overflow = "visible";
  }

  ScrollBody() {
    return (
      
      <div>
        {this.props.editpage
          ? this.state.contacts.map((item, idx) => {
              return (
                <div
                  // id={idx}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img src={item.icon} />
                  </div>
                  <div
                    style={{
                      margin: "8px 10px",
                    }}
                  >
                    <p
                      style={{
                        font: "normal normal 600 14px/16px Montserrat",
                        margin: "3px 0px",
                      }}
                    >
                      {item.heading}
                    </p>

                    {idx == 0 ? (
                      <a
                        href={item.subheading}
                        // <a href={item.subheading.match(new RegExp("^(http|https)://www.", "i")) ? item.subheading : "https://" + item.subheading}
                        target="_blank"
                        style={{}}
                      >
                        {item.subheading}
                      </a>
                    ) : (
                      <p>{item.subheading}</p>
                    )}
                  </div>
                </div>
              );
            })
          : this.state.contactsView.map((item, idx) => {
              return (
                <div
                  // id={idx}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img src={item.icon} />
                  </div>
                  <div
                    style={{
                      margin: "8px 10px",
                    }}
                  >
                    <p
                      style={{
                        font: "normal normal 600 14px/16px Montserrat",
                        margin: "3px 0px",
                      }}
                    >
                      {item.heading}
                    </p>

                    {idx == 0 ? (
                      <a
                        href={item.subheading}
                        // <a href={item.subheading.match(new RegExp("^(http|https)://www.", "i")) ? item.subheading : "https://" + item.subheading}
                        target="_blank"
                        style={{}}
                      >
                        {item.subheading}
                      </a>
                    ) : (
                      <p>{item.subheading}</p>
                    )}
                  </div>
                </div>
              );
            })}
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={t("profile.contactInfo")} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    address: state?.profile?.address || "Address not found",
    dob: state?.profile?.dob || "dd/mm/yyyy",
    location: state?.profile?.location || "",
    email: state?.profile?.user?.email || "user@gmail.com",
    profileId: state?.profile?.profileId || "",

    addressView: state?.profile?.addressView || "Address not found",
    dobView: state?.profile?.dobView || "dd/mm/yyyy",
    locationView: state?.profile?.locationView || "",
    user_email: state?.profile?.user_email || "Email not found",
    profileIdView: state?.profile?.profileIdView || "",
    name: state?.profile?.name || "Vijaya Vajpai",
    // designation:state?.profile?.designation || "Director – Corporate Affairs ",
    address: state?.profile?.address || "Address not found",
    dob: state?.profile?.dob || "dd/mm/yyyy",
    location: state?.profile?.location || "",
    // organisation:state?.profile?.organisation || "I J Kakehashi Services Pvt Ltd",
    // phone:state?.profile?.user?.phone || "9876543210",
    email: state?.profile?.user?.email || "user@gmail.com",
    // user_email:state?.profile?.user_email || "user@gmail.com",
    profile_id: state?.profile?.user?.profile_id || "4",

    addressView: state?.profile?.addressView || "Address not found",
    dobView: state?.profile?.dobView || "dd/mm/yyyy",
    locationView: state?.profile?.locationView || "",
    // phone:state?.profile?.user?.phone || "9876543210",
    // emailView:state?.profile?.emailView || "user@gmail.com",
    user_email: state?.profile?.user_email || "user@gmail.com",
    // profile_id:state?.profile?.user?.profile_id || "4",
  };
};

export default withTranslation()(connect(mapStateToProps, null)(ContactModal));
