import { 
  CREATE_COURSE, 
  EDIT_COURSE, 
  GET_ALL_COURSES, 
  GET_ALL_CREATED_COURSES, 
  CLEAR_ALL_COURSES, 
  DELETE_COURSE,
  UPDATE_COURSE_STATUS,
  GET_COURSE,
  GET_COURSE_FEED,
  GET_PUBLIC_COURSES,
  GET_ENROLLED_COURSES,
  ENROLL_COURSE,
  UPDATE_CURRENT_COURSE_STATUS,
  MARK_AS_COMPLETED,
  MARK_AS_UNCOMPLETED,
  GET_COURSE_PROGRESS,
  CREATE_SECTION,
  UPDATE_SECTION,
  DELETE_SECTION,
  CREATE_LECTURE,
  UPDATE_LECTURE,
  DELETE_LECTURE,
  UPDATE_COURSE,
  DELETE_COURSE_QUIZ
} from "../constants/actionTypes";
import { 
  CREATE_COURSE_MUTATION, 
  EDIT_COURSE_MUTATION, 
  DELETE_COURSE_MUTATION,
  UPDATE_COURSE_STATUS_MUTATION,
  ENROLL_COURSE_MUTATION,  
  UPDATE_COURSE_MUTATION,
  PUBLISH_COURSE_AND_SECTION_MUTATION
} from "../../graphql/mutations/courseMonetisation";
import { MARK_AS_COMPLETED_MUTATION, 
  MARK_AS_UNCOMPLETED_MUTATION,
  CREATE_SECTION_MUTATION,
  UPDATE_SECTION_MUTATION,
  DELETE_SECTION_MUTATION,
  CREATE_LECTURE_MUTATION,
  UPDATE_LECTURE_MUTATION,
  DELETE_LECTURE_MUTATION,
  DELETE_COURSE_SECTION_MUTATION,
  DELETE_COURSE_LECTURE_MUTATION,
  } from "../../graphql/mutations/courseLecturePlan";

import { GET_ALL_COURSES_QUERY,GET_COURSE_PROGRESS_QUERY,GET_COURSE_QUERY} from "../../graphql/queries/courseMonetisation.js";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import { request, gql, GraphQLClient } from 'graphql-request';
import { DELETE_QUIZ_MUTATION } from "../../graphql/mutations/enhancedQuiz";


// import { useTranslation } from 'react-i18next';

// const { t } = useTranslation();

export const createCourse = (data, routeType) => {

    const token = store.getState()?.profile?.user?.token;
    const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
    const client = new GraphQLClient(endpoint, {
        headers: {
            "Authorization": `Token ${token}`,
        },
    });

    const query = CREATE_COURSE_MUTATION;

    // let variables = 
    //     {
    //         "input": {
    //             "class_id": data.classes,
    //             "name": data.courseName,
    //             "thumbnail": data.thumbnail,
    //             "description": data.courseDescription,
    //             "subtitle": data.courseSubtitle,
    //             "free": data.isFree,
    //             "public": data.courseType === "Public" ? true : false,
    //             "currency": "usd",
    //             "price": data.priceValue, 
    //             "active": true,
    //             "is_draft": true,
    //         }
    //     };

    const inputData =  {
      "class_id": data.classes,
      "name": data.courseName,
      "description": data.courseDescription,
      "subtitle": data.courseSubtitle,
      "free": data.isFree,
      "public": data.courseType === "Public" ? true : false,
      "currency": "usd",
      "price": data.priceValue, 
      "active": true,
      "is_draft": true,
  } 
  
  // Only add thumbnail if image-file exists
  if(data.thumbnail instanceof File && typeof data.thumbnail.name === 'string') {
    inputData.thumbnail = data.thumbnail
  }

  let variables = 
  {
      "input": inputData
  };
  
    return async (dispatch) => {
        await client.request(query, variables)
        .then( async (response) => {
          if(routeType == "academics"){
            dispatch(getAllCourses(null,null,null,true))  // getting all created courses
          } else {
           await dispatch({
              type: CREATE_COURSE,
              payload: response?.createcourse
            });
          }        
          
        }).then(()=>{          
          const cc = store.getState()?.courses?.currentCourse;
          console.log('store after dispatch', cc);
          localStorage.setItem("currentCourse", JSON.stringify(cc));
          dispatch(handleSuccessMessage(`${i18n.t("courseMonetisation.courseAdded")}`));
      })
        .catch(async (error) => {
            // console.log(error)
            dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
            return error;
        });
    } 
}

export const editCourse = (data) => {
    const token = store.getState()?.profile?.user?.token;
    const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
    const client = new GraphQLClient(endpoint, {
        headers: {
            "Authorization": `Token ${token}`,
        },
    });

    const query = EDIT_COURSE_MUTATION;
  
    let variables = 
        {
            "input": {
                "id": data.courseId, 
                "class_id": data.classes,
                "thumbnail": data.thumbnail,
                "name": data.courseName,
                "subtitle": data.courseSubtitle,
                "description": data.courseDescription,
                "price": data.priceValue,
                "active": data.active,
                "media": data.mediaFiles,
                "urls": data.urls,
                "deletedUrlIds": data.deleteUrls, 
                "deletedMediaIds": data.deleteMediaFiles, 
            }
        }

    return async (dispatch) => {
        await client.request(query, variables)
        .then((response) => {
        dispatch({
            type: EDIT_COURSE,
            payload: response?.updatecourse
        });
        dispatch(
            handleSuccessMessage(`.${i18n.t("courseMonetisation.courseUpdate")}`) 
        );
        })
        .catch(async (error) => {
            dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
            return error;
        });
    }
};

export const getAllCourses = (classId=undefined, isPublic=undefined, isEnrolled=undefined, isFaculty=false, isFeed=false, isExploring=false, limit=10000000) =>{
    const token = store.getState()?.profile?.user?.token;
    const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
    const endCursor = store.getState()?.courses?.endCursor;

    console.log('inside getAllCourses action', {classId, isPublic, isEnrolled, isFaculty, isFeed, isExploring})

    const client = new GraphQLClient(endpoint, {
      headers: {
        "Authorization": `Token ${token}`,
      },
    });

    const query = GET_ALL_COURSES_QUERY;

    let variables = {
      "input": {
         "class_id" : [classId],
         "public": isPublic,
         "enrolled": isEnrolled,
         "is_draft": false,
         "page": {
          //  "first": isFeed ? 10 : 10000000,  
           "first": limit,  
           //  "after": endCursor == "" ? null : endCursor,
           "after": null,
          
           
         }

       }
   }

   if(classId == undefined  || classId == null){
    delete variables.input.class_id;
   }

   if(isPublic !== true  && isPublic !== false){
    delete variables.input.public;
   }

   if(isEnrolled !== true && isEnrolled !== false){
    delete variables.input.enrolled;
   }

   if(isFaculty){
    delete variables.input.is_draft;
   }
   
    return async (dispatch) => {
      await client.request(query, variables)
      .then((response) => {
        // console.log("THE-RESPONSE",response.allcourses.edges)
        if(isFeed === true){
          dispatch({
            type: GET_COURSE_FEED,
            payload: response?.allcourses, // Payload
          });
        } else if (isExploring === true) {
          dispatch({
            type: GET_ALL_COURSES,
            payload: response?.allcourses?.edges, // Payload
          });
        } else if (isPublic === true) {
          dispatch({
            type: GET_PUBLIC_COURSES,
            payload: response?.allcourses?.edges, // Payload
          });               
        } else if (isFaculty === true) {
          let createdCourses = response?.allcourses?.edges.filter((course)=>
            course?.owner?.id == store.getState()?.profile?.profileId
          )
          
          let enrolledCourses = response?.allcourses?.edges.filter((course)=>
            course?.is_enrolled === true
          )

          dispatch({
            type: GET_ENROLLED_COURSES,
            payload: enrolledCourses, // Payload
          });

          dispatch({
            type: GET_ALL_CREATED_COURSES,
            payload: createdCourses, // Payload
          });
          
          
        } else {
          dispatch({
            type: GET_ENROLLED_COURSES,
            payload: response?.allcourses?.edges, // Payload
          });
        }
        
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });

   }

}

export const clearCourses = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_ALL_COURSES,
    })
  }
}

export const deleteCourse = (courseID) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = DELETE_COURSE_MUTATION;

  let variables = {
    "input": courseID 
  }

  return async (dispatch) => {
    await client.request(query, variables)
      .then((response)=>{
        dispatch({
          type: DELETE_COURSE,
          payload: courseID,
        })        
        dispatch(
          handleSuccessMessage(
            i18n.t("courseMonetisation.courseDeleted")
          )
        )
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });
  }
};

export const updateCourseStatus = (courseID, isActive, isFeed=true) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = UPDATE_COURSE_STATUS_MUTATION;

  let variables = {
    "input":{
      "id": courseID,
      "active": isActive
    }
   }

  return async (dispatch) => {
    await client.request(query, variables)
      .then((response)=>{
        if(isFeed){
          dispatch({
            type: UPDATE_COURSE_STATUS,
            payload: response?.updatecourse, // it has payload.id and pyload.active
          })
        } else {
          dispatch({
            type: UPDATE_CURRENT_COURSE_STATUS,
            payload: response?.updatecourse, // it has payload.id and pyload.active
          })

        }
        
        if(response?.updatecourse?.active == true) {
          dispatch(handleSuccessMessage(i18n.t("courseMonetisation.courseActivated")))
        } else {
          dispatch(handleSuccessMessage(i18n.t("courseMonetisation.courseInactivated")))
        }         
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });
  }
};

export const getCourseDetails = (id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = GET_COURSE_QUERY;

  let variables = {id}

 return async (dispatch) => {
      await client.request(query, variables)
      .then((response) => {
      // console.log("THE-RESPONSE",response.getCoursesById)
      dispatch({
          type: GET_COURSE,
          payload: response.getCoursesById, // Payload
      });
      })
      .catch(async (error) => {
      dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
      return error;
      });

  }
};


export const buyCourse = (success, id, data, free) => {

  data = data ? data : "";
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = ENROLL_COURSE_MUTATION;

  let variables = {};

  if(free){
    variables={
      "input":{
        "success": success,
        "successinput": {
        "razorpay_order_id":data
        } ,
      },
      "id":id
    }
  }

  else if(success){
    variables = {
      "input":{
        "success": success,
        "successinput": data ,
      },
    
      "id":id
    };
  }
  else{
    variables = {
      "input":{
        "success": success,
        "failedinput":data 
      },
    
      "id":id
    };
  }





  return async (dispatch) => {
    await client.request(query, variables)
      .then((response)=>{
        // console.log("response::",response)
        if(response.enrollcourse.message === i18n.t("courseMonetisation.paymentFailure")){
          dispatch(handleErrorMessage(response.enrollcourse.message));
        } 
        else {
          dispatch({
            type: ENROLL_COURSE,
            payload: response // it has status and message
          })        
          dispatch(
            handleSuccessMessage(
              i18n.t("courseMonetisation.coursePaymentDone")
            )
          )
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });
  }
};

export const markAsCompleted = (lectureID, sectionIndex = 0, lectureIndex = 0) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = MARK_AS_COMPLETED_MUTATION;

  let variables = {
    "input": lectureID 
  }

  return async (dispatch) => {
    await client.request(query, variables)
      .then((response)=>{
        dispatch({
          type: MARK_AS_COMPLETED,
          payload: {
            "lecID": lectureID,
            "secIndex": sectionIndex,
            "lecIndex": lectureIndex,
            "progress": response?.completeLecture?.progress_bar,
            "status": response?.completeLecture?.completed
          },
        })        
        dispatch(
          handleSuccessMessage(
            i18n.t("courseMonetisation.markCompleted")
          )
        )
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });
  }

}

export const markAsUncompleted = (lectureID) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = MARK_AS_UNCOMPLETED_MUTATION;

  let variables = {
    "input": lectureID 
  }

  return async (dispatch) => {
    await client.request(query, variables)
      .then((response)=>{
        // dispatch({
        //   type: MARK_AS_UNCOMPLETED,
        //   payload: lectureID,
        // })        
        dispatch(
          handleSuccessMessage(
            i18n.t("courseMonetisation.markUncompleted")
          )
        )
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });
  }

}

// -----------getCourseProgress------------

export const getCourseProgress = (courseID) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = GET_COURSE_PROGRESS_QUERY;

  let variables = {courseID}

  return async (dispatch) => {
    await client.request(query, variables)
      .then((response)=>{
        dispatch({
          type: GET_COURSE_PROGRESS,
          payload: response.getCoursesById?.progress_bar
        })        
        dispatch(
          handleSuccessMessage(
            i18n.t("courseMonetisation.markCompleted")
          )
        )
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });
  }

}

// ----------------------------------

export const createSection = (data) => {

  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
      headers: {
          "Authorization": `Token ${token}`,
      },
  });

  const query = CREATE_SECTION_MUTATION;

  let variables = 
      {
        "input" : {
          "course": data.courseID,
          "title": data.title,
        }
      };

  return async (dispatch) => {
      await client.request(query, variables)
      .then((response) => {
        dispatch({
          type: CREATE_SECTION,
          payload: response?.createSection
        });       
      dispatch(handleSuccessMessage(`${i18n.t("courseMonetisation.sectionAdded")}`));        
      
    })
      .catch(async (error) => {
          // console.log(error)
          dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
          return error;
      });
  } 
}


export const createLecture = (data, sectionID) => {

  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
      headers: {
          "Authorization": `Token ${token}`,
      },
  });

  const query = CREATE_LECTURE_MUTATION;

  let variables={
    "input":data,
    "id":sectionID,
  }

  return async (dispatch) => {
      await client.request(query, variables)
      .then((response) => {
        dispatch({
          type: CREATE_LECTURE,
          // payload: response?.createLecture
          payload: {
            lectureData: response?.createLecture,
            sectionID: sectionID
          }
        });       
      dispatch(handleSuccessMessage(`${i18n.t("courseMonetisation.lectureAdded")}`));        
      
    })
      .catch(async (error) => {
          // console.log(error)
          dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
          return error;
      });
  } 
}

export const publishCourse = (courseID) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = PUBLISH_COURSE_AND_SECTION_MUTATION;

  let variables = {
    "input":courseID
   }

  return async (dispatch) => {
    await client.request(query, variables)
      .then((response)=>{        
        if(response?.pubCourseandSection?.is_draft === false) {
          dispatch(handleSuccessMessage(i18n.t("courseMonetisation.coursePublished")))
        } else if (response?.pubCourseandSection?.is_draft === true) {
          dispatch(handleSuccessMessage(i18n.t("courseMonetisation.courseSaved")))
        }         
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });
  }
};

export const deleteSection = (sectionID) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = DELETE_COURSE_SECTION_MUTATION;

  let variables = {
    "input": [sectionID] 
  }

  return async (dispatch) => {
    await client.request(query, variables)
      .then((response)=>{
        dispatch({
          type: DELETE_SECTION,
          payload: sectionID,
        })        
        dispatch(
          handleSuccessMessage(
            i18n.t("courseMonetisation.sectionDeleted")
          )
        )
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });
  }
};

export const deleteLecture = (sectionID, lectureID) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      "Authorization": `Token ${token}`,
    },
  });

  const query = DELETE_COURSE_LECTURE_MUTATION;

  let variables = {
    "input": [lectureID] 
  }

  return async (dispatch) => {
    await client.request(query, variables)
      .then((response)=>{
        dispatch({
          type: DELETE_LECTURE,
          payload: {"sectionID": sectionID, "lectureID": lectureID },
        })        
        dispatch(
          handleSuccessMessage(
            i18n.t("courseMonetisation.lectureDeleted")
          )
        )
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
        return error;
      });
  }
};

//------------------ deleteCourseQuiz ----------------

export const deleteCourseQuiz = (sectionID, quizID) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = DELETE_QUIZ_MUTATION;

  let variables = {
    input: quizID,
  };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        dispatch({
          type: DELETE_COURSE_QUIZ,
          payload: {"sectionID": sectionID, "quizID": quizID },
        });
        dispatch(
          handleSuccessMessage(i18n.t("quizSuccessMessages.quizDeletedSuccessfully"))
        );
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

export const updateCourse = (data, status) => {

  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
      headers: {
          "Authorization": `Token ${token}`,
      },
  });

  const query = EDIT_COURSE_MUTATION;

  const inputData = {
    "id": data.id, 
    "class_id": data.classes,
    "name": data.courseName,
    "description": data.courseDescription,
    "subtitle": data.courseSubtitle,
    "price": data.priceValue, 
    // "active": data.active,
    "is_draft": status,
  }

  // Only add thumbnail if image-file exists
    if(data.thumbnail instanceof File && typeof data.thumbnail.name === 'string') {
      inputData.thumbnail = data.thumbnail
    }

  let variables = 
      {
          "input": inputData
      };

  return async (dispatch) => {
      await client.request(query, variables)
      .then((response)=>{
        localStorage.setItem("currentCourse", JSON.stringify(response?.updatecourse));
        dispatch(handleSuccessMessage(`${i18n.t("courseMonetisation.courseUpdate")}`));
    })
      .catch(async (error) => {
          // console.log(error)
          dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
          return error;
      });
  } 
}

export const updateSection = (data) => {

  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
      headers: {
          "Authorization": `Token ${token}`,
      },
  });

  const query = UPDATE_SECTION_MUTATION;

  let variables = 
      {
        "input" : {
          "title": data.title
        },
        "id": data.sectionID,
      };

  return async (dispatch) => {
      await client.request(query, variables)
      .then((response) => {
        dispatch({
          type: UPDATE_SECTION,
          payload: {
            "sectionID": data.sectionID, 
            "sectionData": response?.updateSection
          }
        });       
      dispatch(handleSuccessMessage(`${i18n.t("courseMonetisation.sectionUpdated")}`));        
      
    })
      .catch(async (error) => {
          // console.log(error)
          dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
          return error;
      });
  } 
}

export const updateLecture = (lectureID, sectionID, data) => {

  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
      headers: {
          "Authorization": `Token ${token}`,
      },
  });

  const query = UPDATE_LECTURE_MUTATION;

  let variables = 
      {
        "id": lectureID,
        "input" : data,
      };

  return async (dispatch) => {
      await client.request(query, variables)
      .then((response) => {
        dispatch({
          type: UPDATE_LECTURE,
          payload: {
            "lectureID": lectureID, 
            "sectionID": sectionID,
            "lectureData": response?.updateLecture
          }
        });       
      dispatch(handleSuccessMessage(`${i18n.t("courseMonetisation.lectureUpdated")}`));        
      
    })
      .catch(async (error) => {
          // console.log(error)
          dispatch(handleErrorMessage(errorMessageFromResponse(error?.response?.errors[0]?.formatted_message)));
          return error;
      });
  } 
}

