import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styles from "./css/MiddleContain.module.css";

function MiddleContain(props) {
  const [groupName, setGroupName] = useState(props.actionType =="EDIT" ? props.editData?.name : "");
  const [groupDescription, setGroupDescription] = useState(props.actionType =="EDIT" ? props.editData?.description : "");
  const [groupGrade, setGroupGrade] = useState(props.actionType =="EDIT" ? props.editData?.grade : "");
  const [groupSubject, setGroupSubject] = useState(props.actionType =="EDIT" ? props.editData?.subject : "");

  const {t} = useTranslation()
  
  return (
    <div className={styles.classModal}>
       {
          props.actionType =="EDIT" || props.actionType =="ADD" 
          ?  
          <form>
          <h3 className={styles.inputLabel} for="classname">
          <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/title.svg"/>{t("modals.nameGroup")}*</div>
          </h3>
          <input
            style={{marginBottom: "10px"}}
            type="text"
            className={styles.inputField}
            placeholder={t("modals.nameClassPlaceholder")}
            value={groupName}
            onChange={(e) => {
              setGroupName(e.target.value);
              props.onGroupNameChange(e);
            }}
          />
          <h3 for="description" className={styles.inputLabel}>
          <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/description.svg"/>{t("modals.description")}</div>
          </h3>
{/*           <input
            style={{marginBottom: "10px"}}
            type="text"
            className={styles.inputField1}
            placeholder="Ex: Describe your group"
            value={groupDescription}
            onChange={(e) => {
              setGroupDescription(e.target.value);
              props.onGroupDescriptionChange(e);
            }}
          /> */}
          <textarea
            className={styles.inputField1}
            placeholder={t("modals.describeGroupPlaceholder")}
            onChange={(e) => {
              setGroupDescription(e.target.value);
              props.onGroupDescriptionChange(e);
            }}
          >{groupDescription}</textarea>
          <h3 for="grade" className={styles.inputLabel}>
          <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/grade.svg"/>{t("modals.selectGrade")}*</div>
          </h3>
          <div className={styles.drop}>
            {/* <img src = "./Image/dropArrow.svg"></img> */}
            <input
              style={{marginBottom: "10px"}}
              type="text"
              className={styles.inputField}
              placeholder={t("modals.gradePlaceholder")}
              value={groupGrade}
              onChange={(e) => {
                setGroupGrade(e.target.value);
                props.onGroupGradeChange(e);
              }}
            />
          </div>
          <h3 for="subject" className={styles.inputLabel}>
          <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/subject.svg"/>{t("modals.selectSubject")}*</div>
          </h3>
          <div className={styles.drop}>
            {/* <img src = "./Image/dropArrow.svg"></img> */}
            <input
              style={{marginBottom: "10px"}}
              type="text"
              className={styles.inputField}
              placeholder={t("modals.classPlaceholder")}
              value={groupSubject}
              onChange={(e) => {
                setGroupSubject(e.target.value);
                props.onGroupSubjectChange(e);
              }}
            />
          </div>
          {/* <Link to="/group" style={{ textDecoration: "none", color: "inherit" }}> */}
            <button
              id="submitForm"
              className={styles.saveDetailsButton}
              onClick={() => {
                props.actionType == "EDIT" 
                ?
                 props.onGroupEdit()
                : 
                // e.preventDefault();
                props.onGroupAdd();
              }}
            >
              {/* Create Group */}
              {props.actionType == "EDIT" ? t("group.editGroup") : t("modals.createGroupButton") }
            </button>
          {/* </Link> */}
        </form> 
        :
         <form>
         <h3 className={styles.inputLabel}>
         {t("group.sureToDeleteGroup")}
         </h3>
         <button
           onClick={() => {
             props.onGroupDelete()
           }}
           id="submitForm"
           className={styles.saveDetailsButton}
         >
           {t("group.deleteGroup")}
         </button>
       </form>

        }
 
    </div>
  );
}

export default MiddleContain;
