import React, { Component } from "react";
import SuggestionCard from "./SuggestionCard";
import styles from "./CSSFiles/suggestions.module.css";
import { createRef } from "react";
import { withTranslation } from 'react-i18next';

class Suggestions extends Component {
  ref = createRef();
  scrollLeft = () => {
    // alert("Go left");
    this.ref.current.scrollLeft -= 235;
  };
  scrollRight = () => {
    // alert("Go right");
    this.ref.current.scrollLeft += 235;
  };
  render() {
    return (
      <div>
        <div className={styles.settingsContainer}>
          {/* <img src={Settings} style={{position:'absolute', right:0}}/> */}
          <img src="./Image/DashboardImages/DashCommonImages/chevron-left.svg" onClick={this.scrollLeft} style={{ position: "absolute", right: "38px", cursor: "pointer" }} />
          <img src="./Image/DashboardImages/DashCommonImages/chevron-right.svg" onClick={this.scrollRight} style={{ position: "absolute", right: 0, cursor: "pointer" }} />
        </div>
        <div ref={this.ref} className={styles.container}>
          <SuggestionCard
            Background="./Image/DashImage/sideScroll1.png"
            Pic="./Image/DashboardImages/DashCommonImages/sampleProfilePic1.png"
            Topic={this.props.t("course.waldorfHighSchool")}
            Tag="https://www.udemy.com/course/waldorf-high-school-main-lesson-the-word/"
            Following="1,024"
          />

          <SuggestionCard
            Background="./Image/DashImage/sideScroll2.png"
            Pic="./Image/DashboardImages/DashCommonImages/sampleProfilePic2.png"
            Topic={this.props.t("course.spaceFlight")}
            Tag="https://www.udemy.com/course/higher-school-certificate-physics-space/"
            Following="9,560"
          />

          <SuggestionCard
            Background="./Image/DashImage/sideScroll3.png"
            Pic="./Image/DashboardImages/DashCommonImages/sampleProfilePic3.png"
            Topic={this.props.t("course.elementsGrammar")}
            Tag="https://www.udemy.com/course/the-elements-of-english-grammar/"
            Following="4,519"
          />

          <SuggestionCard
            Background="./Image/DashImage/sideScroll4.png"
            Pic="./Image/DashboardImages/DashCommonImages/sampleProfilePic3.png"
            Topic={this.props.t("course.gamedevUnity")}
            Tag="https://www.udemy.com/course/intro-to-game-development-using-unity-part-i/"
            Following="5,589"
          />

          {/* <SuggestionCard 
                        Background="./Image/DashImage/sideScroll5.png"
                        Pic="./Image/DashboardImages/DashCommonImages/sampleProfilePic3.png"
                        Topic="Physics 1 (Algebra Based)
                        Tag="https://www.udemy.com/course/physics-1-algebra-based/"
                    /> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Suggestions)