import { React, Component } from "react";
import styles from "./CSSFiles/Feed.module.css";
import MessageSender from "./MessageSender";
import Post from "./Post";
import { connect } from "react-redux";
import GetStarted from "./GetStarted";
import Suggestions from "./Suggestions";
import { FeedsContext } from "./FeedsContext";
import { CircularProgress } from "@mui/material";
import BoostedProfile from "./BoostedProfile";

class Feed extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    if (
      (!this.context.state.loading && window.innerHeight + window.scrollY) >= (document.body.offsetHeight)-20) {
      this.context.onPaginatedSearch();
    }
  }


  render() {
    return (
      <div className={styles.feed}>
        <MessageSender isNotRequired={true} />
        <GetStarted />
        
        {this?.context?.state?.posts.map((post, index) => 
          index == 0 ? (
            <>
              { post?.post_classification === "POST" ?
                <Post 
                name={post?.post_owner_profile?.name}
                follower={post?.post_owner_profile?.connections}
                timestamp={post.date_and_time}
                message={post?.description || post?.message}
                // tag = {post.tag}
                profileImage={post?.post_owner_profile?.profile_pic || "./Image/DashImage/profile.png"}
                photoUrl={post?.image_url || post?.photoUrl}
                video={post?.video_url || post?.video}
                likes={post.likes}
                file_url={post.file_url}
                likeDetails={post.likedPost}
                shareLink={post.share_link}
                tags={post.tags}
                postId={post.id}
                comments={post.comments}
                shareModalOpener={() => this.props.shareModalOpener(post.share_link)} 
                menu={post?.post_owner_profile?.id == this.props?.userId ? true : false }
                postData={post}
                source="DASHBOARD"
                language_code={post?.language_code}
                postLanguage={post?.language_code}
                postProfileId={post?.post_owner_profile?.id}
                boosted={post?.boosted}
                isNSFW={post?.nsfw}
              /> :
              <BoostedProfile
                name={post?.post_owner_profile?.name}
                postProfileId={post?.post_owner_profile?.id}
                follower={post?.post_owner_profile?.connections}
                timestamp={post.date_and_time}
                message={post?.description || post?.message}
                // tag = {post.tag}
                profileImage={post?.post_owner_profile?.profile_pic || "./Image/DashImage/profile.png"}
                photoUrl={post?.image_url || post?.photoUrl}
                video={post?.video_url || post?.video}
                likes={post.likes}
                file_url={post.file_url}
                likeDetails={post.likedPost}
                shareLink={post.share_link}
                tags={post.tags}
                postId={post.id}
                comments={post.comments}
                shareModalOpener={() => this.props.shareModalOpener(post.share_link)} 
                menu={post?.post_owner_profile?.id == this.props?.userId ? true : false }
                postData={post}
                source="DASHBOARD"
                language_code={post?.language_code}
                postLanguage={post?.language_code}  
                designation={post?.profile_post?.designation}
                experience={post?.profile_post?.experience}  
                location={post?.profile_post?.location}        
              />
              
              }
              <Suggestions />
            </>
          ) : (
            <>
              { post?.post_classification === "POST" ?
                <Post 
                name={post?.post_owner_profile?.name}
                follower={post?.post_owner_profile?.connections}
                timestamp={post.date_and_time}
                message={post?.description || post?.message}
                // tag = {post.tag}
                profileImage={post?.post_owner_profile?.profile_pic || "./Image/DashImage/profile.png"}
                photoUrl={post?.image_url || post?.photoUrl}
                video={post?.video_url || post?.video}
                likes={post.likes}
                file_url={post.file_url}
                likeDetails={post.likedPost}
                shareLink={post.share_link}
                tags={post.tags}
                postId={post.id}
                comments={post.comments}
                shareModalOpener={() => this.props.shareModalOpener(post.share_link)} 
                menu={post?.post_owner_profile?.id == this.props?.userId ? true : false }
                postData={post}
                source="DASHBOARD"
                language_code={post?.language_code}
                postLanguage={post?.language_code}
                postProfileId={post?.post_owner_profile?.id}
                boosted={post?.boosted}
                isNSFW={post?.nsfw}                
              /> :
              <BoostedProfile
                name={post?.post_owner_profile?.name}
                postProfileId={post?.post_owner_profile?.id}
                follower={post?.post_owner_profile?.connections}
                timestamp={post.date_and_time}
                message={post?.description || post?.message}
                // tag = {post.tag}
                profileImage={post?.post_owner_profile?.profile_pic || "./Image/DashImage/profile.png"}
                photoUrl={post?.image_url || post?.photoUrl}
                video={post?.video_url || post?.video}
                likes={post.likes}
                file_url={post.file_url}
                likeDetails={post.likedPost}
                shareLink={post.share_link}
                tags={post.tags}
                postId={post.id}
                comments={post.comments}
                shareModalOpener={() => this.props.shareModalOpener(post.share_link)} 
                menu={post?.post_owner_profile?.id == this.props?.userId ? true : false }
                postData={post}
                source="DASHBOARD"
                language_code={post?.language_code}
                postLanguage={post?.language_code}         
                designation={post?.profile_post?.designation}
                experience={post?.profile_post?.experience}
                location={post?.profile_post?.location}
              />              
              }
            </>
          )
        )}
        {this?.context?.state?.loading ? (<div  style={{textAlign: "center"}}>

        <CircularProgress size={30}/>
        </div>) : null}
        {this?.context?.state?.nextLink === null && !this?.context?.state?.firstLoad ? (<div  style={{textAlign: "center"}}>

      <h4>No More posts to show</h4>
</div>) : null}
      </div>
    );
  }
}

Feed.contextType = FeedsContext;

const mapStateToProps = (state) => {
  return {
    userId: state?.profile?.profileId
  };
};
export default connect(mapStateToProps, null)(Feed);