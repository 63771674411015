import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconTitle from "../../components/commons/IconTitle";
import styles from "./css/ViewAssignmentPage.module.css";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import AssignmentListIndividual from "../../components/assignmentAndQuiz/AssignmentListIndividual";
import DropDown from "../../components/assignmentAndQuiz/DropDown/DropDown";
import AttachmentContainer from "../../components/commons/assignmentAndQuiz/AttachmentContainer";
import {
  getIndividualAssignment,
  deleteAssignment,
} from "../../redux/actions/assignment";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DueDate from "../../components/assignmentAndQuiz/DueDate";
import { deleteQuiz, getIndividualQuiz } from "../../redux/actions/quiz";
import ErrorFourZeroFour from "../../components/ErrorFourZeroFour";
import { CircularProgress } from "@mui/material";
import ReactHtmlParser from 'react-html-parser'; 

const ViewEnterpriseAssignmentPage = (props) => {
  // PropsUsed: assignmentClass, assignment_id, assignmentType
  // props.isOwner and props.viaMail coming from OpenAsgFromMail component, when the owner open the assignment or quiz from email.

  //--- useSelector Section
  const assignment = useSelector((state) =>
    state?.assignment?.assignment || {}
  );

  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Pass assignment id via the ViewAssignment Modal from the class feed
    const assignmentId = window.location.pathname.split("/")[4];
    
    if (assignmentId) {
      dispatch(getIndividualAssignment(assignmentId)).then(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const current_assignment = assignment;

  
  //----------------------------------------

  return (
    <>
      {isLoading ? (
        <div className={styles.loader_style}>
          <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
        </div>
      ) : (
        <div className={styles.main_container}>
          {/* <h5 className={styles.back_btn} onClick={() => {history.goBack(), history.push({state: {activeSection:'Assignments'}})}}> */}
          <p
            className={styles.back_btn}
            onClick={
              () => {
                props.viaMail ? history.push("/user") : history.goBack();
              }
            }
          >
            <img src="/Image/assignment/back_arrow.svg" alt="" />
            &nbsp; &nbsp;
            {assignment?.class_name ||
              assignment?.group_name ||
              t("common.back")}
          </p>

          <div className={styles.feed_container}>
            <div className={styles.title_container}>
              <IconTitle
                title={current_assignment?.title}
                img="/Image/assignment/assignment-2.svg"
                notClickable={true}
                type="ASSIGNMENT"
              />
              <span className={styles.duedate_component_mobile}>
                <DueDate date={current_assignment?.due_date} />
              </span>

              <div className={styles.dueDate}>
                <span className={styles.duedate_component}>
                  <DueDate date={current_assignment?.due_date} />
                </span>
              </div>
            </div>

            
            <div className={styles.assignment_details_container}>
              <div className={styles.assignment_details_description}>
                {/* <p>{current_assignment?.details}</p> */}
                <p>{ReactHtmlParser(current_assignment?.details)}</p>
              </div>
              <div className={styles.attachment_container_div}>
                {current_assignment?.file != null ? (
                  <>
                    <br />
                    <AttachmentContainer
                      assignmentFileType="FILE"
                      fileName={`${current_assignment?.file
                        ?.split("/")
                        .pop()
                        .substr()}`}
                      fileSize=""
                      file={current_assignment?.file}
                      // link="google.com"
                      pageType=""
                    />
                  </>
                ) : null}
                {current_assignment?.url != null ? (
                  <>
                    <br />
                    <AttachmentContainer
                      assignmentFileType="LINK"
                      linkName={current_assignment?.url
                        ?.split("/")
                        .pop()
                        .substr()}
                      fileSize=""
                      link={current_assignment?.url}
                      pageType=""
                    />
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ViewEnterpriseAssignmentPage;
