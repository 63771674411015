import React from "react";
import { Component } from "react";
import styles from "./classcss/classsidebar.module.css";
import CourseModal from "./CourseModal";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import StartLive from "../../../components/commons/StartLive";
import { ModalWithHeader } from "../../../components/shared/ui/Modal/Modal";
import { connect } from "react-redux";
import { store } from "../../../redux/store";
import { updateMission, getClassDetails } from "../../../redux/actions/classes";
import { getGroupDetails } from "../../../redux/actions/groups";
import { withTranslation } from "react-i18next";
import { handleErrorMessage } from "../../../redux/actions/dashboard";

class ClassSidebar extends Component {
  constructor() {
    super();
    this.state = {
      course: "",
      link: "",
      src: "",
      isPostActiveSection: true,
      showCourseModal: false,
      activeSubject: "",
      showWelcomeModal: false,
      showClassMenu: false,
      myClasses: [],
      myCourses: [],
      showModal: false,
      missionText: "",
      numOfPost: 0,
      numOfAssignmentAndQuiz: null,
    };
  }
  componentDidMount() {
    this.setState({
      myClasses: this.props.myClasses,
      myCourses: this.props.myCourses,
      activeSubject: this.props.subjectName,
      missionText: this.props.vision_mission,
      numOfPost:
        this.props.routeType === "Class"
          ? this.props.classPost
          : this.props.groupPost,
      numOfAssignmentAndQuiz:
      this.props.routeType === "Class"
      ? this.props.classAssignments + this.props.classQuizzes
      // ? this.props.classAssignmentsQuizzes
      : 0,
    });
  }
  componentDidUpdate() {
    if (
      this.state.myCourses !== this.props.myCourses ||
      this.state.myClasses !== this.props.myClasses
    ) {
      this.setState({
        myClasses: this.props.myClasses,
        myCourses: this.props.myCourses,
        activeSubject: this.props.subjectName,
        missionText:
          this.props.routeType === "Class"
            ? this.props.vision_mission
            : this.props.groupVision,
        numOfPost:
          this.props.routeType === "Class"
            ? this.props.classPost
            : this.props.groupPost,
      });
    }
  }
  WelcomeModalOpener = () => {
    this.setState({ showWelcomeModal: true });
  };
  CourseModalCloser = () => {
    this.setState({ showCourseModal: false });
  };
  CourseModalOpener = () => {
    this.setState({ showCourseModal: true });
  };
  toogleMenu1 = () => {
    this.setState({
      showClassMenu: !this.state.showClassMenu,
    });
  };
  changeActiveSect = (selectedSection) => {
    if (this.props.activeSection != selectedSection) {
      this.props.changeActiveSection(selectedSection);
    }
  };
  changeSelectedSubject = (myClass) => {
    this.props.getActiveClassDetails(myClass);
  };

  Myclasses = () => {
    if (this.state.showClassMenu) {
      const ClassesList = () => (
        <ul>
          {this.state.myClasses.map((myClass, index) => (
            <div
              className={styles.menu1}
              onClick={() => this.changeSelectedSubject(myClass.id)}
              key={index}
            >
              <li
                className={styles.classList}
                style={{
                  marginTop: 6,
                  marginBottom: 6,
                  color:
                    this.props.subjectName !== myClass.name
                      ? "#555555"
                      : "#ED1E24",
                }}
              >
                {myClass.name}
              </li>
              {index < this.state.myClasses.length - 1 ? (
                <hr className={styles.ClassHr} />
              ) : null}
            </div>
          ))}
        </ul>
      );
      return (
        <div className={styles.dropdown_menu1}>
          <ClassesList />
        </div>
      );
    } else return <></>;
  };
  MyCourses = () => {
    return this.state.myCourses.map((myCourse, index) => (
      <>
        <div className={styles.Classsidebar_stat1} key={index}>
          <img
            src={myCourse.src}
            style={{ width: 48, height: 48, borderRadius: 50 }}
          />
          <p className={styles.Classsidebar_statNumber}>
            {myCourse.link != "" ? (
              <a
                href={myCourse.link}
                target="_blank"
                className={styles.onhover}
                style={{ textDecoration: "none", fontSize: 14 }}
              >
                {myCourse.title}
              </a>
            ) : (
              <p
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  fontSize: 14,
                }}
              >
                {myCourse.title}
              </p>
            )}
          </p>
        </div>
        {index < this.state.myCourses.length - 1 ? (
          <hr className={styles.ClassHr} />
        ) : null}
      </>
    ));
  };
  handleMission = () => {
    const { t } = this.props;

    if (this.state.missionText.trim() != "") {
      let payload = {
        missionText: this.state.missionText,
        classId:
          this.props.routeType === "Class"
            ? this.props.class_id
            : this.props.group_id,
        routeType: this.props.routeType,
      };
      this.props
        .updateMission(payload)
        .then(() => {
          this.setState({ showModal: false, missionText: "" });
          // this.props.routeType === "Class" ? this.props.getClassDetails(this.props.class_id) : this.props.getGroupDetails(this.props.group_id);
        })
        .catch((err) => {});
    } else {
      if (this.props.routeType == "Class") {
        this.props.handleErrorMessage(t("class.emptyMissionVision"));
      } else {
        this.props.handleErrorMessage(t("group.emptyObjective"));
      }
    }
  };
  render() {
    const { t } = this.props;
    this.state.isPostActiveSection = this.props.activeSection == "Post";
    return (
      <div className={styles.Class_Sidebar}>
        <CourseModal
          title={t("classGroup.addCourseLink")}
          closeModal={this.CourseModalCloser}
          show={this.state.showCourseModal}
          getActiveClassDetails={this.props.getActiveClassDetails}
        />
        <ModalWithHeader
          title={
            this.props.routeType === "Class"
              ? t("class.addMissionVision")
              : t("group.addObjective")
          }
          show={this.state.showModal}
          closeHandler={() => {
            this.setState({ showModal: false });
          }}
        >
          <div className={styles.classModal}>
            <div className={styles.formFields}>
              <h3 className={styles.inputLabel} for="classname">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    font: "normal normal 500 16px Montserrat",
                    letteSpacing: "0px",
                    color: "#000000",
                  }}
                >
                  <img
                    style={{ marginRight: "10px" }}
                    src="/Image/modalIcon/mission.svg"
                  />
                  {this.props.routeType === "Class"
                    ? t("class.missionVision")
                    : t("group.objective")}
                </div>
              </h3>
              <textarea
                className={`${styles.inputLabel} ${styles.textarea}`}
                onChange={(e) => this.setState({ missionText: e.target.value })}
                type="textarea"
                // className={styles.textarea}
                rows="6"
                placeholder={`${t("common.exDescribeYour")} ${
                  this.props.routeType === "Class"
                    ? t("class.missionVision")
                    : t("group.objective")
                }`}
                value={this.state.missionText}
              ></textarea>
              <button
                onClick={() => {
                  this.handleMission(this.missionText);
                }}
                id="submitForm"
                className={styles.saveDetailsButton}
              >
                {t("common.save")}
              </button>
            </div>
          </div>
        </ModalWithHeader>
        {/* Mission */}
        <div className={styles.Classsidebar_mycontent}>
          <div className={styles.Class_side}>
            <img
              src="/Image/DashImage/missionVision.svg"
              className={styles.Classsidebar_icon1}
              style={{ width: 35, height: 35, cursor: "pointer" }}
            />
            <h3 style={{ marginLeft: 15, fontSize: 16 }}>
              {this.props.routeType === "Class"
                ? t("class.missionVision")
                : t("group.objective")}
            </h3>
          </div>
        </div>

        <div className={styles.Classsidebar_stats}>
          {this.props.routeType === "Class" ? (
            <p
              style={{
                color: "#555555",
                padding: "15px 30px",
                margin: 0,
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {this.props.vision_mission == ""
                ? t("class.addYourMissionVision")
                : this.props.vision_mission}
            </p>
          ) : (
            <p
              style={{
                color: "#555555",
                padding: "15px 30px",
                margin: 0,
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {this.props.groupVision == ""
                ? t("group.addYourObjective")
                : this.props.groupVision}
            </p>
          )}

          <hr className={styles.ClassHr} />
          <div className={styles.sidebar_stat1}>
            <p
              onClick={() => {
                this.setState({ showModal: true });
              }}
            >
              <span style={{ marginLeft: 5 }}>
                {this.props.routeType === "Class"
                  ? t("class.editMissionVision")
                  : t("group.editObjective")}
              </span>
            </p>
          </div>
        </div>
        {/* Mission */}

        <div className={styles.Classsidebar_mycontent}>
          <div className={styles.Class_side}>
            <img
              src="/Image/DashImage/Chalkmate_MyClasses.svg"
              className={styles.Classsidebar_icon1}
              style={{ width: 35, height: 35, cursor: "pointer" }}
            />
            <h3 style={{ marginLeft: 15, fontSize: 16 }}>{`${
              this.props.routeType === "Class"
                ? t("common.myClasses")
                : t("common.myGroups")
            }`}</h3>
          </div>
          {this.state.myClasses.length > 1 ? (
            <div className={styles.Classnavbar_menu1}>
              <MoreHorizIcon
                onClick={this.toogleMenu1}
                className={styles.Classsidebar_icon2}
                style={{ marginTop: 7, cursor: "pointer" }}
              />
              <this.Myclasses />
            </div>
          ) : null}
        </div>

        {/* POST Section */}

        <div className={styles.Classsidebar_stats}>
          <div
            className={styles.Classsidebar_stat}
            onClick={() => this.changeActiveSect("Post")}
          >
            <p
              style={{
                color: this.props.activeSection == "Post" ? "#ED1E24" : "black",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Post"
                ? 600 : 400,
              }}
            >
              {t("common.post")}
            </p>
            <p
              style={{
                color: "#ED1E24",
                fontWeight: 600,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Post"
                ? 600 : 400,
              }}
            >
              {this.state.numOfPost}
            </p>
          </div>
          <hr className={styles.ClassHr} />

          {/* Assignmenet and Quiz section */}
          {/* {this.props.routeType === "Class" ? ( */}
          <div
            className={styles.Classsidebar_stat}
            onClick={() => this.changeActiveSect("Assignments")}
          >
            <p
              style={{
                color:
                  this.props.activeSection == "Assignments"
                    ? "#ED1E24"
                    : "black",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Assignments"
                ? 600 : 400,
              }}
            >
              {t("assignment.assignments")}
            </p>
            <p
              style={{
                color: "#ED1E24",
                fontWeight: 600,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Assignments"
                ? 600 : 400,
              }}
            >
              {this.props.classAssignments}
              {/* {this.props.classAssignments + this.props.classQuizzes} */}
              {/* {this.props.classAssignmentsQuizzes} */}
            </p>
          </div>

          {/* Quiz only section */}
          <hr className={styles.ClassHr} />

          <div
            className={styles.Classsidebar_stat}
            onClick={() => this.changeActiveSect("Quiz")}
          >
            <p
              style={{
                color:
                  this.props.activeSection == "Quiz"
                    ? "#ED1E24"
                    : "black",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Quiz"
                ? 600 : 400,
              }}
            >
              {t("quiz.quizzes")}
            </p>
            <p
              style={{
                color: "#ED1E24",
                fontWeight: 600,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Quiz"
                ? 600 : 400,
              }}
            >
              {this.props.quizCount ?? 0}
              {/* {this.props.classAssignmentsQuizzes} */}
            </p>
          </div>

          {/* Quiz Template section */}
          <hr className={styles.ClassHr} />

          <div
            className={styles.Classsidebar_stat}
            onClick={() => this.changeActiveSect("quizTemplates")}
          >
            <p
              style={{
                color:
                  this.props.activeSection == "quizTemplates"
                    ? "#ED1E24"
                    : "black",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "quizTemplates"
                ? 600 : 400,
              }}
            >
              {t("enhancedQuiz.quizTemplates")}
            </p>
            <p
              style={{
                color: "#ED1E24",
                fontWeight: 600,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "quizTemplates"
                ? 600 : 400,
              }}
            >
              {this.props.quizTemplateCount ?? 0}
              {/* {this.props.classAssignmentsQuizzes} */}
            </p>
          </div>

          {/* Courses Section */}
          {window.location.pathname.split('/')[1] == 'class' && <hr className={styles.ClassHr} /> }

          {window.location.pathname.split('/')[1] == 'class' && <div
            className={styles.Classsidebar_stat}
            onClick={() => this.changeActiveSect("Courses")}
          >
            <p
              style={{
                color:
                  this.props.activeSection == "Courses"
                    ? "#ED1E24"
                    : "black",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Courses"
                ? 600 : 400,
              }}
            >
              {t("common.courses")}
            </p>
            <p
              style={{
                color: "#ED1E24",
                fontWeight: 600,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Courses"
                ? 600 : 400,
              }}
            >
             {/* add courseCount here */}
             {this.props.courseCount ?? 0}
            </p>
          </div>}


          {/* ) : null} */}
          {/* {this.props.routeType === "Class" ? */}
          <hr className={styles.ClassHr} /> 
          {/* :null} */}
          

          {/* --- assignment quiz ends --- */}

          <div
            className={styles.Classsidebar_stat}
            onClick={() => this.changeActiveSect("Members")}
          >
            <p
              style={{
                color:
                  this.props.activeSection == "Members" ? "#ED1E24" : "black",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Members"
                ? 600 : 400,                
              }}
            >
              {t("common.members")}
            </p>
            <p
              style={{
                color: "#ED1E24",
                fontWeight: 600,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Members"
                ? 600 : 400,     
              }}
            >
              {this.props.numOfMembers}
            </p>
          </div>
          <hr className={styles.ClassHr} />
          <div
            className={styles.Classsidebar_stat}
            onClick={() => this.changeActiveSect("Recordings")}
          >
            <p
              style={{
                color:
                  this.props.activeSection == "Recordings"
                    ? "#ED1E24"
                    : "black",
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Recordings"
                ? 600 : 400,     
              }}
            >
              {t("common.recordings")}
            </p>
            <p
              style={{
                color: "#ED1E24",
                fontWeight: 600,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 0,
                fontWeight: this.props.activeSection == "Recordings"
                ? 600 : 400,     
              }}
            >
              {this.props?.meetings?.length}
            </p>
          </div>
        </div>
        <StartLive
          startLiveText={
            this.props.routeType === "Class"
              ? t("class.liveClass")
              : t("group.liveDiscussion")
          }
          source={this.props.routeType === "Class" ? "CLASS" : "GROUP"}
        />

        {/* <div className={styles.Classsidebar_mycontent}>
          <div className={styles.Class_side}>
            <img
              src="/Image/DashImage/Chalkmate_Courses.svg"
              className={styles.Classsidebar_icon1}
              style={{ width: 35, height: 35, cursor: "pointer" }}
            />
            <h3 style={{ marginLeft: 15, fontSize: 16 }}>
              {t("common.courses")}
            </h3>
          </div>
        </div>

        <div className={styles.Classsidebar_stats}>
          {this.props.myCourses.length > 0 ? (
            <this.MyCourses />
          ) : (
            <p style={{ color: "#555555", fontSize: "13px", margin: "15px 0" }}>
              {this.props.routeType === "Class"
                ? t("class.createCourseDesc")
                : t("group.createCourseDesc")}
            </p>
          )}
          <hr className={styles.ClassHr} />
          <div className={styles.sidebar_stat1}>
            <p onClick={this.CourseModalOpener}>
              <AddIcon style={{ width: 24, height: 24 }} />
              <span style={{ marginLeft: 5 }}>{t("class.createCourse")}</span>
            </p>
          </div>
        </div> */}

      </div>
    );
  }
}

/* export default ClassSidebar; */
const mapStateToProps = (state) => {
  return {
    vision_mission: state.classes.class.vision_mission,
    groupVision: state.groups.group.groupVision,
    classPost: state?.classes?.posts?.length,
    groupPost: state?.groups?.posts?.length,
    classAssignments: state?.assignment?.assignments?.length,
    classQuizzes: state?.quiz?.quizzes?.length,
    courseCount: state?.courses?.courseFeed?.length,
    quizTemplateCount: state?.enhancedQuiz?.quizTemplates?.length,
    // quizCount: state?.enhancedQuiz?.quizzes?.length
    // quizCount: state?.enhancedQuiz?.quizzes[0]?.quiz_owner?.id === state?.profile?.user?.profile_id 
    // ? state?.enhancedQuiz?.quizzes?.length
    // : state?.enhancedQuiz?.quizzes.filter((quiz)=> quiz?.user_submission != null)?.length
    // classAssignmentsQuizzes: state?.assignment?.allAssignmentQuiz?.length,
    quizCount: state?.enhancedQuiz?.quizzes?.length || 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMission: (data) => dispatch(updateMission(data)),
    getClassDetails: (id) => dispatch(getClassDetails(id)),
    getGroupDetails: (id) => dispatch(getGroupDetails(id)),
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ClassSidebar)
);
