import React from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import desc from "../../apps/Profile/pages/templates/desc";
import styles from "../../apps/Profile/Profile.module.css";
import ContactModal from "./ContactModal";
import { connect } from "react-redux";
import Modal from "./Modal";
import ChooseTemplates from "../../apps/Profile/pages/templates/ChooseTemplates";
import HandleApiError from "./HandleApiError";
import { withTranslation } from "react-i18next";
import OpenToWorkModal from "./OpenToWorkModal";
import OpenToWorkViewModal from "./OpenToWorkViewModal";
import OpenToWorkConfirmationModal from "./OpenToWorkConfirmationModal";
class InfoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      editcontact: false,
      showMenu: false,

      showTemlates: false,
      showOpenToWorkModal: false,

      profileLink: desc.website,
      phone: this.props.phone,
      address: this.props.address,
      email: this.props.email,
      dob: this.props.dob,
      user: {},
      username: this.props?.user?.first_name || this.props?.user?.last_name ? this.props.user.first_name + " " + this.props.user.last_name : "",
      designation: this.props.designation || "Director-Corporate Affairs",
      organisation: this.props.organisation || "J kakehasti Services Pvt Ltd.",
      location: this.props.location || "Kolkata, West Bengal, India",
      connections: this.props.connections || 0,
      isOTWEdit : false,
      showDeleteModal: false,
      triggerDeleteOTW: false,
    };
  }

  changeStates(username, designation, location, edit, phone, email, address, dob) {
    this.setState({ username: username, designation: designation, location: location, edit: edit, phone: phone, email: email, address: address, dob: dob });
  }

  changeEdit(editcontact) {
    this.setState({ editcontact: editcontact });
  }
  componentDidMount() {
    this.setState({
      designation: this.props.designation,
      organisation: this.props.organisation,
      location: this.props.location,
      connections: this.props.connections,
      user: this.props.user,
      error: false,
      message: "",
    });
  }
  // componentDidUpdate(){

  //     if(this.props.user!=this.state.user){
  //     this.setState({
  //         designation:this.props.designation,
  //         organisation:this.props.organisation,
  //         location:this.props.location,
  //         connections:this.props.connections
  //     })
  // }
  // }

  changeShowTemplatesState(showTemlates) {
    this.setState({ showTemlates: showTemlates });
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
        {this.props.editpage ? (
          <>
            <div style={{ marginTop: 10, display: "flex", alignItems: "center", justifyContent: "center", textTransform: "capitalize" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/Image/apps/profile-resume-edit-view/badge.svg"
                  alt=" "
                  height="32px"
                  data-tip={this.props?.user?.user_subtype ? this.props.user.user_subtype : "Other"}
                  style={{ cursor: "pointer" }}
                />
              </div>

              <div>
                <p style={{ margin: 0, marginLeft: 5, fontSize: 17, fontWeight: 600, color: "#000", textAlign: "center", maxWidth: "180px" }}>{this.props.name}</p>
              </div>
            </div>
            <br />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #5555555A",
                paddingBottom: 24,
              }}
            >
              <p
                className={styles.section_desc}
                style={{
                  textAlign: "center",
                }}
              >
                {this.props.designation}
                {this.props.designation != "" && this.props.organisation != "" ? " | " : ""}

                {this.props.organisation}
              </p>

              <p
                style={{ fontWeight: 600, fontSize: 14, margin: 0, color: "#ED1E24", cursor: "pointer" }}
                onClick={() => {
                  this.changeEdit(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <u>{t("profile.contactInfo")}</u>
              </p>

              {/* <img src="/Image/location.svg" alt=" " style={{ marginTop: 10 }} /> */}
              {this.props.address || this.props.location ? <img src="/Image/location.svg" style={{ marginTop: 10 }} /> : ""}
              <p
                className={styles.section_desc}
                style={{
                  fontSize: 14,
                  margin: 5,
                  textAlign: "center",
                }}
              >
                {this.props.address} {this.props.location}
              </p>
              {this.props.connections.length == 0 ? (
                <p style={{ fontSize: 14, fontWeight: 600, color: "#ED1E24", margin: 5 }}></p>
              ) : this.props.connections.length > 100 ? (
                <p style={{ fontSize: 14, fontWeight: 600, color: "#ED1E24", margin: 5 }}>100+ {t("common.connections")}</p>
              ) : (
                <p style={{ fontSize: 14, fontWeight: 600, color: "#ED1E24", margin: 5 }}>{this.props.connections.length} {t("common.connections")}</p>
              )}
              <div style={{ position: "relative" }}>
                <p
                  style={{ fontWeight: 600, fontSize: 14, margin: 0, color: "#ED1E24", cursor: "pointer" }}
                  onClick={() => {
                    this.setState({ showTemlates: true });
                    document.body.style.overflow = "hidden";
                  }}
                >
                  <u>{t("profile.shortBio")}</u>
                </p>
              </div>
              <div style={{ position: "relative" }}>
                <p
                  style={{ fontWeight: 600, fontSize: 14, margin: 0, color: "#ED1E24", cursor: "pointer" }}
                  onClick={() => {
                    this.setState({ showOpenToWorkModal: true });
                    // document.body.style.overflow = "hidden";
                  }}
                >
                  <u>{this.props.t("openToWork.openToWork")}</u>
                </p>
              </div>
            </div>

            {this.state.showTemlates ? <ChooseTemplates changeShowTemplatesState={this.changeShowTemplatesState.bind(this)} mobileView={this.props.mobileView} /> : null}
            {this.state.showOpenToWorkModal && 
            <Modal
              card={
                (this.props.openToWork && !this.state.isOTWEdit) ? 
                <OpenToWorkViewModal 
                  triggerDeleteOTW={this.state.triggerDeleteOTW} 
                  openDeleteModal={()=>{this.setState({showDeleteModal: true, OpenToWorkModal: false})}} 
                  openEditView={()=>this.setState({isOTWEdit: true})} 
                  open={this.state.showOpenToWorkModal} 
                  onClose={()=>this.setState({showOpenToWorkModal: false, showDeleteModal: false, triggerDeleteOTW: false})} 
                /> 
                :
                <OpenToWorkModal 
                  isEdit={this.state.isOTWEdit} 
                  open={this.state.showOpenToWorkModal} 
                  onClose={()=>this.setState({showOpenToWorkModal: false, isOTWEdit: false})} 
                />
            }
          />}

          {this.state.showDeleteModal &&
          <Modal 
            card = {<OpenToWorkConfirmationModal 
                      onCancel={()=>this.setState({showOpenToWorkModal: false, showDeleteModal: false, triggerDeleteOTW: false})} 
                      onDelete={()=>this.setState({triggerDeleteOTW: true})} />}
          />
          }

            {this.state.editcontact ? (
              <Modal
                card={
                  <ContactModal
                    editpage={true}
                    profileLink={this.state.profileLink}
                    phone={this.state.phone}
                    address={this.state.address}
                    email={this.state.email}
                    dob={this.state.dob}
                    changeEdit={this.changeEdit.bind(this)}
                  />
                }
              />
            ) : null}
            <ReactTooltip />
          </>
        ) : (
          ////////////////////////////////////////////////////////////// view page ////////////////////////////////////////////////////////////////////////////
          <>
            <div style={{ marginTop: 10, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src="/Image/apps/profile-resume-edit-view/badge.svg"
                  alt=" "
                  height="32px"
                  data-tip={this.props?.user?.user_subtype ? this.props.user.user_subtype : "Other"}
                  style={{ cursor: "pointer" }}
                />
              </div>

              <div>
                <p style={{ margin: 0, marginLeft: 5, fontSize: 17, fontWeight: 600, color: "#000", textAlign: "center", maxWidth: "180px" }}>{this.props.nameView}</p>
              </div>
            </div>
            <br />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid #5555555A",
                paddingBottom: 24,
              }}
            >
              <p
                className={styles.section_desc}
                style={{
                  textAlign: "center",
                }}
              >
                {this.props.designationView}
                {this.props.designationView != "" && this.props.organisationView != "" ? " | " : ""}
                {this.props.organisationView}
              </p>

              <p
                style={{ fontWeight: 600, fontSize: 14, margin: 0, color: "#ED1E24", cursor: "pointer" }}
                onClick={() => {
                  this.changeEdit(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <u>{t("profile.contactInfo")}</u>
              </p>

              {/* <img src="/Image/location.svg" alt=" " style={{ marginTop: 10 }} /> */}
              {this.props.addressView || this.props.locationView ? <img src="/Image/location.svg" style={{ marginTop: 10 }} /> : ""}

              <p
                className={styles.section_desc}
                style={{
                  fontSize: 14,
                  margin: 5,
                }}
              >
                {this.props.addressView} {this.props.locationView}
              </p>
              {this.props.connectionsView.length == 0 ? (
                <p style={{ fontSize: 14, fontWeight: 600, color: "#ED1E24", margin: 5 }}></p>
              ) : this.props.connectionsView.length > 100 ? (
                <p style={{ fontSize: 14, fontWeight: 600, color: "#ED1E24", margin: 5 }}>100+ {t("common.connections")}</p>
              ) : (
                <p style={{ fontSize: 14, fontWeight: 600, color: "#ED1E24", margin: 5 }}>{this.props.connectionsView.length} {t("common.connections")}</p>
              )}
              {/* <p style={{ fontSize: 14, fontWeight: 600, color: "#ED1E24", margin: 5 }}>{this.props.connectionsView} Connections</p> */}

              {this.props.mobileView ? (
                <div style={{ position: "relative" }}>
                  <p
                    style={{ fontWeight: 600, fontSize: 14, margin: 0, color: "#ED1E24", cursor: "pointer" }}
                    onClick={() => {
                      this.setState({ error: true, message: "This feature is only available on desktop" });
                    }}
                  >
                    <u>{t("profile.shortBio")}</u>
                  </p>
                </div>
              ) : (
                <div style={{ position: "relative" }}>
                  <Link to={`/profile/${window.location.pathname.slice(9, -5)}/template/${this.props.short_bio_view}`} style={{ textDecoration: "none" }} target="_blank">
                    <p
                      style={{ fontWeight: 600, fontSize: 14, margin: 0, color: "#ED1E24", cursor: "pointer" }}
                      onClick={() => {
                        this.setState({ showTemlates: true });
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      <u>{t("profile.shortBio")}</u>
                    </p>
                  </Link>
                </div>
              )}
            </div>

            {/* {this.state.showTemlates ? <ChooseTemplates changeShowTemplatesState={this.changeShowTemplatesState.bind(this)} /> : null} */}

            {this.state.editcontact ? (
              <Modal
                card={
                  <ContactModal
                    editpage={false}
                    profileLink={this.state.profileLink}
                    phone={this.state.phone}
                    address={this.state.address}
                    email={this.state.email}
                    dob={this.state.dob}
                    changeEdit={this.changeEdit.bind(this)}
                  />
                }
              />
            ) : null}
            <ReactTooltip />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state?.profile?.user || "",
    // dob:state?.profile?.about?.dob ||"",
    // organisation:state?.profile?.about?.organisation || "",
    // designation:state?.profile?.about?.designation || "",
    // location:state?.profile?.about?.location || "",
    // connections:state?.profile?.about?.connections || 0,
    name: state?.profile?.name || "",
    dob: state?.profile?.dob || "dd/mm/yyyy",
    organisation: state?.profile?.organisation || "",
    designation: state?.profile?.designation || "",
    location: state?.profile?.location || "",
    connections: state?.profile?.connections || 0,
    phone: state?.profile?.user?.phone || "1234567890",
    address: state?.profile?.address || "",

    nameView: state?.profile?.nameView || "",
    dobView: state?.profile?.dobView || "dd/mm/yyyy",
    organisationView: state?.profile?.organisationView || "",
    designationView: state?.profile?.designationView || "",
    locationView: state?.profile?.locationView || "",
    connectionsView: state?.profile?.connectionsView || 0,
    phoneView: state?.profile?.user?.phoneView || "1234567890",
    addressView: state?.profile?.addressView || "",
    short_bio_view: state?.profile?.short_bio_view || "",
    openToWork: state?.profile?.open_to_work?.id || "",
  };
};

export default withTranslation()(connect(mapStateToProps, null)(InfoCard));
