import React, { useState, useEffect } from "react";
import styles from "./QuizTemplateForm.module.css";
import { useTranslation } from "react-i18next";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import IconTitle from "../../../components/commons/IconTitle";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import DiscardDeleteAssignmentModal from "../Modals/DiscardDeleteAssignmentModal";
import Modal from "../../../../src/components/commons/Modal";
import { getIndividualQuizTemplate } from "../../../redux/actions/enhancedQuiz";
import { CircularProgress } from "@mui/material";
import { handleErrorMessage } from "../../../redux/actions/groups";

const QuizTemplateForm = () => {
  const isMobile = window.innerWidth < 768;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const routeType = location?.state?.routeType;
  const actionType = location?.state?.actionType;
  const templateId = location?.state?.templateId;
  const isCourse = location?.state?.isCourse ?? false;

  const existingQuizTemplate = useSelector(
    (state) => state?.enhancedQuiz?.individualQuizTemplate
  );
  const currentClassOrGroupName = useSelector((state) =>
    routeType == "class"
      ? state?.classes?.class?.name
      : state?.groups?.group?.name
  );

  const [quizTemplateTitle, setQuizTemplateTitle] = useState(
    actionType == "edit" ? existingQuizTemplate?.quizTemplateTitle : ""
  );
  const [quizTemplateInstructions, setQuizTemplateInstructions] = useState(
    actionType == "edit" ? existingQuizTemplate?.quizTemplateInstructions : ""
  );

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(
    actionType == "edit" ? true : false
  );

  // ------ useEffect section ------------

  useEffect(() => {
    if (actionType === "edit") {
      dispatch(getIndividualQuizTemplate(templateId)).then(() =>
        setIsLoading(false)
      );
    }
  }, []);

  useEffect(() => {
    if(actionType == 'edit'){
      setQuizTemplateTitle(existingQuizTemplate?.quizTemplateTitle);
      setQuizTemplateInstructions(existingQuizTemplate?.quizTemplateInstructions);
    }
  }, [existingQuizTemplate]);

  // console.log("existingQT", existingQuizTemplate);

  const nextHandler = () => {
    if (quizTemplateTitle?.trim() == "" || quizTemplateTitle == null) {
      dispatch(
        handleErrorMessage(t("errorMessageTranslations.requiredFields"))
      );
    } else {
      history.push({
        pathname: `/template/questions/${params.id}`,
        state: {
          title: quizTemplateTitle,
          instructions: quizTemplateInstructions,
          template: existingQuizTemplate,
          actionType: actionType,
          routeType: routeType,
          currentClassOrGroupName: currentClassOrGroupName,
          isCourse: location?.state?.isCourse,
          courseID: location?.state?.courseID,  // only if isCourse is true
          sectionID: location?.state?.sectionID, // only if isCourse is true
        },
      });
    }
  };
  const discardModalClose = () => {
    setShowModal(false);
  };
  const discardFunction = () => {
    setQuizTemplateTitle(null);
    setQuizTemplateInstructions(null);
    if(isCourse){
      history.goBack();
    } else {
      history.push({
        pathname: `/${routeType}/${params.id}`,
        state: { activeSection: "Assignments" },
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.loader_style}>
          <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
        </div>
      ) : (
        <div className={styles.main_container}>
          {showModal && (
            <Modal
              card={
                <DiscardDeleteAssignmentModal
                  modalMessage={t("enhancedQuiz.discardQuizTemplate")}
                  modalLabel={t("common.discard")}
                  assignmentType="QUIZ"
                  changeEdit={discardModalClose}
                  discardFunction={discardFunction}
                />
              }
            />
          )}
          <h5
            className={styles.back_btn}
            onClick={() => {
              setShowModal(true);
            }}
          >
            <img src="/Image/assignment/back_arrow.svg" alt="" />
            &nbsp; &nbsp;{currentClassOrGroupName || t("courseMonetisation.goBackBtn")}
          </h5>
          <div className={styles.feed_container}>
            <div className={styles.title_container}>
              <IconTitle
                title={t("enhancedQuiz.quizTemplate")}
                img="/Image/assignment/White/Quiz.svg"
                notClickable={true}
                type="ASSIGNMENT"
              />
              {isMobile ? '' : 
              (
                <Button
                  type="solid"
                  label={t("createCourse.next")}
                  clickHandler={nextHandler}
                />
              )
              }
              
            </div>

            <div className={styles.add_template}>
              <img
                alt=""
                className={styles.lable_icon}
                src="/Image/assignment/Add attachment.svg"
              />
              {t("enhancedQuiz.addQuizTemplate")}
            </div>

            <div className={styles.form_container}>
              <div className={styles.form}>
                <p className={styles.input_label} for="quizTitle">
                  <div className={styles.input_title_container}>
                    <img
                      className={styles.lable_icon}
                      src="/Image/assignment/Title.svg"
                    />
                    {t("enhancedQuiz.quizTemplateTitle")}*
                  </div>
                </p>
                <input
                  type="text"
                  className={styles.input_field}
                  placeholder={t("enhancedQuiz.quizTemplatePlaceholder")}
                  value={quizTemplateTitle}
                  onChange={(e) => {
                    setQuizTemplateTitle(e.target.value);
                  }}
                />
                <h3 for="quizInstructions" className={styles.input_label}>
                  <div className={styles.input_title_container}>
                    <img
                      className={styles.lable_icon}
                      src="/Image/assignment/Instructions.svg"
                    />
                    {t("assignment.instructions")}
                  </div>
                </h3>
                <textarea
                  type="text"
                  className={styles.input_field1}
                  placeholder={t("assignment.instructionsPlaceholder")}
                  value={quizTemplateInstructions}
                  onChange={(e) => {
                    setQuizTemplateInstructions(e.target.value);
                  }}
                ></textarea>
              </div>
              {isMobile ? 
                (<div className={styles.mob_btn}><Button
                  type="solid"
                  label={t("createCourse.next")}
                  clickHandler={nextHandler}
                /></div>) : ''
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuizTemplateForm;
