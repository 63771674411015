import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Nav from "./template/home/Nav/Nav";
// import Hero from "./template/home/Hero/Hero";
import HomeView from "./template/home/HeroNew/Hero";
import ProtectedRoute, {
  ProtectedRouteWithHeader,
} from "./components/ProtectedRoute";
import HomePage from "./apps/VdoConf/Home";
import ZoomPage from "./apps/VdoConf/Zoom/ZoomPage";
import Dashboard from "./apps/Dashboard/Components/Dashboard";

import ChooseTemplates from "./apps/Profile/pages/templates/ChooseTemplates";
import Template1 from "./apps/Profile/pages/templates/Template1";
import Template2 from "./apps/Profile/pages/templates/Template2";
import Template3 from "./apps/Profile/pages/templates/Template3";
import Template4 from "./apps/Profile/pages/templates/Template4";

import { Feed as ProfileView } from "./apps/Profile/pages/view/index";
import { Feed as ProfileEdit } from "./apps/Profile/pages/edit/index";

import SearchGlobal from "./components/search/SearchGlobal";
import SearchInvitations from "./components/search/SearchInvitations";

import styles from "./apps/Profile/Profile.module.css";
import Class from "./apps/ClassesGroups/CreateClass/class";
import Group from "./apps/ClassesGroups/CreateGroup/group";

import Signup from "./template/Sign/Signup/Signup";
import Signin from "./template/Sign/Signin/Signin";
import Forgot_Password from "./template/Sign/Forgot_Password/Forgot_Password";
import ChangePassword from "./template/Sign/Forgot_Password/ChangePassword";
import ZoomSubscription from "./apps/VdoConf/ZoomSubscription/zoomSubscription";
import ZoomConfigure from "./apps/VdoConf/ZoomConfigure/ZoomConfigure";
import Settings from "./apps/ZoomSettings/Settings";

import { HeaderProvider } from "./apps/Profile/contexts/HeaderProvider";
import NotificationsPage from "./apps/Dashboard/NotificationsPage/NotificationsPage";
import MobileBurger from "./apps/Dashboard/Components/Mobileburger";
import InviteStudents from "./apps/ClassesGroups/CreateClass/InviteStudents";
import ErrorFourZeroFour from "./components/ErrorFourZeroFour";
import MyClasses from "./apps/ClassesGroups/Classes/MyClasses";
import Academics from "./apps/Academics/Pages/Academics";
import MyGroups from "./apps/ClassesGroups/Groups/MyGroups";
import PublicCourses from "./apps/CourseMonetization/Pages/PublicCourses";
import Connections from "./components/commons/Connections";
import UnProtectedRouteWithoutHeader from "./components/UnProtectedRoute";
import { connect } from "react-redux";
import { getUserSettings } from "./redux/actions/zoomAction";
import CreateUpdateAssignmentFormComponent from "./apps/AssignmentAndQuiz/CreateUpdateAssignmentFormComponent";

import ViewAssignmentPage from "./apps/AssignmentAndQuiz/ViewAssignmentPage";
import ViewAssignmentStatus from "./apps/AssignmentAndQuiz/ViewAssignmentStatus";
import ViewAssignmentGradingPage from "./apps/AssignmentAndQuiz/ViewAssignmentGradingPage";

import StudentAssignmentDetailsPage from "./apps/AssignmentAndQuiz/StudentAssignmentDetailsPage"
import StudentAssignmentList from "./apps/AssignmentAndQuiz/StudentAssignmentList";
import UpcomingAssignmentsComponent from "./apps/AssignmentAndQuiz/Components/UpcomingAssignmentsComponent";
import OpenAsgFromMail from "./apps/AssignmentAndQuiz/Components/OpenAsgFromMail";
import CourseView from './apps/CourseMonetization/Pages/CourseView/CourseView';
import FeatureVideosPage from "./template/FeatureVideos/FeatureVideosPage";
import CourseStreamPage from "./apps/CourseLecturePlan/CourseStreamPage";
import CreateCoursePage from "./apps/CourseLecturePlan/CreateCoursePage";
import ReviewCoursePage from "./apps/CourseLecturePlan/ReviewCoursePage";
import AddCourseContentPage from "./apps/CourseLecturePlan/AddCourseContentPage";

// import OuterQuestionContainer from "./apps/AssignmentAndQuiz/Components/OuterQuestionContainer";
import CreateQuizTemplatePage from "./apps/AssignmentAndQuiz/CreateQuizTemplatePage";
import QuizTemplateForm from "./apps/AssignmentAndQuiz/Components/QuizTemplateForm";
import CreateQuizPage from "./apps/AssignmentAndQuiz/CreateQuizPage";
import AttemptAndPreviewQuizPage from "./apps/AssignmentAndQuiz/AttemptAndPreviewQuizPage";
import ViewQuizStatus from "./apps/AssignmentAndQuiz/ViewQuizStatus";
import ReattemptQuizPage from "./apps/AssignmentAndQuiz/ReattemptQuizPage";
import GradebookView from "./apps/Gradebook/GradebookView";
import GradebookStudentDetails from "./apps/Gradebook/GradebookStudentDetails";
import IndividualQuizGradebook from "./apps/Gradebook/IndividualQuizGradebook";
import IndividualAssignmentGradebook from "./apps/Gradebook/IndividualAssignmentGradebook";
import LiveChat from "./apps/LiveChat/LiveChat";
import EnterpriseDashboard from "./apps/EnterpriseDashboard/EnterpriseDashboard";
import ViewEnterpriseAssignmentPage from "./apps/AssignmentAndQuiz/ViewEnterpriseAssignmentPage";
import EnterpriseFacultyDashboard from "./apps/EnterpriseDashboard/EnterpriseFacultyDashboard";
import Opportunities from "./apps/Opportunities/Opportunities";
// import MyGroups from "./apps/Groups/MyGroups";
// import { HeaderContext } from './apps/Profile/contexts/HeaderProvider';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editpage: false,
      searchpage: false,
      zoomData: undefined,
      redirectToZoom: false,
    };

    this.setZoomData = this.setZoomData.bind(this);
    this.toggleChatList = this.toggleChatList.bind(this);

    this.ProfileVw = this.ProfileVw.bind(this);
    this.ProfileRedirect = this.ProfileRedirect.bind(this);
  }

  toggleChatList = () => {
    this.setState({ chatList: !this.state.chatList });
  };

  setEditPageState(editval) {
    this.setState({ editpage: editval });
  }

  setSearchPageState(editval) {
    this.setState({ searchpage: editval });
  }

  setZoomData(newData, create = 0) {
    // this.setState({ zoomData: newData })
    // console.log(newData,"------------------")
    if (newData) {
      newData.create = create;
      this.setState({ zoomData: newData, redirectToZoom: true });
      // this.props.history.push('/chalkmeet')
    }
  }

  ProfileVw(e) {
    return (
      <div className={styles.mains}>
        <br />
        <div class={styles.feed}>
          <ProfileView editpage={e.editpage} />
        </div>
      </div>
    );
  }

  ProfileEdt(e) {
    return (
      <div className={styles.mains}>
        <br />
        <div class={styles.feed}>
          <ProfileEdit editpage={e.editpage} />
        </div>
      </div>
    );
  }

  Home() {
    return (
      <div className="App">
        <Nav />
        {/* <Hero /> */}
        <HomeView/>
      </div>
    );
  }

  ProfileRedirect({ id }) {
    if (JSON.parse(localStorage.userData).profile_id == id) {
      return <Redirect to={`/profile/${id}/edit`} />;
    } else {
      return <Redirect to={`/profile/${id}/view`} />;
    }
  }

  componentDidMount() {
    // this.props.getUserSettings()
  }

  render() {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/">
              <this.Home />
            </Route>

            <Route exact path="/sidebar">
              <MobileBurger />
            </Route>

            <Route exact path="/features">
              <Nav />
              {/* <HomeView/> */}
              <FeatureVideosPage/>
            </Route>

            <ProtectedRouteWithHeader exact path="/video">
              {this.props.accountType == "PAID" ? (
                <>
                  { }
                  <HomePage
                    redirect={this.state.redirectToZoom}
                    zoomData={this.state.zoomData}
                    setZoomData={this.setZoomData}
                  />
                </>
              ) : null}
              {this.props.accountType == "FREE" ? (
                <>
                  { } <Redirect to={`/zoomconfiguration`} />
                </>
              ) : null}
              {this.props.accountType == "" ? (
                <>
                  { } <Redirect to={`/subscription`} />
                </>
              ) : null}
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/subscription">
              {this.props.accountType == "" ? (
                <>
                  { }
                  <ZoomSubscription />
                </>
              ) : null}
              {this.props.accountType == "FREE" ? (
                <>
                  { } <Redirect to={`/zoomconfiguration`} />
                </>
              ) : null}
              {this.props.accountType == "PAID" ? (
                <>
                  { } <Redirect to={`/video`} />
                </>
              ) : null}
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/zoomconfiguration">
              {this.props.accountType == "FREE" ? (
                <>
                  { }
                  <ZoomConfigure />
                </>
              ) : null}
              {this.props.accountType == "" ? (
                <>
                  { } <Redirect to={`/subscription`} />
                </>
              ) : null}
              {this.props.accountType == "PAID" ? (
                <>
                  { } <Redirect to={`/video`} />
                </>
              ) : null}
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/settings">
              <Settings />
            </ProtectedRouteWithHeader>

            <ProtectedRoute exact path="/chalkmeet">
              <ZoomPage
                zoomData={this.state.zoomData}
                setZoomData={this.setZoomData}
              />
            </ProtectedRoute>

            <Route exact path="/signup">
              <Signup />
            </Route>

            <Route exact path="/signin">
              <Signin />
            </Route>

            <Route exact path="/forgotpassword">
              <UnProtectedRouteWithoutHeader />
              <Forgot_Password />
            </Route>

            <Route exact path="/reset_password">
              <ChangePassword />
            </Route>

            <Route exact path="/not_found">
              <ErrorFourZeroFour />
            </Route>

            <ProtectedRouteWithHeader exact path="/class/:id">
              <Class />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/group/:id">
              <Group />
            </ProtectedRouteWithHeader>

            <ProtectedRoute path="/invite">
              <InviteStudents />
            </ProtectedRoute>

            <ProtectedRouteWithHeader exact path="/user">
              <Dashboard />
            </ProtectedRouteWithHeader>

            <ProtectedRoute exact path="/notifications">
              <NotificationsPage />
            </ProtectedRoute>

            <ProtectedRouteWithHeader exact path="/connections">
              <Connections />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/opportunities">
              <Opportunities />
            </ProtectedRouteWithHeader>

            <Route
              exact
              path="/profile/:id"
              render={({ match }) => (
                <this.ProfileRedirect id={match.params.id} />
              )}
            />

            <ProtectedRouteWithHeader exact path="/profile/:id/view">
              <this.ProfileVw />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/classes">
              <div className="classGroupIndividually"><MyClasses /></div>
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/academics">
              <Academics />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/messages">
              <LiveChat/>
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/groups">
              <div className="classGroupIndividually"><MyGroups /></div>
            </ProtectedRouteWithHeader>
            
            <ProtectedRouteWithHeader exact path="/courses">
              <PublicCourses />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/profile/:id/edit">
              <this.ProfileEdt editpage={true} />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader path="/search/results">
              <SearchGlobal props={this.props} />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader path="/search/invitations">
              <SearchInvitations />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/profile/:id/template/1">
              <Template1 />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/profile/:id/template/2">
              <Template2 />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/profile/:id/template/3">
              <Template3 />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/profile/:id/template/4">
              <Template4 />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/assignment/create/:id" hideMobileNav={true}>
              <CreateUpdateAssignmentFormComponent
                assignmentType="ASSIGNMENT"
              />
            </ProtectedRouteWithHeader >
            
            <ProtectedRouteWithHeader exact path="/quiz/create/:id" hideMobileNav={true}>
              <CreateQuizPage/>
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/quiz/attempt/:id" hideMobileNav={true}>
              <AttemptAndPreviewQuizPage isPreview={false} />
            </ProtectedRouteWithHeader>

            {/* ------- QUIZ TEMPLATE TEST ROUTE */}

            <ProtectedRouteWithHeader exact path="/template/create/:id" hideMobileNav={true}>
               <QuizTemplateForm/>
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/template/questions/:id" hideMobileNav={true}>
              <CreateQuizTemplatePage/>
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/template/preview/" hideMobileNav={true}>
              <AttemptAndPreviewQuizPage />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/assignment/view/:id" hideMobileNav={true}>
              <ViewAssignmentPage
                assignmentType="ASSIGNMENT"
              />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/quiz/view/:id" hideMobileNav={true}>
              <ViewAssignmentPage
                assignmentType="QUIZ"
              />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/assignment/status/:id" hideMobileNav={true}>
              <ViewAssignmentStatus
                assignmentType="ASSIGNMENT"
              />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/quiz/status/:id" hideMobileNav={true}>
              <ViewQuizStatus
                assignmentType="QUIZ"
              />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/assignment/grading/:id" hideMobileNav={true}>
              <ViewAssignmentGradingPage
                assignmentType="ASSIGNMENT"
              />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/quiz/grading/:id" hideMobileNav={true}>
              <AttemptAndPreviewQuizPage />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/assignment/upcoming" hideMobileNav={true}>
              <UpcomingAssignmentsComponent />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/student/assignment/:id" hideMobileNav={true}>
              <StudentAssignmentDetailsPage type="ASSIGNMENT" />
            </ProtectedRouteWithHeader>
            <ProtectedRouteWithHeader exact path="/student/quiz/:id" hideMobileNav={true}>
              <ReattemptQuizPage type="QUIZ" />
            </ProtectedRouteWithHeader>
            <ProtectedRouteWithHeader exact path="/assignment/list/:id" hideMobileNav={true}>
              <StudentAssignmentList assignmentType="ASSIGNMENT" />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/quiz/list/:id" hideMobileNav={true}>
              <StudentAssignmentList assignmentType="QUIZ" />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/assignment/create" hideMobileNav={true}>
              <CreateUpdateAssignmentFormComponent
                assignmentType="ASSIGNMENT"
                assignmentClass="Laws of motion"
              />
            </ProtectedRouteWithHeader>

            {/* --- openAsg and openQuiz from mail --- */}

            <ProtectedRouteWithHeader exact path="/class/assignment/view/:id" hideMobileNav={true}>
              <OpenAsgFromMail
                assignmentType="ASSIGNMENT"
              />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/class/quiz/view/:id" hideMobileNav={true}>
              <OpenAsgFromMail
                assignmentType="QUIZ"
              />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/group/assignment/view/:id" hideMobileNav={true}>
              <OpenAsgFromMail
                assignmentType="ASSIGNMENT"
              />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/group/quiz/view/:id" hideMobileNav={true}>
              <OpenAsgFromMail
                assignmentType="QUIZ"
              />
            </ProtectedRouteWithHeader>


            {/* Course Monitization Routes */}

            <ProtectedRouteWithHeader exact path="/course/view/:id" hideMobileNav={true} >
              <CourseView />
            </ProtectedRouteWithHeader>

            <ProtectedRoute exact path="/course/stream/:id" hideMobileNav={true}>
              <CourseStreamPage />
            </ProtectedRoute>

            <ProtectedRouteWithHeader exact path="/course/create/" hideMobileNav={true} >
              <CreateCoursePage />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/course/content/:id" hideMobileNav={true} >
              <AddCourseContentPage />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/course/review/:id" hideMobileNav={true} >
              <ReviewCoursePage />
            </ProtectedRouteWithHeader>

            {/* --- Gradebook --- */}
            
            <ProtectedRouteWithHeader exact path="/gradebook/view/:id" hideMobileNav={true} >
              <GradebookView/>
            </ProtectedRouteWithHeader>
            
            <ProtectedRouteWithHeader exact path="/gradebook/student/" hideMobileNav={true} >
              <GradebookStudentDetails/>
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/gradebook/quiz/" hideMobileNav={true} >
              <IndividualQuizGradebook/>
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/gradebook/assignment/" hideMobileNav={true} >
              <IndividualAssignmentGradebook/>
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/dashboard" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/faculty-dashboard" hideMobileNav={true} >
              <EnterpriseFacultyDashboard />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/student-dashboard" hideMobileNav={true} >
              <EnterpriseFacultyDashboard isStudent={true} />
            </ProtectedRouteWithHeader>

            {/* <ProtectedRouteWithHeader exact path="/dashboard-faculty" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/dashboard-student" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/dashboard-post" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>


            <ProtectedRouteWithHeader exact path="/dashboard/class-post" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>
            <ProtectedRouteWithHeader exact path="/dashboard/class-assignment" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>
            <ProtectedRouteWithHeader exact path="/dashboard/class-quiz" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>
            <ProtectedRouteWithHeader exact path="/dashboard/class-course" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>

            <ProtectedRouteWithHeader exact path="/dashboard/group-post" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>
            <ProtectedRouteWithHeader exact path="/dashboard/group-assignment" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader>
            <ProtectedRouteWithHeader exact path="/dashboard/group-quiz" hideMobileNav={true} >
              <EnterpriseDashboard />
            </ProtectedRouteWithHeader> */}

            <ProtectedRouteWithHeader exact path="/dashboard/assignment/view/:id" hideMobileNav={true}>
              <ViewEnterpriseAssignmentPage
                assignmentType="ASSIGNMENT"
              />
            </ProtectedRouteWithHeader>






            {/* <Route path="/template/1" children={<Template1 />} />
            <Route path="/template/2" children={<Template2 />} />
            <Route path="/template/3" children={<Template3 />} />
            <Route path="/template/4" children={<Template4 />} /> */}
            <Route component={ErrorFourZeroFour} />
          </Switch>
        </Router>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accountType: state?.zoom?.zoom_settings?.zoomSettings?.account_type,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getUserSettings: () => dispatch(getUserSettings()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
