import React from "react";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import { formatDate } from "../../redux/constants/commonFunctions";
import IconTitle from "../commons/IconTitle";
import AssignmentHeading from "./AssignmentHeading";
import styles from "./css/AssignmentListIndividual.module.css";

class AssignmentListIndividual extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <>
        {this.props.listType == "STUDENT" ? (
          <div
            className={styles.assignment_individual_list_container}
            onClick={this.props.isAsg ? () => this.props.clickHandler(this.props.id) : () => this.props.clickHandler(this.props.assignment)}
          >
            {/* <AssignmentHeading title={this.props?.title}/> */}
            <IconTitle
              title={this.props?.title}
              img="/Image/assignment/White/Assignment.svg"
              notClickable={true}
              type="ASSIGNMENT"
            />
            <p className={styles.assignment_due_date}>
            {t("assignment.dueOn")} {formatDate(this.props?.dueDate)}
            </p>
            <div className={styles.assignment_status_div}>
              <p className={styles.assignment_status}>{this.props?.status}</p>
            </div>
          </div>
        ) : (
          <div
            className={styles.assignment_individual_list_container_tchr}
            onClick={() => this.props.clickHandler(this.props.id)}
          >
            <IconTitle
              title={this.props?.title}
              img={this.props?.isAsg ? "/Image/assignment/White/Assignment.svg" : "/Image/assignment/White/Quiz.svg"}
              notClickable={true}
              type="ASSIGNMENT"
              upcomingItem = {true}
            />
            <p className={styles.assignment_due_date}>
            {t("assignment.dueOn")} {formatDate(this.props?.dueDate)}
            </p>
            <div className={styles.assignment_status_div_tchr}>
              <p
                className={styles.assignment_status}
                style={{ marginBottom: "5px" }}
              >
                {this.props?.assigned}
              </p>
              <p className={styles.assignment_status}>
                {t("viewAssignment.assigned")}
              </p>
            </div>
            <div className={styles.assignment_status_div_tchr}>
              <p
                className={styles.assignment_status}
                style={{ marginBottom: "5px" }}
              >
                {this.props?.submitted}
              </p>
              <p className={styles.assignment_status}>
                {t("viewAssignment.submitted")}
              </p>
            </div>
            <div className={styles.assignment_status_div_tchr}>
              <p
                className={styles.assignment_status}
                style={{ marginBottom: "5px" }}
              >
                {this.props?.graded}
              </p>
              <p className={styles.assignment_status}>
                {t("viewAssignment.graded")}
              </p>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(AssignmentListIndividual);
