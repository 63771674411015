import React from "react";

import styles from "../../../../Profile.module.css";

import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

import { fetchModelSkills, updateSkills } from "../../../../../../redux/actions/skill";
import { connect } from "react-redux";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { GET_MODEL_SKILLS, UPDATE_SKILLS } from "../../../../../../redux/constants/actionTypes";
import { withTranslation } from "react-i18next";

class EditSkills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skillIds: [],
      skillContent: this.props.skillContent,

      deletedIds: [],
      error: false,
      message: "",
    };

    // this.prevContent = this.props.skillContent

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  setSkillIds() {
    let skillIds = [];
    this.props.skillContent.map((item) => {
      skillIds.push(item.id);
    });

    let csvIds = "";
    skillIds.map((item) => {
      csvIds += item + ",";
    });

    this.setState({ skillIds: skillIds, csvSkillIds: csvIds });
  }

  getRandomNumber() {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100);
  }

  componentDidMount() {
    this.setSkillIds();
    if(this.props.modelSkill == null){
      this.props.fetchModelSkills(GET_MODEL_SKILLS)
    }
  }

  onClickDelete(id) {
    let updatedDeletedIds = [...this.state.deletedIds, id];

    let updatedSkillContent = this.state.skillContent.filter((item) => !updatedDeletedIds.includes(item.id));

    let updatedSkillIds = this.state.skillIds.filter((item) => item != id);

    this.setState({ skillContent: updatedSkillContent, deletedIds: updatedDeletedIds, skillIds: updatedSkillIds });
  }

  ModalHeaderOnClick() {
    this.props.changeEdit();
    document.body.style.overflow = "visible";
  }

  getAllAndConvertToCSV() {
    if (this.state.skillIds.length == 0) {
      return "";
    }

    let csvIds = "";
    this.state.skillIds.map((item) => {
      csvIds += item + ",";
    });
    return csvIds.slice(0, csvIds.length - 1);
  }

  SaveButtonOnClick() {
    if (this.state.deletedIds.length == 0) {
      document.body.style.overflow = "visible";
      return;
    }

    const data = { skills: this.getAllAndConvertToCSV() };
    
    this.props.updateSkills(UPDATE_SKILLS, data, this.state.skillIds, "del").then((res) => {
      if (res.val) {
        this.props.changeEdit();
        document.body.style.overflow = "visible";
        return;
      }
      this.setState({ error: true, message: res.message });
    });
  }

  ScrollBody() {
    const {t} = this.props;
    return (
      <div
        id={styles.scroll_div}
        style={{
          maxHeight: 400,
          overflow: "scroll",
          padding: 3,
        }}
      >
        {this.state.skillContent.map((item, idx) => {
          return (
            <div key={idx} style={{ margin: 5, flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  marginBottom: 20,
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={item.upload_img} height="60px" width="60px" style={{ borderRadius: 40, marginRight: 10 }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <p className={styles.section_title}>{item.title}</p>
                      <p className={styles.followers}>{this.getRandomNumber()} {t("common.followers")}</p>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img
                    src="/Image/apps/profile-resume-edit-view/delete.svg"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => this.onClickDelete(item.id)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.modalTitle} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    skillContent: state.profile?.skills || [],
    modelSkill: state.profile?.modelSkills || null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateSkills: (action, data, arrData, type) => dispatch(updateSkills(action, data, arrData, type)),
    fetchModelSkills: (action) => dispatch(fetchModelSkills(action))
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditSkills));
