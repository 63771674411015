import { GET_DROPDOWN_CATEGORIES_API, GET_OPPORTUNITIES_FILTER_RESULT_API } from "../constants/apis";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { GET_DROPDOWN_CATEGORIES, GET_OPPORTUNITIES_FILTER_RESULT } from "../constants/actionTypes";

export const getDropdownCategories = () => {
    const token = store.getState()?.profile?.user?.token;
   
    return async (dispatch) => {
      await axios
        .get(`${GET_DROPDOWN_CATEGORIES_API}`, {
          headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (
            response?.status >= 200 &&
            response?.status < 300 &&
            response?.data?.status == "1"
          ) {
            console.log("before cat", response?.data?.data)
            dispatch({ type: GET_DROPDOWN_CATEGORIES, payload: response?.data?.data });
          }
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    };
  };


export const getOpportunitiesFilterResult = (data) => {
    const token = store.getState()?.profile?.user?.token;

    console.log("filterPayload", data)
   
    return async (dispatch) => {
        await axios({
            method: "post",
            url: `${GET_OPPORTUNITIES_FILTER_RESULT_API}`,
            data: data,
            headers: {
              Authorization: `Token ${token}`,
            },
          })
        .then((response) => {
          if (
            response?.status >= 200 &&
            response?.status < 300 &&
            response?.data?.status == "1"
          ) {
            console.log("before filter", response?.data?.data)
            dispatch({ type: GET_OPPORTUNITIES_FILTER_RESULT, payload: response?.data?.data });
          }
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          dispatch({ type: GET_OPPORTUNITIES_FILTER_RESULT, payload: [] });
          return error;
        });
    };
  };