import React from 'react'
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer  } from 'recharts'

const da2ta = [{
  "name": "React",
  "Assignments": 5,
  "Quizzzes": 20,
},
{
  "name": "Java",
  "Assignments": 15,
  "Quizzzes": 20,
},
{
  "name": "Python",
  "Assignments": 17,
  "Quizzzes": 10,
},
{
  "name": "C++",
  "Assignments": 12,
  "Quizzzes": 14,
},
{
  "name": "Django",
  "Assignments": 7,
  "Quizzzes": 18,
},
{
  "name": "Vue",
  "Assignments": 14,
  "Quizzzes": 8,
},
{
  "name": "Javascript",
  "Assignments": 9,
  "Quizzzes": 25,
}]

const BarChartComponent = ({data}) => {
  if(data?.length < 1){
    return (
      <div>
          <img
            // className={styles.no_data_image}
            src="/Image/EnterpriseDashboard/empty-chart-state.svg"
          />
        </div>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      {console.log("insideBar", data)}
    <BarChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="assignments" fill="#3A4DE9" activeBar={<Rectangle fill="#3A4DE9" stroke="black" />} />
      <Bar dataKey="quizzes" fill="#F8BAC8" activeBar={<Rectangle fill="#F8BAC8" stroke="black" />} />
    </BarChart>
  </ResponsiveContainer>
  )
}

export default BarChartComponent