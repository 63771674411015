import { React, Component } from "react";
import Home from "./Home";
import "./CSSFiles/Dashboard.css";
import MobileBottom from "./MobileBottom";
import "./CSSFiles/Dashindex.css";
import IsAuthenticated from "../../../components/IsAuthenticated";

class Dashboard extends Component {
  render() {
    return (
      <div className="dashapp app">
        <style>
          {`* {
            margin: 0;
          }
          
          body{
            font-size: small;
          }

          .notifications_Dashboard_Dropdown{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
          }
          
          `}
        </style>
        <IsAuthenticated />
        <div>
          <Home toggleChatList={this.props.toggleChatList} isChatListOpen={this.props.isChatListOpen} />
        </div>
        <MobileBottom />
      </div>
    );
  }
}

export default Dashboard;
