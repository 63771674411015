import React, { createContext, Component } from "react";
// import PostSampleImg2 from './Image/DashboardImages/DashCommonImages/postSampleImg2.png';
import { connect } from "react-redux";
import axios from "axios";
import { handleErrorMessage } from "../../../redux/actions/classes";
import { handleSuccessMessage } from "../../../redux/actions/success";
import { CircularProgress } from "@mui/material";

export const FeedsContext = createContext();

class FeedsProviderComponent extends Component {
  state = {
    posts: [],
    class_posts: [],
    group_posts: [],
    nextLink: null,
    prevLink: null,
    loading: true,
    firstLoad: true,
  };

  getPosts = (getUrl, type = "") => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    try {
      axios({
        method: "get",
        url: getUrl,
        headers: {
          Authorization: `Token ${parsedUserData.token}`,
        },
      }).then((response) => {
        const freshPost = response.data.data.filter((obj) =>
          obj.post_type.includes("DASHBOARD")
        );
        this.setState({ loading: false });
        if (type == "post") {
          this.setState({ posts: [...freshPost] });
        } else {
          this.setState({ posts: [...this.state.posts, ...freshPost] });
        }
        this.setState({ nextLink: response.data.next });
        this.setState({ prevLink: response.data.previous });
        this.setState({ firstLoad: false });
      });
    } catch (error) {
      console.error(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };

  componentDidMount() {
    this.getPosts(`${process.env.REACT_APP_API_BASE_URL}api/v1/post/`);
  }
  makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  addPost = (post) => {
    this.setState({ firstLoad: true });
    let p = this.state.posts;
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);
    var currentdate = new Date();
    var datetime =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();

    const data = new FormData();
    data.append("share_link", this.makeid(10));

    if (post.visibility == "Anyone") {
      data.append("visibility_type", "PUBLIC");
    } else if (post.visibility == "Connection Only") {
      data.append("visibility_type", "CONNECTIONS");
    } else {
      data.append("visibility_type", "MYSELF");
    }
    data.append("post_type", post.type);
    data.append("date_and_time", datetime);
    data.append("post_owner_profile", parsedUserData.profile_id);
    data.append("description", post.message);
    if (post.photoUrl != null) {
      data.append("image_url", post.photoUrl, post.photoUrl.name);
    } else if (post.videoUrl != null) {
      data.append("video_url", post.videoUrl, post.videoUrl.name);
    }
    if (post.mediaFileUrl != null) {
      data.append("file_url", post.mediaFileUrl, post.mediaFileUrl.name);
    }
    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      }).then((response) => {
        if (response.data.status) {
          this.getPosts(
            `${process.env.REACT_APP_API_BASE_URL}api/v1/post/`,
            "post"
          );
        } else {
          alert("Alert Response: " + response.data.message);
        }
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  deletePost = (id) => {
    const token = localStorage.getItem("token");
    try {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/${id}/`,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      }).then(() => {
        const oldPosts = [...this.state.posts];
        const index = oldPosts.findIndex(function (oldPost) {
          return oldPost.id === id;
        });
        if (index !== -1) oldPosts.splice(index, 1);
        this.setState({ posts: oldPosts });
        this.props.handleSuccessMessage("Deleted successfully");
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  editPost = (post) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);

    const data = new FormData();
    if (post.message != null) {
      data.append("description", post.message);
    }
    if (post.photoUrl != null && post.onImageEdit == true) {
      data.append("image_url", post.photoUrl);
    }
    if (post.videoUrl != null && post.onVideoEdit == true) {
      data.append("video_url", post.videoUrl);
    }
    if (post.mediaFileUrl != null && post.onMediaFileEdit == true) {
      data.append("file_url", post.mediaFileUrl);
    }

    try {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/${post.id}/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      }).then((response) => {
        if (response.data.status=="1" && response.status>=200 && response.status<300) {
          const oldPosts = [...this.state.posts];
          const index = oldPosts.findIndex(function (oldPost) {
            return oldPost.id === post.id;
         });
         const updatedPost = {...oldPosts[index], ...response.data.data}
         oldPosts[index] = updatedPost
         this.setState({posts: oldPosts})
          this.props.handleSuccessMessage("Updated successfully")
        } else {
          alert("Alert Response: " + response.data.message);
        }
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  onPaginatedSearch = () => {
    if (this.state.nextLink != null) {
      this.setState({ loading: true });
      this.getPosts(this.state.nextLink);
    }
  };

  render() {
    let contextData = {
      state: this.state,
      addPost: this.addPost,
      editPost: this.editPost,
      deletePost: this.deletePost,
      getPost: this.getPosts,
      onPaginatedSearch: this.onPaginatedSearch,
    };
    return (
      <FeedsContext.Provider value={contextData}>
        {this.props.children}
      </FeedsContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "",
    // class_posts: state?.classes?.posts,
    // group_posts: state?.groups?.posts,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
    handleSuccessMessage: (successMessage) =>
      dispatch(handleSuccessMessage(successMessage)),
  };
}
export const FeedsProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedsProviderComponent);
