import React from "react";
import styles from "./WelcomeToCourseModal.module.css";
import { useTranslation } from "react-i18next";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import { useHistory, Link } from "react-router-dom";
import { Button as ButtonMUI, CircularProgress } from '@mui/material';
import { useDispatch } from "react-redux";

// expected props: "Welcome: true/false"
// courseID and courseName in case of Welcome modal
// "congrats: true" for Congrats modal

const WelcomeToCourseModal = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();


  const redirect = () => {
    if (props.welcome) {
      history.push(`/course/stream/${props.courseID}`);
    } else {
      history.push("/user");
    }
  };


  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    fontWeight: 700,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#ED1E24",
      borderColor: "#ED1E24",
      color: "#fff",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    '@media (max-width: 500px)': {
      height: "20px",
      padding: "1rem",
    },
  };


  return (
    <div className={styles.main}>
      <img
        src={`/Image/lecturePlan/${
          props.welcome ? "Welcome-illustration.svg" : "Congratulations.png"
        }`}
        className={styles.illustrations}
      />

      {props.welcome && (
        <p className={styles.description}>
          {t("courseMonetisation.welcomeTo")} - {props.courseName ?? ""}.<br />
          {t("courseMonetisation.accessNow")}
        </p>
      )}

      {props.congrats && (
        <>
          <span className={styles.heading}>{t("common.congrats")}</span>
          <div className={styles.description2}>
            {t("courseMonetisation.successfullyCompleted")}
          </div>
        </>
      )}

      <div className={styles.btn_div}>
        {props.congrats && <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={props.onDownload}
          sx={styleButtonMUI}
          fullWidth
          startIcon={
            props.isLoadingPDF ? <CircularProgress sx={{ color: "#FFF" }} size={20} /> :
            <img style={{width: '1.5rem'}} src="/Image/CourseMonetisation/download-white.svg" />
          }
        >
          {t("courseMonetisation.certificate")}
        </ButtonMUI>}

        <Link
          to={props.welcome ? `/course/stream/${props.courseID}` : `/user`}
          target={`_blank`}
          className={styles.link}
        >
          <ButtonMUI
            variant="outlined"
            disableRipple
            sx={styleButtonMUI}
            onClick={props.closeModal}
          >
            {props?.welcome
              ? t("courseMonetisation.startLearning")
              : t("common.continue")}
          </ButtonMUI>
        </Link>
      </div>
    </div>
  );
};

export default WelcomeToCourseModal;
