import React from 'react'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import styles from './css/ClassTag.module.css'
import { useTranslation } from 'react-i18next';

const ClassTag = (props) => {
  // expected props - courseClasses array, and curbList as true if you want to show only one chip and rest as +more
  const { t } = useTranslation();
  return (
    props?.curbList ?
         <Stack direction="row" spacing={1} style={{flexWrap: "wrap",marginBottom: "5px",}}>
            {props.courseClasses.map((item, index)=>(
              index < 1 &&
              (<Chip label={item} variant="outlined" style={{
                border: "1px solid #ED1E24",
                color: "#ED1E24",
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "12px",
                marginBottom: "5px",
              }}  />)
            ))} 
            { (props.courseClasses?.length - 1) > 0 && <span className={styles.more_text}>+ {props.courseClasses?.length - 1} {t("courseMonetisation.more")}</span>}
         </Stack> 
         :
         <Stack direction="row" spacing={1} style={{flexWrap: "wrap",marginBottom: "5px",}}>
            {props.courseClasses.map((item, index)=>(
              (<Chip label={item} variant="outlined" style={{
                border: "1px solid #ED1E24",
                color: "#ED1E24",
                fontWeight: "600",
                fontFamily: "Montserrat",
                fontSize: "12px",
                marginBottom: "5px",
              }}  />)
            ))} 
         </Stack>
  )
}

export default ClassTag;