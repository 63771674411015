import { React, useEffect } from "react";
import styles from "./CSSFiles/RightSidebar.module.css";
import IconTitle from "../../../components/commons/IconTitle";
import DashboardRightFeedInnerComp from "../../../components/commons/DashboardRightFeedInnerComp";
import ConnectionRightFeedComp from "../../../components/commons/ConnectionRightFeedComp";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { thumbnailList } from "../../../redux/constants/commonFunctions";
import { getAllCourses, clearCourses } from "../../../redux/actions/courses";

function RightSidebar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const courseContent = [
  //   {
  //     img: "/Image/DashImage/course1.png",
  //     href: "https://www.udemy.com/course/high-school-math-pure-mathematics-1/",
  //     content: t("course.pureMathOne"),
  //   },

  //   {
  //     img: "/Image/DashImage/course2.png",
  //     href: "https://www.udemy.com/course/algebra-1-semester-1-a-high-school-curriculum/",
  //     content: t("course.algebraOne"),
  //   },

  //   {
  //     img: "/Image/DashImage/course3.png",
  //     href: "https://www.udemy.com/course/physics-modern-physics/",
  //     content: t("course.modernPhysics"),
  //   },

  //   {
  //     img: "/Image/DashImage/course4.png",
  //     href: "https://www.udemy.com/course/gravity-the-basics/",
  //     content: t("course.basicGravity"),
  //   },

  //   {
  //     img: "/Image/DashImage/course5.png",
  //     href: "https://www.udemy.com/course/physics-electricity/",
  //     content: t("course.highSchoolElectricity"),
  //   },
  // ];

  const publicCourses =
    useSelector((state) => state?.courses?.publicCourses) || [];
  const isEnterprise = useSelector((state) => state?.profile?.user?.user_type);

  useEffect(() => {
    dispatch(clearCourses());
    dispatch(getAllCourses(null, true, null, null, null, null, 5));
    return () => {
      dispatch(clearCourses());
    };
  }, []);

  const newsContent = [
    {
      thumbnail: "/Image/DashImage/news1.jpg",
      href: "https://m.timesofindia.com/city/thiruvananthapuram/educational-institutions-in-kerala-to-reopen-in-phases-from-october-4/articleshow/86025821.cms/",
      content: t("newsFeed.educationKerela"),
    },

    {
      thumbnail: "/Image/DashImage/news2.jpg",
      href: "https://www.thehindu.com/news/national/shikshak-parv-pm-modi-launches-initiatives-in-education-sector/article36332567.ece",
      content: t("newsFeed.shikshakParv"),
    },

    {
      thumbnail: "/Image/DashImage/news3.jpg",
      href: "https://indianexpress.com/article/education/top-10-colleges-in-india-nirf-ranking-2021-delhi-university-miranda-house-loyola-lsr-7500559/",
      content: t("newsFeed.nirfRanking"),
    },

    {
      thumbnail: "/Image/DashImage/news4.jpg",
      href: "https://www.news18.com/news/education-career/education-minister-on-neet-postponement-demand-rahul-gandhi-a-pseudo-expert-with-misplaced-sense-of-entitlement-4187519.html",
      content: t("newsFeed.neetPostpone"),
    },

    {
      thumbnail: "/Image/DashImage/news5.png",
      href: "https://www.indiatvnews.com/education/news-schools-reopening-students-well-being-covid-india-road-ahead-732923",
      content: t("newsFeed.reopeningSchool"),
    },
  ];

  return (
    <div className={styles.RightSidebar}>
      <IconTitle
        title={t("dashboard.newsFeedHead")}
        img="/Image/DashImage/Chalkmate_News.svg"
      />
      <DashboardRightFeedInnerComp content={newsContent} />

      <br />

      {isEnterprise === "ENTERPRISE" && (
        <>
          <IconTitle
            title={t("profile.Dashboard")}
            img="/Image/EnterpriseDashboard/Dashboard.svg"
            />
          <DashboardRightFeedInnerComp isEnterprise={true} />

          <br />
        </>
      )}

      <IconTitle
        title={t("common.courses")}
        img="/Image/DashImage/Chalkmate_Courses.svg"
      />
      <DashboardRightFeedInnerComp content={publicCourses} isCourse={true} />

      <br />

      <IconTitle
        title={t("dashboard.myConnections")}
        img="/Image/DashImage/My_Connections.svg"
      />
      <ConnectionRightFeedComp />
    </div>
  );
}

export default RightSidebar;
