import React from "react";
import styles from "./css/CourseLength.module.css";
import { useTranslation } from "react-i18next";

const CourseLength = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.main}>
      <img src="/Image/lecturePlan/info.svg" alt="course length" />
      <span>
        {props.courseLength < 60
          ? `${Math.floor(props.courseLength)}m`
          : `${Math.floor(props.courseLength / 60)}h ${Math.floor(
              props.courseLength % 60
            )}m`}
        &nbsp;&#x2022;&nbsp;
      </span>

      <span>
        {props.lectureCount > 1
          ? `${props.lectureCount} ${t("common.lectures")}`
          : `${props.lectureCount} ${t("common.lecture")}`}
      </span>
    </div>
  );
};

export default CourseLength;
