import React, { useState } from "react";
import styles from "./GradebookTabComponent.module.css";
import {
  CircularProgress,
  Tab,
  Tabs,
  TabPanel,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { formatDate } from "../../../redux/constants/commonFunctions";
import { useHistory } from "react-router-dom";

const GradebookTabComponent = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const assignmentList = props.result?.AssignmentList;
  const quizList = props.result?.QuizList;

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const [tabValue, setTabValue] = useState(0);

  const redirectAsgQuiz = (asgQuiz, type) => {
    history.push({
      pathname:`/gradebook/${type}`,
      state: {
        profileData: props.profileData,
        asgQuiz: asgQuiz,
        classOrGroupName: props.classOrGroupName,
      }
    })
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              p: 0,
              "@media (max-width: 500px)": {
                // p: 2,
              },
            }}
          >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const styleTableCell = {
    color: "#000",
    fontFamily: "Montserrat",
    // fontSize: "1rem",
    fontSize: {
      xs: ".875rem",
      sm: ".875rem",
      md: "1rem"
    },
    fontWeight: 400,
    borderRight: "1px solid #E5E5E5",
    padding: {
      xs: ".875rem",
      sm: ".875rem",
      md: "1rem"
    },
    
  };

  const styleTableHeader = {
    backgroundColor: "#f6f6f6",
    fontFamily: "Montserrat",
    color: "#7b7b7b",
    // fontSize: "1rem",
    fontSize: {
      xs: ".875rem",
      sm: ".875rem",
      md: "1rem"
    },
    fontWeight: 400,
    textAlign: "center",
    borderRight: "1px solid #E5E5E5",
    padding: {
      xs: ".875rem",
      sm: ".875rem",
      md: "1rem"
    },
  };

  const styleTable = {
    "& .MuiTableRow-root:hover": {
      backgroundColor: "#fecfd1"
    },
    "cursor":"pointer",
  }

  const styleTableFooter = {
    fontFamily: "Montserrat",
    color: "#000",
    fontWeight: 600,
    textAlign: "center",
    borderBottom: "none",
    fontSize: {
      xs: "1rem",
      sm: "1rem",
      md: "1.125rem"
    },
    cursor: "auto",
  }

  return (
    <div className={styles.main}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="secondary tabs example"
        TabIndicatorProps={{
          sx: {
            backgroundColor: "#F87377",
          },
        }}
        sx={{
          "& button.Mui-selected": {
            color: "#F87377",
          },
          "& button:focus": {
            color: "#F87377",
          },
          "& button.Mui-disabled": {
            backgroundColor: "#f6f6f6",
            color: "#959595",
            borderColor: "#f6f6f6",
          },
        }}
      >
        <Tab label={t("template.profile")} />
        <Tab label={t("assignment.assignment")} />
        <Tab label={t("quiz.quiz")} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <p className={styles.label_title}>{t("common.personalDetails")}</p>
        <p className={styles.label}>
          {`${t("common.name")}`}: <span>{props?.profileData?.name}</span>
        </p>
        <p className={styles.label}>
          {`${t("class.class")}`}: <span>{props?.classOrGroupName}</span>
        </p>
        <p className={styles.label}>
          {`${t("common.email")}`}: <span className={styles.email}>{props?.profileData?.UserObj.email}</span>
        </p>
      </TabPanel>
      
      <TabPanel value={tabValue} index={1}>
        <TableContainer>
          <Table stickyHeader sx={styleTable}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("common.sno")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("quiz.quizTitle")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("gradebook.submissionDate")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("assignment.maxMarks")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("gradebook.receivedMarks")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("gradebook.grade")}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {assignmentList?.length > 0 &&
                assignmentList?.map((asg, index) => (
                  <TableRow key={asg.id} onClick={
                    ()=>redirectAsgQuiz(asg, "assignment")
                    }>
                    <TableCell
                      sx={{
                        backgroundColor: "#f6f6f6",
                        fontFamily: "Montserrat",
                        color: "#7b7b7b",
                        borderRight: "#cdcdcd",
                        textAlign:"center",
                        fontSize: {
                          xs: ".875rem",
                          sm: ".875rem",
                          md: "1rem"
                        },
                        padding: {
                          xs: ".875rem",
                          sm: ".875rem",
                          md: "1rem"
                        },

                      }}
                    >
                      {index + 1}.
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell }}>
                      {asg.assignment.title}
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell }}>
                      {asg?.is_submitted
                        ? formatDate(asg?.submission_date)
                        : "—"}
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell, textAlign:"center" }}>
                      {asg.assignment.max_marks}
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell, textAlign:"center" }}>
                      {asg.marks ?? "—"}
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell, textAlign:"center" }}>
                      {asg?.marks != null ? `${asg?.percentage}%` : "—"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell colSpan={3} sx={styleTableFooter}>{t("common.total")}</TableCell>
                <TableCell sx={styleTableFooter}>{props.result?.TotalAsgMarks}</TableCell>
                <TableCell sx={styleTableFooter}>{props.result?.ReceivedAsgMarks}</TableCell>
                <TableCell sx={styleTableFooter}>{props.result?.AvgAsgScore}%</TableCell>                
              </TableRow>
            </TableFooter>

          </Table>
        </TableContainer>
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <TableContainer>
          <Table stickyHeader sx={styleTable}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("common.sno")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("quiz.quizTitle")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("gradebook.submissionDate")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("assignment.maxMarks")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("gradebook.receivedMarks")}
                </TableCell>
                <TableCell
                  sx={{
                    ...styleTableHeader,
                  }}
                >
                  {t("gradebook.grade")}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {quizList?.length > 0 &&
                quizList?.map((quiz, index) => (
                  <TableRow key={quiz.id} onClick={()=>redirectAsgQuiz(quiz, "quiz")}>
                    <TableCell
                      sx={{
                        backgroundColor: "#f6f6f6",
                        fontFamily: "Montserrat",
                        color: "#7b7b7b",
                        borderRight: "#cdcdcd",
                        textAlign:"center",
                        fontSize: {
                          xs: ".875rem",
                          sm: ".875rem",
                          md: "1rem"
                        },
                        padding: {
                          xs: ".875rem",
                          sm: ".875rem",
                          md: "1rem"
                        },
                      }}
                    >
                      {index + 1}.
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell }}>
                      {quiz.quiz.title}
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell }}>
                      {quiz.is_submitted
                        ? formatDate(quiz?.submission_date)
                        : "—"}
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell, textAlign:"center" }}>
                      {quiz.quiz.max_marks}
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell, textAlign:"center" }}>
                      {quiz.marks ?? "—"}
                    </TableCell>
                    <TableCell sx={{ ...styleTableCell, textAlign:"center" }}>
                      {quiz.marks != null ? `${quiz?.percentage}%` : "—"}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TableCell colSpan={3} sx={styleTableFooter}>{t("common.total")}</TableCell>
                <TableCell sx={styleTableFooter}>{props.result?.TotalQuizMarks}</TableCell>
                <TableCell sx={styleTableFooter}>{props.result?.ReceievedQuizMarks}</TableCell>
                <TableCell sx={styleTableFooter}>{props.result?.AvgQuizScore}%</TableCell>                
              </TableRow>
            </TableFooter>

          </Table>
        </TableContainer>
      </TabPanel>
    </div>
  );
};

export default GradebookTabComponent;
