import React from "react";
import styles from "../css/ConfirmSubmissionModal.module.css";
import ButtonMUI from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const ConfirmSubmissionModal = (props) => {
  const { t } = useTranslation();

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    // marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
  };

  return (
    <div className={styles.main}>
      <img src={props.modalData.img} />
      <span className={styles.main_text}>
        {props.modalData.mainText}
      </span>
      <span className={styles.sub_text}>
        {props.modalData?.subText}
      </span>
      <div className={styles.btn_div}>
        <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={props.modalData.btn1Handler}
          sx={styleButtonMUI}
        >
          <span className={styles.btn_label}>
            {props.modalData?.btn1Text}
          </span>
        </ButtonMUI>
        <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={props.modalData.btn2Handler}
          sx={styleButtonMUI}
        >
          <span className={styles.btn_label}>{props.modalData?.btn2Text}</span>
        </ButtonMUI>
      </div>
    </div>
  );
};

export default ConfirmSubmissionModal;
