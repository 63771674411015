import { React, Component } from "react";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import { connect } from "react-redux";
import ClassGroupComponent from "../../ClassesGroups/CreateClass/ClassGroupComponent";
import { getClassDetails, getClasses } from "../../../redux/actions/classes";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { getAllAssignments } from "../../../redux/actions/assignment";
import { getAllCourses, clearCourses} from "../../../redux/actions/courses";
import { getAllQuizTemplates, getAllQuizzes  } from "../../../redux/actions/enhancedQuiz";

class Class extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      activeSection: "Post",
      subjectName: "",
      showCareerModal: false,
      showShareModal: false,
      showCourseModal: false,
      samplePosts: [
        {
          id: -1,
          name: "Himani",
          follower: "234",
          timestamp: "2 hrs",
          likes: "38",
          comments: null,
          profilePic: "/Image/Classes/himani_dp.jpg",
          likedPost: null,
          message: t("samplePosts.onlineLearningHelpStudents"),
          photoUrl: "/Image/Classes/classPost1.png",
          share_link: "2342",
        },
        {
          id: 0,
          name: "Harsh",
          follower: "853",
          likes: "47",
          comments: null,
          profilePic: "/Image/Classes/shivam_dp.jpg",
          likedPost: null,
          message: t("samplePosts.onlineEnvironment"),
          photoUrl: "/Image/Classes/classPost2.png",
          share_link: "8534",
        },
      ],
    };
  }
  getActiveClassDetails = (subjectId) => {
    let { history } = this.props;
    history.push({
      pathname: `/class/${subjectId}`,
      state: { subjectId: subjectId },
    });
    this.props.getClasses();
    this.props.getClassDetails(subjectId).then(() => {
      this.setState({ subjectId: this.props.subjectId });
      this.setState({ subjectName: this.props.subjectName });
    });
    this.props.getAllAssignments(subjectId, window.location?.pathname?.split('/')[1]);
    this.props.getAllQuizzes(subjectId, window.location?.pathname?.split('/')[1]);  //enable it later
    this.props.getAllQuizTemplates();
    // this.props.clearCourses().then(()=>this.props.getAllCourses(subjectId, null, null, null, true));
  };
  componentDidMount = () => {
    const subjectId = this.props.history.location.pathname.split("/")[2] || "";
    this.getActiveClassDetails(subjectId);
  };
  render() {
    return (
      <ClassGroupComponent
        subjectName={this.state.subjectName}
        class_id={this.props.history.location.pathname.split("/")[2]}
        subjectCode={this.props.subjectCode}
        routeType={this.props.routeType}
        imageCover={this.props.imageCover}
        toggleChatList={this.props.toggleChatList}
        subject={this.props.subject}
        // numOfPosts={this.props.numOfPosts}
        grade={this.props.grade}
        isChatListOpen={this.props.isChatListOpen}
        samplePosts={this.state.samplePosts}
        members={this.props.members}
        myCourses={this.props.myCourses}
        getActiveClassDetails={this.getActiveClassDetails}
        myClasses={this.props.myClasses}
        userName={this.props?.user}
        meetings={this.props.meetings}
        activeSectionState={this.props?.location?.state?.activeSection}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state?.profile?.name,
    //imageCover: "/Image/DashImage/class-cover.png",
    subjectName: state?.classes?.class?.name || "",
    subjectCode: state?.classes?.class?.code || "",
    grade: state?.classes?.class?.grade || "",
    // numOfPosts: state?.classes?.posts.length,
    class_id: state?.classes?.class?.id || "",
    subject: state?.classes?.class?.subject || "",
    routeType: "Class",
    imageCover:
      state?.classes?.class?.cover_image || "/Image/DashImage/class-cover.png",
    myClasses:
      state?.classes?.classes?.map((each_class) => {
        return { name: each_class.name, id: each_class.id, owner_name: each_class.class_owner_name };
      }) || [],
    myCourses: [...state?.classes?.courses] || [],
    members: state?.classes?.members,
    meetings: state?.classes?.meetings || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClassDetails: (subjectName) => dispatch(getClassDetails(subjectName)),
    getClasses: () => dispatch(getClasses()),
    getAllAssignments: (id, type) => dispatch(getAllAssignments(id, type)),
    getAllQuizzes: (id, type) => dispatch(getAllQuizzes(id,type)),
    getAllQuizTemplates: () => dispatch(getAllQuizTemplates()),
    clearCourses: () => dispatch(clearCourses()),
    getAllCourses: (classId,isPublic,isEnrolled,isFaculty,isFeed) => dispatch(getAllCourses(classId,isPublic,isEnrolled,isFaculty,isFeed)),
    
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Class))
);
