import React, { Component, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { commentLike, commentLikeDelete } from "../../../redux/actions/dashboard";
import styles from "./CSSFiles/comment.module.css";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";

function Comment(props) {
  const {
    key,
    photo,
    name,
    commentdata,
    id,
    likedComment,
    user,
    commentLike,
    commentLikeDelete,
    newCommentLike,
  } = props;

  const newRefCommentLike = useRef(newCommentLike);
  newRefCommentLike.current = newCommentLike;
  const [likes, setLikes] = useState(0);
  const [likeButtonPressed, setLikeButtonPressed] = useState(false);
  const [likedId, setLikedId] = useState(null);
  const [likeClickable, setLikeClickable] = useState(true)
  let data = {
    commentId: id,
  };
  const { t } = useTranslation();

  useEffect(() => {
    if (likedComment != null) {
      // console.log("likedComment----", likedComment)

      setLikes(likedComment.length);
      if (
        likedComment.some(
          (likedComment) => likedComment.profile_liked_by == user.profile_id
        )
      ) {
        setLikeButtonPressed(true);
        likedComment.map((data, index) => {
          if (data.profile_liked_by == user.profile_id) {
            setLikedId(data.id);
          }
        });
      }
    }
    else {
      setLikes(0)
      setLikeButtonPressed(false)
      setLikedId(null)
    }
  }, [id])

  const liker = (currentLikes) => {
    setLikeClickable(false)
    if (!likeButtonPressed) {
      commentLike(data).then(() => {
        setLikedId(newRefCommentLike.current.id)
        props.updateAddLikedComment(id, newRefCommentLike.current)
        setLikeClickable(true)
      });
      setLikes(currentLikes + 1);
      setLikeButtonPressed(true);
    } else {
      commentLikeDelete(likedId).then(() => {
        setLikeClickable(true)
      });
      props.updateRemoveLikedComment(id)
      setLikes(currentLikes - 1)
      setLikeButtonPressed(false)
      setLikedId(null)
    }
  };

  return (
    <div className={styles.outerContainer}>
      <img
        src={photo}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
      <div className={styles.commentContainer}>
        <div className={styles.innerContainer}>
          <div className={styles.titleDiv}>
            <span className={styles.name}>{name}</span>
            {/* <span className={styles.second}>2nd</span> */}
            {/* <span className={styles.timestamp}>{this.props.timestamp}</span> */}
          </div>
          <p className={styles.message}>{commentdata}</p>
        </div>
        <div className={styles.likeDiv}>
          <span>
            {likeClickable ?
              <span onClick={() => liker(likes)} style={{ cursor: "pointer" }}>
                {t("common.like")} {" "}
              </span> :
               <span style={{ cursor: "not-allowed" }}>
               {t("common.like")} {" "}
             </span>}
            {likeButtonPressed ? (
              <img
                src="/Image/DashboardImages/DashCommonImages/Chalkmate_red_like.svg"
                height="12px"
              />
            ) : (
              <img
                src="/Image/DashboardImages/DashCommonImages/Chalkmate_like.svg"
                height="12px"
              />
            )}
            {/* <img
               src="./Image/DashboardImages/DashCommonImages/Chalkmate_red_like.svg"
               height="12px"
             />{" "} */}{" "}
            {likes}{" "}
          </span>
          {/* <div className={styles.bar} /> */}
          {/* <span>Reply</span> */}
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user,
    newCommentLike: state?.dashboard?.newCommentLike,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    commentLike: (data) => dispatch(commentLike(data)),
    commentLikeDelete: (data) => dispatch(commentLikeDelete(data)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Comment)
);
// photo,name,timestamp,commentdata
