import cloneDeep from "lodash.clonedeep";
import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  UPDATE_EXPERIENCE,
  UPDATE_EDUCATION,
  UPDATE_LICENSES,
  UPDATE_VOLUNTEER,
  ADD_VOLUNTEER,
  UPDATE_SKILLS,
  UPDATE_INTERESTS,
  UPDATE_ACHIEVEMENTS,
  ADD_EXPERIENCE,
  GET_MODEL_INTERESTS,
  GET_INTERESTS_VIEW,
  GET_EXPERIENCE_VIEW,
  GET_PROFILE_PIC,
  UPDATE_PROFILE_PIC,
  GET_TAGS,
  ADD_TAGS,
  GET_ABOUT,
  UPDATE_ABOUT,
  GET_PROFILE_DATA_EDIT,
  GET_PROFILE_DATA_VIEW,
  GET_MODEL_SKILLS,
  ADD_EDUCATION,
  ADD_LICENSES,
  ADD_ACHIEVEMENTS,
  GET_COVER_PIC,
  UPDATE_COVER_PIC,
  GET_PEOPLE_YOU_MAY_KNOW,
  GET_PEOPLE_YOU_MAY_KNOW_VIEW,
  EDIT_PROFILE_DATA,
  UPDATE_SOCIAL_LINKS,
  ADD_SOCIAL_LINKS,
  FETCH_SEARCH_RESULTS,
  SENT_CONNECTIONS,
  CONNECTION_INVITATION,
  ACCPET_CONNECTION,
  MY_CONNECTIONS,
  WITHDRAW_CONNECTION,
  REMOVE_CONNECTION,
  IGNORE_CONNECTION,
  SEND_CONNECTION,
  GET_CODE_SUGGESTIONS,
  SIGNUP_STATUS,
  SEARCH_PAGINATED_RESULT,
  BOOST_MY_POST,
  BOOST_MY_PROFILE,
  REMOVE_EXISTING_PAYMENT_ORDER,
  ADD_PUBLICATION,
  ADD_TRAINING,
  ADD_RESEARCH,
  ADD_PROJECT,
  UPDATE_PUBLICATION,
  UPDATE_TRAINING,
  UPDATE_RESEARCH,
  UPDATE_PROJECT,
} from "../constants/actionTypes";
import { produce } from "immer";

const defaultState = {
  user: null,
  error: "",
  serachResults: "",
  enterprise_codes: [],
  nextSearch: null,
  prevSearch: null,
  profileId: "",
  about: "",
  interests: [],
  education: [],
  experience: [],
  certification: [],
  volunteer: [],
  skills: [],
  achievement: [],
  modelInterests: [],
  modelSkills: [],
  tags: [],
  userProfilePic: "",
  userCoverPic: "",
  peopleYouMayKnow: [],
  address: "",
  dob: "",
  name: "",
  designation: "",
  location: "",
  organisation: "",
  connections: "",
  bookmarks: "",
  search_appearance: "",
  language: "",
  socialLinks: {},
  profileId: "",
  viewSentConnections: [],
  viewConnectionInvitation: [],
  // acceptConnection: [],
  myConnections: [],
  short_bio: "",
  deptCode: "",
  profile_completion_status: "",
  viewsProfile:"",
  // withdrawConnection: [],
  // removeConnection: [],
  // ignoreConnectionRequest: [],
  // sendConnectionRequest: [],

  aboutView: "",
  interestsView: [],
  experienceView: [],
  certificationView: [],
  volunteerView: [],
  skillsView: [],
  achievementView: [],
  userProfilePicView: "",
  tagsView: [],
  userCoverPicView: "",
  peopleYouMayKnowView: [],
  addressView: "",
  dobView: "",
  nameView: "",
  designationView: "",
  locationView: "",
  organisationView: "",
  connectionsView: "",
  bookmarksView: "",
  language_knownView: "",
  socialLinksView: {},
  profileIdView: "",
  // emailView:"",
  // emailView:"",
  user_email: "",
  connectionStateView: "",
  connection_id_view: "",
  short_bio_view: "",
  deptCodeView: "",
  profile_completion_status_view: "",
  viewsProfileView: "",
  boostPaymentOrder: null,
  publications: [],
  trainingAndConference: [],
  research: [],
  project: [],
  dept_id: null,
  open_to_work: null,
};
let userData = {};
let data = {};
let index = null;

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, user: {} };
    case LOGIN_SUCCESS:
      userData = {
        user: { ...state.user },
        error: { ...state.error },
      };
      userData.user = action.payload;
      userData.error = "";
      return userData;
    case LOGIN_FAILURE:
      userData = {
        user: { ...state.user },
        error: { ...state.error },
      };
      userData.user = {};
      userData.error = action.payload;
      return userData;

    case LOGOUT_REQUEST:
      userData = {
        user: {},
        error: "",
      };
      return userData;

    case SIGNUP_STATUS:
      userData = {
        user: { ...state.user },
        error: { ...state.error },
      };
      userData.status = action.payload;
      return userData;

    //profile
    case UPDATE_EXPERIENCE:
      return {
        ...state,
        experience: state.experience.map((item) => (action.payload.id === item.id ? action.payload : item)),
      };

    case GET_EXPERIENCE_VIEW:
      return {
        ...state,
        experienceView: action.payload,
      };

    case ADD_EXPERIENCE:
      return {
        ...state,
        experience: [action.payload, ...state.experience],
      };

    case UPDATE_EDUCATION:
      return {
        ...state,
        education: state.education.map((item) => (action.payload.id === item.id ? action.payload : item)),
      };

    case ADD_EDUCATION:
      return {
        ...state,
        education: [action.payload, ...state.education],
      };

    case ADD_LICENSES:
      return {
        ...state,
        certification: [action.payload, ...state.certification],
      };

    case UPDATE_LICENSES:
      return {
        ...state,
        certification: state.certification.map((item) => (action.payload.id === item.id ? action.payload : item)),
      };

    case ADD_EDUCATION:
      return {
        ...state,
        education: [action.payload, ...state.education],
      };

    case ADD_VOLUNTEER:
      return {
        ...state,
        volunteer: [action.payload, ...state.volunteer],
      };

    case UPDATE_VOLUNTEER:
      return {
        ...state,
        volunteer: state.volunteer.map((item) => (action.payload.id === item.id ? action.payload : item)),
      };

    case ADD_ACHIEVEMENTS:
      return {
        ...state,
        achievement: [action.payload, ...state.achievement],
      };

    case UPDATE_ACHIEVEMENTS:
      return {
        ...state,
        achievement: state.achievement.map((item) => (action.payload.id === item.id ? action.payload : item)),
      };

    case GET_MODEL_SKILLS:
      return {
        ...state,
        modelSkills: action.payload,
      };

    case UPDATE_SKILLS:
      return {
        ...state,
        skills: action.payload,
      };

    case GET_MODEL_INTERESTS:
      return {
        ...state,
        modelInterests: action.payload,
      };

    case UPDATE_INTERESTS:
      return {
        ...state,
        interests: action.payload,
      };

    case GET_INTERESTS_VIEW:
      return {
        ...state,
        interestsView: action.payload,
      };

    case UPDATE_ACHIEVEMENTS:
      userData = {
        ...state,
        achievements: action.payload,
      };
      return userData;

    case GET_TAGS:
      return {
        ...state,
        tagsView: action.payload,
      };
    case ADD_TAGS:
      userData = {
        ...state,
        tags: action.payload,
      };
      return userData;

    case GET_ABOUT:
      return {
        ...state,
        about: action.payload,
      };

    case UPDATE_ABOUT:
      userData = {
        ...state,
        about: action.payload,
      };
      return userData;

    case GET_PROFILE_PIC:
      return {
        ...state,
        userProfilePic: action.payload,
      };

    case UPDATE_PROFILE_PIC:
      userData = {
        ...state,
        userProfilePic: action.payload,
      };
      return userData;

    case UPDATE_SOCIAL_LINKS:
      return {
        ...state,
        socialLinks: action.payload,
      };
    case ADD_SOCIAL_LINKS:
      return {
        ...state,
        socialLinks: action.payload,
      };

    case GET_PROFILE_DATA_EDIT:
      return {
        ...state,
        profileId: action.payload.id,
        about: action.payload.about,
        interests: action.payload.interests,
        education: action.payload.education,
        experience: action.payload.experience,
        certification: action.payload.certification,
        volunteer: action.payload.volunteer,
        skills: action.payload.skills,
        achievement: action.payload.achievement,
        tags: action.payload.tags,
        language: action.payload.language_known,
        userCoverPic: action.payload.cover_pic,
        userProfilePic: action.payload.profile_pic,
        address: action.payload.address,
        dob: action.payload.dob,
        name: action.payload.name,
        designation: action.payload.designation,
        location: action.payload.location,
        organisation: action.payload.organisation,
        connections: action.payload.connections,
        bookmarks: action.payload.bookmarks,
        socialLinks: action.payload.social_links,
        short_bio: action.payload.short_bio,
        deptCode: action.payload.dept_code,
        profile_completion_status: action.payload.profile_completion_status,
        viewsProfile: action.payload.views,
        publications: action.payload.publications,
        trainingAndConference: action.payload.trainingandconference,
        research: action.payload.research,
        project: action.payload.project,
        publications_visibility: action.payload.publications_visibility,
        research_visibility: action.payload.research_visibility,
        project_visibility: action.payload.project_visibility,
        trainingAndconference_visibility: action.payload.trainingAndconference_visibility,
        dept_code: action.payload.dept_code,
        dept_id: action.payload.dept_id,
        user_category: action.payload.user_category,
        open_to_work: action.payload.open_to_work        
      };

    case GET_PROFILE_DATA_VIEW:
      return {
        ...state,
        profileIdView: action.payload.id,
        aboutView: action.payload.about,
        interestsView: action.payload.interests,
        educationView: action.payload.education,
        experienceView: action.payload.experience,
        certificationView: action.payload.certification,
        volunteerView: action.payload.volunteer,
        skillsView: action.payload.skills,
        achievementView: action.payload.achievement,
        tagsView: action.payload.tags,
        language_knownView: action.payload.language_known,
        userCoverPicView: action.payload.cover_pic,
        userProfilePicView: action.payload.profile_pic,
        addressView: action.payload.address,
        dobView: action.payload.dob,
        nameView: action.payload.name,
        designationView: action.payload.designation,
        locationView: action.payload.location,
        organisationView: action.payload.organisation,
        connectionsView: action.payload.connections,
        bookmarksView: action.payload.bookmarks,
        socialLinksView: action.payload.social_links,
        profileIdView: action.payload.id,
        // emailView: action.payload.email,
        // emailView: action.payload.email,
        user_email: action.payload.user_email,
        connectionStateView: action.payload.connection_state,
        connection_id_view: action.payload.connection_id,
        short_bio_view: action.payload.short_bio,
        deptCodeView: action.payload.dept_code,
        profile_completion_status_view: action.payload.profile_completion_status,
        viewsProfileView: action.payload.views,
        publicationsView: action.payload.publications,
        trainingAndConferenceView: action.payload.trainingandconference,
        researchView: action.payload.research,
        projectView: action.payload.project,
        publications_visibilityView: action.payload.publications_visibility,
        research_visibilityView: action.payload.research_visibility,
        project_visibilityView: action.payload.project_visibility,
        trainingAndconference_visibilityView: action.payload.trainingAndconference_visibility,
        dept_code: action.payload.dept_code,
        dept_id: action.payload.dept_id,
        user_category: action.payload.user_category,
        open_to_work_view: action.payload.open_to_work
      };

    case EDIT_PROFILE_DATA:
      userData = {
        ...state,
        about: action.payload?.about || state?.about,
        interests: action.payload?.interests || state?.interests,
        education: action.payload?.education || state?.education,
        experience: action.payload?.experience || state?.experience,
        certification: action.payload?.certification || state?.certification,
        volunteer: action.payload?.volunteer || state?.volunteer,
        skills: action.payload?.skills || state?.skills,
        achievement: action.payload?.achievement || state?.achievement,
        tags: action.payload?.tags || state?.tags,
        userCoverPic: action.payload?.cover_pic || state?.userCoverPic,
        userProfilePic: action.payload?.profile_pic || state?.userProfilePic,
        address: action.payload?.address || state?.address,
        dob: action.payload?.dob || state?.dob,
        name: action.payload?.name || state?.name,
        designation: action.payload?.designation || state?.designation,
        location: action.payload?.location || state?.location,
        organisation: action.payload?.organisation || state?.organisation,
        connections: action.payload?.connections || state?.connections,
        bookmarks: action.payload?.bookmarks || state?.bookmarks,
        language: action.payload?.language_known || state?.language,
        short_bio: action.payload?.short_bio || state?.short_bio,
        deptCode: action.payload?.dept_code || state?.deptCode,
        profile_completion_status: action.payload?.profile_completion_status || state?.profile_completion_status,
      };
      return userData;

    case GET_COVER_PIC:
      return {
        ...state,
        userCoverPic: action.payload,
      };

    case UPDATE_COVER_PIC:
      userData = {
        ...state,
        userCoverPic: action.payload,
      };
      return userData;

    case GET_PEOPLE_YOU_MAY_KNOW:
      return {
        ...state,
        peopleYouMayKnow: action.payload,
      };

    case GET_PEOPLE_YOU_MAY_KNOW_VIEW:
      return {
        ...state,
        peopleYouMayKnowView: action.payload,
      };
    
      case SEARCH_PAGINATED_RESULT: 
      const finalArr = state.serachResults!=undefined ? state.serachResults.concat(action.payload.data) : action.payload.data
      return {
        ...state,
        serachResults: finalArr,
        nextSearch: action.payload.nextSearch,
        prevSearch: action.payload.prevSearch
      };

    case FETCH_SEARCH_RESULTS:
      
      return {
        ...state,
        serachResults: action.payload.data,
        nextSearch: action.payload.nextSearch,
        prevSearch: action.payload.prevSearch
      };

    // connections

    case SENT_CONNECTIONS:
      return {
        ...state,
        viewSentConnections: action.payload,
      };

    case CONNECTION_INVITATION:
      return {
        ...state,
        viewConnectionInvitation: action.payload,
      };

    case ACCPET_CONNECTION:
      userData = {
        ...state,
        viewConnectionInvitation: action.payload,
        connectionStateView: action.payload.connectionState,
      };
      return userData;

    case MY_CONNECTIONS:
      return {
        ...state,
        myConnections: action.payload,
      };

    case WITHDRAW_CONNECTION:
      userData = {
        ...state,
        // withdrawConnection: action.payload,
        // myConnections: state.myConnections,
        viewSentConnections: action.payload,
      };
      return userData;

    case REMOVE_CONNECTION:
      userData = {
        ...state,
        myConnections: action.payload,
      };
      return userData;

    case IGNORE_CONNECTION:
      userData = {
        ...state,
        viewConnectionInvitation: action.payload,
      };
      return userData;

    case SEND_CONNECTION:
      userData = {
        ...state,
        connectionStateView: action.payload.connectionState,
      };
      return userData;

    case GET_CODE_SUGGESTIONS:
      userData = {
        ...state,
        enterprise_codes: action.payload,
      };
      return userData;

    case BOOST_MY_PROFILE:
    case BOOST_MY_POST:
      data = cloneDeep(state);
      data.boostPaymentOrder = action.payload;
      return data;

    case REMOVE_EXISTING_PAYMENT_ORDER:
      data = cloneDeep(state);
      data.boostPaymentOrder = null;
      return data;

    case ADD_PUBLICATION:
      data = cloneDeep(state);
      if(Array.isArray(data.publications)){
        data.publications.push(action.payload);
      } else {
        data.publications = [action.payload];
      }
      return data;

    case ADD_TRAINING:
      data = cloneDeep(state);
      if(Array.isArray(data.trainingAndConference)){
        data.trainingAndConference.push(action.payload);
      } else {
        data.trainingAndConference = [action.payload];
      }
      return data;

    case ADD_RESEARCH:
      data = cloneDeep(state);
      if(Array.isArray(data.research)){
        data.research.push(action.payload);
      } else {
        data.research = [action.payload];
      }
      return data;

    case ADD_PROJECT:
      data = cloneDeep(state);
      if(Array.isArray(data.project)){
        data.project.push(action.payload);
      } else {
        data.project = [action.payload];
      }
      return data;

    case UPDATE_PUBLICATION:
      data = cloneDeep(state);
      index = data.publications.findIndex(item => item.id === action.payload.id);
      if(index === -1){
        return data;
      }
      data.publications[index] = action.payload;
      return data;

    case UPDATE_TRAINING:
      data = cloneDeep(state);
      index = data.trainingAndConference.findIndex(item => item.id === action.payload.id);
      if(index === -1){
        return data;
      }
      data.trainingAndConference[index] = action.payload;
      return data;

    case UPDATE_RESEARCH:
      data = cloneDeep(state);
      index = data.research.findIndex(item => item.id === action.payload.id);
      if(index === -1){
        return data;
      }
      data.research[index] = action.payload;
      return data;

    case UPDATE_PROJECT:
      data = cloneDeep(state);
      index = data.project.findIndex(item => item.id === action.payload.id);
      if(index === -1) {
        return data;
      }
      data.project[index] = action.payload;
      return data;
      
    default:
      return { ...state };
  }
};
