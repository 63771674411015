import React from "react";
import IconTitle from "./IconTitle";
import {store} from "../../redux/store"
import { withTranslation } from "react-i18next";
class IconSectionFeed extends React.Component {
  
  render() {
  const {t} = this.props;
  const feedTitle = [
      t("profile.about"),
      t("profile.featured"),
      t("profile.interests"),
      store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.experience") : t("profile.UniversityInstituteSummary"),
      store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.education") : null,
      t("profile.licensesAndCertifications"),
      store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.volunteerExperience") : t("profile.VolunteerInitiative"),
      t("profile.skillsAndEndorsements"),
      /* "Recommendations", */ t("profile.achievements"),
    ];
  
  const feedTitleImages = [
      "/Image/apps/profile-resume-edit-view/about.svg",
      "/Image/Chalkmate_Featured.svg",
      "/Image/apps/profile-resume-edit-view/intrests.svg",
      "/Image/apps/profile-resume-edit-view/experience.svg",
      store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? "/Image/apps/profile-resume-edit-view/education.svg" : null,
      "/Image/certifications.svg",
      "/Image/Chalkmate_Volunteer.svg",
      "/Image/apps/profile-resume-edit-view/skills.svg",
      // "/Image/Chalkmate_Recommendation.svg",
      "/Image/Chalkmate_Achievement.svg",
    ];
  
    return (
      <div>
        <div>
          {feedTitle.map((title, idx) => {
            return (
              <div
                style={{
                  marginBottom: 10,
                }}
              >
               {feedTitleImages[idx] != null ? <IconTitle idx={idx} title={title} img={feedTitleImages[idx]} /> : null }
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
export default withTranslation()(IconSectionFeed);