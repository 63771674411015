import {
  CHANGE_GROUP_ACTIVE_SECTION,
  DELETE_GROUP,
  GET_ERROR_MESSAGE,
  GET_GROUPS,
  GET_GROUP_DETAILS,
  GET_GROUP_POST,
  POST_GROUP_DETAILS,
  PUT_GROUP_DETAILS,
  ERROR_STATUS_CODE,
  GET_ALL_GROUP_OWNER_CODES
} from "../constants/actionTypes";
import axios from "axios";
import {
  GET_GROUPS_API,
  GET_GROUP_DETAILS_API,
  GET_GROUP_ID_BY_CODE,
  POST_GROUPS_DETAILS_API,
  PUT_GROUP_DETAILS_API,
} from "../constants/apis";
import { store } from "../store";
import { postMembers } from "./classes";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import moment from "moment";
import { handleSuccessMessage } from "./success";
import { getAllAssignments, getAllAssignmentsQuizes } from "./assignment";
import { getAllQuizzes } from "./quiz";

export const handleErrorMessage = (errorMessage) => {
  return {
    type: GET_ERROR_MESSAGE,
    payload: errorMessageFromResponse(errorMessage),
  };
};
export const getGroupDetails = (groupId) => {
  return async (dispatch) => {
    // dispatch(getAllAssignments(groupId, 'group'))
    // dispatch(getAllQuizzes(groupId, 'group'))
    let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
    const token = store.getState()?.profile?.user?.token;
    const profileId = JSON.parse(localStorage.getItem("userData")).profile_id;
    const getGroupDetailsEndpoint = `${GET_GROUP_DETAILS_API}${groupId}/?target=${lang}/`;
    await axios
      .get(getGroupDetailsEndpoint, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        // dispatch(getAllAssignmentsQuizes(groupId, 'group'))
        dispatch({
          type: ERROR_STATUS_CODE,
          payload: response.status,
        })
        const data = response.data.data;
        const { courses, members, posts, meetings } = data;
        // let myCourses = courses.map((each_course) => {
        //   return {
        //     id: each_course.id,
        //     title: each_course.name,
        //     link: each_course.external_link,
        //     src: each_course.image_url || "/Image/class/courseDefault.png",
        //   };
        // });
        let myCourses = [];
        let myMembers = members.map((each_member) => {
          return {
            id: each_member.id,
            name: each_member.profile.name,
            university: each_member.profile.organisation,
            img: each_member.profile.profile_pic,
            privilige: each_member.privilige,
            profileId: each_member.member_users,
          };
        });
        let myMeetings = meetings.reduce((recordingsArr, each_meetings) => {
          const newMeetingObject = {
            uid: each_meetings.meeting_uuid,
            topic: each_meetings.topic,
            time: moment(each_meetings.meeting_created_at).format("LLLL"),
            count: each_meetings.recording_count,
          };
          const recordingObjects = each_meetings?.recordings.map(
            (recording, index, recordings) => {
              const newRecordingObject = { ...newMeetingObject };
              newRecordingObject.playUrl = recording.play_url;
              newRecordingObject.topic =
                recordings.length > 1
                  ? `${each_meetings.topic}-Session${index + 1}`
                  : `${each_meetings.topic}`;
              return newRecordingObject;
            }
          );
          return recordingObjects.length > 0
            ? [...recordingsArr, ...recordingObjects]
            : [...recordingsArr];
        }, []);
        // let sortedPosts = posts.sort((a, b) => b.id - a.id);
        // let myPosts = sortedPosts.map((post) => post);
        const groupDetails = {
          group: {
            id: data.id,
            name: data.name,
            description: data.description,
            grade: data.grade,
            subject: data.subject,
            cover_image: data.cover_image,
            code: data.code,
            group_owner: data.group_owner,
            groupVision: data.vision_mission,
            time_table: data.time_table,
            isGroupOwner: profileId == data.group_owner,
            hasAccess: profileId == data.group_owner,
          },
          members: myMembers,
          courses: myCourses,
          // posts: myPosts,
          meetings: myMeetings,
        };
        dispatch({ type: GET_GROUP_DETAILS, payload: groupDetails });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        let url = window.location.href;
        let newURL = url.replace(/\/(class|group)\/.*$/, '/not_found');
        window.location.assign(newURL)
        if(error?.response?.status === 400){
          dispatch({
            type: ERROR_STATUS_CODE,
            payload: error.response.status,
          })
        } else {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        }        
        return error;
      });
  };
};

export const getGroups = () => {
  const token = store.getState()?.profile?.user?.token;
  const profileId = store.getState()?.profile?.user?.profile_id;
  return async (dispatch) => {
    await axios
      .get(GET_GROUPS_API, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        // const groups = response.data.data;
        const groups = response.data.data.map((groupData) => groupData.groups);

        const payload = groups?.map(x=> {
          let group = {...x}
          group.isGroupOwner = x.group_owner == profileId
          return group;
        })
        console.log("theGroups payload", payload)
        dispatch({ type: GET_GROUPS, payload: payload });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const postGroup = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    const data1 = { ...data };
    delete data1.code;
    if (Object.values(data1).every((field) => field === "")) {
      dispatch(handleErrorMessage("You can't submit an empty form"));
    } else {
      await axios({
        method: "post",
        url: POST_GROUPS_DETAILS_API,
        data: {
          group_owner: store.getState()?.profile?.user.profile_id,
          grade: data.groupGrade,
          name: data.groupName,
          description: data.groupDescription,
          subject: data.groupSubject,
          code: data.code,
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          if (response.data.status) {
            dispatch({
              type: POST_GROUP_DETAILS,
              payload: response.data.status,
            });
            dispatch(handleSuccessMessage("Successfully created group"));
          }
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    }
  };
};

export const joinGroupByCode = (data) => {
  const token = store.getState()?.profile?.user?.token;
  let member_users = store.getState()?.profile?.user?.profile_id;

  return async (dispatch) => {
    await axios({
      method: "get",
      url: `${GET_GROUP_ID_BY_CODE}?group_code=${data.group_code}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (response.data.status) {
          if (response.data.data.length == 0) {
            const errorMsg = "No Such Group Found";
            dispatch(handleErrorMessage(errorMsg));
          } else {
            let memberData = [
              {
                member_users: member_users,
                privilige: "",
                group_members: response.data.data.id,
              },
            ];
            dispatch(postMembers(memberData));
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const editGroup = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const formData = new FormData();
  if (data.groupName !== "") formData.append("name", data.groupName);

  if (data.groupDescription !== "")
    formData.append("description", data.groupDescription);

  if (data.groupGrade !== "") formData.append("grade", data.groupGrade);

  if (data.groupSubject !== "") formData.append("subject", data.groupSubject);

  let value = Object.fromEntries(formData.entries());
  value = { ...value, id: data.groupId };

  return async (dispatch) => {
    await axios({
      method: "put",
      url: `${PUT_GROUP_DETAILS_API}${data.groupId}/`,
      data: formData,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.status >= 200 &&
          response.status < 300 &&
          response?.data?.status == "1"
        ) {
          dispatch({ type: PUT_GROUP_DETAILS, payload: value });
          dispatch(handleSuccessMessage("Successfully updated group"));
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const deleteGroup = (data) => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios({
      method: "delete",
      url: `${PUT_GROUP_DETAILS_API}${data}/`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        dispatch({ type: DELETE_GROUP, payload: data });
        dispatch(handleSuccessMessage("Group deleted successfully"));
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getGroupPosts = (groupId) => {
  return async (dispatch) => {
    const token = store.getState()?.profile?.user?.token;
    try {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/group/${groupId}/`,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((response) => {
          const data = response.data.data.filter((obj) =>
            obj.post_type == "GROUP" || obj.post_type == "CLASS"
            // obj.post_type.includes("GROUP")
          );
          let posts = data.map((posts) => posts);

          dispatch({ type: GET_GROUP_POST, payload: posts });
          return posts;
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    } catch (error) {
      console.error(
        "There is some error is finding the posts. Please try again after some time."
      );
    }
  };
};

// ---------------

export const getGroupOwners = () => {
  const token = store.getState()?.profile?.user?.token;
  return async (dispatch) => {
    await axios
      .get(GET_GROUPS_API, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        const groupOwnerCodes = response.data.data.map((groupData) => groupData.groups.group_owner);
        dispatch({ type: GET_ALL_GROUP_OWNER_CODES, payload: groupOwnerCodes });
        return response;
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};