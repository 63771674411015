import React, { useState } from "react";
import styles from "./QuizGradebookTable.module.css";
import {
  CircularProgress,
  Tab,
  Tabs,
  TabPanel,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
} from "@mui/material";
import cloneDeep from 'lodash.clonedeep'

import { useTranslation } from "react-i18next";
import { formatDate } from "../../../redux/constants/commonFunctions";
import { useHistory, useLocation } from "react-router-dom";

const QuizGradebookTable = (props) => {
  const { t } = useTranslation();
  const quiz = props.quiz;
  const userAnswers = cloneDeep(props?.quiz?.user_answers) || [];
  const questionList = cloneDeep(props.quiz?.quiz?.template?.questions) || [];
  const questions =
    questionList?.map((x) =>
      Object.assign(
        x,
        userAnswers?.find((y) => y.question == x.id)
      )
    ) || [];

  const styleTableHeader = {
    width: "25%",
    fontFamily: "Montserrat",
    backgroundColor: "#F6F6F6",
    color: "#7B7B7B",
    fontSize: {
      xs: ".875rem",
      sm: ".875rem",
      md: "1rem",
    },
    fontWeight: 600,
    border: "none",
    borderRight: "1px solid #E5E5E5",
    borderColor: "#868DA6",
    textAlign: "center",
    padding: {
      xs: "0.5rem",
      sm: ".5rem",
      md: ".875rem",
    },
    textTransform: "uppercase",
  };

  const styleTableCell = {
    width: "25%",
    color: "#000",
    fontFamily: "Montserrat",
    backgroundColor: "#F6F6F6",
    fontSize: {
      xs: ".875rem",
      sm: ".875rem",
      md: "1rem",
    },
    fontWeight: 500,
    border: "none",
    borderRight: "1px solid #E5E5E5",
    borderColor: "#868DA6",
    textAlign: "center",
    padding: {
      xs: "0.5rem",
      sm: ".5rem",
      md: ".875rem",
    },
  };

  return (
    <div className={styles.main}>
      {console.log("tabQuiz", quiz, questions)}
      <TableContainer>
        <Table stickyHeader sx={{ backgroundColor: "#F6F6F6" }}>
          <TableHead sx={styleTableHeader}>
            <TableRow>
              <TableCell sx={styleTableHeader}>
                {t("gradebook.questionNumber")}
              </TableCell>
              <TableCell sx={styleTableHeader}>
                {t("gradebook.status")}
              </TableCell>
              <TableCell sx={styleTableHeader}>
                {t("assignment.maxMarks")}
              </TableCell>
              <TableCell sx={{ ...styleTableHeader, borderRight: "none" }}>
                {t("gradebook.receivedMarks")}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {questions?.length > 0 && questions?.map((item, index) => (
              <TableRow key={item.id}>
                <TableCell sx={styleTableCell}>{index+1}</TableCell>
                <TableCell sx={styleTableCell}>
                  {
                    <img
                      className={styles.icon}
                      src={
                        item.is_correct
                          ? "/Image/Gradebook/correct.svg"
                          : "/Image/Gradebook/wrong.svg"
                      }
                    />
                  }
                </TableCell>
                <TableCell sx={styleTableCell}>{item.marks}</TableCell>
                <TableCell sx={{ ...styleTableCell, borderRight: "none" }}>
                  {item.user_marks || 0}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default QuizGradebookTable;
