import { gql } from "graphql-request";

// getAllStudentDetails
export const GET_ALL_CLASS_STUDENT_DETAILS_QUERY = gql`
  query ($id: Int) {
    member_list(classes: $id) {
      totalStudents
      memberStudents {
        id
        name
        avgQuizPerc
        avgAssPerc
        avgScore
      }
    }
  }
`;

export const GET_ALL_GROUP_STUDENT_DETAILS_QUERY = gql`
  query ($id: Int) {
    member_list(groups: $id) {
      totalStudents
      memberStudents {
        id
        name
        avgQuizPerc
        avgAssPerc
        avgScore
      }
    }
  }
`;

export const GET_CLASS_ASSIGNMENT_QUIZ_RESULTS_QUERY = gql`
  query ($profileId: Int!, $id: Int) {
    CombinedSubmission(profile_id: $profileId, class_id: $id) {
      student {
        id
        name
        profile_pic
        avgQuizPerc
        avgAssPerc
        avgScore
        UserObj {
          id
          email
        }
      }
      QuizCount
      AssignmentCount
      QuizList {
        id
        quiz {
          id
          title
          max_marks   
          template {
            id
            questions {
              id 
              marks
            }          
          }
        }
        attempt
        submission_status
        marks
        time_taken
        user_answers
        is_returned
        is_submitted
        submission_date
        percentage
      }
      AssignmentList {
        id
        assignment {
          id
          title
          details
          file
          url
          max_marks
        }
        fileSubmitted
        url
        submission_status
        marks
        is_returned
        is_submitted
        is_submitted_late
        submission_date
        percentage
      }
      TotalQuizMarks
      TotalAsgMarks
      ReceievedQuizMarks
      ReceivedAsgMarks
      AvgQuizScore
      AvgAsgScore
    }
  }
`;

export const GET_GROUP_ASSIGNMENT_QUIZ_RESULTS_QUERY = gql`
  query ($profileId: Int!, $id: Int) {
    CombinedSubmission(profile_id: $profileId, group_id: $id) {
      student {
        id
        name
        profile_pic
        avgQuizPerc
        avgAssPerc
        avgScore
        UserObj {
          id
          email
        }
      }
      QuizCount
      AssignmentCount
      QuizList {
        id
        quiz {
          id
          title
          max_marks   
          template {
            id
            questions {
              id 
              marks
            }          
          }
        }
        attempt
        submission_status
        marks
        time_taken
        user_answers
        is_returned
        is_submitted
        submission_date
        percentage
      }
      AssignmentList {
        id
        assignment {
          id
          title
          details
          file
          url
          max_marks
        }
        fileSubmitted
        url
        submission_status
        marks
        is_returned
        is_submitted
        is_submitted_late
        submission_date
        percentage
      }
      TotalQuizMarks
      TotalAsgMarks
      ReceievedQuizMarks
      ReceivedAsgMarks
      AvgQuizScore
      AvgAsgScore
    }
  }
`;
