import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./MyPostsFeed.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  Tab,
  Tabs,
  TabPanel,
  Box,
  Typography,
} from "@mui/material";
import Post from "../../../../Dashboard/Components/Post";
import { getMyPosts } from "../../../../../redux/actions/dashboard";

export const MyPostsFeed = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(state=>state?.profile?.profileId);
  const myPosts = useSelector((state) => state?.dashboard?.myPosts);

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const [tabValue, setTabValue] = useState(0);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
  const [isLoadingClasses, setIsLoadingClasses] = useState(true);
  const [isLoadingGroups, setIsLoadingGroups] = useState(true);

  useEffect(() => {
    dispatch(getMyPosts("DASHBOARD", userId)).then(() => setIsLoadingDashboard(false));
  }, []);

  const getPostHandler = (type) => {
    if(type === "CLASS" && myPosts?.classes === null){
      dispatch(getMyPosts(`${type}`, userId)).then(() => setIsLoadingClasses(false));
    } else if (type === "GROUP" && myPosts?.groups === null) {
      dispatch(getMyPosts(`${type}`, userId)).then(() => setIsLoadingGroups(false));
    } else if (type === "DASHBOARD" && myPosts?.dashboard === null) {
      dispatch(getMyPosts(`${type}`, userId)).then(() => setIsLoadingGroups(false));      
    }
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              p: 0,
              "@media (max-width: 500px)": {
                // p: 2,
              },
            }}
          >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  return (
    <div className={styles.main}>
      <p className={styles.my_post_label}>{t("boostProfile.myPosts")}</p>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="secondary tabs example"
        TabIndicatorProps={{
          sx: {
            backgroundColor: "#F87377",
          },
        }}
        sx={{
          marginBottom: "1.25rem",
          "& button.Mui-selected": {
            color: "#F87377",
          },
          "& button:focus": {
            color: "#F87377",
          },
          "& button.Mui-disabled": {
            backgroundColor: "#f6f6f6",
            color: "#959595",
            borderColor: "#f6f6f6",
          },
        }}
      >
        <Tab label={t("boostProfile.dashboard")} onClick={()=>getPostHandler("DASHBOARD")} />
        <Tab label={t("common.classes")} onClick={()=>getPostHandler("CLASS")} />
        <Tab label={t("common.groups")} onClick={()=>getPostHandler("GROUP")} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        {isLoadingDashboard ? (
          <div className={styles.loader_style}>
            <CircularProgress sx={{ color: "#ED1E24" }} size={40} />
          </div>
        ) : myPosts?.dashboard?.length > 0 ? (
          myPosts?.dashboard?.map((post) => (
            <Post
              key={post?.id}
              showBoostBtn = {true}
              name={post?.post_owner_profile?.name}
              follower={post?.post_owner_profile?.connections}
              timestamp={post?.date_and_time}
              message={post?.description || post?.message}
              tag = {post.tags}
              profileImage={
                post?.post_owner_profile?.profile_pic ||
                "./Image/DashImage/profile.png"
              }
              photoUrl={post?.image_url || post?.photoUrl}
              video={post?.video_url || post?.video}
              file_url={post.file_url}
              likes={post.likes}
              likeDetails={post.likedPost}
              shareLink={post.share_link}
              tags={post.tags}
              postId={post.id}
              comments={post.comments}
              // shareModalOpener={() =>
              //   this.props.shareModalOpener(post.share_link)
              // }
              // menu={
              //   post?.post_owner_profile?.id == userId
              //     ? true
              //     : false
              // }
              postData={post}
              // source="DASHBOARD"
              language_code={post?.language_code}
              postLanguage={post?.language_code}
            />
          ))
        ) : (
          <p className={styles.nothing_posted}>
            {t("boostProfile.nothingPosted")}
          </p>
        )}
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
      {isLoadingClasses ? (
          <div className={styles.loader_style}>
            <CircularProgress sx={{ color: "#ED1E24" }} size={40} />
          </div>
        ) : myPosts?.classes?.length > 0 ? (
          myPosts?.classes?.map((post) => (
            <Post
              key={post?.id}
              showBoostBtn = {true}
              name={post?.post_owner_profile?.name}
              follower={post?.post_owner_profile?.connections}
              timestamp={post?.date_and_time}
              message={post?.description || post?.message}
              tag = {post.tags}
              profileImage={
                post?.post_owner_profile?.profile_pic ||
                "./Image/DashImage/profile.png"
              }
              photoUrl={post?.image_url || post?.photoUrl}
              video={post?.video_url || post?.video}
              file_url={post.file_url}
              likes={post.likes}
              likeDetails={post.likedPost}
              shareLink={post.share_link}
              tags={post.tags}
              postId={post.id}
              comments={post.comments}
              // shareModalOpener={() =>
              //   this.props.shareModalOpener(post.share_link)
              // }
              // menu={
              //   post?.post_owner_profile?.id == userId
              //     ? true
              //     : false
              // }
              postData={post}
              // source="DASHBOARD"
              language_code={post?.language_code}
              postLanguage={post?.language_code}
            />
          ))
        ) : (
          <p className={styles.nothing_posted}>
            {t("boostProfile.nothingPosted")}
          </p>
        )}
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
      {isLoadingGroups ? (
          <div className={styles.loader_style}>
            <CircularProgress sx={{ color: "#ED1E24" }} size={40} />
          </div>
        ) : myPosts?.groups?.length > 0 ? (
          myPosts?.groups?.map((post) => (
            <Post
              key={post?.id}
              showBoostBtn = {true}
              name={post?.post_owner_profile?.name}
              follower={post?.post_owner_profile?.connections}
              timestamp={post?.date_and_time}
              message={post?.description || post?.message}
              tag = {post.tags}
              profileImage={
                post?.post_owner_profile?.profile_pic ||
                "./Image/DashImage/profile.png"
              }
              photoUrl={post?.image_url || post?.photoUrl}
              video={post?.video_url || post?.video}
              file_url={post.file_url}
              likes={post.likes}
              likeDetails={post.likedPost}
              shareLink={post.share_link}
              tags={post.tags}
              postId={post.id}
              comments={post.comments}
              // shareModalOpener={() =>
              //   this.props.shareModalOpener(post.share_link)
              // }
              // menu={
              //   post?.post_owner_profile?.id == userId
              //     ? true
              //     : false
              // }
              postData={post}
              // source="DASHBOARD"
              language_code={post?.language_code}
              postLanguage={post?.language_code}
            />
          ))
        ) : (
          <p className={styles.nothing_posted}>
            {t("boostProfile.nothingPosted")}
          </p>
        )}
      </TabPanel>
    </div>
  );
};

export default MyPostsFeed;
