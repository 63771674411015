import React from 'react'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

//Wrap your component within <TooltipWrapper><TooltipWrapper/>
// Pass the tooltip text in "title" prop

const TooltipWrapper = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} placement={"top"}/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "12px",
      fontFamily: "Montserrat"
    },
  }));

export default TooltipWrapper

// exaple

{/* <TooltipWrapper title="Add this to tooltip">   
   <Button>TEXT</Button>
</TooltipWrapper> */}