import React from "react";
import Avatar from "@mui/material/Avatar";
import styles from "./ChatContactListItem.module.css";
import moment from "moment";

const ChatContactListItem = (props) => {
  let timestamp = props.timestamp && moment(props.timestamp).fromNow();

  return (
    <div
      className={
        props.isSelected ? `${styles.main} ${styles.selected}` : styles.main
      }
      onClick={() =>
        props.getSelectedContact({
          channel: props.channel || '',
          name: props.name || '',
          profilePic: props.profilePic || '',
          userID: props.recipient || '',
        })
      }
    >
      <Avatar src={props.profilePic} sx={{ width: 50, height: 50 }} />
      <div className={styles.data_wrap}>
        <p className={styles.name}>{props.name}</p>
        <p className={styles.timestamp}>
          {timestamp != "Invalid date" ? timestamp : ""}
        </p>
        <p className={styles.last_message}>
          {props.lastMessage?.slice(0, 30) + "...."}
        </p>
      </div>
    </div>
  );
};

export default ChatContactListItem;
