import React from "react";
import { withRouter } from "react-router-dom";
import desc from "../../templates/desc";
import styles from "../../../Profile.module.css";
import InfoCard from "../../../../../components/commons/InfoCard";
import { connect } from "react-redux";
import { sendConnectionRequest, acceptConnectionRequest } from "../../../../../redux/actions/connections";
import { SEND_CONNECTION, ACCPET_CONNECTION } from "../../../../../redux/constants/actionTypes";
import { withTranslation } from "react-i18next";

class EditDescFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      showMenu: false,
      connect: false,

      profileLink: desc.website,
      phone: desc.phone,
      address: desc.residencecity + " " + desc.residencestate,
      email: desc.email,
      dob: desc.dob,

      username: this.props?.user?.first_name && this.props?.user?.last_name ? this.props.user.first_name + " " + this.props.user.last_name : "",
      designation: this.props?.user?.profile_id?.designation ? this.props.user.profile_id.designation : "Director-Corporate Affairs",
      organisation: "J kakehasti Services Pvt Ltd.",
      location: "Kolkata, West Bengal, India",
    };
  }
  sendConnections(profileIdView) {
    let data = { connectionState: 2 };
    this.props.sendConnectionRequest(SEND_CONNECTION, profileIdView, data).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }
  acceptConnections(connectionId) {
    let data = { connectionState: 1 };
    this.props.acceptConnectionRequest(ACCPET_CONNECTION, connectionId, data).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  changeConnect(editconnect) {
    this.setState({ editconnect: editconnect });
  }
  componentDidMount() {
    this.setState({
      designation: this.props.designation,
      organisation: this.props.organisation,
      location: this.props.location,
      connections: this.props.connections,
    });
  }
  connectionStateSwitch(connectionState) {
    const {t} = this.props;
    switch (connectionState) {
      case 0:
        return (
          <div
            className={styles.button}
            onClick={() => {
              this.sendConnections(this.props.profileIdView);
              // this.setState({ connect: true })
            }}
          >
            {t("common.connect")}
          </div>
        );
      case 1:
        return (
          <div style={{ cursor: "pointer" }}>
            <p style={{ textAlign: "center", fontSize: 12, textTransform: "uppercase", fontWeight: 600, color: "#555555", margin: 23 }}>{t("common.connected")}</p>
          </div>
        );
      case 2:
        return (
          <div style={{ cursor: "pointer" }}>
            <p style={{ textAlign: "center", fontSize: 12, textTransform: "uppercase", fontWeight: 600, color: "#555555", margin: 23 }}>{t("common.requestSent")}</p>
          </div>
        );
      case 3:
        return (
          <div
            className={styles.button}
            onClick={() => {
              this.acceptConnections(this.props.connection_id_view);
              // this.setState({ connect: true })
            }}
          >
            {t("common.acceptRequest")}
          </div>
        );
      case 4:
        return (
          <div style={{ cursor: "pointer" }}>
            <p style={{ textAlign: "center", fontSize: 12, textTransform: "uppercase", fontWeight: 600, color: "#555555", margin: 23 }}>{t("common.myProfile")}</p>
          </div>
        );
      default:
        return (
          <div
            className={styles.button}
            onClick={() => {
              this.sendConnections(this.props.profileIdView);
              // this.setState({ connect: true })
            }}
          >
            {t("common.connect")}
          </div>
        );
    }
  }

  render() {
    return (
      <div
        className={styles.desc}
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          padding: 10,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          paddingBottom: 0,
        }}
      >
        <InfoCard
          mobileView={this.props.mobileView}
          designation={this.props.designation}
          location={this.props.location}
          organisation={this.props.organisation}
          connections={this.props.connections}
          openToWork={this.props.open_to_work}
        />

        {/* view CODE */}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={styles.editdescfeed_dropdown} style={{ cursor: "pointer" }}>
            <div className={styles.editdescfeed_dropbtn} style={{ cursor: "pointer" }}>
              <div className={styles.buttonsContainer}>
                {
                  // // this.state.connect ?
                  // this.props.myConnections.includes(this.props.profileIdView) ?
                  //     <div
                  //         style={{ cursor: 'pointer' }}
                  //     >
                  //         <p style={{ textAlign: 'center', fontSize: 12, textTransform: 'uppercase', fontWeight: 600, color: '#555555', margin: 23 }}>connected</p>

                  //     </div>
                  //     :
                  //     this.state.connect ?
                  //     <div
                  //         style={{ cursor: 'pointer' }}
                  //     >
                  //         <p style={{ textAlign: 'center', fontSize: 12, textTransform: 'uppercase', fontWeight: 600, color: '#555555', margin: 23 }}>request sent</p>

                  //     </div>
                  //     :
                  //     <div className={styles.button}
                  //         onClick={() => {
                  //             this.sendConnections(this.props.profileIdView)
                  //             this.setState({ connect: true })
                  //         }}
                  //     >
                  //         Connect
                  //     </div>

                  this.connectionStateSwitch(this.props.connectionStateView)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state?.profile?.user || "",
    dob: state?.profile?.dobView || "",
    organisation: state?.profile?.organisationView || "",
    designation: state?.profile?.designationView || "",
    location: state?.profile?.locationView || "",
    connections: state?.profile?.connectionsView || 0,
    sendConnectionRequest: state.profile?.sendConnectionRequest || [],
    profileIdView: state.profile.profileIdView,
    myConnections: state.profile?.connections || [],
    connectionStateView: state.profile?.connectionStateView || "",
    // acceptConnection: state.profile?.acceptConnection || [],
    connection_id_view: state.profile?.connection_id_view || "",
    open_to_work: state?.profile?.open_to_work || {},
  };
};
function mapDispatchToProps(dispatch) {
  return {
    sendConnectionRequest: (action, id, data) => dispatch(sendConnectionRequest(action, id, data)),
    acceptConnectionRequest: (action, id, data) => dispatch(acceptConnectionRequest(action, id, data)),
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(EditDescFeed)));
// export default withRouter(EditDescFeed)
