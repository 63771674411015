import React, { useState, useEffect } from "react";
import styles from "./BoostProfileSection.module.css";
import { store } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";
import IconTitle from "../../../../../components/commons/IconTitle";
import TooltipWrapper from "../../../../CourseMonetization/Components/TooltipWrapper";
import Modal from "../../../../../components/commons/Modal";
import BoostConfirmation from "./Modals/BoostConfirmation";
import { useDispatch, useSelector } from "react-redux";
import { boostProfile, boostPaymentFailed, boostPaymentSuccessful } from "../../../../../redux/actions/profile";
import { CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

const BoostProfileSection = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const boostPaymentOrder = useSelector(state => state?.profile?.boostPaymentOrder);
  const profileId = useSelector((state)=>state?.profile?.user?.profile_id);

  const showModalHandler = () => {
    setShowModal(!showModal);
  };

  const loadScript = (src) => {
    console.log("inside LoadScript")
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

  const openRazorPay = async () => {
    console.log("inside Open RazorPay")
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
  
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    } else {
      console.log("RazorpayLoaded_options",boostPaymentOrder, process.env.REACT_APP_RAZORPAY_KEY )
    }
  
  
    const options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      // amount: this.props?.currentCourse?.price, // 2000 paise = INR 20, amount in paisa
      amount:5,
      name: boostPaymentOrder?.options_name ?? store?.getState().profile?.boostPaymentOrder?.options_name,
      description: boostPaymentOrder?.options_description ?? store?.getState().profile?.boostPaymentOrder?.options_description,
      order_id: boostPaymentOrder?.options_order_id ?? store?.getState().profile?.boostPaymentOrder?.options_order_id,
      image: boostPaymentOrder?.options_image ?? store?.getState().profile?.boostPaymentOrder?.options_image,
      currency: 'USD',
      handler: (response) => {
        const values = {
          razorpay_signature: response.razorpay_signature,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          // transactionamount : amount,
        }
  
        console.log("theValues", values);
        // call success api
        dispatch(boostPaymentSuccessful(values)).then(()=>setIsLoadingBtn(false));
      },
      prefill: {
        name: boostPaymentOrder?.prefill_name ?? store?.getState().profile?.boostPaymentOrder?.prefill_name,
        email:boostPaymentOrder?.prefill_email ?? store?.getState().profile?.boostPaymentOrder?.prefill_email,
      },
      notes: {
        address: boostPaymentOrder?.notes_address ?? store?.getState().profile?.boostPaymentOrder?.notes_address,
      },
      theme: {
        color: "#ED1E24",
      },
      "modal": {
        "ondismiss": () => {
            console.log("isLoading False")
            setIsLoadingBtn(false);
            // this.setState({isLoading:false});
        }
    }
    };
  
  
    const paymentObject = new window.Razorpay(options);
  
    paymentObject.on("payment.failed", (response) => {
              
      let data = {
        order_id: response?.error?.metadata?.order_id,
        payment_id: response?.error?.metadata?.payment_id,
        code: response?.error?.code,
        description: response?.error?.description,
        reason: response?.error?.reason,
        step: response?.error?.step,
        source: response?.error?.source,
      };
      dispatch(boostPaymentFailed(data)).then(()=>setIsLoadingBtn(false));
    });
  
    paymentObject.open();
  
  }

  // useEffect(() => {
  //   if(boostPaymentOrder != null && boostPaymentOrder != undefined){
  //    openRazorPay();
  //   } else {
  //     setIsLoadingBtn(false);
  //   };
    
  //  }, [boostPaymentOrder])


  const handleBoostProfile = () => {
    console.log("Boosting profile", profileId)
    setShowModal(false);
    dispatch(boostProfile(profileId)).then(()=>openRazorPay());
  }

  const styleProgress = {
    opacity: isLoadingBtn ? 1 : 0,
    position: "absolute",
    top: "20%",
    left: "45%",
    color: "#ED1E24",    
  }
  


  return (
    <div className={styles.main}>
      {showModal && (
        <Modal
          card={<BoostConfirmation onCancel={()=>{
            setIsLoadingBtn(false);
            setShowModal(false);
          }} onBoost={handleBoostProfile} />}
          // onOutsideClick={showModalHandler}
        />
      )}
      <div className={styles.boost_btn} onClick={()=>{
        setIsLoadingBtn(true);
        setShowModal(true);
      }}>
        <TooltipWrapper title={t("boostProfile.increaseYourChances")}>
          <CircularProgress sx={styleProgress} size={25} />
            {/* <p className={isLoadingBtn && styles.hide_connect_label}>{t("boostProfile.boostPost")}</p> */}
            <div className={isLoadingBtn && styles.hide_connect_label}>
              <div className={styles.text_wrapper}>
                <img src="/Image/boost-profile/boost_profile.svg" />
                <p>{t("boostProfile.boostProfile")}</p>
              </div>
            </div>
        </TooltipWrapper>
      </div>

      <div
        className={styles.activity_container}
        onClick={() => {
          props.viewMyPosts();
          if(props.hamburgerDisplay){
            props.closeHamburgerMenu();
          }
        }}
      >
        <IconTitle
          title={t("boostProfile.myPostActivity")}
          img="/Image/boost-profile/my_post _ activity.svg"
        />
        <p className={styles.manage_label}>
          {t("boostProfile.viewManagePost")}
        </p>
      </div>
    </div>
  );
};

export default BoostProfileSection;
