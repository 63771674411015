import FavoriteIcon from "@material-ui/icons/Favorite";
import { React, useState, Component, useEffect, useRef } from "react";
import InputOption from "./InputOption";
import styles from "./CSSFiles/BoostedProfile.module.css";
import InputOptionFromSource from "./InputOptionFromSource";
import Comment from "./Comment";
import Video from "./Video";
import Picker from "emoji-picker-react";
import "./CSSFiles/picker.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { postComment, postLike, postLikeDelete, translatePost } from "../../../redux/actions/dashboard";
import { Link, Redirect } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PostModal from "../postModal/PostModal";
import DiscussionModal from "../postModal/DiscussionModal";
import SaveButton from "../../../components/commons/SaveButton"
import { useTranslation } from "react-i18next";
import TooltipWrapper from "../../CourseMonetization/Components/TooltipWrapper";
import { useHistory } from "react-router-dom";
import { sendConnectionRequest } from "../../../redux/actions/connections";
import { SEND_CONNECTION } from "../../../redux/constants/actionTypes";
import { CircularProgress } from "@mui/material";


function BoostedProfile(props) {
  const {
    postComment,
    newComment,
    newTranslation,
    newLike,
    postLike,
    name,
    follower,
    timestamp,
    message,
    photoUrl,
    file_url,
    tags,
    title,
    video,
    shareModalOpener,
    profileImage,
    user,
    likes,
    likeDetails,
    postId,
    shareLink,
    comments,
    source,
    translatePost,
    currentLanguage,
    postLanguage,
    postData,
    about,
    location,
    experience,
    menu,
    postProfileId,
    designation   
  } = props;
  const [profileLikes, setProfileLikes] = useState(0);
  const [likeButtonPressed, setLikeButtonPressed] = useState(false);
  const [comment, setComment] = useState([]);
  const [likedId, setLikedId] = useState(0);
  const [editPostId, setEditPostId] = useState(0);
  const [action, setAction] = useState("")
  const [anchorEl, setAnchorEl] = useState(null);

  const history = useHistory();
  
  const newRefComment = useRef(newComment);
  newRefComment.current = newComment;
  const newRefLike = useRef(newLike);
  newRefLike.current = newLike;
  const newRefTranslation = useRef(newTranslation);
  newRefTranslation.current = newTranslation;
  const profilePicUrl = useSelector((state) => state.profile.userProfilePic);
  const profileId = useSelector((state) => state.profile.user.profile_id);
  const open = Boolean(anchorEl);
  const [translatedMessage, setTranslatedMessage] = useState({})
  const [translatedMessageToShow, setTranslatedMessageToShow] = useState(message)
  const [seeTranslation, setSeeTranslation] = useState(true)
  const [likeClickable, setLikeClickable] = useState(true)
  
  let firstpostTime = new Date(timestamp);
  let postTime = firstpostTime.setMinutes(firstpostTime.getMinutes() - 330);
  let seconds = Math.floor((new Date() - postTime) / 1000);
  let years = seconds / 31536000;
  let months = seconds / 2592000;
  let days = seconds / 86400;
  let hours = seconds / 3600;
  let minutes = seconds / 60;
  const dispatch = useDispatch();
  const {t} = useTranslation()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    setAction("EDIT")
    setAnchorEl(null);
    toggleModal(true);
  };
  const handleDelete = () => {
    setAction("DELETE")
    setAnchorEl(null);
    toggleModal(true);
  };


  useEffect(() => {
    setTranslatedMessageToShow("")
    if(message){ 
      setTranslatedMessage({"lang": message})
      setTranslatedMessageToShow(message)
    }
    if (likeDetails != null) {
      setProfileLikes(likeDetails.length);
      if (likeDetails.some((likedPost) => likedPost.profile_liked_by == user.profile_id)) {
        setLikeButtonPressed(true);
        likeDetails.map((data, index) => {
          if (data.profile_liked_by == user.profile_id) {
            setLikedId(data.id);
          }
        });
      }
    }
    else {
      setProfileLikes(0)
      setLikeButtonPressed(false)
      setLikedId(0)
    }
    if (comments != null) {
      setComment(comments);
    }
    else {
      setComment([]);
    }
  }, [postId, message]);

  const like = (currentLikes) => {
    setLikeClickable(false)
    if (postId > 0) {
      const data = {
        postId: postId,
        profileId: profileId,
      };

      if (!likeButtonPressed) {
        postLike(data).then(() => {
          setLikedId(newRefLike?.current?.id);
          setLikeClickable(true)
        });
        setProfileLikes(currentLikes + 1);
        setLikeButtonPressed(true);
      } else {
        dispatch(postLikeDelete(likedId)) 
        .then(() => {
          setLikeClickable(true)
        });
        setProfileLikes(currentLikes - 1);
        setLikeButtonPressed(false);
        setLikedId(0);
      }
    } else {
      if (!likeButtonPressed) {
        setProfileLikes(1);
        setLikeButtonPressed(true);
      } else {
        setLikeButtonPressed(false);
        setProfileLikes(0);
      }
    }
  };

  const [Input, setInput] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    var currentdate = new Date();
    let datetime =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();
    if (postId > 0) {
      const data = {
        postId: postId,
        description: Input,
        dateTime: datetime,
      };
      postComment(data).then(() => {
        setComment([...comment, newRefComment?.current]);
      });
    } else {
      setComment({
        postId: postId,
        description: Input,
        datetime: datetime,
        likedComment: null,
        id: -1,
      });
    }

    setInput("");
  };

  const sortfunction = (comment) => {
    comment.sort(function (a, b) {
      return b.id - a.id;
    });
  };
  const updateAddLikedComment = (id, newLikedComment) =>{
    for (const c of comment) {
      if(c.id == id){
        c.likedComment = [newLikedComment]
        break;
      }
    }
  }
  const updateRemoveLikedComment = (id) =>{
    for (const c of comment) {
      if(c.id == id){
        c.likedComment = null
        break;
      }
    }
  }
  const commentSection = () => {
    if (comment[0]) {
      sortfunction(comment);
      return comment.map((c, i) => (
        <Comment
          key={i}
          photo={c?.profile_id?.profile_pic || "/Image/DashImage/profile.png"}
          name={c?.profile_id?.name}
          commentdata={c?.description}
          id={c?.id}
          likedComment={c?.likedComment}
          updateAddLikedComment = {updateAddLikedComment}
          updateRemoveLikedComment = {updateRemoveLikedComment}
          // timestamp={c.timestamp}
        />
      ));
    } else {
      return null;
    }
  };

  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showModal, toggleModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setChosenEmoji(emojiObject);
    setInput(Input + emojiObject.emoji);
  };

  const [emoji, setEmoji] = useState(false);

  const toggleEmojiPicker = () => {
    setEmoji(!emoji);
  };

  const thumbsUp = () => {
    setInput(Input + "👍");
  };

/*   const OpenModal = () => {
    setAnchorEl(null);
    toggleModal(true);
  }; */

  const closeModal = () => {
    toggleModal(false);
  };


  const styleProgress = {
    opacity: isLoading ? 1 : 0,
    position: "absolute",
    top: "20%",
    left: "45%",
    color: "#ED1E24",
    
  }

  const handleTranslatePost = (msg) => {
    setSeeTranslation(false)
    if(!translatedMessage.hasOwnProperty(currentLanguage)){
      let data = {target: currentLanguage.slice(0,2), text: msg}
    translatePost(data).then(()=>{
      let translation = {...translatedMessage, currentLanguage: newRefTranslation?.current}
      setTranslatedMessage(translation)
      setTranslatedMessageToShow(newRefTranslation?.current)
    } )

    }
    else{
      setTranslatedMessageToShow(translatedMessage.currentLanguage)
    }
    
  }

  const handleConnect = () => {
    setIsLoading(true);
    let data = { connectionState: 2 };
    dispatch(sendConnectionRequest(SEND_CONNECTION, postProfileId, data)).then(()=>setIsLoading(false));
  }


  const handleSeeOriginal = () => {
    setTranslatedMessageToShow(translatedMessage.lang);
    setSeeTranslation(true)
  }
  /* ACTIONS START */
/*   const deletePost = () => {
    const token = localStorage.getItem("token");
    try {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/delete/${props.postData.id}/`,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      }).then(() => {
          getPosts()
          alert("Post deleted");
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  } */
  /* ACTIONS END */
  return (
    <div className={styles.Post}>
      {emoji && (
        <div
          onClick={() => {
            toggleEmojiPicker();
          }}
          className={styles.overlay}
        />
      )}
      {
        source == "DASHBOARD" && action !== "" 
          ? <PostModal actionType={action} postData={postData} showModal={showModal} closeModal={closeModal} />
          : null
      }
      {
        source !== "DASHBOARD" && action !== "" 
        ? <DiscussionModal actionType={action} postData={postData} showModal={showModal} closeModal={closeModal} />
        : null
      }
      
      {<div className={styles.post_tag}>{t("common.popular")}</div>} 
      <div className={styles.post_header}>
        {/* <Avatar className="post-profile-pic" /> */}
        <div className={styles.infoContainer}>
          <img src={profileImage} className={styles.post_profile_pic} /> 
          <div className={styles.profile_info}>
            <p className={styles.profile_name}>{name}</p>
            {designation && <p className={styles.profile_about}>{designation}</p>}
            {location && <p className={styles.profile_location}>{location}</p>}
            {experience?.length > 0 && <div className={styles.profile_exp}>
              <img src="/Image/profile_page/work.svg" />
              <p className={styles.exp}><span className={styles.label}>{t("profile.experience")}: </span> {experience.join(', ').replace(/, ([^,]*)$/, ' and $1')}</p>
            </div>}
            <div className={styles.buttons_container}>
              <div className={styles.button} onClick={handleConnect}>
                <CircularProgress sx={styleProgress} size={20} />
                <p className={isLoading && styles.hide_connect_label}>{t("common.connect")}</p>
              </div>
              <div className={styles.button} onClick={() => history.push(`/profile/${postProfileId}`)}>
                <p>
                  {t("boostProfile.viewFullProfile")}  
                </p>
              </div>
            </div>
          </div>

          {/* <div className={styles.post_Info}>
            <h2 className={styles.post_info_userName}>{name}</h2>            
            <p className={styles.post_info_follower}>{follower} {t("common.following")}</p>
           
            {years >= 1 ? (
              <p className={styles.post_info_timestamp}>{Math.floor(years)} {t("common.year")} {t("common.ago")}</p>
            ) : months >= 1 ? (
              <p className={styles.post_info_timestamp}>{Math.floor(months)} {t("common.month")} {t("common.ago")}</p>
            ) : days >= 1 ? (
              <p className={styles.post_info_timestamp}>{Math.floor(days)} {t("common.day")} {t("common.ago")}</p>
            ) : hours >= 1 ? (
              <p className={styles.post_info_timestamp}>{Math.floor(hours)} {t("common.hour")} {t("common.ago")}</p>
            ) : minutes >= 1 ? (
              <p className={styles.post_info_timestamp}>{Math.floor(minutes)} {t("common.minute")} {t("common.ago")}</p>
            ) : (
              <p className={styles.post_info_timestamp}>{t("common.justNow")}</p>
            )}
          </div> */}
        </div>
        {/* {menu &&   
          <div className="post-header-options">
              <MoreHorizIcon onClick={handleClick} style={{ marginTop: 7, cursor: "pointer", opacity: 0.6 }} />
              <Menu
                onClick={() => setEditPostId(postId)}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button'}}
                menuStyle={{ boxShadow: 'none' }}
                className={styles.memberMenu} >
                <MenuItem onClick={handleEdit}>{t("common.edit")}</MenuItem>
                <MenuItem onClick={handleDelete}>{t("common.delete")}</MenuItem>
              </Menu>
          </div>          
        } */}
      </div>

      <div className={styles.post_body}>
        {/* <p className={styles.postMessage}>
          <b style={{textTransform: "capitalize", display: "block"}}>
              {t("common.popular")}
          </b>
          <br/>
          <p>{message?.includes("(Link:") ? message.split("(Link:")[0] : translatedMessageToShow}</p>
          <a href={message?.split("(Link:").pop().split(")")[0]} target="_blank" style={{display: "block", margin: "10px 0", color: "#ED1E24", textDecoration: "none", cursor: "pointer"}}>{message?.includes("(Link:") ? message?.split("(Link:").pop().split(")")[0] : null}</a>
        </p> */}

        {/* <div className={styles.btn_container}>
            <div className={styles.btn1}>{t("common.connect")}</div>
            <div className={styles.btn2}>{t("boostProfile.viewFullProfile")}</div>
        </div> */}

        {/* ------buttonConainter--------------- */}
        {/* <div className={styles.buttonsContainer}>
          <div className={styles.button} onClick={handleConnect}>
            <CircularProgress sx={styleProgress} size={20} />
            <p className={isLoading && styles.hide_connect_label}>{t("common.connect")}</p>
          </div>
          <div className={styles.button} onClick={() => history.push(`/profile/${postProfileId}`)}>
            <p>
              {t("boostProfile.viewFullProfile")}  
            </p>
          </div>
        </div> */}
        
        {/* {video ? <Video src={video} /> : null}
        {file_url ? (
          <div className={styles.post_option}>
            <SaveButton onClick={() => window.open(file_url, "_blank")} btnTitle={file_url.toString().split("/").pop()} />
          </div>
        ) : null}
        {photoUrl ? <img src={photoUrl} alt="" /> : null}
        {message && currentLanguage.slice(0, 2) != postLanguage ? (
          
            seeTranslation ? (<p className={styles.translateText} onClick={() => handleTranslatePost(message)}>{t("dashboard.translatePost")}</p>) : (<p className={styles.translateText} onClick={() => handleSeeOriginal(message)}>{t("dashboard.seeOriginal")}</p>)
          

        ) : null
        } */}
        {/* {console.log(translatedMessage,"translatedMessage")} */}
  {/* {console.log(postLanguage)}{currentLanguage.slice(0, 2) } {postLanguage} */}
      </div>

      <div className={styles.post_likeCommentBox}>
        <div
          // onClick={like}
          className={styles.post_like}
          style={{ cursor: "pointer" }}
        >
          <img src="/Image/DashboardImages/DashCommonImages/Chalkmate_red_like.svg" height="13px" style={{ marginRight: 7 }} />
          {profileLikes}
        </div>
        <div
          // onClick={openComments}
          className={styles.post_comment}
          style={{ cursor: "pointer" }}
        >
          <img src="/Image/DashboardImages/DashCommonImages/Chalkmate_green_Comment.svg" height="13px" style={{ marginRight: 7 }} />
          {comment != null ? comment.length : 0} {comment != null ? (comment.length <= 1 ? t("common.comment") : t("common.comments")) : t("common.comment")}
        </div>
      </div>

      <div className={styles.post_option}>
        {/* <InputOption Icon={FavoriteIcon} title="Likes" color="#B4B4B4" /> */}
        {likeClickable
        ?
          <span onClick={() => like(profileLikes)}>
          {/* <span onClick={() => like()}> */}
          {!likeButtonPressed ? (
            <InputOption Icon={FavoriteIcon} title={t("common.like")} color="#858585" />
          ) : (
            <InputOptionFromSource Icon="/Image/DashboardImages/DashCommonImages/Chalkmate_red_like.svg" title={t("common.like")} />
          )}
          </span>
        :
          <span >
            {/* <span onClick={() => like()}> */}
            {!likeButtonPressed ? (
              <InputOption Icon={FavoriteIcon} title={t("common.like")} color="#858585"  cursor= "not-allowed"  />
            ) : (
              <InputOptionFromSource Icon="/Image/DashboardImages/DashCommonImages/Chalkmate_red_like.svg" title={t("common.like")} cursor= "not-allowed" />
            )}
          </span>
          
        }
        <span>
          <InputOptionFromSource Icon="/Image/DashboardImages/DashCommonImages/Chalkmate_Comment.svg" title={t("common.comment")} />
        </span>
        <span onClick={shareModalOpener}>
          <InputOptionFromSource Icon="/Image/DashboardImages/DashCommonImages/Chalkmate_Share.svg" title={t("common.share")} />
        </span>
        {/* <InputOption Icon={Bookmark} title="Bookmark" color="#B4B4B4" /> */}
      </div>

      {/* <div className="Comment">
        <Avatar />
        <form>
          <input className="input" placeholder="Write your Comment here..." />
          <button type="submit" style={{ color: "#236ae8" }}>
            <b>POST</b>
          </button>
        </form>
      </div> */}
      <div className={styles.comment_input}>
        <div>
          <img src={profilePicUrl || "/Image/DashImage/profile.png"} className={styles.comment_avatar} />
        </div>
        <div className={styles.comment_input_div}>
          <form onSubmit={handleSubmit}>
            <input
              value={Input}
              onChange={(e) => setInput(e.target.value)}
              // onClick={<Popup />}
              className={styles.input}
              placeholder={t("dashboard.writeComment")}
            />
            {/* <button
              onClick={handleSubmit}
              type="submit"
              style={{ color: "blue" }}
            >
              <b>SHARE</b>
            </button> */}
            {emoji ? <Picker className={styles.picker} onEmojiClick={onEmojiClick} /> : ""}
          </form>

          <div className={styles.comments_icons_div}>
            <img onClick={toggleEmojiPicker} style={{ height: 20, width: 20 }} src="/Image/DashImage/Chalkmate_Smiley.svg" />
            <img onClick={thumbsUp} style={{ height: 20, width: 20 }} src="/Image/DashImage/Chalkmate_Thumbsup.svg" />
            {/* <img
              style={{ height: 20, width: 20 }}
              src="/Image/DashImage/Chalkmate_GIF.svg"
            /> */}
          </div>
        </div>
      </div>

      {/* {comments != null ? ( */}
      {comment[0] ? (
        <div className={styles.comments_div}>
          {t("dashboard.mostRelevant")}
          {commentSection()}
        </div>
      ) : null}
    </div>
  );
}

// export default Post;
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user,
    newComment: state?.dashboard?.newComment,
    newLike: state?.dashboard?.newLike,
    newTranslation: state?.dashboard?.newTranslation?.translated_text,
    currentLanguage: state?.dashboard?.currentLanguage || localStorage.getItem("i18nextLng") || navigator.language || navigator.userLanguage || "en",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    postComment: (data) => dispatch(postComment(data)),
    postLikeDelete: (data) => dispatch(postLikeDelete(data)),
    postLike: (data) => dispatch(postLike(data)),
    translatePost: (data) => dispatch(translatePost(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BoostedProfile);
