import React from "react";
import styles from "./TemplateFeedPost.module.css";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CourseTitle from "../../CourseMonetization/Components/CourseTitle";
import { useTranslation } from "react-i18next";
import AssignmentHeading from "../../../components/assignmentAndQuiz/AssignmentHeading";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {deleteQuizTemplate} from '../../../redux/actions/enhancedQuiz' 

const TemplateFeedPost = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    if (action == "edit") {
      history.push({
        pathname: `/template/create/${
          parseInt(window.location.pathname.split('/')[2])
        }`,
        state: {
          templateId: props.template.id,
          actionType: "edit",
          routeType: props.routeType,
          isOwner: props?.isOwner,
        },
      });
    } else if (action == "delete") {      
      dispatch(deleteQuizTemplate(props.template.id));
            
    } else if (action == "preview") {
      history.push({
        pathname: "/template/preview/",
        state: {
          templateId: props.template.id,
          isPreview: true,
          routeType: props.routeType,
          isOwner: props?.isOwner,
          isEditable: props.isEditable,
          classOrGroupId: parseInt(window.location.pathname.split('/')[2])
        },
      });

    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.heading}>
      <div className={styles.title}>
        <AssignmentHeading type="quiz" title={props.template?.title ?? ""} />
      </div>
      {props.isOwner && (
        <div className={styles.menu}>
          <MoreHorizIcon
            style={{ cursor: "pointer", opacity: "0.4", fontSize: "1.9rem"}}
            onClick={handleClick}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              style: {
                boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                borderRadius: "10px",
                fontFamily: "Montserrat",
              },
            }}
          >
            <MenuItem
              disabled={!props.isEditable}
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "12px",
                color: "#000000",
                minHeight: 'auto'
              }}
              onClick={() => handleAction("edit")}
            >
              {t("common.edit")}
            </MenuItem>
            <MenuItem
              // disabled={!props.isEditable}
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "12px",
                color: "#000000",
                minHeight: 'auto'
              }}
              onClick={() => handleAction("delete")}
            >
              {t("common.delete")}
            </MenuItem>
            <MenuItem
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "400",
                fontSize: "12px",
                color: "#000000",
                minHeight: 'auto'
              }}
              onClick={() => handleAction("preview")}
            >
              {t("common.preview")}
            </MenuItem>
          </Menu>
        </div>
      )}
      </div>
      <div className={styles.paragraph}>
      <div className={styles.details}>
        <span>{props.template?.details}</span>
      </div>

      <div className={styles.max_marks}>
        {t("assignment.maxMarks")}: {props.template?.marks}
      </div>
      </div>

      <div className={styles.bottom_container}>
        {/* <div className={styles.time}>
          <img src="/Image/AssignmentAndQuizzes/DueDate.svg" alt="time"/>
          {time}
          <span>{t("enhancedQuiz.minutes")}</span>
        </div> */}
        <div className={styles.count}>
          <img src="/Image/assignment/Instructions.svg" alt="time" />
          <span>
            {props.template.ques_count ?? ""} {t("enhancedQuiz.questions")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TemplateFeedPost;
