import { AiOutlinePlus } from "react-icons/ai";
import { React, Component } from "react";
import { connect } from "react-redux";
import Chat from "../../../components/chat/Chat";
import { ChatProvider } from "../../../components/chat/ChatContext";
import SubjectCard from "../../../components/commons/SubjectCard";
import styles from "./myClasses.module.css";
import ClassModal from "../ClassModal/ClassModal";
import GroupModal from "../../ClassesGroups/GroupModal/GroupModal.js";
import { withRouter } from "react-router";
import { getClassDetails } from "../../../redux/actions/classes";
import { withTranslation } from "react-i18next";
// import Header from "../../../components/profile-header-explicit/Header";
// import carrerModal from "../../../components/careerModal/CarrerModal";
// import QRModal from "../../../components/QrModal/qrmodal.js"
// import ShareModal from "../../../components/shareModal/ShareModal";

class MySubjectsComponent extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      showQrModal: false,
      showCareerModal: false,
      showShareModal: false,
      actionType: "",
      editData: {}
    };
  }
  ClassModalCloser = () => {
    this.setState({ showModal: false });
  };

  ClassModalOpener = () => {
    this.setState({ showModal: true });
  };
  // QRModalCloser = () => {
  //   this.setState({ showQrModal: false });
  // };

  // QRModalOpener = () => {
  //   this.setState({ showQrModal: true });
  // };

  // CareerModalCloser = () => {
  //   this.setState({ showCareerModal: false });
  // };

  // CareerModalOpener = () => {
  //   this.setState({ showCareerModal: true });
  // };
  getSubjectDetails = (subjectId) => {
    let { history } = this.props;
    if (this.props.routeType === "/class") {
      history.push({
        pathname: `/class/${subjectId}`,
        state: { subjectId: subjectId },
      });
    } else if (this.props.routeType === "/group") {
      history.push({
        pathname: `/group/${subjectId}`,
        state: { subjectId: subjectId },
      });
    }
  };

  handleAddClass = () => {
    this.setState({ showModal: true });
    this.setState({ actionType: "ADD" })
  }

  handleEditClass = () => {
    this.setState({ showModal: true });
    this.setState({ actionType: "EDIT" })
  }

  handleDeleteClass = () => {
    this.setState({ showModal: true });
    this.setState({ actionType: "DELETE" })
  }

  editClassId = (eachSubject) => {
    this.setState({ editData: eachSubject })
  }

  render() {
    const { t } = this.props;
    const backgroundImagePath = this.props.routeType === "/class" ? "/Image/Classes/myclass.png" : "/Image/Classes/mygroup.png";
    
    // do now show create-class option to students
    const doNotShowCreateOption = !this.props.isTeacher && (this.props.routeType === "/class")
    const  showCreateOption = this.props.routeType === "/class" ? this.props.isTeacher : (this.props.isTeacher || this.props.isStudent);
    
    return (
      <div className={styles.app}>
        {this.props.routeType === "/class" ? (
          <ClassModal editData={this.state.editData} actionType={this.state.actionType} closeModal={this.ClassModalCloser} show={this.state.showModal} />
        ) : (
          <GroupModal editData={this.state.editData} actionType={this.state.actionType} closeModal={this.ClassModalCloser} show={this.state.showModal} />
        )}
        <div className={styles.app_body}>
          <div className="col-xs-0 col-sm-0 col-md-1"></div>
          <div
            className={styles.classContainer}
            style={{
              backgroundImage: `url(${backgroundImagePath})`,
              backgroundRepeat: "no-repeat",
              backgroundOrigin: "padding-box",
              backgroundPositionX: "50%",
              backgroundSize: "contain",
              width: "100%"
            }}
          >
            {/* <div className={styles.topHeading}>{`${this.props.routeType === "/class" ? t("common.myClasses") : t("common.myGroups")}`}</div> */}
            <div className={styles.subjectCardsContainer}>
              {showCreateOption && <div className={styles.sampleCardContainer} onClick={this.handleAddClass}>
                <AiOutlinePlus className={styles.plusSign} />
                <div className={styles.sampleCardText}>{`${t("classGroup.createNew")} ${this.props.routeType === "/class" ? t("class.class") : t("group.group")}`}</div>
              </div>}
              {this.props.subjects.map((eachSubject) => (
                <SubjectCard editClassId={this.editClassId} eachSubject={eachSubject} getSubjectDetails={this.getSubjectDetails} editClassModalOpener={this.handleEditClass} deleteClassModalOpener={this.handleDeleteClass} />
              ))}
            </div>
          </div>
          <div className="col-xs-0 col-sm-0 col-md-1"></div>
        </div>
        <div className="col-xs-0 col-sm-0 col-md-3">
          <ChatProvider>
            <Chat toggleChatList={this.props.toggleChatList} isChatListOpen={this.props.isChatListOpen} />
          </ChatProvider>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getClassDetails: (subjectId) => dispatch(getClassDetails(subjectId)),
  };
};

export default withTranslation()(connect(null, mapDispatchToProps)(withRouter(MySubjectsComponent)));
