import React from "react";
import { withRouter } from "react-router";

import styles from "../../../../Profile.module.css";

import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

import { connect } from "react-redux";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { ADD_ACHIEVEMENTS, UPDATE_ACHIEVEMENTS } from "../../../../../../redux/constants/actionTypes";
import { updateAchievement, addAchievement } from "../../../../../../redux/actions/achievement";
import { withTranslation } from "react-i18next";

class EditAchievements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: this.props.modalTitle,
      id: this.props.id,
      upload_img: this.props.upload_img,
      name: this.props.name,
      issuer: this.props.issuer,
      issue_date: this.props.issue_date,
      description: this.props.description,

      pictureAsFile: this.props.upload_img,

      error: false,
      message: "",

      uploadImageFlag: false,
    };

    this.handleImageChange = (event) => {
      this.setState({
        upload_img: URL.createObjectURL(event.target.files[0]),
        pictureAsFile: event.target.files[0],
        uploadImageFlag: true,
      });
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  componentDidMount() {
    if (this.state.upload_img == "") {
      fetch("/Image/apps/profile-resume-edit-view/default-award.png")
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "exp.png", blob);
          this.setState({ pictureAsFile: file, upload_img: "/Image/apps/profile-resume-edit-view/default-award.png" });
        });
    }
  }

  ModalHeaderOnClick() {
    this.props.changeEdit();
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    const achState = {
      id: this.state.id,
      upload_img: this.state.upload_img,
      name: this.state.name,
      issuer: this.state.issuer,
      issue_date: this.state.issue_date,
      description: this.state.description,
      pictureAsFile: this.state.pictureAsFile,

      uploadImageFlag: this.state.uploadImageFlag,
    };
    this.props.changeEdit();

    if (this.props.add) {
      this.props.addAchievement(achState, ADD_ACHIEVEMENTS).then((res) => {
        if (res.val) {

          document.body.style.overflow = "visible";
          return;
        }
        this.setState({ error: true, message: res.message });
      });
    } else {
      this.props.updateAchievement(achState, UPDATE_ACHIEVEMENTS).then((res) => {
        if (res.val) {
          document.body.style.overflow = "visible";
        }
        this.setState({ error: true, message: res.message });
      });
    }
  }

  ScrollBody() {
    const {t} = this.props;
    return (
      <div>
        <div
          style={{
            // borderBottom: '1px solid #5555555A',
            paddingBottom: 20,
          }}
        >
          <div className={styles.imageUpload}>
            <p className={styles.modal_heading}>{t("profile.media")}*</p>
            <div style={{ display: "flex", borderRadius: "5px" }}>
              <img src={this.state.upload_img} style={{ width: "96px", height: "96px", borderRadius: "10px", objectFit: "cover" }} />
              <div style={{ marginLeft: "20px" }}>
                <p className={styles.sub_heading}>{t("profile.addImageOfYourCompany")}</p>
                <label style={{ marginLeft: 30 }}>
                  <div style={{ border: "2px solid #ED1E24", padding: "12px 60px", borderRadius: 40, cursor: "pointer" }}>
                    <p style={{ margin: 0, fontWeight: 600, textTransform: "uppercase", textAlign: "center", color: "#ED1E24" }}>{t("common.upload")}</p>
                  </div>
                  <input type="file" onChange={this.handleImageChange} style={{ cursor: "pointer", display: "none" }} accept="image/png, image/jpeg, image/svg" />
                </label>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/title.svg"/>{t("profile.awardName")}*</div></p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExPresidentAward")}
              maxlength="50"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.name}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
            <br />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/company.svg"/>{t("profile.issuingOrganization")}*</div></p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExInfoSphere")}
              maxlength="50"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.issuer}
              onChange={(event) => {
                this.setState({ issuer: event.target.value });
              }}
            />
            <br />
          </div>

          {/* <p style={{ textAlign: 'right', fontSize: 12, color: '#555555', fontWeight: 500, marginRight: 10 }}>Learn More</p> */}
        </div>

        <div
          style={{
            display: "flex",
            marginBottom: 15,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", marginRight: 20 }}>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/date.svg"/>{t("profile.issueDate")}*</div></p>
            <input
              className={styles.modal_para}
              type="date"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.issue_date}
              onChange={(event) => {
                this.setState({ issue_date: event.target.value });
              }}
            />
            <br />
          </div>
        </div>

        <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/description.svg"/>{t("common.description")}</div></p>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <textarea
            className={styles.modal_para}
            id={styles.textarea}
            cols={60}
            rows={8}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 10,
            }}
            value={this.state.description}
            onChange={(event) => {
              this.setState({ description: event.target.value });
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.state.modalTitle} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateAchievement: (achState, action) => dispatch(updateAchievement(achState, action)),
    addAchievement: (achState, action) => dispatch(addAchievement(achState, action)),
  };
}

export default withTranslation()(connect(null, mapDispatchToProps)(EditAchievements));
