import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import styles from "../../../../Profile.module.css";

import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

import { connect } from "react-redux";

import HandleApiError from "../../../../../../components/commons/HandleApiError";
import {
  updateProfileData,
  getEnterpriseCodes,
} from "../../../../../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../../../../../redux/constants/actionTypes";
import { handleSuccessMessage } from "../../../../../../redux/actions/success";
import { withTranslation } from "react-i18next";

class UpdateInstituteCode extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      about: this.props.about,
      // pictureAsFile: null,
      edit: false,
      text: this.props.about || "",

      error: false,
      message: "error",

      options: [],
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
    this.changeText = this.changeText.bind(this);
  }

  changeText(text) {
    this.setState({ text: text });
  }
  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  ModalHeaderOnClick() {
    this.props.changeEdit(false);
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    const { t } = this.props;
    if (this.state?.text?.length > 4 || this.state?.text?.length == 0) {
      this.setState({ error: true, message: t("errors.instituteCodeLength") });
      return;
    }
    let data = new FormData();
    data.append("dept_code", this.state.text);

    this.props.updateInstituteCode(EDIT_PROFILE_DATA, data).then((res) => {
      if (res.val) {
        this.props.changeEdit();
        document.body.style.overflow = "visible";
        this.props.handleSuccessMessage(t("successMsgs.codeSetSuccessfully!"));

        // console.log("success");
        return;
      }
      this.setState({ error: true, message: "Error!" });
      // console.log(this.state.error);
    });

    // this.props.changeEdit(false);
    // document.body.style.overflow = "visible";
  }

  ScrollBody() {
    const { t } = this.props;
    return (
      <div>
        <p className={styles.modal_heading}>
          {t("profile.schoolUniversityCode")}
        </p>
        <br />
        <div
          style={{
            position: "relative",
            // display: "flex",
            justifyContent: "center",
          }}
        >
          {this.props.user_type == "INDIVIDUAL" ? (
            <Autocomplete
              //   disablePortal
              id="combo-box-demo"
              options={this.state.options}
              onChange={(event, value) => {
                this.setState({ text: value.label });
                // console.log(value.label);
              }}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      border: "1px solid #5555555A",

                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      marginLeft: 20,
                      marginTop: 20,
                      borderRadius: "25px",
                      width: "85%",
                      marginLeft: "15px",
                      minHeight: "42px",
                      height: "max-content",
                      outline: "none",
                      padding: "0 0 0 20px ",
                      marginBottom: 0,
                    }}
                    type="text"
                    {...params.inputProps}
                    placeholder={t("profile.modalPlaceholder.selectYourCode")}
                  />
                </div>
              )}
            />
          ) : (
            // null
            <input
              type="text"
              id="textarea"
              className={styles.modal_para}
              placeholder={t("profile.modalPlaceholder.typeYourCode")}
              value={this.state.text}
              style={{
                // border: "1px solid #5555555A",
                // padding: 10,
                // fontSize: 15,
                // borderRadius: 50,
                // width: "85%",
                // margin: "auto",
                // marginTop: 20,

                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginLeft: 20,
                marginTop: 20,
                borderRadius: "25px",
                width: "85%",
                marginLeft: "15px",
                minHeight: "42px",
                height: "max-content",
                outline: "none",
                padding: "0 0 0 20px ",
                marginBottom: 0,
              }}
              onChange={(event) => {
                this.changeText(event.target.value);
              }}
            />
          )}
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.props.getEnterpriseCodes().then(() => {
      this.setState({
        options: this.props.codeSuggestions.map((value) => ({
          label: value.code,
        })),
      });
      // console.log(this.state.options)
    });
  }
  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader
          title={this.props.modalTitle}
          onClick={this.ModalHeaderOnClick}
        />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => this.setState({ error: false })}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    deptCode: state?.profile?.deptCode || "",
    user_type: state?.profile?.user?.user_type || "",
    codeSuggestions: state?.profile?.enterprise_codes || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateInstituteCode: (action, data) =>
      dispatch(updateProfileData(action, data)),
    getEnterpriseCodes: () => dispatch(getEnterpriseCodes()),
    handleSuccessMessage: (successMsg) =>
      dispatch(handleSuccessMessage(successMsg)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(UpdateInstituteCode)
);
