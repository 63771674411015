import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getPosts } from "../../../redux/actions/dashboard";
import { getGroups } from "../../../redux/actions/groups";
import styles from "./CSSFiles/SidebarGroup.module.css";
import { Link } from "react-router-dom";
import {withTranslation} from "react-i18next"
class SidebarGroup extends Component {
  componentDidMount = () => {
    this.props.getGroups();
    // this.props.getPosts();
  };
  getSubjectDetails = (subjectId) => {
    let { history } = this.props;
    history.push({
      pathname: `/group/${subjectId}`,
      state: { subjectId: subjectId },
    });
  };

  sortfunction = (myGroup) => {
    myGroup.sort(function (a, b) {
      return b.id - a.id;
    });
  };
  render() {
    return (
      <div>
        {this.sortfunction(this.props.myGroups)}
        {this.props.myGroups.length != 0 ? (
          this.props.myGroups.map((data, index) => {
            if (index < 3) {
              return (
                <div className={styles.sidebarClass} onClick={() => this.getSubjectDetails(data.id)}>
                <div className={ data.coverImg ? styles.classImage : styles.classImageHover }>
                  <img className={styles.classimg} src={data.coverImg || "/Image/DashImage/class-cover.png"} />
                </div>
                <div className={styles.classPara}>
                  <p style={{fontSize: "16px", fontWeight: "600", color: "black"}}>{data.name}</p>
                  <div style={{textAlign: "left", padding: "2px 0 0 0", fontSize: "14px", fontWeight: "300"}}>{data.grade}</div>
                  <div style={{textAlign: "left", padding: "2px 0 0 0", fontSize: "14px", fontWeight: "300"}}>{data.group_owner_name}</div>
                </div>
              </div>
              );
            }
          })
        ) : (
          <div className={styles.classText}>{this.props.t("dashboard.noGroupSidebar")}</div>
        )}
        {this.props.myGroups.length > 3 ? (
          <div className={styles.sidebarClass}>
            <div className={styles.sidebar_stat1}>
              <Link to="/groups" style={{ textDecoration: "none", color: "#ED1E24" }}>
              {this.props.t("dashboard.allGroups")}
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user || "",
    myGroups:
      state?.groups?.groups?.map((each_group) => {
        return {
          name: each_group.name,
          id: each_group.id,
          coverImg: each_group.cover_image,
          group_owner_name: each_group.group_owner_name,
          grade: each_group.grade
        };
      }) || [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getGroups: () => dispatch(getGroups()),
    // getPosts: () => dispatch(getPosts()),
  };
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarGroup)));
