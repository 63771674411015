import { gql } from "graphql-request";

export const CREATE_QUIZ_TEMPLATE_MUTATION = gql`
  mutation ($input: TemplateInput!) {
    create_template(input: $input) {
      id
      temp_owner {
        id
        name
      }
      title
      details
      marks
      questions {
        id
        text
        marks
        is_mandatory
        question_type
        choice {
          id
          is_correct
          img
          choice_text
          correct_choice
          correct_list {
            correct_choice
          }
        }
      }
      timestamp
      utimestamp
      ques_count
    }
  }
`;

// ------ To Be Done ------
export const CREATE_QUIZ_MUTATION = gql`
  mutation ($input: QuizInput!) {
    create_quiz(input: $input) {
      id
      title
      details
      max_marks
      due_date
      isPublished
      template {
        id
        title
        marks
        questions {
          id
          text
          marks
          is_mandatory
          question_type
        }
        ques_count
      }
      is_timed
      duration
      question_randomized
      reattempt
      show_answers
      timestamp
      utimestamp
    }
  }
`;

export const UPDATE_QUIZ_TEMPLATE_MUTATION = gql`
  mutation ($id: ID!, $input: TemplateUpdateInput!) {
    update_template(id: $id, input: $input) {
      id
      temp_owner {
        id
        name
      }
      title
      details
      marks
      questions {
        id
        text
        marks
        is_mandatory
        question_type
        choice {
          id
          choice_text
          correct_choice
          is_correct
          img
          correct_list {
            correct_choice
          }
        }
      }
      timestamp
      utimestamp
      ques_count
    }
  }
`;

export const DELETE_QUIZ_TEMPLATE_MUTATION = gql`
  mutation ($input: ID!) {
    delete_template(id: $input)
  }
`;

export const DELETE_QUIZ_MUTATION = gql`
  mutation ($input: ID!) {
    delete_quiz(id: $input)
  }
`;

export const UPDATE_QUIZ_MUTATION = gql`
  mutation ($id: ID!, $input: QuizUpdateInput!) {
    update_quiz(id: $id, input: $input) {
      id
      quiz_owner {
        id
        name
      }
      title
      details
      max_marks
      template {
        id
        title
        marks
      }
      due_date      
      timestamp
      utimestamp
      
    }
  }
`;

// ----------- submitQuiz / attemptQuiz ----------

export const SUBMIT_QUIZ_MUTATION = gql`
  mutation ($id: ID!, $input: QuizSubmissionUpdateInput!) {
    update_quiz_submission(id: $id, input: $input) {
    id    
    quiz {
      id
      template {
        id
        title
      }
      classes{
        id
        name
      }
      quiz_owner{
        id
        name
      }
      title
      details
      submission_count {
        assigned
        submitted
        graded
      }
      max_marks
      show_answers
      is_active
      is_timed
      duration
      cut_off_score
      question_randomized
      reattempt
      due_date
      is_lock
      timestamp
      utimestamp
    }
    students {
      id
      name
      
    }
    attempt
    submission_status
    marks
    time_taken
    user_answers
    is_returned
    is_submitted
    submission_date
  }
  }
`;


export const UPDATE_QUIZ_MARKS_MUTATION = gql`
mutation ($id: ID!, $input: QuizSubmissionUpdateInput!) {
  update_quiz_submission(id: $id, input: $input) {
  id    
  quiz {
    id
    template {
      id
      title
    }
    classes{
      id
      name
    }
    quiz_owner{
      id
      name
    }
    title
    details
    submission_count {
      assigned
      submitted
      graded
    }
    max_marks
    show_answers
    is_active
    is_timed
    duration
    cut_off_score
    question_randomized
    reattempt
    due_date
    is_lock
    timestamp
    utimestamp
  }
  students {
    id
    name
    
  }
  attempt
  submission_status
  marks
  time_taken
  user_answers
  is_returned
  is_submitted
  submission_date
}
}
`;



export const RETURN_QUIZ_SUBMISSION_MUTATION = gql`
  mutation ($input: [ID!]!) {
    return_quiz_submissions(sub_list: $input)
  }
`;

export const UNASSIGN_QUIZ_MUTATION = gql`
  mutation ($input: [ID!]!) {
    delete_quiz_submission(id: $input)
  }
`;

export const ASSIGN_QUIZ_MUTATION = gql`
  mutation ($input: QuizSubmissionInput! ) {
    create_quiz_submission(input: $input)
  }
`;