import React, { useState, useEffect } from "react";
import styles from "./css/IndividualAssignmentGradebook.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { CircularProgress } from "@mui/material";
import {
  downloadGradebook,
  getAsgQuizResults,
  downloadIndividualAsgQuizGradebook
} from "../../redux/actions/gradebook";
import IconTitle from "../../components/commons/IconTitle";
import StudentNameComponent from "./Components/StudentNameComponent";
import ButtonMenuComponent from "./Components/ButtonMenuComponent";
import { formatDate } from "../../redux/constants/commonFunctions";
import AttachmentContainer from "../../components/commons/assignmentAndQuiz/AttachmentContainer";

const IndividualAssignmentGradebook = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const routeType = location?.state?.routeType || "class";
  // const classOrGroupName = useSelector((state) =>
  //   routeType === "class"
  //     ? state?.classes?.class?.name
  //     : state?.groups?.group?.name
  // );
  const classOrGroupName = location?.state?.classOrGroupName;

  const classOrGroupId = parseInt(location?.state?.classOrGroupId);
  const profileId = parseInt(location?.state?.profileId);
  const profileData = location?.state?.profileData;
  const assignment = location?.state?.asgQuiz;

  const printHandler = () => {
    setIsPrinting(true);
    console.log("Print this", classOrGroupId, profileId);
    if(assignment?.id){
      dispatch(downloadIndividualAsgQuizGradebook(parseInt(assignment?.id), true))
      .then(()=>setIsPrinting(false));
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loader_style}>
        <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
      </div>
    );
  } else {
    return (
      <div className={styles.main_container}>
        {console.log("IndProfile", profileData)}
        {console.log("IndAsg", assignment)}
        <h5
          className={styles.back_btn}
          onClick={() => {
            if (classOrGroupId) {
              history.push({
                pathname: `/${routeType}/${classOrGroupId}`,
              });
            } else {
              history.push({
                pathname: `/user`,
              });
            }
          }}
        >
          <img src="/Image/assignment/back_arrow.svg" alt="" />
          &nbsp; &nbsp;
          {classOrGroupName ?? t("courseMonetisation.goBackBtn")}
        </h5>

        <div className={styles.gradebook_outer_container}>
          <IconTitle
            title={t("gradebook.gradebook")}
            img="/Image/Gradebook/star-white2.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />

          <div className={styles.gradebook_inner_container}>
            <div className={styles.header}>
              <StudentNameComponent
                studentName={profileData?.name}
                profilePic={profileData?.profile_pic}
                classOrGroupName={classOrGroupName}
              />

              <div className={styles.asg_info}>
                <p>
                  {t("assignment.assignment")}: {assignment?.assignment?.title}
                </p>
                <p>
                  {t("viewAssignment.submittedOn")}:{" "}
                  {assignment?.is_submitted
                    ? formatDate(assignment?.submission_date)
                    : "—"}
                </p>
              </div>

              <div className={styles.avg_score_container}>
                <p className={styles.label}>{t("gradebook.averageScore")}: </p>
                <p className={styles.avgScore}>{`${
                  assignment?.percentage || 0
                }%`}</p>
              </div>

              <ButtonMenuComponent onPrint={printHandler} isPrinting={isPrinting} />
            </div>

            {/* --- Table --- */}
            <div className={styles.assignment_wrapper}>
              <div className={styles.assignment_container}>
                <p className={styles.subheading}>
                  {t("assignment.assignment")}
                </p>
                <div className={styles.file_container}>
                  <p className={styles.description}>
                    {assignment?.assignment?.details}
                  </p>

                  {/* --- file or link ---- */}
                  <div className={styles.attachment_container_div}>
                    {assignment?.fileSubmitted && (
                      <>
                        <br />
                        <AttachmentContainer
                          assignmentFileType="FILE"
                          fileName={`${assignment?.fileSubmitted
                            ?.split("/")
                            .pop()
                            .substr()}`}
                          fileSize=""
                          file={assignment?.fileSubmitted}
                          pageType=""
                        />
                      </>
                    )}
                    {assignment?.url && (
                      <>
                        <br />
                        <AttachmentContainer
                          assignmentFileType="LINK"
                          linkName={assignment?.url
                            ?.split("/")
                            .pop()
                            .substr()}
                          fileSize=""
                          link={assignment?.url}
                          pageType=""
                        />
                      </>
                    )}

                    {assignment?.url == null && assignment?.fileSubmitted == null && <p className={styles.no_sub}>{t("gradebook.noSubmission")}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default IndividualAssignmentGradebook;
