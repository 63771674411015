import React from "react";
import styles from "./StudentNameComponent.module.css";
import Avatar from "@mui/material/Avatar";

const StudentNameComponent = (props) => {
  // props: studentName, profilePic
  return (
    <div className={styles.name_container}>
      <Avatar
        // sx={{ width: 70, height: 70 }}
        sx={{
          width: {
            xs: 65, // theme.breakpoints.up('xs')
            sm: 65, // theme.breakpoints.up('sm')
            md: 70,
          },
          height: {
            xs: 65, // theme.breakpoints.up('xs')
            sm: 65, // theme.breakpoints.up('sm')
            md: 70,
          },
        }}
        className={styles.avatar}
        src={props.profilePic}
      />
      <div style={{display: "flex", flexDirection: "column"}}>
        <span className={styles.student_name}>{props.studentName}</span>
        <span className={styles.class_name}>{props.classOrGroupName}</span>
      </div>
    </div>
  );
};

export default StudentNameComponent;
