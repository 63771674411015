import React, { useState } from "react";
import styles from "./OpenToWorkModal.module.css";
import { ModalWithHeader } from "../../apps/Dashboard/shared/ui/Modal/Modal";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

import ButtonMUI from "@mui/material/Button";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from "react-redux";
import { saveOpenToWork } from "../../redux/actions/profile";
import { handleErrorMessage } from "../../redux/actions/classes";

const OpenToWorkModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editData = useSelector((state) => state?.profile?.open_to_work);

  console.log("editData", editData)

  let isEdit = props.isEdit || false;

  const [selectedTags, setSelectedTags] = useState([]);
  const [dateType, setDateType] = useState(isEdit ? [...editData?.start_availability]?.[0]?.toLowerCase() : "");
  const [jobTitles, setJobTitles] = useState(isEdit ? [...editData?.job_title]  : []);
  const [inputText, setInputText] = useState();

  const [selectedLocations, setSelectedLocations] = useState(isEdit ? [...editData?.preferred_work_location?.map(x => x.toLowerCase())]  : []);
  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState(isEdit ? [...editData?.employment_types?.map(x => x.toLowerCase())] : [])
  const [visibility, setVisibility] = useState(isEdit ? editData?.visibility : false);

  const profileId = useSelector(state => state?.profile?.profileId)

  console.log("stateData", {
    jobTitles,
    selectedLocations,
    selectedEmploymentTypes,
    dateType

  })

  const addTagHandler = (tag) => {
    let data = [...selectedTags];
    let index = data.findIndex((x) => x === tag);

    if (index >= 0) {
      data = data.filter((x) => x != tag);
    } else {
      data.push(tag);
    }
    setSelectedTags(data);
  };

  const addLocationHandler = (tag) => {
    let data = [...selectedLocations];
    let index = data.findIndex((x) => x === tag);

    if (index >= 0) {
      data = data.filter((x) => x != tag);
    } else {
      data.push(tag);
    }
    setSelectedLocations(data);
  };

  const addEmploymentTypeHandler = (tag) => {
    let data = [...selectedEmploymentTypes];
    let index = data.findIndex((x) => x === tag);

    if (index >= 0) {
      data = data.filter((x) => x != tag);
    } else {
      data.push(tag);
    }
    setSelectedEmploymentTypes(data);
  };

  const addJobTitle = (title) => {
    if (title != "") {
      let data = [...jobTitles];
      data.push(title.trim());
      setJobTitles(data);
    }
  };

  const removeJobTitle = (title) => {
    console.log("remove called", title);
    let data = [...jobTitles].filter((x) => x != title);
    setJobTitles(data);
  };

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "20px",
      padding: "5px 11px 6px 11px",
    },
  };

  console.log("selected tags", selectedTags, dateType, jobTitles);

  console.log("isEdit", props.isEdit)

  const handleStartDate = (e) => {
    console.log("jobStartDate", e.target.value);
    setDateType(e.target.value);
  };

  const handleJobTitleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 && inputText.trim() !== "") {
      setJobTitles([...jobTitles, inputText]);
      setInputText(""); // Clear the input after adding
    }
  };

  const handleSave = () => {
    if(jobTitles?.length < 1) {
      dispatch(handleErrorMessage(t("openToWorkError.enterJobTitle")));
      return null;
    }
    if(selectedEmploymentTypes?.length < 1) {
      dispatch(handleErrorMessage(t("openToWorkError.addEmploymentType")));
      return null;
    }
    if(selectedLocations?.length < 1) {
      dispatch(handleErrorMessage(t("openToWorkError.addLocation")));
      return null;
    }
    if(dateType === "") {
      dispatch(handleErrorMessage(t("openToWorkError.addDate")));
      return null;
    }

    // if(dateType === "")
    
    const payload = {
      "job_title": jobTitles,
      "preferred_work_location": selectedLocations?.map(x => x.toUpperCase()),
      "start_availability": [dateType]?.map(x => x.toUpperCase()),
      "employment_types": selectedEmploymentTypes?.map(x => x.toUpperCase()),
      "visibility": visibility,
      // "profile_id": profileId,
  }

  if(!isEdit)  {
    payload.profile_id = profileId;

  }

  console.log("save payload", payload)

  dispatch(saveOpenToWork(payload, isEdit, editData?.id)).then(()=> props.onClose())

  }

  const radioCheckboxStyle = {
    "&.Mui-checked": { color: "#ED1E24" },
    marginRight: "0px",
    fontFamily: "Montserrat",
    "&.Mui-disabled": {
      color: "#959595",
    },
  };

  return (
    <>
      <ModalWithHeader
        title={t("openToWork.jobPreference")}
        show={props.open}
        closeHandler={props.onClose}
        maxWidth="32rem"
        minWidth="21rem"
      >
        <div className={styles.middle_container}>
          <div className={styles.title_label}>
            <p>{t("openToWork.jobTitle")}</p>

            <div className={styles.tag_container}>
              {jobTitles?.length > 0 &&
                jobTitles.map((item, index) => (
                  <div
                    key={`${index}${item}`}
                    onClick={() => removeJobTitle(item)}
                    className={styles.selected_tag}
                  >
                    <p>{t(`${item}`)}</p>
                    <ClearIcon />
                  </div>
                ))}
            </div>

            <input
              className={styles.input_field}
              placeholder={t("openToWork.inputPlaceholder")}
              onChange={handleJobTitleInputChange}
              onKeyDown={handleKeyPress}
              value={inputText}
            />
          </div>
          <div className={styles.title_label}>
            <p>{t("openToWork.locationType")}</p>

            <div className={styles.tag_container}>
              {["onsite", "remote", "hybrid"].map((item, index) => (
                <div
                  key={`${index}${item}`}
                  onClick={() => addLocationHandler(item)}
                  className={
                    selectedLocations?.includes(item)
                      ? styles.selected_tag
                      : styles.tag
                  }
                >
                  <AddIcon />
                  <p>{t(`openToWork.${item}`)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.title_label}>
            <p>{t("openToWork.startDate")}</p>
            <FormControl sx={{ fontWeight: 500 }}>
              <RadioGroup
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={dateType}
                onChange={handleStartDate}
              >
                <FormControlLabel
                  sx={{
                    marginRight: "5px",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                  value={"immediately"}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        "&.Mui-checked": { color: "#ED1E24" },
                        marginRight: "0px",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    />
                  }
                  label={t("openToWork.immediately")}
                />

                <FormControlLabel
                  sx={{
                    marginRight: "5px",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                  value={"flexible"}
                  control={
                    <Radio
                      size="small"
                      sx={{
                        "&.Mui-checked": { color: "#ED1E24" },
                        marginRight: "0px",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    />
                  }
                  label={t("openToWork.flexible")}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={styles.title_label}>
            <p>{t("openToWork.employmentType")}</p>

            <div className={styles.tag_container}>
              {[
                "fulltime",
                "parttime",
                "contract",
                "internship",
                "temporary",
              ].map((item, index) => (
                <div
                  key={`${index}${item}`}
                  onClick={() => addEmploymentTypeHandler(item)}
                  className={
                    selectedEmploymentTypes?.includes(item)
                      ? styles.selected_tag
                      : styles.tag
                  }
                >
                  <AddIcon />
                  <p>{t(`openToWork.${item}`)}</p>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.title_label}>
            <p>{t("openToWork.visibility")}</p>

            <div className={styles.checkbox_container}>
              <Checkbox
                checked={visibility}
                sx={radioCheckboxStyle}
                onChange={(event) => {
                  setVisibility(event.target.checked);
                  console.log("checkbox", event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
              <p className={styles.add_frame}>
                {t("openToWork.addOpenToWorkPhotoFrame")}
              </p>
            </div>
          </div>

          <div className={styles.bottom_container}>
            <p onClick={props.onClose} className={styles.btn_label_cancel}>
              {t("common.cancel")}
            </p>
            {/* <p>Save</p> */}

            <ButtonMUI
              variant="outlined"
              disableRipple
              onClick={handleSave}
              sx={styleButtonMUI}
            >
              <span className={styles.btn_label}>{t("common.save")}</span>
            </ButtonMUI>
          </div>
        </div>
      </ModalWithHeader>
    </>
  );
};

export default OpenToWorkModal;
