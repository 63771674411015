import React, { Component } from "react";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import styles from "./css/qrModal.module.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ShareModal from "../shareModal/ShareModal";
// import { QRCode } from "qrcode";
import QRCode from "qrcode.react";
import { withTranslation } from "react-i18next";

class QRModal extends React.Component {
  state = {
    // show: false,
    showShareModal: false,
    link: "ccy-beag-tdz",
  };

  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };

  downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };
  shareCode = () => {
    this.shareModalOpener();
  };
  onLinkChange = (link) => {};
  render() {
    return (
      <div>
        <ModalWithHeader
          title={this.props.t("modals.myQr")}
          show={this.props.show}
          // closeHandler={this.props.closeHandler}
          closeHandler={this.props.closeModal}
          className={styles.qrModalContainer}
        >
          <div className={styles.container}>
            <div className={styles.qrImage}>
              <img
                src={this.props.profilePicUrl || "/Image/DashImage/profile.png"}
              />
            </div>
            <div className={styles.qrdetails}>
              <h3 style={{ textTransform: "capitalize" }}>
                {this.props.name ??
                  this.props.user.first_name +
                    " " +
                    this.props.user.last_name ??
                  ""}
                <br />
              </h3>
              {/* <h4>{this.props.designation}</h4> */}
              <h4>
                {this.props?.user?.user_subtype
                  ? this.props.user.user_subtype
                  : "Other"}
              </h4>
            </div>
            <div className={styles.qrcode}>
              {/* <img src="/Image/DashImage/qr.svg" /> */}

              <QRCode
                value={`${window.location.origin}${
                  "/profile/" + this.props.profile_id + "/view"
                }`}
                id="qrCodeEl"
                size={111}
                style={{ height: "200px", width: "180px" }}
              />
              <p>{this.props.t("modals.scanCode")}</p>
            </div>
            <ShareModal
              closeModal={this.shareModalCloser}
              show={this.state.showShareModal}
              link={this.state.link}
            />
            <div className={styles.qrbuttons}>
              <button className={styles.qrButton} onClick={this.shareCode}>
                {this.props.t("modals.shareCode")}
              </button>
              <a onClick={this.downloadQRCode} download="qrcode">
                <button className={styles.qrButton}>
                  {" "}
                  {this.props.t("modals.saveGallery")}
                </button>
              </a>
            </div>
          </div>
        </ModalWithHeader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "",
    profilePicUrl: state.profile.userProfilePic,
    designation: state?.profile?.about?.designation,
    profile_id: state?.profile?.user?.profile_id || "4",
    name: state?.profile?.name,
  };
};

export default withTranslation()(connect(mapStateToProps, null)(QRModal));
