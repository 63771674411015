import React from "react";

import AboutFeed from "./AboutFeed";
import FeaturedFeed from "./FeaturedFeed";
import InterestsFeed from "./InterestsFeed";
import ExperienceFeed from "./ExperienceFeed";
import EducationFeed from "./EducationFeed";
import LicencesFeed from "./LicencesFeed";
import VolunteerFeed from "./VolunteerFeed";
import EndorsementsFeed from "./EndorsmentsFeed";
import RecommendationsFeed from "./RecommendationsFeed";
import AchievementsFeed from "./AchievementsFeed";
import MidDpicFeed from "./MidDpicFeed";
import EditDescFeed from "./EditDescFeed";
import SocailFeed from "./SocialFeed";
import { store } from "../../../../../redux/store";
import InstituteCode from "./InstituteCode";
import styles from "../../../Profile.module.css";
import MyPostsFeed from "./MyPostsFeed";
import PublicationFeed from "./PublicationFeed";
import ProjectFeed from "./ProjectFeed";
import ResearchFeed from "./ResearchFeed";
import TrainingFeed from "./TrainingFeed";

export default class LeftFeed extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          justifyContent: "center",
        }}
      >
        <div className={styles.leftbar_hamburger_view}>
          <div className={styles.dpic_inside_ham}>
            <MidDpicFeed props={this.props} />
          </div>

          <EditDescFeed editpage={true} mobileView={true} />
          <br />
          <InstituteCode editpage={true} />
          <br />
          <SocailFeed />
          <br />
          <br />
        </div>

        {this.props.showMyPostsFeed ? <MyPostsFeed/> : <div className={styles.profile_sections}>
          <div id="About" style={{ scrollMarginTop: 120 }}>
            <AboutFeed editpage={this.props.editpage} />
          </div>

          <br />
          <br />

          <div
            id="Featured"
            class={styles.Featured}
            style={{ scrollMarginTop: 120 }}
          >
            <FeaturedFeed editpage={this.props.editpage} />
          </div>

          <br />
          <br />
          <div id="Interests" style={{ scrollMarginTop: 120 }}>
            <InterestsFeed />
          </div>

          <br />
          <br />

          <div id="Experience" style={{ scrollMarginTop: 120 }}>
            <ExperienceFeed editpage={this.props.editpage} />
          </div>

          <br />
          <br />

          {store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? (
            <div id="Education" style={{ scrollMarginTop: 120 }}>
              <EducationFeed editpage={this.props.editpage} />
            </div>
          ) : null}

          <br />
          <br />

          <div
            id="Licenses and Certifications"
            style={{ scrollMarginTop: 120 }}
          >
            <LicencesFeed editpage={this.props.editpage} />
          </div>

          <br />
          <br />

          <div id="Volunteer Experience" style={{ scrollMarginTop: 120 }}>
            <VolunteerFeed editpage={this.props.editpage} />
          </div>

          <br />
          <br />

        
          <div id="Patents" style={{ scrollMarginTop: 120 }}>
            <PublicationFeed isEditPage={true}/>
          </div>

          <br />
          <br />

          <div id="Consultancy" style={{ scrollMarginTop: 120 }}>
            <ProjectFeed isEditPage={true}/>
          </div>

          <br />
          <br />

          <div id="Research" style={{ scrollMarginTop: 120 }}>
            <ResearchFeed isEditPage={true}/>
          </div>

          <br />
          <br />

          <div id="Training" style={{ scrollMarginTop: 120 }}>
            <TrainingFeed isEditPage={true}/>
          </div>

          <br />
          <br />

          <div id="Skills and endorsements" style={{ scrollMarginTop: 120 }}>
            <EndorsementsFeed editpage={this.props.editpage} />
          </div>

          <br />
          <br />

          <div id="Achievements" style={{ scrollMarginTop: 120 }}>
            <AchievementsFeed editpage={this.props.editpage} />
          </div>

          <br />
          <br />
        </div>}
      </div>
    );
  }
}
