import { handleErrorMessage } from "./groups";
import axios from "axios";
import { handleSuccessMessage } from "./success";

import i18n from "../../i18nextConf";

export const forgotPassword = (emailData) => {
  emailData = { email: emailData };
  return async (dispatch) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/password_reset/`,
      data: emailData,
    })
    .then((response) => {
      if (
        response?.status >= 200 &&
        response?.status < 300 
      ) {
        dispatch(
          handleSuccessMessage(
            i18n.t("signin.emailSent")
          )
        );
      }
    })
      .catch(async (error) => {
        // const errorMsg =
        //   error?.response?.data?.error?.detail ||
        //   error?.response?.data?.error ||
        //   "Network Error";
        dispatch(handleErrorMessage("Error to process the request!"));
        return error;
      });
  };
};

export const resetPassword = (payload) => {
  return async (dispatch) => {
    let data = new FormData();
    data.append("token", payload.token);
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/password_reset/validate_token/`,
      data: data,
    })
      .then((res) => {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/password_reset/confirm/`,
          data: payload,
        })
          .then(() => {})
          .catch((error) => {
            // const errorMsg =
            // error?.password ||
            // "Network Error";
            // console.log("Error", error?.password)
            dispatch(handleErrorMessage("Error to process the request!"));
          });
      })
      .catch(async (error) => {
        // const errorMsg =
        //   error?.response?.data?.error?.detail ||
        //   error?.response?.data?.error ||
        //   "Network Error";
        dispatch(handleErrorMessage("Error to process the request!"));
        return error;
      });
  };
};

/* export const validateToken = (token) => {
  return async (dispatch) => {
    await axios({
      method: "post",
      url: (`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/password_reset/validate_token/`),
      data: token,
    })
      .then((response) => {
        
      })
      .catch(async (error) => {
        const errorMsg =
          error?.response?.data?.error?.detail ||
          error?.response?.data?.error ||
          "Network Error";
        dispatch(handleErrorMessage(errorMsg));
        return error;
      });
    };
  }; */
