import { React, useRef, useState } from "react";
import { connect } from "react-redux";
import { loginUser, signUpUser } from "../../../redux/actions/login";
import "../Sign.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SignNav from "../../home/Nav/SignNav";
import validator from "validator";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Signup({ user, error, loginUser, signUpUser, status, errors }) {
  const horizontal = "center";
  const vertical = "top";
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const {t} = useTranslation()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [enterpriseCode, setEnterpriseCode] = useState("");
  const [showCodeError, setShowCodeError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstNameValidation, setFirstNameValidation] = useState("");
  const [enterpriseNameValidation, setEnterpriseNameValidation] = useState("");
  const [emailValidation, setEmailValidation] = useState(0);
  const [passwordValidation, setPasswordValidation] = useState(0);

  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const history = useHistory();
  const userRef = useRef(user);
  userRef.current = user;
  const errorRef = useRef(error);
  errorRef.current = error;
  const statusRef = useRef(status);
  statusRef.current = status;
  const errorsRef = useRef(errors);
  errorsRef.current = errors;

  function handleSubmit() {
    // const pattern = /^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/;
    if (category == "INDIVIDUAL") {
      if (firstName === "") {
        setFirstNameValidation(1);
        return;
      } else {
        setFirstNameValidation(0);
      }
    }
    if (category == "ENTERPRISE") {
      if (enterpriseName === "") {
        setEnterpriseNameValidation(1);
        return;
      } else {
        setEnterpriseNameValidation(0);
      }
    }

    if (email === "") {
      setEmailValidation(1);
      return;
    }

    if(!validator.isEmail(email)){
      setEmailValidation(2)
      return;
    }
    
    /*     if(!email.match(pattern)){
      setEmailValidation(2)
      return
    }
    else{
      setEmailValidation(0)
    } */

    if (password === "" && confirmPassword === "") {
      setPasswordValidation(1);
      return;
    }
    if (password != confirmPassword) {
      setPasswordValidation(2);
      return;
    }

    if (category == "ENTERPRISE" && !enterpriseCode) {
      setShowCodeError(true);
      return;
    }

    
    /* history.push("/user"); */
    let item = {
      email: email,
      phone: "",
      user_type: category,
      user_subtype: subCategory,
      password: password,
      first_name: firstName,
      last_name: lastName,
      enterprise_name: enterpriseName,
    };

    if (category == "ENTERPRISE" && enterpriseCode) {
      item.dept_code = enterpriseCode
    }

    signUpUser(item).then((data) => {
      if (statusRef.current === 201) {
        loginUser(email, password).then(() => {
            if (userRef.current?.token) {
              history.push("/user");

                } else {
                  setMessage(errorsRef.current);
                  setOpen(true);
                  setSeverity("error");
                    }
                });
            setMessage("Account Created Successfuly");
            setOpen(true);
            setSeverity("success");
      
              }
      else{
        setMessage(errorsRef.current);
        setOpen(true);
        setSeverity("error");
      }
    })
    // const signupapiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/users/`;

    // try {
    //   axios
    //     .post(signupapiUrl, JSON.stringify(item), {
    //       headers: {
    //         "content-type": "application/json",
    //       },
    //     })
    //     .then((res) => {
    //       if (res.status === 201) {
    //         loginUser(email, password).then(() => {
    //           if (userRef.current?.token) {
    //             history.push("/user");
    //           } else {
    //             setMessage("Incorrect credentials/Network Error");
    //             setOpen({ open: true });
    //             setSeverity("error");
    //           }
    //         });
    //         setMessage("Account Created Successfuly");
    //         setOpen({ open: true });
    //         setSeverity("success");
    //       }
    //     })
    //     .catch((err) => {
    //       setMessage("Email already exist");
    //       setOpen({ open: true });
    //       setSeverity("info");
    //     });
    // } catch (err) {
    //   setMessage("Something went wrong!");
    //   setOpen({ open: true });
    //   setSeverity("error");
    // }
  }

  return (
    <div className="sign-container">
      {/* <img src="Image/signbgLeft.svg" alt="#" /> */}
      <div className="sign-container-left">
      <SignNav/>
        <div className={`sign-form ${subCategory === "" ? "" : "hidden"}`}>
          <div className="sign-heading">
            <div>{t("signup.selectCategory")}</div>
            <div>{t("signup.accountCreateDesc")}</div>
          </div>

          <div className={`category-section ${category === "" ? "" : "hidden"}`}>
            <div className="category-heading">{t("signup.chooseCategory")}</div>
            <div className="category-first">
              <div className="category-card" onClick={() => setCategory("INDIVIDUAL")}>
                <img src="Image/signImage/individual.png" alt="#" />
                <div>{t("signup.individual")}</div>
              </div>
              <div className="category-card" onClick={() => setCategory("ENTERPRISE")}>
                <img src="Image/signImage/enterprise.png" alt="#" />
                <div>{t("signup.enterprise")}</div>
              </div>
            </div>
          </div>

          <div className={`category-section ${category === "INDIVIDUAL" ? "" : "hidden"}`}>
            <div className="category-heading">{t("signup.subCategory")}</div>
            <div className="category-first">
              <div className="category-card" onClick={() => setSubCategory("FACULTY")}>
                <img src="Image/signImage/teacher.png" alt="#" />
                <div>{t("common.teacher")}</div>
              </div>
              <div className="category-card" onClick={() => setSubCategory("STUDENT")}>
                <img src="Image/signImage/student.png" alt="#" />
                <div>{t("common.student")}</div>
              </div>
              <div className="category-card" onClick={() => setSubCategory("TRAINER")}>
                <img src="Image/signImage/trainer.png" alt="#" />
                <div>{t("common.trainer")}</div>
              </div>
              <div className="category-card" onClick={() => setSubCategory("PROFESSIONALL")}>
                <img src="Image/signImage/individual.png" alt="#" />
                <div>{t("common.professional")}</div>
              </div>
            </div>
          </div>

          <div className={`category-section ${category === "ENTERPRISE" ? "" : "hidden"}`}>
            <div className="category-heading">{t("signup.subCategory")}</div>
            <div className="category-first">
              <div className="category-card" onClick={() => setSubCategory("UNI_CLG")}>
                <img src="Image/signImage/university.png" alt="#" />
                <div>{t("common.university")}</div>
              </div>
              <div className="category-card" onClick={() => setSubCategory("SCHOOL")}>
                <img src="Image/signImage/school.png" alt="#" />
                <div>{t("common.k12School")}</div>
              </div>
              <div className="category-card" onClick={() => setSubCategory("PVT_COACHING")}>
                <img src="Image/signImage/building.png" alt="#" />
                <div>{t("common.privateCoaching")}</div>
              </div>
              <div className="category-card" onClick={() => setSubCategory("COMPANY")}>
                <img src="Image/signImage/enterprise.png" alt="#" />
                <div>{t("common.company")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`sign-form ${subCategory === "" ? "hidden" : ""}`}>
          <div className="sign-heading">
            <div>{t("common.signup")}</div>
            <div>{t("signup.accountCreateDesc")}</div>
          </div>

          {/* --------------------------------- Individual Category Form-------------------------------- */}

          <div className={`sign-input ${category === "INDIVIDUAL" ? "" : "hidden"}`}>
            <div className="sign-name">
              <input className="sign-field" onChange={(e) => setFirstName(e.target.value)} type="text" placeholder={t("signup.firstName")} />
              <input className="sign-field" onChange={(e) => setLastName(e.target.value)} type="text" placeholder={t("signup.lastName")} />
            </div>
            <div className={`input-alert ${firstNameValidation === 1 ? "" : "hidden"}`}>{t("errors.firstNameError")}</div>
            <input className="sign-field" onChange={(e) => setEmail(e.target.value)} type="text" placeholder={t("common.email")} />
            <div className={`input-alert ${emailValidation === 1 ? "" : "hidden"}`}>{t("errors.emailError")}</div>
            <div className={`input-alert ${emailValidation === 2 ? "" : "hidden"}`}>{t("errors.validEmail")}</div>

            <input className="sign-field" onChange={(e) => setPassword(e.target.value)} type="password" placeholder={t("common.password")} />
            <div className={`input-alert ${passwordValidation === 1 ? "" : "hidden"}`}>{t("errors.passwordError")}</div>
            <input className="sign-field" onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder={t("signup.confirmPassword")} />
            <div className={`input-alert ${passwordValidation === 2 ? "" : "hidden"}`}>{t("errors.confirmPasswordError")}</div>

            <button className="button sign-btn" onClick={handleSubmit}>
            {t("signup.createAccount")}
            </button>
            <div className="or">
              <hr />
              {t("common.or")}
              <hr />
            </div>
            <div className="account-already">
            {t("signup.alreadyAccount")}    
             <span className="sign-link">
                <Link to="signin">{t("common.signIn")}</Link>
              </span>
            </div>
          </div>

          {/* ---------------------------------Enterprise Category Form--------------------------------  */}

          <div className={`sign-input ${category === "ENTERPRISE" ? "" : "hidden"}`}>
            <input className="sign-field" onChange={(e) => setEnterpriseName(e.target.value)} type="text" placeholder={t("signup.uniName")} />
            <div className={`input-alert ${enterpriseNameValidation === 1 ? "" : "hidden"}`}>Please enter University/ School/ Company name</div>
            
            <input onFocus={()=>setShowCodeError(false)} className="sign-field" onChange={(e) => setEnterpriseCode(e.target.value)} type="text" placeholder={t("signup.code")} />
            {showCodeError && <p style={{color: 'red', marginTop: '0.5rem', textAlign: 'left'}}>{t("signup.codeError")}</p>}
           

            <input className="sign-field" onChange={(e) => setEmail(e.target.value)} type="text" placeholder={t("common.email")} />
            <div className={`input-alert ${emailValidation === 1 ? "" : "hidden"}`}>{t("errors.emailError")}</div>
            <div className={`input-alert ${emailValidation === 2 ? "" : "hidden"}`}>{t("errors.validEmail")}</div>

            <input className="sign-field" onChange={(e) => setPassword(e.target.value)} type="password" placeholder={t("common.password")} />
            <div className={`input-alert ${passwordValidation === 1 ? "" : "hidden"}`}>{t("errors.passwordError")}</div>
            <input className="sign-field" onChange={(e) => setConfirmPassword(e.target.value)} type="password" placeholder={t("signup.confirmPassword")} />
            <div className={`input-alert ${passwordValidation === 2 ? "" : "hidden"}`}>{t("errors.confirmPasswordError")}</div>

            <button className="button sign-btn" onClick={handleSubmit}>
            {t("signup.createAccount")}
            </button>
            <div className="or">
              <hr />
              {t("common.or")}
              <hr />
            </div>
            <div className="account-already">
            {t("signup.alreadyAccount")}
              <span className="sign-link">
                <Link to="signin">{t("common.signIn")}</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="sign-container-right">
        <img src={`Image/homePageRedesign/${subCategory === "FACULTY" ? "Sign_up_teacher" : category === "ENTERPRISE" ? "sign_up_buisness" : "Sign_up"}.svg`} alt="#" className="signup-image" />
        <p className="subtext">{t(subCategory === "FACULTY" ? `${t("HomePageRedesign.buildingBright")}` : category === "ENTERPRISE" ? `${t("HomePageRedesign.drivingSuccess")}` : `${t("HomePageRedesign.unlockingKnowledge")}`)}</p>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state

  return {
    user: state.profile.user,
    error: state.profile.error,
    status: state?.profile?.status,
    errors: state.errors.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  //mapDisptachToProps is used to dispatch actions
  return {
    loginUser: (email, password) => dispatch(loginUser(email, password)),
    signUpUser: (data) => dispatch(signUpUser(data))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
/* export default Signup; */

{
  /* <div className='sign-container-left'>
<Link to="/"><img className='sign-logo' src="Image/signImage/logo.svg" alt="#" /></Link>
  <div>
    <div className='Signup-heading'>
        <div>Sign up</div>
        <div>Login to continue your social and knowledge journey on platform.</div>
    </div>
    <div className='sign-input'>
        <input className='sign-field' onChange={e => setFirstName(e.target.value)} type="text" placeholder="First Name" required/>
        <div className={`input-alert ${ firstNameValidation==1 ? '': 'hidden'}`} >Enter First Name</div>
        <input className='sign-field' onChange={e => setLastName(e.target.value)} type="email" placeholder="Last Name" />

        <input className='sign-field' onChange={e => setEmail(e.target.value)} type="email" placeholder="Email" />
        <div className={`input-alert ${ emailValidation==1 ? '': 'hidden'}`} >Enter email</div>
        <div className={`input-alert ${ emailValidation==2 ? '': 'hidden'}`}>Enter valid email</div>

        <input className='sign-field' onChange={e => setPassword(e.target.value)} type="password" placeholder="Password" />
        <div className={`input-alert ${ passwordValidation==1 ? '': 'hidden'}`} >Enter Password</div>
        <input className="sign-field" onChange={e => setConfirmPassword(e.target.value)}type="password" placeholder="Confirm Password" />
        <div className={`input-alert ${ passwordValidation==2 ? '': 'hidden'}`}>Password doesn't match</div>

        <button className='button Signup-btn' onClick={handleSubmit}>Create account</button>
        <div className='or'><hr/>or<hr/></div>
        <div className='account-already'>Already have an account?<span className="sign-link"><Link to="signin">Sign in</Link></span></div>
    </div>
  </div>
</div>
<div className='Signup-container-right'>
  <div>
    <img src="Image/HomeImages/logo-white.svg" alt="#" />
    <div>Chalkmates is a global education network that helps connect all learners with the people and resources needed to reach their full potential.</div>
  </div>
  <img src="Image/signImage/signin-bg.svg" alt="#" />
</div> */
}
