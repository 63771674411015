import { withRouter } from "react-router";
import { connect } from "react-redux";
import styles from "./css/CourseButton.module.css"
import React, { useEffect } from 'react'
import { withTranslation } from "react-i18next";
import {
  createPaymentCourse,
} from "../../../redux/actions/payment";
import {buyCourse} from "../../../redux/actions/courses"
import { CircularProgress } from "@mui/material";
import ShareModal from "../../../components/shareModal/ShareModal";


class CoursePayment extends React.Component {

   // this is a button which can do payment, share

    constructor(props) {
        super(props);
        this.state={
          isLoading:false,
          showShareModal: false,
          link: window.location.href,
        }
      }

      componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
      }

      setAsyncState = (newState) =>
      new Promise((resolve) => this.setState(newState, resolve));
      
      shareModalOpener = (link) => {
        this.setState({ showShareModal: true, link: link });
      };

      shareModalCloser = () => {
        this.setState({ showShareModal: false });
      };

      shareCode = () => {
        this.shareModalOpener();
      };

      handleShare = ()=>{

      }

      openPayModal() {
        // this.setState({isLoading:false})

        if(this.props?.data?.price===0) {
          this.props.buyCourse(true,this.props.data.courseId,this.props.paymentCreated?.options_order_id,true).then(()=>{
            this.setState({isLoading:false});
          })
        }else{

          // console.log("open modal is called");
        
          // var amount = amt * 100; //Razorpay consider the amount in paise
          // console.log("modal data =", this.props.paymentCreated)
          // console.log(p?.courses?.paymentCreated?.options_name)
    
          var options = {
            key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
            // amount: this.props?.currentCourse?.price, // 2000 paise = INR 20, amount in paisa
            amount:200,
            name: this.props.paymentCreated?.options_name,
            description: this.props.paymentCreated?.options_description,
            order_id: this.props.paymentCreated?.options_order_id,
            image: this.props.paymentCreated?.options_image,
            handler: (response) => {
              var values = {
                razorpay_signature: response.razorpay_signature,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                // transactionamount : amount,
              }
              this.props.buyCourse(true,this.props?.data?.courseId,values,false).then(()=>{
                this.setState({isLoading:false});
              });
            },
            prefill: {
              name: this.props.paymentCreated?.prefill_name,
              email:this.props.paymentCreated?.prefill_email,
              //   "contact":'1234567890',
            },
            notes: {
              address: this.props.paymentCreated?.notes_address,
            },
            theme: {
              color: "#ED1E24",
            },
            "modal": {
              "ondismiss": () => {
                  this.setState({isLoading:false});
              }
          }
          };
    
          var rzp1 = new window.Razorpay(options);
          rzp1.on("payment.failed", (response) => {
            
            var data = {
              order_id: response.error.metadata.order_id,
              payment_id: response.error.metadata.payment_id,
              code: response.error.code,
              description: response.error.description,
              reason: response.error.reason,
              step: response.error.step,
              source: response.error.source,
            };
            this.props.buyCourse(false,this.props.data.courseId,data,false);
    
          });
          rzp1.open();
      }
    }

    render(){
      return(
        <>
        <ShareModal
              closeModal={this.shareModalCloser}
              show={this.state.showShareModal}
              link={window.location.href}
              coursePage={true}

            />
            {            
          //  (this.props?.currentCourse?.is_enrolled && !this.props?.currentCourse?.free ? null : 
             <button className={styles.actionBtn} 
                    style={!this.props.currentCourse?.active ? {opacity:"0.4", cursor:"not-allowed"} : null} 
                    // if the course is active and it can be enrolled then only do the functioning
                    onClick={this.props.currentCourse?.active && this.props.data?.enroll ? 
                          ()=>this.setAsyncState({isLoading:true}).then(()=>this.props?.createPaymentCourse(this.props.data?.courseId,this.props.data?.price))
                          .then(()=>this.openPayModal()): (this.props?.currentCourse?.is_enrolled  ? this.shareCode : null) }> 
                      {this.state.isLoading ?  <CircularProgress sx={{color: "white"}} size={15} />: this.props.data?.btnText}
            </button>
            // )
      } 
        </>
      )
    }
}

const mapStateToProps = (state) => {
  return {
    paymentCreated:state?.courses?.paymentCreated,
    currentCourse:state?.courses?.currentCourse
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPaymentCourse: (id,amount) => dispatch(createPaymentCourse(id,amount)),
    buyCourse: (isSuccess,id,data,free)=> dispatch(buyCourse(isSuccess,id,data,free))
  };
};


export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(CoursePayment))
);