import React, { useState, useEffect } from "react";
import styles from "./ResearchFeed.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import AddPatentResearchTrainingModal from "./Modals/AddPatentResearchTrainingModal";
import Modal from "../../../../../components/commons/Modal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { toggleSectionVisibility } from "../../../../../redux/actions/profile";
import TooltipWrapper from "../../../../CourseMonetization/Components/TooltipWrapper";


const ResearchFeed = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expand, setExpand] = React.useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isResearchOngoing, setIsResearchOngoing] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editTarget, setEditTarget] = useState(null);

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const isResearchFeedVisible = useSelector((state) => state?.profile?.research_visibility);

  const [isVisible, setIsVisible] = useState(isResearchFeedVisible);

  // "owner" is for the logged in user, "view" is for the user who's profile is being viewed by the logged in user
  const storedResearchOwner = useSelector((state) => state?.profile?.research);
  const storedResearchView = useSelector((state) => state?.profile?.researchView);

  const research = props.isEditPage ? storedResearchOwner : storedResearchView;
  
  const profileId = useSelector((state) => state?.profile?.profileId);

  const handleToggle = (event) => {
    setIsLoadingBtn(true);
    setIsVisible(event.target.checked);
    let data = new FormData();
    data.append("research_visibility", event.target.checked);
    
    dispatch(toggleSectionVisibility(data, profileId))
    .then(()=>setIsLoadingBtn(false));
  };

  useEffect(() => {
    setIsVisible(isResearchFeedVisible);
  }, [isResearchFeedVisible])
  

  const handleSelection = (type, isOngoing) => {
    setModalType(type);
    setIsResearchOngoing(isOngoing);
    setAnchorEl(null);
    setShowModal(true);
  };

  const closeModal = () => {
    setIsEditing(false);
    setEditTarget(null);
    setShowModal(false);
    // setModalType()
    // setIsResearchOngoing()
  }



  const styleSwitch = {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ED1E24",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#ED1E24",
    },
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(false);
  };

  const styleMenuItem = {
    'color': '#000',
    'fontFamily': 'Montserrat',
    'fontWeight': '500',
    'fontSize': '0.875rem',
    '&:hover': {
      backgroundColor: '#FFCFD0',
   },
  }

  return (
    <div className={styles.main}>
      {showModal && (
        <Modal
          card={
            <AddPatentResearchTrainingModal
              onClose={closeModal}
              modalType={modalType}
              isResearchOngoing={isResearchOngoing}
              isEditing={isEditing}
              targetId={editTarget?.id}
              title={editTarget?.title}
              organisation={editTarget?.industry_name}
              date={editTarget?.issue_date}
              description={editTarget?.description}
              url={editTarget?.url}
              image={editTarget?.image}
            />
          }
        />
      )}
      <div className={styles.header}>
        <IconTitle
          title={t("profile.addResearch")}
          img="/Image/profile_page/Research (2).svg"
        />
        {props.isEditPage && 
          <TooltipWrapper title={t("profile.invisibleTooltipMessage")}>      
            <Switch
              checked={isVisible}
              disabled={isLoadingBtn}
              sx={styleSwitch}
              onChange={handleToggle}
              inputProps={{ "aria-label": "controlled" }}
            />
          </TooltipWrapper>        
        }
      </div>

      <div className={styles.card}>
        {props.isEditPage && <>
        <p className={styles.description}>{t("profile.addResearch")}</p>
        <button
          className={styles.add_btn}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          >
          <p className={styles.btn_label}>
            &#65291; {t("profile.addResearch")}
          </p>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              boxShadow:'0px 0px 20px rgba(63, 63, 65, 0.102)',
              borderRadius: '1rem',
            }
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          >
          <MenuItem
            sx={styleMenuItem} 
            onClick={() => handleSelection("industryResearch", true)}
            >{`${t("profile.industry")} - ${t("profile.ongoing")}`}</MenuItem>
          <MenuItem
            sx={styleMenuItem} 
            onClick={() => handleSelection("governmentResearch", true)}
            >{`${t("profile.government")} - ${t("profile.ongoing")}`}</MenuItem>
          <MenuItem
            sx={styleMenuItem} 
            onClick={() => handleSelection("otherResearch", true)}
            >{`${t("profile.other")} - ${t("profile.ongoing")}`}</MenuItem>
          <MenuItem
            sx={styleMenuItem} 
            onClick={() => handleSelection("industryResearch", false)}
            >{`${t("profile.industry")} - ${t("profile.past")}`}</MenuItem>
          <MenuItem
            sx={styleMenuItem} 
            onClick={() => handleSelection("governmentResearch", false)}
            >{`${t("profile.government")} - ${t("profile.past")}`}</MenuItem>
          <MenuItem
            sx={styleMenuItem} 
            onClick={() => handleSelection("otherResearch", false)}
            >{`${t("profile.other")} - ${t("profile.past")}`}</MenuItem>
        </Menu>
        </>}

        {console.log("isEditPage", props.isEditPage)}

        {research?.length > 0 ?
          research?.map((item) => (
            <div className={styles.publication_container} key={item?.id}>
              <img src={item?.image || "/Image/apps/profile-resume-edit-view/default-certificate.png"} className={styles.media} />
              <div className={styles.text_wrapper}>
                <div className={styles.title_header}>
                  <p className={styles.title}>{item?.title}</p>
                  {props.isEditPage && <img
                    src="/Image/Chalkmate_Edit.svg"
                    onClick={() =>{
                      setEditTarget(item);
                      setIsEditing(true);
                      setIsResearchOngoing(item.ongoing === true);
                      let type = item?.research_type === "industry" ? "industryResearch" : item?.research_type === "govt" ? "governmentResearch" : "otherResearch";
                      setModalType(type);
                      setShowModal(true);
                    }}
                  />}
                </div>
                <p className={styles.publisher_and_date}>
                  {item?.industry_name} &#x2022; {item?.ongoing ? `${item?.issue_date} - ${t("common.present")}` : item?.issue_date}
                </p>
                {item?.url && (
                  <div
                    className={styles.url}
                    onClick={() => window.open(item?.url, "_blank")}
                  >
                    {t("profile.showResearch")}
                    <img src="/Image/redirect.png" />
                  </div>
                )}

                <p className={styles.description}>{item?.description}</p>
              </div>
            </div>
          )) : <p>{t("profile.noInfoFound")}</p>}
      </div>
    </div>
  );
};

export default ResearchFeed;
