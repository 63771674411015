import React from "react";
import { connect } from "react-redux";
import { addExperience, updateExperience } from "../../../../../../redux/actions/experience";
import { ADD_EXPERIENCE, UPDATE_EXPERIENCE } from "../../../../../../redux/constants/actionTypes";
import styles from "../../../../Profile.module.css";
import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import {store} from "../../../../../../redux/store"
import { withTranslation } from "react-i18next";
// import { t } from "i18next";

class EditExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: this.props.modalTitle,
      id: this.props.id,
      upload_img: this.props.upload_img,
      title: this.props.title,
      description: this.props.description,
      start_date: this.props.start_date,
      end_date: this.props.end_date,
      employment_type: this.props.employment_type,
      company: this.props.company,
      pictureAsFile: this.props.upload_img,
      error: false,
      message: "",

      uploadImageFlag: false,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }
  componentDidMount() {
    if (this.state.upload_img == "") {
      fetch("/Image/apps/profile-resume-edit-view/default-experience.png")
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "exp.png", blob);
          this.setState({ pictureAsFile: file, upload_img: "/Image/apps/profile-resume-edit-view/default-experience.png" });
        });
    }
  }
  handleImageChange(event) {
    this.setState({
      upload_img: URL.createObjectURL(event.target.files[0]),
      pictureAsFile: event.target.files[0],
      uploadImageFlag: true,
    });
  }
  ModalHeaderOnClick() {
    this.props.changeEdit();
    document.body.style.overflow = "visible";
  }
  SaveButtonOnClick() {
    const expState = {
      id: this.props.id,
      title: this.state.title,
      description: this.state.description,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      employment_type: this.state.employment_type,
      company: this.state.company,
      pictureAsFile: this.state.pictureAsFile,

      uploadImageFlag: this.state.uploadImageFlag,
    };
    this.props.changeEdit();
    if (this.props.add) {
      this.props.addExperience(expState, ADD_EXPERIENCE).then((res) => {
        if (res.val) {
          
          document.body.style.overflow = "visible";
          return;
        }
        this.setState({ error: true, message: res.message });
      });
    } else {
      this.props.updateExperience(expState, UPDATE_EXPERIENCE).then((res) => {
        if (res.val) {
          document.body.style.overflow = "visible";
        }
        this.setState({ error: true, message: res.message });
      });
    }
  }
  ScrollBody() {
    const {t} = this.props;
    return (
      <div>
        <div
          style={{
            // borderBottom: '1px solid #5555555A',
            paddingBottom: 20,
          }}
        >
        <div className={styles.imageUpload}>
          <p className={styles.modal_heading}>{t("profile.media")}*</p>
          <div style={{ display: "flex", borderRadius: "5px" }}>
            <img src={this.state.upload_img} style={{ width: "96px", height: "96px", borderRadius: "10px", objectFit: "cover" }} />
            <div style={{ marginLeft: "20px" }}>
              <p className={styles.sub_heading}>{store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("profile.addImageOfYourCompany") : t("profile.addImageOfOrganisation")}</p>
              <label style={{ marginLeft: 30 }} >
                <div style={{  border: "2px solid #ED1E24", padding: "12px 60px", borderRadius: 40, cursor: "pointer" }} >
                  <p style={{ margin: 0, fontWeight: 600, textTransform: "uppercase", textAlign: "center", color: "#ED1E24" }} >
                    {t("common.upload")}
                  </p>
                </div>
                <input type="file" onChange={this.handleImageChange} style={{ cursor: "pointer", display: "none" }} accept="image/png, image/jpeg, image/svg" />
              </label>
            </div>
          </div>
        </div>

          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/title.svg"/>{t("profile.title")}*</div></p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExRetailSalesManager")}
              maxlength="50"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.title}
              onChange={(event) => {
                this.setState({ title: event.target.value });
              }}
            />
            <br />
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
          <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/company.svg"/>{store.getState()?.profile?.user?.user_type === "INDIVIDUAL" ? t("common.company")+"*" : t("common.organisation")+"*"}</div></p>
          <input
            className={styles.modal_para}
            type="text"
            placeholder={t("profile.modalPlaceholder.ExWireoutTechnologies")}
            maxlength="50"
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 50,
            }}
            value={this.state.company}
            onChange={(event) => {
              this.setState({ company: event.target.value });
            }}
          />
          <br />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/employmentType.svg"/>{t("profile.employmentType")}*</div></p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExFulltime")}
              maxlength="50"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.employment_type}
              onChange={(event) => {
                this.setState({ employment_type: event.target.value });
              }}
            />
            <br />
          </div>
        </div>

        <div className={styles.modal_date}>
          <div>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/date.svg"/>{t("profile.StartDate")}*</div></p>
            <input
              className={styles.modal_para}
              type="date"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.start_date}
              onChange={(event) => {
                this.setState({ start_date: event.target.value });
              }}
            />
            <br />
          </div>
          <div>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/date.svg"/>{t("profile.EndDate")}*</div></p>
            <input
              className={styles.modal_para}
              type="date"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.end_date}
              onChange={(event) => {
                this.setState({ end_date: event.target.value });
              }}
            />
            <br />
          </div>
        </div>
        <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/description.svg"/>{t("common.description")}</div></p>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <textarea
            className={styles.modal_para}
            id={styles.textarea}
            cols={55}
            rows={8}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 10,
            }}
            value={this.state.description}
            placeholder={t("profile.describeExperienceWithOrganisation")}
            onChange={(event) => {
              this.setState({ description: event.target.value });
            }}
          />
        </div>
      </div>
    );
  }
  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.modalTitle} onClick={this.ModalHeaderOnClick} />
        <ScrollDivBody body={this.ScrollBody} />
        <SaveButton onClick={this.SaveButtonOnClick} />
        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    updateExperience: (expState, action) => dispatch(updateExperience(expState, action)),
    addExperience: (expState, action) => dispatch(addExperience(expState, action)),
  };
}
export default withTranslation()(connect(null, mapDispatchToProps)(EditExperience));
