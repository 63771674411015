import React, { useState } from "react";
import styles from "./QuestionInputField.module.css";
import { useTranslation } from "react-i18next";

function QuestionInputField(props) {
  const { t } = useTranslation();
  const [questionText, setQuestionText] = useState(props?.actionType == 'edit' ? props?.question.questionText : '')
  
  return (
    <input
      type="text"
      className={styles.questionInputField}
      placeholder={t("common.question")}
      value={questionText}
      onChange={(e) => {
        setQuestionText(e.target.value);
        props.getQuestionInput(e.target.value)
      }}
    />
  );
}

export default QuestionInputField;
