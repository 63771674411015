import { React, useRef, useState, useEffect } from "react";
import "../Sign.css";
import { Link, useHistory } from "react-router-dom";
import { Redirect } from "react-router";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { connect } from "react-redux";
import { loginUser, loginSuccess } from "../../../redux/actions/login";
import { useTranslation } from "react-i18next";
import Nav from "../../home/Nav/Nav";
import SignNav from "../../home/Nav/SignNav";

function Signin({ user, error, loginUser, loginSuccess, errors }) {
  useEffect(() => {
    const userData = localStorage.getItem("userData");

    if (userData) {
      loginSuccess(JSON.parse(userData));
      setalreadyLoggedIn(true);
    }
  }, []);
  const {t} = useTranslation()

  const horizontal = "center";
  const vertical = "top";
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const [email, setEmail] = useState("");
  const [alreadyLoggedIn, setalreadyLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const [emailValidation, setEmailValidation] = useState(0);
  const [passwordValidation, setPasswordValidation] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const history = useHistory();
  const userRef = useRef(user);
  userRef.current = user;
  const errorRef = useRef(error);
  errorRef.current = error;
  const errorsRef = useRef(errors);
  errorsRef.current = errors;

  function handleSubmit() {
    const pattern = /^[a-z0-9](\.?[a-z0-9_-]){0,}@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/;
    if (email == "") {
      setEmailValidation(1);
      return;
    } else {
      setEmailValidation(0);
    }
    /*     if(!email.match(pattern)){
      setEmailValidation(2)
      return
    }
    else{
      setEmailValidation(0)
    } */
    if (password == "") {
      setPasswordValidation(1);
      return;
    }
    loginUser(email, password).then((res) => {
      if (userRef.current?.token) {
        history.push("/user");
      } 
      else {
        setMessage(errorsRef.current);
        setOpen(true);
        setSeverity("error");
      }
    });
  }

  const keyDownHandler = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  }


  return (
    <div className="sign-container">
      {alreadyLoggedIn ? <Redirect to="/user" /> : null}
      <div className="sign-container-left">
        <SignNav/>
        <div className="sign-form">
          <div className="sign-heading">
            <div>{t("common.signIn")}</div>
            <div>{t("signin.signInDesc")}</div>
          </div>
          <div className="sign-input">
            <input className="sign-field" onChange={(e) => setEmail(e.target.value)} type="email" placeholder={t("common.email")} onKeyDown={(e)=>keyDownHandler(e)} />
            <div className={`input-alert ${emailValidation == 1 ? "" : "hidden"}`}>{t("errors.emailError")}</div>
            <div className={`input-alert ${emailValidation == 2 ? "" : "hidden"}`}>{t("errors.validEmail")}</div>

            <input className="sign-field" onChange={(e) => setPassword(e.target.value)} type="password" placeholder={t("common.password")} onKeyDown={(e)=>keyDownHandler(e)} />
            <div className={`input-alert ${passwordValidation == 1 ? "" : "hidden"}`}>{t("errors.passwordError")}</div>
            <div className="sign-action">
              <Link to="forgotpassword">
                <div className="forgot-password">{t("signin.forgotPassword")}</div>
              </Link>
            </div>
            <button className="button sign-btn" onClick={handleSubmit}>
              {t("common.login")}
            </button>

            <div className="or">
              {/* <hr /> */}
              {t("common.or")}
              {/* <hr /> */}
            </div>
            <div className="account-already">
              {t("signin.noAccount")} 
              <span>
                {" "}<Link to="signup">{t("common.signup")}</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="sign-container-right">
        <img src="Image/Hero/signbg.svg" alt="#" />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state

  return {
    user: state.profile.user,
    error: state.profile.error,
    errors: state.errors.errorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  //mapDisptachToProps is used to dispatch actions
  return {
    loginUser: (email, password) => dispatch(loginUser(email, password)),
    loginSuccess: (userData) => dispatch(loginSuccess(userData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
