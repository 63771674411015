import * as React from "react";
import { TextField, InputBase } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker, DateTimePicker, TimePicker, MobileTimePicker } from "@mui/x-date-pickers";
import styles from "./DateTimePicker.module.css";
import { withStyles } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function TimePickerComponent(props) {
  // props - getAssignmentTime(params)
  const [value, setValue] = React.useState(props?.assignmentTime ?? new Date());
  const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "transparent",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "transparent",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent",
        },
        "&:hover fieldset": {
          borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent",
        },
      },
    },
  })(TextField);
  const theme = createTheme({
    palette: {
      // primary: { main: '#ED1E24' },
      primary: { main: "#00a77f" },
    },
  });

  return (
    props.isSmallHeight ? 
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileTimePicker
          PaperProps={{
            style: {
              borderRadius: "25px",
            },
            sx: {
              "& .MuiPickersToolbar-penIconButton": {
                display: "none",
              },
              "& .MuiPickersToolbar-content": {
                width: "fit-content",
                margin: "auto",
              },
            },
          }}
          ampm={true}
          closeOnSelect={false}
          showToolbar="true"
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
            props.getAssignmentTime(newValue);
          }}
          renderInput={(params) => (
            <div className={styles.date_field}>
              <CssTextField
                sx={{ width: "208px" }}
                size="small"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: "12:00 AM",
                }}
              />
            </div>
          )}
          {...props}
          inputProps={{
            style: {
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "400",
            },
          }}
        />
      </LocalizationProvider>
    </ThemeProvider>
    :
    <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        PaperProps={{
          style: {
            borderRadius: "25px",
          },
          sx: {
            "& .MuiPickersToolbar-penIconButton": {
              display: "none",
            },
            "& .MuiPickersToolbar-content": {
              width: "fit-content",
              margin: "auto",
            },
          },
        }}
        ampm={true}
        closeOnSelect={false}
        showToolbar="true"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          props.getAssignmentTime(newValue);
        }}
        renderInput={(params) => (
          <div className={styles.date_field}>
            <CssTextField
              sx={{ width: "208px" }}
              size="small"
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "12:00 AM",
              }}
            />
          </div>
        )}
        {...props}
        inputProps={{
          style: {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "400",
          },
        }}
      />
    </LocalizationProvider>
  </ThemeProvider>
  );
}
