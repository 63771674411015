import React, { useState } from "react";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import SaveButton from "../../../../../../components/commons/SaveButton";
import styles from "./AddPatentResearchTrainingModal.module.css";
import { useTranslation } from "react-i18next";
import {
  isValidUrl,
  validateFileSize,
} from "../../../../../../redux/constants/commonFunctions";
import { handleSuccessMessage } from "../../../../../../redux/actions/success";
import { handleErrorMessage } from "../../../../../../redux/actions/classes";
import { useDispatch, useSelector } from "react-redux";
import {
  addPublication,
  addTraining,
  addResearch,
  updatePublication,
  updateTraining,
  updateResearch,
} from "../../../../../../redux/actions/profile";
import { CircularProgress } from "@mui/material";

// props:
// modalType = book, patent, paper, caseStudy, industryResearch, governmentResearch, otherResearch, training;
// isResearchOngoing = true/false (only in case of Research)

const AddPatentResearchTrainingModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profileId = useSelector((state) => state?.profile?.profileId);

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const labelType = {
    book: {
      title: "profile.bookTitle",
      organisation: "profile.publicationPublisher",
      date: "profile.publicationDate",
      url: "profile.publicationURL",
      description: "profile.description",
      addImage: "profile.addImageBook",
    },
    patent: {
      title: "profile.patentTitle",
      organisation: "profile.patentNumber",
      date: "profile.issueDate",
      url: "profile.patentURL",
      description: "profile.description",
      addImage: "profile.addImagePatent",
    },
    paper: {
      title: "profile.paperTitle",
      organisation: "profile.paperNumber",
      date: "profile.issueDate",
      url: "profile.paperURL",
      description: "profile.description",
      addImage: "profile.addImagePaper",
    },
    caseStudy: {
      title: "profile.caseStudyTitle",
      organisation: "profile.caseStudyPublisher",
      date: "profile.issueDate",
      url: "profile.caseStudyURL",
      description: "profile.description",
      addImage: "profile.addImageCaseStudy",
    },
    industryResearch: {
      title: "profile.researchTitle",
      organisation: "profile.industryName",
      date: props.isResearchOngoing ? "profile.StartDate" : "profile.issueDate",
      url: "profile.researchURL",
      description: "profile.description",
      addImage: "profile.addImageResearch",
    },
    governmentResearch: {
      title: "profile.researchTitle",
      organisation: "profile.govtName",
      date: props.isResearchOngoing ? "profile.StartDate" : "profile.issueDate",
      url: "profile.researchURL",
      description: "profile.description",
      addImage: "profile.addImageResearch",
    },
    otherResearch: {
      title: "profile.researchTitle",
      organisation: "profile.conductorName",
      date: props.isResearchOngoing ? "profile.StartDate" : "profile.issueDate",
      url: "profile.researchURL",
      description: "profile.description",
      addImage: "profile.addImageResearch",
    },
    training: {
      title: "profile.title",
      organisation: "profile.organisationName",
      date: "profile.issueDate",
      url: "profile.credentialURL",
      description: "profile.description",
      addImage: "profile.addImageTraining",
    },
  };

  const [thumbnail, setThumbnail] = useState( props.image ?? "/Image/apps/profile-resume-edit-view/default-certificate.png"
  );
  const [title, setTitle] = useState(props.isEditing ? props.title : "");
  const [organisation, setOrganisation] = useState(props.isEditing ? props.organisation : "");
  const [date, setDate] = useState(props.isEditing ? props.date : "");
  const [url, setUrl] = useState(props.isEditing ? props.url : "");
  const [description, setDescription] = useState(props.isEditing ? props.description : "");

  // case_study, paper, patent, book
  const publicationType = props.publicationType;
  const isResearchOngoing = props.isResearchOngoing || "false";

  const modalType = props.modalType;

  const handleThumbnail = (e) => {
    let errorMessage = validateFileSize(e.target.files[0], 5120);
    let extension = e.target.files[0]?.name?.split(".").pop().toLowerCase();

    if (!["png", "jpeg", "jpg"].includes(extension)) {
      errorMessage = `.${extension} ${t(
        "courseMonetisation.filesNotSupported"
      )}`;
    }

    if (errorMessage) {
      dispatch(handleErrorMessage(errorMessage));
    } else {
      setThumbnail(e.target.files[0]);
      // setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSave = () => {
    if (title === "" || organisation === "" || date === "") {
      dispatch(
        handleErrorMessage(t("errorMessageTranslations.requiredFields"))
      );
    } else if (url && !isValidUrl(url)) {
      dispatch(handleErrorMessage(t("errorMessageTranslations.validUrl")));
    } else {
      let data = new FormData();
      let publicationType =
        modalType === "caseStudy" ? "case_study" : modalType;

      if (
        modalType === "book" ||
        modalType === "patent" ||
        modalType === "paper" ||
        modalType === "caseStudy"
      ) {
        data.append("profile", profileId);
        data.append("title", title);
        data.append("publication", organisation);
        data.append("publication_date", date);
        data.append("description", description);
        data.append("pub_type", publicationType);

        if (typeof thumbnail === "object") {
          data.append("image", thumbnail);
        }

        if (url) {
          data.append("url", url);
        }

        if(props.isEditing){
          setIsLoadingBtn(true);
          dispatch(updatePublication(data,props.targetId))
          .then(()=>setIsLoadingBtn(false))
          .then(()=>props.onClose());
        } else {
          setIsLoadingBtn(true);
          dispatch(addPublication(data))
          .then(()=>setIsLoadingBtn(false))
          .then(() => props.onClose());
        }

      }

      if (
        modalType === "industryResearch" ||
        modalType === "governmentResearch" ||
        modalType === "otherResearch"
      ) {
        let reserachType =
          modalType === "industryResearch"
            ? "industry"
            : modalType === "government"
            ? "govt"
            : "other";

        data.append("profile", profileId);
        data.append("title", title);
        data.append("description", description);
        data.append("issue_date", date);
        data.append("industry_name", organisation);
        data.append("ongoing", props.isResearchOngoing);
        data.append("research_type", reserachType);

        if (typeof thumbnail === "object") {
          data.append("image", thumbnail);
        }

        if (url) {
          data.append("url", url);
        }

        if(props.isEditing){
          setIsLoadingBtn(true)
          dispatch(updateResearch(data, props.targetId))
          .then(()=>setIsLoadingBtn(false))
          .then(()=> props.onClose());
        } else {
          setIsLoadingBtn(true)
          dispatch(addResearch(data))
          .then(()=>setIsLoadingBtn(false))
          .then(() => props.onClose());
        }

      }

      if (modalType === "training") {
        data.append("profile", profileId);
        data.append("title", title);
        data.append("description", description);
        data.append("issue_date", date);
        data.append("organization_name", organisation);

        if (typeof thumbnail === "object") {
          data.append("image", thumbnail);
        }

        if (url) {
          data.append("credential_url", url);
        }

        if(props.isEditing){
          setIsLoadingBtn(true)
          dispatch(updateTraining(data, props.targetId))
          .then(()=>setIsLoadingBtn(false))
          .then(()=>props.onClose())
        } else {
          setIsLoadingBtn(true)
          dispatch(addTraining(data))
          .then(()=>setIsLoadingBtn(false))
          .then(() => props.onClose());
        }
      }
    }
  };

  const innerContainer = () => {
    return (
      <div className={styles.modal_body}>
        <p className={styles.label}>{t("profile.media")}</p>
        <div className={styles.add_image_container}>
          <div className={styles.image_wrapper}>
            <img
              // src={"/Image/apps/profile-resume-edit-view/default-award.png"}
              src={
                typeof thumbnail === "string"
                  ? thumbnail
                  : URL.createObjectURL(thumbnail)
              }
              // className={styles.thumbnail_pic}
            />
          </div>
          <div className={styles.upload_button_container}>
            <p className={styles.add_image_label}>
              {t(labelType[modalType]?.addImage)} :
            </p>
            <div className={styles.upload_btn}>
              <p>{t("common.upload")}</p>
              <input
                type="file"
                onChange={handleThumbnail}
                className={styles.file_upload}
                accept="image/png, image/jpeg, image/svg"
              />
            </div>
          </div>
        </div>

        <div className={styles.field_container}>
          <div className={styles.label_icon}>
            <img src="/Image/modalIcon/title.svg" />
            <p>{t(labelType[modalType]?.title)}*</p>
          </div>
          <input
            className={styles.input_field}
            type="text"
            placeholder={t("profile.addTextHere")}
            // maxlength="50"
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
        </div>

        <div className={styles.field_container}>
          <div className={styles.label_icon}>
            <img
              src={
                modalType === "paper" || modalType === "patent"
                  ? "/Image/profile_page/credentialID.svg"
                  : "/Image/modalIcon/company.svg"
              }
            />
            <p>{t(labelType[modalType]?.organisation)}*</p>
          </div>
          <input
            className={styles.input_field}
            type="text"
            placeholder={t("profile.addTextHere")}
            // maxlength="50"
            value={organisation}
            onChange={(event) => {
              setOrganisation(event.target.value);
            }}
          />
        </div>

        <div className={styles.field_container}>
          <div className={styles.label_icon}>
            <img src="/Image/modalIcon/date.svg" />
            <p>{t(labelType[modalType]?.date)}*</p>
          </div>
          <input
            className={styles.input_field}
            type="date"
            placeholder={t("profile.addTextHere")}
            value={date}
            onChange={(event) => {
              setDate(event.target.value);
            }}
          />
        </div>

        <div className={styles.field_container}>
          <div className={styles.label_icon}>
            <img src="/Image/profile_page/url.svg" />
            <p>{t(labelType[modalType]?.url)}</p>
          </div>
          <input
            className={styles.input_field}
            type="text"
            placeholder={t("assignment.addUrl")}
            // maxlength="50"
            value={url}
            onChange={(event) => {
              setUrl(event.target.value);
            }}
          />
        </div>

        <div className={styles.field_container}>
          <div className={styles.label_icon}>
            <img src="/Image/modalIcon/description.svg" />
            <p>{t("common.description")}</p>
          </div>
          <textarea
            className={styles.text_area}
            placeholder={t("profile.addTextHere")}
            cols={60}
            rows={8}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.main}>
      {console.log("currentInfo in modal", {
        thumbnail,
        title,
        organisation,
        date,
        url,
        description,
        publicationType,
        modalType,
      })}
      {console.log("isEditing", props.url, props.isEditing, props.targetId, props.title, props.organisation, props.date, props.description, props.publicationType )}
      {console.log("isResearchOn",props.isResearchOngoing)}
      {console.log("typeOfImage", typeof(thumbnail))}
      <ModalHeader title={props.heading} onClick={props.onClose} />
      <ScrollDivBody body={innerContainer} />
      {/* <SaveButton  btnTitle={props?.isLoadingBtn ? <CircularProgress color="inherit" size={20} /> : t("common.save")} /> */}
      <SaveButton onClick={isLoadingBtn ? null : handleSave} isLoadingBtn={isLoadingBtn} />
    </div>
  );
};

export default AddPatentResearchTrainingModal;
