import { gql } from "graphql-request";

// getAllQuizTempaltes
export const GET_ALL_QUIZ_TEMPLATES_QUERY = gql`
  query {
    templates {
      id
      is_editable
      is_objective
      temp_owner {
        id
        name
      }
      title
      details
      marks
      ques_count
    }
  }
`;

export const GET_ALL_CLASS_QUIZZES_QUERY = gql`
  query($id: ID!, $userId: ID!){
    quizzes(classes: $id, logged_user: $userId) {
      id
      quiz_owner {
        id
        name
        profile_pic
      }
      owner_pic
      title
      details
      isPublished
      reattempt
      submission_count {
        assigned
        submitted
        graded
        not_assigned
      }
      due_date
      timestamp
      max_marks
      is_timed 
      duration
      classes {
        id
        name
      }
      template {
        id
        title
        is_editable
        is_objective
        ques_count
      }
      user_submission {
        id
        marks
        attempt
        time_taken
        is_submitted
        is_returned
        submission_status
      }
    }
  }
`;

export const GET_ALL_GROUP_QUIZZES_QUERY = gql`
  query($id: ID!, $userId: ID!){
    quizzes(group: $id, logged_user: $userId) {
      id
      quiz_owner {
        id
        name
        profile_pic
      }
      owner_pic
      title
      details
      isPublished
      reattempt
      submission_count {
        assigned
        submitted
        graded
        not_assigned
      }
      due_date
      timestamp
      max_marks
      is_timed 
      duration
      group {
        id
        name
      }
      template {
        id
        title
        is_editable
        is_objective
        ques_count
      }
      user_submission {
        id
        marks
        attempt
        time_taken
        is_submitted
        is_returned
        submission_status
      }
    }
  }
`;

export const GET_INDIVIDUAL_QUIZ_TEMPLATE_QUERY = gql`
query($id: ID!){
  template(id: $id) {
    id
    temp_owner {
      id
      name
    }
    title
    details
    is_editable
    is_objective
    marks
    questions {
      id
      text
      marks
      is_mandatory
      question_type
      choice{
        id
        choice_text
        correct_choice
        is_correct
        img
        correct_list {
          id
          correct_choice
        }
      }
    }
    timestamp
    utimestamp
    ques_count
  }
}
`;


export const GET_INDIVIDUAL_QUIZ_QUERY = gql`
query($id: ID!){
  quiz(id: $id) {
      id
      title
      details
      isPublished      
      template {
        id
        title
        is_editable
        is_objective
        marks
        questions {
        id
        text
        marks
        is_mandatory
        question_type
        choice{
          id
          choice_text
          correct_choice
          is_correct
          img
          correct_list {
            correct_choice
          }
        }
      }
        ques_count
      }
      
      is_timed
      duration
      question_randomized
      reattempt
      show_answers
      
      max_marks
      is_lock
      due_date
      timestamp
      utimestamp    
      user_submission {
      id
      attempt
      marks
      submission_status
      is_submitted   
      is_returned      
      user_answers
    }  

      classes{
        id
        name
        class_owner{
          id
          name
        }
      }

      group{
        id
        name
        group_owner{
          id
          name
        }        
      }
    }
}
`;

/**
 * Get all the submissions made on a quiz (ie the student list)
 */
export const GET_ALL_QUIZ_SUBMISSIONS_QUERY  = gql`
query($id: ID!)
{
  quiz_submissions(quiz_id: $id) {
    submissions {
      id
      students {
        id
        name
        profile_pic
      }
      attempt
      submission_status
      marks
      time_taken
      user_answers
      is_returned
      is_submitted
      submission_date
    }
    un_assigned_list {
      id
      name
      profile_pic
      
    }
    quiz {
      id
      classes {
        id
        name
      }
      group{
        id
        name
      }
      title
      details
      max_marks
      duration
      due_date
      template {
        is_objective
        ques_count
        questions {
          id
          text
          marks
          question_type
          choice{
            id
            choice_text
            is_correct
            img
            correct_choice
            correct_list {
              id
              correct_choice
            }
            
          }
        }
        
      }
      submission_count {
        assigned
        submitted
        graded
        not_assigned        
      }
      
    }
  }
}

`;