import React from "react";

import styles from "../../../Profile.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class EndorsementsFeed extends React.Component {
  state = {
    endIdx: 4,

    seeall: false,
    skillContent: [],
  };

  getRandomNumber() {
    return Math.floor(Math.random() * (999 - 100 + 1) + 100);
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 20px 0px 0px",
          }}
        >
          <IconTitle title={t("profile.skillsAndEndorsements")} img="/Image/apps/profile-resume-edit-view/intrests.svg" />
        </div>
        <br />
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              padding: this.props.skillContent.length <= 4 ? 0 : 20,
              // paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              borderBottom: this.props.skillContent.length <= 4 ? "none" : "1px solid #5555551A",
            }}
          >
            <div>
              <div style={{ marginTop: 10, display: "flex", flexWrap: "wrap" }}>
                {this.props.skillContent.length == 0 ? (
                  <div>{t("profile.NoInformationExistsForSkills")}</div>
                ) : (
                  this.props.skillContent.slice(0, this.state.endIdx).map((item, idx) => {
                    return (
                      <div
                        className={styles.wrap_100}
                        key={idx}
                        // style={{ width: "50%" }}
                      >
                        <div style={{ display: "flex" }}>
                          <div>
                            <img src={item.upload_img} height="50px" width="50px" style={{ borderRadius: 40, margin: 10 }} />
                          </div>
                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <p className={styles.section_title}>{item.title}</p>
                            <p className={styles.followers}>{this.getRandomNumber()} followers</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>

          {this.props.skillContent.length <= 4 ? null : (
            <div
              style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10 }}
              onClick={() => {
                if (this.state.endIdx > 4) {
                  this.setState({ endIdx: 4 });
                } else {
                  this.setState({ endIdx: this.props.skillContent.length });
                }

                if (this.state.seeall) {
                  this.setState({ seeall: false });
                } else {
                  this.setState({ seeall: true });
                }
              }}
            >
              <btn style={{ fontSize: 12, fontWeight: 600, color: "#555555", margin: 10 }}>
                {this.props.skillContent.length <= 4 ? null : this.state.seeall ? t("common.collapse") : t("common.seeAll")}
              </btn>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    skillContent: state.profile?.skillsView || [],
  };
}

export default withTranslation()(connect(mapStateToProps, null)(EndorsementsFeed));
