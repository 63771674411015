import { React, useState, useEffect } from 'react';
import '../Sign.css';
import {Link, useHistory, useLocation } from 'react-router-dom'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../redux/actions/forgotPassword';
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from 'react-i18next';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function ChangePassword() {

  const location = useLocation();
  const history = useHistory();
  const {t} = useTranslation();

  const horizontal='center';
  const vertical='top';
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [open, setOpen] = useState(false);
  const [message ,setMessage] = useState('');
  const [severity, setSeverity] = useState("");
  const dispatch = useDispatch();
  
  useEffect(() => {
    //console.log(location.search.split("=")[1])
  }, []);
  
  /* 
    Old Passowd Conditions:

      Min 1 uppercase letter.
      Min 1 lowercase letter.
      Min 1 special character.
      Min 1 number.
      Min 8 characters.
      Max 30 characters.

    New Password Conditions:
      Min 1 uppercase letter.
      Min 1 lowercase letter.
      Min 1 number.
      Min 1 special character
      Min 8 characters.
  
  */

  function handleSubmit(){
    let token = location.search.split("=")[1];
    // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/; 
    // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g;
    // const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=\\S+$).{8,}$/;
    // const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/
    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    
    if(password !== confirmPassword) {
      setMessage(t("profile.passwordDoesntMatch"));
      setOpen({ open: true });
      setSeverity("error");
      return
    }
    
    if(password === confirmPassword) {
      if(!(pattern.test(password))) {
        console.log('The Match Failed')
        // setMessage("Weak password, please make strong password (Minimum eight characters, at least one uppercase letter, one lowercase letter and one number )");
        setMessage(t("profile.weakPassword"));
        setOpen({ open: true });
        setSeverity("error");
        return
      }
      let payload = {
        token: token,
        password: password
      }
      dispatch(resetPassword(payload));
      setMessage(t("profile.passwordReset"));
      setOpen({ open: true });
      setSeverity("success");
      setTimeout(() => {
        history.push("/signin")
      }, 1000)
    }
  }

  return ( 
    <div className='sign-container'>
    <img src="Image/signbgLeft.svg" alt="#" />
      <div className='sign-container-left'>
        <Link to="/"><img className='sign-logo' src="Image/signImage/logo.svg" alt="#" /></Link>
          <div className='sign-form'>
            <div className='sign-heading'>
                <div>Forgot Password?</div>
                <div>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</div>
            </div>
          <div className="sign-input">
            <input className="sign-field" type="password" placeholder="New Password" onChange={(e) => setPassword(e.target.value.trim())} />
            <input className="sign-field" type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value.trim())} />
            {/* <Link to="reset_password"> */}
              <button className="button sign-btn" onClick={handleSubmit}>Reset</button>
            {/* </Link> */}
          </div>
          </div>
        </div>
      <div className="sign-container-right">
        <img src="Image/signbg.svg" alt="#" />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
   
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (payload) => dispatch(resetPassword(payload)),
  };
};

export default connect(null, mapDispatchToProps)(ChangePassword);
