import axios from "axios";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import { errorMessageFromResponse } from "../constants/commonFunctions";

import {
  ADD_ENTERPRISE_FACULTY_STUDENT_API,
  DELETE_ENTERPRISE_FACULTY_STUDENT_API,
  GET_DASHBOARD_DATA_API,
  GET_SEARCH_RESULT_API,
} from "../constants/apis";
import {
  GET_DASHBOARD_FACULTY,
  GET_DASHBOARD_STUDENT,
  GET_DASHBOARD_POSTS,
  GET_DASHBOARD_DATA_COUNT,
  GET_DASHBOARD_CLASS_ASSIGNMENT,
  GET_DASHBOARD_CLASS_QUIZ,
  GET_DASHBOARD_GROUP_ASSIGNMENT,
  GET_DASHBOARD_GROUP_QUIZ,
  GET_DASHBOARD_CLASS_COURSE,
  GET_DASHBOARD_CHART_DATA,
  GET_DASHBOARD_SEARCH_RESULT,
  GET_DASHBOARD_CLASS,
  GET_DASHBOARD_GROUP,
  GET_DASHBOARD_CLASS_LIST,
  GET_DASHBOARD_GROUP_LIST,
  GET_DASHBOARD_CLASS_POST,
  GET_DASHBOARD_GROUP_POST,
} from "../constants/actionTypes";

// type = "dashboard_faculty" || "dashboard_student"

export const getDashboardFacultyStudentPost = (type, id) => {
  const token = store.getState()?.profile?.user?.token;
  let entity = "";

  if (type === "dashboard_faculty") {
    entity = "ent-faculty";
  }
  if (type === "dashboard_student") {
    entity = "ent-student";
  }
  if (type === "dashboard_post") {
    entity = "ent-dashboard-posts";
  }
  // if(type === "dashboard_class") {
  //   entity = "ent-classes"
  // }
  // if(type === "dashboard_class_assignment") {
  //   entity = "ent-classes-assignments"
  // }
  // if(type === "dashboard_class_quiz") {
  //   entity = "ent-classes-quizzes"
  // }
  // if(type === "dashboard_class_course") {
  //   entity = "ent-classes-courses"
  // }
  // if(type === "dashboard_class_post") {
  //   entity = "ent-classes-posts"
  // }

  if (!id || !type) {
    return async (dispatch) =>
      dispatch({ type: GET_DASHBOARD_DATA_COUNT, payload: 0 });
  }

  return async (dispatch) => {
    await axios
      .get(`${GET_DASHBOARD_DATA_API}${entity}/?dept_id=${id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if (type === "dashboard_faculty") {
            dispatch({
              type: GET_DASHBOARD_FACULTY,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_student") {
            dispatch({
              type: GET_DASHBOARD_STUDENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_post") {
            dispatch({
              type: GET_DASHBOARD_POSTS,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// type = "dashboard_faculty" || "dashboard_student"
export const getDashboardClassGroupAssignmentQuiz = (type, id) => {
  const token = store.getState()?.profile?.user?.token;
  let entity = "";

  if (type === "dashboard_class_assignment") {
    entity = "ent-classes-assignments";
  }
  if (type === "dashboard_class_quiz") {
    entity = "ent-classes-quizzes";
  }
  if (type === "dashboard_group_assignment") {
    entity = "ent-group-assignments";
  }
  if (type === "dashboard_group_quiz") {
    entity = "ent-group-quizzes";
  }
  if (type === "dashboard_class_course") {
    entity = "ent-classes-courses";
  }
  if (type === "dashboard_main") {
    entity = "ent-dashboard";
  }
  if (type === "dashboard_class") {
    entity = "ent-classes";
  }
  if (type === "dashboard_group") {
    entity = "ent-groups";
  }

  if (!id || !type) {
    return async (dispatch) =>
      dispatch({ type: GET_DASHBOARD_DATA_COUNT, payload: 0 });
  }

  let enterpriseUserId = store.getState()?.profile?.user?.user_id || "";
  console.log('enterpriseUserID', enterpriseUserId);

  let url =
    type === "dashboard_main"
      ? `${GET_DASHBOARD_DATA_API}${entity}/?dept_id=${id}&user_id=${enterpriseUserId}&is_enterprise_admin=true`
      : `${GET_DASHBOARD_DATA_API}${entity}/?dept_id=${id}&user_id=${enterpriseUserId}`;

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if (type === "dashboard_class_assignment") {
            dispatch({
              type: GET_DASHBOARD_CLASS_ASSIGNMENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_quiz") {
            dispatch({
              type: GET_DASHBOARD_CLASS_QUIZ,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_course") {
            dispatch({
              type: GET_DASHBOARD_CLASS_COURSE,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group_assignment") {
            dispatch({
              type: GET_DASHBOARD_GROUP_ASSIGNMENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group_quiz") {
            dispatch({
              type: GET_DASHBOARD_GROUP_QUIZ,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class") {
            dispatch({
              type: GET_DASHBOARD_CLASS,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group") {
            dispatch({
              type: GET_DASHBOARD_GROUP,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_main") {
            dispatch({
              type: GET_DASHBOARD_CHART_DATA,
              payload: response?.data?.data,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// --------- search user on the whole platfrom -----------------------

export const getSearchResult = (searchQuery, isFaculty) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "get",
      url: `${GET_SEARCH_RESULT_API}?search=${searchQuery}&subs_type=${isFaculty ? "FACULTY" : "STUDENT"}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          // const list = response?.data?.data;
          console.log("theSearchRes", response?.data?.data);
          dispatch({
            type: GET_DASHBOARD_SEARCH_RESULT,
            payload: response?.data?.data,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ------------search a student or faculty --------------

export const getStudentFacultySearchResult = (type, id, searchQuery) => {
  const token = store.getState()?.profile?.user?.token;
  let entity = "";

  if (type === "dashboard_faculty") {
    entity = "ent-faculty";
  }
  if (type === "dashboard_student") {
    entity = "ent-student";
  }
  // if (type === "dashboard_post") {
  //   entity = "ent-dashboard-posts";
  // }
  if (type === "dashboard_class") {
    entity = "ent-classes";
  }
  if (type === "dashboard_group") {
    entity = "ent-groups";
  }
  // if(type === "dashboard_class_assignment") {
  //   entity = "ent-classes-assignments"
  // }
  // if(type === "dashboard_class_quiz") {
  //   entity = "ent-classes-quizzes"
  // }
  // if(type === "dashboard_class_course") {
  //   entity = "ent-classes-courses"
  // }

  if (!id || !type) {
    return async (dispatch) =>
      dispatch({ type: GET_DASHBOARD_DATA_COUNT, payload: 0 });
  }

  return async (dispatch) => {
    await axios
      .get(
        `${GET_DASHBOARD_DATA_API}${entity}/?dept_id=${id}&search=${searchQuery}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if (type === "dashboard_faculty") {
            dispatch({
              type: GET_DASHBOARD_FACULTY,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_student") {
            dispatch({
              type: GET_DASHBOARD_STUDENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }

          if (type === "dashboard_class") {
            dispatch({
              type: GET_DASHBOARD_CLASS,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group") {
            dispatch({
              type: GET_DASHBOARD_GROUP,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ----------- get Enterprise Classes and Groups -----

export const getEnterpriseClassesAndGroups = (type, id) => {
  const token = store.getState()?.profile?.user?.token;
  let entity = "";

  if (type === "dashboard_class") {
    entity = "ent-classes";
  }
  if (type === "dashboard_group") {
    entity = "ent-groups";
  }

  if (!id || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  let url = `${GET_DASHBOARD_DATA_API}${entity}/?dept_id=${id}`;

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if (type === "dashboard_class") {
            dispatch({
              type: GET_DASHBOARD_CLASS_LIST,
              payload: response?.data?.data,
            });
          }
          if (type === "dashboard_group") {
            dispatch({
              type: GET_DASHBOARD_GROUP_LIST,
              payload: response?.data?.data,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ---------- get dashboard-class-group-post ------------

export const getDashboardClassGroupPost = (type, id) => {
  const token = store.getState()?.profile?.user?.token;
  let entity = "";

  if (type === "dashboard_class_post") {
    entity = "ent-classes-posts";
  }
  if (type === "dashboard_group_post") {
    entity = "ent-group-posts";
  }
  if (type === "dashboard_post") {
    entity = "ent-dashboard-posts";
  }

  if (!id || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  let url = `${GET_DASHBOARD_DATA_API}${entity}/?dept_id=${id}`;

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if (type === "dashboard_class_post") {
            dispatch({
              type: GET_DASHBOARD_CLASS_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group_post") {
            dispatch({
              type: GET_DASHBOARD_GROUP_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_post") {
            dispatch({
              type: GET_DASHBOARD_POSTS,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ----------------- assignment quiz post search  --------------------------

export const getAssignmentQuizPostSearchResult = (
  type,
  departmentID,
  classGroupId,
  searchQuery
) => {
  const token = store.getState()?.profile?.user?.token;
  let entity = "";

  if (type === "dashboard_class_assignment") {
    entity = "ent-classes-assignments";
  }
  if (type === "dashboard_class_quiz") {
    entity = "ent-classes-quizzes";
  }
  if (type === "dashboard_class_course") {
    entity = "ent-classes-courses";
  }
  if (type === "dashboard_class_post") {
    entity = "ent-classes-posts";
  }
  if (type === "dashboard_group_post") {
    entity = "ent-group-posts";
  }
  if (type === "dashboard_post") {
    entity = "ent-dashboard-posts";
  }

  if (!departmentID || !type) {
    return async (dispatch) =>
      dispatch({ type: GET_DASHBOARD_DATA_COUNT, payload: 0 });
  }

  let url =
    type === "dashboard_post" || !classGroupId
      ? `${GET_DASHBOARD_DATA_API}${entity}/?dept_id=${departmentID}&search=${searchQuery}`
      : `${GET_DASHBOARD_DATA_API}${entity}/?${
          type.includes("class") ? "class_id" : "group_id"
        }=${classGroupId}&search=${searchQuery}`;

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if (type === "dashboard_class_assignment") {
            dispatch({
              type: GET_DASHBOARD_CLASS_ASSIGNMENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_quiz") {
            dispatch({
              type: GET_DASHBOARD_CLASS_QUIZ,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }

          if (type === "dashboard_class_course") {
            dispatch({
              type: GET_DASHBOARD_CLASS_COURSE,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_post") {
            dispatch({
              type: GET_DASHBOARD_POSTS,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_post") {
            dispatch({
              type: GET_DASHBOARD_CLASS_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group_post") {
            dispatch({
              type: GET_DASHBOARD_GROUP_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_DASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// -------------------------- deleteFacultyStudent ---------------

export const deleteEnterpriseFacultyStudent = (type, studentFacultyUserId) => {
  const token = store.getState()?.profile?.user?.token;

  if (!studentFacultyUserId) {
    return async (dispatch) => dispatch({ type: null });
  }

  let data = new FormData();
  data.append("dept_mem", studentFacultyUserId);

  return async (dispatch) => {
    await axios({
      method: "delete",
      data: data,
      url: DELETE_ENTERPRISE_FACULTY_STUDENT_API,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 
        ) {
          // if (type === "dashboard_faculty") {
          //   dispatch({
          //     type: GET_DASHBOARD_CLASS_ASSIGNMENT,
          //     payload: response?.data?.data,
          //   });
          // }
          // if (type === "dashboard_student") {
          //   dispatch({
          //     type: GET_DASHBOARD_CLASS_QUIZ,
          //     payload: response?.data?.data,
          //   });
          // }

          if (type === "dashboard_faculty") {
            dispatch(
              handleSuccessMessage(i18n.t("enterpriseDashboard.facultyRemoved"))
            );
          }
          if (type === "dashboard_student") {
            dispatch(
              handleSuccessMessage(i18n.t("enterpriseDashboard.studentRemoved"))
            );
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ----------------------- Add faculty or Student to department ---------------

export const AddFacultyStudentToDepartment = (
  type,
  departmentID,
  studentFacultyUserId
) => {
  const token = store.getState()?.profile?.user?.token;

  if (!studentFacultyUserId) {
    return async (dispatch) => dispatch({ type: null });
  }

  let data = new FormData();
  data.append("dept_mem", studentFacultyUserId);
  data.append("department", departmentID);

  return async (dispatch) => {
    await axios({
      method: "post",
      data: data,
      url: ADD_ENTERPRISE_FACULTY_STUDENT_API,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          // if (type === "dashboard_faculty") {
          //   dispatch({
          //     type: GET_DASHBOARD_CLASS_ASSIGNMENT,
          //     payload: response?.data?.data,
          //   });
          // }
          // if (type === "dashboard_student") {
          //   dispatch({
          //     type: GET_DASHBOARD_CLASS_QUIZ,
          //     payload: response?.data?.data,
          //   });
          // }

          if (type === "dashboard_faculty") {
            dispatch(
              handleSuccessMessage(i18n.t("enterpriseDashboard.facultyAdded"))
            );
          }
          if (type === "dashboard_student") {
            dispatch(
              handleSuccessMessage(i18n.t("enterpriseDashboard.studentAdded"))
            );
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ------------- active/inactive class/group ---------------

export const activeInactiveClassGroup = (classGroupId, newStatus, type) => {
  const token = store.getState()?.profile?.user?.token;

  if (!classGroupId) {
    return async (dispatch) => dispatch({ type: null });
  }

  let url = `${process.env.REACT_APP_API_BASE_URL}api/v1/deptSubscription/${
    newStatus ? "active" : "inactive"
  }${type.includes("class") ? "Class" : "Group"}/?${
    type.includes("class") ? "class_id" : "group_id"
  }=${classGroupId}`;

  return async (dispatch) => {
    await axios({
      method: "post",
      url: url,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 
        ) {
          // if (type === "dashboard_faculty") {
          //   dispatch({
          //     type: GET_DASHBOARD_CLASS_ASSIGNMENT,
          //     payload: response?.data?.data,
          //   });
          // }
          // if (type === "dashboard_student") {
          //   dispatch({
          //     type: GET_DASHBOARD_CLASS_QUIZ,
          //     payload: response?.data?.data,
          //   });
          // }

          dispatch(
            handleSuccessMessage(i18n.t("enterpriseDashboard.statusUpdated"))
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
