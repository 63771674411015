import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./GradebookTableRow.module.css";

const GradebookTableRow = (props) => {
  const { t } = useTranslation();
  const details = props.studentDetails;

  return (
    <div className={styles.main}>
      <p>{((props.page-1)*10) + (props.index)}.</p>
      <p>{details?.name}</p>
      <p className={styles.hide}>{details?.avgAssPerc}%</p>
      <p className={styles.hide}>{details?.avgQuizPerc}%</p>
      <p className={styles.hide}>{details?.avgScore}%</p>
      <div className={styles.view_btn} onClick={() => props.onClick(details?.id)}>
        <img src="/Image/Gradebook/visibility_icon_red.svg" alt="icon"/>
        <p>{t("gradebook.viewDetails")}</p>
      </div>
    </div>
  );
};

export default GradebookTableRow;
