import React, { useState, useEffect } from "react";
import styles from "./PossibleChoice.module.css";
import ShortTextIcon from "@mui/icons-material/ShortText";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import IconButton from "@mui/material/IconButton";
import {
  Theme,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// props: questionType  -- radio or checkbox else text and other
// props: onRemove function

const PossibleChoice = (props) => {
  const checkedRef = useRef();
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [choiceText, setChoiceText] = useState(
    props?.actionType == "edit" ? props?.choice?.choiceText : ""
  );
  const [correctChoice, setCorrectChoice] = useState(
    props?.actionType == "edit" ? props?.choice?.correctChoice : ""
  ); // for grid choice only
  const [isChecked, setIsChecked] = useState(
    props?.actionType == "edit" && props?.choice?.isCorrect ? true : false
  );
  const [uploadedImage, setUploadedImage] = useState(
    props?.actionType == "edit" ? props?.choice?.choiceText : null
  );

  const handleChoiceInput = (data) => {
    props.getChoiceInput(props.questionIndex, props.choiceIndex, data);
  };

  const handleGridCorrectChoiceInput = (text) => {
    props.getGridChoiceInput(
      props.questionIndex,
      props.choiceIndex,
      choiceText,
      text
    );
  };

  const uploadImageHandler = () => {
    inputRef.current.click();
  };
  const colortheme = createTheme({
    palette: {
      primary: { main: "#ED1E24" },
    },
  });

  // ===== image menu =========
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.main}>
      {props.questionType != "grid" &&
        (props.questionType == "mcq" ? (
          <input type="radio" disabled />
        ) : props?.questionType == "checkboxes" ? (
          <input type="checkbox" disabled />
        ) : props.questionType == "trueFalse" ? (
          <input type="radio" disabled />
        ) : props?.questionType == "imageChoice" ? (
          <input type="checkbox" disabled />
        ) : props?.questionType == "fillInBlanks" ? null : (
          <ShortTextIcon />
        ))}

      {props?.questionType == "imageChoice" ? (
        <>
          <div className={styles.image_container}>
            {uploadedImage == null ? (
              <div className={styles.img_empty_option}>
                <img
                  alt=""
                  src="/Image/add_photo.svg"
                  className={styles.upload_icon}
                  onClick={uploadImageHandler}
                />
                <img
                  alt="remove choice"
                  src={"/Image/assignment/Cross.svg"}
                  onClick={props?.onRemove}
                  className={styles.img_cross}
                />
              </div>
            ) : (
              <ImageList
                sx={{
                  width: "100%",
                  height: 200,
                  overflow: "hidden",
                  objectFit: "cover",
                }}
                cols={1}
                rowHeight={164}
              >
                <ImageListItem>
                  <img
                    src={uploadedImage}
                    srcSet={uploadedImage}
                    alt={uploadedImage?.name}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    position="top"
                    title={uploadedImage?.name}
                    actionIcon={
                      <IconButton sx={{ color: "rgba(255, 255, 255, 0.54)" }}>
                        {/* <InfoIcon onClick={props?.onRemove} /> */}
                        <img
                          src="/Image/assignment/more_horiz_3.svg"
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          PaperProps={{
                            style: {
                              boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                              borderRadius: "10px",
                              fontFamily: "Montserrat",
                            },
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              props.onRemove();
                              setAnchorEl(null);
                            }}
                          >
                            {t("enhancedQuiz.removeChoice")}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setIsChecked(!props?.choice?.isCorrect);
                              props.changeCorrectStatus(
                                props.questionIndex,
                                props.choiceIndex,
                                !props?.choice?.isCorrect
                              );
                              setAnchorEl(null);
                            }}
                          >
                            {props?.choice?.isCorrect
                              ? t("enhancedQuiz.markIncorrect")
                              : t("enhancedQuiz.markCorrect")}
                          </MenuItem>
                        </Menu>
                      </IconButton>
                    }
                  />
                </ImageListItem>
              </ImageList>
            )}
            <input
              onChange={(e) => {
                setUploadedImage(URL.createObjectURL(e.target.files[0]));
                handleChoiceInput(e.target.files[0]);
              }}
              type="file"
              accept="image/*"
              ref={inputRef}
              className={styles.fileUpload + " " + styles.text_input}
              hidden
            />
          </div>
          {isChecked && (
            <img
              src="/Image/assignment/checkmark_green.svg"
              alt=""
              className={styles.image_checked}
            />
          )}
        </>
      ) : props?.questionType == "fillInBlanks" ? (
        <div className={styles.fib_option}>
          <ThemeProvider theme={colortheme}>
            <TextField
              label={`${t("enhancedQuiz.blankAnswer")} ${
                props?.choiceIndex + 1
              }`}
              placeholder={`${t("enhancedQuiz.addBlankAnswer")} ${
                props?.choiceIndex + 1
              }`}
              variant="standard"
              value={correctChoice}
              onChange={(e) => {
                setCorrectChoice(e.target.value);
                handleChoiceInput(e.target.value);
              }}
            />
          </ThemeProvider>
          <img
            alt="remove choice"
            src={"/Image/assignment/Cross.svg"}
            onClick={props?.onRemove}
            className={styles.fib_cross}
          />
        </div>
      ) : props?.questionType != "grid" ? (
        <input
          type="text"
          value={choiceText}
          placeholder={t("enhancedQuiz.addOption")}
          className={styles.text_input}
          onChange={(e) => {
            setChoiceText(e.target.value);
            handleChoiceInput(e.target.value);
          }}
        />
      ) : null}

      {/* {<img src="/Image/assignment/collections.svg" alt="" className={styles.image_choice} />} */}

      {props.questionType != "grid" && props.questionType != "imageChoice" && (
        <div className={styles.correct}>
          <img
            alt=""
            src={"/Image/assignment/Cross.svg"}
            onClick={props?.onRemove}
          />
          <input
            type="checkbox"
            ref={checkedRef}
            onChange={(e) => {
              setIsChecked(e.target.checked);
              props?.changeCorrectStatus(
                props?.questionIndex,
                props?.choiceIndex,
                e.target.checked
              );
            }}
          />
          <span>{t("enhancedQuiz.correctAnswer")}</span>
        </div>
      )}

      {props.questionType != "grid" &&
        props.questionType != "imageChoice" &&
        isChecked && <img src="/Image/assignment/checkmark_green.svg" alt="" />}

      {/* =---------- for Grid type questions ---------- */}

      {props.questionType === "grid" && (
        <div className={styles.grid_choice}>
          <div className={styles.grid_choice_inner}>
            <span>{`${props?.choiceIndex + 1}. `}</span>
            <input
              type="text"
              value={choiceText}
              placeholder={t("enhancedQuiz.addOption")}
              className={styles.text_input_grid}
              onChange={(e) => {
                setChoiceText(e.target.value);
                handleChoiceInput(e.target.value);
              }}
            />
            {/* <img
              alt="remove choice"
              src={"/Image/assignment/Cross.svg"}
              onClick={props?.onRemove}
            /> */}
            <>
              <span>{`${String.fromCharCode(
                props?.choiceIndex + 1 + 64
              )}. `}</span>
              <input
                type="text"
                value={correctChoice}
                placeholder={t("enhancedQuiz.addCorrect")}
                className={styles.text_input_grid}
                onChange={(e) => {
                  setCorrectChoice(e.target.value);
                  handleGridCorrectChoiceInput(e.target.value);
                }}
              />
              <img
                alt="remove choice"
                src={"/Image/assignment/Cross.svg"}
                onClick={props?.onRemove}
              />
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default PossibleChoice;
