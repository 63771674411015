import { GET_ERROR_MESSAGE, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_REQUEST, RESET_ERROR_MESSAGE, SIGNUP_STATUS } from "../constants/actionTypes";
import axios from "axios";
import { errorMessageFromResponse, formatName } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";

//Define actions here. If you have actions which we need reducer,return {type:ACTION_NAME,payload:data}
//For API calls, use axios as used in loginUser function below.
export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};
export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const resetError = () => {
  return{
    type: RESET_ERROR_MESSAGE,
    payload: "",
  }
}

export const loginFailure = (error) => {
  return {
    type: GET_ERROR_MESSAGE,
    payload: error,
  };
};

export const logoutUser = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("token");
  return {
    type: LOGOUT_REQUEST,
  };
};

export const loginUser = (email, password) => {
  return async (dispatch) => {
    

    let payload = JSON.stringify({
      username: email,
      password: password,
    });
    const loginapiUrl = process.env.REACT_APP_API_BASE_URL + "api/v1/login/";
    const localloginapiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/login/`;
    await axios
      .post(localloginapiUrl, payload, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((response) => {
        const user = response.data.data;
        user.first_name = formatName(user.first_name);
        user.last_name = formatName(user.last_name);

        localStorage.setItem("token", response.data.data.token);
        localStorage.setItem("userData", JSON.stringify(user));
        dispatch(resetError())
        dispatch(loginSuccess(user));
        return response;
      })
      .catch(async (error) => {
        localStorage.clear();
        dispatch(handleErrorMessage(errorMessageFromResponse(error)))
        return error;
      });
  };
};

export const signUpUser = (data) => {
  const password = data.password;
  const email = data.email;

  return async (dispatch) => {
    const signupapiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/users/`;
    
      await axios({
        method: "post",
        url: signupapiUrl,
        data: data,
        headers: {
          "content-type": "application/json",
        },
      })
        .then((res) => {
          dispatch({
            type: SIGNUP_STATUS,
            payload: res.status
          })

          // if (res.status === 201) {
              // dispatch(loginUser(email, password))
          //     if (userRef.current?.token) {
          //       history.push("/user");
          //     } else {
          //       setMessage("Incorrect credentials/Network Error");
          //       setOpen({ open: true });
          //       setSeverity("error");
          //     }
          //   });
          //   setMessage("Account Created Successfuly");
          //   setOpen({ open: true });
          //   setSeverity("success");
          // }
        // }
      })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)))
          return error;
        });
   
  };
};
