import React from "react";
import { withTranslation } from "react-i18next";
import styles from "../../apps/Profile/Profile.module.css";
import IconTitle from "./IconTitle";

class StatsFeed extends React.Component {
  render() {
    const {t} = this.props;
    return (
      <div>
        <div
          id="Dashboard"
          style={{
            scrollMargin: "120px 0px 0px 0px",
          }}
        >
          <IconTitle title={t("profile.Dashboard")} img="/Image/apps/profile-resume-edit-view/dashboard.svg" />
          <br />

          <div
            className={styles.desc}
            style={{
              boxShadow: "0px 0px 20px #5555551a",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <div style={{ display: "flex", alignItems: "center", borderBottom: "1px solid #e2e2e2", paddingBottom: 10, justifyContent: "space-between" }}>
              <div>
                <p
                  style={{
                    margin: 0,
                    fontSize: 16,
                    padding: "10px 0px",
                    fontWeight: 600,
                    color: "#ED1E24",
                  }}
                >
                  35
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: 12,
                    color: "#555555",
                    fontWeight: 600,
                  }}
                >
                  {t("profile.whoViewedYourProfile")}
                </p>
              </div>
              <div className={styles.col_5}>
                <img src="/Image/apps/profile-resume-edit-view/sidechart1.png" alt=" " height={80} />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: 10 }}>
              <div>
                <p
                  style={{
                    margin: 0,
                    fontSize: 16,
                    padding: "10px 0px",
                    fontWeight: 600,
                    color: "#ED1E24",
                  }}
                >
                  316
                </p>
                <p
                  style={{
                    margin: 0,
                    fontSize: 12,
                    color: "#555555",
                    fontWeight: 600,
                  }}
                >
                  {t("profile.searchAppearance")}
                </p>
              </div>
              <div>
                <img src="/Image/apps/profile-resume-edit-view/sidechart2.png" alt=" " height={80} />
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    );
  }
}
export default withTranslation()(StatsFeed);
