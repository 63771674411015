import React from "react";
import styles from "./Settings.module.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { putUserSettings } from "../../redux/actions/zoomAction";
import { getUserSettings } from "../../redux/actions/zoomAction";
import {
  createPayment,
  failurePayment,
  successPayment,
} from "../../redux/actions/payment";
import { handleErrorMessage } from "../../redux/actions/classes";
import { withTranslation } from "react-i18next";
class Settings extends React.Component {
  constructor() {
    super();
    this.state = {
      copied: false,
    };
  }

  openPayModal() {
    var options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      //   "amount": 0, // 2000 paise = INR 20, amount in paisa
      name: this.props.paymentCreated?.options_name,
      description: this.props.paymentCreated?.options_description,
      order_id: this.props.paymentCreated?.options_order_id,
      image: this.props.paymentCreated?.options_image,
      handler: (response) => {
        var values = {
          razorpay_signature: response.razorpay_signature,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          // transactionamount : amount,
        };
        this.props.successPayment(values);
        this.putZoomSettings(
          this.props.accountType == "PAID" ? "FREE" : "PAID"
        );
      },
      prefill: {
        name: this.props.paymentCreated?.prefill_name,
        email: this.props.paymentCreated?.prefill_email,
        //   "contact":'1234567890',
      },
      notes: {
        address: this.props.paymentCreated?.notes_address,
      },
      theme: {
        color: "#ED1E24",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", (response) => {
      var data = {
        order_id: response.error.metadata.order_id,
        payment_id: response.error.metadata.payment_id,
        code: response.error.code,
        description: response.error.description,
        reason: response.error.reason,
        step: response.error.step,
        source: response.error.source,
      };
      this.props.failurePayment(data);
    });
    rzp1.open();
  }
  getZoomSettings = () => {
    this.props.getUserSettings().then(() => {});
  };
  componentDidMount() {
    // this.getZoomSettings()
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }
  editConfiguration = () => {
    let { history } = this.props;
    history.push("/zoomconfiguration");
  };
  putZoomSettings = (accountType) => {
    let { history } = this.props;
    let payload = {
      zoom_settings: { account_type: accountType },
    };
    this.props.putUserSettings(payload).then(() => {
      const nextUrl = accountType == "FREE" ? "/zoomconfiguration" : "/video";
      history.push({
        pathname: nextUrl,
        state: { showPaymentSuccessModal: true },
      });
    });
  };

  render() {
    return (
      <div className={styles.settingsContainer}>
        <div className={styles.settingsArea}>
          <div className={styles.heading}>
            {this.props.t("subscription.accountSettings")}
          </div>
          <div className={styles.settingsSection}>
            <div className={styles.headingSection} style={{ display: "flex" }}>
              <div className={styles.sectionHeading}>
                {this.props.t("subscription.zoomAccount")}
              </div>
              <img
                style={{ cursor: "pointer" }}
                src="/Image/DashboardImages/DashCommonImages/Chalkmate_Edit.svg"
                height={25}
                width={25}
                onClick={this.editConfiguration}
              />
            </div>
            <hr></hr>
            <div className={styles.headingSection}>
              <div
                className={styles.subHeading}
                style={{ display: "flex", alignItems: "center" }}
              >
                {this.props.t("subscription.accountType")}
                <div
                  style={{
                    padding: "2px 8px",
                    fontSize: "12px",
                    fontWeight: "700",
                    borderRadius: "4px",
                    backgroundColor: "#ED1E24",
                    marginLeft: "10px",
                    textTransform: "uppercase",
                    color: "white",
                  }}
                >
                  {this.props.accountType != "PAID" ||
                  this.props.accountType === ""
                    ? this.props.t("subscription.free")
                    : this.props.t("subscription.paid")}
                </div>
              </div>
              {this.props.accountType != "PAID" ? (
                <div
                  className={styles.accountType}
                  onClick={() => {
                    this.props.createPayment().then(() => {
                      this.openPayModal();
                    });
                  }}
                >
                  {this.props.t("subscription.upgradeAccount")}
                </div>
              ) : (
                <div
                  className={styles.accountType}
                  onClick={() => {
                    this.putZoomSettings(
                      this.props.accountType == "PAID" ? "FREE" : "PAID"
                    );
                  }}
                >
                  {this.props.t("subscription.unsubscribe")}
                </div>
              )}
            </div>
            <hr></hr>
            <div className={styles.headingSection}>
              <div className={styles.subHeading}>
                {this.props.t("subscription.meetingLink")}
              </div>
              <div
                className={styles.details}
                onClick={() => {
                  navigator.clipboard.writeText(this.props.meetingLink);
                  this.setState({ copied: true });
                }}
              >
                {this.props.meetingLink === "" ? (
                  this.props.t("subscription.configureMeetingLink")
                ) : (
                  <div
                    style={{
                      color: this.state.copied ? "#ED1E24" : "#000000",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.link}>{this.props.meetingLink}</div>
                    <img
                      className={styles.copyButton}
                      height="25px"
                      width="25px"
                      src="/Image/DashboardImages/sharemodImages/copy.svg"
                    />
                  </div>
                )}
              </div>
            </div>
            <hr></hr>
            <div className={styles.headingSection}>
              <div className={styles.subHeading}>
                {this.props.t("subscription.platform")}
              </div>
              <div className={styles.details}>
                {this.props.t("subscription.zoom")}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightImage}>
          <img src="/Image/zoomConfigure.svg" />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accountType: state?.zoom?.zoom_settings?.zoomSettings?.account_type,
    meetingLink: state?.zoom?.zoom_settings?.zoomSettings?.default_meeting_link,
    zoomId: state?.zoom?.zoom_settings?.zoomId,
    paymentCreated: state?.zoom?.paymentCreated,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserSettings: () => dispatch(getUserSettings()),
    putUserSettings: (accountType) => dispatch(putUserSettings(accountType)),
    createPayment: () => dispatch(createPayment()),
    successPayment: (data) => dispatch(successPayment(data)),
    failurePayment: (data) => dispatch(failurePayment(data)),
    handleErrorMessage: (message) => dispatch(handleErrorMessage(message)),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Settings))
);
