import reactDom from "react-dom";
import React from "react";
import { withRouter } from "react-router";
import styles from "../../apps/Dashboard/Components/CSSFiles/Sidebar.module.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUserSettings } from "../../redux/actions/zoomAction";
import { withTranslation } from "react-i18next";
import IconTitle from "./IconTitle";

class StartLive extends React.Component {
  constructor(props) {
    super();

    this.state = {
      img: "/Image/DashImage/live-session.svg",
    };
  }

  getZoomSettings = () => {
    let { history } = this.props;
    this.props.getUserSettings().then(() => {
      if (this.props.accountType === "") {
        history.push("/subscription");
      } else {
        if (this.props.accountType == "PAID") {
          history.push("/video");
        } else {
          history.push("/zoomconfiguration");
        }
      }
    });
  };

  render() {
    localStorage.setItem("source", this.props.source);
    return (
      <div className={styles.live_stream}>
        <button
          onClick={this.getZoomSettings}
          className={styles.live_session}
          onMouseOver={() => {
            this.setState({ img: "/Image/DashImage/live-session-hover.svg" });
          }}
          onMouseOut={() => {
            this.setState({ img: "/Image/DashImage/live-session.svg" });
          }}
        >
          <img
            src={this.state.img}
            style={{ marginRight: "10px", width: 24, height: 24 }}
          />
          <span>
            {this?.props?.startLiveText || this.props.t("dashboard.startLive")}
          </span>
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accountType: state?.zoom?.zoom_settings?.zoomSettings?.account_type,
    zoomId: state?.zoom?.zoom_settings?.zoomId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserSettings: () => dispatch(getUserSettings()),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(StartLive))
);
