import React, { useState, useEffect } from "react";
import styles from "./css/GradebookStudentDetails.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  CircularProgress,
} from "@mui/material";
import {
  downloadGradebook,
  getAsgQuizResults,
} from "../../redux/actions/gradebook";
import cloneDeep from 'lodash.clonedeep'
import { produce } from "immer";
import IconTitle from "../../components/commons/IconTitle";
import StudentNameComponent from "./Components/StudentNameComponent";
import GradebookTabComponent from "./Components/GradebookTabComponent";
import ButtonMenuComponent from "./Components/ButtonMenuComponent";

const GradebookStudentDetails = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isPrinting, setIsPrinting] = useState(false);

  const storedResult = useSelector((state) => state?.gradebook?.result);
  const [result, setResult] = useState(storedResult);

  const routeType = location?.state?.routeType || "class";
  // const classOrGroupName = useSelector((state) =>
  //   routeType === "class"
  //     ? state?.classes?.class?.name
  //     : state?.groups?.group?.name
  // );
  const classOrGroupName = location?.state?.classOrGroupName;

  const classOrGroupId = parseInt(location?.state?.classOrGroupId);
  const profileId = parseInt(location?.state?.profileId);
  const profileData = storedResult?.student;

  const assignmentCount = storedResult?.AssignmentCount;
  const quizCount = storedResult?.QuizCount;
  

  useEffect(() => {
    const isClass = routeType === "class";
    dispatch(
      getAsgQuizResults(profileId, classOrGroupId, isClass)
    ).then(() => setIsLoading(false));
  }, [profileId]);

  useEffect(()=>{
    setResult(cloneDeep(storedResult));
  }, [storedResult])

  const sortByMarks = (a,b) => {
    return b.marks - a.marks;
  }

  const sortAsgByDate = (a,b) => {
    if(new Date(a.submission_date) < new Date(b.submission_date)){
      return 1
    } else if (new Date(a.submission_date) > new Date(b.submission_date)) {
      return -1
    } else {
      return 0
    }
  }

  const sortQuizByDate = (a,b) => {
    if(new Date(a.submission_date) < new Date(b.submission_date)){
      return 1
    } else if (new Date(a.submission_date) > new Date(b.submission_date)) {
      return -1
    } else {
      return 0
    }
  }

  const sortHandler = (sortBy) => {

    if(sortBy === "date"){
      setResult(
        produce(result, draftResult => {
          draftResult?.AssignmentList.sort(sortAsgByDate);
          draftResult?.QuizList.sort(sortQuizByDate);
          return draftResult;
        })
      )
    } 
    
    if(sortBy === "marks"){
      setResult(
        produce(result, draftResult => {
          draftResult?.AssignmentList.sort(sortByMarks);
          draftResult?.QuizList.sort(sortByMarks);
          return draftResult;
        })
      )
    }
  }

  const printHandler = () => {
    setIsPrinting(true);
    console.log("Print this", classOrGroupId, profileId)
    const isClass = routeType === "class";
    dispatch(downloadGradebook(parseInt(classOrGroupId), parseInt(profileId), isClass))
    .then(()=>setIsPrinting(false));
  }

  

  if (isLoading) {
    return (
      <div className={styles.loader_style}>
        <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
      </div>
    );
  } else {
    return (
      <div className={styles.main_container}>
        <h5
          className={styles.back_btn}
          onClick={() => {
            if (classOrGroupId) {
              history.push({
                pathname: `/${routeType}/${classOrGroupId}`,
              });
            } else {
              history.push({
                pathname: `/user`,
              });
            }
          }}
        >
          <img src="/Image/assignment/back_arrow.svg" alt="" />
          &nbsp; &nbsp;
          {classOrGroupName ?? t("courseMonetisation.goBackBtn")}
        </h5>

        <div className={styles.gradebook_outer_container}>
          <IconTitle
            title={t("gradebook.gradebook")}
            img="/Image/Gradebook/star-white2.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />

          <div className={styles.gradebook_inner_container}>
            <div className={styles.header}>
              <StudentNameComponent
                studentName={profileData?.name}
                profilePic={profileData?.profile_pic}
                classOrGroupName={classOrGroupName}
              />

              <div className={styles.asg_quiz_count_container}>
                <div className={styles.box}>
                  <div className={styles.icon}>
                    <img
                      src="/Image/assignment/White/Assignment.svg"
                      alt="icon"
                    />
                  </div>
                  <div className={styles.label}>
                    {t("assignment.assignments")} -{" "}
                    <span>{assignmentCount || ""}</span>
                  </div>
                </div>

                <div className={styles.box}>
                  <div className={styles.icon}>
                    <img src="/Image/assignment/White/Quiz.svg" alt="icon" />
                  </div>
                  <div className={styles.label}>
                    {t("quiz.quizzes")} - <span>{quizCount || ""}</span>
                  </div>
                </div>
              </div>

              <div className={styles.avg_score_container}>
                <p className={styles.label}>{t("gradebook.averageScore")}: </p>
                <p className={styles.avgScore}>{`${
                  profileData?.avgScore || 0
                }%`}</p>
              </div>

              <ButtonMenuComponent showSort={true} onPrint={printHandler} onSort={sortHandler} isPrinting={isPrinting}/>

              

            </div>

            {/* --- Table --- */}
            <GradebookTabComponent
              profileData={profileData}
              result={result}
              classOrGroupName={classOrGroupName}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default GradebookStudentDetails;
