import { CHANGE_CURRENT_LANGUAGE, COMMENT_DISLIKE, COMMENT_LIKE, GET_DASHBOARD_POST, POST_COMMENT, POST_DISLIKE, POST_LIKE, TRANSLATE_DATA, GET_MY_POSTS } from "../constants/actionTypes";
import cloneDeep from "lodash.clonedeep"

const defaultState = {
  posts: [],
  currentLanguage: localStorage.getItem("i18nextLng"),
  myPosts: {
    dashboard: null,
    classes: null,
    groups: null,
  }
};
let postData = {};
export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_POST:
      postData = { ...state };
      postData.posts = action.payload;
      return postData;
    case POST_LIKE:
      postData = { ...state };
      postData.newLike = action.payload;
      return postData;
    case POST_COMMENT:
      postData = { ...state };
      postData.newComment = action.payload;
      return postData;
    case COMMENT_LIKE:
      postData = { ...state };
      postData.newCommentLike = action.payload;
      return postData;
    case POST_DISLIKE:
      return { ...state };
    case COMMENT_DISLIKE:
      return { ...state };
    case TRANSLATE_DATA: 
      postData = {...state};
      postData.newTranslation = action.payload;
      return postData;
    case CHANGE_CURRENT_LANGUAGE:
      postData = {...state};
      postData.currentLanguage = action.payload;
      return postData;

    case GET_MY_POSTS:
      let data = cloneDeep(state);
      if(action.payload.postType === "DASHBOARD") {
        data.myPosts.dashboard = action.payload.posts;
      } else if (action.payload.postType === "CLASS") {
        data.myPosts.classes = action.payload.posts;
      } else if (action.payload.postType === "GROUP") {
        data.myPosts.groups = action.payload.posts;
      }
      return data;


    default:
      return state;
  }
};
