import axios from "axios";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import {
  SEND_MESSAGE,
  GET_CHAT_LIST,
  GET_CHAT_MESSAGES,
  GET_SEARCH_RESULT,
  CREATE_CHANNEL,
  UPDATE_CHAT_LIST,
} from "../constants/actionTypes";
import { GET_CHAT_LIST_API, GET_CHAT_MESSAGES_API, SEND_MESSAGE_API, GET_SEARCH_RESULT_API, CREATE_CHANNEL_API } from "../constants/apis";
import { errorMessageFromResponse } from "../constants/commonFunctions";

export const getChatList = () => {
  console.log("GetCHatList CALLED")
  const token = store.getState()?.profile?.user?.token;

  const data = new FormData();

  data.append("channel_direct", "direct");

  return async (dispatch) => {
    await axios({
      method: "get",
      url: GET_CHAT_LIST_API,
      headers: {
        Authorization: `Token ${token}`,
      },
      data: data,
    })
      .then((response) => {
        if (
          response.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          const list = response?.data?.data;
          console.log("theRes", list);
          dispatch({ type: GET_CHAT_LIST, payload: list });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getChatMessages = (channelID) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "get",
      url: `${GET_CHAT_MESSAGES_API}?channel_id=${channelID}&count=50`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          console.log("theResMsg", response?.data?.data);
          dispatch({ type: GET_CHAT_MESSAGES, payload: response?.data?.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const sendChatMessage = (channelID, message) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "post",
      url: SEND_MESSAGE_API,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
      data: {
        message: message,
        channel_id: channelID,
      }
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          console.log("theSentMsg", response?.data?.data);
          // dispatch({ type: GET_CHAT_MESSAGES, payload: response?.data?.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};


export const getChatSearchResult = (searchQuery) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "get",
      url: `${GET_SEARCH_RESULT_API}?search=${searchQuery}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          response.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          // const list = response?.data?.data;
          console.log("theSearchRes", response?.data?.data);
          dispatch({ type: GET_SEARCH_RESULT, payload: response?.data?.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const createChannel = (userID) => {
  const token = store.getState()?.profile?.user?.token;

  return async (dispatch) => {
    await axios({
      method: "post",
      url: CREATE_CHANNEL_API,
      headers: {
        "content-type": "application/json",
        Authorization: `Token ${token}`,
      },
      data: {
        channel_type: "direct",
        recipients_id: [userID]
      }
    })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          console.log("createChannelRes", response?.data?.data);
          // dispatch({ type: GET_CHAT_MESSAGES, payload: response?.data?.data });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const updateChatList = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_CHAT_LIST, payload: data})
  }
}