import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../apps/Profile/Profile.module.css";
import { fetchPeopleYouMayKnow } from "../../redux/actions/peopleyoumayknow";
import { GET_PEOPLE_YOU_MAY_KNOW } from "../../redux/constants/actionTypes";
import {
  viewSentConnection,
  viewConnectionInvitations,
  acceptConnectionRequest,
  viewMyConnections,
  withdrawConnectionRequest,
  removeConnection,
  ignoreConnectionRequest,
} from "../../redux/actions/connections";
import {
  SENT_CONNECTIONS,
  CONNECTION_INVITATION,
  ACCPET_CONNECTION,
  MY_CONNECTIONS,
  WITHDRAW_CONNECTION,
  REMOVE_CONNECTION,
  IGNORE_CONNECTION,
} from "../../redux/constants/actionTypes";

import HandleApiError from "./HandleApiError";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Connections extends React.Component {
  state = {
    error: false,
    message: "",
  };

  acceptConnections(connectionId) {
    let data = this.props.viewConnectionInvitation.filter(
      (connection) => connection.id != connectionId
    );
    let acceptedConnections = this.props.viewConnectionInvitation.filter(
      (connection) => connection.id == connectionId
    );
    this.props.myConnections.push(acceptedConnections[0].from_user);
    // console.log(data, "data after accept connections")
    // console.log(acceptedConnections[0].from_user, "updated data after accept connections")
    this.props
      .acceptConnectionRequest(ACCPET_CONNECTION, connectionId, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
        // this.myConnections()
        // this.connectionInvitation()
        // this.sentConnections()
      });
  }
  withdrawConnections(connectionId) {
    let data = this.props.viewSentConnections.filter(
      (connection) => connection.id != connectionId
    );
    // console.log(data, "data after withdraw connections")
    this.props
      .withdrawConnectionRequest(WITHDRAW_CONNECTION, connectionId, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
      });
  }
  removeConnections(profileID) {
    let data = this.props.myConnections.filter(
      (connection) => connection.id != profileID
    );
    this.props
      .removeConnection(REMOVE_CONNECTION, profileID, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
        // this.myConnections()
        // this.connectionInvitation()
        // this.sentConnections()
      });
  }
  ignoreConnections(connectionId) {
    let data = this.props.viewConnectionInvitation.filter(
      (connection) => connection.id != connectionId
    );
    // let ignoredConnections = this.props.viewConnectionInvitation.filter(connection => connection.id == connectionId)
    // console.log(ignoredConnections,"--------------")
    // this.props.myConnections.remove(ignoredConnections[0].from_user)

    this.props
      .ignoreConnectionRequest(IGNORE_CONNECTION, connectionId, data)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
      });
    // this.myConnections()
    // this.connectionInvitation()
    // this.sentConnections()
  }
  myConnections() {
    this.props.viewMyConnections(MY_CONNECTIONS).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }
  connectionInvitation() {
    this.props.viewConnectionInvitations(CONNECTION_INVITATION).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }
  sentConnections() {
    this.props.viewSentConnection(SENT_CONNECTIONS).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }

  componentDidMount() {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    this.props
      .fetchPeopleYouMayKnow(GET_PEOPLE_YOU_MAY_KNOW, token)
      .then((res) => {
        if (res.val == false) {
          this.setState({ error: true, message: res.message });
        }
      });
    this.myConnections();
    this.connectionInvitation();
    this.sentConnections();
  }

  render() {
    const { t } = this.props;
    return (
      <div className={styles.root_search}>
        <div className={styles.mains_search}>
          {this.props.myConnections.length != 0 ||
          this.props.viewConnectionInvitation.length != 0 ? (
            <div className={styles.peopleSection}>
              <div className={styles.profile_title}>
                <div
                  style={{
                    textDecoration: "none",
                    color: "#000",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 20px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className={styles.icon_container}>
                        <img
                          className={styles.inner_icon}
                          src="/Image/apps/profile-resume-edit-view/about.svg"
                          alt=" "
                        />
                      </div>
                      <div>
                        <p className={styles.icon_text}>
                          {t("connections.myConnections")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <br />
                </div>
              </div>
              <div className={styles.invitation_content}>
                <div>
                  {this.props.viewConnectionInvitation.map((item, idx) => {
                    return (
                      <div key={idx} className={styles.inner_invitation}>
                        <div className={styles.invitationSection}>
                          <div
                            className={`${styles.center_div} ${styles.inner_invitation_img}`}
                          >
                            {item.from_user.profile_pic != null ? (
                              <img src={item.from_user.profile_pic} height="60px" width="60px" style={{ borderRadius: 40 }} />
                            ) : (
                              <img
                                src={
                                  "/Image/apps/profile-resume-edit-view/default-user.png"
                                }
                                height="60px"
                                width="60px"
                                style={{ borderRadius: 40 }}
                              />
                            )}
                          </div>
                          <div className={styles.flex_col_cen}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p className={styles.section_title}>
                                  {item.from_user.name}
                                  <br />
                                  <span className={styles.followers}>
                                    {item.from_user.designation}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <p
                              className={styles.followers}
                              style={{
                                opacity: 0.7,
                                color: "#555555",
                                marginBottom: 6,
                              }}
                            >
                              {item.from_user.organisation}
                            </p>
                          </div>
                        </div>
                        <div className={styles.flex_sp}>
                          <div>
                            <button
                              className={`${styles.btn_fade} ${styles.connect_btn_fade}`}
                              onClick={() => this.ignoreConnections(item.id)}
                            >
                              <p
                                className={styles.btn_text_fade}
                              >
                                {t("connections.ignoreRequest")}
                              </p>
                            </button>
                          </div>
                          <div>
                            <button
                              className={`${styles.btn} ${styles.connect_btn}`}
                              onClick={() => this.acceptConnections(item.id)}
                            >
                              <p
                                className={styles.btn_text}
                              >
                                {t("connections.acceptRequest")}
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {this.props.myConnections.map((item, idx) => {
                    return (
                      <div key={idx} className={styles.inner_invitation}>
                        <div className={styles.invitationSection}>
                          <div
                            className={`${styles.center_div} ${styles.inner_invitation_img}`}
                          >
                            {item.profile_pic != null ? (
                              <img src={item.profile_pic} height="60px" width="60px" style={{ borderRadius: 40 }} />
                            ) : (
                              <img
                                src={
                                  "/Image/apps/profile-resume-edit-view/default-user.png"
                                }
                                height="60px"
                                width="60px"
                                style={{ borderRadius: 40 }}
                              />
                            )}
                          </div>
                          <div className={styles.flex_col_cen}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p className={styles.section_title}>
                                  {item.name}
                                  <br />
                                  <span className={styles.followers}>
                                    {item.designation}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <p
                              className={styles.followers}
                              style={{
                                opacity: 0.7,
                                color: "#555555",
                                marginBottom: 6,
                              }}
                            >
                              {item.organisation}
                            </p>
                          </div>
                        </div>
                        <div className={styles.flex_sp}>
                          <div>
                            <button
                              className={`${styles.btn_fade} ${styles.connect_btn_fade}`}
                              onClick={() => this.removeConnections(item.id)}
                            >
                              <p
                                // onClick={() => this.props.history.push(`/profile/${item.id}`)}
                                className={styles.btn_text_fade}
                              >
                                {t("connections.removeConnection")}
                              </p>
                            </button>
                          </div>
                          <div>
                            <button
                              className={`${styles.btn} ${styles.connect_btn}`}
                              onClick={() =>
                                this.props.history.push(`/profile/${item.id}`)
                              }
                            >
                              <p
                                className={styles.btn_text}
                              >
                                {t("header.viewProfile")}
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.peopleSection}>
              <div className={styles.profile_title}>
                <div
                  style={{
                    textDecoration: "none",
                    color: "#000",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 20px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className={styles.icon_container}>
                        <img
                          className={styles.inner_icon}
                          src="/Image/apps/profile-resume-edit-view/about.svg"
                          alt=" "
                        />
                      </div>
                      <div>
                        <p className={styles.icon_text}>
                          {t("profile.peopleYouMayKnow")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <br />
                </div>
              </div>
              <div className={styles.invitation_content}>
                <div>
                  {this.props.peopleYouMayKnow.map((item, idx) => {
                    return (
                      <div key={idx} className={styles.inner_invitation}>
                        <div style={{ display: "flex" }}>
                          <div
                            className={`${styles.center_div} ${styles.inner_invitation_img}`}
                          >
                            <img
                              src={item.profile_pic}
                              height="60px"
                              width="60px"
                              style={{ borderRadius: 40 }}
                            />
                          </div>
                          <div className={styles.flex_col_cen}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p className={styles.section_title}>
                                  {item.name}
                                  <br />
                                  <span className={styles.followers}>
                                    {item.designation}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <p
                              className={styles.followers}
                              style={{
                                opacity: 0.7,
                                color: "#555555",
                                marginBottom: 6,
                              }}
                            >
                              {item.organisation}
                            </p>
                          </div>
                        </div>
                        <div className={styles.flex_sp}>
                          <div>
                            <button
                              className={`${styles.btn} ${styles.connect_btn}`}
                              onClick={() =>
                                this.props.history.push(`/profile/${item.id}`)
                              }
                            >
                              <p
                                className={styles.btn_text}
                              >
                                {t("header.viewProfile")}
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <HandleApiError
                error={this.state.error}
                message={this.state.message}
                onClose={() => this.setState({ error: false })}
              />
            </div>
          )}
          <br></br>
          <div
            className={styles.peopleSection}
            style={{ paddingBottom: "80px" }}
          >
            <div className={styles.profile_title}>
              <div
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.icon_container}>
                      <img
                        className={styles.inner_icon}
                        src="/Image/apps/profile-resume-edit-view/about.svg"
                        alt=" "
                      />
                    </div>
                    <div>
                      <p className={styles.icon_text}>
                        {t("connections.sentConnections")}
                      </p>
                    </div>
                  </div>
                </div>

                <br />
              </div>
            </div>
            <div className={styles.invitation_content}>
              <div>
                {this.props.viewSentConnections.map((item, idx) => {
                  return (
                    <div key={idx} className={styles.inner_invitation}>
                      <div className={styles.invitationSection}>
                        <div
                          className={`${styles.center_div} ${styles.inner_invitation_img}`}
                        >
                          {item.to_user.profile_pic != null ? (
                            <img src={item.to_user.profile_pic} height="60px" width="60px" style={{ borderRadius: 40 }} />
                          ) : (
                            <img
                              src={
                                "/Image/apps/profile-resume-edit-view/default-user.png"
                              }
                              height="60px"
                              width="60px"
                              style={{ borderRadius: 40 }}
                            />
                          )}
                        </div>
                        <div className={styles.flex_col_cen}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <p className={styles.section_title}>
                                {item.to_user.name}
                                <br />
                                <span className={styles.followers}>
                                  {item.to_user.designation}
                                </span>
                              </p>
                            </div>
                          </div>
                          <p
                            className={styles.followers}
                            style={{
                              opacity: 0.7,
                              color: "#555555",
                              marginBottom: 6,
                            }}
                          >
                            {item.to_user.organisation}
                          </p>
                        </div>
                      </div>
                      <div className={styles.flex_sp}>
                        <div>
                          <button
                            className={`${styles.btn_fade} ${styles.connect_btn_fade}`}
                            onClick={() => this.withdrawConnections(item.id)}
                          >
                            <p
                              className={styles.btn_text_fade}
                            >
                              {t("connections.withdrawRequest")}
                            </p>
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    peopleYouMayKnow: state.profile?.peopleYouMayKnow || [],
    viewSentConnections: state.profile?.viewSentConnections || [],
    viewConnectionInvitation: state.profile?.viewConnectionInvitation || [],
    acceptConnection: state.profile?.acceptConnection || [],
    myConnections: state.profile?.myConnections || [],
    withdrawConnection: state.profile?.withdrawConnection || [],
    removeConnection: state.profile?.removeConnection || [],
    ignoreConnectionRequest: state.profile?.ignoreConnectionRequest || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPeopleYouMayKnow: (action, token) =>
      dispatch(fetchPeopleYouMayKnow(action, token)),
    viewSentConnection: (action) => dispatch(viewSentConnection(action)),
    viewConnectionInvitations: (action) =>
      dispatch(viewConnectionInvitations(action)),
    acceptConnectionRequest: (action, id, data) =>
      dispatch(acceptConnectionRequest(action, id, data)),
    viewMyConnections: (action) => dispatch(viewMyConnections(action)),
    withdrawConnectionRequest: (action, id, data) =>
      dispatch(withdrawConnectionRequest(action, id, data)),
    removeConnection: (action, id, data) =>
      dispatch(removeConnection(action, id, data)),
    ignoreConnectionRequest: (action, id, data) =>
      dispatch(ignoreConnectionRequest(action, id, data)),
  };
}

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Connections))
);
