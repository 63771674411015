import React from 'react';
import './PictureCard.css';


export default function PictureCard(props){

    // var a = "../../../../../animation/Hero/bg3.png"

    // var bgStyle = {
    //     backgroundImage: `url(${a})`,
    //     backgroundPosition: `${props.bgPos}`
    // }

    return(
        <>
            <div className="PictureCard">
                <div className='PictureCard-bg'>
                    
                </div>
                <div style={props.position ? {flexDirection:"Row-Reverse"} : null } className="PictureCard-wrapper">
                    <div className="PictureCard-image">
                        <img src={props.src} alt={props.alt}/>
                    </div>

                    <div className="PictureCard-text">
                        <h4>{props.heading}</h4>
                        <p>{props.desc}</p>
                    </div>
                </div>
            </div>
        </>
    )

}