import {
  CREATE_QUIZ_TEMPLATE,
  CREATE_QUIZ,
  GET_ALL_QUIZ_TEMPLATES,
  GET_ALL_QUIZZES,
  GET_INDIVIDUAL_QUIZ_TEMPLATE,
  GET_INDIVIDUAL_QUIZ,
  DELETE_QUIZ_TEMPLATE,
  DELETE_QUIZ,
  ATTEMPT_QUIZ,
  GET_ALL_QUIZ_SUBMISSIONS,
  UPDATE_QUIZ_MARKS
} from "../constants/actionTypes";
import {
  CREATE_QUIZ_TEMPLATE_MUTATION,
  CREATE_QUIZ_MUTATION,
  UPDATE_QUIZ_TEMPLATE_MUTATION,
  DELETE_QUIZ_MUTATION,
  DELETE_QUIZ_TEMPLATE_MUTATION,
  UPDATE_QUIZ_MUTATION,
  SUBMIT_QUIZ_MUTATION,
  UPDATE_QUIZ_MARKS_MUTATION,
  RETURN_QUIZ_SUBMISSION_MUTATION,
  UNASSIGN_QUIZ_MUTATION,
  ASSIGN_QUIZ_MUTATION
} from "../../graphql/mutations/enhancedQuiz.js";
import {
  GET_ALL_QUIZ_TEMPLATES_QUERY,
  GET_INDIVIDUAL_QUIZ_TEMPLATE_QUERY,
  GET_INDIVIDUAL_QUIZ_QUERY,
  GET_ALL_CLASS_QUIZZES_QUERY,
  GET_ALL_GROUP_QUIZZES_QUERY,
  GET_ALL_QUIZ_SUBMISSIONS_QUERY
} from "../../graphql/queries/enhancedQuiz.js";
// import { GET_ALL_COURSES_QUERY,GET_COURSE_QUERY} from "../../graphql/queries/courseMonetisation.js";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import { request, gql, GraphQLClient } from "graphql-request";
import { GRADE_ZERO_QUIZ_API } from "../constants/apis";
import axios from "axios";

// ------- utility functions ----------------------------------
const deriveTemplateData = (res) => {
  let currentList = [...res.questions];
  const newList = currentList.map((item) => {
    let obj = {};

    switch (item.question_type) {
      case "SingleChoice":
        obj.type = "mcq";
        break;
      case "Mcq":
        obj.type = "checkboxes";
        break;
      case "True/False":
        obj.type = "trueFalse";
        break;
      case "Grid":
        obj.type = "grid";
        break;
      case "Fib":
        obj.type = "fillInBlanks";
        break;
      case "ImageChoice":
        obj.type = "imageChoice";
        break;
      case "ShortAnswer":
        obj.type = "shortAnswer";
        break;
      case "Paragraph":
        obj.type = "paragraph";
        break;
    }

    obj.isMandatory = item.is_mandatory;
    obj.maxMarks = item.marks;
    obj.questionText = item.text;
    obj.id = item.id;

    if (
      item.question_type === "SingleChoice" ||
      item.question_type === "Mcq" ||
      item.question_type === "True/False"
    ) {
      obj.choices = item.choice.map((content) => {
        let x = {};
        x.id = content.id;
        x.choiceText = content.choice_text;
        x.isCorrect = content.is_correct;
        return x;
      });
    } else if (item.question_type === "Fib" || item.question_type === "Grid") {
      obj.choices = item.choice.map((content) => {
        let x = {};
        x.id = content.id;
        x.choiceText = content.choice_text;
        x.correctChoice = content.correct_choice;
        return x;
      });
    } else if (item.question_type === "ImageChoice") {
      // Todo
      obj.choices = item.choice.map((content) => {
        let x = {};
        x.id = content.id;
        x.choiceImage = content.img;
        x.choiceText = content.choice_text; // getting the url in this key from backend
        x.isCorrect = content.is_correct;
        return x;
      });
    }
    return obj;
  });

  let data = {};

  data.id = res.id;
  data.quizTemplateTitle = res.title;
  data.quizTemplateInstructions = res.details;
  data.questionsList = newList;
  data.questionCount = res.ques_count;
  data.totalMarks = res.marks;
  data.timestamp = res.timestamp;
  return data;
};

// ----------- createQuizTemplate -----------

export const createQuizTemplate = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = CREATE_QUIZ_TEMPLATE_MUTATION;

  let variables = {
    input: data,
  };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        // dispatch({
        // type: CREATE_QUIZ_TEMPLATE,
        //   payload: response?.create_template,
        // });
        dispatch(
          handleSuccessMessage(`${i18n.t("enhancedQuiz.saveTemplate")}`)
        );
      })
      .catch(async (error) => {
        // console.log(error)
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

// --------- createQuiz ----------------

export const createQuiz = (data) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = CREATE_QUIZ_MUTATION;

  let variables = {
    input: data,
  };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        console.log("quizCreatedResponse", response);
        // dispatch({
        // type: CREATE_QUIZ_TEMPLATE,
        //   payload: response?.create_quiz,
        // });
        dispatch(
          handleSuccessMessage(
            `${i18n.t("quizSuccessMessages.quizCreatedSuccessfully")}`
          )
        );
      })
      .catch(async (error) => {
        // console.log(error)
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

// ---------- updateQuiz ----------------

export const updateQuiz = (id, data) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = UPDATE_QUIZ_MUTATION;

  let variables = {
    id: id,
    input: data,
  };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        console.log("quizCreatedResponse", response);
        // dispatch({
        // type: UPDATE_QUIZ_TEMPLATE,
        //   payload: response?.update_quiz,
        // });
        dispatch(
          handleSuccessMessage(
            `${i18n.t("quizSuccessMessages.quizUpdatedSuccessfully")}`
          )
        );
      })
      .catch(async (error) => {
        // console.log(error)
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
}; 

// --------- getAllQuizTemplates ----------

export const getAllQuizTemplates = () => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = GET_ALL_QUIZ_TEMPLATES_QUERY;

  return async (dispatch) => {
    await client
      .request(query)
      .then((response) => {
        dispatch({
          type: GET_ALL_QUIZ_TEMPLATES,
          payload: response?.templates, // Payload
        });
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

// --------- getAllQuizzes ---------------

export const getAllQuizzes = (id, routeType) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const userId = store.getState()?.profile?.user?.profile_id;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = routeType == 'class' ? GET_ALL_CLASS_QUIZZES_QUERY : GET_ALL_GROUP_QUIZZES_QUERY ;
  
  let variables = { id, userId };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        dispatch({
          type: GET_ALL_QUIZZES,
          payload: response?.quizzes, // Payload
        });
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

// --------- getIndividualQuizTemplate ---------------
/**
 * In the case of editing a template, we are manipulating the incoming response before sending it to the reducer,
 * and in the case of previewing a template, we send the template data as it is.
 */

export const getIndividualQuizTemplate = (id, isPreview = false) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = GET_INDIVIDUAL_QUIZ_TEMPLATE_QUERY;
  let variables = { id };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        let data = isPreview ? response?.template : deriveTemplateData(response?.template);
        dispatch({
          type: GET_INDIVIDUAL_QUIZ_TEMPLATE,
          payload: data, 
        });
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

// --------------- updateQuizTemplate ------------------

export const updateQuizTemplate = (id, data) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = UPDATE_QUIZ_TEMPLATE_MUTATION;

  let variables = {
    id: id,
    input: data,
  };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        // dispatch({
        // type: CREATE_QUIZ_TEMPLATE,
        //   payload: response?.create_template,
        // });
        dispatch(
          handleSuccessMessage(`${i18n.t("enhancedQuiz.updateTemplate")}`)
        );
      })
      .catch(async (error) => {
        // console.log(error)
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

// ----------------getIndividualQuiz-----------------

export const getIndividualQuiz = (id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = GET_INDIVIDUAL_QUIZ_QUERY;
  let variables = { id };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        dispatch({
          type: GET_INDIVIDUAL_QUIZ,
          payload: response?.quiz, // Payload
        });
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

//------------------ deleteQuizTemplate ----------------

export const deleteQuizTemplate = (id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = DELETE_QUIZ_TEMPLATE_MUTATION;

  let variables = {
    input: id,
  };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        dispatch({
          type: DELETE_QUIZ_TEMPLATE,
          payload: id,
        });
        dispatch(
          handleSuccessMessage(i18n.t("enhancedQuiz.deleteTemplate"))
        );
        
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

//------------------ deleteQuiz ----------------

export const deleteQuiz = (id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = DELETE_QUIZ_MUTATION;

  let variables = {
    input: id,
  };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        dispatch({
          type: DELETE_QUIZ,
          payload: id,
        });
        dispatch(
          handleSuccessMessage(i18n.t("quizSuccessMessages.quizDeletedSuccessfully"))
        );
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

// ---------- submitQuiz ----------------

export const submitQuiz = (id, userAnswers) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = SUBMIT_QUIZ_MUTATION;
  
  let variables = null;

  // let variables = {
  //   id: id,
  //   input: {
  //     user_answers: userAnswers
  //   },
  // };

  if (userAnswers?.length > 0) {
    variables = {
      id: id,
      input: {
        user_answers: userAnswers
      },
    };
  } else {
    variables = {
      id: id,
      input: {},
    };
  }

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        console.log("quizCreatedResponse", response);
        dispatch({
        type: ATTEMPT_QUIZ,
          payload: response?.update_quiz_submission,
        });
        dispatch(
          handleSuccessMessage(
            `${i18n.t("quizSuccessMessages.quizSubmittedSuccessfully")}`
          )
        );
      })
      .catch(async (error) => {
        // console.log(error)
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
}; 


// ----------------getAllQuizSubmissions-----------------

export const getAllQuizSubmissions = ( id ) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = GET_ALL_QUIZ_SUBMISSIONS_QUERY;
  let variables = { id };

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        console.log('SubResponse', response)
        dispatch({
          type: GET_ALL_QUIZ_SUBMISSIONS,
          payload: response?.quiz_submissions, 
        });
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

// ---------- updateQuizMarks ----------------

export const updateQuizMarks = (id, marks) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = UPDATE_QUIZ_MARKS_MUTATION;
  
  // let variables = null;

  let variables = {
    id: id,
    input: {
      marks: marks,
    },
  };

  // if (userAnswers?.length > 0) {
  //   variables = {
  //     id: id,
  //     input: {
  //       user_answers: userAnswers
  //     },
  //   };
  // } else {
  //   variables = {
  //     id: id,
  //     input: {},
  //   };
  // }

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        console.log("updateMarksResponse", response);
        dispatch({
        type: UPDATE_QUIZ_MARKS,
          payload: response?.update_quiz_submission,
        });
        dispatch(
          handleSuccessMessage(
            `${i18n.t("enhancedQuiz.marksUpdatedSuccessfully")}`
          )
        );
      })
      .catch(async (error) => {
        // console.log(error)
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
}; 

// ---------------- returnQuizSubmission -----------------

export const returnQuizSubmission = (id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = RETURN_QUIZ_SUBMISSION_MUTATION;
  
  let variables = {
    "input": [id] 
  }

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        // dispatch(
        //   handleSuccessMessage(
        //     `${i18n.t("enhancedQuiz.marksUpdatedSuccessfully")}`
        //   )
        // );
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
}; 


// ---------------- unassignStudentFromQuiz -----------------

export const unassignStudentFromQuiz = (id) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = UNASSIGN_QUIZ_MUTATION;
  
  let variables = {
    "input": [...id] 
  }

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        // dispatch(
        //   handleSuccessMessage(
        //     `${i18n.t("enhancedQuiz.marksUpdatedSuccessfully")}`
        //   )
        // );
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
}; 

// ---------------- assignStudentToQuiz -----------------

export const assignStudentToQuiz = (quizId, studentId) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;
  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  const query = ASSIGN_QUIZ_MUTATION;
  
  let variables = {
    "input": {
      quiz: quizId,
      students: [...studentId]
    } 
  }

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        dispatch(
          handleSuccessMessage(
            `${i18n.t("enhancedQuiz.studentAssignedSuccessfully")}`
          )
        );
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
}; 

// ---------------- gradeStudentZeroForQuiz ---------------------

export const gradeStudentZeroForQuiz = (studentList) => {
  const token = store.getState()?.profile?.user?.token;

  let data = {
    "submission_list": studentList
  }

  return async (dispatch) => {
    const url = `${GRADE_ZERO_QUIZ_API}`;

    await axios({
      method: "post",
      url: url,
      data: data,
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((response) => {
        if (
          // response.data.status == "1" &&
          response.status >= 200 &&
          response.status < 300
        ) {
          dispatch(
            handleSuccessMessage(
              i18n.t("assignmentSuccessMessages.studentsGradedSuccessfully")
            )
          );
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ----------------getIndividualQuiz-----------------


export const getIndividualQuizSubmission = ( ) => {}