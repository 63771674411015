import React, { Component } from "react";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import MiddleContainer from "./MiddleContainer";
import styles from "./css/modal.module.css";
import BottomContainer from "./BottomContainer";
import { FeedsContext } from "../Components/FeedsContext";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { validateFileSize } from "../../../redux/constants/commonFunctions";
import { withTranslation } from "react-i18next";

// import PostSampleImg2 from './Image/DashboardImages/DashCommonImages/postSampleImg2.png';
class PostModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.description
          : "",
      photoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.image_url
          : null,
      videoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.video_url
          : null,
      mediaFileUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.file_url
          : null,
      photo: null,
      video: null,
      mediaFile: null,
      dropDown: "Anyone",
      open: false,
      onImageEdit: false,
      onVideoEdit: false,
      onMediaFileEdit: false,
    };
  }

  getState = () => {
    this.setState({
      message:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.description
          : "",
      photoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.image_url
          : null,
      videoUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.video_url
          : null,
      mediaFileUrl:
        this.props?.actionType == "EDIT"
          ? this.props?.postData?.file_url
          : null,
      photo: null,
      video: null,
      mediaFile: null,
    });
  };
  componentDidMount() {
    this.getState();
  }

  /*   componentDidUpdate() {
    if(  ) {
      this.getState()
    }
    //console.log("CDU", this.state)
  } */

  handleClose = () => {
    this.setState({ open: false });
  };

  onPost = () => {
    let post = {};
    post.message = this.state.message;
    post.photoUrl = this.state.photo;
    post.videoUrl = this.state.video;
    post.mediaFileUrl = this.state.mediaFile;
    post.type = "DASHBOARD";
    post.visibility = this.state.dropDown;
    if (
      this.state.message.trim() != "" ||
      this.state.photo != null ||
      this.state.video != null ||
      this.state.mediaFile != null
    ) {
      this.context.addPost(post);
    } else {
      this.setState({
        open: true,
        alertMessage: "Please Add Something before posting",
      });
    }
    this.setState({
      message: "",
      photoUrl: null,
      videoUrl: null,
      photo: null,
      video: null,
      mediaFileUrl: null,
      mediaFile: null,
      dropDown: "Anyone",
    });
    this.props.closeModal();
  };

  postDelete = () => {
    let id = this.props.postData.id;
    this.context.deletePost(id);
    this.props.closeModal();
  };

  postEdit = () => {
    if (
      this.state.photo === null ||
      this.state.video === null ||
      this.state.mediaFile === null
    ) {
      this.setState({
        photo: this.props?.postData?.image_url,
        video: this.props?.postData?.video_url,
        mediaFile: this.props?.postData?.file_url,
      });
    }
    if (this.state.message === "") {
      this.setState({ message: this.props?.postData?.description });
    }

    let post = {};
    post.message = this.state.message;
    post.photoUrl = this.state.photo;
    post.videoUrl = this.state.video;
    post.mediaFileUrl = this.state.mediaFile;
    post.id = this.props?.postData?.id;
    post.onImageEdit = this.state.onImageEdit;
    post.onVideoEdit = this.state.onVideoEdit;
    post.onMediaFileEdit = this.state.onMediaFileEdit;
    post.type = "DASHBOARD";
    post.visibility = this.state.dropDown;
    if (
      this.props?.postData?.description.trim() !== this.state.message.trim() ||
      this.state.photo !== this.props?.postData?.image_url ||
      this.state.video !== this.props?.postData?.video_url ||
      this.state.mediaFile !== this.props?.postData?.file_url
    ) {
      if (
        this.state.message !== "" ||
        this.state.photo !== null ||
        this.state.video !== null ||
        this.state.mediaFile !== null
      ) {
        this.context.editPost(post);
      }
    } else {
    }
    // console.log("changeddd", this.props?.postData?.description, this.state.message)
    this.setState({
      onImageEdit: false,
      onVideoEdit: false,
      onMediaFileEdit: false,
    });

    this.props.closeModal();
  };

  onChange = (e) => {
    this.setState({ message: e.target.value });
  };

  onDropodownChange = (item) => {
    this.setState({ dropDown: item });
  };
  onPicInput = (e) => {
    console.log("PHOTOURL", this.state.photoUrl);
    console.log("Event.target", e.target.files);
    const errorMessage = validateFileSize(e.target.files[0], 30720);
    this.setState(
      errorMessage
        ? { open: true, alertMessage: errorMessage }
        : {
            photoUrl: URL.createObjectURL(e.target.files[0]),
            photo: e.target.files[0],
            videoUrl: null,
            video: null,
            // mediaFileUrl: null,
            // mediaFile: null,
            onImageEdit: true,
          }
    );
  };

  videoInput = (e) => {
    const errorMessage = validateFileSize(e.target.files[0], 30720);
    this.setState(
      errorMessage
        ? { open: true, alertMessage: errorMessage }
        : {
            videoUrl: URL.createObjectURL(e.target.files[0]),
            video: e.target.files[0],
            photoUrl: null,
            photo: null,
            mediaFileUrl: null,
            mediaFile: null,
            onVideoEdit: true,
          }
    );
  };
  mediaFileInput = (e) => {
    const errorMessage = validateFileSize(e.target.files[0], 30720);
    this.setState(
      errorMessage
        ? { open: true, alertMessage: errorMessage }
        : {
            mediaFileUrl: URL.createObjectURL(e.target.files[0]),
            mediaFile: e.target.files[0],
            // photoUrl: null,
            // photo: null,
            videoUrl: null,
            video: null,
            onMediaFileEdit: true,
          }
    );
  };

  render() {
    return (
      <>
        <Snackbar
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.handleClose}
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
          >
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
        <div>
          <ModalWithHeader
            title={
              this.props.actionType == "EDIT"
                ? this.props.t("dashboard.editPost")
                : this.props.actionType == "DELETE"
                ? this.props.t("dashboard.deletePost")
                : this.props.t("dashboard.createPost")
            }
            show={this.props.showModal}
            closeHandler={this.props.closeModal}
          >
            <div className={styles.modalBody}>
              <MiddleContainer
                actionType={this.props.actionType}
                postData={this.props.postData}
                pic={this.state.photoUrl}
                video={this.state.videoUrl}
                file={this.state.mediaFileUrl}
                state={this.state}
                onChange={this.onChange}
                onDropdownChange={this.onDropodownChange}

              />
              <BottomContainer
                actionType={this.props.actionType}
                videoInput={this.videoInput}
                fileInput={this.onPicInput}
                onPost={this.onPost}
                postEdit={this.postEdit}
                postDelete={this.postDelete}
                mediaFileInput={this.mediaFileInput}
              />
            </div>
            {/*  <img height="100px" src={this.state.image} /> */}
          </ModalWithHeader>
        </div>
      </>
    );
  }
}

PostModal.contextType = FeedsContext;
export default withTranslation()(PostModal);
