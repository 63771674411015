import React from "react";
import { withRouter } from "react-router";

import styles from "../../apps/Dashboard/Components/CSSFiles/RightSidebar.module.css";
import { thumbnailList } from "../../redux/constants/commonFunctions";
import { Link } from "react-router-dom";
import {withTranslation} from "react-i18next";

class DashboardRightFeedInnerComp extends React.Component {
  state = {};

  render() {
    return (
      <div className={styles.Rightsidebar_stats}>
        {this.props?.content?.length > 0 && this.props?.content?.map((item, idx) => {
          return (
            <>
              <div id={idx} className={styles.Rightsidebar_stat} onClick={()=>{
                return this.props.isCourse ? 
                this.props.history.push(`/course/view/${item?.id}`) : null;
              }}>
                <img src={item?.thumbnail ?? thumbnailList[idx%13]} style={{ width: 48, height: 48, borderRadius: 50 }} />
                <p className={this.props?.isCourse ? styles.Rightsidebar_statNumber2 : styles.Rightsidebar_statNumber }>
                  {this.props?.isCourse ?
                    <span>{item?.name}</span> :
                    <a href={item.href} target="_blank" style={{ textDecoration: "none", color: "inherit" }}>
                    {item.content} </a>
                   }                  
                </p>
              </div>
              {idx == this.props.content.length - 1 ? null : <hr className={styles.Hr1} />}
            </>
          );
        })}

        {this.props?.isEnterprise && <p className={styles.view_enterprise_data_label}>{this.props.t("enterpriseDashboard.viewDetailedData")}</p>}

        {/* Add the viewAllCourses button below this comment */}
        { this.props?.isCourse && <><hr className={styles.Hr1} /> <span><Link to="/courses" className={styles.sidebar_stat2}>{this.props.t("common.viewallcourses")}</Link></span></> }
        { this.props?.isEnterprise && <><hr className={styles.Hr1} /> <span><Link to="/dashboard" className={styles.sidebar_stat2}>{this.props.t("common.view")}</Link></span></> }
        
      </div>
    );
  }
}

export default withTranslation()(withRouter(DashboardRightFeedInnerComp));
