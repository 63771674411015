import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./EnterpriseDataTable.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import Pagination from "@mui/material/Pagination";
import moment from "moment";

const EnterpriseDataTable = (props) => {
  const { t } = useTranslation();

  let type = props.type;

  const [page, setPage] = useState(1);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const styleTableHeader = {
    color: "rgb(81, 81, 81)",
    fontFamily: "Montserrat",
    fontSize: {
      xs: "0.88rem",
      sm: "0.88rem",
      md:"1rem",
    },
    fontWeight: 400,
    // lineHeight: "1.25rem",
    letterSpacing: "0%",
    textAlign: "center",
    
  };

  const styleTableCell = {
    color: "rgb(81, 81, 81)",
    fontFamily: "Montserrat",
    fontSize: {
      xs: "0.75rem",
      sm: "0.75rem",
      md:"0.88rem",
    },
    fontWeight: 500,
    lineHeight: "1.5rem",
    letterSpacing: "0%",
    padding: "0.35rem 0.8rem",
    textAlign: 'center'
  };

  let tableHeaderArrary =
    type === "dashboard_faculty" || type === "dashboard_student"
      ? [
          "enterpriseDashboard.name",
          "enterpriseDashboard.joiningDate",
          `${type.includes("faculty") ? "enterpriseDashboard.facultyId" : "enterpriseDashboard.studentId"}`,
          "enterpriseDashboard.phoneNumber",
          "enterpriseDashboard.action",
        ]
      : type.includes("assignment") || type.includes("quiz")
      ? [
          "enterpriseDashboard.title",
          "enterpriseDashboard.createdBy",
          "enterpriseDashboard.maxMarks",
          "enterpriseDashboard.assigned",
          "enterpriseDashboard.submissions",
          "enterpriseDashboard.dueDate",
          "enterpriseDashboard.action",
        ]
      : type.includes("course")
      ? [
          "enterpriseDashboard.title",
          "enterpriseDashboard.createdBy",
          "enterpriseDashboard.access",
          "enterpriseDashboard.price",
          "enterpriseDashboard.enrolled",
          "enterpriseDashboard.createdDate",
          "enterpriseDashboard.action",
        ]
      : type.includes("post")
      ? [
          "enterpriseDashboard.postBy",
          "enterpriseDashboard.date",
          "enterpriseDashboard.comments",
          "enterpriseDashboard.likes",
          "enterpriseDashboard.visibility",
          "enterpriseDashboard.action",
        ]
      : type === "dashboard_class" ? [
        "enterpriseDashboard.title",
        "enterpriseDashboard.faculty",
        "enterpriseDashboard.classCode",
        "enterpriseDashboard.totalMembers",
        "enterpriseDashboard.status",
        "enterpriseDashboard.action",
      ] : type === "dashboard_group" ? [
        "enterpriseDashboard.title",
        "enterpriseDashboard.createdBy",
        "enterpriseDashboard.groupCode",
        "enterpriseDashboard.totalMembers",
        "enterpriseDashboard.status",
        "enterpriseDashboard.action",
      ] : type === "dashboard_class_student" ? [
        "enterpriseDashboard.name",
        "enterpriseDashboard.joiningDate",
        "enterpriseDashboard.studentId",
        "enterpriseDashboard.phoneNumber",
        "enterpriseDashboard.action",
      ] : type === "dashboard_group_member" ? [
        "enterpriseDashboard.name",
        "enterpriseDashboard.joiningDate",
        "enterpriseDashboard.memberId",
        "enterpriseDashboard.phoneNumber",
        "enterpriseDashboard.action",
      ] : [];

  let allLabel = type === "dashboard_faculty"
  ? "enterpriseDashboard.faculties"
  : type === "dashboard_student"
  ? "enterpriseDashboard.students"
  : type === "dashboard_post"
  ? "enterpriseDashboard.posts"
  : type === "dashboard_class"
  ? "enterpriseDashboard.allClasses"
  : type === "dashboard_group"
  ? "enterpriseDashboard.allGroups"
  : type === "dashboard_class_post"
  ? "enterpriseDashboard.classPosts"
  : type === "dashboard_class_assignment"
  ? "enterpriseDashboard.classAssignments"
  : type === "dashboard_class_quiz"
  ? "enterpriseDashboard.classQuizzes"
  : type === "dashboard_class_course"
  ? "enterpriseDashboard.classCourses"
  : type === "dashboard_group_post"
  ? "enterpriseDashboard.groupPosts"
  : type === "dashboard_group_assignment"
  ? "enterpriseDashboard.groupAssignments"
  : type === "dashboard_group_quiz"
  ? "enterpriseDashboard.groupQuizzes"
  : type === "dashboard_class_student"
  ? "enterpriseDashboard.students"
  : type === "dashboard_group_member"
  ? "enterpriseDashboard.groupMembers"
  : []

  return (
    <div className={styles.main}>
      {console.log("insideDatatable", props.data)}
      <div className={styles.count_header}>
        <p className={styles.type}>{`${t(allLabel)} ${props.showingSearchResult ? t("enterpriseDashboard.searchResult") : ""}`}</p>
        <p className={styles.total_count}>
          <span className={styles.count}>{props.count || 0}</span>
          {/* {`Total ${allLabel}`} */}
          {/* {`Total Count`} */}
          <span className={styles.count_label}>{t("enterpriseDashboard.totalCount")}</span>
        </p>
      </div>

      <TableContainer sx={{ marginTop: "0.35rem", overflow: "scroll", maxHeight: "90%" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaderArrary?.map((item) => (
                <TableCell sx={styleTableHeader}>{t(`${item}`)}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          {(type === "dashboard_faculty" || type === "dashboard_student") && (
            <TableBody>
              {props.data?.length > 0 &&
                props.data
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            gap: "2rem",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            alt={`${row?.first_name} ${row?.last_name}`}
                            src={row?.profile_data?.profile_pic || "/static/images/avatar/1.jpg"}
                            sx={{ width: 36, height: 36 }}
                          />
                          {`${row?.first_name} ${row?.last_name}`}
                        </div>
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        {moment(row?.date_joined).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell sx={styleTableCell}>{row?.id}</TableCell>
                      <TableCell sx={styleTableCell}>{row?.phone}</TableCell>
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src="/Image/EnterpriseDashboard/Delete.svg"
                            alt="icon"
                            className={styles.icon}
                            onClick={()=>props.onDelete(row?.id)}
                          />
                          <img
                            src="/Image/EnterpriseDashboard/View Profile.svg"
                            alt="icon"
                            className={styles.icon}
                            onClick={()=>props.onView(row?.id, `${row?.first_name} ${row?.last_name}`, row?.profile_data?.profile_pic )}
                            // onClick={()=>props.onView(row?.profile_id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          )}

          {/* --------- table for assignment & quiz ----------- */}
          {(type.includes("assignment") || type.includes("quiz")) && (
            <TableBody>
              {props.data?.length > 0 &&
                props.data
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            gap: "2rem",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            alt={`${row?.first_name} ${row?.last_name}`}
                            src="/static/images/avatar/1.jpg"
                            sx={{ width: 36, height: 36 }}
                          />
                          {`${row?.title}`}
                        </div>
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        {row?.owner_name || ""}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.max_marks}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.assigned || 0}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.submitted || 0}
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        {moment(row?.due_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src="/Image/EnterpriseDashboard/View Profile.svg"
                            alt="icon"
                            className={styles.icon}
                            onClick={()=>props.onView(row)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          )}


          {/* ----------------- class & group table ---------------- */}

          {(type === "dashboard_class" || type === "dashboard_group") && (
            <TableBody>
              {props.data?.length > 0 &&
                props.data
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            gap: "2rem",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            alt={`${row?.first_name} ${row?.last_name}`}
                            src={row?.cover_image || "/Image/DashImage/class-cover.png"}
                            sx={{ width: 36, height: 36 }}
                          />
                          {`${row?.name}`}
                        </div>
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        {type === "dashboard_class" ? row?.owner_name : row?.group_owner_name}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.code}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.members || 0}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        <div onClick={()=>props.onStatusChange(row?.id, !row.is_active)}>
                        {row?.is_active ? <p className={styles.active_mode}>{t("enterpriseDashboard.active")}</p> : <p className={styles.inactive_mode}>{t("enterpriseDashboard.inactive")}</p> }
                        </div>
                      </TableCell>
                      {/* <TableCell sx={styleTableCell}>
                        {moment(row?.date_joined).format("DD-MM-YYYY")}
                      </TableCell> */}
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            cursor: (props.loggedInUserProfileId === row?.class_owner || props.loggedInUserProfileId === row?.group_owner) ? "pointer": "not-allowed",
                          }}
                        >
                          <img
                            src={(props.loggedInUserProfileId === row?.class_owner || props.loggedInUserProfileId === row?.group_owner) ? "/Image/EnterpriseDashboard/View Profile.svg" : "/Image/EnterpriseDashboard/disabled-view.svg"}
                            alt="icon"
                            className={styles.icon}
                            onClick={(props.loggedInUserProfileId === row?.class_owner || props.loggedInUserProfileId === row?.group_owner) ? ()=>props.onView(row?.id) : ()=>null}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          )}


          {/* ------------- course table ---------------- */}
          {type.includes("course") && (
            <TableBody>
              {props.data?.length > 0 &&
                props.data
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            gap: "2rem",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            alt={`${row?.first_name} ${row?.last_name}`}
                            src="/static/images/avatar/1.jpg"
                            sx={{ width: 36, height: 36 }}
                          />
                          {`${row?.name}`}
                        </div>
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        {row?.owner_name || row?.owner || ""}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.public ? "Public" : "Private"}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.price || 0}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.enrolled_count || 0}
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        {moment(row?.timestamp).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src="/Image/EnterpriseDashboard/View Profile.svg"
                            alt="icon"
                            className={styles.icon}
                            onClick={()=>props.onView(row)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          )}

          {/* ------------------ dashboard posts ----------------- */}
          {type.includes("post") && (
            <TableBody>
              {props.data?.length > 0 &&
                props.data
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            gap: "2rem",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            alt={`${row?.first_name} ${row?.last_name}`}
                            src={row?.post_owner_profile?.profile_pic}
                            sx={{ width: 36, height: 36 }}
                          />
                          {`${row?.post_owner_profile?.name}`}
                        </div>
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        {moment(row?.date_and_time).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        {row?.comments?.length || 0}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.likes || 0}
                      </TableCell>
                      <TableCell
                        sx={{ ...styleTableCell, textAlign: "center" }}
                      >
                        {row?.visibility_type}
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src="/Image/EnterpriseDashboard/View Profile.svg"
                            alt="icon"
                            className={styles.icon}
                            onClick={props.onView}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          )}

          {/* -------------------- table for faculyDashboard: student and group members ----------- */}
          {(type === "dashboard_class_student" || type === "dashboard_group_member") && (
            <TableBody>
              {props.data?.length > 0 &&
                props.data
                  .slice(indexOfFirstItem, indexOfLastItem)
                  .map((row) => (
                    <TableRow
                      key={row?.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            gap: "2rem",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            alt={`${row?.name}`}
                            src={row?.profile_pic || "/static/images/avatar/1.jpg"}
                            sx={{ width: 36, height: 36 }}
                          />
                          {`${row?.name}`}
                        </div>
                      </TableCell>
                      <TableCell sx={styleTableCell}>
                        {moment(row?.joining_date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell sx={styleTableCell}>{row?.user_id}</TableCell>
                      <TableCell sx={styleTableCell}>{row?.phone}</TableCell>
                      <TableCell sx={styleTableCell}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            cursor: "pointer",
                          }}
                        >
                          <img
                            src="/Image/EnterpriseDashboard/Delete.svg"
                            alt="icon"
                            className={styles.icon}
                            onClick={()=>props.onDelete(row?.id)}
                          />
                          <img
                            src="/Image/EnterpriseDashboard/View Profile.svg"
                            alt="icon"
                            className={styles.icon}
                            onClick={()=>props.onView(row?.user_id, row?.name, row?.profile_pic )}
                            // onClick={()=>props.onView(row?.profile_id)}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          )}

        </Table>
      </TableContainer>

      {props.data?.length < 1 && (
        <div className={styles.data_not_found_image}>
          <img
            className={styles.no_data_image}
            src="/Image/EnterpriseDashboard/Empty state.svg"
          />
        </div>
      )}

      {props.data?.length > 0 && (
        <div className={styles.pagination_container}>
          <Pagination
            count={Math.ceil(props.data?.length / 10) || 1}
            page={page}
            onChange={(event, value) => {
              setPage(parseInt(value));
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EnterpriseDataTable;
