import React from "react";
import { Component } from "react";
import CourseModal from "./CourseModal";
import styles from "./classcss/classrightsidebar.module.css";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import moment from "moment";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import InvitePeopleModal from "./InvitePeopleModal";
import {
  updateTimeTable,
  getClassDetails,
  handleErrorMessage,
} from "../../../redux/actions/classes";
import { getGroupDetails } from "../../../redux/actions/groups";
import { validateFileSize } from "../../../redux/constants/commonFunctions";
import { store } from "../../../redux/store";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UpcomingAssignmentsComponent from "../../AssignmentAndQuiz/Components/UpcomingAssignmentsComponent";

class GroupRightSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      showAgendaModal: false,
      isModalOpen: false,
    };
    //this.handleTimeTable = this.handleTimeTable.bind(this);
  }
  CourseModalCloser = () => {
    this.setState({ showAgendaModal: false });
  };
  CourseModalOpener = () => {
    this.setState({ showAgendaModal: true });
  };
  openComingSoonModal = () => {
    this.setState({ isModalOpen: true });
  };
  closeComingSoonModal = () => {
    this.setState({ isModalOpen: false });
  };

  timeTableUpload = (e) => {
    
    if(!(e.target.files[0] instanceof File)){
      return null
    }
    
    const extension = e.target.files[0]?.name?.split(".").pop().toLowerCase();
    const errorMessage = validateFileSize(e.target.files[0], 30720);

    if(extension !== "pdf" && extension !== "jpg" && extension !== "jpeg" && extension !== "png"){
      this.props.handleErrorMessage(
        `.${extension} ${this.props.t("courseMonetisation.filesNotSupported")}`
      )
    } else if (errorMessage){
      this.props.handleErrorMessage(errorMessage);
    } else {
      this.setState({ timeTable: e.target.files[0] });
      let file = e.target.files[0];
      let payload = {
        timeTable: file,
        classId:
          this.props.routeType === "Class"
            ? this.props.class_id
            : this.props.group_id,
        routeType: this.props.routeType,
      };

      this.props.updateTimeTable(payload)
        .then(() => {
          this.props.routeType === "Class"
            ? this.props.getClassDetails(this.props.class_id)
            : this.props.getGroupDetails(this.props.group_id);
        })
        .catch((err) => {});

    }
    
  };

  render() {
    const { t } = this.props;
    let date = moment().format("LLLL").split(",");
    date = date[0] + `, ` + date[1];
    return (
      <div className={styles.group_rightsidebar}>
        <CourseModal
          title={t("classGroup.addAgenda")}
          routeType={this.props.routeType}
          closeModal={this.CourseModalCloser}
          show={this.state.showAgendaModal}
        />
        {this.state.isModalOpen ? (
          <InvitePeopleModal
            getActiveClassDetails={this.props.getActiveClassDetails}
            showInvitePeopleModal={this.state.isModalOpen}
            class_id={this.props.class_id}
            routeType={this.props.routeType}
            InvitePeopleModalCloser={this.closeComingSoonModal}
            subjectCode={this.props.subjectCode}
          />
        ) : null}

        <div className={styles.groupRightsidebar_stats}>
          <div className={styles.groupRightsidebar_stat}>
            <div
              className={styles.groupRightsidebar_inner_stat}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "10px 0 0 0",
              }}
            >
              <p
                style={{
                  font: "normal normal normal 14px/20px Montserrat",
                  color: "#929292",
                  margin: "10px 0",
                }}
              >
                {t("class.todayDate")}
              </p>
              <Tooltip title={t("class.notLive")} arrow>
                <FiberManualRecordIcon
                  style={{
                    color: "#FC1159",
                    height: 8,
                    width: 8,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>
            <h4
              style={{
                font: "font: normal normal medium 16px/20px Montserrat",
                color: "#000000",
              }}
            >
              {date}
            </h4>
          </div>
          <hr className={styles.groupHr} />
          <div className={styles.groupRightsidebar_stat1}>
            <div className={styles.class_agenda}>
              <div className={styles.class_agenda_details}>
                <img src="/Image/DashImage/agenda.svg" />
                <h4>{t("class.agenda")}</h4>
                <button>{t("common.new")}</button>
              </div>
              <h4>{t("class.agendaDesc")}</h4>
            </div>
            <p
              style={{
                font: "normal normal normal 16px/19px Montserrat",
                fontWeight: "600",
                color: "#000000",
                marginLeft: 20,
              }}
            >
              {t("class.todayAgenda")}
            </p>
            <div
              className={styles.classinputdiv}
              onClick={this.CourseModalOpener}
            >
              <p>{t("class.postAgenda")}</p>
              <img src="/Image/DashImage/plus.svg" />
            </div>
          </div>
        </div>

        {this.props.isOwner && <div>
          <button
            className={styles.groupinviteButton}
            onClick={this.openComingSoonModal}
          >
            {t("class.invite")}
          </button>
        </div>}

        {/* Gradebook */}
        {this.props.routeType === "Class" && <>
        <div className={styles.Classsidebar_mycontent}>
          <div className={styles.Class_side}>
            <img
              src="/Image/Gradebook/gradebook-star.svg"
              className={styles.Classsidebar_icon1}
              style={{ width: 35, height: 35, cursor: "pointer" }}
            />
            {/* <h3 style={{ marginLeft: 15, fontSize: 16 }}>
              {this.props.routeType === "Class"
                ? t("gradebook.gradebook")
                : t("gradebook.gradebook")}
            </h3> */}
            <h3 style={{ marginLeft: 15, fontSize: 16 }}>
              {t("gradebook.gradebook")}
            </h3>
          </div>
        </div>
        
        <Link 
          to={{
            pathname: this.props.isOwner ? `/gradebook/view/${this.props.class_id}` : `/gradebook/student/`,
            state: {
              routeType : window.location.pathname.split("/")[1],
              isOwner: this.props?.isOwner,
              classOrGroupId: this.props.class_id,
              profileId: this.props.profileId,
              classOrGroupName: this.props.class_name,
            } ,
            }} 
          style={{textDecoration: "none"}}>        
          <div className={styles.Classsidebar_stats} style={{cursor: "pointer"}}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",   
              }}
              >
              <div
                style={{
                  color: "#555555",
                  padding: "15px 15px",
                  margin: 0,
                  fontSize: "14px",
                  textAlign: "center",
                }}
                >
                {this.props.isOwner ? t("gradebook.viewRecord") : t("gradebook.viewYourAsgQuiz")}
              </div>
            </div>
            <hr className={styles.ClassHr} />
            <div
              className={styles.sidebar_stat1}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "12px",
                paddingBottom: "12px",
                color: "#ED1E24",
                font: "normal normal 600 13px/16px Montserrat",
                cursor: "pointer",
                "textTransform": "uppercase",              
              }}
            >
              <label class="custom-file-upload">
                {t("common.view")}
              </label>
            </div>
          </div>
        </Link>
        </>}

        {/* Time Table */}
        <div className={styles.Classsidebar_mycontent}>
          <div className={styles.Class_side}>
            <img
              src="/Image/DashImage/Chalkmate_Timetable.svg"
              className={styles.Classsidebar_icon1}
              style={{ width: 35, height: 35, cursor: "pointer" }}
            />
            <h3 style={{ marginLeft: 15, fontSize: 16 }}>
              {this.props.routeType === "Class"
                ? t("class.timeTable")
                : t("group.schedule")}
            </h3>
          </div>
        </div>

        <div className={styles.Classsidebar_stats}>
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => window.open(this.props.time_table, "_blank")}
          >
            <div
              style={{
                color: "#555555",
                padding: "15px 15px",
                margin: 0,
                fontSize: "14px",
                textAlign: "center",
              }}
            >
              {this.props.routeType === "Class"
                ? this.props.time_table === undefined ||
                  this.props.time_table === null
                  ? t("class.timeTableDesc")
                  : this.props.time_table.split("/").pop().split("_")[0]
                : this.props.time_table === undefined ||
                  this.props.time_table === null
                ? t("group.scheduleDesc")
                : this.props.time_table.split("/").pop().split("_")[0]}
              {/*               {(store.getState().classes.class.time_table === undefined || store.getState().classes.class.time_table === null)
              ? `Upload ${this.props.routeType === "Class" ? "time table for your students." : "schedule for your peers"}`
              : `${store.getState().classes.class.time_table.split("/").pop().split("_")[0]}`
              } */}
            </div>
            {this.props.time_table === undefined ||
            this.props.time_table === null ? null : (
              <img src="/Image/download.svg" height={20} width={20} />
            )}
          </div>
          <hr className={styles.ClassHr} />
          <div
            className={styles.sidebar_stat1}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: "12px",
              paddingBottom: "12px",
              color: "#ED1E24",
              font: "normal normal 600 13px/16px Montserrat",
              cursor: "pointer",
            }}
          >
            <label class="custom-file-upload">
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e)=>this.timeTableUpload(e)}
              />
              {this.props.routeType === "Class"
                ? this.props.time_table === null
                  ? t("class.uploadTimeTable")
                  : t("class.updateTimeTable")
                : this.props.time_table === null
                ? t("group.uploadSchedule")
                : t("group.updateSchedule")}
            </label>
          </div>
        </div>
        {/* ---- Adding Upcoming */}
        <UpcomingAssignmentsComponent class_name={this.props.class_name} class_id={this.props.class_id} routeType={window.location.pathname.split("/")[1]} isAsg={true} isOwner={this.props.isOwner} />
        <UpcomingAssignmentsComponent class_name={this.props.class_name} class_id={this.props.class_id} routeType={window.location.pathname.split("/")[1]} isAsg={false} isOwner={this.props.isOwner} />
      </div>
    );
  }
}

/* export default GroupRightSidebar; */
const mapStateToProps = (state) => {
  return {
    time_table: state.classes.class.time_table || state.groups.group.time_table,
    classOwner: state?.classes?.class?.class_owner,
    groupOwner: state?.groups?.group?.group_owner,
    // isOwner: window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.group_owner === state?.profile?.profileId) : (state?.profile?.user?.user_subtype == "FACULTY"),
    // isOwner: window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.group_owner === state?.profile?.profileId) : (state?.classes?.class?.class_owner == state?.profile?.profileId),
    isOwner: window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.hasAccess) : (state?.classes?.class?.hasAccess),
    userId: state?.profile?.profileId,
    class_name: window.location.pathname.split("/")[1] === 'class'? state?.classes?.class?.name : state?.groups?.group?.name,
    class_id: window.location.pathname.split("/")[2], // works for both class and group
    profileId: state?.profile?.profileId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTimeTable: (data) => dispatch(updateTimeTable(data)),
    getClassDetails: (id) => dispatch(getClassDetails(id)),
    getGroupDetails: (id) => dispatch(getGroupDetails(id)),
    handleErrorMessage: (message) => dispatch(handleErrorMessage(message)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(GroupRightSidebar)
);
