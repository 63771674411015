import React, { Component } from "react";
import { handleErrorMessage } from "../../../redux/actions/classes";
import styles from "../../ClassesGroups/ClassModal/css/MiddleContain.module.css";
// import { Link } from "react-router-dom";
import AgendaBottomContainer from "../../Dashboard/postModal/AgendaBottomContainer";
import { useDispatch } from 'react-redux';
import HandleApiError from "../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";
class AgendaMiddleContainer extends Component {
  state = {
    error: false,
    message: "",

  };
  render() {
    const { t } = this.props;
    const { name, photoUrl, message, link, video } = this.props.post;

    return (
      <div className={styles.classModal}>
        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
        <h3 className={styles.inputLabel} for="classname">
        <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/title.svg"/>{t("classGroup.agendaTitle")}*</div>
        </h3>
        <input id="agendaTitle" type="text" className={styles.inputField} value={name} onChange={(e) => this.props.updateTitle(e)} placeholder={t("classGroup.ExDoubtClearingSession")} required />
        <h3 for="description" className={styles.inputLabel}>
        <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/description.svg"/>{t("common.description")}</div>
        </h3>
        <textarea
          className={styles.inputField1}
          value={message}
          onChange={(e) => this.props.updateMessage(e)}
          type="textarea"
          rows="6"
          placeholder={`${t("classGroup.shareYourTask")} ${this.props.routeType === "Class" ? t("class.classwork") : t("group.work")} ${t("classGroup.otherFiles")} ${this.props.routeType === "Class" ? t("class.class") : t("group.group")}`}
        >
          {message}
        </textarea>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h3 for="subject" className={styles.inputLabel}>
          <div style={{display: "flex", alignItems: "center", font: "normal normal 500 16px Montserrat", letteSpacing: "0px", color: "#000000", margin: "15px 0"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/link.svg"/>{t("common.link")}
          <p style={{ font: "italic normal normal 14px/18px Montserrat", color: "#000000", opacity: 0.5}}> ({t("common.optional")})</p>
          </div>
          </h3>
        </div>
        <div className={styles.drop}>
          <input type="text" id="agendaLink" value={link} onChange={(e) => this.props.updateLink(e)} className={styles.inputField} placeholder={t("classGroup.ExAddLinksForAgenda")} />
        </div>
        <h3 for="media" className={styles.inputLabel}>
          {t("common.addMedia")}
        </h3>
        <AgendaBottomContainer videoInput={this.props.videoInput} fileInput={this.props.onPicInput} />
        <button
          onClick={() => {
            if( document.getElementById('agendaTitle').value == '')
              {
                this.setState({error: true, message: t("errors.AgendaTitleRequired")}); 
                return;           
              }
            if(document.getElementById('agendaLink').value !="" && !document.getElementById('agendaLink').value.match(new RegExp("^(ht|f)tp(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$")))
              {
               this.setState({error: true, message: t("errors.EnterValidLink")});  
               return;           
              }
            this.props.onPost()
           
          }}
          id="submitForm"
          className={styles.saveDetailsButton}
        >
          {t("common.save")}
        </button>
      </div>
    );
  }
}
export default withTranslation()(AgendaMiddleContainer)
