import React from "react";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Tags extends React.Component {
  render() {
    const {t} = this.props;
    return (
      <div
        id="Tags"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <IconTitle title={t("profile.tags")} img="/Image/apps/profile-resume-edit-view/tag.svg" />
        <br />

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.props.tagContent?.split(",").map((item, idx) => {
                return (
                  <>
                  {item != "" ?
                  <li key={idx} className={styles.tagList}>
                    {item}
                  </li>
                    : 
                    null
                    }
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tagContent: state?.profile?.tagsView || "",
  };
}

export default withTranslation()(connect(mapStateToProps, null)(Tags));
