import React, { Component } from "react";
import MiddleContain from "./MiddleContain";
import { ModalWithHeader } from "../../Dashboard/shared/ui/Modal/Modal";
import {
  getClasses,
  postClass,
  editClass,
  updateClass,
  deleteClass,
  handleErrorMessage,
} from "../../../redux/actions/classes";
import { connect } from "react-redux";
import Feed from "../../Dashboard/Components/Feed";
import { withTranslation } from "react-i18next";

class ClassModal extends Component {
  state = {
    className:
      this.props.actionType == "EDIT" ? this.props?.editData?.name : "",
    classDescription:
      this.props.actionType == "EDIT" ? this.props?.editData?.description : "",
    classGrade:
      this.props.actionType == "EDIT" ? this.props?.editData?.grade : "",
    classSubject:
      this.props.actionType == "EDIT" ? this.props?.editData?.subject : "",
    classId: this.props.actionType == "EDIT" ? this.props?.editData?.id : "",
  };
  /*   : {
    className: "",
    classDescription: "",
    classGrade: "",
    classSubject: "",
    classId: ""
  } */
  getState = () => {
    this.setState({
      className:
        this.props.actionType == "EDIT" ? this.props?.editData?.name : "",
      classDescription:
        this.props.actionType == "EDIT"
          ? this.props?.editData?.description
          : "",
      classGrade:
        this.props.actionType == "EDIT" ? this.props?.editData?.grade : "",
      classSubject:
        this.props.actionType == "EDIT" ? this.props?.editData?.subject : "",
      classId: this.props.actionType == "EDIT" ? this.props?.editData?.id : "",
    });
  };

  componentDidMount() {
    this.getState();
  }

  openwelcome = () => {
    this.props.closeModal();
    this.props.showWelcome();
  };

  onClassNameChange = (e) => {
    this.setState({ className: e.target.value });
  };
  onClassDescriptionChange = (e) => {
    this.setState({ classDescription: e.target.value });
  };
  onClassGradeChange = (e) => {
    this.setState({ classGrade: e.target.value });
  };
  onClassSubjectChange = (e) => {
    this.setState({ classSubject: e.target.value });
  };

  // makeid = () => {
  //   var userData = localStorage.getItem("userData");
  //   var parsedUserData = JSON.parse(userData);
  //   var randomString = "";
  //   var result = "";
  //   var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   var charactersLength = 10;
  //   for (var i = 0; i < 10; i++) {
  //     randomString += characters.charAt(Math.floor(Math.random() * charactersLength));
  //   }

  //   result = randomString;

  //   return result;
  // };

  onClassAdd = (addToDept) => {
    let addedClass = {};
    addedClass.className = this.state.className;
    addedClass.classDescription = this.state.classDescription;
    addedClass.classGrade = this.state.classGrade;
    addedClass.classSubject = this.state.classSubject;

    if(addToDept){
      addedClass.department = this.props.departmentId;
    }
    // addedClass.code = this.makeid();

    console.log('class add payload',  addedClass)

    if (
      addedClass.className != "" &&
      addedClass.classGrade != "" &&
      addedClass.classSubject != ""
    ) {
      if (addedClass.classGrade.length > 10) {
        this.props.handleErrorMessage("Grade can be at most 10 characters");
      } else if (addedClass.classSubject.length > 50) {
        this.props.handleErrorMessage("subject can be at most 50 characters");
      } else {
        this.props.postClass(addedClass).then(() => this.props.getClasses());
        this.setState({
          className: "",
          classDescription: "",
          classGrade: "",
          classSubject: "",
        });
        this.props.closeModal();
      }

      this.props.closeModal();
    } else {
      this.props.handleErrorMessage("Please fill required fields!");
      this.props.closeModal();
    }
  };

  onClassEdit = () => {
    let editedClass = {};
    editedClass.className = this.state.className;
    editedClass.classDescription = this.state.classDescription;
    editedClass.classGrade = this.state.classGrade;
    editedClass.classSubject = this.state.classSubject;
    editedClass.classId = this.props?.editData?.id;
    this.props.editClass(editedClass);
    this.setState({
      className: "",
      classDescription: "",
      classGrade: "",
      classSubject: "",
    });
    this.props.closeModal();
  };

  onClassDelete = () => {
    let id = this.props.editData.id;
    this.props.deleteClass(id);
    this.props.closeModal();
  };

  render() {
    const { t } = this.props;
    /* console.log(this.props.editData.id) */
    return (
      <div>
        {this.props.actionType == "EDIT" ? (
          <ModalWithHeader
            title={
              this.props.actionType == "EDIT"
                ? t("class.editClass")
                : this.props.t("common.createClass")
            }
            show={this.props.show}
            closeHandler={this.props.closeModal}
            maxWidth="605px"
            minWidth="350px"
          >
            <div
              style={{ backgroundColor: "#fff", borderRadius: "0 0 15px 15px" }}
            >
              <MiddleContain
                value={4}
                open={this.openwelcome}
                onClassAdd={this.onClassAdd}
                onClassEdit={this.onClassEdit}
                onClassNameChange={this.onClassNameChange}
                onClassDescriptionChange={this.onClassDescriptionChange}
                onClassGradeChange={this.onClassGradeChange}
                onClassSubjectChange={this.onClassSubjectChange}
                editData={this.props.editData}
                actionType={this.props.actionType}
                isDepartmentUser={this.props.departmentId ? true : false}
              />
            </div>
          </ModalWithHeader>
        ) : this.props.actionType == "DELETE" ? (
          <ModalWithHeader
            title={t("common.delete")}
            show={this.props.show}
            closeHandler={this.props.closeModal}
            maxWidth="605px"
            minWidth="350px"
          >
            <div
              style={{ backgroundColor: "#fff", borderRadius: "0 0 15px 15px" }}
            >
              <MiddleContain
                value={4}
                open={this.openwelcome}
                onClassAdd={this.onClassAdd}
                onClassEdit={this.onClassEdit}
                onClassDelete={this.onClassDelete}
                onClassNameChange={this.onClassNameChange}
                onClassDescriptionChange={this.onClassDescriptionChange}
                onClassGradeChange={this.onClassGradeChange}
                onClassSubjectChange={this.onClassSubjectChange}
                editData={this.props.editData}
                actionType={this.props.actionType}
                isDepartmentUser={this.props.departmentId ? true : false}
              />
            </div>
          </ModalWithHeader>
        ) : (
          <ModalWithHeader
            title={
              this.props.actionType == "EDIT"
                ? t("class.editClass")
                : this.props.t("common.createClass")
            }
            show={this.props.show}
            closeHandler={this.props.closeModal}
            maxWidth="605px"
            minWidth="350px"
          >
            <div
              style={{ backgroundColor: "#fff", borderRadius: "0 0 15px 15px" }}
            >
              {console.log('insideClassModal dept', this.props.departmentId)}
              <MiddleContain
                value={4}
                open={this.openwelcome}
                onClassAdd={this.onClassAdd}
                onClassEdit={this.onClassEdit}
                onClassNameChange={this.onClassNameChange}
                onClassDescriptionChange={this.onClassDescriptionChange}
                onClassGradeChange={this.onClassGradeChange}
                onClassSubjectChange={this.onClassSubjectChange}
                editData={this.props.editData}
                actionType={this.props.actionType}
                isDepartmentUser={this.props.departmentId ? true : false}
              />
            </div>
          </ModalWithHeader>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user || "",
    departmentId: state.profile.dept_id || null,
    //   subjects:state.classes.classes.map((each_class)=>{ return {id:each_class.id,
    //     name:each_class.name,
    //     grade:each_class.grade,
    //     subject:each_class.subject,
    //     image_url:each_class.cover_image || "./Image/DashImage/profile.png"}
    //   }) || [],
    //   routeType:'/class'
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    postClass: (data) => dispatch(postClass(data)),
    editClass: (data) => dispatch(editClass(data)),
    deleteClass: (data) => dispatch(deleteClass(data)),
    getClasses: () => dispatch(getClasses()),
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ClassModal)
);
