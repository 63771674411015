import React from "react";

export default class MidDpicFeed extends React.Component {
  constructor(props) {
    super(props);
    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange(event) {
    this.props.props.updateDp(URL.createObjectURL(event.target.files[0]));
  }

  render() {
    console.log("MDic view props", this.props)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            marginTop: 15,
            backgroundColor: "#5555555A",
            height: 160,
            width: 160,
            borderRadius: 100,
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 30,
          }}
        >
          <>
          <div style={{ position: "absolute", width: 160, height: 160, overflow: "hidden", borderRadius: 100 }}>
            {/* <img src={this.props.props.dpfileloc} style={{ width: "100%", height: "100%", objectFit: "cover" }} /> */}
            <img src={this.props.props.profilePicUrlview} style={{ width: "100%", height: "100%", objectFit: "cover" }} />
          </div>
          {this.props.props.showOTW && <div style={{ position: "absolute", width: 160, height: 160, overflow: "hidden", borderRadius: 100 }}>
              <img src={"/Image/otw/otw_frame_160x160_v6.svg"} style={{ width: "100%", height: "100%", objectFit: "none", top: 0, left: 0, opacity: 1 }} />
            </div>}
          </>

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", paddingBottom: 20 }}>
            <div
              style={{
                border: "5px solid #fff",
                padding: 8,
                borderRadius: 20,
                backgroundColor: "#00FF80",
                zIndex: 2,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
