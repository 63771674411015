import React, { useEffect, useState } from "react";
import styles from "./css/AddCourseContentPage.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import IconTitle from "../../components/commons/IconTitle";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import ButtonMUI from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import MultipleSelectChip from "../AssignmentAndQuiz/Components/MultipleSelectChip";
import { handleErrorMessage } from "../../redux/actions/classes";
import OuterSectionContainer from "./Components/OuterSectionContainer";
import LectureItem from "./Components/LectureItem";
import AddSectionLectureForm from "./Components/AddSectionLectureForm";
import { CircularProgress } from "@mui/material";
import {
  getCourseDetails,
  clearCourses,
  publishCourse,
} from "../../redux/actions/courses";
import ErrorFourZeroFour from "../../components/ErrorFourZeroFour";
import { GET_COURSE } from "../../redux/constants/actionTypes";
import { handleSuccessMessage } from "../../redux/actions/success";
import UpdateLectureComponent from "./Components/UpdateLectureComponent";

const AddCourseContentPage = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const courseID = params.id;
  const currentCourse = useSelector((state) => state?.courses?.currentCourse);
  const sections = currentCourse?.section;
  // const [sections, setSections] = useState([]);
  const [showAddSectionForm, setShowAddSectionForm] = useState(false);
  const [showUpdateLectureForm, setShowUpdateLectureForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [show404, setShow404] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [sectionEditData, setSectionEditData] = useState({
    id: null,
    title: "",
  });
  const [lectureEditData, setLectureEditData] = useState(null);

  useEffect(() => {
    if (!currentCourse?.id) {
      dispatch(getCourseDetails(courseID)).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  });

  const handleSave = () => {
    // NO API CALL ON SAVE BUTTON, ONLY REDIRECT
    dispatch(handleSuccessMessage(t("courseMonetisation.courseSaved")));
    history.push({
      pathname: `/academics`,
      state: { switchSection: "course" },
    });
  };

  const handlePublish = () => {
    setIsLoadingBtn(true);
    let hasEmptyLectureArray = currentCourse?.section?.some((section) => {
      return section.quizOrLecture?.length < 1;
    });

    if (hasEmptyLectureArray) {
      setIsLoadingBtn(false);
      dispatch(handleErrorMessage(t("courseMonetisation.cantPublish")));
    } else {
      setIsLoadingBtn(false);
      dispatch(publishCourse(courseID));
      history.push({
        pathname: `/academics`,
        state: { switchSection: "course" },
      });
    }
  };

  const handleTitleEdit = (id, title) => {
    setIsEditing(true);
    setShowAddSectionForm(true);
    setSectionEditData({ id: id, title: title });
  };

  const handleEditLecture = (sectionID, lectureData) => {
    const data = { ...lectureData };
    data.sectionID = sectionID;
    setIsEditing(true);
    setShowUpdateLectureForm(true);
    setLectureEditData(data);
  };

  // useEffect(() => {
  //   if (currentCourse?.id) {
  //     localStorage.setItem("currentCourse", JSON.stringify(currentCourse));
  //     setIsLoading(false);
  //   }
  // }, [currentCourse]);

  // useEffect(() => {
  //   if (!currentCourse) {
  //     const course = JSON.parse(localStorage.getItem("currentCourse")) ?? null;
  //     if (!course) {
  //       setIsLoading(false);
  //       setShow404(true);
  //     } else {
  //       // dispatch(getCourseDetails(course?.id)).then(() => setIsLoading(false));
  //       console.log('inside else')

  //       dispatch({
  //         type: "GET_COURSE",
  //         payload: course, // Payload
  //       })
  //     }
  //   }
  // });

  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem("currentCourse");
  //     dispatch(clearCourses());
  //   };
  // }, []);

  const styleAddIcon = {
    '@media (max-width: 500px)': {
      width:"13px",
      height:"13px"
    },
  }

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    '@media (max-width: 500px)': {
      height: "25px",
      padding: "6.756px 6.647px 8px 10px",
    },

  };

  if (isLoading) {
    return (
      <div className={styles.loader_style}>
        <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
      </div>
    );
  } else if (show404) {
    return <ErrorFourZeroFour />;
  } else {
    return (
      <div className={styles.main}>
        <div className={styles.left_container}>
          <div className={styles.thumbnail_container}>
            <img
              src={
                currentCourse?.thumbnail ??
                "/Image/CourseMonitization/CourseViewBanner.png"
              }
              alt="course-thumbnail"
            />
          </div>
          {/* {console.log("currentCourse A", currentCourse)}
          {console.log("onAddConentePage", { isEditing, lectureEditData })} */}

          <IconTitle
            // title={t("quiz.quiz")}
            title={currentCourse?.name}
            img="/Image/CourseMonetisation/booklet-line-2.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />

          <div className={styles.courseType}>
            <span className={styles.course_type_label}>
              {t("courseMonetisation.courseType")}:
            </span>
            <span className={styles.course_type_span}>
              {t(
                `${currentCourse?.public ? "common.Public" : "common.Private"}`
              )}
            </span>
          </div>

          {/* <span className={styles.input_label}>
          {t("courseMonetisation.courseType")}
        </span> */}
          {currentCourse?.classes?.length > 0 && (
            <>
              <span className={styles.input_label}>
                {t("courseMonetisation.publishingTo")}
              </span>
              <div className={styles.inputTags}>
                <Stack direction="row" spacing={0.4}>
                  {currentCourse?.classes.map((item) => (
                    <Chip
                      key={item?.id}
                      label={item?.name}
                      variant="outlined"
                      style={{
                        backgroundColor: "#ED1E24",
                        border: "none",
                        color: "#FFFFFF",
                        fontWeight: "700",
                        fontFamily: "Montserrat",
                        fontSize: "14px",
                        textTransform: "capitalize",
                      }}
                    />
                  ))}
                </Stack>
              </div>
            </>
          )}

          <span className={styles.input_label}>
            {t("createCourse.courseSubtitle")}
          </span>

          <span className={styles.subtext}>{currentCourse?.subtitle}</span>

          <span className={styles.input_label}>
            {t("courseMonetisation.description")}
          </span>

          <span className={styles.subtext}>{currentCourse?.description}</span>
        </div>
        {/* left ends here */}
        {/* -------------RIGHT CONTAINER--------- */}

        <div className={styles.right_container}>
          <span className={styles.add_content_label}>
            {t("courseMonetisation.addContent")}
          </span>

          {/* <div className={styles.add_section_btn}>
          <Button
            type="solid"
            label={t("courseMonetisation.plusSection")}
            disable={false}
          />
        </div> */}
          {sections?.length > 0 &&
            sections?.map((section, sectionIndex) => (
              <OuterSectionContainer
                key={section.id}
                index={sectionIndex}
                title={section.title}
                courseID={courseID}
                sectionID={section.id}
                isEditing={isEditing}
                onEdit={handleTitleEdit}
                showUpdateLectureForm={showUpdateLectureForm}
                lectureEditData={lectureEditData}
                onCancelLectureUpdate={() => {
                  setIsEditing(false);
                  setShowUpdateLectureForm(false);
                  setLectureEditData(null);
                }}
                onLectureContentUpdate={() => {
                  setShowUpdateLectureForm(false);
                }}
              >
                {/* {section?.lectures?.length > 0 &&
                  section?.lectures?.map((lecture, lectureIndex) => (
                    <LectureItem
                      key={lecture?.id}
                      sectionID={section.id}
                      lectureID={lecture.id}
                      index={lectureIndex}
                      title={lecture?.title}
                      fileType={lecture?.fileType}
                      isEditing={isEditing}
                      onEdit={handleEditLecture}
                      lecture={lecture}
                    />
                  ))} */}
                  {section?.quizOrLecture?.length > 0 &&
                  section?.quizOrLecture?.map((lecture, lectureIndex) => (
                    <LectureItem
                      key={lecture?.id}
                      sectionID={section.id}
                      lectureID={lecture.id}
                      index={lectureIndex}
                      title={lecture?.title}
                      fileType={lecture?.fileType}
                      isEditing={isEditing}
                      onEdit={handleEditLecture}
                      lecture={lecture}
                      lectureOrQuiz={lecture?.typeof}
                    />
                  ))}

                {/* {section?.quiz?.length > 0 &&
                section?.quiz?.map((quiz, quizIndex) => (
                  <LectureItem
                    key={quiz?.id}
                    index={quizIndex}
                    title={quiz?.title}
                    fileType={"quiz"}
                  />
                ))} */}

                {/* <ButtonMUI
                variant="outlined"
                startIcon={<AddIcon />}
                disableRipple
                onClick={() => setShowAddLectureForm(true)}
                sx={styleButtonMUI}
              >
                <span className={styles.btn_label}>
                  {t("courseMonetisation.curriculumItem")}
                </span>
              </ButtonMUI> */}
              </OuterSectionContainer>
            ))}

          <ButtonMUI
            variant="outlined"
            startIcon={<AddIcon sx={styleAddIcon}/>}
            disableRipple
            disabled={showAddSectionForm || isEditing}
            onClick={() => setShowAddSectionForm(true)}
            sx={styleButtonMUI}
          >
            <span className={styles.btn_label}>{t("common.section")}</span>
          </ButtonMUI>

          {showAddSectionForm && (
            <AddSectionLectureForm
              isSection={true}
              courseID={courseID}
              onCancel={() => {
                setShowAddSectionForm(false);
                setIsEditing(false);
                setSectionEditData({});
              }}
              isEditing={isEditing}
              sectionID={sectionEditData.id}
              lectureID={sectionEditData.id}
              title={sectionEditData.title}
            />
          )}

          {/* {showUpdateLectureForm && (
            <UpdateLectureComponent lecture={lectureEditData} onCancel={()=>{
              setIsEditing(false);
              setShowUpdateLectureForm(false);
              setLectureEditData(null);
            }} />
          )} */}

          <div className={styles.btn_div}>
            <Button
              type="solid"
              label={t("assignment.save")}
              disable={false}
              clickHandler={handleSave}
            />
            <Button
              type="solid"
              // label={t("common.publish")}
              label={
                isLoadingBtn ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  t("common.publish")
                )
              }
              disable={currentCourse?.section?.length < 1}
              clickHandler={handlePublish}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default AddCourseContentPage;
