import { React, useState, useEffect } from 'react';
import '../Sign.css';
import {Link, useHistory} from 'react-router-dom'
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { forgotPassword } from '../../../redux/actions/forgotPassword';
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import { handleErrorMessage } from '../../../redux/actions/classes';
import { useTranslation } from "react-i18next";
import SignNav from '../../home/Nav/SignNav';
import validator from "validator";


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


function Forgot_Password(){

  const horizontal='center';
  const vertical='top';
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const {t} = useTranslation()

  const [ email, setEmail ] = useState('');
  const [open, setOpen] = useState(false);
  const [message ,setMessage] = useState('');
  const [severity, setSeverity] = useState("");
  const dispatch = useDispatch();
  
  function handleSubmit(){
    // const pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/; 
    if(email == "") {
      setMessage("Enter email / Incorrect email");
      setOpen({ open: true });
      setSeverity("error");
      return
    }
    else if(!validator.isEmail(email)){
      dispatch(handleErrorMessage("Invalid email"))
      return
    }else{
      dispatch(forgotPassword(email));
      // setMessage("Password reset link send successfully");
      // setOpen({ open: true });
      // setSeverity("success");
    }
  }
  return ( 
    <div className='sign-container'>
    <img src="Image/signbgLeft.svg" alt="#" />
      <div className='sign-container-left'>
       <SignNav/>
          <div className='sign-form'>
            <div className='sign-heading'>
                <div>{t("signin.forgotPassword")}</div>
                <div>{t("signin.forgotPasswordDesc")}</div>
            </div>
            <div className="sign-input" >
                <input className='sign-field' onChange={e => setEmail(e.target.value)} type="email" value={email} placeholder={t("common.email")} />
                <button className='button sign-btn' onClick={()=>handleSubmit()} >{t("signin.sendResetLink")}</button>
            </div>
          </div> 
          {/* <div className={`sign-input ${step === 1 ? "" : "hidden"}`}>
            <input className="sign-field" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
            <button className="button sign-btn" onClick={() => setStep(2)}>
              Send Reset Link
            </button>
          </div>
          <div className={`sign-input ${step === 2 ? "" : "hidden"}`}>
            <input className="sign-field" onChange={(e) => setEmail(e.target.value)} type="password" placeholder="New Password" />
            <input className="sign-field" onChange={(e) => setEmail(e.target.value)} type="password" placeholder="Confirm Password" />
            <Link to="signin">
              <button className="button sign-btn">Reset</button>
            </Link>
          </div> */}
        </div>
      <div className="sign-container-right">
        <img src="Image/signbg.svg" alt="#" />
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
   
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (email) => dispatch(forgotPassword(email)),
  };
};

export default connect(null, mapDispatchToProps)(Forgot_Password);