import {
  GET_CHAT_LIST,
  GET_CHAT_MESSAGES,
  GET_SEARCH_RESULT,
  UPDATE_CHAT_LIST,
} from "../constants/actionTypes";
import cloneDeep from "lodash.clonedeep";
import { produce } from "immer";

const defaultState = {
  chatList: [],
  chatMessages: [],
  searchResult: [],
};

let data = {};
let index = null;

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CHAT_LIST:
      data = { ...state };
      data.chatList = action.payload;
      return data;

    case UPDATE_CHAT_LIST:
      data = cloneDeep(state);
      index = data.chatList.findIndex(
        (item) => item?.channel === action.payload?.channel
      );
      if (index >= 0) {
        data.chatList[index].dm_info.last_msg = action.payload.message;
        data.chatList[index].dm_info.msg_timestamp = action.payload.timestamp;
        data.chatList.unshift(data?.chatList?.splice(index,1)[0]);
      }
      return data;

    case GET_SEARCH_RESULT:
      data = { ...state };
      data.searchResult = action.payload;
      return data;

    case GET_CHAT_MESSAGES:
      data = { ...state };
      data.chatMessages = action.payload;
      return data;

    default:
      return { ...state };
  }
};
