import React from 'react';
import styles from './css/MyCourseCard.module.css';
import ClassTag from './ClassTag';
import CourseCards from './CourseCards.json';
import StudentCount from './StudentCount';
import InstructorName from "./InstructorName";
import {Link, useHistory} from "react-router-dom";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import TooltipWrapper from './TooltipWrapper';

const MyCourseCard = (props) => {

    let history = useHistory();

    const routehandler = (id)=>{
        history.push(`/course/view/${id}`) ///course/view/1dbf5a15-698b-4e6c-aa20-a4ddddcdc900
    }

    const { t } = useTranslation();
    return (
            <div onClick={()=>routehandler(props.id)} style={!props?.limit ? null : (props.ind > props?.limit ? {display:"none"} : null ) }  className={styles.myCourseCard} key={props.id}>
                    { props.showProgress &&
                        <div className={styles.progress_icon}>
                            <img src={`/Image/lecturePlan/${props.progress == 0 ? "Course-Not-started.svg" : props.progress == 100 ? "course-completed.svg" : "course-In-progress.svg"}`} alt="status" />
                        </div> 
                    } 
                    <div className={styles.cardImage}>
                        <img src={props.thumbnail} alt="defaultCourseImage" />
                    </div>
                    <div className={styles.myCourseCardContent}>
                        <h6>{props.public ? t("common.public") : t("common.private")}</h6>
                        <h4>{props.name}</h4>
                        {props?.isOwner && props.price === 0 ? <h5>{t("common.free")}</h5> : props.price === 0 ? <h5>{t("common.free")}</h5> : <h5>${props.price}</h5>}
                        {props?.isEnrolled && <div className={styles.studentCount}>
                            <img src="/Image/CourseMonetisation/user-2-line.svg" alt="" />
                            <span>{props.ownerName}</span>
                        </div>}
                        <StudentCount count={props.memberCount} />
                        {/* <ClassTag courseClasses={props.classes?.map((item)=>item.name)} /> */}
                        <Stack direction="row" spacing={1} style={{flexWrap: "wrap",marginBottom: "5px",}}>
                            {props.classes?.length < 2 ? 
                                <>
                                    {props.classes?.map((item)=>(
                                        <Chip label={item.name} variant="outlined" style={{
                                            border: "1px solid #ED1E24",
                                            color: "#ED1E24",
                                            fontWeight: "600",
                                            fontFamily: "Montserrat",
                                            fontSize: "12px",
                                            marginBottom: "5px",
                                            }}  
                                        />
                                    ))}
                                </>
                                : 
                                <>
                                    <Chip label={props.classes[0].name} variant="outlined" style={{
                                        border: "1px solid #ED1E24",
                                        color: "#ED1E24",
                                        fontWeight: "600",
                                        fontFamily: "Montserrat",
                                        fontSize: "12px",
                                        marginBottom: "5px",
                                        }}  
                                    />
                                    <p className={styles.classTagPara}>+ {props.classes?.length - 1} {t("courseMonetisation.more")}</p>
                                </>
                            }    
                        </Stack>

                        {props.isDraft ? <TooltipWrapper title={t("courseMonetisation.thisDraft")}>
                        <p className={styles.draft}>{t("courseMonetisation.draft")}</p>
                        </TooltipWrapper> : <p className={styles.published}>{t("courseMonetisation.published")}</p>}
                    </div>
                </div>
    )            
}

export default MyCourseCard;