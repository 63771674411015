import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./BoostConfirmation.module.css";

const BoostConfirmation = (props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.main}>
      <div className={styles.icon}>
      <img src="/Image/boost-profile/growth_icon.svg" />
      </div>

      <p className={styles.message}>
        {t("boostProfile.boostConfirmationModalMessage")}
      </p>

      <div className={styles.btn_container}>
        <div className={styles.btn1} onClick={props.onCancel}>{t("common.cancel")}</div>
        <div className={styles.btn2} onClick={props.onBoost}>{t("common.continue")}</div>
      </div>
    </div>
  );
};

export default BoostConfirmation;
