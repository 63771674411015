import React, { createContext, Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import posts from "../../../redux/reducers/posts";
import { errorMessageFromResponse } from "../../../redux/constants/commonFunctions";
import { handleErrorMessage } from "../../../redux/actions/classes";
import { handleSuccessMessage } from "../../../redux/actions/success";
import { getPosts } from "../../../redux/actions/classes";
import { getGroupPosts } from "../../../redux/actions/groups";

export const FeedsContext = createContext();

class FeedsProviderComponent extends Component {
  state = {
    posts: [],
    APIbaseURL: "https://api.yucampus.com/",
  };

  makeid = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  updatePostsState = () => {
    if (this.props.routeType === "Class") {
      this.setState({ posts: this.props.class_posts });
    } else {
      this.setState({ posts: this.props.group_posts });
    }
  };
  componentDidMount() {
    if (this.props.routeType === "Class") {
      this.props.getPosts(this.props.class_id);
      this.updatePostsState();
    } else {
      this.props.getGroupPosts(this.props.class_id);
      this.updatePostsState();
    }
  }
  componentDidUpdate() {
    if (
      this.props.routeType === "Class" &&
      this.state.posts !== this.props.class_posts
    ) {
      this.updatePostsState();
    } else if (
      this.props.routeType !== "Class" &&
      this.state.posts !== this.props.group_posts
    ) {
      this.updatePostsState();
    }
  }

  addPost = (post) => {
    let userData = localStorage.getItem("userData");
    let parsedUserData = JSON.parse(userData);
    let currentdate = new Date();
    let datetime =
      currentdate.getFullYear() +
      "-" +
      (currentdate.getMonth() + 1) +
      "-" +
      currentdate.getDate() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();
    const userName =
      this.props?.user?.first_name && this.props?.user?.last_name
        ? this.props.user.first_name + " " + this.props.user.last_name
        : "";
    let newPost = {
      name: userName,
      follower: "0",
      // timestamp:"2 hr",
      message:
        "Chrome versions of famous logos by Martin Naumann. Go give Martin a follow on Instagram via username @mnaumanndesign \n #logos #lego #adidas #nike #nasa #apple #reebok",
      // tag: "POPULAR EVENT",
      photoUrl: "/Image/DashboardImages/DashCommonImages/postSampleImg2.png",
      videoUrl: "",
    };
    newPost.message = post.message;
    newPost.photoUrl = post.photoUrl;
    newPost.videoUrl = post.videoUrl;
    const data = new FormData();
    data.append("share_link", this.makeid(10));
    data.append("visibility_type", "PUBLIC");
    data.append("post_type", post.type);
    data.append("tags", post.tags ? post.tags : "");
    data.append("date_and_time", datetime);
    if (this.props.routeType === "Class") {
      data.append("post_class_id", this.props.class_id);
    } else {
      data.append("post_group_id", this.props.class_id);
    }
    data.append("post_owner_profile", this.props?.user?.profile_id);
    data.append("description", post.message);
    if (post.photoUrl != null) {
      data.append("image_url", post.photoUrl, post?.photoUrl.name);
    } else if (post.videoUrl != null) {
      data.append("video_url", post.videoUrl, post?.videoUrl.name);
    }
    if (post.mediaFileUrl != null) {
      data.append("file_url", post.mediaFileUrl, post?.mediaFile?.name);
    }
    data.append(
      "title",
      post.name || `${userName} posted to ${this.props.subjectName}`
    );

    try {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      })
        .then((response) => {
          if (response.data.status) {
            if (this.props.routeType === "Class") {
              this.props.getPosts(this.props.class_id);
              this.updatePostsState();
            } else {
              this.props.getGroupPosts(this.props.class_id);
              this.updatePostsState();
            }
          } else {
            alert("Alert Response: " + response.data.message);
          }
        })
        .catch((error) => {
          this.props.handleErrorMessage(errorMessageFromResponse(error));
        });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  deletePost = (id) => {
    const token = localStorage.getItem("token");
    try {
      axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/${id}/`,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      }).then(() => {
        if (this.props.routeType === "Class") {
          this.props.getPosts(this.props.class_id);
        } else {
          this.props.getGroupPosts(this.props.class_id);
        }
        this.updatePostsState();
        this.props.handleSuccessMessage("Deleted successfully");
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  editPost = (post) => {
    var userData = localStorage.getItem("userData");
    var parsedUserData = JSON.parse(userData);

    const data = new FormData();
    if (post.message != null) {
      data.append("description", post.message);
    }
    if (post.photoUrl != null && post.onImageEdit) {
      data.append("image_url", post.photoUrl);
    }
    if (post.videoUrl != null && post.onVideoEdit) {
      data.append("video_url", post.videoUrl);
    }
    if (post.mediaFileUrl != null && post.onFileEdit) {
      data.append("file_url", post.mediaFileUrl);
    }

    try {
      axios({
        method: "put",
        url: `${process.env.REACT_APP_API_BASE_URL}api/v1/post/${post.id}/`,
        data: data,
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Token ${parsedUserData.token}`,
        },
      }).then((response) => {
        if (response.data.status) {
          if (this.props.routeType === "Class") {
            this.props.getPosts(this.props.class_id);
          } else {
            this.props.getGroupPosts(this.props.class_id);
          }
          this.updatePostsState();
          this.props.handleSuccessMessage("Updated successfully");
        } else {
          alert("Alert Response: " + response.data.message);
        }
      });
    } catch (error) {
      alert("Something went wrong, Please try again.");
    }
  };

  render() {
    let contextData = {
      state: this.state,
      addPost: this.addPost,
      deletePost: this.deletePost,
      editPost: this.editPost,
    };
    return (
      <FeedsContext.Provider value={contextData}>
        {this.props.children}
      </FeedsContext.Provider>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "",
    class_posts: state?.classes?.posts.sort((a, b) => {
      return b.id - a.id;
    }),
    group_posts: state?.groups?.posts.sort((a, b) => {
      return b.id - a.id;
    }),
    // class_posts: state?.classes?.posts,
    // class_id: state?.classes?.class?.id
    // group_posts: state?.groups?.posts,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
    handleSuccessMessage: (successMessage) =>
      dispatch(handleSuccessMessage(successMessage)),
    getPosts: (classId) => dispatch(getPosts(classId)),
    getGroupPosts: (groupId) => dispatch(getGroupPosts(groupId)),
  };
}
export const FeedsProvider = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedsProviderComponent);

// export const FeedsProvider = FeedsProviderComponent;
