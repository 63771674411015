import React from "react";
import { Component } from "react";
import styles from "./MobileBottom.module.css";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import Referral from "../referral/referral";
import { withTranslation } from "react-i18next";
import i18next, { t } from "i18next";
import { fetchProfileDataEdit, fetchProfileDataView } from "../../redux/actions/profile";
import { GET_PROFILE_DATA_EDIT, GET_PROFILE_DATA_VIEW } from "../../redux/constants/actionTypes";
import { connect } from "react-redux";
import LanguageButton from "../LanguageButton";
class MobileBottom extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      notifyChanges: false,
      path: "",
      chat: null,
      showReferralModal: false,
      showLangMenu : false, 
      lang : localStorage.getItem("i18nextLng") || navigator.language || navigator.userLanguage
    };
  }

  idetifyChange = () => {
    this.setState({
      notifyChanges: !this.state.notifyChanges,
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);    
    this.setState({
      path: window.location.href.split("/")[3]
    })
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        notifyChanges: false,
      });
    }
  };
  referralOpen = () => {
    this.setState({showReferralModal: true})
  }
  ReferralModalCloser = () => {
    this.setState({ showReferralModal: false });
  };

  changeActiveState = (text) => {
    this.setState({
      path: text
    })
  }

  // changeSelectedLanguage(selectedLang) {
  //   i18next.changeLanguage(selectedLang)
  //   localStorage.setItem("i18nextLng", selectedLang)
  //   this.setState({lang: selectedLang})

  //   if( window.location.pathname.split("/")[1] == 'profile' && window.location.pathname.split("/")[3] == 'edit'){
  //     this.props.fetchProfileDataEdit(GET_PROFILE_DATA_EDIT, JSON.parse(localStorage.getItem("userData"))?.token, JSON.parse(localStorage.userData)?.profile_id);
  //   }
  //   if( window.location.pathname.split("/")[1] == 'profile' && window.location.pathname.split("/")[3] == 'view'){
  //     this.props.fetchProfileDataView(GET_PROFILE_DATA_VIEW, JSON.parse(localStorage.getItem("userData"))?.token, window.location.pathname.split("/")[2]);
  //   }
  // }
  // toggleLanguageMenu() {
    
  //   if(this.state.showLangMenu == true) {
  //     this.setState({showLangMenu: false})
  //   }
  //   else {
  //     this.setState({showLangMenu: true})
  //   }
  // }

  render() {
    const {t} = this.props;
    return (
      <div className={styles.mobileBottom}>
        <Referral closeModal={this.ReferralModalCloser} show={this.state.showReferralModal} />
        <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <Link to="/user" style={{ textDecoration: "none" }} onClick={()=>this.changeActiveState("user")}>
              <div>
              {this.state.path === "user" ? (
                            <img src="/Image/header/home-active.svg" className={styles.image} style={{ width: 24, height: 24 }} />
                          ) : (
                            <img src="/Image/header/home-inactive.svg" className={styles.image} style={{ width: 24, height: 24 }} />
                          )}
                <div className={styles.image_name} style={this.state.path === "user" ? { color: "#ED1E24" } : { color: "#b4b4b4" }}> {t("header.home")}</div>
              </div>
            </Link>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <Link to="/academics" style={{ textDecoration: "none" }} onClick={()=>this.changeActiveState("academics")}>
              <div>
              {this.state.path === "academics" ? (
                            <img src="/Image/header/class-active.svg" className={styles.image} style={{ width: 24, height: 24 }} />
                          ) : (
                            <img src="/Image/header/class-inactive.svg" className={styles.image} style={{ width: 24, height: 24 }} />
                          )}
                <div className={styles.image_name} style={this.state.path === "academics" ? { color: "#ED1E24" } : { color: "#b4b4b4" }}> {t("header.academics")}</div>
              </div>
            </Link>
          </div>
        </div>
        {/* ------ Messages ------- */}
        {/* <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <Link to="/messages" style={{ textDecoration: "none" }} onClick={()=>this.changeActiveState("messages")}>
              <div>
              {this.state.path === "messages" ? (
                            <img src="/Image/liveChat/message_red.svg" className={styles.image} style={{ width: 24, height: 24 }} />
                          ) : (
                            <img src="/Image/liveChat/message_grey.svg" className={styles.image} style={{ width: 24, height: 24 }} />
                          )}
                <div className={styles.image_name} style={this.state.path === "messages" ? { color: "#ED1E24" } : { color: "#b4b4b4" }}> {t("header.messages")}</div>
              </div>
            </Link>
          </div>
        </div> */}
        <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <Link to="/opportunities" style={{ textDecoration: "none" }}>
              <div>
              <img src="/Image/otw/opportuities-inactive.svg" className={styles.image} style={{ width: 24, height: 24 }} />
                <div className={styles.image_name} onClick={this.idetifyChange}>
                {t("openToWork.opportunities")}
                </div>
              </div>
            </Link>
          </div>
        </div> 
        <div className={styles.bottom}>
          <div className={styles.bottom_container} >
            <LanguageButton/>
             {/* <div className={styles.language_div_dark} onClick={() => this.toggleLanguageMenu()}>
                <img className={styles.language_header} src="/Image/langIconGrey.png" alt="language icon"/>
                <div className={styles.langTextDiv}>{this.state.lang == "uz" ? "o'zbek" : this.state.lang == "ru" ? "русский" : "English"}</div>
                {
                  this.state.showLangMenu ?(  
                  <div className={styles.dropdownLanguage}>

                    <ul className={styles.dropdownList}>
                      <div>
                          <li className={this.state.lang == "en" ? styles.activeList : styles.inactiveList} onClick={() =>this.changeSelectedLanguage("en")}>English</li>
                          <hr style={{margin: "8px 0"}}/>
                          <li className={this.state.lang == "ru" ? styles.activeList : styles.inactiveList} onClick={() => this.changeSelectedLanguage("ru")}>русский</li>
                          <hr style={{margin: "8px 0"}}/>
                          <li className={this.state.lang == "uz" ? styles.activeList : styles.inactiveList} onClick={() => this.changeSelectedLanguage("uz")}>o'zbek</li>
                      </div>
                    </ul>
                  </div>
                  ):null
                }
              </div> */}
          </div>
        </div>
        <div className={styles.bottom}>
          {/* <div className={styles.bottom_container}>
            <a href="https://video.chalkboard.ml/" target="_bank" style={{ textDecoration: "none" }}>
              <img src="/Image/header/chalkBoard.svg" className={styles.image} style={{ width: "25px", height: "auto"}} />
              <div className={styles.image_name}>Chalkboard</div>
            </a>
          </div> */}
        </div>
        <div></div>
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProfileDataEdit: (action, token, id) => dispatch(fetchProfileDataEdit(action, token, id)),
    fetchProfileDataView: (action, token, id) => dispatch(fetchProfileDataView(action, token, id)),
  };
}
export default withTranslation()(connect(null, mapDispatchToProps)(MobileBottom));
// export default withTranslation()(MobileBottom);
