import React, { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styles from "./DropDown.module.css";
import {
  Theme,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useTranslation } from "react-i18next";

// NOTE:  handleChaneg function isn't complete, it needs modification to capture the selected option, to be done at the time of integration.

// Expected Props: option, label
// options - An array of strings
// minWidth - an integer value for width size (eg: 120)

const DropDown = (props) => {
  const [selection, setSelection] = React.useState("");
  const { t } = useTranslation();

  // handleChaneg function isn't complete, it needs modification to capture the selected option
  const handleChange = (event, type) => {
    console.log('targetInside',event.target.value);
    setSelection(event.target.value);
    props.getSelection(event.target.value, props?.type);
  };

  const colortheme = createTheme({
    palette: {
      primary: { main: "#ED1E24" },
    },
  });

  useEffect(()=>{
    
    if(props.type == 'order'){
      console.log('inside useEffect order ', props.value)
      props.value ? setSelection(props.options[1]) : setSelection(props.options[0]);
    } else if (props.type == 'reveal') {
      console.log('inside useEffect reveal ', props.value)
      props.value ? setSelection(props.options[1]) : setSelection(props.options[0]);
    } else {
      console.log('inside useEffect attempt ', props.value)
      setSelection(props.value)
    }
  },[props?.value])

  return (
    <>
      <ThemeProvider theme={colortheme}>
        <Select
          value={selection}
          fullWidth
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            borderRadius: "25px",
            height: "40px",
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <MenuItem className={styles.text} value="">
            {/* {props.label ?? props.options?.[0]} */}
          </MenuItem>
          {props.options?.map((item, index) => (
            <MenuItem
              key={`${index} + ${item}`}
              className={styles.text}
              value={item}
              sx={{
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              {typeof item == "string" ? t(`${item}`) : item}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>
    </>
  );
};

export default DropDown;
