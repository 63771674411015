import React, { useEffect, useState } from "react";
import styles from "./OpenToWorkViewModal.module.css";
import Modal, { ModalWithHeader } from "../../apps/Dashboard/shared/ui/Modal/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { deleteOpenToWork } from "../../redux/actions/profile";
import OpenToWorkConfirmationModal from "./OpenToWorkConfirmationModal";
import WelcomeToCourseModal from "../../apps/CourseLecturePlan/Modals/WelcomeToCourseModal";
import DiscardDeleteAssignmentModal from "../../apps/AssignmentAndQuiz/Modals/DiscardDeleteAssignmentModal";
// import AddIcon from "@mui/icons-material/Add";

// import ButtonMUI from "@mui/material/Button";

// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import Checkbox from "@mui/material/Checkbox";
// import ClearIcon from "@mui/icons-material/Clear";
// import { saveOpenToWork } from "../../redux/actions/profile";
// import { handleErrorMessage } from "../../redux/actions/classes";

const OpenToWorkViewModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(true);

  const data = useSelector((state) => state?.profile);


  console.log("profileData", data);

  const handleDelete = () => {
    setIsLoading(true)
    console.log("Delete this", data?.open_to_work?.id);
    dispatch(deleteOpenToWork(data?.open_to_work?.id))
    .then(()=>props.onClose());
    setIsLoading(false);
  }

  useEffect(()=>{
    if(props.triggerDeleteOTW){
      handleDelete();
    }

  }, [props.triggerDeleteOTW])

  return (
    <>
      <ModalWithHeader
        title={t("openToWork.jobPreference")}
        show={props.open}
        closeHandler={props.onClose}
        maxWidth="32rem"
        minWidth="21rem"
        borderColor={"#ED1E24"}
      >
        <div className={styles.middle_container}>

         
          <div className={styles.info_container}>
            <Avatar
              src={data?.userProfilePic || ""}
              sx={{ width: "4.6875rem", height: "4.6875rem" }}
            />

            <div className={styles.name_wrapper}>
              <p className={styles.name}>{data?.name}</p>
              {data?.open_to_work?.visibility && (
                <p className={styles.otw}>{t("openToWork.isOpenToWork")} </p>
              )}
            </div>

            <div className={styles.icon_wrapper}>
              <img src="/Image/otw/bin.svg" alt="delete" onClick={isLoading ? ()=>null : ()=> props.openDeleteModal()} /> 
              <img
                onClick={props.openEditView}
                src="/Image/otw/box-edit.svg"
                alt="edit"
                />
            </div>
          </div>
          <div className={styles.wrapper}>
            <p className={styles.title_label}>{t("openToWork.jobTitle")}</p>
            {/* {data?.open_to_work?.job_title?.map((item) => (
              <p className={styles.value}>{item?.toLowerCase() || ""}</p>
            ))} */}
            
            <p className={styles.value}> {data?.open_to_work?.job_title?.join(", ")} </p>
          </div>
          <div className={styles.wrapper}>
            <p className={styles.title_label}>{t("openToWork.locationType")}</p>
            {data?.open_to_work?.preferred_work_location.map((item) => (
              <p className={styles.value}>{item?.toLowerCase() || ""}</p>
            ))}
          </div>
          <div className={styles.wrapper}>
            <p className={styles.title_label}>{t("openToWork.startDate")}</p>
            {data?.open_to_work?.start_availability?.map((item) => (
              <p className={styles.value}>{item?.toLowerCase() || ""}</p>
            ))}
          </div>
          <div className={styles.wrapper}>
            <p className={styles.title_label}>
              {t("openToWork.employmentType")}
            </p>
            {data?.open_to_work?.employment_types?.map((item) => (
              <p className={styles.value}>{item?.toLowerCase() || ""}</p>
            ))}
          </div>
        </div>
      </ModalWithHeader>
    </>
  );
};

export default OpenToWorkViewModal;
