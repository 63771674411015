import React from "react";
import styles from "../css/StartQuizModal.module.css";
import { useTranslation } from "react-i18next";
import AssignmentHeading from "../../../components/assignmentAndQuiz/AssignmentHeading";
import ButtonMUI from "@mui/material/Button";
import { useHistory } from "react-router-dom";

const StartQuizModal = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleStartQuiz = () => {
    if (props.type == "QUIZ") {
      history.push({
        // pathname: `/quiz/attempt/${props?.quiz?.classes?.id ?? props?.quiz?.group?.id}`,
        pathname: `/student/quiz/${props?.quiz?.classes?.id ?? props?.quiz?.group?.id}`,
        state: {
          routeType: props.routeType,
          isOwner: props?.isOwner,
          quizID: props.quizID,
          quizTemplateID: props.quiz?.template?.id,
          classOrGroupId: props?.quiz?.classes?.id ?? props?.quiz?.group?.id,
          quiz: props.quiz
        },
      });
    }
  };

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "25px",
      padding: "7px 16px",
    },
  };

  if (props.type == "QUIZ") {
    return (
      <div className={styles.main}>
        {console.log("inStartModal", props.quizID, props.quiz, props.isOwner)}
        <AssignmentHeading title={props.title} type={"quiz"} />
        <div className={styles.mid_container}>
          <p className={styles.description}>{props.details}</p>
          <div className={styles.count_div}>
            <img
              style={{ width: 16, height: 16 }}
              src="/Image/assignment/Instructions.svg"
              alt=""
            />
            <span>
              {props.count > 1
                ? props.count + ` ${t("enhancedQuiz.questions")}`
                : props.count + ` ${t("common.question")}`}
            </span>
          </div>

          {props.isTimed && (
            <div className={styles.count_div}>
              <img
                style={{ width: 16, height: 16 }}
                src="/Image/AssignmentAndQuizzes/DueDate.svg"
                alt=""
              />
              <span>
                {props.duration < 60
                  ? `${Math.floor(props.duration)} ${t("enhancedQuiz.minutes")}`
                  : `${Math.floor(props.duration / 60)} ${t(
                      "enhancedQuiz.hours"
                    )}  ${Math.floor(props.duration % 60)} ${t(
                      "enhancedQuiz.minutes"
                    )}`}
              </span>
            </div>
          )}
        </div>

        <div className={styles.buttons_div}>
          <ButtonMUI
            variant="outlined"
            disableRipple
            // disabled={showAddSectionForm || isEditing}
            onClick={handleStartQuiz}
            sx={styleButtonMUI}
          >
            <span className={styles.btn_label}>{t("common.start")}</span>
          </ButtonMUI>
        </div>
      </div>
    );
  }
};

export default StartQuizModal;
