import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./CSSFiles/GetStarted.module.css";
import { joinClassByCode } from "../../../redux/actions/classes";
import { joinGroupByCode } from "../../../redux/actions/groups";
import JoinClassModal from "./JoinClassModal";
import JoinGroupModal from "./JoinGroupModal";
import { withTranslation } from 'react-i18next';

class GetStarted extends Component {
  state = {
    showShareJoinClassModal: false,
    showShareJoinGroupModal: false,
    joinGroupCode: "",
    joinClassCode: "",
  };
  shareModalOpener = (joinType) => {
    if (joinType === "class") this.setState({ showShareJoinClassModal: true });
    else this.setState({ showShareJoinGroupModal: true });
  };
  shareJoinClassModalCloser = () => {
    this.setState({ showShareJoinClassModal: false });
  };
  shareJoinGroupModalCloser = () => {
    this.setState({ showShareJoinGroupModal: false });
  };
  addGroupMember = () => {
    this.setState({ showShareJoinGroupModal: false });
    const joinGroupCodeOnly = this.state.joinGroupCode.split("/").pop();
    this.props.joinGroupByCode({ group_code: joinGroupCodeOnly });
  };
  addClassMember = () => {
    this.setState({ showShareJoinClassModal: false });
    const joinClassCodeOnly = this.state.joinClassCode.split("/").pop();
    this.props.joinClassByCode({ class_code: joinClassCodeOnly });
  };

  changeJoinClassCode = (e) => {
    this.setState({ joinClassCode: e.target.value });
  };
  changeJoinGroupCode = (e) => {
    this.setState({ joinGroupCode: e.target.value });
  };

  render() {
    return (
      <div className={styles.container}>
        <img src="./Image/DashboardImages/DashCommonImages/get-started.svg" className={styles.pic} />
        <div className={styles.content}>
          <h2>{this.props.t("dashboard.gettingStarted")}</h2>
          <p>{this.props.t("dashboard.gettingStartedDesc")}</p>
        </div>
        <JoinClassModal
            show={this.state.showShareJoinClassModal}
            shareModalCloser={this.shareJoinClassModalCloser}
            addClassMember={this.addClassMember}
            changeJoinClassCode={this.changeJoinClassCode}
          />
          <JoinGroupModal
            show={this.state.showShareJoinGroupModal}
            shareModalCloser={this.shareJoinGroupModalCloser}
            addGroupMember={this.addGroupMember}
            changeJoinGroupCode={this.changeJoinGroupCode}
          />
        <div className={styles.buttonsContainer}>
          <div className={styles.button} onClick={() => this.shareModalOpener("group")}>
          {this.props.t("common.joinGroup")}
          </div>
          <div className={styles.button} onClick={() => this.shareModalOpener("class")}>
          {this.props.t("common.joinClass")}          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    joinGroupByCode: (data) => dispatch(joinGroupByCode(data)),
    joinClassByCode: (data) => dispatch(joinClassByCode(data)),
  };
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GetStarted));
