import React from "react";
import styles from "./css/StudentAssignmentSubmissionList.module.css";
import StudentNameComponent from "../../apps/AssignmentAndQuiz/Components/StudentNameComponent";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useRef } from "react";

const StudentAssignmentSubmissionList = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const checkbox = useRef();
  const status_check =
    props.submissionRemark === "SUB" || props.submissionRemark === "DL";

  // const enableCheckbox = props.submissionRemark != "GRD" && props.grade != null;
  // const enableCheckbox = !(props.submissionRemark === "GRD" || (props.submissionRemark === "SUB" && props.grade == null));
  const enableCheckbox = props.assignmentType == "ASSIGNMENT" ? !(props.submissionRemark === "GRD" || ((props.submissionRemark === "SUB" || props.submissionRemark === "DL") && props.grade == null)) : !(props.submissionRemark === "GRD" || props.submissionRemark === "SUB" || props.submissionRemark === "DL");


  const clickHandler = () => {
    // if props.submission_id != null  direct to the grading page
    // along with submission_id
    if (props.assignmentType == "ASSIGNMENT") {
      history.push({
        pathname: `/assignment/grading/${props.assignment_id}`,
        state: {
          className: props.className,
          maxMarks: props.maxMarks,
          assignmentTitle: props.assignmentTitle,
          submission_id: props.submission_id,
          filtered_submissions: props.filtered_submissions,
          marks: props.grade,
          isOwner: props.isOwner,
          routeType: props.routeType,
        },
      });
    } else {
      history.push({
        pathname: `/quiz/grading/${props.assignment_id}`,
        state: {
          className: props.className,
          maxMarks: props.maxMarks,
          assignmentTitle: props.assignmentTitle,
          submission_id: props.submission_id,
          marks: props.grade,
          isOwner: props.isOwner,
          routeType: props.routeType,
          isGrading: true,
          currentQuiz: props.currentQuiz,
          userAnswers: props.userAnswers,
          classOrGroupId: props.classOrGroupId,
          userMarks: props.userMarks,
          userSubmissionId: props.userSubmissionId
        },
      });
    }

    // console.log("Was Clicked: ", props.studentName);
  };

  // ------ unassign drop down ---------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUnassign = () => {
    props.handleUnassign(props?.submission_id)
    setAnchorEl(null);
  }

  return (
    <div
      className={styles.assignment_submission_list_container}
      style={status_check ? null : { backgroundColor: "#F6F6F6" }}
    >
      {/* {console.log('rainCheck',checkbox?.current?.checked)} */}
      <div
        className="for_onClick"
        // onClick={enableCheckbox? props.addToReturnList(props.submission_id):null}
        onClick={() =>props?.isNewMember ? props?.addToReturnList(props.studentId, checkbox?.current?.checked) : props?.addToReturnList(props.submission_id, checkbox?.current?.checked)}
      >
        {enableCheckbox ? (
          <input
            type="checkbox"
            className={styles.checkbox_round}
            ref={checkbox}
            // onClick={props.addToReturnList(props.submission_id)}
          />
        ) : (
          <input type="checkbox" className={styles.checkbox_round} disabled />
        )}
      </div>
      <div
        className={styles.full_list}
        onClick={status_check ? clickHandler : null}
        style={status_check ? { cursor: "pointer" } : null}
      >
        <div className={styles.column_width}>
          <StudentNameComponent
            studentName={props.studentName}
            profilePic={props.profilePic}
          />
        </div>
        {/* <div className={styles.three_column}> */}
        <div className={styles.three_column_assignment}>
          <div className={styles.assignment_due_date}>
            <p className={styles.assignment_status}>{props.notAssigned ? "  " : (props.submissionDate ?? '_')}</p>
          </div>
          <div className={styles.assignment_status_div}>
            <p className={styles.assignment_status}>
              {props.submissionRemark === "GRD"
                ? t("viewAssignment.graded")
                : props.submissionRemark === "SUB"
                ? t("viewAssignment.submitted")
                : props.submissionRemark === "DL"
                ? t("viewAssignment.doneLate")
                : props.submissionRemark === "AS"
                ? t("viewAssignment.assigned")
                : "  "}
            </p>
          </div>
          <div className={styles.assignment_grade_div}>
            <p className={styles.assignment_status}>
              <span className={styles.assignment_marks}>
                {/* {props.grade != "" ? props.grade : "___"} */}
                {/* {props.notAssigned ? "  " : (props.grade != null && props.grade != undefined) ? `${props.grade ?? 0} / ${props.maxMarks ?? 0}` : ` ${props.grade ?? '     '} / ${props.maxMarks}`} */}
                {/* {props.grade ?? "___"} */}
                {props.notAssigned ? "  " : props.assignmentType === "ASSIGNMENT" ? `${props.grade ?? ""} / ${props.maxMarks ?? ""}` : `${props.submissionRemark !== "GRD" ? "   " : props.grade } / ${props.maxMarks ?? ""}`}
              </span>
              {/* &nbsp; /&nbsp;{props.maxMarks} */}
            </p>
          </div>
          
        </div>
        { 
          <div className={styles.action_div}>
            { props.notAssigned ? 
              <div className={styles.assign_text} onClick={()=>props?.handleAssign(props?.studentId)}>{t("assignment.assign")}</div> : 
              <div>
                <img className={styles.more_icon} src="/Image/assignment/more_horiz_2.svg" style = {{ cursor: `${props?.submissionRemark == "AS" ? "pointer" : "not-allowed"}`, opacity: "0.6" }} onClick = {props?.submissionRemark == "AS" ? handleClick : null}/>
                <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
               
                PaperProps={{
                  style: {
                    boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                    borderRadius: "10px",
                    fontFamily: 'Montserrat',
                  },
                }}
              >
                  <MenuItem 
                    onClick={()=> handleUnassign()}
                    sx={{
                      fontFamily: 'Montserrat',
                      fontWeight: '400',
                      fontSize:'12px',
                      color: '#000000',
                    }}>
                    {t("assignment.unassign")}
                  </MenuItem>
              </Menu>
              </div>
              }
          </div> }
      </div>
    </div>
  );
};
export default StudentAssignmentSubmissionList;
