import React from "react";
import { withRouter } from "react-router";

import styles from "../../apps/Profile/Profile.module.css";
import FollowButton from "./FollowButton";
import IconTitle from "./IconTitle";

import { fetchPeopleYouMayKnow } from "../../redux/actions/peopleyoumayknow";
import { GET_PEOPLE_YOU_MAY_KNOW } from "../../redux/constants/actionTypes";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class PeopleYouMayKnow extends React.Component {
  componentDidMount() {
    if(!this.props?.hamburgerDisplay){
      return;
    }
    // get drived state
    let token = JSON.parse(localStorage.getItem("userData")).token;
    this.props.fetchPeopleYouMayKnow(GET_PEOPLE_YOU_MAY_KNOW, token).then((res) => {
      if (res.val == false) {
        this.setState({ error: true, message: res.message });
      }
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div
        id="People You May Know"
        style={{
          scrollMargin: 120,
        }}
      >
        <IconTitle title={t("profile.peopleYouMayKnow")} img="/Image/apps/profile-resume-edit-view/Chalkmate_Peopleyoumayknow.svg" />
        <br />

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          {this.props.peopleYouMayKnowContent.map((item, idx) => {
            return (
              <div key={idx} className={`${styles.col} ${styles.interestIndividual}`}>
                <div className="hovme" style={{ display: "flex", marginBottom: "10px" ,justifyContent: "space-between" }}>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div>
                      <img src={item.profile_pic} height="35px" width="35px" style={{ borderRadius: 40, margin: "5px 10px 5px 10px" }} />
                    </div>
                    <div className={styles.mar0} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <p className={`${styles.hovmep} ${styles.section_title}`}>{item.name}</p>
                      <p className={styles.followers}>{item.designation}</p>
                      <p className={styles.followers}>{item.organisation}</p>
                    </div>
                  </div>

                  <FollowButton id={item.id} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  //U can destructure here to get only the required attributes from the state
  return {
    peopleYouMayKnowContent: state.profile?.peopleYouMayKnow || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPeopleYouMayKnow: (action, token) => dispatch(fetchPeopleYouMayKnow(action, token)),
  };
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PeopleYouMayKnow)));
