import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { ModalWithoutHeader } from "../shared/ui/Modal/Modal";
import styles from "./css/premium.module.css";
// import Diamond from './Image/DashboardImages/premiummodImages/diamond.svg';

class premiumModal extends Component {
  render() {
    return (
      <div>
        <ModalWithoutHeader maxWidth={this.props.maxWidth} closeHandler={this.props.closeModal} show={this.props.show}>
          <div className={styles.outerContainer}>
            <div className={styles.comingSoon}>{this.props.t("common.comingSoon")}</div>
            <span onClick={this.props.closeModal} className={styles.close}>
              X
            </span>
            <div className={styles.bottomContainer}>
              <img className={styles.diamond} src="./Image/DashboardImages/premiummodImages/diamond.svg" />
              <h2 className={styles.title}>{this.props.t("common.chalkmatePremium")}</h2>
              <p className={styles.desc}>{this.props.t("common.chalkmatePremiumDescription")}</p>
            </div>
          </div>
        </ModalWithoutHeader>
      </div>
    );
  }
}

export default withTranslation()(premiumModal)
