import { React, Component } from "react";
import styles from "../../Dashboard/Components/CSSFiles/Feed.module.css";
import ClassMessageSender from "../../Dashboard/Components/ClassMessageSender";
// import Post from "../Post";
import Post from "../../Dashboard/Components/Post";
import { connect } from "react-redux";
import { FeedsContext } from "./FeedsContext";
import ShareModal from "../../Dashboard/shareModal/ShareModal";
class Feed extends Component {
  state = {
    showShareModal: false,
    link: "",
  };

  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };
  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };
  render() {
    return (
      <div className={styles.feed}>
        {/* <ShareModal  show={true} shareLink={`${window.location.origin}/post/`} /> */}
        <ShareModal
          closeModal={this.shareModalCloser}
          show={this.state.showShareModal}
          shareLink={`${window.location.origin}/post/${this.state.link}`}
        />

        <ClassMessageSender routeType={this.props.routeType} />
        {this.context?.state?.posts?.map((post) => (
          <Post
            name={post?.post_owner_profile?.name}
            follower={post?.post_owner_profile?.connections}
            timestamp={post.date_and_time}
            message={post?.description || post?.message}
            // tag = {post.tag}
            profileImage={
              post?.post_owner_profile?.profile_pic ||
              "/Image/DashImage/profile.png"
            }
            photoUrl={post?.image_url || post?.photoUrl}
            video={post?.video_url || post?.video}
            likes={post.likes}
            file_url={post.file_url}
            likeDetails={post.likedPost}
            shareLink={post.share_link}
            tags={post.tags}
            title={post.title}
            postId={post.id}
            comments={post.comments}
            // shareModalOpener={this.shareModalOpener}
            shareModalOpener={() => this.shareModalOpener(post.share_link)}
            menu={
              post?.post_owner_profile?.id == this.props?.userId ? true : false
            }
            postData={post}
            source="CLASS"
            postLanguage={post?.language_code}
            onClick={() => {}}
            postProfileId={post?.post_owner_profile?.id}
            boosted={post?.boosted}
          />
        ))}
        {this.props.samplePosts.map((post) => (
          <Post
            key={post.timestamp}
            name={post.name}
            follower={post.follower}
            timestamp={post.timestamp}
            message={post.message}
            file_url={post.file_url}
            tags={post.tags}
            title={post.title}
            likes={post.likes}
            comments={post.comments}
            profileImage={post.profilePic || "/Image/DashImage/profile.png"}
            photoUrl={post.photoUrl}
            video={post.video}
            shareModalOpener={() => this.shareModalOpener(post.share_link)}
            // shareModalOpener={this.props.shareModalOpener}
            menu={
              post?.post_owner_profile?.id == this.props?.userId ? true : false
            }
            postData={post}
            source="CLASS"
            onClick={() => {}}
            postLanguage={post?.language_code}
            postProfileId={post?.post_owner_profile?.id}
            boosted={post?.boosted}
          />
        ))}
      </div>
    );
  }
}

Feed.contextType = FeedsContext;

const mapStateToProps = (state) => {
  return {
    userId: state?.profile?.profileId,
  };
};
export default connect(mapStateToProps, null)(Feed);
