import React from "react";

import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import AddIcon from "@material-ui/icons/Add";

import Modal from "../../../../../components/commons/Modal";
import AddFeatured from "./Modals/AddFeatured";
import AddFeaturedLink from "./Modals/AddFeaturedLink";
import EditFeatured from "./Modals/EditFeatured";
import AddFeaturedPost from "./Modals/AddFeaturedPost";

import IconTitle from "../../../../../components/commons/IconTitle";
import { connect } from "react-redux";

import styles from "../../../Profile.module.css";
import { withTranslation } from "react-i18next";
import { getPosts } from "../../../../../redux/actions/dashboard";

class FeaturedFeed extends React.Component {
  state = {
    edit: false,
    editLink: false,
    editFeatured: false,
    newpost: false,

    featuredContent: [
      {
        img: "/Image/apps/profile-resume-edit-view/dummy/maths.png",
        title: "Crash Course",
        desc: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.",
        islink: false,
      },

      {
        img: "/Image/apps/profile-resume-edit-view/dummy/featured1.png",
        title: "Crash Course",
        desc: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.",
        islink: false,
      },

      {
        img: "/Image/apps/profile-resume-edit-view/dummy/featured4.png",
        title: "Crash Course",
        desc: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed.",
        islink: false,
      },
    ],

    featuredImage: [
      "/Image/apps/profile-resume-edit-view/dummy/maths.png",
      "/Image/apps/profile-resume-edit-view/dummy/featured1.png",
      "/Image/apps/profile-resume-edit-view/dummy/featured4.png",
    ],
  };

  addFeaturedContent(newcontent) {
    let oldcontent = this.state.featuredContent;
    let combinedcontent = [...oldcontent, ...newcontent];
    this.setState({ featuredContent: combinedcontent });
  }

  editFeaturedContent(editedcontent) {
    this.setState({ featuredContent: editedcontent });
  }

  changeState(img) {
    let imgList = [...img, ...this.state.featuredImage];
    this.setState({ featuredImage: imgList });
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  changeEditLink(edit) {
    this.setState({ editLink: edit });
  }

  changeEditpost(edit) {
    this.setState({ newpost: edit });
  }

  changeEditFeaturedState(img) {
    this.setState({ featuredImage: img });
  }

  changeEditFeatured(editFeatured) {
    this.setState({ editFeatured: editFeatured });
  }

  scrollLeft() {
    const scrollElm = document.getElementById("featured-scroll");
    scrollElm.scrollLeft -= 200;
  }

  scrollRight() {
    const scrollElm = document.getElementById("featured-scroll");
    scrollElm.scrollLeft += 200;
  }
  componentDidMount() {
     this.props.getPosts().then((res) => {
      if (res) {
        //
        return;
      }
      this.setState({ error: true, posts: "" });
    });
  }

  render() {
    const {t} = this.props;
    return (
      <div className={styles.featured_div}>
        <div className={styles.profile_title}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 20px 0px 0px",
              alignItems: "center",
            }}
          >
            <IconTitle title={t("profile.featured")} img="/Image/Chalkmate_Featured.svg" />

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginRight: 20 }}>
                <div
                  style={{
                    background: "#E5E5E5 0% 0% no-repeat padding-box",
                    borderRadius: 30,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 15,
                    cursor: "pointer",
                  }}
                  onClick={() => this.scrollLeft()}
                >
                  <KeyboardArrowLeftIcon style={{ width: 22, height: 22 }} />
                </div>
                <div
                  onClick={() => this.scrollRight()}
                  style={{ cursor: "pointer", backgroundColor: "#E5E5E5", borderRadius: 30, display: "flex", alignItems: "center", justifyContent: "center", marginRight: 15 }}
                >
                  <KeyboardArrowRightIcon style={{ width: 22, height: 22 }} />
                </div>
                {/* <div className={styles.dropdown_featured}>

                                    <div
                                        className={styles.dropbtn_featured}
                                        style={{ backgroundColor: '#E5E5E5', borderRadius: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}

                                    >
                                        <AddIcon style={{ width: 22, height: 22 }} />
                                    </div>

                                    <div className={styles.dropdown_content_featured}>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this.setState({ title: 'Add Featured Post', edit: true })
                                                document.body.style.overflow = 'hidden'
                                            }}
                                        >Select Post</a>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this.setState({ title: 'Add Featured Post', editLink: true })
                                                document.body.style.overflow = 'hidden'
                                            }}
                                        >Add Link</a>
                                        <a
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                this.setState({ title: 'Add Featured Post', newpost: true })
                                                document.body.style.overflow = 'hidden'
                                            }}
                                        >Add Media</a>
                                    </div>
                                </div> */}
              </div>

              {/* <div
                                onClick={() => {
                                    this.setState({ editFeatured: true })
                                    document.body.style.overflow = 'hidden'
                                }}
                            >

                                <img src='/Image/Chalkmate_Edit.svg' style={{ height: 20, cursor: 'pointer' }} />
                            </div> */}
            </div>
          </div>
        </div>
        <br />

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            padding: 10,
          }}
        >
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <div id="featured-scroll" className={`${styles.scroll_div} ${styles.featured_scroll}`} style={{ display: "flex", padding: 0, overflow: "scroll", maxWidth: 600 }}>
              {this.props.posts.slice(0, 6).map((item, idx) => {
                if (item.islink) {
                  return (
                    <a
                      href={item.desc.match(new RegExp("^(http|https)://www.", "i")) ? item.desc : "https://www." + item.desc}
                      target="_blank"
                      key={idx}
                      style={{ margin: 5, textDecoration: "none", color: "#000" }}
                    >
                      <div>
                        <img className={styles.card_img_top} src={item.img} alt="Card image cap" style={{ height: 128, width: "250px" }} />
                        <div style={{ margin: "10px 0px 10px 0px" }}>
                          <p className={styles.section_title}>{item.title}</p>
                          <p className={styles.featured_desc}>{item.desc}</p>
                        </div>
                      </div>
                    </a>
                  );
                } else {
                  if (item.image_url != null) {
                    return (
                      <div key={idx} style={{ margin: 5 }}>
                        <div>
                          <img
                            className={styles.card_img_top}
                            src={item.image_url ?? "/Image/apps/profile-resume-edit-view/dummy/featured1.png"}
                            alt="Card image cap"
                            style={{ height: 128, width: "250px" }}
                          />
                          <div style={{ margin: "10px 0px 10px 0px" }}>
                            <p className={styles.section_title}>{item.title}</p>
                            <p className={styles.featured_desc}>{item.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  }
                }
              })}

              {/* <CorouselFeatured content={this.state.featuredImage} /> */}
            </div>
          </div>
        </div>
        {this.state.edit ? (
          <Modal
            card={
              <AddFeatured
                title="Select Featured Post"
                changeState={this.changeState.bind(this)}
                addFeaturedContent={this.addFeaturedContent.bind(this)}
                changeEdit={this.changeEdit.bind(this)}
              />
            }
          />
        ) : null}

        {this.state.editLink ? (
          <Modal card={<AddFeaturedLink title="Add Featured Link" addFeaturedContent={this.addFeaturedContent.bind(this)} changeEditLink={this.changeEditLink.bind(this)} />} />
        ) : null}

        {this.state.newpost ? (
          <Modal card={<AddFeaturedPost title="Add Featured Post" addFeaturedContent={this.addFeaturedContent.bind(this)} changeEditpost={this.changeEditpost.bind(this)} />} />
        ) : null}

        {this.state.editFeatured ? (
          <Modal
            card={
              <EditFeatured
                title="Edit Featured Post"
                featuredContent={this.state.featuredContent}
                editFeaturedContent={this.editFeaturedContent.bind(this)}
                featuredImage={this.state.featuredImage}
                changeEditFeatured={this.changeEditFeatured.bind(this)}
                changeEditFeaturedState={this.changeEditFeaturedState.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "",
    posts: state.dashboard.posts,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getPosts: () => dispatch(getPosts()),
  };
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FeaturedFeed));
