import {
  GET_OPPORTUNITIES_FILTER_RESULT,
  GET_DROPDOWN_CATEGORIES,
} from "../constants/actionTypes";
import cloneDeep from "lodash.clonedeep";

const defaultState = {
  dropdownCategories: {},
  filterResult: [],
};

let data = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_DROPDOWN_CATEGORIES:
      data = cloneDeep(state);
      data.dropdownCategories = action.payload;
      return data;

    case GET_OPPORTUNITIES_FILTER_RESULT:
      data = cloneDeep(state);
      data.filterResult = action.payload;
      return data;

    default:
      data = cloneDeep(state);
      return data;
  }
};
