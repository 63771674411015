import React from "react";
import styles from "../../../../Profile.module.css";

import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

export default class AddFeaturedPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      heading: "",
      desc: "",

      file: "/Image/apps/profile-resume-edit-view/default-user.png",
    };

    this.handleImageChange = this.handleImageChange.bind(this);

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  handleImageChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  ModalHeaderOnClick() {
    this.props.changeEditpost(false);
    document.body.style.overflow = "visible";
  }

  ScrollBody() {
    return (
      <div
        style={{
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            marginTop: 15,
            // height: 160,
            // width: 160,
            borderRadius: 100,
            display: "flex",
            alignItems: "center",
            // justifyContent: 'flex-end',
            marginBottom: 30,
          }}
        >
          <div style={{ width: 600, height: 300, overflow: "hidden", borderRadius: 10 }}>
            <img src={this.state.file} style={{ width: "100%", height: "auto", objectFit: "cover" }} />
          </div>

          <label
            style={{
              marginLeft: 30,
            }}
          >
            {/* upload */}
            <div
              // className={styles.btn}
              style={{
                border: "2px solid #0003",
                padding: "12px 60px",
                borderRadius: 40,
                cursor: "pointer",
              }}
            >
              <p
                // className={styles.btn_text}
                style={{
                  margin: 0,
                  fontWeight: 600,
                  textTransform: "uppercase",
                  color: "#0008",
                }}
              >
                upload
              </p>
            </div>
            <input type="file" onChange={this.handleImageChange} style={{ cursor: "pointer", display: "none" }} accept="image/png, image/jpeg, image/svg" />
          </label>
        </div>

        <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
          <p className={styles.modal_heading}>Enter Title</p>
          <input
            type="text"
            className={styles.modal_para}
            placeholder="Enter a heading"
            value={this.state.heading}
            onChange={(e) => {
              this.setState({ heading: e.target.value });
            }}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 50,
            }}
          />
          <br />
        </div>

        <p className={styles.modal_heading}>Description</p>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <textarea
            className={styles.modal_para}
            id={styles.textarea}
            cols={60}
            rows={8}
            placeholder="Add some description"
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 10,
              // marginBottom: 15
            }}
            value={this.state.desc}
            onChange={(event) => {
              this.setState({ desc: event.target.value });
            }}
          />
        </div>
      </div>
    );
  }

  SaveButtonOnClick() {
    let e = [
      {
        img: this.state.file,
        title: this.state.heading,
        desc: this.state.desc,
        islink: false,
      },
    ];

    this.props.addFeaturedContent(e);
    this.props.changeEditpost(false);
    document.body.style.overflow = "visible";
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.title} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />
      </div>
    );
  }
}
