import React, { useEffect, useState } from "react";
import styles from "./EnterpriseDashboard.module.css";
import { useTranslation } from "react-i18next";
import {
  AppstoreOutlined,
  CalendarOutlined,
  LinkOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Divider, Menu, Switch } from "antd";
import EnterpriseDataTable from "./Components/EnterpriseDataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardClassGroupAssignmentQuiz,
  getDashboardFacultyStudentPost,
  getSearchResult,
  getStudentFacultySearchResult,
  getEnterpriseClassesAndGroups,
  getDashboardClassGroupPost,
  getAssignmentQuizPostSearchResult,
  deleteEnterpriseFacultyStudent,
  AddFacultyStudentToDepartment,
  activeInactiveClassGroup,
} from "../../redux/actions/enterpriseDashboard";
import debounce from "lodash.debounce";
import LineChartComponent from "./Components/LineChartComponent";
import PieChartComponent from "./Components/PieChartComponent";
import BarChartComponent from "./Components/BarChartComponent";
import EnterpriseDropDownFilter from "./Components/EnterpriseDropDownFilter";
import Post from "../Dashboard/Components/Post";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const EnterpriseDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  let departmentID = useSelector((state) => state?.profile?.dept_id) || "";
  let loggedInUserProfileId = useSelector((state) => state?.profile?.user?.profile_id) || "";

  let dashboardFactultyData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_faculty) || [];
  let dashboardStudentData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_student) || [];
  let dashboardClassData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_class) || [];
  let dashboardGroupData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_group) || [];
  let dashboardPostData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_posts) || [];

  let dashboardClassPostData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_class_post) ||
    [];
  let dashboardClassAssignmentData =
    useSelector(
      (state) => state?.enterpriseDashboard?.dashboard_class_assignment
    ) || [];
  let dashboardClassQuizData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_class_quiz) ||
    [];
  let dashboardClassCourseData =
    useSelector(
      (state) => state?.enterpriseDashboard?.dashboard_class_course
    ) || [];

  let dashboardGroupPostData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_group_post) ||
    [];
  let dashboardGroupAssignmentData =
    useSelector(
      (state) => state?.enterpriseDashboard?.dashboard_group_assignment
    ) || [];
  let dashboardGroupQuizData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_group_quiz) ||
    [];

  let dashboardDataCount =
    useSelector((state) => state?.enterpriseDashboard?.count) || 0;

  let dashboardChartData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_chart_data) ||
    {};
  let connectionsSearchResult =
    useSelector(
      (state) => state?.enterpriseDashboard?.dashboard_search_result
    ) || {};
  let dashboardClassList =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_class_list) ||
    {};
  let dashboardGroupList =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_group_list) ||
    {};

  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState("dashboard_main");

  const [searchQueryOne, setSearchQueryOne] = useState("");
  const [searchQueryTwo, setSearchQueryTwo] = useState("");
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  // selected class/group id
  const [dropdownSelection, setDropdownSelection] = useState("");
  const [dropdownResetCounter, setDropdownResetCounter] = useState(0);

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  let postFeedData =
    type === "dashboard_class_post"
      ? dashboardClassPostData
      : type === "dashboard_group_post"
      ? dashboardGroupPostData
      : [];

  const items = [
    getItem("Dashboard", "dashboard_main", <img src="/Image/EnterpriseDashboard/Dashboard option.svg" />),
    getItem("Faculty", "dashboard_faculty", <img src="/Image/EnterpriseDashboard/Faculty.svg" />),
    getItem("Students", "dashboard_student", <img src="/Image/EnterpriseDashboard/Student.svg" />),
    getItem("Classes", "dashboard_class_tab", <img src="/Image/EnterpriseDashboard/Classes.svg" />, [
      getItem("All Classes", "dashboard_class", <img src="/Image/EnterpriseDashboard/Classes.svg" />),
      getItem("Posts", "dashboard_class_post", <img src="/Image/EnterpriseDashboard/Post.svg" />),
      getItem(
        "Assignments",
        "dashboard_class_assignment",
        <img src="/Image/EnterpriseDashboard/Assignments.svg" />
      ),
      getItem("Quizzes", "dashboard_class_quiz", <img src="/Image/EnterpriseDashboard/Quizzes.svg" />),
      getItem("Courses", "dashboard_class_course", <img src="/Image/EnterpriseDashboard/Courses.svg" />),
    ]),
    getItem("Groups", "dashboard_group_tab", <img src="/Image/EnterpriseDashboard/Groups.svg" />, [
      getItem("All Groups", "dashboard_group", <img src="/Image/EnterpriseDashboard/Groups.svg" />),
      getItem("Posts", "dashboard_group_post", <img src="/Image/EnterpriseDashboard/Post.svg" />),
      // getItem(
      //   "Assignments",
      //   "dashboard_group_assignment",
      //   <CalendarOutlined />
      // ),
      // getItem("Quizzes", "dashboard_group_quiz", <CalendarOutlined />),
    ]),

    // getItem("Posts", "dashboard_post", <CalendarOutlined />),
  ];

  const barChartData = dashboardChartData["10_top_classes"]?.map((item) => {
    let data = {};
    data.name = item[1].name;
    data.assignments = item[1].asgs;
    data.quizzes = item[1].quizzes;
    return data;
  });
  const lineChartData = dashboardChartData["10_top_classes"]?.map((item) => {
    let data = {};
    data.name = item[1].name;
    data.posts = item[1].posts_count;
    return data;
  });

  useEffect(() => {
    setIsLoading(true);

    if (
      departmentID &&
      (type === "dashboard_faculty" || type === "dashboard_student")
    ) {
      dispatch(getDashboardFacultyStudentPost(type, departmentID)).then(() =>
        setIsLoading(false)
      );
    }

    if (
      (departmentID &&
        (type === "dashboard_class_assignment" ||
          type === "dashboard_class_quiz" ||
          type === "dashboard_class_course")) ||
      type === "dashboard_class" ||
      type === "dashboard_main"
    ) {
      dispatch(getDashboardClassGroupAssignmentQuiz(type, departmentID)).then(
        () => setIsLoading(false)
      );
    }

    if (
      departmentID &&
      (type === "dashboard_group_assignment" ||
        type === "dashboard_group_quiz" ||
        type === "dashboard_group")
    ) {
      dispatch(getDashboardClassGroupAssignmentQuiz(type, departmentID)).then(
        () => setIsLoading(false)
      );
    }

    if (
      departmentID &&
      (type === "dashboard_class_post" ||
        type === "dashboard_group_post" ||
        type === "dashboard_post")
    ) {
      dispatch(getDashboardClassGroupPost(type, departmentID)).then(() =>
        setIsLoading(false)
      );
    }

    setIsLoading(false);
  }, [departmentID, type]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getEnterpriseClassesAndGroups("dashboard_class", departmentID))
      .then(() =>
        dispatch(getEnterpriseClassesAndGroups("dashboard_group", departmentID))
      )
      .then(() => setIsLoading(false));
  }, [departmentID]);

  useEffect(() => {
    const handleResizeEvent = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResizeEvent);

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  const menuStyles = {
    color: "rgb(29, 29, 29)",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "1.5rem",
    textAlign: "left",
    marginBottom: "2.85rem",
  };

  const handleProfileSearch = (event) => {
    if (event?.target?.value?.trim().length > 0) {
      // setSearchQueryTwo(event?.target.value.trim());
      dispatch(getSearchResult(event?.target?.value.trim(), type === "dashboard_faculty" ? true : false));
    } else {
      // setShowSearchResult(false);
    }
  };

  const handleStudentFacultySearch = (event) => {
    dispatch(
      getStudentFacultySearchResult(type, departmentID, event?.target?.value)
    );
    // if (event?.target?.value?.trim().length > 0) {
    // setSearchQueryTwo(event?.target.value.trim());
    // } else {
    // setShowSearchResult(false);
    // }
  };

  const handleAssignmentQuizPostSearch = (event) => {
    dispatch(
      getAssignmentQuizPostSearchResult(
        type,
        departmentID,
        dropdownSelection,
        event?.target?.value
      )
    ).then(() => setIsLoading(false));
  };

  const getDashboardFilteredResult = (id) => {
    dispatch(
      getAssignmentQuizPostSearchResult(
        type,
        departmentID,
        id,
        ""
      )
    ).then(() => setIsLoading(false));

  }

  const debouncedProfileSearch = debounce(handleProfileSearch, 500);
  const debouncedAssignmentQuizPostSearch = debounce(
    handleAssignmentQuizPostSearch,
    500
  );
  const debouncedStudentFacultySearch = debounce(
    handleStudentFacultySearch,
    500
  );

  const handleOnDelete = (userId) => {
    console.log("delete this", userId, type);

    if (
      userId &&
      (type === "dashboard_faculty" || type === "dashboard_student")
    ) {
      console.log("calling deleteFunction");
      dispatch(deleteEnterpriseFacultyStudent(type, userId)).then(() =>
        dispatch(getDashboardFacultyStudentPost(type, departmentID))
      );
    }
  };
  const handleOnView = (data, name, pic) => {
    console.log("view this", data);

    if (data && type === "dashboard_class_quiz") {
      history.push({
        pathname: "/template/preview/",
        state: {
          templateId: parseInt(data.template),
          isPreview: true,
          routeType: null,
          isOwner: false,
          isEditable: false,
          classOrGroupId: parseInt(data.classes),
          isEnterprise: true,
        },
      });
    }
    if (data && type === "dashboard_class_course") {
      history.push({
        pathname: `/course/view/${data?.id}`,
      });
    }
    if (data && type === "dashboard_class_assignment") {
      history.push({
        pathname: `/dashboard/assignment/view/${data?.id}`,
      });
    }
    if (data && type === "dashboard_class") {
      history.push({
        pathname: `/class/${data}`,
      });
    }
    if (data && type === "dashboard_group") {
      history.push({
        pathname: `/group/${data}`,
      });
    }
    if (
      data &&
      (type === "dashboard_faculty" || type === "dashboard_student")
    ) {
      history.push({
        // pathname: `profile/${data}/view`,
        pathname: type === "dashboard_faculty" ? `/faculty-dashboard` : `/student-dashboard`,
        state: {
          userId: data,
          name: name,
          pic: pic
        }
      });
    }
  };
  const handleStatusChange = (classGroupId, newStatus) => {
    dispatch(activeInactiveClassGroup(classGroupId, newStatus, type)).then(() =>
      dispatch(getDashboardClassGroupAssignmentQuiz(type, departmentID))
    );
  };

  // const handleDropdownSelection = (id) => {
  //   if (
  //     type === "dashboard_class_assignment" ||
  //     type === "dashboard_class_quiz" ||
  //     type === "dashboard_class_course"
  //   ) {
  //     dispatch(getDashboardClassGroupAssignmentQuiz(type, id)).then(() =>
  //       setIsLoading(false)
  //     );
  //   }

  //   if (
  //     type === "dashboard_class_post" ||
  //     type === "dashboard_group_post" ||
  //     type === "dashboard_post"
  //   ) {
  //     dispatch(getDashboardClassGroupAssignmentQuiz(type, id)).then(() =>
  //       setIsLoading(false)
  //     );
  //   }
  // };

// ------------------- Mobile View ----------------------------

if(showHamburgerMenu){
  return (
    <div>
      <div className={styles.top_navbar}>
        <img
          src="/Image/Gradebook/menu.svg"
          alt="icon"
          className={styles.menu_icon}
          onClick={() => setShowHamburgerMenu(!showHamburgerMenu)}
        />
        <div className={styles.logo_wrapper}>
          <div>
            <img src="/Image/EnterpriseDashboard/Dashboard logo.svg" />
          </div>
          <p>Dashboard</p>
        </div>
      </div>

      <div style={{ width: "100%" }}>
        <div>
          <Menu
            onClick={(x) => {
              setSearchQueryOne("");
              setSearchQueryTwo("");
              setDropdownSelection("");
              setDropdownResetCounter((dropdownResetCounter) =>
                setDropdownResetCounter(dropdownResetCounter + 1)
              );
              setType(x.key);
              setShowHamburgerMenu(false);
            }}
            // onOpenChange={(x)=>console.log("Clicking onCH this",x)}
            style={menuStyles}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["4.1"]}
            mode="inline"
            items={items}
          />
        </div>
      </div>
    </div>
  );



}




// --------------------- Desktop View -------------------------
  return (
    <>
       <div className={styles.top_navbar}>
          <img
            src="/Image/Gradebook/menu.svg"
            alt="icon"
            className={styles.menu_icon}
            onClick={()=>setShowHamburgerMenu(!showHamburgerMenu)}
          />
          <div className={styles.logo_wrapper}>
            <div>
              <img src="/Image/EnterpriseDashboard/Dashboard logo.svg" />
            </div>
            <p>Dashboard</p>
          </div>
        </div>

      <div className={styles.main}>
        {console.log("dropdownSelection", dropdownSelection)}
        {console.log("outsidePi", dashboardChartData)}
        <div className={styles.sidebar}>
          <div className={styles.sidebar_top}>
            <img src="/Image/EnterpriseDashboard/Dashboard logo.svg" />
            {/* <PieChartIcon/> */}
            <p>{t("profile.Dashboard")}</p>
          </div>
          <div>
            <Menu
              onClick={(x) => {
                setSearchQueryOne("");
                setSearchQueryTwo("");
                setDropdownSelection("");
                setDropdownResetCounter((dropdownResetCounter) =>
                  setDropdownResetCounter(dropdownResetCounter + 1)
                );
                setType(x.key);
              }}
              // onOpenChange={(x)=>console.log("Clicking onCH this",x)}
              style={menuStyles}
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["4.1"]}
              mode="inline"
              items={items}
            />
          </div>
        </div>

        {/* ----- mobile menu header ------ */}

        {/* -------------- */}
        <div className={styles.header}>
          {console.log("theType", type, dashboardChartData)}

          <div className={styles.dashboard_main_header}>
            {type === "dashboard_main" &&
            [
              {
                count: dashboardChartData?.total_faculty_mems,
                label: "Total number of Faculties",
                id: "something_here1",
                icon: "Faculty",
              },
              {
                count: dashboardChartData?.total_student_mems,
                label: "Total number of Students",
                id: "something_here2",
                icon: "Student",
              },
              {
                count: dashboardChartData?.total_classes,
                label: "Total number of Classes",
                id: "something_here3",
                icon: "Classes",
              },
            ].map((item) => (
              <div key={item?.id} className={styles.main_header_card}>
                <div className={styles.counter}>
                  <p className={styles.count}>{item?.count || 0}</p>
                  <p className={styles.count_label}>
                    {item?.label || "Total Number of "}
                  </p>
                  <div className={styles.icon_wrapper}>
                    <img
                      className={styles.counter_icon}
                      src={`/Image/EnterpriseDashboard/${item?.icon}.svg`}
                    />
                  </div>
                </div>
              </div>
            ))}

          </div>

          {(type === "dashboard_faculty" || type === "dashboard_student") && (
            <>
              <div
                className={`${styles.header_card} ${styles.searchbar_wrapper}`}
              >
                <p className={styles.search_label}>{`Quickly Search a ${
                  type === "dashboard_faculty" ? "Faculty" : "Student"
                }`}</p>
                <div className={styles.searchbar}>
                  {/* <img
                  src="/Image/Gradebook/menu.svg"
                  alt="icon"
                  className={styles.menu_icon}
                /> */}
                  <input
                    type="text"
                    value={searchQueryOne}
                    onChange={(e) => {
                      setSearchQueryOne(e.target.value.trim());
                      debouncedStudentFacultySearch(e);
                    }}
                    placeholder={t("gradebook.searchByName") + ". . ."}
                  />

                  <img src="/Image/Gradebook/search.svg" alt="icon" />
                </div>
              </div>

              <div
                className={`${styles.header_card} ${styles.searchbar_wrapper} ${styles.add_new_faculty_student}`}
              >
                <p className={styles.search_label}>{`Add a new ${
                  type === "dashboard_faculty" ? "Faculty" : "Student"
                }`}</p>
                <div className={styles.search_dropdown_wrapper}>
                  <div className={styles.searchbar}>
                    {/* <img
                  src="/Image/Gradebook/menu.svg"
                  alt="icon"
                  className={styles.menu_icon}
                /> */}
                    <input
                      value={searchQueryTwo}
                      type="text"
                      onChange={(e) => {
                        setSearchQueryTwo(e.target.value);
                        debouncedProfileSearch(e);
                      }}
                      placeholder={t("gradebook.searchByName") + ". . ."}
                    />

                    <img src="/Image/Gradebook/search.svg" alt="icon" />
                  </div>
                  {searchQueryTwo && connectionsSearchResult?.length > 0 && (
                    <div className={styles.search_dropdown}>
                      {connectionsSearchResult?.map((item) => (
                        <div
                          style={{
                            display: "flex",
                            gap: "1rem",
                            alignItems: "center",
                            marginBottom: "0.5rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSearchQueryTwo("");
                            dispatch(
                              AddFacultyStudentToDepartment(
                                type,
                                departmentID,
                                item?.user_id
                              )
                            ).then(() =>
                              dispatch(
                                getDashboardFacultyStudentPost(
                                  type,
                                  departmentID
                                )
                              )
                            );
                          }}
                        >
                          <Avatar
                            alt={`${item?.name}`}
                            src={item?.profile_pic}
                            sx={{ width: 24, height: 24 }}
                          />
                          {`${item?.name}`}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {/* --------- dropdown and search bar header -------- */}

          {(type === "dashboard_class_assignment" ||
            type === "dashboard_class_quiz" ||
            type === "dashboard_class_post" ||
            type === "dashboard_class_course" ||
            type === "dashboard_group_post") && (
            <div className={`${styles.header_card} ${styles.flex_wrap}`}>
              {
                <div
                  className={`${styles.header_cards} ${styles.dropdown_wrapper}`}
                >
                  <p className={styles.search_label}>{`Select ${
                    type === "dashboard_group_post" ? "Group" : "Class"
                  }`}</p>
                  <EnterpriseDropDownFilter
                    resetTrigger={dropdownResetCounter}
                    options={
                      type === "dashboard_group_post"
                        ? dashboardGroupList
                        : dashboardClassList
                    }
                    getSelection={(classGroupId) => {
                      setDropdownSelection(classGroupId);
                      getDashboardFilteredResult(classGroupId);
                      setSearchQueryTwo("")
                    }
                    }
                  />
                </div>
              }

              <div
                className={`${styles.header_cards} ${styles.asg_quiz_searchbar_wrapper}`}
              >
                <p className={styles.search_label}>{`Quick Search ${
                  type === "dashboard_class_assignment"
                    ? "Assignment"
                    : type === "dashboard_class_course"
                    ? "Course"
                    : type === "dashboard_class_quiz"
                    ? "Quiz "
                    : "Post"
                }`}</p>

                <div className={styles.searchbar}>
                  {/* <img
                  src="/Image/Gradebook/menu.svg"
                  alt="icon"
                  className={styles.menu_icon}
                /> */}
                  <input
                    value={searchQueryTwo}
                    type="text"
                    onChange={(e) => {
                      setSearchQueryTwo(e.target.value);
                      debouncedAssignmentQuizPostSearch(e);
                    }}
                    placeholder={t("gradebook.searchByName") + ". . ."}
                  />

                  <img src="/Image/Gradebook/search.svg" alt="icon" />
                </div>
              </div>
            </div>
          )}

          {/* ------------------- for all classes and all groups ----------- */}
          {(type === "dashboard_class" || type === "dashboard_group") && (
            <div
              className={`${styles.header_card} ${styles.searchbar_wrapper}`}
            >
              <p className={styles.search_label}>{`Quickly Search a ${
                type === "dashboard_class" ? "Class" : "Group"
              }`}</p>
              <div className={styles.searchbar}>
                <input
                  type="text"
                  value={searchQueryOne}
                  onChange={(e) => {
                    setSearchQueryOne(e.target.value.trim());
                    debouncedStudentFacultySearch(e);
                  }}
                  placeholder={t("gradebook.searchByName") + ". . ."}
                />

                <img src="/Image/Gradebook/search.svg" alt="icon" />
              </div>
            </div>
          )}
        </div>

        {/* --------------- Center table and Feed ------------- */}

        {console.log("dashboard-faculty-data", dashboardFactultyData)}
        {console.log("dashboard-faculty-data", dashboardFactultyData)}
        {console.log("dashboard-class-post-data", dashboardClassPostData)}
        <div
          className={
            type === "dashboard_class_post" || type === "dashboard_group_post"
              ? `${styles.table} ${styles.post_feed_wrapper}`
              : styles.table
          }
        >
          {type !== "dashboard_main" &&
            type !== "dashboard_class_post" &&
            type !== "dashboard_group_post" && (
              <EnterpriseDataTable
                data={
                  type === "dashboard_faculty"
                    ? dashboardFactultyData
                    : type === "dashboard_student"
                    ? dashboardStudentData
                    : type === "dashboard_post"
                    ? dashboardPostData
                    : type === "dashboard_class"
                    ? dashboardClassData
                    : type === "dashboard_group"
                    ? dashboardGroupData
                    : type === "dashboard_class_post"
                    ? dashboardClassPostData
                    : type === "dashboard_class_assignment"
                    ? dashboardClassAssignmentData
                    : type === "dashboard_class_quiz"
                    ? dashboardClassQuizData
                    : type === "dashboard_class_course"
                    ? dashboardClassCourseData
                    : type === "dashboard_group_post"
                    ? dashboardGroupPostData
                    : type === "dashboard_group_assignment"
                    ? dashboardGroupAssignmentData
                    : type === "dashboard_group_quiz"
                    ? dashboardGroupQuizData
                    : []
                }
                type={type}
                showingSearchResult={searchQueryOne.length > 0 ? true : false}
                count={dashboardDataCount}
                onDelete={handleOnDelete}
                onView={handleOnView}
                onStatusChange={handleStatusChange}
                loggedInUserProfileId={loggedInUserProfileId}
              />
            )}

          {/* ----------------- Main Dashboard with Charts ----------- */}

          {type === "dashboard_main" && (
            <div className={styles.charts_container}>
              <div className={styles.line_chart}>
                <LineChartComponent data={lineChartData} />
              </div>
              <div className={styles.pie_chart}>
                <PieChartComponent data={dashboardChartData.modules_percent} />
              </div>
              <div className={styles.bar_chart}>
                <BarChartComponent data={barChartData} />
              </div>
            </div>
          )}

          {/* ------------------- post feed -------------------- */}

          {(type === "dashboard_class_post" ||
            type === "dashboard_group_post") && (
            <div className={styles.post_feed_container}>
              {searchQueryTwo && (
                <p className={styles.not_found}>Search Result</p>
              )}
              {postFeedData?.length > 0 ? (
                postFeedData?.map((post) => (
                  <Post
                    key={post?.id}
                    showBoostBtn={false}
                    name={post?.post_owner_profile?.name}
                    follower={post?.post_owner_profile?.connections}
                    timestamp={post?.date_and_time}
                    message={post?.description || post?.message}
                    tag={post.tags}
                    profileImage={
                      post?.post_owner_profile?.profile_pic ||
                      "./Image/DashImage/profile.png"
                    }
                    photoUrl={post?.image_url || post?.photoUrl}
                    video={post?.video_url || post?.video}
                    file_url={post.file_url}
                    likes={post.likes}
                    likeDetails={post.likedPost}
                    shareLink={post.share_link}
                    tags={post.tags}
                    postId={post.id}
                    comments={post.comments}
                    hideBottom={true}
                    // shareModalOpener={() =>
                    //   this.props.shareModalOpener(post.share_link)
                    // }
                    // menu={
                    //   post?.post_owner_profile?.id == userId
                    //     ? true
                    //     : false
                    // }
                    postData={post}
                    // source="DASHBOARD"
                    language_code={post?.language_code}
                    postLanguage={post?.language_code}
                  />
                ))
              ) : (
                <div className={styles.data_not_found_image}>
                  <img
                    className={styles.no_data_image}
                    src="/Image/EnterpriseDashboard/Empty state.svg"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EnterpriseDashboard;

// useEffect(() => {
//   const handleResizeEvent = () => {
//     setInnerWidth(window.innerWidth);
//   };

//   window.addEventListener("resize", handleResizeEvent);

//   return () => {
//     window.removeEventListener("resize", handleResizeEvent);
//   };
// }, []);
