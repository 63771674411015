import React from "react";

import AboutFeed from "./AboutFeed";
import FeaturedFeed from "./FeaturedFeed";
import InterestsFeed from "./InterestsFeed";
import ExperienceFeed from "./ExperienceFeed";
import EducationFeed from "./EducationFeed";
import LicencesFeed from "./LicencesFeed";
import VolunteerFeed from "./VolunteerFeed";
import EndorsementsFeed from "./EndorsmentsFeed";
import RecommendationsFeed from "./RecommendationsFeed";
import AchievementsFeed from "./AchievementsFeed";
import MidDpicFeed from "./MidDpicFeed";
import EditDescFeed from "./EditDescFeed";
import SocailFeed from "./SocialFeed";

import styles from "../../../Profile.module.css";
import InstituteCode from "./InstituteCode";
import PublicationFeed from "../../edit/components/PublicationFeed";
import ProjectFeed from "../../edit/components/ProjectFeed";
import ResearchFeed from "../../edit/components/ResearchFeed";
import TrainingFeed from "../../edit/components/TrainingFeed";
import { connect } from "react-redux";


class LeftFeed extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "relative",
          justifyContent: "center",
        }}
      >
        {!this.props.isPrinting && <div
          className={styles.leftbar_hamburger_view}
        >
          <div className={styles.dpic_inside_ham}>
            <MidDpicFeed props={this.props} />
          </div>
          <EditDescFeed mobileView={true} />
          <br />
          <InstituteCode />

          <SocailFeed />
          <br />
          <br />
        </div>}

        <div id="About" style={{ scrollMarginTop: 120 }}>
          <AboutFeed editpage={this.props.editpage} />
        </div>

        <br />
        <br />

        {!this.props.isPrinting && <div id="Featured" class={styles.Featured} style={{ scrollMarginTop: 120 }}>
          <FeaturedFeed editpage={this.props.editpage} />
        </div>}

        <br />
        <br />
        <div id="Interests" style={{ scrollMarginTop: 120 }}>
          <InterestsFeed editpage={this.props.editpage} />
        </div>

        <br />
        <br />

        <div id="Experience" style={{ scrollMarginTop: 120 }}>
          <ExperienceFeed editpage={this.props.editpage} />
        </div>

        <br />
        <br />

        <div id="Education" style={{ scrollMarginTop: 120 }}>
          <EducationFeed editpage={this.props.editpage} />
        </div>

        <br />
        <br />

        <div id="Licenses and Certifications" style={{ scrollMarginTop: 120 }}>
          <LicencesFeed editpage={this.props.editpage} />
        </div>

        <br />
        <br />

        <div id="Volunteer Experience" style={{ scrollMarginTop: 120 }}>
          <VolunteerFeed editpage={this.props.editpage} />
        </div>

        <br />
        <br />

        {this.props.showPublication && 
          <>
            <div id="Patents" style={{ scrollMarginTop: 120 }}>
              <PublicationFeed isEditPage={false}/>          
            </div>        
            <br />
            <br />
          </>
        }


       {this.props.showProject &&
       <>       
        <div id="Consultancy" style={{ scrollMarginTop: 120 }}>
          <ProjectFeed isEditPage={false}/>          
        </div>
        <br />
        <br />
       </>
      }


        {this.props.showResearch && 
        <>
          <div id="Research" style={{ scrollMarginTop: 120 }}>
            <ResearchFeed isEditPage={false}/>          
          </div>        
          <br />
          <br />
        </>
        }


        {this.props.showTraining && 
          <>        
            <div id="Training" style={{ scrollMarginTop: 120 }}>
              <TrainingFeed isEditPage={false}/>          
            </div>
            <br />
            <br />
          </>
        }


        <div id="Skills and endorsements" style={{ scrollMarginTop: 120 }}>
          <EndorsementsFeed editpage={this.props.editpage} />
        </div>

        <br />
        <br />

        {/* <div id="Recommendations" style={{ scrollMarginTop: 120 }}>

                    <RecommendationsFeed />
                </div>

                <br />
                <br /> */}

        <div id="Achievements" style={{ scrollMarginTop: 120 }}>
          <AchievementsFeed editpage={this.props.editpage} />
        </div>

        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showPublication: state?.profile?.publications_visibilityView,
    showTraining: state?.profile?.trainingAndconference_visibilityView,
    showProject: state?.profile?.project_visibilityView,
    showResearch: state?.profile?.research_visibilityView,
    showOTW: state.profile?.open_to_work_view?.visibility || false, 
    profilePicUrlview: state.profile.userProfilePicView || "/Image/apps/profile-resume-edit-view/default-user.png",
  }
}

export default connect(mapStateToProps)(LeftFeed)