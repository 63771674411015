import reactDom from "react-dom";
import React from "react";
import { withRouter } from "react-router";
import styles from "../../apps/Profile/Profile.module.css";
import { Link } from "react-router-dom";

import IconTitle from "./IconTitle";

class FollowButton extends React.Component {
  constructor() {
    super();

    this.state = {
      img: "./Image/DashImage/live-session.svg",
    };
  }

  render() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          onClick={() => {
            this.props.history.push(`/profile/${this.props.id}`);
          }}
          className={styles.hov}
          style={{ height: 35, width: 35, borderRadius: 100, border: "1px solid #ED1E24", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
        >
          <p className={styles.pilus} style={{ margin: 0, fontSize: 28 }}>
            +
          </p>
        </div>
      </div>
    );
  }
}

export default withRouter(FollowButton);
