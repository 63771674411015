import React from "react";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Languages extends React.Component {
  bgcolor = ["#9C27B0","#ED1E24","#FFC107","#0C1B7A","#6564db", "#23e6e8", "#236ae8", "#ffaf02", "#fd0275"];

  render() {
    const {t} = this.props;
    return (
      <div
        id="Tags"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <IconTitle title={t("profile.languages")} img="/Image/apps/profile-resume-edit-view/language.svg" />
        <br />

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.props.langContent?.split(",").map((item, idx) => {
                return (
                  <>
                  {item != "" ?
                  <li key={idx} className={styles.langList}>
                    <hr className={styles.langHr} style={{ backgroundColor: this.bgcolor[idx], borderColor: this.bgcolor[idx]}}/>
                    {item}
                  </li>: 
                  null
                  }
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    langContent: state?.profile?.language_knownView || "",
  };
}

export default withTranslation()(connect(mapStateToProps, null)(Languages));
