import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getClasses } from "../../../redux/actions/classes";
import styles from "./CSSFiles/SidebarClass.module.css";
import { Link } from "react-router-dom";
import {withTranslation} from "react-i18next"

class SidebarClass extends Component {
  componentDidMount = () => {
    this.props.getClasses();
  };

  getSubjectDetails = (subjectId) => {
    let { history } = this.props;

    history.push({
      pathname: `/class/${subjectId}`,
      state: { subjectId: subjectId },
    });
  };

  sortfunction = (myClass) => {
    myClass.sort(function (a, b) {
      return b.id - a.id;
    });
  };
  render() {
    return (
      <div>
        {this.sortfunction(this.props.myClasses)}
        {this.props.myClasses.length != 0 ? (
          this.props.myClasses.map((data, index) => {
            if (index < 3) {
              return (
                <>
                  <div className={styles.sidebarClass} onClick={() => this.getSubjectDetails(data.id)}>
                    <div className={data.coverImg ? styles.classImage : styles.classImageHover}>
                      <img className={styles.classimg} src={data.coverImg || "/Image/DashImage/class-cover.png"} />
                    </div>
                    <div className={styles.classPara}>
                      <p style={{fontSize: "16px", fontWeight: "600", color: "black"}}>{data.name}</p>
                      <div style={{textAlign: "left", padding: "2px 0 0 0", fontSize: "14px", fontWeight: "300"}}>{data.grade}</div>
                      <div style={{textAlign: "left", padding: "2px 0 0 0", fontSize: "14px", fontWeight: "300"}}>{data.class_owner_name}</div>
                    </div>
                  </div>
                </>
              );
            }
          })
        ) : (
          <div className={styles.classText}>{this.props.t("dashboard.noClassSidebar")}</div>
        )}

        {this.props.myClasses.length > 3 ? (
          <div className={styles.sidebarClass}>
            <div className={styles.sidebar_stat1}>
              <Link to="/classes" style={{ textDecoration: "none", color: "#ED1E24" }}>
                {this.props.t("dashboard.allClasses")}
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user || "",
    myClasses:
      state?.classes?.classes?.map((each_class) => {
        return {
          name: each_class.name,
          id: each_class.id,
          coverImg: each_class.cover_image,
          class_owner_name: each_class.class_owner_name,
          grade: each_class.grade
        };
      }) || [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getClasses: () => dispatch(getClasses()),
  };
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(SidebarClass)));
