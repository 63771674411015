import React, { useEffect } from "react";
import styles from "./UpcomingAssignmentsComponent.module.css";
import IconTitle from "../../../components/commons/IconTitle";
import UpcomingAssignmentList from "./UpcomingAssignmentList";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UpcomingAssignmentsComponent = (props) => {
  const history = useHistory();
  const { t } = useTranslation();

  const assignments = useSelector((state)=> state?.assignment.assignments || []);
  const quizzes = useSelector((state)=> state?.enhancedQuiz?.quizzes || []);


  const whichList = props.isAsg? assignments : quizzes
  
  // const assignments = props?.allAssignmentQuiz?.filter((asgQuiz) => asgQuiz.name == "Assignment")
  // const quizzes =  props?.allAssignmentQuiz?.filter((asgQuiz) => asgQuiz.name == "Quiz")
  
  const redirectTo = () => {
    const path = props.isAsg? "assignment" : "quiz";
    history.push({
      pathname: `/${path}/list/${props?.class_id}`,
      state: {
        class_name: props?.class_name,
        class_id: props?.class_id,
        routeType: props?.routeType,
        isOwner: props?.isOwner,
        quizzes: quizzes,
        // isAsg: props?.isAsg,
      }
    })
  }
    

  return (
    <div className={styles.upcoming_container}>
      <div className={styles.top_container}>
        <IconTitle
          title={props.isAsg ? t("upcomingAssignment.upcomingAsg"): t("upcomingAssignment.upcomingQuiz")}
          img="/Image/assignment/White/Due work.svg"
        />
      </div>
      <div className={styles.card_container}>
        <div className={styles.middle_container}>
          {whichList.map(
            (asg, index) =>
              index < 3 && (
                <UpcomingAssignmentList
                  assignmentTitle={asg?.title}
                  assignmentDueDate={asg?.due_date}
                  key={asg?.id}
                />
              )
          )}
        </div>
        {whichList.length !== 0 ? (
          <div
            className={styles.bottom_container}
            onClick={redirectTo}
          >
            {props.isAsg ? t("upcomingAssignment.viewAllAssignments") : t("upcomingAssignment.viewAllQuizzes") }
          </div>
        ) : (
          <div
            className={styles.bottom_container}
            style={{ cursor: "default" }}
          >
            {/* {t("upcomingAssignment.noUpcomingAssignments")} */}
            {props.isAsg ? t("upcomingAssignment.noUpcomingAssignments") : t("upcomingAssignment.noUpcomingQuizzes") }
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingAssignmentsComponent;

// const mapStateToProps = (state) => {
  // return {
    // class_id: state?.classes?.class?.id || state?.groups?.groups?.id ||0,
    // assignments: state?.assignment.assignments || [],
    // quizzes: state?.quiz.quizzes || [],
    // allAssignmentQuiz : state?.assignment?.allAssignmentQuiz,
    // isOwner: window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.group_owner === state?.profile?.profileId) : (state?.classes?.class?.class_owner === state?.profile.profileId && state?.profile?.user?.user_subtype == "FACULTY")
  // };
// };

// export default connect(
//   mapStateToProps,
//   null
// )(UpcomingAssignmentsComponent);
