import React from "react";
import { useSelector, useDispatch } from "react-redux";
import AssignmentPost from "./AssignmentPost";
import { deleteQuiz } from "../../redux/actions/enhancedQuiz";
import styles from './css/QuizFeed.module.css'

const QuizFeed = () => {
  const storedQuizzes = useSelector((state) => state?.enhancedQuiz?.quizzes);
  const dispatch = useDispatch();
  const profileID = useSelector((state)=>state?.profile?.user?.profile_id);
  const classOwnerID = useSelector((state)=>state?.classes?.class?.class_owner);
  const isTeacher = useSelector((state)=>state?.profile?.user?.user_subtype === 'FACULTY')
  
  // const quizzes = storedQuizzes[0]?.quiz_owner?.id === profileID 
  // ? storedQuizzes 
  // : storedQuizzes?.filter((quiz)=> quiz?.user_submission != null)

  const quizzes = storedQuizzes;

  console.log(profileID, classOwnerID);

  // isOwner: window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.hasAccess) : (state?.classes?.class?.hasAccess),
        

  const hasAccess = useSelector((state) => window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.hasAccess) : (state?.classes?.class?.hasAccess))


  return (
    <div className={styles.feed_container}>
      { quizzes.length > 0 &&
        quizzes?.map((quiz) => 
          <AssignmentPost
            key={quiz?.id}
            assignment={quiz}
            isClassOwner={classOwnerID === profileID}
            // isGroupOwner={this.props?.groupOwner === this.props?.userId}
            // isOwner={quiz?.quiz_owner?.id === profileID}
            isOwner={hasAccess}
            isTeacher={isTeacher}
            type={"QUIZ"}
            handleDeleteAssignment={() => {
              dispatch(deleteQuiz(quiz.id));
            }}
            routeType={
              window.location.pathname.split("/")[1] === "group"
                ? "group"
                : "class"
            }
          />
        )}
    </div>
  );
};

export default QuizFeed;
