import React from "react";
import { withTranslation } from "react-i18next";
import styles from "../../apps/Profile/Profile.module.css";
import { CircularProgress } from "@mui/material";
class SaveButton extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className={`${styles.save_btn_wrapper}`}>
        <button className={`${styles.btn} ${styles.save_btn}`} onClick={this.props.onClick}>
          {this.props?.isLoadingBtn ? <CircularProgress sx={{color: "#ED1E24"}} size={15} /> : 
          <p className={`${styles.btn_text} ${styles.save_btn_p}`}>{this.props.btnTitle || t("common.save")}</p> }
        </button>
      </div>
    );
  }
} 
export default withTranslation()(SaveButton);
