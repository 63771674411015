import React, { useEffect, useState, useRef } from 'react'; 
import styles from "./FillInBlankContainer.module.css";
import { useTranslation } from "react-i18next";

function FillInBlankContainer(props) {
  const [question, setQuestion] = useState(props?.actionType == 'edit' ? props?.question.questionText : '');
  const {t} = useTranslation();

  // useEffect(() => {
  //   props.getQuestionInput(question)
  // }, [question]);

  function handleAddBlank() {
    props.getQuestionInput(question + " ______ ");
    setQuestion((prevQuestion) => prevQuestion + " ______ ");
  }

  return (
    <div className={styles.FillInTheBlankQuestion}>
      <input
        type="text"
        value={question}
        onChange={(e)=>{
          setQuestion(e.target.value);
          props.getQuestionInput(e.target.value);
        }}
      />
      <button onClick={handleAddBlank}>{t('enhancedQuiz.addBlank')}</button>
    </div>
  );
}

export default FillInBlankContainer;
