import React, { useReducer, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import styles from "./css/UploadCourseFilesModal.module.css";
import { useTranslation } from 'react-i18next';
import IconTitle from "../../../components/commons/IconTitle";
import Button from "../../../../src/components/assignmentAndQuiz/Button/Button";
import AttachmentContainer from "../../../../src/components/commons/assignmentAndQuiz/AttachmentContainer";
import { isValidUrl, validateFileSize } from "../../../redux/constants/commonFunctions";
import { handleErrorMessage } from "../../../redux/actions/classes";
import PublishCourseModal from "./PublishCourseModal";

const UploadCourseFilesModal = (props) => {
  const { t } = useTranslation();
  const dispatchReduxAction = useDispatch();

  const [linkValue, setLinkValue] = useState("");

  const reducer = (state, action) => {
    switch (action.type) {
      case "ADD_FILES":
        // console.log("addMediaFilesAction", action)
        return { ...state, uploadFiles: state.uploadFiles.concat(action.newFiles) }
      case "TOGGLE_DRAG_STATE":
        return { ...state, isDragActive: !state.isDragActive }
      case "UPDATE_FILES":
        return { ...state, uploadFiles: action.newFiles }
      case "UPDATE_EXISTING_FILES":
        return { ...state, uploadExistingFiles: action.newFiles }
      case "UPDATE_EXISTING_URLS":
        return { ...state, uploadExistingUrls: action.newLinks }
      case "ADD_LINKS":
        // console.log("addLinksAction", action)
        return { ...state, uploadLinks: state.uploadLinks.concat(action.linkValue) }
      case "UPDATE_LINKS":
        return { ...state, uploadLinks: action.newLinks }
      default:
        return state;
    }
  }

  const goBackBtnFiles = props.goBackClicked === true ? props.filesInfo?.uploadFiles : [];
  const goBackBtnExistingFiles = props.goBackClicked === true ? props.filesInfo?.uploadExistingFiles : (props.courseTitleType === t("common.edit") && props.currentMediaFiles?.map((item) => item.media));
  const goBackBtnUrls = props.goBackClicked === true ? props.filesInfo?.uploadLinks : [];
  const urlsFromBackend = props.currentUrls?.map((item) => item.url);
  const [state, dispatch] = useReducer(reducer, { isDragActive: false, uploadFiles: goBackBtnFiles, uploadLinks: goBackBtnUrls, uploadExistingFiles: goBackBtnExistingFiles, uploadExistingUrls: urlsFromBackend });

  const handleDragEnterAndLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "TOGGLE_DRAG_STATE" });
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: "TOGGLE_DRAG_STATE" });

    let files = [...e.dataTransfer.files];

    // console.log("theDrop", files);
    if (files && files.length > 0) {
      const existingFiles = state.uploadFiles?.map(f => f.name) 
      files = files.filter(f => !existingFiles.includes(f.name))

      files.map(file => {
        let extension = file.name.split('.').pop().toLowerCase(); 
        switch (extension) {
          case "pdf":
          case "ppt":
          case "pptx":
          case "doc":
          case "docx":
          case "xls":
          case "xlsx":
          case "mp3":
          case "mp4":
          case "jpg":
          case "jpeg":
          case "png":
            let errorMessage = validateFileSize(file, 204800);
            if (errorMessage) {
              dispatchReduxAction(handleErrorMessage(errorMessage));
            } else {
              let newFiles = [file]
              dispatch({ type: 'ADD_FILES', newFiles });
            }           
            break;
          default:
            dispatchReduxAction(handleErrorMessage(`.${extension} ${t("courseMonetisation.filesNotSupported")}`));
        }
      })
    }
  }

  const onFileDrop = (e) => {
    // console.log(e.target.files);
    let files = [...e.target.files];
    if (files && files.length > 0) {
      const existingFiles = state.uploadFiles?.map(f => f.name)
      files = files.filter(f => !existingFiles.includes(f.name))

      files.map(file => {
        let extension = file.name.split('.').pop().toLowerCase();
        switch (extension) {
          case "pdf":
          case "ppt":
          case "pptx":
          case "doc":
          case "docx":
          case "xls":
          case "xlsx":
          case "mp3":
          case "mp4":
          case "jpg":
          case "jpeg":
          case "png":
            let errorMessage = validateFileSize(file, 204800);
            if (errorMessage) {
              dispatchReduxAction(handleErrorMessage(errorMessage));
            } else {
              let newFiles = [file]
              dispatch({ type: 'ADD_FILES', newFiles });
            }           
            break;
          default:
            dispatchReduxAction(handleErrorMessage(`.${extension} ${t("courseMonetisation.filesNotSupported")}`));
        }
      })
    }
  }

  const [deletedFiles, setDeletedFiles] = useState([]);
  const [deletedUrls, setDeletedUrls] = useState([]);
  const urlsToBeDeletedArr = useRef([]);
  const filesToBeDeletedArr = useRef([]);

  const removeSubmittedFile = (filetype, id) => {
    if (filetype == "FILE") {
      const newFiles = [...state.uploadFiles];
      newFiles.splice(state.uploadFiles?.map(file => file.name).indexOf(id), 1);
      dispatch({ type: 'UPDATE_FILES', newFiles });
    }
    else { 
      const newLinks = [...state.uploadLinks];
      newLinks.splice(state.uploadLinks?.indexOf(id), 1);
      dispatch({ type: "UPDATE_LINKS", newLinks });
    }
  };

  const removeSubmittedFileForExistingFiles = (filetype, id) => {
    if (filetype == "FILE") {
    if(props.courseTitleType === t("common.edit") && props.currentMediaFiles?.length > 0) {
      filesToBeDeletedArr.current = [...filesToBeDeletedArr.current, id];
      const filesToBeDeleted = filesToBeDeletedArr.current.map(e => (props.currentMediaFiles?.find(item => item.media === e )));
      setDeletedFiles(filesToBeDeleted.map((item) => item.id));
      const newFiles = [...state.uploadExistingFiles].map(item => item).filter(item => item !== id);
      dispatch({ type: 'UPDATE_EXISTING_FILES', newFiles });
    }}
    else{
      if(props.courseTitleType === t("common.edit") && props.currentUrls?.length > 0) {
        urlsToBeDeletedArr.current = [...urlsToBeDeletedArr.current, id];
        const urlsToBeDeleted = urlsToBeDeletedArr.current.map(e => (props.currentUrls?.find(item => item.url === e )));
        setDeletedUrls(urlsToBeDeleted.map((item) => item.id));
        const newLinks = [...state.uploadExistingUrls].map(item => item).filter(item => item !== id);
        dispatch({ type: 'UPDATE_EXISTING_URLS', newLinks });
      }
    }
  };

  const changeLink = (e) => {
    setLinkValue(e.target.value);
  };

  const addLink = () => {
    // console.log("inAddLink", linkValue)
    if (isValidUrl(linkValue)) {
      // console.log("linkDispatch")
      dispatch({ type: "ADD_LINKS", linkValue })
      setLinkValue("");

    } else {
      // console.log("linkNOTDispatch")
      dispatchReduxAction(handleErrorMessage(t("errorMessageTranslations.validUrl")));
      setLinkValue("");
    }
  };

  const [style, setStyle] = useState(styles.popupModalBox);
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);
  }
  // console.log(state.uploadExistingFiles?.length, "length");


  return (
    <>
      {showModal && (
        <PublishCourseModal
          courseId={props.courseId}
          deletedFiles={deletedFiles}
          deletedUrls={deletedUrls}
          courseTitleType={props.courseTitleType}
          handleClose={handleModal}
          courseType={props.courseType}
          courseName={props.courseName}
          coursePriceType={props.coursePriceType}
          coursePriceValue={props.coursePriceValue}
          courseSubtitle={props.courseSubtitle}
          courseDescription={props.courseDescription}
          classTags={props.classTags}
          classIds={props.classIds}
          previousClassTags={props.previousClassTags}
          thumbnailForDisplay={props.thumbnailForDisplay}
          thumbnail={props.thumbnail}
          isCourseFree={props.isCourseFree}
          isCourseFreeEdit={props.isCourseFreeEdit}
          filesInfo={state}
          currentMediaFiles={props.currentMediaFiles}
          currentUrls={props.currentUrls}
          courseView={props?.courseView}
          isActive={props?.isActive}
        />
      )}
      <div className={style}>
        <div className={styles.main_container}>
          <div className={styles.header}>
            <IconTitle
              title={`${props.courseTitleType} ${props.courseType} ${t("common.course")}`}
              img="/Image/CourseMonetisation/booklet-line-2.svg"
              notClickable={true}
              type="ASSIGNMENT"
            />

            <img
              alt=""
              src="/Image/assignment/Cross.svg"
              onClick={props.handleClose}
            />
          </div>

          <div className="styles.scroll_container">
            <div className={styles.add_attachment_text}>{t("assignment.addAttachments")}*</div>

            <div>
              <div className={!state.isDragActive ? styles.drop_area : styles.drop_area_active}
                onDrop={e => handleDrop(e)}
                onDragEnter={e => handleDragEnterAndLeave(e)}
                onDragLeave={e => handleDragEnterAndLeave(e)}
                onDragOver={e => handleDragOver(e)}>
                {/* <UploadIcon sx={{ color:"#ED1E24" }} /> */}

                <input type="file" multiple value="" onChange={onFileDrop} className={styles.drop_input}/>

                <img
                  alt=""
                  src="/Image/CreateCourse/upload.svg"
                  className={styles.upload_icon}
                />
                <div className={styles.drop_area_text}>
                  {!state.isDragActive ? <p className={styles.drag_and_drop_text}>{t("common.dragAndDrop")}
                    <span className={styles.browse_text}>{t("common.browse")}</span></p> :
                    <p className={styles.drag_active_text}>{t("common.dropHere")}</p>
                  }
                  <p className={styles.supported_files_text}>{t("common.supportedFiles")}</p>
                </div>
              </div>

              <div className={styles.file_attachments_container}>
                {state.uploadFiles?.length > 0 &&
                    (state.uploadFiles?.map((file) =>
                      <AttachmentContainer
                        assignmentFileType="FILE"
                        fileName={file?.name}
                        file={file}
                        pageType="Submission"
                        closeBtn={true}
                        removeSubmittedFile={removeSubmittedFile}
                        id={file?.name}
                        key={file?.name}
                      />
                    ))
                  }
              </div>
              { props.courseTitleType === t("common.edit") && 
                <div className={styles.file_attachments_container}>
                   {props.currentMediaFiles?.length > 0 &&
                     (state.uploadExistingFiles?.map((file) =>
                       <AttachmentContainer
                         assignmentFileType="FILE"
                         fileName={file}
                         file={file}
                         pageType="Submission"
                         closeBtn={true}
                         removeSubmittedFile={removeSubmittedFileForExistingFiles}
                         id={file}
                         key={file}
                       />
                     ))
                   }
               </div>
              }

              {/* ----- Add Links Section for Free Courses ---- */}

              {(props.courseTitleType === t("common.create") ? props.isCourseFree === 'FREE' : props.isCourseFreeEdit === 'FREE') &&
                <div className='add_link_section'>
                  <span className={styles.add_links_text}>{t("courseMonetisation.addLinks")}</span>

                  <div className={styles.add_links_input_container} >
                    <input type="text" placeholder={t("assignment.addUrl")} className={styles.input_link}
                      onChange={(e) => changeLink(e)}
                      value={linkValue}
                    />
                    <div className={styles.add_btn}>
                      <Button
                        label={t("common.add")}
                        clickHandler={addLink}
                      // disable = {}
                      />
                    </div>

                  </div>

                </div>}
              <div className={styles.file_attachments_container}>
               {state.uploadLinks?.length > 0 &&
                  (state.uploadLinks?.map((link) =>
                    <AttachmentContainer
                      assignmentFileType="LINK"
                      linkName={link}
                      link={link}
                      pageType="Submission"
                      removeSubmittedFile={removeSubmittedFile}
                      closeBtn={true}
                      id={link}
                      key={link}
                    />
                ))} 
              </div>
              { props.courseTitleType === t("common.edit") && <div className={styles.file_attachments_container}> {props.currentUrls?.map.length > 0 &&
                  (state.uploadExistingUrls?.map((link) =>
                    <AttachmentContainer
                      assignmentFileType="LINK"
                      linkName={link}
                      link={link}
                      pageType="Submission"
                      removeSubmittedFile={removeSubmittedFileForExistingFiles}
                      closeBtn={true}
                      id={link}
                      key={link}
                    />
                ))} </div> 
              }

              <div className={styles.review_btn} onClick={() => {
                (props.courseTitleType === t("common.create") ? (state.uploadFiles?.length + state.uploadLinks?.length) : (state.uploadFiles?.length + state.uploadLinks?.length + state.uploadExistingFiles?.length + state.uploadExistingUrls?.length)) > 0 && handleModal();
                (props.courseTitleType === t("common.create") ? (state.uploadFiles?.length + state.uploadLinks?.length) : (state.uploadFiles?.length + state.uploadLinks?.length + state.uploadExistingFiles?.length + state.uploadExistingUrls?.length)) > 0 && setStyle(styles.hidePopupModalBox);
              }}>
                <Button
                  type="solid"
                  label={t("common.review")}
                  disable = { props.courseTitleType === t("common.create") ? (!(state.uploadFiles?.length + state.uploadLinks?.length) > 0 ? true :false) : (!(state.uploadFiles?.length + state.uploadLinks?.length + state.uploadExistingFiles?.length + state.uploadExistingUrls?.length) > 0 ? true :false) }
                />
              </div>

            </div>

          </div>

        </div>
      </div>
    </>
  )
}

export default UploadCourseFilesModal;