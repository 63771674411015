import React from "react";
import TemplateFeedPost from "./Components/TemplateFeedPost";
import styles from "./css/QuizTemplateFeed.module.css";
import { useSelector } from "react-redux";

const QuizTemplateFeed = () => {
  const profileId = useSelector((state) => state?.profile?.profileId) || 0;
  const quizTemplates =
    useSelector((state) => state?.enhancedQuiz?.quizTemplates) ?? [];
  
  const routeType = window.location.pathname.split('/')[1] 

  console.log("inFeed", quizTemplates, quizTemplates?.length);


  const hasAccess = useSelector((state) => window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.hasAccess) : (state?.classes?.class?.hasAccess))

  return (
    <div className={styles.feed_container}>
      {quizTemplates?.length > 0 &&
        quizTemplates?.map((template) => (
          <TemplateFeedPost
            key={template.id}
            template={template}
            // isOwner={template?.temp_owner?.id == profileId}
            isOwner={hasAccess}
            routeType={routeType}
            isEditable={template.is_editable}
          />
        ))}
    </div>
  );
};

export default QuizTemplateFeed;
