import React from "react";
import styles from "./CSSFiles/InputOption.module.css";

function InputOption({ Icon, title, color, dontShrink, cursor }) {
  return (
    // <div className={styles.inputOption} style={{cursor: "not-allowed"}}>
    <div className={styles.inputOption} style={{cursor: cursor}}>
      <Icon style={{ color: color }} />
      <h4 className={dontShrink ? null : styles.text}>{title}</h4>
    </div>
  );
}

export default InputOption;
