import React from "react";
import styles from "./OpenToWorkConfirmationModal.module.css";
import ButtonMUI from "@mui/material/Button";
import { useTranslation } from "react-i18next";

const OpenToWorkConfirmationModal = (props) => {
  const { t } = useTranslation();

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    // marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
  };
  return (
    <div className={styles.main}>
     <img src="/Image/lecturePlan/error_outline.svg" />
     <span className={styles.question_text}>{t("openToWork.deleteJob")}</span>
      <div className={styles.btn_div}>
        <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={props.onCancel}
          sx={styleButtonMUI}
        >
          <span className={styles.btn_label}>{ t("common.cancel")}</span>
        </ButtonMUI>
        <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={props.onDelete}
          sx={styleButtonMUI}
        >
          <span className={styles.btn_label}>{t("common.delete")}</span>
        </ButtonMUI>
      </div>
    </div>
  );
};

export default OpenToWorkConfirmationModal;
