import React from "react";
import CorouselFeed from "../../../../../components/commons/CorouselFeed";
import PeopleYouMayKnow from "../../../../../components/commons/PeopleYouMayKnowFeed";
import UpskillingFeed from "../../../../../components/commons/UpskillingFeed";
// import {} from '../'
import Languages from "../components/Languages";
import styles from "../../../Profile.module.css";
import BoostProfileSection from "./BoostProfileSection";

export default class RightFeed extends React.Component {
  corouselContent = [
    {
      img: "/Image/adds/ad1.png",
      href: "https://www.shardauniversity.uz/",
    },

    {
      img: "/Image/adds/ad2.png",
      href: "https://www.khanacademy.org/",
    },

    {
      img: "/Image/adds/ad3.png",
      href: "https://www.coursera.org/in",
    },

    {
      img: "/Image/adds/ad4.png",
      href: "http://skills.sharda.ac.in/",
    },
  ];

  render() {
    return (
      <div>
        <div class={styles.rightSide}>
          <CorouselFeed corouselContent={this.corouselContent} />

          <br />

          <BoostProfileSection hamburgerDisplay={this.props.hamburgerDisplay} viewMyPosts={this.props.viewMyPosts} closeHamburgerMenu={this.props.closeHamburgerMenu}/>

          <PeopleYouMayKnow hamburgerDisplay={this.props?.hamburgerDisplay}/>

          <br />
          <br />

          <UpskillingFeed />
          <br />
          <br />

          <Languages />
        </div>
      </div>
    );
  }
}
