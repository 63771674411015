import React, { Component } from "react";
import styles from "./css/AgendaBottomContainer.module.css";
import DropBox from "./DropBox";

export default class AgendaBottomContainer extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.innerBox}>
          <div className={styles.uploadBox} style={{ position: "relative", cursor: "pointer" }}>
            <input onChange={this.props.fileInput} type="file" accept="image/*" className={styles.fileUpload} />
            <img src="/Image/DashboardImages/postModImages/photo.svg" alt="Photos" className={styles.photo} />
          </div>
          <div className={styles.uploadBox} style={{ position: "relative" }}>
            <input onChange={this.props.videoInput} type="file" accept="video/mp4,video/x-m4v,video/*" className={styles.fileUpload} />
            <img src="/Image/DashboardImages/postModImages/video.svg" alt="Video" className={styles.photo} />
          </div>
        </div>
      </div>
    );
  }
}

//filter: invert(53%) sepia(66%) saturate(962%) hue-rotate(195deg) brightness(99%) contrast(87%)
