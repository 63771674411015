import React from "react";
// import Add from './Image/DashboardImages/postModImages/add.svg'
// import Close from './Image/DashboardImages/postModImages/cross.svg'



export default function TopBar(props) {

  const topBarStyle = {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    padding: "14px 16px",
    background: "#fff",
    borderRadius: "14px 14px 0px 0px",
    // borderBottom: "1px solid #eee",
    borderBottom: `1px solid ${ props.borderColor ? props.borderColor : "#eee"}`,
    position: "sticky",
  };
  
  return (
    <div style={topBarStyle}>
      <p style={{ color: "#000", fontSize: '1.125rem' , fontFamily: "Montserrat", letterSpacing: "0px", color: "#000000", alignItems: "center", display: "flex", fontWeight: 600 }}>
        <img style={{ paddingRight: "10px" }} src={props.icon ?? "/Image/DashboardImages/postModImages/add.svg"} alt="Add" />
        {props.title}
      </p>
      <button onClick={props.close} style={{ position: "relative", cursor: "pointer", backgroundColor: "#fff", borderRadius: "100%", border: "none" }}>
        <img src="/Image/DashboardImages/postModImages/cross.svg" alt="X" />
      </button>
    </div>
  );
}
