import React, { useState, useEffect } from "react";
import styles from "./css/IndividualQuizGradebook.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import {
  CircularProgress,
} from "@mui/material";
import {
  downloadGradebook,
  getAsgQuizResults,
  downloadIndividualAsgQuizGradebook
} from "../../redux/actions/gradebook";
import { produce } from "immer";
import IconTitle from "../../components/commons/IconTitle";
import StudentNameComponent from "./Components/StudentNameComponent";
import ButtonMenuComponent from "./Components/ButtonMenuComponent";
import QuizGradebookTable from "./Components/QuizGradebookTable";
import { formatDate } from "../../redux/constants/commonFunctions";

const IndividualQuizGradebook = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const routeType = location?.state?.routeType || "class";
  // const classOrGroupName = useSelector((state) =>
  //   routeType === "class"
  //     ? state?.classes?.class?.name
  //     : state?.groups?.group?.name
  // );
  const classOrGroupName = location?.state?.classOrGroupName;

  const classOrGroupId = parseInt(location?.state?.classOrGroupId);
  const profileId = parseInt(location?.state?.profileId);
  const profileData = location?.state?.profileData;
  const quiz = location?.state?.asgQuiz;




  const printHandler = () => {
    setIsPrinting(true);
    console.log("Print this", classOrGroupId, profileId)
    if(quiz?.id){
      dispatch(downloadIndividualAsgQuizGradebook(parseInt(quiz?.id), false))
      .then(()=>setIsPrinting(false));
    }
  }
  
  

  if (isLoading) {
    return (
      <div className={styles.loader_style}>
        <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
      </div>
    );
  } else {
    return (
      <div className={styles.main_container}>
        {console.log("IndQuizProfile", profileData)}
        {console.log("IndQuiz", quiz)}
        <h5
          className={styles.back_btn}
          onClick={() => {
            if (classOrGroupId) {
              history.push({
                pathname: `/${routeType}/${classOrGroupId}`,
              });
            } else {
              history.push({
                pathname: `/user`,
              });
            }
          }}
        >
          <img src="/Image/assignment/back_arrow.svg" alt="" />
          &nbsp; &nbsp;
          {classOrGroupName ?? t("courseMonetisation.goBackBtn")}
        </h5>

        <div className={styles.gradebook_outer_container}>
          <IconTitle
            title={t("gradebook.gradebook")}
            img="/Image/Gradebook/star-white2.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />

          <div className={styles.gradebook_inner_container}>
            <div className={styles.header}>
              <StudentNameComponent
                studentName={profileData?.name}
                profilePic={profileData?.profile_pic}
                classOrGroupName={classOrGroupName}
              />

              <div className={styles.quiz_info}>
                <p>{t("quiz.quiz")}: {quiz?.quiz?.title}</p>
                <p>{t("gradebook.totalQuestions")}: {quiz?.quiz?.template?.questions?.length}</p>
                <p>{t("viewAssignment.submittedOn")} {quiz?.is_submitted
                        ? formatDate(quiz?.submission_date)
                        : "—"}</p>
              </div>

              <div className={styles.avg_score_container}>
                <p className={styles.label}>{t("gradebook.averageScore")}: </p>
                <p className={styles.avgScore}>{`${
                  quiz?.percentage || 0
                }%`}</p>
              </div>

              <ButtonMenuComponent onPrint={printHandler} isPrinting={isPrinting}/>

              

            </div>

            {/* --- Table --- */}            
            {<QuizGradebookTable
              quiz={quiz}
            />}
          </div>
        </div>
      </div>
    );
  }
};

export default IndividualQuizGradebook;