import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../apps/Profile/Profile.module.css";
// import { HeaderContext } from '../../apps/Profile/contexts/HeaderProvider'

import { connect } from "react-redux";
import { fetchPeopleYouMayKnow } from '../../redux/actions/peopleyoumayknow'
import { GET_PEOPLE_YOU_MAY_KNOW, FETCH_SEARCH_RESULTS, SEARCH_PAGINATED_RESULT } from '../../redux/constants/actionTypes'
import {withTranslation} from 'react-i18next'
import { fetchSearchResultsData } from "../../redux/actions/profile";
import { CircularProgress } from "@mui/material";
// import HandleApiError from '../commons/HandleApiError'

class SearchGlobalInvitations extends React.Component {
  // city_names = ["Aberdeen", "Abilene", "Akron", "Albany", "Albuquerque", "Alexandria", "Allentown", "Amarillo", "Anaheim", "Anchorage", "Ann Arbor", "Antioch", "Apple Valley", "Appleton", "Arlington", "Arvada", "Asheville", "Athens", "Atlanta", "Atlantic City", "Augusta", "Aurora", "Austin", "Bakersfield", "Baltimore", "Barnstable", "Baton Rouge", "Beaumont"]

  // school_names = [
  //     "Global Wisdom International School", "Ratnam Concept School", "Priyadarshini High School", "Satavahana College", "Don Bosco High School", "St. Mary's English High School", "Bhavan Vidyalaya", "Yadavindra Public School", "Bastar High School", "Sainik School"
  // ]

  state = {
    invitaionEndIdex: 3,
    searchText: "",
    loading: false,
    // invitationsContent: [

    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[0]
    //     },

    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[1]
    //     },

    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[2]
    //     },

    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[3]
    //     },

    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[4]
    //     },

    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[5]
    //     },

    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[6]
    //     },
    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[7]
    //     },
    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[8]
    //     },
    //     {
    //         heading: this.generateName(),
    //         subheading: 'Wake Forest University',
    //         subsubheading: this.city_names[9]
    //     },

    // ],

    error: false,
    message: "",
  };

  // capFirst(string) {
  //     return string.charAt(0).toUpperCase() + string.slice(1);
  // }

  // getRandomInt(min, max) {
  //     return Math.floor(Math.random() * (max - min)) + min;
  // }

  // generateName() {


  //     var name = this.capFirst(name1[this.getRandomInt(0, name1.length + 1)]) + ' ' + this.capFirst(name2[this.getRandomInt(0, name2.length + 1)]);
  //     return name;

  // }

  componentDidMount () {
      // let token = JSON.parse(localStorage.getItem("userData")).token
      // this.props.fetchPeopleYouMayKnow(FETCH_SEARCH_RESULTS, token).then(res => {
      //     if (res.val == false) {
      //         this.setState({ error: true, message: res.message })
      //     }
      // })
      window.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }
  paginatedSearchResults = () => {
    this.setState({loading: true})
    this.props.fetchSearchResultsData(SEARCH_PAGINATED_RESULT, this.props.nextSearch).then(() => {
      this.setState({loading: false})
    })
  }

  onScroll = () => {
    if (!this.state.loading && window.innerHeight + window.scrollY >= (document.body.scrollHeight)-20) {
      this.paginatedSearchResults();
    }
  }


  render() {
    return (
      <div className={`${styles.search_feed}`}>
        {this.props?.searchResultsContent?.length == 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "70vh"
            }}
          >
            <img
              src={"/Image/apps/profile-resume-edit-view/no_result.png"}
              className={styles.noResult}
            />
            <div style={{fontSize: "24px", fontWeight: "700", margin: "40px 0px", color: "#ED1E24"}}>No such profile found!</div>
          </div>
        ) : (
          <>
            <div
              className={styles.profile_title}
              // style={{ marginBottom: 10 }}
            >
              <div
                style={{
                  textDecoration: "none",
                  color: "#000",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className={styles.icon_container}>
                      <img className={styles.inner_icon} src="/Image/apps/profile-resume-edit-view/about.svg" alt=" " />
                    </div>
                    <div>
                      <p className={styles.icon_text}>{this.props.t("common.searchResults")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.invitation_content}>
              <div
                style={
                  {
                    // borderBottom: '1px solid #5555551A'
                  }
                }
              >
                {
                  // this.props.searchResultsContent.slice(0, this.state.invitaionEndIdex).map((item, idx) => {
                  // this.props.searchResultsContent.length == 0 ?
                  //     <div
                  //         className={styles.inner_invitation}>

                  //         <img src={"/Image/apps/profile-resume-edit-view/no_result.png"} />
                  //     </div>
                  //     :
                  this.props?.searchResultsContent?.map((item, idx) => {
                    return (
                      <div key={idx} className={styles.inner_invitation}>
                        <div /* style={{ display: "flex" }} */ className={styles.invitationSection}>
                          <div className={`${styles.center_div} ${styles.inner_invitation_img}`}>
                            <img src={item.profile_pic} height="60px" width="60px" style={{ borderRadius: 40 }} />
                          </div>
                          <div className={styles.flex_col_cen}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <p className={styles.section_title}>
                                  {item.name}
                                  <br />
                                  <span className={styles.followers}>{item.designation}</span>
                                </p>
                              </div>
                            </div>
                            <p className={styles.followers} style={{ opacity: 0.7, color: "#555555", marginBottom: 6 }}>
                              {item.organisation}
                            </p>
                          </div>
                        </div>
                        <div className={styles.flex_sp}>
                          <div>
                            <button onClick={() => this.props.history.push(`/profile/${item.id}`)} className={`${styles.btn} ${styles.connect_btn}`}>
                              <p className={styles.btn_text}>
                                {this.props.t("header.viewProfile")}
                              </p>
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {this.state.loading ? (<div  style={{textAlign: "center"}}>

                  <CircularProgress size={30}/>
                  </div>) : null}
                
              </div>

              {/* <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}

                        onClick={() => {
                            if (this.state.invitaionEndIdex > 3) {
                                this.props.history.push('/search/invitations', {
                                    invitationsContent: this.state.invitationsContent
                                })

                            } else {
                                this.state.invitationsContent.length >= 5 ? this.setState({ invitaionEndIdex: 5 }) : this.setState({ invitaionEndIdex: this.state.invitationsContent.length })
                            }
                        }}
                    >
                        <btn style={{ fontSize: 12, fontWeight: 600, color: '#555555', margin: 10 }}>{this.state.invitaionEndIdex <= 3 ? "SHOW MORE" : `SEE ALL ${this.state.invitationsContent.length}`}</btn>
                    </div> */}
            </div>

            {/* <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} /> */}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  //U can destructure here to get only the required attributes from the state
  return {
    searchResultsContent: state.profile?.serachResults || [],
    nextSearch: state.profile?.nextSearch,
  };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPeopleYouMayKnow: (action, token) => dispatch(fetchPeopleYouMayKnow(action, token)),
        fetchSearchResultsData: (action, url) => dispatch(fetchSearchResultsData(action, url))
    }
}

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchGlobalInvitations)));
