import React from "react";
import Modal from "../../../../../components/commons/Modal";
import EditSocial from "./Modals/EditSocial";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";

import SocialHandles from "../../../../../components/commons/SocialHandles";
import { withTranslation } from "react-i18next";

class SocailFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };

    this.socialModal = this.socialModal.bind(this);
    this.addSocial = this.addSocial.bind(this);
    this.changeEdit = this.changeEdit.bind(this);
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  addSocial() {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className={styles.hov}
          style={{ height: 40, width: 40, borderRadius: 100, border: "1px solid #ED1E24", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}
          onClick={() => {
            document.body.style.overflow = "hidden";
            this.changeEdit(true);
          }}
        >
          <p className={styles.pilus} style={{ margin: 0, fontSize: 30 }}>
            +
          </p>
        </div>
      </div>
    );
  }

  socialModal() {
    const {t} = this.props;
    if (this.state.edit) {
      return <Modal card={<EditSocial title={t("profile.addSocialLinks")} text={this.state.text} changeEdit={this.changeEdit.bind(this)} />} />;
    }
    return null;
  }

  render() {
    return (
      <>
        <SocialHandles
          addSocial={this.addSocial}
          socialModal={this.socialModal}
          facebook={this.props.facebook}
          linkedin={this.props.linkedin}
          twitter={this.props.twitter}
          instagaram={this.props.instagaram}
          medium={this.props.medium}
        />
      </>
    );
  }
}
function mapStateToProps(state) {
  // if( state.profile.socialLinks != null){
  //     return {socialLinksId: state.profile.socialLinks.id}
  // }
  return {
    facebook: state.profile?.socialLinks?.facebook || "",
    linkedin: state.profile?.socialLinks?.linkedin || "",
    twitter: state.profile?.socialLinks?.twitter || "",
    instagaram: state.profile?.socialLinks?.instagaram || "",
    medium: state.profile?.socialLinks?.medium || "",
    socialLinks: state.profile.socialLinks,

    socialLinksId: state.profile?.socialLinks?.id || "",
  };
}
export default withTranslation()(connect(mapStateToProps, null)(SocailFeed));
