import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./groups";

export const subscribeMember = (data1) => {
  return async (dispatch) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_BASE_URL}api/v1/subscribe/`,
      data: { email: data1 },
    })
      .then((response) => {})
      .catch(async (error) => {
        // const errorMsg = error?.response?.data?.error?.detail || error?.response?.data?.error || "Network Error";
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};
