import React, { useState } from "react";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import SaveButton from "../../../../../../components/commons/SaveButton";
import styles from "./AddProjectModal.module.css";
import { useTranslation } from "react-i18next";
import {
  isValidUrl,
  validateFileSize,
} from "../../../../../../redux/constants/commonFunctions";
import { handleSuccessMessage } from "../../../../../../redux/actions/success";
import { handleErrorMessage } from "../../../../../../redux/actions/classes";
import { useDispatch, useSelector } from "react-redux";
import {
  addPublication,
  addTraining,
  addResearch,
  addProject,
  updateProject,
} from "../../../../../../redux/actions/profile";

const AddProjectModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profileId = useSelector((state) => state?.profile?.profileId);

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const [thumbnail, setThumbnail] = useState( props.image ?? "/Image/apps/profile-resume-edit-view/default-certificate.png");
  const [title, setTitle] = useState(props.isEditing ? props.title : "");
  const [startDate, setStartDate] = useState(props.isEditing ? props.startDate : "");
  const [endDate, setEndDate] = useState(props.isEditing ? props.endDate : "");
  const [description, setDescription] = useState(props.isEditing ? props.description : "");


  const handleThumbnail = (e) => {
    let errorMessage = validateFileSize(e.target.files[0], 5120);
    let extension = e.target.files[0]?.name?.split(".").pop().toLowerCase();

    if (!["png", "jpeg", "jpg"].includes(extension)) {
      errorMessage = `.${extension} ${t(
        "courseMonetisation.filesNotSupported"
      )}`;
    }

    if (errorMessage) {
      dispatch(handleErrorMessage(errorMessage));
    } else {
      setThumbnail(e.target.files[0]);
      // setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSave = () => {
    if (title === "" || startDate === "" || endDate === "") {
      dispatch(
        handleErrorMessage(t("errorMessageTranslations.requiredFields"))
      );
    } else {
      let data = new FormData();

      data.append("profile", profileId);
      data.append("name", title);
      data.append("description", description);
      data.append("start_date", startDate);
      data.append("end_date", endDate);

      if (typeof thumbnail === "object") {
        data.append("image", thumbnail);
      }

      console.log("finalData", data);

      if(props.isEditing){
        setIsLoadingBtn(true)
        dispatch(updateProject(data, props.targetId))
        .then(()=>setIsLoadingBtn(false))        
        .then(()=> props.onClose());
      } else {
        setIsLoadingBtn(true)
        dispatch(addProject(data))
        .then(()=>setIsLoadingBtn(false))  
        .then(() => props.onClose());
      }

    }
  };

  const innerContainer = () => {
    return (
      <div className={styles.modal_body}>
        <p className={styles.label}>{t("profile.media")}</p>
        <div className={styles.add_image_container}>
          <div className={styles.image_wrapper}>
            <img
              // src={"/Image/apps/profile-resume-edit-view/default-award.png"}
              src={
                typeof thumbnail === "string"
                  ? thumbnail
                  : URL.createObjectURL(thumbnail)
              }
              // className={styles.thumbnail_pic}
            />
          </div>
          <div className={styles.upload_button_container}>
            <p className={styles.add_image_label}>{t("profile.addImageProject")} :</p>
            <div className={styles.upload_btn}>
              <p>{t("common.upload")}</p>
              <input
                type="file"
                onChange={handleThumbnail}
                className={styles.file_upload}
                accept="image/png, image/jpeg, image/svg"
              />
            </div>
          </div>
        </div>

        <div className={styles.field_container}>
          <div className={styles.label_icon}>
            <img src="/Image/modalIcon/title.svg" />
            <p>{t("profile.title")}*</p>
          </div>
          <input
            className={styles.input_field}
            type="text"
            placeholder={t("profile.addTextHere")}
            // maxlength="50"
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
        </div>

        <div className={styles.field_container}>
          <div className={styles.label_icon}>
            <img src="/Image/modalIcon/description.svg" />
            <p>{t("common.description")}</p>
          </div>
          <textarea
            className={styles.text_area}
            placeholder={t("profile.addTextHere")}
            cols={60}
            rows={8}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className={styles.date_container}>
          <div className={styles.field_container}>
            <div className={styles.label_icon}>
              <img src="/Image/modalIcon/date.svg" />
              <p>{t("profile.StartDate")}*</p>
            </div>
            <input
              className={styles.input_field}
              type="date"
              placeholder={t("profile.addTextHere")}
              value={startDate}
              onChange={(event) => {
                setStartDate(event.target.value);
              }}
            />
          </div>

          <div className={styles.field_container}>
            <div className={styles.label_icon}>
              <img src="/Image/modalIcon/date.svg" />
              <p>{t("profile.EndDate")}*</p>
            </div>
            <input
              className={styles.input_field}
              type="date"
              placeholder={t("profile.addTextHere")}
              value={endDate}
              onChange={(event) => {
                setEndDate(event.target.value);
              }}
            />
          </div>
        </div>

      </div>
    );
  };

  return (
    <div className={styles.main}>
      {console.log("currentInfo in modal", {
        thumbnail,
        title,
        startDate,
        endDate,
        description,
        profileId,
      })}
      {console.log("isEditing", props.isEditing, props.targetId, props.title, props.description, props.startDate, props.endDate, props.image)}
      
      <ModalHeader title={props.heading} onClick={props.onClose} />
      <ScrollDivBody body={innerContainer} />
      <SaveButton onClick={isLoadingBtn ? null : handleSave} isLoadingBtn={isLoadingBtn} />
    </div>
  );
};

export default AddProjectModal;
