import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import styles from "../../apps/Profile/Profile.module.css";
import { withRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import GroupBurger from "../../apps/ClassesGroups/CreateGroup/GroupBurger";
import Logout from "../Logout";
import { connect } from "react-redux";
import { HeaderContext } from "../../apps/Profile/contexts/HeaderProvider";

import { changeCurrentLanguage, getPosts } from "../../redux/actions/dashboard";
import Referral from "../referral/referral";
import ShareModal from "../shareModal/ShareModal";
import {
  FETCH_SEARCH_RESULTS,
  GET_PROFILE_DATA_EDIT,
  GET_PROFILE_DATA_VIEW,
} from "../../redux/constants/actionTypes";
import {
  fetchProfileDataEdit,
  fetchSearchResultsData,
  fetchProfileDataView,
} from "../../redux/actions/profile";
import { fetchPeopleYouMayKnow } from "../../redux/actions/peopleyoumayknow";
import { userSubType } from "../../redux/constants/commonFunctions";
import { getUserSettings } from "../../redux/actions/zoomAction";
import i18next, { t } from "i18next";
import { withTranslation } from "react-i18next";
import LanguageButton from "../LanguageButton";

// import "../../template/home/Nav/Nav"
class Header extends Component {
  constructor(props) {
    super();

    this.state = {
      showMenu: false,
      notifyChanges: false,
      search: "",
      logout: false,
      showModal: false,
      showQrModal: false,
      showCareerModal: false,
      showReferralModal: false,
      showShareModal: false,
      link: "ccy-beag-tdz",
      path: "",
      showLangMenu: false,
      // lang: ""
      lang:
        localStorage.getItem("i18nextLng") ||
        navigator.language ||
        navigator.userLanguage,
    };

    this.handleInputChange = (e) => {
      this.setState({ search: e.target.value });
    };
    this.signOut = () => {
      this.setState({ logout: true });
    };
  }

  QRModalCloser = () => {
    this.setState({ showQrModal: false });
  };

  QRModalOpener = () => {
    this.setState({ showQrModal: true });
  };

  CareerModalCloser = () => {
    this.setState({ showCareerModal: false });
  };

  CareerModalOpener = () => {
    this.setState({ showCareerModal: true });
  };

  ReferralModalOpener = () => {
    this.setState({ showReferralModal: true });
  };

  ReferralModalCloser = () => {
    this.setState({ showReferralModal: false });
  };

  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };

  navbar = (e) => {
    if (this.state.showMenu) {
      return (
        <div className={styles.dropdown_menu}>
          <ul>
            <li>
              <div className={styles.profile}>
                <img
                  src={
                    this.props.profilePicUrl ??
                    "/Image/apps/profile-resume-edit-view/default-user.png"
                  }
                  className={styles.drop_profile}
                  style={{ width: 32, height: 32, opacity: 1 }}
                />
                <h1
                  style={{
                    fontSize: 14,
                    color: "#000000",
                    textTransform: "capitalize",
                    textAlign: "left",
                  }}
                >
                  {" "}
                  {this.props.name ??
                    this.props?.first_name + " " + this.props?.last_name ??
                    ""}{" "}
                  <br />{" "}
                  <span
                    style={{
                      fontSize: 12,
                      color: "#555555",
                      fontWeight: "normal",
                    }}
                  >
                    {this.props?.user?.user_subtype
                      ? userSubType(this.props.user?.user_subtype)
                      : "Other"}
                  </span>
                </h1>
              </div>
            </li>
            <hr className={styles.Hr2} />
            <div className={styles.menu}>
              <Link
                to={`/profile/${JSON.parse(localStorage?.userData || "{}").profile_id
                  }/edit`}
                style={{ textDecoration: "none" }}
                onClick={this.toggleMenu}
              >
                <li
                  style={{ marginTop: 6, color: "#555555", cursor: "pointer" }}
                >
                  {t("header.viewProfile")}
                </li>
              </Link>

              <hr className={styles.Hr2} />
              {/* Referral button */}
              <div className={styles.menu}>
                <div onClick={()=>{
                  this.toggleMenu();
                  this.props.showReferral();
                }}>
                  {/* <img src="/Image/header/referral.svg" className={styles.image} style={{ width: 24, height: 24 }} /> */}
                  <li
                    style={{
                      marginTop: 6,
                      color: "#555555",
                      cursor: "pointer",
                    }}
                  >
                    {t("header.referral")}
                  </li>
                </div>
              </div>

              {/* <hr className={styles.Hr2} /> */}
              <Link to={"/settings"} style={{ textDecoration: "none" }} onClick={this.toggleMenu}>
                <hr className={styles.Hr2} />
                <li
                  style={{ marginTop: 6, color: "#555555", cursor: "pointer" }}
                >
                  {t("header.settings")}
                </li>
              </Link>
              <hr className={styles.Hr2} />
              <li
                onClick={this.signOut}
                style={{ marginTop: 6, color: "#ED1E24", cursor: "pointer" }}
              >
                {t("header.signOut")}
              </li>
            </div>
          </ul>
        </div>
      );
    } else return <></>;
  };

  notification = () => {
    if (this.state.notifyChanges) {
      return (
        <div className={styles.notifications_menu}>
          <ul className="notifications_Dashboard_Dropdown">
            <li>
              <Link
                to={`/profile/${JSON.parse(localStorage?.userData || "{}").profile_id
                  }/edit`}
                style={{ textDecoration: "none" }}
              >
                <div className={styles.notification}>
                  <img
                    src="/Image/notification/userProfile.svg"
                    style={{ width: 30, height: 30, opacity: 1 }}
                  />
                  <p style={{ fontSize: 14, color: "#000000" }}>
                    {" "}
                    {/* Complete your profile */}
                    {t("dashboard.completeProfile")}
                    <br />
                    {/* <span style={{ fontSize: 12, color: "#555555", fontWeight: "normal" }}>Lorem ipsum dolor sit.</span> */}
                  </p>
                </div>
              </Link>
            </li>
            <hr className={styles.Hr2} />
            <li>
              <Link to="/video" style={{ textDecoration: "none" }}>
                <div className={styles.notification}>
                  <img
                    src="/Image/notification/videoConfrence.svg"
                    style={{ width: 30, height: 30, opacity: 1 }}
                  />
                  <p style={{ fontSize: 14, color: "#000000", marginLeft: 5 }}>
                    {t("header.startLiveSession")} <br />
                    {/* <span style={{ fontSize: 12, color: "#555555", fontWeight: "normal" }}>Lorem ipsum dolor sit.</span> */}
                  </p>
                </div>
              </Link>
            </li>
            <hr className={styles.Hr2} />
            <li>
              {/* <Link to="/search/result" style={{ textDecoration: 'none' }}> */}
              <div className={styles.notification}>
                <img
                  src="/Image/notification/searchMatch.svg"
                  style={{ width: 30, height: 30, opacity: 1 }}
                />
                <p
                  style={{
                    fontSize: 14,
                    color: "#000000",
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (
                      this.props.history.location.pathname !== "/search/results"
                    ) {
                      this.props.history.push("/search/results");
                    }
                  }}
                >
                  {t("header.searchYourMatch")}
                  <br />
                  {/* <span style={{ fontSize: 12, color: "#555555", fontWeight: "normal" }}>Lorem ipsum dolor sit.</span> */}
                </p>
              </div>
              {/* </Link> */}
            </li>
          </ul>
        </div>
      );
    } else return <></>;
  };

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
      notifyChanges:false,      
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);

    const token = JSON.parse(localStorage.getItem("userData"))?.token || "";
    if (token != "") {
      this.props.fetchProfileDataEdit(
        GET_PROFILE_DATA_EDIT,
        token,
        JSON.parse(localStorage.userData).profile_id
      );
      this.props.getUserSettings();
    }

    // this.props.getPosts().then((res) => {
    //   if (res) {
    //     //
    //     return;
    //   }
    //   this.setState({ error: true, posts: "" });
    // });
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        showMenu: false,
        notifyChanges: false,
      });
    }
  };

  idetifyChange = () => {
    this.setState({
      notifyChanges: !this.state.notifyChanges,
    });
  };

  openSidebar = () => {
    let { history } = this.props;
    if (this.props.history.location.pathname === "/user") {
      history.push("/sidebar");
    } else if (
      this.props.history.location.pathname ===
      `/group/${this.props?.history?.location?.state?.subjectId}` ||
      this.props.history.location.pathname ===
      `/class/${this.props?.history?.location?.state?.subjectId}`
    ) {
      document.getElementById("classComponent").style.display = "none";
      document.getElementById("mobileBurg").style.display = "block";
      document.querySelector(".mobileBurger").classList.toggle("showBurger");
    } else {
      document.getElementById("hamburger-view").style.display = "block";
      document.body.style.overflow = "hidden";
    }
  };

  // changeSelectedLanguage(selectedLang) {
  //   i18next.changeLanguage(selectedLang)
  //   localStorage.setItem("i18nextLng", selectedLang)
  //   this.props.changeCurrentLanguage(selectedLang)
  //   this.setState({lang: selectedLang})

  //   if( window.location.pathname.split("/")[1] == 'profile' && window.location.pathname.split("/")[3] == 'edit'){
  //     this.props.fetchProfileDataEdit(GET_PROFILE_DATA_EDIT, JSON.parse(localStorage.getItem("userData"))?.token, JSON.parse(localStorage.userData)?.profile_id);
  //   }
  //   if( window.location.pathname.split("/")[1] == 'profile' && window.location.pathname.split("/")[3] == 'view'){
  //     this.props.fetchProfileDataView(GET_PROFILE_DATA_VIEW, JSON.parse(localStorage.getItem("userData"))?.token, window.location.pathname.split("/")[2]);
  //   }
  // }

  toggleLanguageMenu() {
    if (this.state.showLangMenu == true) {
      this.setState({ showLangMenu: false });
    } else {
      this.setState({ showLangMenu: true });
    }
  }

  render() {
    let arr = window.location.href.split("/");
    let path = arr[3];
    const initLang =
      localStorage.getItem("i18nextLng") ||
      navigator.language ||
      navigator.userLanguage;
    // this.setState({lang: initLang})
    const { t } = this.props;
    return (
      <>
        {this.state.logout ? <Logout /> : null}
        <HeaderContext.Consumer>
          {(value) => {
            if (value != null) {
              const [state, setEditPage, setSearchPage, setSearchText] = value;

              return (
                <div className={styles.header}>
                  <ShareModal
                    closeModal={this.shareModalCloser}
                    show={this.state.showShareModal}
                    link={this.state.link}
                  />
                  <qrModal
                    closeModal={this.QRModalCloser}
                    show={this.state.showQrModal}
                  />
                  <carrerModal
                    closeModal={this.CareerModalCloser}
                    show={this.state.showCareerModal}
                  />
                  <Referral
                    closeModal={this.ReferralModalCloser}
                    show={this.state.showReferralModal}
                  />

                  <div className={styles.header_left}>
                    <Link to="/">
                      <img
                        src="/Image/DashImage/chalkmate-logo.svg"
                        alt="logo"
                      />
                    </Link>
                    <div className={styles.header_search}>
                      <img
                        src="/Image/DashImage/Chalkmate_Search.svg"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          const search = this.state.search;
                          setSearchText(search);
                          e.preventDefault();

                          if (this.state.search.length == 0) {
                            let token = JSON.parse(
                              localStorage.getItem("userData")
                            ).token;
                            this.props
                              .fetchSearchResultsData(
                                FETCH_SEARCH_RESULTS,
                                `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results?search=`
                              )
                              // .fetchPeopleYouMayKnow(
                              //   FETCH_SEARCH_RESULTS,
                              //   token
                              // )
                              .then((res) => {
                                if (res.val) {
                                  this.props.history.push("/search/results");
                                  return;
                                }
                                this.setState({
                                  error: true,
                                  message: res.message,
                                });
                              });
                          } else {
                            this.props
                              .fetchSearchResultsData(
                                FETCH_SEARCH_RESULTS,
                                `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results/?search=${this.state.search}`
                              )
                              .then((res) => {
                                if (res.val) {
                                  this.props.history.push("/search/results");
                                  return;
                                }
                                this.setState({
                                  error: true,
                                  message: res.message,
                                });
                              });
                          }
                        }}
                      />
                      <form
                        onSubmit={(e) => {
                          const search = this.state.search;
                          setSearchText(search);
                          e.preventDefault();

                          if (this.state.search.length == 0) {
                            let token = JSON.parse(
                              localStorage.getItem("userData")
                            ).token;
                            this.props
                              .fetchSearchResultsData(
                                FETCH_SEARCH_RESULTS,
                                `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results?search=`
                              )
                              // .fetchPeopleYouMayKnow(
                              //   FETCH_SEARCH_RESULTS,
                              //   token
                              // )
                              .then((res) => {
                                if (res.val) {
                                  this.props.history.push("/search/results");
                                  return;
                                }
                                this.setState({
                                  error: true,
                                  message: res.message,
                                });
                              });
                          } else {
                            this.props
                              .fetchSearchResultsData(
                                FETCH_SEARCH_RESULTS,
                                `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results/?search=${this.state.search}`
                              )
                              .then((res) => {
                                if (res.val) {
                                  this.props.history.push("/search/results");
                                  return;
                                }
                                this.setState({
                                  error: true,
                                  message: res.message,
                                });
                              });
                          }
                        }}
                      >
                        <input
                          className={styles.input}
                          type="text"
                          placeholder={t("common.search")}
                          value={this.state.search}
                          onChange={this.handleInputChange}
                        />
                      </form>
                      <img
                        src="/Image/DashImage/qr.svg"
                        onClick={this.props.show}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  <div className={styles.header_right}>
                    <div className={styles.header_option}>
                      <Link to="/user" style={{ textDecoration: "none" }} onClick={()=>this.setState({showMenu: false, notifyChanges:false})}>
                        <div className={styles.header_option_container}>
                          {path === "user" ? (
                            <img
                              src="/Image/header/home-active.svg"
                              className={styles.image}
                              style={{ width: 24, height: 24 }}
                            />
                          ) : (
                            <img
                              src="/Image/header/home-inactive.svg"
                              className={styles.image}
                              style={{ width: 24, height: 24 }}
                            />
                          )}
                          <div
                            className={styles.specific}
                            style={
                              path === "user"
                                ? { color: "#ED1E24" }
                                : { color: "#858585" }
                            }
                          >
                            {t("header.home")}
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className={styles.header_option}>
                      <Link to="/academics" style={{ textDecoration: "none" }} onClick={()=>this.setState({showMenu: false, notifyChanges:false})}>
                        <div className={styles.header_option_container}>
                          {path === "academics" ? (
                            <img
                              src="/Image/header/class-active.svg"
                              className={styles.image}
                              style={{ width: 24, height: 24 }}
                            />
                          ) : (
                            <img
                              src="/Image/header/class-inactive.svg"
                              className={styles.image}
                              style={{ width: 24, height: 24 }}
                            />
                          )}
                          <div
                            className={styles.specific}
                            style={
                              path === "academics" 
                                ? { color: "#ED1E24" }
                                : { color: "#858585" }
                            }
                          >
                            {t("header.academics")}
                          </div>
                        </div>
                      </Link>
                    </div>

                    {/* ----- Messages ------ */}

                    {/* <div className={styles.header_option}>
                      <Link to="/messages" style={{ textDecoration: "none" }} onClick={()=>this.setState({showMenu: false, notifyChanges:false})}>
                        <div className={styles.header_option_container}>
                          {path === "messages" ? (
                            <img
                              src="/Image/liveChat/message_red.svg"
                              className={styles.image}
                              style={{ width: 24, height: 24 }}
                            />
                          ) : (
                            <img
                              src="/Image/liveChat/message_grey.svg"
                              className={styles.image}
                              style={{ width: 24, height: 24 }}
                            />
                          )}
                          <div
                            className={styles.specific}
                            style={
                              path === "messages" 
                                ? { color: "#ED1E24" }
                                : { color: "#858585" }
                            }
                          >
                            {t("header.messages")}
                          </div>
                        </div>
                      </Link>
                    </div> */}

                    {/* { ---------- notifications ----------} */}

                    {/* <div className={styles.header_option}>
                      <div className={styles.header_option_container}>
                        <div
                          className={styles.notification_menu}
                          // onClick={this.idetifyChange}
                          onClick={()=>{
                            this.idetifyChange();
                            this.setState({showMenu: false});
                          }}
                        >
                          <img
                            src="/Image/header/notification-inactive.svg"
                            className={styles.image}
                            style={{ width: 24, height: 24 }}
                          />
                          <div className={styles.specific}>
                            {" "}
                            {t("header.notifications")}
                          </div>
                        </div>
                        <this.notification />
                      </div>
                    </div> */}

                          {/* --------- Opportunities Option --------- */}
                    <div className={styles.header_option}>
                    <Link to="/opportunities" style={{ textDecoration: "none" }} onClick={()=>this.setState({showMenu: false, notifyChanges:false})}>
                      <div className={styles.header_option_ceontainer}>
                        <div
                          className={styles.notification_menu}
                          // onClick={this.idetifyChange}
                          onClick={()=>{
                            this.idetifyChange();
                            this.setState({showMenu: false});
                          }}
                        >
                          <img
                            src="/Image/otw/opportuities-inactive.svg"
                            className={styles.image}
                            style={{ width: 24, height: 24 }}
                          />
                          <div className={styles.specific}>
                            {" "}
                            {t("openToWork.opportunities")}
                          </div>
                        </div>
                        <this.notification />
                      </div>
                      </Link>
                    </div>


                    {/* <div className={styles.header_option}>
                      <div
                        className={styles.header_option_container}
                        onClick={this.props.showcareer}
                      >
                        <img
                          src="/Image/header/career-inactive.svg"
                          className={styles.image}
                          style={{ width: 24, height: 24 }}
                        />
                        <div className={styles.specific}>
                          {t("header.career")}
                        </div>
                      </div>
                    </div> */}
                    {/* <div className={styles.header_option}>
                      <div className={styles.header_option_container} onClick={this.props.showReferral}>
                        <img src="/Image/header/referral.svg" className={styles.image} style={{ width: 24, height: 24 }} />
                        <div className={styles.specific}>Referral</div>
                      </div>
                    </div> */}
                    {/* <div className={styles.header_option}>
                      <a
                        href="https://video.chalkboard.ml/"
                        target="_bank"
                        style={{ textDecoration: "none" }}
                      >
                        <div className={styles.header_option_container}>
                          <img
                            src="/Image/header/chalkBoard.svg"
                            className={styles.image}
                            style={{ width: "25px", height: "auto" }}
                          />
                          <div className={styles.specific}>Chalkboard</div>
                        </div>
                      </a>
                    </div> */}

                    {/* translation button */}
                    <div className={styles.header_option}>
                      <div onClick={()=>this.setState({showMenu: false, notifyChanges:false})}>
                        <LanguageButton />
                      </div>
                      {/* <div className={styles.language_div_dark} onClick={() => this.toggleLanguageMenu()}>
                      <img className={styles.language_header} src="/Image/langIconGrey.png" alt="language icon"/>
                      <div className={styles.langTextDiv}>{this.state.lang == "uz" ? "o'zbek" : this.state.lang == "ru" ? "русский" : "English"}</div>
                      {
                        this.state.showLangMenu ?(  
                        <div className={styles.dropdownLanguage}>

                          <ul className={styles.dropdownList}>
                            <div>
                                <li className={this.state.lang == "en" ? styles.activeList : styles.inactiveList} onClick={() =>this.changeSelectedLanguage("en")}>English</li>
                                <hr style={{margin: "8px 0"}}/>
                                <li className={this.state.lang == "ru" ? styles.activeList : styles.inactiveList} onClick={() => this.changeSelectedLanguage("ru")}>русский</li>
                                <hr style={{margin: "8px 0"}}/>
                                <li className={this.state.lang == "uz" ? styles.activeList : styles.inactiveList} onClick={() => this.changeSelectedLanguage("uz")}>o'zbek</li>
                            </div>
                          </ul>
                        </div>
                        ):null
                      }
                    </div> */}
                    </div>
                    {/* translatin button ends */}
                    <div className={styles.profile_icon}>
                      {window.location.pathname != "/video" ? (
                        window.location.pathname != "/settings" ? (
                          window.location.pathname != "/zoomconfiguration" ? (
                            window.location.pathname != "/subscription" ? (
                              window.location.pathname != "/connections" ? (
                                window.location.pathname !=
                                  "/search/results" ? (
                                  window.location.pathname != "/classes" ? (
                                    window.location.pathname != "/groups" ? (
                                      !this.props?.hideMobileNav ? (
                                        <img
                                          src="/Image/nav_menu.jpeg"
                                          className={styles.header_nav}
                                          style={{ cursor: "pointer" }}
                                          onClick={() => this.openSidebar()}
                                        />
                                      ) : null
                                    ) : null
                                  ) : null
                                ) : null
                              ) : null
                            ) : null
                          ) : null
                        ) : null
                      ) : null}
                      <div className={styles.navbar_menu}>
                        <img
                          onClick={this.toggleMenu}
                          src={
                            this.props.profilePicUrl ??
                            "/Image/apps/profile-resume-edit-view/default-user.png"
                          }
                          className={styles.header_avatar}
                        />
                      </div>
                      <this.navbar setEditPage={setEditPage} state={state} />
                    </div>
                  </div>

                  {this.props.history.location.pathname ===
                    `/group/${this.props?.history?.location?.state?.subjectId}` ? (
                    <GroupBurger
                      routeType="Group"
                      subjectId={
                        this.props?.history?.location?.state?.subjectId
                      }
                    />
                  ) : this.props.history.location.pathname ===
                    `/class/${this.props?.history?.location?.state?.subjectId}` ? (
                    <GroupBurger
                      routeType="Class"
                      subjectId={
                        this.props?.history?.location?.state?.subjectId
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              );
            } else {
              return null;
            }
          }}
        </HeaderContext.Consumer>
      </>
    );
  }
}

// export default withRouter(Header);
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state?.profile?.user,
    first_name: state?.profile?.user?.first_name,
    last_name: state?.profile?.user?.last_name,
    profilePicUrl: state?.profile?.userProfilePic,
    designation: state?.profile?.about?.designation,
    coverPicUrl: state?.profile?.userCoverPic,
    posts: state?.dashboard?.posts,
    name: state?.profile?.name,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    // getPosts: () => dispatch(getPosts()),
    fetchProfileDataEdit: (action, token, id) =>
      dispatch(fetchProfileDataEdit(action, token, id)),
    // fetchProfileDataView: (action, token, id) => dispatch(fetchProfileDataView(action, token, id)),
    fetchSearchResultsData: (action, url) =>
      dispatch(fetchSearchResultsData(action, url)),
    fetchPeopleYouMayKnow: (action, token) =>
      dispatch(fetchPeopleYouMayKnow(action, token)),
    getUserSettings: () => dispatch(getUserSettings()),
    // changeCurrentLanguage: (lang) => dispatch(changeCurrentLanguage(lang))
  };
}
export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
);
