import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import styles from "./css/AssignmentPost.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import AttachmentContainer from "../../components/commons/assignmentAndQuiz/AttachmentContainer";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import Modal from "../../components/commons/Modal";
import ViewAssignmentDetailsModal from "./Modals/ViewAssignmentDetailsModal";
import { Link, withRouter } from "react-router-dom";
import DueDate from "../../components/assignmentAndQuiz/DueDate";
import IconTitle from "../../components/commons/IconTitle";
import AssignmentHeading from "../../components/assignmentAndQuiz/AssignmentHeading";
import { timeSince } from "../../redux/constants/commonFunctions";
import { Component } from "react";
import ReactDOM from "react-dom";
import StartQuizModal from "./Modals/StartQuizModal";
import assignment from "../../redux/reducers/assignment";
import ModalHeader from "../../components/commons/ModalHeader";
import ReactHtmlParser from 'react-html-parser'; 
class AssignmentPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewAssignmentModal: false,
      showStartQuizModal: false,
      anchorEl: null,
      open: false,
    };
  }
  componentDidMount() {
    // document.addEventListener("click", this.handleClickOutside, true);
  }
  componentWillUnmount() {
    // document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleOpenMenu = (event) => {
    this.setState({anchorEl: event.currentTarget, open: true});
  };

  handleCloseMenu = () => {
    this.setState({anchorEl: null, open: false});
  };

  handleEditAssignment = () => {
    this.setState({anchorEl: null, open: false});
    if(this.props.type == 'QUIZ'){
      this.props.history.push({
        pathname: `/quiz/create/${this.props?.assignment?.classes?.id ?? this.props?.assignment?.group?.id}`,
        state: { assignment: this.props.assignment, actionType: "edit", routeType: this.props.routeType, isOwner: this.props?.isOwner, quizId: this.props?.assignment?.id, quizTemplateID: this.props?.assignment?.template?.id },
      });
    }
    else{
      this.props.history.push({
        pathname: `/assignment/create/${this.props?.assignment?.classes ?? this.props?.assignment?.groups}`,
        state: { assignment: this.props.assignment, actionType: "edit", routeType: this.props.routeType, isOwner: this.props?.isOwner },
      });
    }
  };

    // handlePostClick = () => {
    //   if(this.props.type === "ASSIGNMENT"){
    //     this.setState({viewAssignmentModal: true})
    //   } else if(this.props.type === "QUIZ" && !this.props.isOwner ) {
    //     this.setState({showStartQuizModal: true})
    //   } else if (this.props.type === "QUIZ" && this.props.isOwner ) {
    //     this.setState({viewAssignmentModal: true})
    //   }
    // }

    handleAssignmentPostClick = () => {

      console.log("handleAsgPOst got called")
      this.setState({viewAssignmentModal: true})
    }

    handleQuizPostClick = () => {
      console.log("handleQuizPOst got called")
      if(this.props.isOwner){
        this.setState({viewAssignmentModal: true})
      } else {
        this.setState({showStartQuizModal: true})        
      }

    }

    handleClickOutside = (event) => {
      const domNode = ReactDOM.findDOMNode(this);
  
      if (!domNode || !domNode.contains(event.target)) {
        this.setState({
          viewAssignmentModal: false,
        });
      }
    };


    render(){
      const {t} = this.props;
      // console.log("In AsgPost RouteType",this.props.routeType)
  return (
    
        <div className={styles.Post}>
          <div className={styles.post_header}>
            <div className={styles.infoContainer}>
              <img src={this.props.assignment?.owner_pic || "/Image/DashImage/profile.png"} className={styles.post_profile_pic} /> 
              <div className={styles.post_Info}>
                <h2 className={styles.post_info_userName}>{this.props.assignment?.owner_name || this.props.assignment?.quiz_owner?.name || ""}</h2>
                <p className={styles.post_info_follower}>{this.props.type ==="ASSIGNMENT" ? `${this.props.t("assignment.postedNewAssignment")} ${this.props?.assignment?.class_name || this.props.assignment?.group_name || ""}` : `${this.props.t("quiz.postedNewQuiz")} ${this.props?.assignment?.classes?.name || this.props?.assignment?.group?.name || ""}`}</p>
                <p className={styles.post_info_timestamp}>
                  {this.props.isOwner ? timeSince(this.props.assignment?.timestamp) : 
                    this.props.type ==="ASSIGNMENT" ? timeSince(this.props.assignment?.timestamp) : timeSince(this.props.assignment?.timestamp)}{this.props.t("common.ago")}
                  
                  </p>
                          
              </div>   
            </div>
            {this.props?.isOwner ? (
                          <div>
                          <MoreHorizIcon 
                            onClick={this.handleOpenMenu} 
                            style={{ marginTop: 0, cursor: "pointer", opacity: 0.4, fontSize: "1.8rem" }} />
                            <Menu
                              id="basic-menu"
                              anchorEl={this.state.anchorEl}
                              open={this.state.open}
                              onClose={this.handleCloseMenu}
                              MenuListProps={{ 'aria-labelledby': 'basic-button'}}
                              menuStyle={{ boxShadow: 'none' }}
                              className={styles.memberMenu} 
                              PaperProps={{
                                style: {
                                  boxShadow:'0px 0px 20px rgba(63, 63, 65, 0.102)',
                                  borderRadius: '15px',
                                  padding: '5px',
                                  minHeight: 'auto',
                                  '@media (max-width: 580px)': {
                                    padding: '0px', // Font size for screens with a maximum width of 580px or less
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    minHeight: 'auto'
                                  },
                                }
                              }}
                              >
                              <MenuItem 
                                onClick={() => this?.handleEditAssignment()}
                                style={{ fontSize: '12px',
                                minHeight: 'auto',
                                '@media (max-width: 580px)': {
                                  padding: '0px', // Font size for screens with a maximum width of 580px or less
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                  minHeight: 'auto'
                                },
                              }}
                              >
                                {this.props.t("common.edit")}
                              </MenuItem>
                              <MenuItem 
                                style={{ fontSize: '12px',
                                minHeight: 'auto',
                                '@media (max-width: 580px)': {
                                  padding: '0px', // Font size for screens with a maximum width of 580px or less
                                  paddingTop: '0px',
                                  paddingBottom: '0px',
                                  minHeight: 'auto'
                                }, }}
                                onClick={() => {this.props?.handleDeleteAssignment(this.props?.assignment?.id); 
                                                this.setState({anchorEl: null, open: false});
                                               }}
                              >
                                {this.props.t("common.delete")}
                              </MenuItem>
                            </Menu> 
                          </div>
            ): null }
          </div>
          <div className={styles.post_body} 
          // onClick={() => this.handlePostClick()}
          onClick={this.props.type === "QUIZ" ? this.handleQuizPostClick : this.handleAssignmentPostClick}
        >
          <div className={styles.assignment_post_container}>
            <div className={styles.assignment_post_heading}>
              <AssignmentHeading title={this.props.assignment?.title} />
              <DueDate date={this.props.assignment?.due_date} />
            </div>
            <div className={styles.assignment_post_description}>
              <div className={styles.assignment_post_description_para}>
                <p>{this.props.type === "ASSIGNMENT" ? ReactHtmlParser(this.props.assignment?.details) : this.props.assignment?.details}</p>
            </div>
            {this.props?.isOwner ? 
                        (<div className={styles.assignment_post_open_button}>
                        <Button label={`${this.props.type === "QUIZ" 
                        ? `${this.props.assignment.submission_count.submitted + this.props.assignment.submission_count.graded}` 
                        : (this.props.assignment?.submitted + this.props.assignment?.graded) || 0} ${this.props.t("assignment.submissions")}`} 
                        fullWidth={true}/>
                    </div>) : 
                      (<div className={styles.assignment_post_open_button}>
                          <Button label={this.props.t("assignment.open")} fullWidth={true}/>
                        </div>)
            }
            </div>
            <div className={styles.assignment_post_attachment_container}>
              {this.props.assignment?.file ? (
                            <div className={styles.assignment_post_attachment}>
                            <AttachmentContainer assignmentFileType="FILE" fileName={`${this.props.assignment?.file?.split("/").pop().substr()}`} pageType="Post" file={this.props.assignment?.file}/>
                        </div>

              ): null}
              {
                this.props.assignment?.url ? (
                  <div className={styles.assignment_post_attachment}>
                  <AttachmentContainer assignmentFileType="LINK" linkName={this.props.assignment?.url?.split("/").pop().substr()} link={this.props.assignment?.url} pageType="Post"/>
              </div>
                ): null
              }
            </div>
          </div>
        </div>
        {/* </Link> */}
        {<ViewAssignmentDetailsModal show={this.state.viewAssignmentModal} 
          shareModalCloser={() => this.setState({viewAssignmentModal: false})}
          assignment={this.props?.assignment}
          isClassOwner={this.props?.isClassOwner} 
          isGroupOwner={this.props?.isGroupOwner}
          type={this.props?.type}
          handleDeleteAssignment={this.props.handleDeleteAssignment}
          showEditMenu={this.props?.isOwner}
          handleClickOutside={this.handleClickOutside}
          routeType={this.props.routeType}
          isOwner={this.props.isOwner}
        />}
        {console.log("before Start", this.props.assignment)}
        {console.log("isOwner in Feed", this.props.isOwner)}

        {
          this.state.showStartQuizModal 
          && this.props.type === "QUIZ" 
          && !this.props.isOwner
          && 
          <Modal
            card={
              <StartQuizModal 
                type={this.props.type} 
                title={this.props.assignment.title}
                details={this.props.assignment.details}
                count={this.props.assignment.template.ques_count}
                isTimed={this.props.assignment.is_timed}
                duration={this.props.assignment.duration || 0}
                quiz={this.props.assignment}
                routeType={this.props.routeType}
                isOwner={this.props.isOwner}
                quizID={this.props.assignment.id}
              />
            }
            onOutsideClick={()=>this.setState({showStartQuizModal: false})}
            
          /> 
          
        }
      </div>
    );
  }
}

export default withTranslation()(withRouter(AssignmentPost));
