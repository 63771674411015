import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SaveButton from "../../../components/commons/SaveButton";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
class JoinClassModal extends Component {
  render() {
    return (
      <div>
        <ModalWithHeader title={this.props.t("common.joinClass")} closeHandler={this.props.shareModalCloser} show={this.props.show} style={{ backgroundColor: "white", padding: 20, maxWidth: 350 }}>
          <br />
          <input
            type="text"
            placeholder={this.props.t("dashboard.classCode")}
            onChange={(e) => this.props.changeJoinClassCode(e)}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 50,
              width: "90%",
            }}
          />
          <SaveButton btnTitle={this.props.t("common.join")} onClick={this.props.addClassMember} />
        </ModalWithHeader>
      </div>
    );
  }
}


export default withTranslation()(JoinClassModal)