import React, { useState, useRef, useEffect } from "react";
import styles from "./AddArticleVideoComponent.module.css";
import { useTranslation } from "react-i18next";
import {
  isValidUrl,
  validateFileSize,
} from "../../../redux/constants/commonFunctions";
import { handleErrorMessage } from "../../../redux/actions/classes";
import { handleSuccessMessage } from "../../../redux/actions/success";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { CircularProgress } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ 
          p: 3,
          '@media (max-width: 500px)': {
            p: 2,
          },
        }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const tabStyle = {
  textTransform: "none",
  color: "#A6A6A6",
  font: "14px Montserrat",
  fontWeight: 500,
  padding: "0px 10px",
  '@media (max-width: 500px)': {
    font: "13px Montserrat",
    padding: "0px 10px",
  }
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddArticleVideoComponent = (props) => {
  const { t } = useTranslation();
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0); // It's for mui tabs
  const [uploadFile, setUploadFile] = useState(null);
  const [uploadURL, setUploadURL] = useState("");
  const [link, setLink] = useState("");
  const [time, setTime] = useState({
    hours: "",
    min: "",
  });
  const [duration, setDuration] = useState(0);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  useEffect(() => {
    const data = {
      fileType: props.contentType,
      duration: duration,
    };

    if(props.isEditing){
      data.fileLink = null;
      data.url = null;
    }

    if (uploadFile) {
      data.fileLink = uploadFile;
    } else if (uploadURL) {
      data.url = uploadURL;
    }
    console.log('end of useEffect', data);
    props.getLectureData(data);
  }, [uploadFile, uploadURL, duration]);

  const addURL = () => {
    if (isValidUrl(link.trim())) {
      setUploadURL(link.trim());
      dispatch(handleSuccessMessage(t("courseMonetisation.urlAdded")));
      if (duration > 0) {
        props.disableAddLectureButton(false);
      }
    } else {
      dispatch(handleErrorMessage(t("errorMessageTranslations.validUrl")));
      setLink("");
      setUploadURL("");
    }
  };

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(Math.ceil(media.duration / 60));
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  // new handleFileUpload
  const handleFileUpload = (file) => {
    console.log("inside file upload");
    setIsLoadingBtn(true);
    let extension = file?.name?.split(".").pop().toLowerCase();
    console.log("inEx", { file, extension });
    if (!extension) {
      setIsLoadingBtn(false);
      return null;
    }

    switch (extension) {
      case "pdf":
      case "mp4":
        let errorMessage = validateFileSize(file, 256000);
        if (errorMessage) {
          dispatch(handleErrorMessage(errorMessage));
          setUploadFile(null);
          setIsLoadingBtn(false);
        } else {
          if (extension == "mp4") {
            getVideoDuration(file)
              .then((durationInMinutes) => {
                setDuration(durationInMinutes);
                setUploadFile(file);
                setIsLoadingBtn(false);
              })
              .then(() => {
                props.disableAddLectureButton(false);
                dispatch(
                  handleSuccessMessage(t("courseMonetisation.fileAdded"))
                );
              });
          } else if (extension == "pdf") {
            setUploadFile(file);
            setIsLoadingBtn(false);
            dispatch(handleSuccessMessage(t("courseMonetisation.fileAdded")));
            if (duration) {
              props.disableAddLectureButton(false);
            }
          }
        }
        break;
      default:
        dispatch(
          handleErrorMessage(
            `.${extension} ${t("courseMonetisation.filesNotSupported")}`
          )
        );
        setIsLoadingBtn(false);
    }
  };

  const validateFile = (file) => {
    setIsLoadingBtn(true);
    let extension = file?.name?.split(".").pop().toLowerCase();
    console.log("in validate File func.", { file, extension });

    if (!extension) {
      setIsLoadingBtn(false);
      return null;
    } else if (props.contentType == "video" && extension == "pdf") {
      dispatch(
        handleErrorMessage(
          `${t("courseMonetisation.pleaseUploadVideo")}`
        )
      );
      setIsLoadingBtn(false);
    } else if (props.contentType == "pdf" && extension == "mp4") {
      dispatch(
        handleErrorMessage(
          `${t("courseMonetisation.pleaseUploadPDF")}`
        )
      );
      setIsLoadingBtn(false);
    } else {
      handleFileUpload(file);
    }
  };

  // const handleFileUpload = (file) => {
  //   let extension = file?.name?.split(".").pop().toLowerCase();

  //   setIsLoadingBtn(true);
  //   const errorMessage = validateFileSize(e.target.files[0], 256000);
  //   if (errorMessage) {
  //     dispatch(handleErrorMessage(errorMessage));
  //     setUploadFile(null);
  //     setIsLoadingBtn(false);
  //   } else {
  //     if (e.target.files[0]?.type == "video/mp4") {
  //       getVideoDuration(e.target.files[0])
  //         .then((durationInMinutes) => {
  //           setDuration(durationInMinutes);
  //           const data = {
  //             fileType: props.contentType,
  //             fileLink: e.target.files[0],
  //             duration: durationInMinutes,
  //           };
  //           console.log("Mars250", data);
  //           props.getLectureData(data);
  //           setIsLoadingBtn(false);
  //         })
  //         .then(() =>
  //           dispatch(handleSuccessMessage(t("courseMonetisation.fileAdded")))
  //         );
  //     } else {
  //       const data = {
  //         fileType: props.contentType,
  //         fileLink: e.target.files[0],
  //         duration: duration,
  //       };
  //       props.getLectureData(data);
  //     }
  //     setUploadFile(e.target.files[0]);
  //     dispatch(handleSuccessMessage(t("courseMonetisation.fileAdded")));
  //   }
  // };

  const setTimeDuration = () => {
    if (time.hours === "" || time.min === "") {
      dispatch(handleErrorMessage(t("courseMonetisation.timeCantBeEmpty")));
    } else if (
      (time.hours === 0 && time.min === 0) ||
      time.hours < 0 ||
      time.min < 0
    ) {
      dispatch(handleErrorMessage(t("courseMonetisation.timeCantBeLess")));
    } else if (time.hours > 59) {
      dispatch(handleErrorMessage(t("courseMonetisation.hoursCantExceed")));
    } else if (time.min > 59) {
      dispatch(handleErrorMessage(t("courseMonetisation.minutesCantExceed")));
    } else {
      const inMinutes = time.hours * 60 + time.min;
      if (inMinutes > 0) {
        setDuration(inMinutes);
        dispatch(
          handleSuccessMessage(t("courseMonetisation.timeDurationAdded"))
        );
        if (uploadURL || uploadFile) {
          props.disableAddLectureButton(false);
        }
      } else {
        dispatch(handleErrorMessage(t("courseMonetisation.timeCantBeLess")));
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // when changing tabs - reset state;
    setTime({ ...time, hours: "", min: "" });
    setDuration(0);
    setUploadFile(null);
    setUploadURL("");
    setLink("");
    props.disableAddLectureButton(true);
  };
  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <span>
          {props.isVideo
            ? t("courseMonetisation.addVideo")
            : t("courseMonetisation.addArticle")}
        </span>
        <img
          src="/Image/lecturePlan/cross.svg"
          onClick={() => {
            props.onClose();
            props.disableAddLectureButton(true);
          }}
        />
      </div>
      {console.log('contentType 250', props.contentType)}

      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#ED1E24",
            },
          }}
          sx={{
            "& button.Mui-selected": {
              color: "#ED1E24",
            },
            "& button:focus": {
              color: "#ED1E24",
            },
            "& button.Mui-disabled": {
              backgroundColor: "#f6f6f6",
              color: "#959595",
              borderColor: "#f6f6f6",
            },
          }}
        >
          <Tab
            label={
              props.isVideo
                ? t("courseMonetisation.uploadVideo")
                : t("courseMonetisation.uploadArticle")
            }
            sx={tabStyle}
            disabled={link ? true : false} // file tab becomes disabled if URL has been added.
          />
          <Tab
            label={t("common.addURL")}
            sx={tabStyle}
            disabled={uploadFile ? true : false} // URL tab becomes disabled if file has been added.
          />
        </Tabs>
        <Divider />
      </div>
      <TabPanel value={value} index={0}>
        <div className={styles.file_container}>
          <div className={styles.file_input}>
            <input
              type="file"
              accept={props.isVideo ? ".mp4" : ".pdf"}
              hidden
              ref={fileInputRef}
              onChange={(e) => {
                validateFile(e.target.files[0]);
              }}
            />
            {uploadFile?.name ?? (
              <span className={styles.placeholder_text}>
                {props.isVideo
                  ? t("courseMonetisation.selectVideo")
                  : t("courseMonetisation.selectFile")}
              </span>
            )}
            <img
              src={"/Image/lecturePlan/cross.svg"}
              onClick={() => {
                setUploadFile(null);
                props.disableAddLectureButton(true);
              }}
            />
          </div>
          <div className={styles.btn_add_article}>
            <button onClick={() => fileInputRef.current.click()}>
              {isLoadingBtn ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <span>
                  {props.isVideo
                    ? t("courseMonetisation.addVideo")
                    : t("courseMonetisation.addArticle")}
                </span>
              )}
            </button>
          </div>
        </div>
        {console.log("videoDuration", duration)}
        {!props.isVideo && (
          <div className={styles.time_container}>
            <div className={styles.file_input}>
              <div className={styles.time_input_container}>
                <input
                  type="number"
                  placeholder="00"
                  value={time.hours}
                  onChange={(e) => {
                    setTime({ ...time, hours: Number(e.target.value) });
                    setDuration(0);
                    props.disableAddLectureButton(true);
                  }}
                />{" "}
                <span className={styles.time_unit}>
                  {t("courseMonetisation.hr")}
                </span>{" "}
                <span className={styles.colon}>&#58;</span>
                <input
                  type="number"
                  placeholder="00"
                  value={time.min}
                  onChange={(e) => {
                    setTime({ ...time, min: Number(e.target.value) });
                    setDuration(0);
                    props.disableAddLectureButton(true);
                  }}
                />{" "}
                <span className={styles.time_unit}>
                  {t("courseMonetisation.min")}
                </span>{" "}
                {/* <span className={styles.colon}>&#58;</span>
                <input type="number" placeholder="00" />{" "}
                <span className={styles.time_unit}>
                  {t("courseMonetisation.sec")}
                </span>{" "} */}
                {console.log("time", time, duration)}
              </div>
            </div>
            <div className={styles.btn_add_article}>
              <button onClick={setTimeDuration}>
                <span>{t("courseMonetisation.setDuration")}</span>
              </button>
            </div>
          </div>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className={styles.url_container}>
          <div className={styles.url_input}>
            <input
              type="text"
              placeholder={t("assignment.addUrl")}
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
                setUploadURL("");
                props.disableAddLectureButton(true);
              }}
            />
            <img
              src={"/Image/lecturePlan/cross.svg"}
              onClick={() => {
                setLink("");
                setUploadURL("");
                props.disableAddLectureButton(true);
              }}
            />
          </div>
          <div className={styles.btn_add_url}>
            <button onClick={addURL}>{t("common.addURL")}</button>
          </div>
        </div>

        <div className={styles.time_container}>
          <div className={styles.file_input}>
            <div className={styles.time_input_container}>
              <input
                type="number"
                placeholder="00"
                value={time.hours}
                onChange={(e) => {
                  setTime({ ...time, hours: Number(e.target.value) });
                  setDuration(0);
                  props.disableAddLectureButton(true);
                }}
              />{" "}
              <span className={styles.time_unit}>
                {t("courseMonetisation.hr")}
              </span>{" "}
              <span className={styles.colon}>&#58;</span>
              <input
                type="number"
                placeholder="00"
                value={time.min}
                onChange={(e) => {
                  setTime({ ...time, min: Number(e.target.value) });
                  setDuration(0);
                  props.disableAddLectureButton(true);
                }}
              />{" "}
              <span className={styles.time_unit}>
                {t("courseMonetisation.min")}
              </span>{" "}
              {/* <span className={styles.colon}>&#58;</span>
                <input type="number" placeholder="00" />{" "}
                <span className={styles.time_unit}>
                  {t("courseMonetisation.sec")}
                </span>{" "} */}
            </div>
          </div>
          <div className={styles.btn_add_article}>
            <button onClick={setTimeDuration}>
              <span>{t("courseMonetisation.setDuration")}</span>
            </button>
          </div>
        </div>
      </TabPanel>
    </div>
  );
};

export default AddArticleVideoComponent;
