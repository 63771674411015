import React, { useEffect, useState } from "react";
import styles from "./EnterpriseFacultyDashboard.module.css";
import { useTranslation } from "react-i18next";
import {
  AppstoreOutlined,
  CalendarOutlined,
  LinkOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";
import EnterpriseDataTable from "./Components/EnterpriseDataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardClassGroupAssignmentQuiz,
  getDashboardFacultyStudentPost,
  getSearchResult,
  getStudentFacultySearchResult,
  getEnterpriseClassesAndGroups,
  getDashboardClassGroupPost,
  getAssignmentQuizPostSearchResult,
  deleteEnterpriseFacultyStudent,
  AddFacultyStudentToDepartment,
  activeInactiveClassGroup,
} from "../../redux/actions/enterpriseDashboard";
import debounce from "lodash.debounce";
import LineChartComponent from "./Components/LineChartComponent";
import PieChartComponent from "./Components/PieChartComponent";
import BarChartComponent from "./Components/BarChartComponent";
import EnterpriseDropDownFilter from "./Components/EnterpriseDropDownFilter";
import Post from "../Dashboard/Components/Post";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import {
  clearFacultyDashboard,
  getActiveInactiveFilteredContent,
  getEnterpriseFacultySearchResult,
  getFacultyStudentDashboardClassAssignments,
  getFacultyStudentDashboardClassGroupPost,
  getFacultyStudentDashboardClassQuiz,
  getFacultyStudentDashboardClassStudentOrGroupMembers,
  getFacultyStudentDashboardClassesAndGroups,
  getFacultyStudentMainDashboard,
  getFilteredContent,
} from "../../redux/actions/facultyDashboard";
import StudentNameComponent from "../AssignmentAndQuiz/Components/StudentNameComponent";

const EnterpriseFacultyDashboard = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  let departmentID = useSelector((state) => state?.profile?.dept_id) || "";
  let loggedInUserProfileId = useSelector((state) => state?.profile?.user?.profile_id) || "";

  const facultyStudentUserId = location?.state?.userId || "";
  const facultyStudentUserName = location?.state?.name || "";
  const facultyStudentUserPic = location?.state?.pic || "";

  let dashboardClassData =
    useSelector((state) => state?.facultyDashboard?.dashboard_class_list) || [];
  let dashboardGroupData =
    useSelector((state) => state?.facultyDashboard?.dashboard_group_list) || [];

  let dashboardClassAssignmentData =
    useSelector(
      (state) => state?.facultyDashboard?.dashboard_class_assignment
    ) || [];

  let dashboardClassQuizData =
    useSelector((state) => state?.facultyDashboard?.dashboard_class_quiz) || [];

  let dashboardFactultyData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_faculty) || [];
  let dashboardClassStudentData =
    useSelector((state) => state?.facultyDashboard?.dashboard_class_student) || [];

  let dashboardClassPostData =
    useSelector((state) => state?.facultyDashboard?.dashboard_class_post) ||
    [];

  let dashboardClassCourseData =
    useSelector(
      (state) => state?.enterpriseDashboard?.dashboard_class_course
    ) || [];

  let dashboardGroupPostData =
    useSelector((state) => state?.facultyDashboard?.dashboard_group_post) ||
    [];

  let dashboardGroupMemberData =
    useSelector((state) => state?.facultyDashboard?.dashboard_group_member) ||
    [];

  let dashboardGroupAssignmentData =
    useSelector(
      (state) => state?.enterpriseDashboard?.dashboard_group_assignment
    ) || [];
  let dashboardGroupQuizData =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_group_quiz) ||
    [];

  

  let dashboardDataCount =
    useSelector((state) => state?.facultyDashboard?.count) || 0;

  let dashboardChartData =
    useSelector((state) => state?.facultyDashboard?.dashboard_chart_data) ||
    {};
  let connectionsSearchResult =
    useSelector(
      (state) => state?.enterpriseDashboard?.dashboard_search_result
    ) || {};
  let dashboardClassList =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_class_list) ||
    {};
  let dashboardGroupList =
    useSelector((state) => state?.enterpriseDashboard?.dashboard_group_list) ||
    {};

  const [isLoading, setIsLoading] = useState(true);
  const [type, setType] = useState("dashboard_main");

  const [searchQuery, setSearchQuery] = useState("");
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);

  // selected class/group id
  const [dropdownSelection, setDropdownSelection] = useState("");
  const [activeDropdownSelection, setActiveDropdownSelection] = useState("");
  const [dropdownResetCounter, setDropdownResetCounter] = useState(0);

  function getItem(label, key, icon, children) {
    return {
      key,
      icon,
      children,
      label,
    };
  }

  let postFeedData =
    type === "dashboard_class_post"
      ? dashboardClassPostData
      : type === "dashboard_group_post"
      ? dashboardGroupPostData
      : [];

  const barChartData = dashboardChartData["10_top_classes"]?.map((item) => {
    let data = {};
    data.name = item[1].name;
    data.assignments = item[1].asgs;
    data.quizzes = item[1].quizzes;
    return data;
  });
  const lineChartData = dashboardChartData["10_top_classes"]?.map((item) => {
    let data = {};
    data.name = item[1].name;
    data.posts = item[1].posts_count;
    return data;
  });

  const getData = () =>{
    console.log('type inside getData', type)
    
    if (
      departmentID &&
      (type === "dashboard_main")
    ) {
      dispatch(
        getFacultyStudentMainDashboard(
          type,
          departmentID,
          facultyStudentUserId
        )
      ).then(() => setIsLoading(false));
    }

    if (
      departmentID &&
      (type === "dashboard_class" || type === "dashboard_group")
    ) {
      dispatch(
        getFacultyStudentDashboardClassesAndGroups(
          type,
          departmentID,
          facultyStudentUserId
        )
      ).then(() => setIsLoading(false));
    }

    if (
      departmentID &&
      (type === "dashboard_class_post" || type === "dashboard_group_post")
    ) {
      dispatch(
        getFacultyStudentDashboardClassGroupPost(
          type,
          departmentID,
          facultyStudentUserId
        )
      ).then(() => setIsLoading(false));
    }

    if (departmentID && type === "dashboard_class_assignment") {
      dispatch(
        getFacultyStudentDashboardClassAssignments(
          type,
          departmentID,
          facultyStudentUserId
        )
      ).then(() => setIsLoading(false));
    }

    if (departmentID && type === "dashboard_class_quiz") {
      dispatch(
        getFacultyStudentDashboardClassQuiz(
          type,
          departmentID,
          facultyStudentUserId
        )
      ).then(() => setIsLoading(false));
    }

    if (
      departmentID &&
      (type === "dashboard_class_student" || type === "dashboard_group_member")
    ) {
      dispatch(
        getFacultyStudentDashboardClassStudentOrGroupMembers(
          type,
          departmentID,
          facultyStudentUserId
        )
      ).then(() => setIsLoading(false));
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getData();
    setIsLoading(false);
  }, [departmentID, type]);

  // This is for when the dropdown selection changes
  useEffect(() => {
    if (
      type !== "dashboard_main" &&
      type !== "dashboard_class" &&
      type !== "dashboard_group"
    ) {
      console.log('dropDownS', dropdownSelection)
      if(dropdownSelection){
        dispatch(
          getFilteredContent(type, departmentID, facultyStudentUserId, dropdownSelection)
        );
        setSearchQuery("");
      } 
      
      if(dropdownSelection === undefined) {
        getData();
      }
    }
  }, [dropdownSelection]);

  // This is for when the activeDropdownSelection changes
  useEffect(() => {
    if (
      type === "dashboard_class" ||
      type === "dashboard_group"
    ) {
      console.log('dropDownS', activeDropdownSelection)
      if(activeDropdownSelection === true || activeDropdownSelection === false){
        dispatch(getActiveInactiveFilteredContent(type, departmentID, facultyStudentUserId, activeDropdownSelection))
        setSearchQuery("");
      } 
      
      if(activeDropdownSelection === null) {
        getData();
      }
    }
  }, [activeDropdownSelection]);
  console.log("theAct", activeDropdownSelection)


  // This is to fetch all the enterpirse classes and groups to show inside the dropdowns.
  useEffect(() => {
    setIsLoading(true);
    dispatch(getEnterpriseClassesAndGroups("dashboard_class", departmentID))
      .then(() =>
        dispatch(getEnterpriseClassesAndGroups("dashboard_group", departmentID))
      )
      .then(() => setIsLoading(false));
  }, [departmentID]);

  useEffect(() => {
    const handleResizeEvent = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResizeEvent);

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, []);

  const menuStyles = {
    color: "rgb(29, 29, 29)",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: 500,
    textAlign: "left",
  };

  const handleProfileSearch = (event) => {
    if (event?.target?.value?.trim().length > 0) {
      // setSearchQueryTwo(event?.target.value.trim());
      dispatch(getSearchResult(event?.target?.value.trim()));
    } else {
      // setShowSearchResult(false);
    }
  };

  const handleStudentFacultySearch = (event) => {
    dispatch(
      getStudentFacultySearchResult(type, departmentID, event?.target?.value)
    );
    // if (event?.target?.value?.trim().length > 0) {
    // setSearchQueryTwo(event?.target.value.trim());
    // } else {
    // setShowSearchResult(false);
    // }
  };

  const handleAssignmentQuizPostSearch = (event) => {
    dispatch(
      getAssignmentQuizPostSearchResult(
        type,
        departmentID,
        dropdownSelection,
        event?.target?.value
      )
    ).then(() => setIsLoading(false));
  };

  const debouncedProfileSearch = debounce(handleProfileSearch, 500);
  const debouncedAssignmentQuizPostSearch = debounce(
    handleAssignmentQuizPostSearch,
    500
  );
  const debouncedStudentFacultySearch = debounce(
    handleStudentFacultySearch,
    500
  );

  const handleSearch = (query) => {
    setIsLoading(true);
    dispatch(getEnterpriseFacultySearchResult(type, departmentID, dropdownSelection, facultyStudentUserId, query)).then(()=>setIsLoading(false))
  }

  const debouncedSearch = debounce(handleSearch, 500);

  const handleOnDelete = (userId) => {
    console.log("delete this", userId, type);

    if (
      userId &&
      (type === "dashboard_faculty" || type === "dashboard_student")
    ) {
      console.log("calling deleteFunction");
      dispatch(deleteEnterpriseFacultyStudent(type, userId)).then(() =>
        dispatch(getDashboardFacultyStudentPost(type, departmentID))
      );
    }
  };
  const handleOnView = (data, name, pic) => {
    console.log("view this", data, name, pic);

    if (data && type === "dashboard_class_quiz") {
      history.push({
        pathname: "/template/preview/",
        state: {
          templateId: parseInt(data.template),
          isPreview: true,
          routeType: null,
          isOwner: false,
          isEditable: false,
          classOrGroupId: parseInt(data.classes),
          isEnterprise: true,
        },
      });
    }
    if (data && type === "dashboard_class_course") {
      history.push({
        pathname: `/course/view/${data?.id}`,
      });
    }
    if (data && type === "dashboard_class_assignment") {
      history.push({
        pathname: `/dashboard/assignment/view/${data?.id}`,
      });
    }
    if (
      data &&
      (type === "dashboard_group_member" || type === "dashboard_class_student")
    ) {

      dispatch(clearFacultyDashboard()).then(()=>{
        history.push({
          // pathname: `profile/${data}/view`,
          // pathname: type === "dashboard_faculty" ? `/faculty-dashboard` : `/student-dashboard`,
          pathname: `/student-dashboard`,
          state: {
            userId: data,
            name: name,
            pic: pic
            }
            });
          setType("dashboard_main")
      })
      
    }
  };
  const handleStatusChange = (classGroupId, newStatus) => {
    dispatch(activeInactiveClassGroup(classGroupId, newStatus, type)).then(() =>
      getData()
    );
  };

  

  //------------------ For Mobile View ----------------

  if(showHamburgerMenu){
    return (
      <div>
        <div className={styles.top_navbar}>
          <img
            src="/Image/Gradebook/menu.svg"
            alt="icon"
            className={styles.menu_icon}
            onClick={()=>setShowHamburgerMenu(!showHamburgerMenu)}
          />
          {/* <div className={styles.logo_wrapper}>
            <div>
              <img src="/Image/EnterpriseDashboard/Dashboard logo.svg" />
            </div>
            <p>Dashboard</p>
          </div> */}
          <StudentNameComponent studentName={facultyStudentUserName} profilePic={facultyStudentUserPic} />
        </div>

        {/* Mobile Sidebar */}
        <div className={styles.mobile_sidebar_container}>
          <Sidebar style={{width: '100%', paddingTop: '1rem'}}>
            <Menu style={menuStyles}>
              <MenuItem
                style={{
                  backgroundColor:
                    type === "dashboard_main" ? "#ffeced" : "transparent",
                }}
                // onClick={() => setType("dashboard_main")}
                onClick={(x) => {
                  setSearchQuery("");
                  setDropdownSelection("");
                  setDropdownResetCounter((dropdownResetCounter) =>
                    setDropdownResetCounter(dropdownResetCounter + 1)
                  );
                  setType("dashboard_main");
                  setShowHamburgerMenu(false);
                }}
              >
                <div className={styles.sidebar_menu_item_wrapper}>
                  <img src={"/Image/EnterpriseDashboard/Dashboard option.svg"} />
                  {t("profile.Dashboard")}{" "}
                </div>
              </MenuItem>
              <SubMenu label="Classes">
                <MenuItem
                  style={{
                    backgroundColor:
                      type === "dashboard_class" ? "#ffeced" : "transparent",
                  }}
                  // onClick={() => setType("dashboard_class")}
                  onClick={(x) => {
                    setSearchQuery("");
                    setDropdownSelection("");
                    setDropdownResetCounter((dropdownResetCounter) =>
                      setDropdownResetCounter(dropdownResetCounter + 1)
                    );
                    setType("dashboard_class");
                    setShowHamburgerMenu(false);
                  }}
                >
                  <div className={styles.sidebar_menu_item_wrapper}>
                  <img src={"/Image/EnterpriseDashboard/Classes.svg"} />
                    {t("enterpriseDashboard.allClasses")}
                  </div>
                </MenuItem>
                {!props.isStudent && (
                  <MenuItem
                    style={{
                      backgroundColor:
                        type === "dashboard_class_student"
                          ? "#ffeced"
                          : "transparent",
                    }}
                    // onClick={() => setType("dashboard_class_student")}
                    onClick={(x) => {
                      setSearchQuery("");
                      setDropdownSelection("");
                      setDropdownResetCounter((dropdownResetCounter) =>
                        setDropdownResetCounter(dropdownResetCounter + 1)
                      );
                      setType("dashboard_class_student");
                      setShowHamburgerMenu(false);
                    }}
                  >
                    <div className={styles.sidebar_menu_item_wrapper}>
                    <img src={"/Image/EnterpriseDashboard/Student.svg"} />
                      {t("enterpriseDashboard.students")}{" "}
                    </div>
                  </MenuItem>
                )}
                <MenuItem
                  style={{
                    backgroundColor:
                      type === "dashboard_class_post"
                        ? "#ffeced"
                        : "transparent",
                  }}
                  // onClick={() => setType("dashboard_class_post")}
                  onClick={(x) => {
                    setSearchQuery("");
                    setDropdownSelection("");
                    setDropdownResetCounter((dropdownResetCounter) =>
                      setDropdownResetCounter(dropdownResetCounter + 1)
                    );
                    setType("dashboard_class_post");
                    setShowHamburgerMenu(false);
                  }}
                >
                  <div className={styles.sidebar_menu_item_wrapper}>
                  <img src={"/Image/EnterpriseDashboard/Post.svg"} />
                    {t("enterpriseDashboard.posts")}{" "}
                  </div>
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor:
                      type === "dashboard_class_assignment"
                        ? "#ffeced"
                        : "transparent",
                  }}
                  // onClick={() => setType("dashboard_class_assignment")}
                  onClick={(x) => {
                    setSearchQuery("");
                    setDropdownSelection("");
                    setDropdownResetCounter((dropdownResetCounter) =>
                      setDropdownResetCounter(dropdownResetCounter + 1)
                    );
                    setType("dashboard_class_assignment");
                    setShowHamburgerMenu(false);
                  }}
                >
                  <div className={styles.sidebar_menu_item_wrapper}>
                  <img src={"/Image/EnterpriseDashboard/Assignments.svg"} />
                    {t("enterpriseDashboard.assignments")}{" "}
                  </div>
                </MenuItem>
                <MenuItem
                  style={{
                    backgroundColor:
                      type === "dashboard_class_quiz"
                        ? "#ffeced"
                        : "transparent",
                  }}
                  // onClick={() => setType("dashboard_class_quiz")}
                  onClick={(x) => {
                    setSearchQuery("");
                    setDropdownSelection("");
                    setDropdownResetCounter((dropdownResetCounter) =>
                      setDropdownResetCounter(dropdownResetCounter + 1)
                    );
                    setType("dashboard_class_quiz");
                    setShowHamburgerMenu(false);
                  }}
                >
                  <div className={styles.sidebar_menu_item_wrapper}>
                  <img src={"/Image/EnterpriseDashboard/Quizzes.svg"} />
                    {t("enterpriseDashboard.quizzes")}{" "}
                  </div>
                </MenuItem>
              </SubMenu>
              <SubMenu label="Groups">
                <MenuItem
                  style={{
                    backgroundColor:
                      type === "dashboard_group" ? "#ffeced" : "transparent",
                  }}
                  // onClick={() => setType("dashboard_group")}
                  onClick={(x) => {
                    setSearchQuery("");
                    setDropdownSelection("");
                    setDropdownResetCounter((dropdownResetCounter) =>
                      setDropdownResetCounter(dropdownResetCounter + 1)
                    );
                    setType("dashboard_group");
                    setShowHamburgerMenu(false);
                  }}
                >
                  <div className={styles.sidebar_menu_item_wrapper}>
                  <img src={"/Image/EnterpriseDashboard/Groups.svg"} />
                    {t("enterpriseDashboard.allGroups")}{" "}
                  </div>
                </MenuItem>
                {!props.isStudent && (
                  <MenuItem
                    style={{
                      backgroundColor:
                        type === "dashboard_group_member"
                          ? "#ffeced"
                          : "transparent",
                    }}
                    // onClick={() => setType("dashboard_group_member")}
                    onClick={(x) => {
                      setSearchQuery("");
                      setDropdownSelection("");
                      setDropdownResetCounter((dropdownResetCounter) =>
                        setDropdownResetCounter(dropdownResetCounter + 1)
                      );
                      setType("dashboard_group_member");
                      setShowHamburgerMenu(false);
                    }}
                  >
                    <div className={styles.sidebar_menu_item_wrapper}>
                    <img src={"/Image/EnterpriseDashboard/Student.svg"} />
                      {t("enterpriseDashboard.members")}{" "}
                    </div>
                  </MenuItem>
                )}
                <MenuItem
                  style={{
                    backgroundColor:
                      type === "dashboard_group_post"
                        ? "#ffeced"
                        : "transparent",
                  }}
                  // onClick={() => setType("dashboard_group_post")}
                  onClick={(x) => {
                    setSearchQuery("");
                    setDropdownSelection("");
                    setDropdownResetCounter((dropdownResetCounter) =>
                      setDropdownResetCounter(dropdownResetCounter + 1)
                    );
                    setType("dashboard_group_post");
                    setShowHamburgerMenu(false);
                  }}
                >
                  <div className={styles.sidebar_menu_item_wrapper}>
                  <img src={"/Image/EnterpriseDashboard/Post.svg"} />
                    {t("enterpriseDashboard.posts")}{" "}
                  </div>
                </MenuItem>
              </SubMenu>
            </Menu>
          </Sidebar>
        </div>
      </div>
    );
  }


  //------------------ For Desktop View ----------------

  return (
    <>
    <div className={styles.top_navbar}>
        <img
          src="/Image/Gradebook/menu.svg"
          alt="icon"
          className={styles.menu_icon}
          onClick={()=>setShowHamburgerMenu(!showHamburgerMenu)}
        />
        {/* <div className={styles.logo_wrapper}>
          <div>
            <img src="/Image/EnterpriseDashboard/Dashboard logo.svg" />
          </div>
          <p>Dashboard</p>
        </div> */}
        <StudentNameComponent studentName={facultyStudentUserName} profilePic={facultyStudentUserPic} />
      </div>
    <div className={styles.main}>
      <div className={styles.sidebar_container}>
        <Sidebar>
          {console.log("insideSidebar", type)}
          <div className={styles.sidebar_header}>
              <img className={styles.profile_pic} src={facultyStudentUserPic || "/static/images/avatar/1.jpg"} />
            <div className={styles.username_label_wrapper}>
              <img src={"/Image/badge.svg"} />
              <p className={styles.username}>{facultyStudentUserName}</p>
            </div>
              <p className={styles.usertype}>{props.isStudent ? "Student" : "Faculty"}</p>
          </div>
          <Menu style={menuStyles}>
            <MenuItem
              style={{
                backgroundColor:
                  type === "dashboard_main" ? "#ffeced" : "transparent",
              }}
              // onClick={() => setType("dashboard_main")}
              onClick={(x) => {
                setSearchQuery("");
                setDropdownSelection("");
                setDropdownResetCounter((dropdownResetCounter) =>
                  setDropdownResetCounter(dropdownResetCounter + 1)
                );
                setType("dashboard_main");
              }}
            >
              <div className={styles.sidebar_menu_item_wrapper}>
                <img src={"/Image/EnterpriseDashboard/Dashboard option.svg"} />
                {t("profile.Dashboard")}{" "}
              </div>
            </MenuItem>
            <SubMenu label="Classes">
              <MenuItem
                style={{
                  backgroundColor:
                    type === "dashboard_class" ? "#ffeced" : "transparent",
                }}
                // onClick={() => setType("dashboard_class")}
                onClick={(x) => {
                  setSearchQuery("");
                  setDropdownSelection("");
                  setDropdownResetCounter((dropdownResetCounter) =>
                    setDropdownResetCounter(dropdownResetCounter + 1)
                  );
                  setType("dashboard_class");
                }}
              >
                <div className={styles.sidebar_menu_item_wrapper}>
                <img src={"/Image/EnterpriseDashboard/Classes.svg"} />
                {t("enterpriseDashboard.allClasses")}
                </div>
              </MenuItem>
              {!props.isStudent && (
                <MenuItem
                  style={{
                    backgroundColor:
                      type === "dashboard_class_student"
                        ? "#ffeced"
                        : "transparent",
                  }}
                  // onClick={() => setType("dashboard_class_student")}
                  onClick={(x) => {
                    setSearchQuery("");
                    setDropdownSelection("");
                    setDropdownResetCounter((dropdownResetCounter) =>
                      setDropdownResetCounter(dropdownResetCounter + 1)
                    );
                    setType("dashboard_class_student");
                  }}
                >
                  <div className={styles.sidebar_menu_item_wrapper}>
                  <img src={"/Image/EnterpriseDashboard/Student.svg"} />
                  {t("enterpriseDashboard.students")}{" "}
              </div>
                </MenuItem>
              )}
              <MenuItem
                style={{
                  backgroundColor:
                    type === "dashboard_class_post" ? "#ffeced" : "transparent",
                }}
                // onClick={() => setType("dashboard_class_post")}
                onClick={(x) => {
                  setSearchQuery("");
                  setDropdownSelection("");
                  setDropdownResetCounter((dropdownResetCounter) =>
                    setDropdownResetCounter(dropdownResetCounter + 1)
                  );
                  setType("dashboard_class_post");
                }}
              >
                <div className={styles.sidebar_menu_item_wrapper}>
                <img src={"/Image/EnterpriseDashboard/Post.svg"} />
                {t("enterpriseDashboard.posts")}{" "}
              </div>
              </MenuItem>
              <MenuItem
                style={{
                  backgroundColor:
                    type === "dashboard_class_assignment"
                      ? "#ffeced"
                      : "transparent",
                }}
                // onClick={() => setType("dashboard_class_assignment")}
                onClick={(x) => {
                  setSearchQuery("");
                  setDropdownSelection("");
                  setDropdownResetCounter((dropdownResetCounter) =>
                    setDropdownResetCounter(dropdownResetCounter + 1)
                  );
                  setType("dashboard_class_assignment");
                }}
              >
                <div className={styles.sidebar_menu_item_wrapper}>
                <img src={"/Image/EnterpriseDashboard/Assignments.svg"} />
                {t("enterpriseDashboard.assignments")}{" "}
              </div>
              </MenuItem>
              <MenuItem
                style={{
                  backgroundColor:
                    type === "dashboard_class_quiz" ? "#ffeced" : "transparent",
                }}
                // onClick={() => setType("dashboard_class_quiz")}
                onClick={(x) => {
                  setSearchQuery("");
                  setDropdownSelection("");
                  setDropdownResetCounter((dropdownResetCounter) =>
                    setDropdownResetCounter(dropdownResetCounter + 1)
                  );
                  setType("dashboard_class_quiz");
                }}
              >
                <div className={styles.sidebar_menu_item_wrapper}>
                <img src={"/Image/EnterpriseDashboard/Quizzes.svg"} />
                {t("enterpriseDashboard.quizzes")}{" "}
              </div>
              </MenuItem>
            </SubMenu>
            <SubMenu label="Groups">
              <MenuItem
                style={{
                  backgroundColor:
                    type === "dashboard_group" ? "#ffeced" : "transparent",
                }}
                // onClick={() => setType("dashboard_group")}
                onClick={(x) => {
                  setSearchQuery("");
                  setDropdownSelection("");
                  setDropdownResetCounter((dropdownResetCounter) =>
                    setDropdownResetCounter(dropdownResetCounter + 1)
                  );
                  setType("dashboard_group");
                }}
              >
                <div className={styles.sidebar_menu_item_wrapper}>
                <img src={"/Image/EnterpriseDashboard/Groups.svg"} />
                {t("enterpriseDashboard.allGroups")}{" "}
              </div>
              </MenuItem>
              {!props.isStudent && (
                <MenuItem
                  style={{
                    backgroundColor:
                      type === "dashboard_group_member"
                        ? "#ffeced"
                        : "transparent",
                  }}
                  // onClick={() => setType("dashboard_group_member")}
                  onClick={(x) => {
                    setSearchQuery("");
                    setDropdownSelection("");
                    setDropdownResetCounter((dropdownResetCounter) =>
                      setDropdownResetCounter(dropdownResetCounter + 1)
                    );
                    setType("dashboard_group_member");
                  }}
                >
                  <div className={styles.sidebar_menu_item_wrapper}>
                  <img src={"/Image/EnterpriseDashboard/Student.svg"} />
                  {t("enterpriseDashboard.members")}{" "}
              </div>
                </MenuItem>
              )}
              <MenuItem
                style={{
                  backgroundColor:
                    type === "dashboard_group_post" ? "#ffeced" : "transparent",
                }}
                // onClick={() => setType("dashboard_group_post")}
                onClick={(x) => {
                  setSearchQuery("");
                  setDropdownSelection("");
                  setDropdownResetCounter((dropdownResetCounter) =>
                    setDropdownResetCounter(dropdownResetCounter + 1)
                  );
                  setType("dashboard_group_post");
                }}
              >
                <div className={styles.sidebar_menu_item_wrapper}>
                <img src={"/Image/EnterpriseDashboard/Post.svg"} />
                {t("enterpriseDashboard.posts")}{" "}
              </div>
              </MenuItem>
            </SubMenu>
          </Menu>
        </Sidebar>
      </div>

      {/* ----- mobile menu header ------ */}

      {/* -----Right Contianer --------- */}
      <div className={styles.right_container}>
        <div className={styles.header_container}>
          {console.log("theType", type)}

          {/* ---------- DASHBOARD_MAIN_HEADER ---------- */}
          {type === "dashboard_main" && (
            <div className={styles.main_header_wrapper}>
              {[
                {
                  count: dashboardChartData?.total_faculty_mems,
                  label: "Total number of Faculties",
                  id: "something_here1",
                  icon: "Faculty",
                },
                {
                  count: dashboardChartData?.total_student_mems,
                  label: "Total number of Students",
                  id: "something_here2",
                  icon: "Student",
                },
                {
                  count: dashboardChartData?.total_classes,
                  label: "Total number of Classes",
                  id: "something_here3",
                  icon: "Classes",
                },
              ].map((item) => (
                <div key={item?.id} className={styles.header_card}>
                  <div className={styles.counter}>
                    <p className={styles.count}>{item?.count || 0}</p>
                    <p className={styles.count_label}>
                      {item?.label || "Total Number of "}
                    </p>
                    <div className={styles.icon_wrapper}>
                      <img
                        className={styles.counter_icon}
                        src={`/Image/EnterpriseDashboard/${item?.icon}.svg`}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* ---------- DASHBOARD_SEARCH & FILTER_HEADER ---------- */}
          {type !== "dashboard_main" &&
            type !== "dashboard_class" &&
            type !== "dashboard_group" && (
              <div className={`${styles.header_wrapper}`}>
                <div className={`${styles.header_left}`}>
                  <p className={styles.search_label}>{`Select ${
                    type.includes("group") ? "Group" : "Class"
                  }`}</p>
                  <EnterpriseDropDownFilter
                    resetTrigger={dropdownResetCounter}
                    options={
                      type.includes("group")
                        ? dashboardGroupList
                        : dashboardClassList
                    }
                    getSelection={(classGroupId) =>{
                      setDropdownSelection(classGroupId);
                      setSearchQuery("");
                    }}
                  />
                </div>

                <div className={styles.header_right}>
                  <p className={styles.search_label}>{`Quick Search ${
                    type === "dashboard_class_assignment"
                      ? "Assignment"
                      : type === "dashboard_class_course"
                      ? "Course"
                      : type === "dashboard_class_quiz"
                      ? "Quiz"
                      : type === "dashboard_class_student"
                      ? "Student"
                      : type === "dashboard_group_member"
                      ? "Group Member"
                      : "Post"
                  }`}</p>

                  <div className={styles.searchbar}>
                    <input
                      value={searchQuery}
                      type="text"
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        // setDropdownResetCounter(dropdownResetCounter + 1);
                        debouncedSearch(e.target.value);
                      }}
                      placeholder={t("gradebook.searchByName") + ". . ."}
                    />

                    <img src="/Image/Gradebook/search.svg" alt="icon" />
                  </div>
                </div>
              </div>
            )}

          {/*TO BE DONE  */}
          {console.log("ActiveDropdownSelection", activeDropdownSelection)}
          {(type === "dashboard_class" || type === "dashboard_group") &&
            3 < 7 && (
              <div className={`${styles.header_wrapper}`}>
                <div className={`${styles.header_left}`}>
                  <p className={styles.search_label}>{`Select ${
                    type.includes("group") ? "Group" : "Class"
                  }`}</p>
                  <EnterpriseDropDownFilter
                    resetTrigger={dropdownResetCounter}
                    options={ [ {'id':1, 'name':'Active'}, {'id':0, 'name':'Inactive'}]}
                    getSelection={(id) => setActiveDropdownSelection(id === 1 ? true : id === 0 ? false : null)}
                  />
                </div>

                <div className={styles.header_right}>
                  <p className={styles.search_label}>{`Quick Search ${
                    type === "dashboard_class_assignment"
                      ? "Assignment"
                      : type === "dashboard_class_course"
                      ? "Course"
                      : type === "dashboard_class_quiz"
                      ? "Quiz"
                      : type === "dashboard_class_student"
                      ? "Student"
                      : type === "dashboard_group_member"
                      ? "Group Member"
                      : type === "dashboard_class"
                      ? "Class"
                      : type === "dashboard_group"
                      ? "Group"
                      : "Post"
                  }`}</p>

                  <div className={styles.searchbar}>
                    <input
                      value={searchQuery}
                      type="text"
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        console.log("theSearchQuery", e.target.value);
                        // debouncedAssignmentQuizPostSearch(e);
                      }}
                      placeholder={t("gradebook.searchByName") + ". . ."}
                    />

                    <img src="/Image/Gradebook/search.svg" alt="icon" />
                  </div>
                </div>
              </div>
            )}
        </div>

        {/* --------------- Center table and Feed ------------- */}

        {console.log("dashboard-faculty-data", dashboardFactultyData)}
        {console.log("dashboard-faculty-data", dashboardFactultyData)}
        {console.log("dashboard-class-post-data", dashboardClassPostData)}
        <div
          className={
            type === "dashboard_class_post" || type === "dashboard_group_post"
              ? `${styles.table} ${styles.post_feed_wrapper}`
              : styles.table
          }
        >
          {type !== "dashboard_main" &&
            type !== "dashboard_class_post" &&
            type !== "dashboard_group_post" && (
              <EnterpriseDataTable
                data={
                  type === "dashboard_faculty"
                    ? dashboardFactultyData
                    : type === "dashboard_class_student"
                    ? dashboardClassStudentData
                    : type === "dashboard_class"
                    ? dashboardClassData
                    : type === "dashboard_group"
                    ? dashboardGroupData
                    : type === "dashboard_class_assignment"
                    ? dashboardClassAssignmentData
                    : type === "dashboard_class_quiz"
                    ? dashboardClassQuizData
                    : type === "dashboard_class_course"
                    ? dashboardClassCourseData
                    : type === "dashboard_group_assignment"
                    ? dashboardGroupAssignmentData
                    : type === "dashboard_group_quiz"
                    ? dashboardGroupQuizData
                    : type === "dashboard_group_member"
                    ? dashboardGroupMemberData
                    : []
                }
                type={type}
                showingSearchResult={searchQuery.length > 0 ? true : false}
                count={dashboardDataCount}
                onDelete={handleOnDelete}
                onView={handleOnView}
                onStatusChange={handleStatusChange}
                loggedInUserProfileId={loggedInUserProfileId}
              />
            )}

          {/* ----------------- Main Dashboard with Charts ----------- */}

          {type === "dashboard_main" && (
            <div className={styles.charts_container}>
              <div className={styles.line_chart}>
                <LineChartComponent data={lineChartData} />
              </div>
              <div className={styles.pie_chart}>
                <PieChartComponent data={dashboardChartData.modules_percent} />
              </div>
              <div className={styles.bar_chart}>
                <BarChartComponent data={barChartData} />
              </div>
            </div>
          )}

          {/* ------------------- post feed -------------------- */}

          {(type === "dashboard_class_post" ||
            type === "dashboard_group_post") && (
            <div className={styles.post_feed_container}>
              {searchQuery && <p className={styles.not_found}>Search Result</p>}
              {postFeedData?.length > 0 ? (
                postFeedData?.map((post) => (
                  <Post
                    key={post?.id}
                    showBoostBtn={false}
                    name={post?.post_owner_profile?.name}
                    follower={post?.post_owner_profile?.connections}
                    timestamp={post?.date_and_time}
                    message={post?.description || post?.message}
                    tag={post.tags}
                    profileImage={
                      post?.post_owner_profile?.profile_pic ||
                      "./Image/DashImage/profile.png"
                    }
                    photoUrl={post?.image_url || post?.photoUrl}
                    video={post?.video_url || post?.video}
                    file_url={post.file_url}
                    likes={post.likes}
                    likeDetails={post.likedPost}
                    shareLink={post.share_link}
                    tags={post.tags}
                    postId={post.id}
                    comments={post.comments}
                    hideBottom={true}
                    // shareModalOpener={() =>
                    //   this.props.shareModalOpener(post.share_link)
                    // }
                    // menu={
                    //   post?.post_owner_profile?.id == userId
                    //     ? true
                    //     : false
                    // }
                    postData={post}
                    // source="DASHBOARD"
                    language_code={post?.language_code}
                    postLanguage={post?.language_code}
                  />
                ))
              ) : (
                <div className={styles.data_not_found_image}>
                  <img
                    className={styles.no_data_image}
                    src="/Image/EnterpriseDashboard/Empty state.svg"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default EnterpriseFacultyDashboard;
