import React, { Component } from "react";
import styles from "./css/MiddleContainer.module.css";
// import ProfilePic from './Image/DashboardImages/postModImages/profilepic.jpg'
import DropBox from "./DropBox";
import Video from "../Components/Video";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class MiddleContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      selectedOption: "Anyone",
      options: ["Anyone"],
      hashtag: {
        target: {
          value: "#",
        },
      },
      value: this.props?.actionType == "EDIT" ? this.props?.postData?.description : "",
    };
  }

  changeActiveElement = (item) => {
    this.setState({ selectedOption: item });
  };

  updateValue = (e) => {
    this.setState({ value: e.target.value });
    this.props.onChange(e);
  };


  render() {
    return (
      <div>
        {
          this.props.actionType == "EDIT" || this.props.actionType == "ADD"
          ?   <div>
                <div className={styles.container}>
                  <img className={styles.profilePic} src={this.props.profilePicUrl} alt="Profile Pic" />
                  <div className={styles.dropDiv}>
                    <DropBox title={this.props.name ?? this.props.user.first_name + " " + this.props.user.last_name ?? ""} />
                    <DropBox drop light eye title={this.state.selectedOption} options={this.state.options} changeActiveElement={this.changeActiveElement} />
                  </div>
                </div>
                <div style={{position: "relative"}}>
                {this.props.pic || this.props.video ? !this.props.video ?
                 <img src={this.props?.pic} style={{width: '100%', height:'200px', objectFit: 'contain'}} /> : <Video src={this.props.video} /> : null}
                </div>
                 
                  {this.props?.file ? 
                  <p style={{    border: "1px #757575 solid", borderRadius: "20px",padding: "9px",color: "#757575", width: "fit-content",margin: "10px 0"}}>File : {this.props?.state?.mediaFile?.name || this.props?.state?.mediaFileUrl.split("/")[8] ||  this.props?.state?.mediaFileUrl || "uploaded"}</p> : null}
                  {/* {this.props.actionType == 'EDIT' ? this.props.postData.image_url !=null ? <img src={this.props.pic} style={{width: '100%', height:'200px', objectFit: 'contain'}} /> : <Video src={this.props.video} /> : null} */}
                  <div className={styles.textareaContainer}>
                  <textarea
                    // onChange={(e) => this.props.onChange(e)}
                    onChange={(e) => this.updateValue(e)}
                    type="textarea"
                    placeholder={this.props.t("dashboard.shareThoughts")}
                    className={styles.textarea}
                    rows="10"
                  >
                    {this.state.value}
                  </textarea>
                </div>
                </div>
          :  <div className={styles.container}>
              {this.props.t("dashboard.sureDelete")}
             </div>
        }

        {/* <p
          className={styles.hashtag}
          onClick={() => {
            // this.props.onChange(this.state.hashtag)z;
          }}
        >
          Add Hashtags
        </p> */}
      </div>
    );
  }
}
 
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "", 
    name: state.profile.name || "",
    profilePicUrl: state.profile.userProfilePic || "/Image/DashImage/profile.png",
  };
};

export default withTranslation()(connect(mapStateToProps, null)(MiddleContainer));
