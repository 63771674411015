import React, { useState, useEffect } from "react";
import styles from "./AddQuizForm.module.css";
import { useTranslation } from "react-i18next";
import Button from "../../../components/assignmentAndQuiz/Button/Button";
import ButtonMUI from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddArticleVideoComponent from "./AddArticleVideoComponent";
import ContentTypeButtonMenu from "./ContentTypeButtonMenu";
import {
  clearCourses,
  createLecture,
  createSection,
  updateLecture,
  updateSection,
} from "../../../redux/actions/courses";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { handleErrorMessage } from "../../../redux/actions/groups";
import { CircularProgress } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Theme,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { getAllQuizTemplates } from "../../../redux/actions/enhancedQuiz";

const AddQuizForm = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const quizTemplates =
    useSelector((state) => state?.enhancedQuiz?.quizTemplates) ?? [];
  const [selectedTemplate, setSelectedTemplate] = useState([]);

  useEffect(() => {
    if (quizTemplates?.length === 0)
      dispatch(getAllQuizTemplates()).then(() => setIsLoading(false));
  }, []);

  const colortheme = createTheme({
    palette: {
      primary: { main: "#ED1E24" },
    },
  });
  const menuItemStyles = {
    color: "#000",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px" /* 150% */,
    letterSpacing: "0.024px",
    textTransform: "capitalize",
    "@media (max-width: 580px)":{
      maxWidth: "calc(100% - 100px)",
      fontSize: "11px",
      fontWeight: "400"
    }
  };

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
      "@media (max-width: 500px)": {
       fontSize: "10px",
       fontWeight: "400"
      },
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "20px",
      padding: "5px 11px 6px 11px",
    },
  };

  return (
    <div className={styles.main}>
      <div className={styles.add_quiz_container}>
        <span>{t("courseMonetisation.newQuiz")}:</span>
        <ThemeProvider theme={colortheme}>
          <Select
            value={selectedTemplate}
            fullWidth
            displayEmpty
            sx={{
              borderRadius: "25px",
              height: "34px",
              fontFamily: "Montserrat",
              fontSize: "14px",
              fontWeight: "400",
              "@media (max-width: 580px)":{
                color: "var(--text-grey, #555)",
                fontFamily: "Montserrat",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                marginTop: "10px"
              }
            }}
            onChange={(e) => {
              setSelectedTemplate(e.target.value);
            }}
          >
            <MenuItem sx={menuItemStyles} disabled value="">
              <em>{t("enhancedQuiz.selectQuizTemplates")}</em>
            </MenuItem>
            {quizTemplates
              ?.filter((quiz) => quiz.is_objective === true)
              ?.map((item) => (
                <MenuItem
                  key={item.id}
                  className={styles.text}
                  value={item}
                  sx={{
                    fontFamily: "Montserrat",
                    fontSize: "14px",
                    fontWeight: "400",
                    "@media (max-width: 580px)":{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      marginTop: "10px",
                      minHeight: "30px"
                    }
                  }}
                >
                  {item.title}
                </MenuItem>
              ))}
          </Select>
        </ThemeProvider>
      </div>

      <div className={styles.btn_div}>
        <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={props.onCancel}
          sx={styleButtonMUI}
        >
          <span className={styles.btn_label}>{t("common.cancel")}</span>
        </ButtonMUI>

        <ButtonMUI
          variant="outlined"
          disableRipple
          onClick={() => {
            if(!selectedTemplate?.id) {
              dispatch(handleErrorMessage(t("enhancedQuiz.selectQuizTemplate")));
            } else {
              dispatch(clearCourses());
              history.push({
                pathname: `/quiz/create/${props.courseID}`,
                state: { quizTemplateID: selectedTemplate?.id, isCourse: true, sectionID: props.sectionID, courseID: props.courseID  },
              })
            }
          }}
          sx={styleButtonMUI}
        >
          <span className={styles.btn_label}>{t("quiz.addQuiz")}</span>
        </ButtonMUI>
      </div>
    </div>
  );
};

export default AddQuizForm;
