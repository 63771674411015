import React, { useState, useEffect } from "react";
import styles from "./QuestionChoiceComponent.module.css";
import { useTranslation } from "react-i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { produce } from "immer";
import FormGroup from "@mui/material/FormGroup";
import AnswerChoiceDropDown from "./AnswerChoiceDropDown";
import AnswerTextArea from "./AnswerTextArea";
import shuffle from "lodash.shuffle";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { submitQuiz } from "../../../redux/actions/enhancedQuiz";

const QuestionChoiceComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedRadioValue, setSelectedRadioValue] = useState(); //temp
  const [dropDownSelection, setDropDownSelection] = useState({});
  const [answerKey, setAnswerKey] = useState({});
  const [timestamps, setTimestamps] = useState({});

  const [randomizedDropDownChoiceList, setRandomizedDropDownChoiceList] = useState([])

  // Note for myself
  /**
   * Instead of maintaining one state respect to one type,
   * We'll maintain an AnswerKey object, with question IDs as Keys and User's selected answers as an array of values.
   * const answerKey = {
   *      "1" : [101]
   *      "3" : [105, 107, 108]
   *  }
   *
   */

  useEffect(() => {
    let count = 0;
    Object.keys(answerKey)?.forEach((key) => {
      if (answerKey[key]?.length > 0) {
        ++count;
      }
    });
    console.log("total count", count);
    props.getAttemptedQuestionCount(count);
  }, [answerKey]);

  useEffect(() => {
    console.log("submit useEFFECT");
    if (props.trigger) {
      let userAnswers = [];

      Object.keys(answerKey)?.forEach((key) => {
        if (typeof answerKey[key] === "string" && answerKey[key].length > 0) {
          userAnswers.push({
            question: key,
            text: answerKey[key],
            time_left: timestamps[key] || 0,
          });
        } else if (Array.isArray(answerKey[key]) && answerKey[key].length > 0) {
          userAnswers.push({
            question: key,
            chosen_answer: answerKey[key],
            time_left: timestamps[key] || 0,
          });
        }
      });

      console.log("userAnswers", userAnswers);
      dispatch(submitQuiz(props.studentSubmissionID, userAnswers)).then(()=>{
        if(props.isCourse){
          if(props.isShowAnswers){
            props.handleShowAnswers();
          } else {
            props.onClose();
          }
          
        } else {
          // When not a course, and isShowAnswers:true then setShowAnswers(true)
          if(props.isShowAnswers){
            props.handleShowAnswers();
          } else {
            history.push(`/${props.routeType}/${props.classOrGroupId}`)
          }
        }
      });
    }
  }, [props.trigger]);

  useEffect(()=>{
    let updatedAnswerKey = {};
    let updatedDropDownSelection = {};

    if(props.isGrading){
      props.userAnswers.forEach(x => {
        if(Array.isArray(x?.chosen_answer?.[0])){
          x.chosen_answer.forEach(z => {
            updatedDropDownSelection[z[0]] = z[1]
          })
        } else {
          updatedAnswerKey[x.question] = x.chosen_answer ?? x.text
        }
      });

      setAnswerKey(updatedAnswerKey);
      setDropDownSelection(updatedDropDownSelection)
    }    

  }, [props.isGrading, props.userAnswers])

  useEffect(()=>{
    let updatedDropDownSelection = {};

    if(props.isGrading && props.type === "Grid") {
      let newCorrectList = {}; 
      
      props.choices[0]?.correct_list.forEach(x => {
        newCorrectList[x.id] = x.correct_choice
      }) 

      props.userAnswers.forEach(x => {
        if(x.question == props.questionID){
          x.chosen_answer.forEach(z => {
            updatedDropDownSelection[z[0]] = newCorrectList[z[1]]
          })
        } 
      });

      setDropDownSelection({...dropDownSelection, ...updatedDropDownSelection});
    }

  }, [props.questionID])

  const handleCheckboxChange = (e) => {
    let index = answerKey[props.questionID].indexOf(parseInt(e.target.value));

    if (index === -1) {
      setAnswerKey(
        produce(answerKey, (draftAnswerKey) => {
          draftAnswerKey[props.questionID].push(parseInt(e.target.value));
        })
      );

      setTimestamps({
        ...timestamps,
        [props.questionID]: props.currentTime,
      });
    } else {
      setAnswerKey(
        produce(answerKey, (draftAnswerKey) => {
          draftAnswerKey[props.questionID].splice(index, 1);
        })
      );

      setTimestamps({
        ...timestamps,
        [props.questionID]: props.currentTime,
      });
    }
  };

  const handleFibSelection = (choiceID, text) => {
    setDropDownSelection({ ...dropDownSelection, [choiceID]: text });

    let index = answerKey[props.questionID].findIndex(
      (item) => item[0] === choiceID
    );
    if (index === -1) {
      setAnswerKey(
        produce(answerKey, (draftAnswerKey) => {
          draftAnswerKey[props.questionID].push([choiceID, text]);
        })
      );

      setTimestamps({
        ...timestamps,
        [props.questionID]: props.currentTime,
      });
    } else {
      setAnswerKey(
        produce(answerKey, (draftAnswerKey) => {
          draftAnswerKey[props.questionID].splice(index, 1);
          draftAnswerKey[props.questionID].push([choiceID, text]);
        })
      );
      setTimestamps({
        ...timestamps,
        [props.questionID]: props.currentTime,
      });
    }
  };

  /**
   * For grid questions solution, back-end expects the answer array like this:
   *
   * question.id = [
   *    [<choice id>, <id of the text the user chose from the drop down>]
   * ]
   *
   */

  const addGridSelectionToAnswerKey = (choiceID, text) => {
    let index = answerKey[props.questionID].findIndex(
      (item) => item[0] === choiceID
    );
    let textIndexInChoiceArray = props.choices?.findIndex(
      (choice) => choice.correct_choice === text
    );

    if (index === -1) {
      setAnswerKey(
        produce(answerKey, (draftAnswerKey) => {
          draftAnswerKey[props.questionID].push([
            choiceID,
            props.choices[textIndexInChoiceArray].id,
          ]);
        })
      );
      setTimestamps({
        ...timestamps,
        [props.questionID]: props.currentTime,
      });
    } else {
      setAnswerKey(
        produce(answerKey, (draftAnswerKey) => {
          draftAnswerKey[props.questionID].splice(index, 1);
          draftAnswerKey[props.questionID].push([
            choiceID,
            props.choices[textIndexInChoiceArray].id,
          ]);
        })
      );
      setTimestamps({
        ...timestamps,
        [props.questionID]: props.currentTime,
      });
    }
  };

  /**
   *  {
   *    choice_id: "selected text"     *
   *  }
   */
  const getDropDownSelection = (choiceID, text) => {
    setDropDownSelection({ ...dropDownSelection, [choiceID]: text });

    addGridSelectionToAnswerKey(choiceID, text);
  };

  const radioCheckboxStyle = {
    "&.Mui-checked": { color: "#ED1E24" },
    marginRight: "0px",
    fontFamily: "Montserrat",
    "&.Mui-disabled": {
      color: "#959595",
    },
  };

  if (
    props.type == "SingleChoice" ||
    props.type == "True/False" ||
    props.type == "ImageChoice"
  ) {
    {
      !answerKey.hasOwnProperty(props.questionID) &&
        setAnswerKey(
          produce(answerKey, (draftAnswerKey) => {
            draftAnswerKey[props.questionID] = [];
          })
        );
    }

    return (
      <div className={`${ props.isRevealing ? styles.main_disabled : styles.main}`}>
      {console.log("userAnswers QC", props.userAnswers)}
      {console.log("isRevealing QC", props.isRevealing)}
        <FormControl>
          <RadioGroup
            // aria-labelledby="demo-controlled-radio-buttons-group"
            // name="controlled-radio-buttons-group"
            defaultValue=""
            value={`${answerKey[props.questionID]?.[0] ?? ""}`} //chosen value
            onChange={(e) => {
              setAnswerKey(
                produce(answerKey, (draftAnswerKey) => {
                  draftAnswerKey[props.questionID] = [parseInt(e.target.value)];
                })
              );

              setTimestamps({
                ...timestamps,
                [props.questionID]: props.currentTime,
              });
            }}
          >
            {console.log("answerKey", answerKey)}
            {props.choices?.length > 0 &&
              props.choices?.map((choice) => (
                <FormControlLabel
                  key={choice?.id}
                  sx={{ fontFamily: "Montserrat", textTransform: "capitalize" }}
                  // disabled={props.isGrading}
                  value={choice?.id}
                  control={<Radio size="small" sx={radioCheckboxStyle} />}
                  label={
                    props.type == "ImageChoice" ? (
                      <img
                        src={choice?.choice_text}
                        className={styles.image_choice}
                      />
                    ) : (
                      <div style={{
                          color: "#555",
                          fontFamily: "Montserrat",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                      }} >
                        {choice?.choice_text}
                      </div>
                    )
                  }
                />
              ))}
          </RadioGroup>
        </FormControl>

        {/* ---------For Reveal ----------*/}
        {props.isRevealing && props.userAnswers?.filter((item)=>item.question == props.questionID)
          .map(item => 
            <div className={styles.correct_incorrect_div}>
              <img src={item.is_correct ? "/Image/assignment/check_circle-green.svg" : "/Image/assignment/cross-circle-red.svg"}/>
              <span style={{color: item.is_correct ? '#46CB4C':'#E81123'}}>{t(`${item.is_correct ? "common.correct" : "common.incorrect"}`)}</span>
            </div>        
          )        
        }

        {props.isRevealing && 
          props.choices?.filter(item => item.is_correct == true)
          .map(choice => 
          <div className={styles.correct_answer}>
            <p className={styles.answer_label}>{t("common.answer")}:</p> 
            
            {props.type == "ImageChoice" ? 
              <img
                src={choice?.choice_text}
                className={styles.image_choice}
              /> :        
              <p>{choice.choice_text}</p>
            }
          </div>)
        }
      </div>
    );
  }

  if (props.type == "Mcq") {
    {
      !answerKey.hasOwnProperty(props.questionID) &&
        setAnswerKey(
          produce(answerKey, (draftAnswerKey) => {
            draftAnswerKey[props.questionID] = [];
          })
        );
    }
    return (
      <div className={`${ props.isRevealing ? styles.main_disabled : styles.main}`}>
        {console.log("answerKey", answerKey)}
        <FormControl>
          <FormGroup>
            {props.choices?.length > 0 &&
              props.choices?.map((choice) => (
                <FormControlLabel
                  key={choice?.id}
                  sx={{ fontFamily: "Montserrat", textTransform: "capitalize" }}
                  label={choice?.choice_text}
                  control={
                    <Checkbox
                      size="small"
                      sx={radioCheckboxStyle}
                      value={choice?.id}
                      checked={answerKey[props.questionID]?.includes(
                        choice?.id
                      )}
                      // disabled={props.isGrading}
                      onChange={handleCheckboxChange}
                    />
                  }
                />
              ))}
          </FormGroup>
        </FormControl>

        {/* ---------For Reveal ----------*/}
        {props.isRevealing && props.userAnswers?.filter((item)=>item.question == props.questionID)
          .map(item => 
            <div className={styles.correct_incorrect_div}>
              <img src={item.is_correct ? "/Image/assignment/check_circle-green.svg" : "/Image/assignment/cross-circle-red.svg"}/>
              <span style={{color: item.is_correct ? '#46CB4C':'#E81123'}}>{t(`${item.is_correct ? "common.correct" : "common.incorrect"}`)}</span>
            </div>        
          )        
        }
        
        {props.isRevealing && <p className={styles.answer_label_mcq}>{t("common.answer")}:</p> }

        {props.isRevealing && 
          props.choices?.filter(item => item.is_correct == true)
          .map(choice => 
          <div className={styles.correct_answer_mcq}>
            <p>{choice.choice_text}</p>            
          </div>)
        }
      </div>
    );
  }

  if (props.type == "Fib") {
    {
      !answerKey.hasOwnProperty(props.questionID) &&
        setAnswerKey(
          produce(answerKey, (draftAnswerKey) => {
            draftAnswerKey[props.questionID] = [];
          })
        );
    }

    // let randomizedDropDownChoiceList = [];
    // if (props.choices?.length > 0) {
    //   randomizedDropDownChoiceList = shuffle(
    //     props.choices[0]?.correct_list?.map((x) => x.correct_choice)
    //   );
    // }

    return (
      <div className={`${ props.isRevealing ? styles.main_disabled : styles.main}`}>
        {console.log("userAnswers QC", props.userAnswers)}
      {console.log("isRevealing QC", props.isRevealing)}
        {console.log("answerKey", answerKey)}
        {props.choices?.length > 0 &&
          props.choices?.map((choice, index) => (
            <div key={choice?.id} className={styles.fib_choice_container}>
              <div className={styles.span_container}>
                <span className={styles.list_number_span}>{`${
                  index + 1
                }.`}</span>
                <span>{` _______ `}</span>
              </div>
              {/* add dropdown here */}
              <div className={styles.dropdown_container}>
                <span
                  className={styles.list_number_span}
                >{`${String.fromCharCode(index + 1 + 64)}. `}</span>
                <AnswerChoiceDropDown
                  choiceID={choice?.id}
                  // options={choice?.correct_list?.map((x) => x.correct_choice)}
                  isDisabled = {props.isGrading}
                  value={dropDownSelection[choice?.id] ?? ""}
                  options={props.choices[0]?.correct_list?.map((x) => x.correct_choice).reverse()}
                  getSelection={handleFibSelection}
                />
              </div>
            </div>
          ))}

          {/* ---------For Reveal ----------*/}
        {props.isRevealing && props.userAnswers?.filter((item)=>item.question == props.questionID)
          .map(item => 
            <div className={styles.correct_incorrect_div}>
              <img src={item.is_correct ? "/Image/assignment/check_circle-green.svg" : "/Image/assignment/cross-circle-red.svg"}/>
              <span style={{color: item.is_correct ? '#46CB4C':'#E81123'}}>{t(`${item.is_correct ? "common.correct" : "common.incorrect"}`)}</span>
            </div>        
          )        
        }

        {props.isRevealing && <p className={styles.answer_label_mcq}>{t("common.answer")}:</p> }

        {props.isRevealing && 
          props.choices.map(choice => 
          <div className={styles.correct_answer_mcq}>
            <p>{choice.correct_choice}</p>            
          </div>)
        }
      </div>
    );
  }

  if (props.type == "Grid") {
    {
      !answerKey.hasOwnProperty(props.questionID) &&
        setAnswerKey(
          produce(answerKey, (draftAnswerKey) => {
            draftAnswerKey[props.questionID] = [];
          })
        );
    }

    // let randomizedDropDownChoiceList = [];
    // if (randomizedDropDownChoiceList.length === 0) {
    //   randomizedDropDownChoiceList = shuffle(
    //     props.choices[0]?.correct_list?.map((x) => x.correct_choice)
    //   );
    // }

    return (
      <div className={`${ props.isRevealing ? styles.main_disabled : styles.main}`}>
        {console.log("userAnswers QC", props.userAnswers)}
      {console.log("isRevealing QC", props.isRevealing)}
        {console.log("answerKey", answerKey)}
        {console.log("dropDownSelection", dropDownSelection)}
        {props.choices?.length > 0 &&
          props.choices?.map((choice, index) => (
            <div key={choice?.id} className={styles.grid_choice_container}>
              <div className={styles.span_container}>
                <span className={styles.list_number_span}>{`${
                  index + 1
                }.`}</span>
                <span>{choice?.choice_text}</span>
              </div>
              {/* add dropdown here */}
              <div className={styles.dropdown_container}>
                <span
                  className={styles.list_number_span}
                >{`${String.fromCharCode(index + 1 + 64)}. `}</span>
                <AnswerChoiceDropDown
                  // options={shuffle(
                  //   choice?.correct_list?.map((x) => x.correct_choice)
                  // )}
                  options={props.choices[0]?.correct_list?.map((x) => x.correct_choice).reverse()}
                  choiceID={choice?.id}
                  value={dropDownSelection[choice?.id] ?? ""}
                  getSelection={getDropDownSelection}
                />
              </div>
            </div>
          ))}

          {/* ---------For Reveal ----------*/}
        {props.isRevealing && props.userAnswers?.filter((item)=>item.question == props.questionID)
          .map(item => 
            <div className={styles.correct_incorrect_div}>
              <img src={item.is_correct ? "/Image/assignment/check_circle-green.svg" : "/Image/assignment/cross-circle-red.svg"}/>
              <span style={{color: item.is_correct ? '#46CB4C':'#E81123'}}>{t(`${item.is_correct ? "common.correct" : "common.incorrect"}`)}</span>
            </div>        
          )        
        }

        {props.isRevealing && <p className={styles.answer_label_mcq}>{t("common.answer")}:</p> }

        {props.isRevealing && 
          props.choices.map(choice => 
          <div className={styles.correct_answer_mcq}>
            <p>{choice.correct_choice}</p>            
          </div>)
        }
      </div>
    );
  }

  if (props.type == "ShortAnswer" || props.type == "Paragraph") {
    {
      !answerKey.hasOwnProperty(props.questionID) &&
        setAnswerKey(
          produce(answerKey, (draftAnswerKey) => {
            draftAnswerKey[props.questionID] = "";
          })
        );
    }
    return (
      <div className={`${`${ props.isRevealing ? styles.main_disabled : styles.main}`} ${styles.answer_container}`}>
        {console.log("userAnswers QC", props.userAnswers)}
      {console.log("isRevealing QC", props.isRevealing)}
        {console.log("answerKey", answerKey)}
        <AnswerTextArea
          isLong={props.type == "Paragraph" ? true : false}
          // disabled={props.isGrading}
          placeholder={t("enhancedQuiz.enterAnswer")}
          value={answerKey[props.questionID]}
          questionID={props.questionID}
          getUserText={(userText) => {
            setAnswerKey(
              produce(answerKey, (draftAnswerKey) => {
                draftAnswerKey[props.questionID] = userText;
              })
            );
            setTimestamps({
              ...timestamps,
              [props.questionID]: props.currentTime,
            });
          }}
        />

        {/* -------- For Reveal ---------- */}
        {props.isRevealing && !props.isGrading &&
        <div className={styles.correct_incorrect_div}>
          <span className={styles.not_graded_yet_text}>
            * {t("enhancedQuiz.notGradedYet")}
          </span>
        </div>}
      </div>
    );

    
  } else {
    return (
    <div className={`${styles.main}`}>
      {console.log("userAnswers NQ", props.userAnswers)}
       <h2 className={styles.not_found_text}>{t("enhancedQuiz.noQuestionsFound")}</h2>
    </div>
    )
  }
};

export default QuestionChoiceComponent;
