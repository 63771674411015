import React, { useEffect } from "react";
import styles from "./QuizSettings.module.css";
import DropDown from "./DropDown";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import { useState } from "react";

const QuizSettings = (props) => {
  const [chosenSettings, setChosenSettings] = useState({
    order: false,
    attempts: 1,
    reveal: false,
    isTimed: false,
    duration: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const orderDropDown = [
    "enhancedQuiz.doNotReshuffleQuestions",
    "enhancedQuiz.reshuffleQuestions",
  ];

  const attemptsDropDown = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const revealDropDown = [
    "enhancedQuiz.doNotRevealAnswers",
    "enhancedQuiz.revealAfter",
  ];
  const { t } = useTranslation();

  useEffect(() => {
    // props.getSettings(chosenSettings);
    let finalSettings = { ...chosenSettings };
    let totalTime =
      chosenSettings?.hours * 60 +
      chosenSettings?.minutes +
      chosenSettings?.seconds / 60;
    finalSettings.duration = totalTime;
    // console.log("finalSettings", finalSettings);
    props.getQuizSettings(finalSettings);
  }, [chosenSettings]);


  const handleSettings = (value, type) => {
    let settings = { ...props.chosenSettings };

    if (type == "order") {
      settings.order = !value.toLowerCase().includes("not");
    } else if (type == "reveal") {
      settings.reveal = !value.toLowerCase().includes("not");
    } else {
      settings.attempts = value;
    }

    setChosenSettings(settings);
  };

  console.log("quizSettings-Z", chosenSettings, props.chosenSettings);

  const switchStyle = {
    borderRadius: 2,
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ED1E24",
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#ED1E24",
    },
    // "&:hover .MuiSwitch-switchBase": {
    //   color: '#ED1E24'
    // },
  };

  return (
    <div className={styles.main}>
      <div className={styles.header_top}>
        <div className={styles.header}>
          <img src="/Image/assignment/settings2.svg" alt="settings" />
          <span>{t("header.settings")}</span>
        </div>
        <div className={styles.header_cross}>
          <img src="/Image/assignment/Cross.svg" alt="settings" onClick={props.closeSettingModal} />
        </div>
      </div>
      

      <div className={styles.time}>
        <div className={styles.time_switch}>
          <span>{t("enhancedQuiz.timeToComplete")}:</span>
          <Switch
            checked={props.chosenSettings?.isTimed}
            onChange={(event) => {
              setChosenSettings({
                ...chosenSettings,
                isTimed: event.target.checked,
                hours: 0,
                minutes: 0,
                seconds: 0,
              });
            }}
            sx={switchStyle}
          />
        </div>

        <div className={styles.time_container}>
          {/* <Switch
            checked={chosenSettings?.isTimed}
            onChange={() => {
                setChosenSettings({
                  ...chosenSettings,
                  isTimed: !chosenSettings?.isTimed,
                  hours: 0,
                  minutes: 0,
                  seconds: 0,
                });              
            }}
            sx={switchStyle}
          /> */}
          {/* <span>{t("enhancedQuiz.timeLimit")}</span> */}
          <div className={styles.time_unit}>
            <input
              type="number"
              className={styles.time_input}
              disabled={!props.chosenSettings?.isTimed}
              value={props.chosenSettings?.hours}
              onChange={(e) => {
                setChosenSettings({
                  ...chosenSettings,
                  hours: parseInt(e.target.value),
                });
              }}
            />{" "}
            <span>{t("enhancedQuiz.hours")}</span>
          </div>
          <div className={styles.time_unit}>
            <input
              type="number"
              className={styles.time_input}
              disabled={!props.chosenSettings?.isTimed}
              value={props.chosenSettings?.minutes}
              onChange={(e) => {
                setChosenSettings({
                  ...chosenSettings,
                  minutes: parseInt(e.target.value),
                });
              }}
            />{" "}
            <span>{t("enhancedQuiz.minutes")}</span>
          </div>
          <div className={styles.time_unit}>
            <input
              type="number"
              className={styles.time_input}
              disabled={!props.chosenSettings?.isTimed}
              value={props.chosenSettings?.seconds}
              onChange={(e) => {
                setChosenSettings({
                  ...chosenSettings,
                  seconds: parseInt(e.target.value),
                });
              }}
            />{" "}
            <span>{t("enhancedQuiz.seconds")}</span>
          </div>
        </div>
      </div>

      <div className={styles.order}>
        <span>{t("enhancedQuiz.orderOfQuestions")}:*</span>
        <DropDown
          options={orderDropDown}
          getSelection={handleSettings}
          type={"order"}
          actionType={props?.actionType}
          value={props?.chosenSettings?.order}
        />
      </div>

      <div className={styles.attempts}>
        <span>{t("enhancedQuiz.numberOfAttempts")}:*</span>
        <DropDown
          options={attemptsDropDown}
          getSelection={handleSettings}
          type={"attempts"}
          value={props?.chosenSettings?.attempts}
          actionType={props?.actionType}
        />
      </div>

      <div className={styles.reveal}>
        <span>{t("enhancedQuiz.revealAnswers")}:*</span>
        <DropDown
          options={revealDropDown}
          getSelection={handleSettings}
          type={"reveal"}
          value={props?.chosenSettings?.reveal}
          actionType={props?.actionType}
        />
      </div>
    </div>
  );
};

export default QuizSettings;
