import React from 'react'
import styles from '../css/QuestionTypeModal.module.css'
import { useTranslation } from 'react-i18next';

const QuestionTypeModal = (props) => {
  const {t} = useTranslation();
  // ${t("courseMonetisation.students")}

  let questionTypes = [
    {
      'icon':'/Image/assignment/radio_button_checked.svg',
      'text':'enhancedQuiz.mcq',
      'type': 'mcq',
    },
    {
      'icon':'/Image/assignment/horizontal_rule.svg',
      'text':'enhancedQuiz.fillInBlanks',
      'type': 'fillInBlanks',
    },
    {
      'icon':'/Image/assignment/check_box.svg',
      'text':'enhancedQuiz.checkboxes',
      'type': 'checkboxes',
    },
    
    {
      'icon':'/Image/assignment/toggle_on.svg',
      'text':'enhancedQuiz.trueFalse',
      'type': 'trueFalse',
    },
    {
      'icon':'/Image/assignment/apps.svg',
      'text':'enhancedQuiz.grid',
      'type': 'grid',
    },
    {
      'icon':'/Image/assignment/collections.svg',
      'text':'enhancedQuiz.imageChoice',
      'type': 'imageChoice',
    },
  ]

  if (!props.isCourse) {    
    let types = [
      {
        'icon':'/Image/assignment/view_headline.svg',
        'text':'enhancedQuiz.paragraph',
        'type': 'paragraph',
      },
      {
        'icon':'/Image/assignment/short_text.svg',
        'text':'enhancedQuiz.shortAnswer',
        'type': 'shortAnswer',
      },
    ];

    questionTypes = questionTypes.concat(types)
  }



  return (
    <div className={styles.modal_container}>
        <div className={styles.header}>
            <span>Choose the type of questions:</span>
          </div>        

        <div className={styles.question_types}>
          {questionTypes.map((item, index)=><div key={index} className={styles.question_icon} onClick={()=>props.redirect(item.icon, item.type)}>
            <img alt="" src={item.icon} />
            <span>{t(item.text)}</span>
          </div>)}
        </div>
    </div>
  )
}

export default QuestionTypeModal;