import React from "react";

import styles from "../../../../Profile.module.css";

import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

import { connect } from "react-redux";

class EditFeatured extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      featuredIds: [],
      featuredContent: this.props.posts,
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    this.props.changeEditFeatured(false);
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    this.props.editFeaturedContent(this.state.featuredContent);
    this.props.changeEditFeatured(false);
    document.body.style.overflow = "visible";
  }

  ScrollBody() {
    return (
      <div>
        {this.props.featuredContent.map((item, idx) => {
          if (item.image_url != null) {
            return (
              <div key={idx} style={{ margin: 5, flex: 1 }}>
                <div
                  style={{
                    display: "flex",
                    marginBottom: 20,
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 50, width: "40%" }}>
                      <img
                        className={styles.card_img_top}
                        src={item.image_url}
                        alt="Card image cap"
                        height="120px"
                        width="200px"
                        // style={{objectFit: "cover"}}
                      />
                    </div>
                    <div style={{ margin: "10px 0px 10px 0px", width: "200px" }}>
                      <p className={styles.modal_heading} style={{ marginLeft: 0 }}>
                        {item.title}
                      </p>
                      <p className={(styles.section_desc, styles.ellipse)}>{item.description}</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/Image/apps/profile-resume-edit-view/delete.svg"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        let content = this.props.featuredContent;
                        content.splice(idx, 1);
                        this.setState({ featuredContent: content });
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.title} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    featuredContent: state.dashboard.posts,
  };
};
export default connect(mapStateToProps, null)(EditFeatured);
