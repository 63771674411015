import React, { useState, useEffect } from "react";
import styles from "../css/GradebookStudentDetails.module.css";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, CircularProgress } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import TooltipWrapper from "../../CourseMonetization/Components/TooltipWrapper";

const ButtonMenuComponent = (props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      disableScrollLock={false}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      // minWidth: 180,
      // color:
      //   theme.palette.mode === "light"
      //     ? "rgb(55, 65, 81)"
      //     : theme.palette.grey[300],
      color: "#000",
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          backgroundColor: "#FFCFD0",
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: "#FFCFD0",
        },
        "&:hover": {
          backgroundColor: "#FFCFD0",
        },
      },
    },
  }));

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "22px",
      padding: "5px 11px 6px 11px",
    },
  };

  const styleArrowDownIcon = {
    width: "15px",
    height: "15px",
  };

  const styleProgress = {
    opacity: props.isPrinting ? 1 : 0,
    position: "absolute",
    top: "20%",
    left: "45%",
  }


  return (
    <div className={styles.btn_container}>
      {props.showSort && <TooltipWrapper title={t("gradebook.sortInDesc")}>
        <div className={styles.btn} onClick={handleClick}>
          <img src="/Image/Gradebook/sort-red.svg" alt="icon" />
          <p>{t("common.sortBy")}</p>
        </div>
      </TooltipWrapper>}

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            props.onSort("date");
          }}
          disableRipple
        >
          {/* <img
            src={"/Image/CourseMonetisation/video-line.svg"}
            className={styles.menu_icon}
          /> */}
          <span className={styles.menu_item_label}>{t("common.date")}</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            props.onSort("marks");
          }}
          disableRipple
        >
          {/* <img
            src={"/Image/lecturePlan/article-(student).svg"}
            className={styles.menu_icon}
          /> */}
          <span className={styles.menu_item_label}>
            {t("enhancedQuiz.marks")}
          </span>
        </MenuItem>
      </StyledMenu>

      <div className={styles.btn2} onClick={props.onPrint}>
        <img src="/Image/Gradebook/pdf-outline.svg" alt="icon" />
        <CircularProgress sx={styleProgress} color="inherit" size={20} />
        <p className={props.isPrinting && styles.hide_print_label}>{t("common.print")}</p>
      </div>
    </div>
  );
};

export default ButtonMenuComponent;
