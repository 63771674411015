import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  deleteAssignment,
  getAllAssignments,
  getAllAssignmentsQuizes,
} from "../../redux/actions/assignment";
import { changeActiveSection } from "../../redux/actions/classes";
import { deleteQuiz, getAllQuizzes } from "../../redux/actions/quiz";
import styles from "../AssignmentAndQuiz/css/AssignmentFeed.module.css";
import AssignmentPost from "./AssignmentPost";
import { CircularProgress } from "@mui/material";

class AssignmentFeed extends Component {
    constructor(props){
        super(props);
        this.state = {
            anchorEl: null,
            open: false,
            isLoading: true
        };
    }

  componentDidMount(){
    const routeType = window.location.pathname.split('/')[1] 
      // this.props.getAllAssignments(this.props.classId, routeType);
      // this.props.getAllQuizzes(this.props.classId, routeType);
    this.setState({isLoading:false})
  }  
  handleDeleteAssignment = (assignmentId) => {
    const routeType = window.location.pathname.split('/')[1] 
    const classGrpId = window.location.pathname.split('/')[2] 
    this.props.deleteAssignment(assignmentId).then(() =>{this.setState({anchorEl: null, open: false});})
  }

  handleDeleteQuiz = (quizId) => {
    const routeType = window.location.pathname.split('/')[1] 
    const classGrpId = window.location.pathname.split('/')[2] 
    this.props.deleteQuiz(quizId).then(() =>{this.setState({anchorEl: null, open: false});});
  };
  typeAsgOrQuiz = (asgQuiz)=> {
    if(window.location.pathname.split('/')[1] === "group"){
      if(this.props?.groupOwner === this.props?.userId){
        return asgQuiz?.name?.toUpperCase()
      }else{
        if(asgQuiz.hasOwnProperty('assignment_date')){
          return "ASSIGNMENT"
        }else{
          return "QUIZ"
        }
      }
    }else{
      if(this.props?.classOwner === this.props?.userId){
        return asgQuiz?.name?.toUpperCase()
      }else{
        if(asgQuiz.hasOwnProperty('assignment_date')){
          return "ASSIGNMENT"
        }else{
          return "QUIZ"
        }
      }
    }
     
  }
  render() {
    const { t } = this.props;
    return (
      <div>
           {/* { console.log(this.props?.allAssignmentQuiz, "allassgQuiz")} */}
        {
          this.state?.isLoading ?<div className={styles.loader}><CircularProgress sx={{color: "#ED1E24"}} size={40} /> </div>:
      <>
        <div className={styles.assignment_feed}>
          {this.props?.allAssignmentQuiz?.length  ===
          0 ? (
            <p className={styles.noAssignmentOrQuiz}>
              {/* {t("noWorkAssigned")} */}
              {this.props.isOwner 
                ? t("common.noAssignmentsOrQuizzes")
                : t("common.noWorkAssigned")}
            </p>
          ) : null}
        </div>
        {this.props?.allAssignmentQuiz?.filter(item => item.name == "Assignment")?.map((asgQuiz) => (
          <AssignmentPost
            assignment={asgQuiz}
            isClassOwner={this.props?.classOwner === this.props?.userId}
            isGroupOwner={this.props?.groupOwner === this.props?.userId}
            isOwner={this.props?.isOwner}
            type={asgQuiz?.name?.toUpperCase()}
            // type={this.typeAsgOrQuiz(asgQuiz)}
            isTeacher={this.props.isTeacher}
            handleDeleteAssignment={asgQuiz?.name =='Quiz' ? this.handleDeleteQuiz : this.handleDeleteAssignment}
            routeType={ window.location.pathname.split('/')[1] === "group" ? "group" : "class" }
            key={asgQuiz?.id}
            // menu={true}
          />
        ))}
        {/* {this.props?.assignment?.map((assignment, index) => (
          <AssignmentPost
            assignment={assignment}
            isClassOwner={this.props?.classOwner === this.props?.userId}
            isGroupOwner={this.props?.groupOwner === this.props?.userId}
            type="ASSIGNMENT"
            isTeacher={this.props.isTeacher}
            handleDeleteAssignment={this.handleDeleteAssignment}
            routeType={ window.location.pathname.split('/')[1] === "group" ? "group" : "class" }
            key={assignment.id}
            // menu={true}
          />
        ))} */}
      </>
        }       
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    //U can destructure here to get only the required attributes from the state
    return {
        assignment: state?.assignment?.assignments,
        classOwner: state?.classes?.class?.class_owner,
        groupOwner: state?.groups?.group?.group_owner,
        userId: state?.profile?.profileId,
        classId: window.location.pathname.split('/')[1] === "group" ? state?.groups?.group?.id :state?.classes?.class?.id,
        quizzes: state?.quiz?.quizzes,
        isTeacher: state?.profile?.user?.user_subtype === "FACULTY",
        isClassOwner: state?.classes?.class?.class_owner === state?.profile?.profileId,
        isGroupOwner: state?.groups?.group?.group_owner === state?.profile?.profileId, 
        // isOwner: window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.group_owner === state?.profile?.profileId) : (state?.profile?.user?.user_subtype == "FACULTY"),
        isOwner: window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.hasAccess) : (state?.classes?.class?.hasAccess),
        allAssignmentQuiz : (window.location.pathname.split('/')[1] == 'class' ? (state?.profile?.user?.user_subtype === "FACULTY") : state?.groups?.group?.group_owner === state?.profile?.profileId) ?
         [...state?.assignment?.assignments,...state?.quiz?.quizzes ].sort(function(x, y){
            return new Date(x?.utimestamp) < new Date(y?.utimestamp) ? 1 : -1
          })
          :
           [...[...state?.assignment?.assignments].map(({ assignment_date: timestamp,...rest}) => ({timestamp, ...rest})),
            ... [...state?.quiz?.quizzes].map(({ quiz_date: timestamp,...rest}) => ({timestamp, ...rest}))].sort(function(x,y){
            return new Date(x?.timestamp) < new Date(y?.timestamp) ? 1 : -1
            })
        || null
        // allAssignmentQuiz : state?.assignment?.allAssignmentQuiz,
    };
  };

function mapDispatchToProps(dispatch) {
    return {
        getAllAssignments: (classId, type) => dispatch(getAllAssignments(classId, type)),
        getAllQuizzes: (classId, type) => dispatch(getAllQuizzes(classId, type)),
        deleteAssignment: (assignmentId) => dispatch(deleteAssignment(assignmentId)),
        deleteQuiz: (quizId) => dispatch(deleteQuiz(quizId)),
        changeActiveSection: (sectionName) => dispatch(changeActiveSection(sectionName)),
        // getAllAssignmentsQuizes: (id,type) => dispatch(getAllAssignmentsQuizes(id,type))
    };
}


export default withTranslation()(connect(mapStateToProps,mapDispatchToProps)(AssignmentFeed));