import { useState, useEffect } from "react";
import styles from "./CourseQuizView.module.css";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ButtonMUI from "@mui/material/Button";
import { getIndividualQuiz } from "../../../redux/actions/enhancedQuiz";
import { CircularProgress } from "@mui/material";
import Modal from "../../../components/commons/Modal";
import AttemptQuizComponent from "../../AssignmentAndQuiz/Components/AttemptQuizComponent";

const CourseQuizView = (props) => {
  const isMobile = window.innerWidth < 768;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [showAttemptQuizModal, setShowAttemptQuizModal] = useState(false);
  const currentQuiz =
    useSelector((state) => state?.enhancedQuiz?.individualQuiz) ?? [];

  useEffect(() => {
    if (props.quizID) {
      dispatch(getIndividualQuiz(props.quizID)).then(() => setIsLoading(false));
    }
  }, [props.quizID]);

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "25px",
      padding: "15px",
      width: "150px"
    },
  };

  if (isLoading) {
    return (
      <div className={styles.loader_style}>
        <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
      </div>
    );
  } else {
    return (
      <div className={styles.main}>
        {showAttemptQuizModal && (
          <Modal
            card={
              <AttemptQuizComponent
                currentQuiz={currentQuiz}
                isCourse={true}
                onClose={() =>{props.onClose().then(()=>setShowAttemptQuizModal(false)) }}
              />
            }
            // onOutsideClick={()=>console.log('clickedOutside Hell')}
          />
        )}
        <div className={styles.title_container}>
          <p className={styles.title}>{currentQuiz?.title || ""}</p>
          <p className={styles.count}>
            {`${t("quiz.quiz")}: ${currentQuiz?.template?.ques_count || ""} ${t(
              "enhancedQuiz.questions"
            )}`}{" "}
          </p>
        </div>

        <div className={styles.middle_container}>
          <div className={styles.instructions}>
            <span>{t("assignment.instructions")}</span>
            <p>{currentQuiz?.details || "attempt all questions"}</p>
          </div>

          {isMobile ? '' : (<ButtonMUI
            variant="outlined"
            disableRipple
            disabled={
              currentQuiz?.user_submission?.attempt > currentQuiz?.reattempt
            }
            onClick={() => {
              props.closeDrawer();
              setShowAttemptQuizModal(true);
            }}
            sx={styleButtonMUI}
          >
            <span className={styles.btn_label}>
              {t("enhancedQuiz.startQuiz")}
            </span>
          </ButtonMUI>)}
          
        </div>

        <div className={styles.bottom_container}>
          <div className={styles.bottom_container_top}>
            <span className={styles.max_marks_text}>{t("assignment.maxMarks")}: </span>
            <span className={styles.marks_value}>{currentQuiz?.max_marks}</span>
          </div>

          <div className={styles.bottom_container_down}>
            <span className={styles.your_grade_text}>{t("courseMonetisation.yourGrade")}: </span>
            <span className={styles.marks_value}>{currentQuiz?.user_submission?.marks}</span>
          </div>
        </div>
        {isMobile ? 
          (<ButtonMUI
            variant="outlined"
            disableRipple
            disabled={
              currentQuiz?.user_submission?.attempt > currentQuiz?.reattempt
            }
            onClick={() => {
              props.closeDrawer();
              setShowAttemptQuizModal(true);
            }}
            sx={styleButtonMUI}
          >
            <span className={styles.btn_label}>
              {t("enhancedQuiz.startQuiz")}
            </span>
          </ButtonMUI>)
          : 
          ''
          }
      </div>
    );
  }
};

export default CourseQuizView;
