import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./ContentDrawer.module.css";

//set drawer width according to screen size
let screenWidth = window.innerWidth;
const isMobile = screenWidth <= 600;
const isTablet = screenWidth > 600 && screenWidth < 1001;
const drawerWidth = isMobile ? '100%' : (isTablet ? '50%':'25%');

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const ContentDrawer = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [activeLecture, setActiveLecture] = useState(
    props.sections[0]?.quizOrLecture[0]?.id ?? ""
  );

  const getFileIcon = (fileType, isCompleted, quizSubmissionStatus) => {
    
    if(quizSubmissionStatus == "SUB" || quizSubmissionStatus == "DL" || quizSubmissionStatus == "GRD") {
      return "/Image/lecturePlan/quiz-completed.svg"
    }

    if (isCompleted) {
      switch (fileType) {
        case "pdf":
          return "/Image/lecturePlan/article-(student)-green.svg";
        case "ppt":
        case "pptx":
          return "/Image/CourseMonetisation/green-variant/file-ppt-line.svg";
        case "doc":
        case "docx":
          return "/Image/CourseMonetisation/green-variant/file-word-line.svg";
        case "xls":
        case "xlsx":
          return "/Image/CourseMonetisation/green-variant/file-excel-line.svg";
        case "mp3":
          return "/Image/CourseMonetisation/green-variant/music-line.svg";
        case "video":
          return "/Image/CourseMonetisation/green-variant/video-line.svg";
        case "jpg":
        case "jpeg":
        case "png":
          return "/Image/CourseMonetisation/green-variant/image-line.svg";
        default:
          return "/Image/CourseMonetisation/green-variant/file-code-line.svg";
      }
    } else {
      
      if(quizSubmissionStatus === "AS") {  
        return "/Image/lecturePlan/quiz.svg"
      }
      
      switch (fileType) {
        case "pdf":
          return "/Image/lecturePlan/article-(student).svg";
        case "ppt":
        case "pptx":
          return "/Image/CourseMonetisation/file-ppt-line.svg";
        case "doc":
        case "docx":
          return "/Image/CourseMonetisation/file-word-line.svg";
        case "xls":
        case "xlsx":
          return "/Image/CourseMonetisation/file-excel-line.svg";
        case "mp3":
          return "/Image/CourseMonetisation/music-line.svg";
        case "video":
          return "/Image/CourseMonetisation/video-line.svg";
        case "jpg":
        case "jpeg":
        case "png":
          return "/Image/CourseMonetisation/image-line.svg";
        default:
          return "/Image/CourseMonetisation/file-code-line.svg";
      }
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={props.onOpen}
        edge="start"
        className={styles.slider_icon}
        sx={{ marginTop: 2, mr: 2, ...(props.isOpen && { display: "hidden" }) }}
      >
        <FormatListBulletedIcon sx={{ paddingLeft: 2 }} />
      </IconButton>

      <Drawer
        sx={{
          //   marginTop: 20,
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={props.isOpen}
        PaperProps={{
          sx: {
            height: "calc(100% - 90px)",
            top: 89,
            boxShadow: 8,
            "@media (max-width: 600px)": {
              height: "calc(100% - 50px)",
              top: 65,
              boxShadow: 8,
              zIndex: 50
            },
          },
        }}
      >
        <DrawerHeader>
          <div className={styles.drawer_header}>
            <IconButton color="inherit" onClick={props.onClose}>
              <FormatListBulletedIcon />
            </IconButton>
            {t("common.content")}
          </div>
          <IconButton onClick={props.onClose}>
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </DrawerHeader>
        <Divider />

        {/* ----- accordion ----- */}
        {props.sections?.length > 0 &&
          props.sections?.map((section, sectionIndex) => (
            <Accordion key={section.id} disableGutters>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: "black", }} />}
                sx={{
                  "@media (max-width: 600px)": {
                    backgroundColor: "#F8F9FB",
                    margin: "0px"
                  }
                }}
              >
                <span className={styles.section_title}>{`${t(
                  "common.section"
                )} ${sectionIndex + 1}: ${section.title}`}</span>
              </AccordionSummary>
              <Divider />
              {/* lecture array */}
              {section?.quizOrLecture?.length > 0 &&
                section?.quizOrLecture?.map((lecture, lectureIndex) => (      
                  <AccordionDetails
                    key={lecture.id}
                    onClick={() => {
                      setActiveLecture(lecture.id);
                      props.onSelection(lecture, sectionIndex, lectureIndex);
                    }}
                    sx={{
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      backgroundColor: `${
                        activeLecture == lecture.id ? "var(--Light2, #F87377);" : "#FFFFFF"
                      }`,
                      "@media (max-width: 600px)": {
                        backgroundColor: "#F8F9FB"
                      }
                    }}
                  >
                    <div className={styles.lecture_container}>
                      <img
                        className={styles.icon}
                        src={
                          activeLecture == lecture.id && (lecture?.completed || lecture?.sub_status === "SUB" || lecture?.sub_status === "DL" || lecture?.sub_status === "GRD")
                            ? "/Image/lecturePlan/check_circle-green.svg"
                            : getFileIcon(lecture.fileType, lecture.completed, lecture.sub_status)
                        }
                      />
                      <span
                        className={
                          lecture.completed
                            ? styles.lecture_title_completed
                            : lecture.sub_status == "GRD" 
                            ? styles.lecture_title_completed
                            : activeLecture == lecture.id
                            ? styles.lecture_title_active
                            : styles.lecture_title
                        }
                      >
                        {lecture.title}
                      </span>{" "}
                      <span
                        // className={
                        //   activeLecture == lecture.id
                        //     ? styles.duration_active
                        //     : lecture.completed
                        //     ? styles.duration_completed
                        //     : styles.duration
                        // }
                        className={
                          lecture.completed
                            ? styles.duration_completed
                            : activeLecture == lecture.id
                            ? styles.duration_active
                            : styles.duration
                        }
                      >
                        {lecture?.duration < 60
                          ? `${Math.floor(lecture?.duration)}m`
                          : `${Math.floor(
                              lecture?.duration / 60
                            )}h ${Math.floor(lecture?.duration % 60)}m`}
                      </span>
                    </div>
                  </AccordionDetails>
                ))}
            </Accordion>
          ))}
      </Drawer>
    </Box>
  );
};

export default ContentDrawer;
