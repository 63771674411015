import React from 'react';
import './InfoCard.css';


export default function InfoCard(props){

    return(
        <>
            <div style={{background:props.background,color:props.color}} className="InfoCard">
                <h4>{props.heading}</h4>
                <h6>{props.level}</h6>
                <p>{props.desc}</p>
            </div>
        </>
    )

}