import React, { useState, useEffect } from "react";
import styles from "./zoomSubscription.module.css";
import { connect } from "react-redux";
import {
  getUserSettings,
  putUserSettings,
} from "../../../redux/actions/zoomAction";
import { withRouter } from "react-router";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@mui/material/Alert";
import MuiAlert from "@material-ui/lab/Alert";
import { errorMessageFromResponse } from "../../../redux/constants/commonFunctions";
import HandleApiError from "../../../components/commons/HandleApiError";
import { handleErrorMessage } from "../../../redux/actions/classes";
import {
  createPayment,
  failurePayment,
  successPayment,
} from "../../../redux/actions/payment";
import { withTranslation } from "react-i18next";

/* class Alert extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return <MuiAlert elevation={6} variant="filled" />;
    }
  } */
class zoomSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontal: "center",
      vertical: "top",
      message: "",
      open: false,
      severity: "",
    };
    // this.openPayModal = this.openPayModal.bind(this);
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }

  openPayModal() {
    // var amount = amt * 100; //Razorpay consider the amount in paise
    var options = {
      key: `${process.env.REACT_APP_RAZORPAY_KEY}`,
      //   "amount": 0, // 2000 paise = INR 20, amount in paisa
      name: this.props.paymentCreated?.options_name,
      description: this.props.paymentCreated?.options_description,
      order_id: this.props.paymentCreated?.options_order_id,
      image: this.props.paymentCreated?.options_image,
      handler: (response) => {
        var values = {
          razorpay_signature: response.razorpay_signature,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          // transactionamount : amount,
        };
        this.props.successPayment(values);
        this.putZoomSettings("PAID");
      },
      prefill: {
        name: this.props.paymentCreated?.prefill_name,
        email: this.props.paymentCreated?.prefill_email,
        //   "contact":'1234567890',
      },
      notes: {
        address: this.props.paymentCreated?.notes_address,
      },
      theme: {
        color: "#ED1E24",
      },
    };

    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", (response) => {
      var data = {
        order_id: response.error.metadata.order_id,
        payment_id: response.error.metadata.payment_id,
        code: response.error.code,
        description: response.error.description,
        reason: response.error.reason,
        step: response.error.step,
        source: response.error.source,
      };
      this.props.failurePayment(data);
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  }

  putZoomSettings = (accountType) => {
    let { history } = this.props;
    let payload = {
      zoom_settings: { account_type: accountType },
    };
    this.props.putUserSettings(payload).then(() => {
      const nextUrl = accountType == "FREE" ? "/zoomconfiguration" : "/video";
      history.push({
        pathname: nextUrl,
        state: { showPaymentSuccessModal: true },
      });
    });
  };

  paid = () => {
    this.props.handleErrorMessage("Payment options coming soon");
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  render() {
    return (
      <div className={styles.subscriptionContainer}>
        <div className={styles.subscriptionBody}>
          <div className={styles.heading}>
            {this.props.t("subscription.startNow")}
            <br></br>
            {this.props.t("subscription.your")}{" "}
            <span style={{ fontWeight: "800" }}>
              {this.props.t("subscription.freePlan")}
            </span>
            <hr className={styles.partition}></hr>
          </div>
        </div>
        <div className={styles.subscriptionOptions}>
          <div className={styles.optionsCards}>
            <div className={styles.optionType}>
              <div className={styles.optionHeading}>
                <div>{this.props.t("subscription.lite")}</div>
                <hr></hr>
              </div>
              <div className={styles.pricing}>
                <div>{this.props.t("subscription.free")}</div>
                <div>{this.props.t("subscription.restriction")}</div>
              </div>
            </div>
            <div className={styles.facilities}>
              <div>
                <img src="/Image/freeZoom.svg" />
                <div>{this.props.t("subscription.restrictionPointOne")}</div>
              </div>
              <div>
                <img src="/Image/freeZoom.svg" />
                <div>{this.props.t("subscription.restrictionPointTwo")}</div>
              </div>
              <div>
                <img src="/Image/freeZoom.svg" />
                <div>{this.props.t("subscription.restrictionPointThree")}</div>
              </div>
            </div>
            <button
              className={styles.zoomBtn}
              onClick={() => this.putZoomSettings("FREE")}
            >
              {this.props.t("subscription.startFree")}
            </button>
          </div>

          <div className={styles.optionsCards}>
            <div className={styles.optionType}>
              <div className={styles.optionHeading}>
                <div>{this.props.t("subscription.pro")}</div>
                <hr></hr>
              </div>
              <div className={styles.pricing}>
                <div>₹4000/ $60</div>
                <div>{this.props.t("subscription.monthly")}</div>
              </div>
            </div>
            <div className={styles.facilities}>
              <div>
                <img src="/Image/paidZoom.svg" />
                <div>{this.props.t("subscription.proPointOne")}</div>
              </div>
              <div>
                <img src="/Image/paidZoom.svg" />
                <div>{this.props.t("subscription.proPointTwo")}</div>
              </div>
              <div>
                <img src="/Image/paidZoom.svg" />
                <div>
                  {this.props.t("subscription.proPointThree")}
                  <br></br>
                  <i>[{this.props.t("subscription.proPointThreeManage")}]</i>
                </div>
              </div>
            </div>
            <button
              className={styles.zoomBtn}
              onClick={(e) => {
                this.props.createPayment().then(() => this.openPayModal());
              }}
            >
              {this.props.t("subscription.proceed")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    accountType: state?.zoom?.zoom_settings?.zoomSettings?.account_type,
    meetingLink: state?.zoom?.zoom_settings?.zoomSettings?.default_meeting_link,
    zoomId: state?.zoom?.zoom_settings?.zoomId,
    paymentCreated: state?.zoom?.paymentCreated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserSettings: () => dispatch(getUserSettings()),
    putUserSettings: (accountType) => dispatch(putUserSettings(accountType)),
    handleErrorMessage: (message) => dispatch(handleErrorMessage(message)),
    createPayment: () => dispatch(createPayment()),
    successPayment: (data) => dispatch(successPayment(data)),
    failurePayment: (data) => dispatch(failurePayment(data)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(zoomSubscription))
);
