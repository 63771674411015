import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { EDIT_PROFILE_DATA } from "../../../../redux/constants/actionTypes";
import { updateProfileData } from "../../../../redux/actions/profile";
import HandleApiError from "../../../../components/commons/HandleApiError";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { withTranslation } from "react-i18next";

class TemplatePreviewCard extends React.Component {
  state = {
    showChooseView: false,
    success: false,
    message: "",
  };

  render() {
    const { t } = this.props;

    return (
      <div
        id="tp-card"
        style={{
          padding: "30px 20px 30px 20px",
          backgroundColor: this.state.showChooseView ? "#ED1E24" : "#DBDBDB",
          borderRadius: 8,
          height: 200,
          width: 150,
          margin: 10,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        onMouseEnter={() => {
          this.setState({ showChooseView: true });
        }}
        onMouseLeave={() => {
          this.setState({ showChooseView: false });
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.success}
          onClose={() => this.setState({ success: false })}
        >
          <MuiAlert
            onClose={() => this.setState({ success: false })}
            severity="success"
            elevation={6}
            variant="filled"
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>
        {
          this.state.showChooseView ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "auto",
                justifyContent: "space-between",
              }}
            >
              <div style={{ padding: 3.4 }}>
                <div>
                  <p
                    style={{
                      margin: 0,
                      fontSize: 14,
                      color: "#fff",
                    }}
                  >
                    {t("template.profileTemplate")} {this.props.idx + 1} <br />
                    <br />
                    {t("common.free")}
                  </p>
                </div>
              </div>
              <div>
                <div
                  style={{
                    borderRadius: 20,
                    border: "2px solid #fff",
                    textAlign: "center",
                    padding: "8px 0px",
                    marginBottom: 3,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.props.changeTemplatePreviewState(true, this.props.idx);
                  }}
                >
                  <p style={{ margin: 0, color: "#fff", fontSize: 14 }}>
                    {t("template.view")}
                  </p>
                </div>
                {/* <Link to={`/profile/${window.location.pathname.slice(9,-5)}/template/${this.props.idx + 1}`} style={{ textDecoration: "none" }} target="_blank" profileIdView={this.props.profileIdView}> */}
                {this.props.idx + 1 != this.props.short_bio ? (
                  <div
                    onClick={() => {
                      let data = new FormData();
                      data.append("short_bio", this.props.idx + 1);
                      this.props
                        .updateShortBio(EDIT_PROFILE_DATA, data)
                        .then(() => {
                          // alert("shot bio set");
                          this.setState({
                            success: true,
                            message: t("template.shortBioSucessMsg"),
                          });

                          return;
                          // <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />

                          // document.body.style.overflow = "visible";
                          // this.props.changeShowTemplatesState(false);
                        });
                    }}
                    style={{
                      borderRadius: 20,
                      border: "2px solid #fff",
                      textAlign: "center",
                      padding: "10px 0px",
                      backgroundColor: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: "#ED1E24",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      {t("template.use")}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      borderRadius: 20,
                      border: "2px solid #fff",
                      textAlign: "center",
                      padding: "10px 0px",
                      backgroundColor: "#fff",
                      cursor: "not-allowed",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        color: "#ED1E24",
                        fontSize: 12,
                        fontWeight: 600,
                      }}
                    >
                      {t("template.set")}
                    </p>
                  </div>
                )}

                {/* </Link> */}
              </div>
            </div>
          ) : (
            <div style={{ height: "100%" }}>
              <img
                src={this.props.img}
                alt="template"
                style={{
                  height: "100%",
                  width: "auto",
                  boxShadow: "0px 10px 15px #969696",
                }}
                onLoad={() => "loading..."}
              />
            </div>
          )
          // null
        }
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    // nameView: state?.profile?.user?.first_name || "Write a short bio about yourself.",
    profileIdView: state?.profile?.profileIdView || state.profile.profileId,
    short_bio: state?.profile?.short_bio || "",
  };
}
function mapDispatchToProps(dispatch) {
  return {
    // fetchProfileDataView: (action, token, id) => dispatch(fetchProfileDataView(action, token, id)),
    updateShortBio: (action, data) => dispatch(updateProfileData(action, data)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(TemplatePreviewCard)
);
