import React from "react";
import { connect } from "react-redux";

import styles from "../../../Profile.module.css";

class SocailFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  render() {
    return (
      <div
        className={styles.desc}
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: 5 }}>
          <a href={this.props.facebook || "#"} style={{ textDecoration: "none" }} target={this.props.facebook ? "_blank" : "_parent"}>
            <img
              src={this.props.facebook == "" ? "/Image/facebook.svg" : "/Image/apps/profile-resume-edit-view/facebook-colored.svg"}
              alt=" "
              style={{ height: 25, marginRight: 10, cursor: "pointer" }}
            />
          </a>
          <a href={this.props.linkedin || "#"} style={{ textDecoration: "none" }} target={this.props.linkedin ? "_blank" : "_parent"}>
            <img
              src={this.props.linkedin == "" ? "/Image/linkedin.svg" : "/Image/apps/profile-resume-edit-view/linkedin-colored.svg"}
              alt=" "
              style={{ height: 25, marginRight: 10, cursor: "pointer" }}
            />
          </a>
          <a href={this.props.twitter || "#"} style={{ textDecoration: "none" }} target={this.props.twitter ? "_blank" : "_parent"}>
            <img
              src={this.props.twitter == "" ? "/Image/twitter.svg" : "/Image/apps/profile-resume-edit-view/twitter-colored.svg"}
              alt=" "
              style={{ height: 25, marginRight: 10, cursor: "pointer" }}
            />
          </a>
          <a href={this.props.instagaram || "#"} style={{ textDecoration: "none" }} target={this.props.instagaram ? "_blank" : "_parent"}>
            <img
              src={this.props.instagaram == "" ? "/Image/instagram.svg" : "/Image/apps/profile-resume-edit-view/instagram-colored.svg"}
              alt=" "
              style={{ height: 25, marginRight: 10, cursor: "pointer" }}
            />
          </a>
          <a href={this.props.medium || "#"} style={{ textDecoration: "none" }} target={this.props.medium ? "_blank" : "_parent"}>
            <img
              src={this.props.medium == "" ? "/Image/medium.svg" : "/Image/apps/profile-resume-edit-view/medium-colored.svg"}
              alt=" "
              style={{ height: 25, marginRight: 10, cursor: "pointer" }}
            />
          </a>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    facebook: state.profile?.socialLinksView?.facebook || "",
    linkedin: state.profile?.socialLinksView?.linkedin || "",
    twitter: state.profile?.socialLinksView?.twitter || "",
    instagaram: state.profile?.socialLinksView?.instagaram || "",
    medium: state.profile?.socialLinksView?.medium || "",
  };
}
export default connect(mapStateToProps, null)(SocailFeed);
