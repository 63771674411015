import React from "react";
import styles from "./CSSFiles/InputOption.module.css";

function InputOptionFromSource({ Icon, title, color, fontcolor, dontShrink, cursor }) {
  return (
    <div className={styles.inputOption} style={{cursor: cursor}}>
      {/* <Icon style={{ color: color }} /> */}
      <img src={Icon} alt={Icon} className={styles.InputOptionsIconImage} />
      <h4 className={dontShrink ? null : styles.text} style={{ color: fontcolor }}>
        {title}
      </h4>
    </div>
  );
}

export default InputOptionFromSource;
