import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./OuterSectionContainer.module.css";
import AddIcon from "@mui/icons-material/Add";
import ButtonMUI from "@mui/material/Button";
import AddSectionLectureForm from "./AddSectionLectureForm";
import { useDispatch } from "react-redux";
import { clearCourses, deleteSection } from "../../../redux/actions/courses";
import UpdateLectureComponent from "./UpdateLectureComponent";
import AddQuizForm from "./AddQuizForm";
import ConfirmAddContentModal from "../Modals/ConfirmAddContentModal";
import Modal from "../../../components/commons/Modal";
import { useHistory } from "react-router-dom";

const OuterSectionContainer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showBar, setShowBar] = useState(false);
  const [showAddLectureForm, setShowAddLectureForm] = useState(false);
  const [showAddQuizForm, setShowAddQuizForm] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleCreateTemplate = () => {
    dispatch(clearCourses());
    history.push({
      pathname: `/template/create/${props.courseID}`,
      state: {
        isCourse: true,
        sectionID: props.sectionID,
        courseID: props.courseID,
      },
    });
  };

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "25px",
      padding: "6.756px 6.647px 8px 10px",
    },
  };

  const styleAddIcon = {
    "@media (max-width: 500px)": {
      width: "13px",
      height: "13px",
    },
  };

  const handleSectionEdit = () => {
    console.log("Editing section");
    props.onEdit(props.sectionID, props.title);
  };

  return (
    <div className={styles.main}>
      {showConfirmModal && (
        <Modal
          card={
            <ConfirmAddContentModal
              isQuiz={true}
              onYes={() => {
                // setIsLoadingNextBtn(true);
                // handleNextAndSave("next");
                handleCreateTemplate();
                setShowConfirmModal(false);
              }}
              onNo={() => {
                // setIsLoadingSaveBtn(true);
                // handleNextAndSave("save");
                setShowAddQuizForm(true);
                setShowConfirmModal(false);
              }}
            />
          }
          onOutsideClick={() => setShowConfirmModal(false)}
        />
      )}
      <div className={styles.section_header}>
        <div className={styles.section_title}>
          {`${t("common.section")} ${props.index + 1}: `}
          <span>{props.title}</span>
        </div>
        <div className={props.isEditing ? styles.icons_disabled : styles.icons}>
          <img
            src={`/Image/lecturePlan/${
              props.isEditing ? "Edit-grey" : "Edit"
            }.svg`}
            onClick={props.isEditing ? null : handleSectionEdit}
          />
          <img
            src={`/Image/lecturePlan/${
              props.isEditing ? "delete-bin-grey" : "delete-bin"
            }.svg`}
            onClick={
              props.isEditing
                ? null
                : () => dispatch(deleteSection(props.sectionID))
            }
          />
        </div>
      </div>

      {props.children}

      {!showBar ? (
        <ButtonMUI
          variant="outlined"
          startIcon={<AddIcon sx={styleAddIcon} />}
          disableRipple
          disabled={showAddLectureForm || props.isEditing}
          onClick={() => setShowBar(true)}
          // onClick={() => console.log("clickedBar", showBar)}
          sx={styleButtonMUI}
        >
          <span className={styles.btn_label}>
            {t("courseMonetisation.curriculumItem")}
          </span>
        </ButtonMUI>
      ) : (
        <div className={styles.selection_bar}>
          <div
            className={styles.option}
            onClick={() => {
              setShowAddLectureForm(true);
              setShowBar(false);
            }}
          >
            <img src="/Image/plus.svg" />
            <span>{t("common.lecture")}</span>
          </div>
          <div
            className={`${styles.option} ${styles.option_quiz}`}
            onClick={() => {
              // setShowAddQuizForm(true);
              setShowConfirmModal(true);
              setShowBar(false);
            }}
          >
            <img src="/Image/plus.svg" />
            <span>{t("quiz.quiz")}</span>
          </div>
          {/* <div className={styles.option}>
            <img src="/Image/plus.svg" />
            <span>{t("assignment.assignment")}</span>
          </div> */}
        </div>
      )}

      {showAddLectureForm && (
        <AddSectionLectureForm
          isSection={false}
          onCancel={() => {
            setShowAddLectureForm(false); // this hides the AddSectionLectureForm
            props.onCancelLectureUpdate(); // this reset is the props.isEditing and props.lectureEditData data state
          }}
          sectionID={props.sectionID}
          lectureID={props.lectureEditData?.id}
          isEditing={props.isEditing}
          title={props.lectureEditData?.title}
          lecture={props.lectureEditData}
          onSuccessfulUpdate={() => {
            setShowAddLectureForm(false); // this hides the AddSectionLectureForm
            props.onCancelLectureUpdate(); // this reset is the props.isEditing and props.lectureEditData data state
          }}
        />
      )}

      {showAddQuizForm && (
        <AddQuizForm
          isSection={false}
          onCancel={() => setShowAddQuizForm(false)}
          courseID={props.courseID}
          sectionID={props.sectionID}
          lectureID={props.lectureEditData?.id}
          isEditing={props.isEditing}
          title={props.lectureEditData?.title}
          lecture={props.lectureEditData}
          onSuccessfulUpdate={() => {
            setShowAddLectureForm(false); // this hides the AddSectionLectureForm
            props.onCancelLectureUpdate(); // this reset is the props.isEditing and props.lectureEditData data state
          }}
        />
      )}

      {/* {console.log("isEqual", {props.sectionID, props.lectureEditData.sectionID})} */}

      {props.sectionID === props.lectureEditData?.sectionID &&
        props.showUpdateLectureForm && (
          <UpdateLectureComponent
            lecture={props.lectureEditData}
            onCancel={props.onCancelLectureUpdate}
            onCrossingContentLink={() => {
              setShowAddLectureForm(true);
              props.onLectureContentUpdate();
            }}
          />
        )}
    </div>
  );
};

export default OuterSectionContainer;
