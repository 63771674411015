import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./css/MiddleContain.module.css";
// import { Link } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
 
function MiddleContain(props) {
  const [className, setClassName] = useState(props.actionType =="EDIT" ? props.editData?.name : "");
  const [classDescription, setClassDescription] = useState(props.actionType =="EDIT" ? props.editData?.description : "");
  const [classGrade, setClassGrade] = useState(props.actionType =="EDIT" ? props.editData?.grade : "");
  const [classSubject, setClassSubject] = useState(props.actionType =="EDIT" ? props.editData?.subject : "");
  //
  const [classId, setClassId] = useState(props.actionType =="EDIT" ? props.editData.id : "");
  const [addToDept, setAddToDept] = useState(props.actionType =="EDIT" ? props.editData.department ? true : false : false);
  const {t} = useTranslation()
  


  return (
    <div className={styles.classModal}>
      {props.actionType == "EDIT" || props.actionType == "ADD" ? (
        <form>
          <h3 className={styles.inputLabel} for="classname">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                font: "normal normal 500 16px Montserrat",
                letteSpacing: "0px",
                color: "#000000",
                margin: "15px 0",
              }}
            >
              <img
                style={{ marginRight: "10px" }}
                src="/Image/modalIcon/title.svg"
              />
              {t("modals.nameClass")}*
            </div>
          </h3>
          <input
            style={{ marginBottom: "10px" }}
            type="text"
            className={styles.inputField}
            placeholder={t("modals.nameClassPlaceholder")}
            value={className}
            onChange={(e) => {
              setClassName(e.target.value);
              props.onClassNameChange(e);
            }}
          />
          <h3 for="description" className={styles.inputLabel}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                font: "normal normal 500 16px Montserrat",
                letteSpacing: "0px",
                color: "#000000",
                margin: "15px 0",
              }}
            >
              <img
                style={{ marginRight: "10px" }}
                src="/Image/modalIcon/description.svg"
              />
              {t("modals.description")}
            </div>
          </h3>
          <textarea
            className={styles.inputField1}
            placeholder={t("modals.describeClassPlaceholder")}
            onChange={(e) => {
              setClassDescription(e.target.value);
              props.onClassDescriptionChange(e);
            }}
          >
            {classDescription}
          </textarea>
          <h3 for="grade" className={styles.inputLabel}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                font: "normal normal 500 16px Montserrat",
                letteSpacing: "0px",
                color: "#000000",
                margin: "15px 0",
              }}
            >
              <img
                style={{ marginRight: "10px" }}
                src="/Image/modalIcon/grade.svg"
              />
              {t("modals.selectGrade")}*
            </div>
          </h3>
          <div className={styles.drop}>
            {/* <img src = "./Image/dropArrow.svg"></img> */}
            <input
              style={{ marginBottom: "10px" }}
              type="text"
              className={styles.inputField}
              placeholder={t("modals.gradePlaceholder")}
              value={classGrade}
              onChange={(e) => {
                setClassGrade(e.target.value);
                props.onClassGradeChange(e);
              }}
            />
          </div>
          <h3 for="subject" className={styles.inputLabel}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                font: "normal normal 500 16px Montserrat",
                letteSpacing: "0px",
                color: "#000000",
                margin: "15px 0",
              }}
            >
              <img
                style={{ marginRight: "10px" }}
                src="/Image/modalIcon/subject.svg"
              />
              {t("modals.selectSubject")}*
            </div>
          </h3>
          <div className={styles.drop}>
            {/* <img src = "./Image/dropArrow.svg"></img> */}
            <input
              style={{ marginBottom: "10px" }}
              type="text"
              className={styles.inputField}
              placeholder={t("modals.classPlaceholder")}
              value={classSubject}
              onChange={(e) => {
                setClassSubject(e.target.value);
                props.onClassSubjectChange(e);
              }}
            />
          </div>

          {props.isDepartmentUser && (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                margin: "1rem",
                fontWeight: 500,
              }}
            >
              <p>{t("modals.addToDept")}?* : </p>
              <Checkbox
                checked={addToDept}
                disabled={props.actionType === "EDIT"}
                onChange={(event) => {
                  setAddToDept(event.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            </div>
          )}
          <button
            onClick={() => {
              props.actionType == "EDIT"
                ? props.onClassEdit()
                : props.onClassAdd(addToDept);
            }}
            id="submitForm"
            className={styles.saveDetailsButton}
          >
            {props.actionType == "EDIT"
              ? t("class.editClass")
              : t("modals.createClassButton")}
          </button>
        </form>
      ) : (
        <form>
          <h3 className={styles.inputLabel}>{t("class.sureToDeleteClass")}</h3>
          <button
            onClick={() => {
              props.onClassDelete();
            }}
            id="submitForm"
            className={styles.saveDetailsButton}
          >
            {t("class.deleteClass")}
          </button>
        </form>
      )}
    </div>
  );
}

export default MiddleContain;
