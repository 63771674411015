import React, { useEffect, useState } from "react";
import AdCard from "./Components/AdCard/AdCard";
import InfoCard from "./Components/InfoCard/InfoCard";
import PictureCard from "./Components/PictureCard/PictureCard";
import "./Hero.css";
import "../Hero/Hero.css";
import { Link } from "react-router-dom";
import { useLottie } from "lottie-react";
import HeroAnimation from "../../../animation/HeroAnimation.json";
import Confetti from "react-dom-confetti";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import Helmet from "helmet";
import { useDispatch } from "react-redux";
import { subscribeMember } from "../../../redux/actions/subscribe";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Layout from "../Layout/Layout";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import VideoCard from "../../../components/commons/VideoCard";
// import SwipeableViews from 'react-swipeable-views';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function HomePage() {
  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  // for video
  const [playButton, setPlayButton] = useState(true);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [active, setActive] = useState(false);
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));

  const theme = useTheme();
  const { t } = useTranslation();
  const horizontal = "center";
  const vertical = "top";
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const dispatch = useDispatch();

  const options = {
    // animationData: HeroAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  const handlePlayButton = () => {
    setPlayButton(false);
  };

  const subscribe = () => {
    if (email == "") {
      setMessage("Enter email");
      setOpen({ open: true });
      setSeverity("error");
      setActive(false);
      return;
    } else if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setMessage("Thanks for subscribing us!");
      setOpen({ open: true });
      setSeverity("success");
      setActive(true);
      setEmail("");
    } else {
      setMessage("Enter valid email");
      setOpen({ open: true });
      setSeverity("error");
      setActive(false);
      setEmail("");
    }
  };

  useEffect(() => {
    setLanguage(localStorage.getItem("i18nextLng"));
  }, [localStorage.getItem("i18nextLng")]);

  // changing interest tabs
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            // sx={{
            //   "@media (max-width: 1450px)": {
            //     p: 2,
            //   },
            //   "@media (max-width: 500px)": {
            //     p: 2,
            //   },
            // }}
          >
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const tabStyle = {
    textTransform: "none",
    color: "rgb(85, 85, 85)",
    font: "1.25rem Montserrat",
    fontWeight: 500,
    marginRight: "2.0625rem",
    "text-align": "center",
    // padding: "0px 10px",
    // border: "0.06rem solid rgb(237, 30, 36)",
    // borderRadius: "3.75rem",
    "@media (max-width: 500px)": {
      font: "0.8125rem Montserrat",
      padding: "0px 10px",
    },
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <>
      <div className="Home-Wrapper">
        <div className="Home-section1-svg-wrapper">
          <div className="Home-Section1-Wrapper">
            <div className="Home-Section1">
              <h1> {t("HomePage.createYourOwn")}</h1>
              <h1 style={{ fontWeight: 800 }}> {t("HomePage.community")}</h1>
              <h1> {t("HomePage.toLearnWith")}</h1>
              <p>{t("HomePage.SubHeading")}</p>
              <div className="Home-Section1-buttons">
                <Link className={"Home-Section1-buttons-link"} to="/signup">
                  <button className="Home-section1-btn1">
                    {t("HomePage.GetStarted")}
                  </button>
                </Link>
                <Link className={"Home-Section1-buttons-link"} to="/features">
                  <button className="Home-section1-btn2">
                    {t("HomePage.Explore")}
                  </button>
                </Link>
              </div>
            </div>

            <img
              src="/Image/homePageRedesign/Hero_Image.svg"
              className="hero-image"
            />
          </div>

          <svg class="svg-section1">
            <clipPath id="clip-path-section1" clipPathUnits="objectBoundingBox">
              <path d="M0,0 L1,0 L1,1 L0.12,0.879 C0.053,0.87,0,0.767,0,0.642 L0,0"></path>
            </clipPath>
          </svg>
        </div>

        <div className="Home-section2">{View}</div>

        {/* Old Section3 hidden from display */}
        {/* <div className="Home-section3" style={{ display: "none" }}>
          <div className="Home-section3-group">
            <div className="Home-section3-stable">
              <h4 className="why-choose-us">{t("HomePage.WhyChooseUs")}</h4>
              <p>{t("HomePage.WhyChooseUsCaption")}</p>
            </div>
            
            <div className="Home-section3-adCards">
              <AdCard strength={t("HomePage.WhyChooseUsCard1")} />
              <AdCard strength={t("HomePage.WhyChooseUsCard2")} />
              <AdCard strength={t("HomePage.WhyChooseUsCard3")} />
              <AdCard strength={t("HomePage.WhyChooseUsCard3")} opacity="0" />
            </div>
          </div>
        </div> */}

        {/* ----------- New Home-Section3 -------- */}

        <div className="new_home_section_3">
          <p className="new_home_section_title">{t("HomePage.WhyChooseUs")}</p>
          <div className="card-container">
            <div className="card">
              <img
                className="card_thumbnail"
                src="/Image/homePageRedesign/Card_1.svg"
              />
              <p className="card-main-text">{t("HomePage.WhyChooseUsCard2")}</p>
              <p className="card-sub-text">
                {t("HomePage.WhyChooseUsCard2Caption")}
              </p>
            </div>
            <div className="card">
              <img
                className="card_thumbnail"
                src="/Image/homePageRedesign/Card_2.svg"
              />
              <p className="card-main-text">{t("HomePage.WhyChooseUsCard1")}</p>
              <p className="card-sub-text">
                {t("HomePage.WhyChooseUsCard1Caption")}
              </p>
            </div>
            <div className="card">
              <img
                className="card_thumbnail"
                src="/Image/homePageRedesign/Card_3.svg"
              />
              <p className="card-main-text">{t("HomePage.WhyChooseUsCard4")}</p>
              <p className="card-sub-text">
                {t("HomePage.WhyChooseUsCard4Caption")}
              </p>
            </div>
          </div>
        </div>

        {/* <Layout> */}
        <div className="subLayout-NOT-USING">
          {/* <div className="Home-section4-svg-wrapper">
              <div className="Home-section4">
                
                <div className="Home-section4-video">
                  <h3>{t("HomePage.ExploreChalkmates")}</h3>
                  <div class="video-div">
                    <ReactPlayer
                      className="video"
                      url={
                        language === "ru"
                          ? "/Image/FeatureVideos/demo-russian.m4v"
                          : "/Image/FeatureVideos/demo-english.m4v"
                      }
                      playing={!playButton}
                      controls
                      width="60%"
                      height="100%"
                      onPause={() => setPlayButton(true)}
                      onEnded={() => setPlayButton(true)}
                      onPlay={() => setPlayButton(false)}
                    />
                    {playButton ? (
                      <div
                        class="play-btn"
                        id="playButton"
                        onClick={() => handlePlayButton()}
                      >
                        <div class="play-btn-img">
                          <img src="/Image/Icons/play.png" alt="" />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <PictureCard
                  src={"/Image/homePageRedesign/Create_career_portfolio.svg"}
                  alt="img"
                  heading={t("HomePage.Portfolio")}
                  desc={t("HomePage.PortfolioCaption")}
                />
              </div>
              <svg class="svg-section4">
                <clipPath
                  id="clip-path-section4"
                  clipPathUnits="objectBoundingBox"
                >
                  <path d="M0,0 C0.127,0.172,0.564,0.194,1,0.194 L1,1 L0,1 L0,0"></path>
                </clipPath>
              </svg>
            </div> */}

          {/* <div className="Home-section5"> */}
          {/* <div className="Home-section5-bg bg-1">

                            </div> */}

          {/* <div className="Home-section5-bg bg-2">

                                </div>     */}

          <div className="homepage-video-section-svg-wrapper">
            <div className="homepage-video-section">
              <p className={"explore-label"}>
                {t("HomePage.ExploreChalkmates")}
              </p>

              {/* --- add react player here */}
              <div className={"video-container"}>
                <VideoCard
                  path={
                    language === "ru"
                      ? "/Image/FeatureVideos/demo-russian.m4v"
                      : "/Image/FeatureVideos/demo-english.m4v"
                  }
                />
              </div>

              <PictureCard
                src={"/Image/homePageRedesign/Create_career_portfolio.svg"}
                alt="img"
                heading={t("HomePage.Portfolio")}
                desc={t("HomePage.PortfolioCaption")}
              />
            </div>
            <svg class="svg-section4">
              <clipPath
                id="clip-path-section4"
                clipPathUnits="objectBoundingBox"
              >
                <path d="M0,0 C0.127,0.172,0.564,0.194,1,0.194 L1,1 L0,1 L0,0"></path>
              </clipPath>
            </svg>
          </div>

          <div className={"home-section5-svg-wrapper"}>
            <div className={"home-section5-new"}>
              {/* <div style={{ marginTop: "5rem" }}></div> */}
              <PictureCard
                position="left"
                src={"/Image/homePageRedesign/Monetize_your_content.svg"}
                alt="img"
                heading={t("HomePage.Monetize")}
                desc={t("HomePage.MonetizeCaption")}
              />

              {/* <div style={{ marginBottom: "12.5rem" }}></div> */}

              <PictureCard
                src={"/Image/homePageRedesign/Digital_content_management.svg"}
                alt="img"
                heading={t("HomePage.Digital")}
                desc={t("HomePage.DigitalCaption")}
              />
            </div>

            <svg class="svg-section5">
              <clipPath
                id="clip-path-section5"
                clipPathUnits="objectBoundingBox"
              >
                <path d="M0,0 C0.127,0.172,0.564,0.194,1,0.194 L1,1 L0,1 L0,0"></path>
              </clipPath>
            </svg>
          </div>

          {/* ---------- 5.5 ---- */}
          <div className={"home-section5-svg-wrapper"}>
            <div className={"home-section5-new"}>
              {/* <div style={{ marginTop: "5rem" }}></div> */}
              <PictureCard
                position="left"
                src={"/Image/homePageRedesign/Social_engagement.svg"}
                alt="img"
                heading={t("HomePage.Social")}
                desc={t("HomePage.SocialCaption")}
              />

              {/* <div style={{ marginBottom: "12.5rem" }}></div> */}

              <PictureCard
                src={"/Image/homePageRedesign/Personalized_contents.svg"}
                alt="img"
                heading={t("HomePage.Personalization")}
                desc={t("HomePage.PersonalizationCaption")}
              />
            </div>

            <svg class="svg-section5">
              <clipPath
                id="clip-path-section5"
                clipPathUnits="objectBoundingBox"
              >
                <path d="M0,0 C0.127,0.172,0.564,0.194,1,0.194 L1,1 L0,1 L0,0"></path>
              </clipPath>
            </svg>
          </div>

          {/* ---------- 5.5 ---- */}

          {/* <div className="Home-section5-bg bg-3">

                            </div>     */}

          {/* <div className="Home-section5-bg bg-4">

                            </div>     */}

          {/* <PictureCard
              position="left"
              src={"/Image/homePageRedesign/Social_engagement.svg"}
              alt="img"
              heading={t("HomePage.Social")}
              desc={t("HomePage.SocialCaption")}
            /> */}

          {/* <div className="Home-section5-bg bg-5">

                            </div>     */}

          {/* <PictureCard
              src={"/Image/homePageRedesign/Personalized_contents.svg"}
              alt="img"
              heading={t("HomePage.Personalization")}
              desc={t("HomePage.PersonalizationCaption")}
            /> */}
          {/* </div> */}

          <div className="home-section6-svg-wrapper">
            <div className="Home-section6">
              <h4>{t("HomePage.TopicsOfInterest")}</h4>
              {/* static menu */}
              <div className="Home-section6-cards">
                <div
                  // style={{
                  //   display: "flex",
                  //   "flex-direction": "column",
                  //   width: "40vw",
                  // }}
                  className="tabs-wrapper"
                >
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    centered
                    TabIndicatorProps={{
                      sx: {
                        backgroundColor: "#ED1E24",
                      },
                    }}
                    sx={{
                      
                      "& button.Mui-selected": {
                        color: "#ED1E24",
                      },
                      "& button:focus": {
                        color: "#ED1E24",
                        // border: "0.06rem solid rgb(237, 30, 36)",
                        // borderRadius: "3.75rem",
                        // padding: "0px 2.0625rem",
                      },
                      "& button.Mui-disabled": {
                        backgroundColor: "#f6f6f6",
                        color: "#959595",
                        borderColor: "#f6f6f6",
                      },

                      "@media (max-width: 950px)": {
                        "& button": {
                          margin: 0,
                        },
                      }
                      
                      
                    }}
                  >
                    <Tab sx={tabStyle} label={t("common.science")} />
                    <Tab sx={tabStyle} label={t("common.maths")} />
                    <Tab sx={tabStyle} label={t("common.art")} />
                    <Tab sx={tabStyle} label={t("common.english")} />
                  </Tabs>

                  <TabPanel value={tabValue} index={0}>
                    <div className="info_card_container">
                      <InfoCard
                        color={"rgb(29, 29, 29)"}
                        background={
                          "linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%)"
                        }
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                      <InfoCard
                        color={"white"}
                        background={"#f87478"}
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                      <div>
                      <InfoCard
                        color={"rgb(29, 29, 29)"}
                        background={"rgba(248, 115, 119, 0.6)"}
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                        />
                      </div>
                    </div>
                  </TabPanel>

                  <TabPanel value={tabValue} index={1}>
                    <div className="info_card_container">
                      <InfoCard
                        color={"rgb(29, 29, 29)"}
                        background={"rgba(248, 115, 119, 0.6)"}
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                      <InfoCard
                        color={"rgb(29, 29, 29)"}
                        background={
                          "linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%)"
                        }
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                      <InfoCard
                        color={"white"}
                        background={"#f87478"}
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                    </div>
                  </TabPanel>

                  <TabPanel value={tabValue} index={2}>
                    <div className="info_card_container">
                      <InfoCard
                        color={"white"}
                        background={"#f87478"}
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                      <InfoCard
                        color={"rgb(29, 29, 29)"}
                        background={"rgba(248, 115, 119, 0.6)"}
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                      <InfoCard
                        color={"rgb(29, 29, 29)"}
                        background={
                          "linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%)"
                        }
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel value={tabValue} index={3}>
                    <div className="info_card_container">
                      <InfoCard
                        color={"rgb(29, 29, 29)"}
                        background={
                          "linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%)"
                        }
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                      <InfoCard
                        color={"white"}
                        background={"#f87478"}
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                      <InfoCard
                        color={"rgb(29, 29, 29)"}
                        background={"rgba(248, 115, 119, 0.6)"}
                        heading={t("HomePage.CourseCard1")}
                        level={t("HomePage.Advance")}
                        desc={t("HomePage.CourseCard1Caption")}
                      />
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>

            <svg class="svg-section5">
              <clipPath
                id="clip-path-section5"
                clipPathUnits="objectBoundingBox"
              >
                <path d="M0,0 C0.127,0.172,0.564,0.194,1,0.194 L1,1 L0,1 L0,0"></path>
              </clipPath>
            </svg>
          </div>

          <div className="Hero-section7">
            <h1>{t("HomePage.SubscribeCaption")}</h1>
            <div className="Hero-section7-subs">
              <input
                type="email"
                placeholder={t("HomePage.EnterEmail")}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setActive(false);
                }}
                value={email}
              />
              <Confetti active={active} config={config} />

              <button
                onClick={() => {
                  subscribe();
                  dispatch(subscribeMember(email));
                }}
              >
                {t("HomePage.Subscribe")}
              </button>
            </div>
          </div>
        </div>
        {/* </Layout> */}
      </div>

      <div className="footer-svg-wrapper">
       
      <div className="footer">
        <div className="footer-left">
          <div>
            {/* <img src="Image/HomeImages/logo-white.svg" alt="" /> */}
            <img src="/Image/HomeImages/logo.svg" alt="" />
            <p>{t("home.about")}</p>
            <div className="footer-social">
              <a
                href="https://www.facebook.com/Chalkmates-102745632283169/?ref=pages_you_manage"
                target="_blank"
              >
                <div>
                  <img
                    className="footer-img"
                    src="Image/facebook.png"
                    alt="facebook-icon"
                  />
                </div>
              </a>

              <a
                href="https://www.linkedin.com/company/chalkmates/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <img
                    className="footer-img"
                    src="Image/linkedin.png"
                    alt="linkedin-icon"
                  />
                </div>
              </a>

              <a
                href="https://twitter.com/chalkmates"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <img
                    className="footer-img"
                    src="Image/twitter.png"
                    alt="twitter-icon"
                  />
                </div>
              </a>

              <a
                href="https://www.instagram.com/chalkmates_team/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>
                  <img
                    className="footer-img"
                    src="Image/instagram.png"
                    alt="instagram-icon"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-right">
          {/* <img src="Image/HomeImages/footer-image.svg" alt="" /> */}
          <div className="footer-header">
            <p>{t("HomePageRedesign.aboutUs")}</p>
          </div>
          <div className="footer-header">
            <p>{t("HomePageRedesign.support")}</p>
          </div>
          <div className="footer-header">
            <p>{t("HomePageRedesign.company")}</p>
          </div>
          <div>
            <p className={"footer-link"}>{t("HomePageRedesign.aboutUs")}</p>
          </div>
          <div>
            <p className={"footer-link"}>{t("HomePageRedesign.feedback")}</p>
          </div>
          <div>
            <p className={"footer-link"}>{t("HomePageRedesign.faq")}</p>
          </div>
          <div>
            <p className={"footer-link"}>{t("HomePageRedesign.complaint")}</p>
          </div>
          <div>
            <p className={"footer-link"}>{t("HomePageRedesign.legalPolicy")}</p>
          </div>
          <div>
            <p className={"footer-link"}>{t("HomePageRedesign.contactUs")}</p>
          </div>
        </div>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>

        <div className="application">
          <Helmet>
            <script src="//code.tidio.co/e5kksaxjoyrmay3xbgjttl4uzvjimoho.js"></script>
          </Helmet>
        </div>
      </div>
      <svg class="svg-section5">
          <clipPath id="clip-path-footer" clipPathUnits="objectBoundingBox">
            <path d="M0,1 L1,1 L1,0 L0.118,0.149 C0.051,0.16,0,0.259,0,0.377 L0,1"></path>
          </clipPath>
        </svg>
      </div>
    </>
  );
}
