import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IconTitle from "../../components/commons/IconTitle";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import styles from "./css/CreateQuizTemplatePage.module.css";
import { useHistory, useLocation, useParams } from "react-router";
import QuestionTypeModal from "./Modals/QuestionTypeModal";
import Modal from "../../components/commons/Modal";
import OuterQuestionContainer from "./Components/OuterQuestionContainer";
import PossibleChoice from "./Components/PossibleChoice";
import TooltipWrapper from "../CourseMonetization/Components/TooltipWrapper";
import { v4 as uuidv4 } from "uuid";
import cloneDeep from 'lodash.clonedeep'
import { useDispatch, useSelector } from "react-redux";
import {
  createQuizTemplate,
  getIndividualQuizTemplate,
  updateQuizTemplate,
} from "../../redux/actions/enhancedQuiz";
import { handleErrorMessage } from "../../redux/actions/classes";
import AnswerTextArea from "./Components/AnswerTextArea";
import { CircularProgress } from "@mui/material";


/* -- Type of questions for checks at Frontend.
'type': 'mcq',
'type': 'fillInBlanks',
'type': 'checkboxes',
'type': 'shortAnswer'
'type': 'trueFalse',
'type': 'paragraph',
'type': 'grid',
'type': 'imageChoice'
*/

/* 
  trueChoice is the number of choices a user has marked true for a question.
*/

const CreateQuizTemplatePage = () => {
  const isMobile = window.innerWidth < 768;
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const existingQuizTemplate = location?.state?.template;
  const quizTemplateTitle = location?.state?.title;
  const quizTemplateInstructions = location?.state?.instructions;
  const actionType = location?.state?.actionType;
  const routeType = location?.state?.routeType ?? '';
  const currentClassOrGroupName = location?.state?.currentClassOrGroupName;
  
  const isCourse = location?.state?.isCourse ?? false;
  const courseID = location?.state?.courseID ?? 0;
  const sectionID = location?.state?.sectionID ?? 0;

  const [questionsList, setQuestionsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [deletedList, setDeletedList] = useState({
    deletedQuestions: [],
    deletedChoices: [],
  });

  // console.log("title", quizTemplateTitle, quizTemplateInstructions);

  const toggleAddQuestionModal = () => {
    setShowModal(!showModal);
  };

  // console.log("existing23", existingQuizTemplate);

  // ------ useEffect section ------------

  // useEffect(() => {
  //   if (actionType === "edit") {
  //     dispatch(getIndividualQuizTemplate(1));
  //   }
  // }, []);

  useEffect(() => {
    if (actionType === "edit") {
      setQuestionsList(existingQuizTemplate?.questionsList ?? []);
    }
  }, [existingQuizTemplate]);

  // ---- localStorage -------

  // useEffect(() => {
  //   let data = window.localStorage.getItem("templateQuestionList");
  //   if (data !== null && JSON.parse(data).length > 0) {
  //     console.log("firstLoad", JSON.parse(data));
  //     setQuestionsList(JSON.parse(data));
  //   }
  // }, []);

  // useEffect(() => {
  //   window.localStorage.setItem(
  //     "templateQuestionList",
  //     JSON.stringify(questionsList)
  //   );
  // }, [questionsList]);

  const addQuestionType = (questionIcon, questionType) => {
    let question = {};
    question.id = uuidv4();
    question.icon = questionIcon;
    question.type = questionType;
    question.isMandatory = false;
    question.maxMarks = null;
    question.questionText = "";
    if (
      questionType == "mcq" ||
      questionType == "checkboxes" ||
      questionType == "trueFalse"
    ) {
      
      question.choices = [
        { id: uuidv4(), choiceText: "", isCorrect: false },
        { id: uuidv4(), choiceText: "", isCorrect: false }
      ];
    } else if (questionType == "grid") {
      question.choices = [
        { id: uuidv4(), choiceText: "", correctChoice: "" },
        { id: uuidv4(), choiceText: "", correctChoice: "" }
      ];
    } else if (questionType == "imageChoice") {
      question.choices = [
        { id: uuidv4(), choiceImage: null, isCorrect: false },
        { id: uuidv4(), choiceImage: null, isCorrect: false },
      ];
    } else if (questionType == 'fillInBlanks') {
      question.choices = [
        { id: uuidv4(), choiceText: "enhancedQuiz.blankAnswer", correctChoice: "" },
      ];
    }
    setQuestionsList([...questionsList, question]);
    setShowModal(!showModal);
  };

  const addChoice = (questionIndex) => {
    let newChoice = null;
    let newList = [...questionsList];
    if (newList[questionIndex]?.type == "grid") {
      newChoice = { id: uuidv4(), choiceText: "", correctChoice: "" };
    } else if (newList[questionIndex]?.type == "imageChoice") {
      newChoice = { id: uuidv4(), choiceImage: null, isCorrect: false };
    } else if (newList[questionIndex].type === "fillInBlanks") {
      newChoice = { id: uuidv4(), choiceText: "enhancedQuiz.blankAnswer", correctChoice: "" };      
    } else {
      newChoice = { id: uuidv4(), choiceText: "", isCorrect: false };
    }
    newList[questionIndex].choices.push(newChoice);
    setQuestionsList(newList);
  };

  const removeChoice = (questionIndex, choiceIndex) => {
    let newList = [...questionsList];
    if (
      actionType == "edit" &&
      !isUUID(newList[questionIndex].choices[choiceIndex].id)
    ) {
      let x = [...deletedList.deletedChoices];
      x.push(parseInt(newList[questionIndex].choices[choiceIndex].id));
      setDeletedList({ ...deletedList, deletedChoices: x });
    }
    newList[questionIndex].choices.splice(choiceIndex, 1);
    setQuestionsList(newList);
  };

  const removeQuestion = (questionIndex) => {
    let newList = [...questionsList];
    if (actionType == "edit" && !isUUID(newList[questionIndex].id)) {
      let x = [...deletedList.deletedQuestions];
      x.push(parseInt(newList[questionIndex].id));
      setDeletedList({ ...deletedList, deletedQuestions: x });
    }
    newList.splice(questionIndex, 1);
    setQuestionsList(newList);
  };

  const handleMandatoryStatus = (questionIndex) => {
    let newList = [...questionsList];
    newList[questionIndex].isMandatory = !newList[questionIndex].isMandatory;
    setQuestionsList(newList);
  };

  // console.log("currentList", questionsList);
  // console.log("deletedList", deletedList);

  const getQuestionInput = (index, questionInput) => {
    let newList = [...questionsList];
    newList[index].questionText = questionInput;
    setQuestionsList(newList);
  };

  const getChoiceInput = (questionIndex, choiceIndex, data) => {
    let newList = [...questionsList];
    if (newList[questionIndex]?.type == "fillInBlanks") {
      newList[questionIndex].choices[choiceIndex].correctChoice = data;
    } else if (newList[questionIndex]?.type == "imageChoice") {
      newList[questionIndex].choices[choiceIndex].choiceImage = data;
    } else {
      newList[questionIndex].choices[choiceIndex].choiceText = data;
    }

    setQuestionsList(newList);
  };

  const getGridChoiceInput = (
    questionIndex,
    choiceIndex,
    choiceText,
    correctChoice
  ) => {
    let newList = [...questionsList];
    if (newList[questionIndex]?.type == "grid") {
      newList[questionIndex].choices[choiceIndex].choiceText = choiceText;
      newList[questionIndex].choices[choiceIndex].correctChoice = correctChoice;
    }
    setQuestionsList(newList);
  };

  const getMaxMarks = (questionIndex, marks) => {
    let newList = [...questionsList];
    newList[questionIndex].maxMarks = parseInt(marks);
    setQuestionsList(newList);
  };

  // --- move up and down ---
  const handleMoveUp = (index) => {
    let newList = [...questionsList];
    [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
    setQuestionsList(newList);
  };

  const handleMoveDown = (index) => {
    let newList = [...questionsList];
    [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
    setQuestionsList(newList);
  };

  const handleCorrectStatus = (questionIndex, choiceIndex, status) => {
    let newList = [...questionsList];
    newList[questionIndex].choices[choiceIndex].isCorrect = status;
    setQuestionsList(newList);
  };

  const isUUID = (id) => {
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(id.toString());
  };

  const validate = () => {
    // setIsLoadingBtn(true);
    // let currentList = [...questionsList];
    // let currentList = JSON.parse(JSON.stringify(questionsList));
    let currentList = cloneDeep(questionsList);

    let invalid = false;

    if(currentList?.length < 1){
      invalid = true;
      dispatch(handleErrorMessage(t("enhancedQuiz.templateMust")));
      return false;
    }

    if(invalid){
      return false;
    }



    const newList = currentList?.map((item) => {
      let obj = {};

      /*
       adding a temporary key (trueChoiceCount) to mcq, checkbox, trueFalse and image questions,
       to add up the count for number of choices marked correct by the user.
       trueChoice key won't be send to the backend.
      */

      switch (item.type) {
        case "mcq":
          obj.question_type = "SingleChoice";
          item.trueChoiceCount = 0
          break;
        case "checkboxes":
          obj.question_type = "Mcq";
          item.trueChoiceCount = 0
          break;
        case "trueFalse":
          obj.question_type = "True/False";
          item.trueChoiceCount = 0
          break;
        case "grid":
          obj.question_type = "Grid";
          break;
        case "fillInBlanks":
          obj.question_type = "Fib";
          break;
        case "imageChoice":
          obj.question_type = "ImageChoice";
          item.trueChoiceCount = 0
          break;
        case "shortAnswer":
          obj.question_type = "ShortAnswer";
          break;
        case "paragraph":
          obj.question_type = "Paragraph";
          break;
      }

      obj.is_mandatory = item.isMandatory;
      obj.marks = item.maxMarks;
      obj.text = item.questionText;

      // checking for errors in questions
      if (obj.text == "" || obj.text == null || obj.text == undefined) {
        invalid = true;
        dispatch(handleErrorMessage(t("enhancedQuiz.questionTextEmpty")));
        return false;
      } else if (obj.marks == null || obj.marks == undefined || obj.marks < 1) {
        invalid = true;
        dispatch(
          handleErrorMessage(
            t("enhancedQuiz.marksCannotBeLessThanOne")
          )
        );
        return false;
      }

      if(invalid){
        return false;
      }

      if (actionType === "edit" && !isUUID(item.id)) {
        obj.question_id = parseInt(item.id);
      }

      // --- renaming choice keys -----

      if (
        item.type === "mcq" ||
        item.type === "checkboxes" ||
        item.type === "trueFalse"
      ) {
        if (item.type === "mcq" && item?.choices?.length < 2 ) {
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.mcqMust")));
          return false;
        } else if (item.type === "checkboxes" && item?.choices?.length < 2){
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.checkboxesMust")));
          return false;
        } else if (item.type === "trueFalse" && item?.choices?.length != 2){
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.trueFalseMCQ")));
          return false;
        }

        if(invalid){
          return false;
        }

        obj.choice = item.choices?.map((content) => {
          let x = {};
          x.choice_text = content.choiceText;
          x.is_correct = content.isCorrect;

          if (x.is_correct) {
            item.trueChoiceCount += 1;
          }

          // ---------- checking choices for errors --------------
          if (
            x.choice_text == "" ||
            x.choice_text == null ||
            x.choice_text == undefined
          ) {
            invalid = true;
            dispatch(
              handleErrorMessage(t("enhancedQuiz.choiceTextEmpty"))
            );
            return false;
          }          

          if (actionType === "edit" && !isUUID(content.id)) {
            x.choice_id = parseInt(content.id);
          }

          return x;
        });

        if(invalid){
          return false;
        }

        if (item.type === "mcq" && item.trueChoiceCount != 1) {
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.onlyOneMCQ")));
          return false;
        } else if (item.type === "trueFalse" && item.trueChoiceCount != 1) {
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.onlyOneTrueTF")));
          return false;
        } else if (item.type === "checkboxes" && item.trueChoiceCount < 1) {
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.atLeastOneCheckbox")));
          return false;
        }

      } else if (item.type === "fillInBlanks" || item.type === "grid") {
        
        if (item.type === "fillInBlanks" && item?.choices?.length < 1 ) {
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.fibMust")));
          return false;
        } else if (item.type === "grid" && item?.choices?.length < 2){
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.gridMust")));
          return false;
        }

        if(invalid){
          return false;
        }


        obj.choice = item.choices?.map((content) => {
          let x = {};
          x.choice_text = content.choiceText;
          x.correct_choice = content.correctChoice;

          // --------- checking choices for errors ----------
          if (
            x.choice_text == "" ||
            x.choice_text == null ||
            x.choice_text == undefined ||
            x.correct_choice == "" ||
            x.correct_choice == null ||
            x.correct_choice == undefined
          ) {
            invalid = true;
            dispatch(
              handleErrorMessage(t("enhancedQuiz.choiceTextEmpty"))
            );
            return false;
          }

          if (actionType === "edit" && !isUUID(content.id)) {
            x.choice_id = parseInt(content.id);
          }
          return x;
        });
        
      } else if (item.type === "imageChoice") {

        if (item.type === "imageChoice" && item?.choices?.length < 2){
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.imageMust")));
          return false;
        }

        if(invalid){
          return false;
        }

        obj.choice = item.choices?.map((content) => {
          let x = {};
          x.img = content.choiceImage;
          x.is_correct = content.isCorrect;

          if (x.is_correct) {
            item.trueChoiceCount += 1;
          }

          //------ checking image choice for null value
          if (
            x.img == "" ||
            x.img == null ||
            x.img == undefined
          ) {
            invalid = true;
            dispatch(
              handleErrorMessage(t("enhancedQuiz.imageChoiceEmpty"))
            );
            return false;
          }

          if (actionType === "edit" && !isUUID(content.id)) {
            x.choice_id = parseInt(content.id);
          }
          return x;
        });
        
        if(invalid){
          return false;
        }

        if (item.type === "imageChoice" && item.trueChoiceCount < 1) {
          invalid = true;
          dispatch(handleErrorMessage(t("enhancedQuiz.atLeastOneImage")));
          return false;
        }

        if(invalid){
          return false;
        }
      }

      if(invalid){
        return false;
      }
      
      return obj;
    });

    if(invalid){
      return false;
    }

    let data = {};
    data.title = quizTemplateTitle;
    data.details = quizTemplateInstructions ?? '';
    data.questions = newList;

    if(data.title.trim() == "" ){
      invalid = true;
      dispatch(handleErrorMessage(t("enhancedQuiz.templateTitleEmpty")));
      return false;
    }

    if(invalid){
      return false;
    }

    if (actionType == "edit" && deletedList.deletedQuestions?.length > 0) {
      data.delete_question_ids = deletedList.deletedQuestions;
    }
    if (actionType == "edit" && deletedList.deletedChoices?.length > 0) {
      data.delete_choice_ids = deletedList.deletedChoices;
    }
    // console.log("FinalData", data);
    return (cloneDeep(data));
    

    // if ( actionType == "edit" && checkForErrors(data.questions)){
    //   dispatch(updateQuizTemplate(existingQuizTemplate.id, data)).then(()=>setIsLoadingBtn(false))
    // } else if (checkForErrors(data.questions)) {
    //   dispatch(createQuizTemplate(data)).then(()=>setIsLoadingBtn(false));
    // }
  };
  
  
  const saveTemplate = () => {
    let result = validate();
    // console.log("result inside", result)

    if ( actionType == "edit" && result){
      setIsLoadingBtn(true);
      dispatch(updateQuizTemplate(existingQuizTemplate.id, result)).then(()=>{
        setIsLoadingBtn(false)
        history.push({
          pathname: `/${routeType}/${params.id}`,
          state: { activeSection: "Assignments" },
        });
      });
    } else if (isCourse && result) {
      setIsLoadingBtn(true);
      dispatch(createQuizTemplate(result)).then(()=>{
        setIsLoadingBtn(false)
        history.push({
          pathname: `/quiz/create/${courseID}`,
          state: { isCourse: isCourse, sectionID: sectionID, courseID: courseID, isCreatedTemplate: true },
        });
      });
    } else if (result) {
      setIsLoadingBtn(true);
      dispatch(createQuizTemplate(result)).then(()=>{
        setIsLoadingBtn(false)
        history.push({
          pathname: `/${routeType}/${params.id}`,
          state: { activeSection: "Assignments" },
        });
      });
    }
  };

  return (
    <div className={styles.main_container}>
      {showModal && (
        <Modal
          onOutsideClick = {()=>setShowModal(false)}
          card={
            <QuestionTypeModal
              isCourse={isCourse}
              redirect={addQuestionType}
            />
          }
        />
      )}
      {/* <span
        className={styles.back_btn}
      >
        <img src="/Image/assignment/back_arrow.svg" alt="" />
        &nbsp; &nbsp;
      </span> */}
      <h5
          className={styles.back_btn}
          onClick={() => {
            history.goBack();
          }}
        >
          <img src="/Image/assignment/back_arrow.svg" alt="" />
          &nbsp; &nbsp;{currentClassOrGroupName ?? t("courseMonetisation.goBackBtn")}
        </h5>
      <div className={styles.feed_container}>
        <div className={styles.title_container}>
          <IconTitle
            title={quizTemplateTitle}
            img="/Image/assignment/White/Quiz.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />
          {/* <div className={styles.buttons}>
            <Button
              type="solid"
              label={t("common.preview")}
              clickHandler={() => {
                console.log("preview template");
              }}
            />
            <Button
              type="solid"
              label={t("assignment.save")}
              clickHandler={saveTemplate}
            />
          </div> */}

          {
          isMobile ? 
            ''
            :
            <Button
              type="solid"
              // label={t("assignment.save")}
              // clickHandler={saveTemplate}
              label={
                isLoadingBtn ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  t("assignment.save")
                )
              }
              clickHandler={saveTemplate}
              disable={isLoadingBtn ? true : false}
            />
          }
          
        </div>
        {/* middle and quiz-settings containers */}
        <div className={styles.middle_container}>
          {questionsList?.length > 0 &&
            questionsList?.map((item, questionIndex) => (
              <OuterQuestionContainer
                key={item?.id}
                question={item}
                questionIndex={questionIndex}
                questionType={item?.type}
                questionTypeIcon={item?.icon}
                isMandatory={item?.isMandatory}
                changeMandatoryStatus={() =>
                  handleMandatoryStatus(questionIndex)
                }
                onRemove={() => removeQuestion(questionIndex)}
                getQuestionInput={getQuestionInput}
                getMaxMarks={getMaxMarks}
                totalQuestionCount={questionsList.length}
                moveUp={() => handleMoveUp(questionIndex)}
                moveDown={() => handleMoveDown(questionIndex)}
                actionType={actionType}
              >
                {item?.choices?.length > 0 &&
                  item.choices?.map((choice, choiceIndex) => (
                    <PossibleChoice
                      key={choice?.id}
                      choice={choice}
                      questionIndex={questionIndex}
                      choiceIndex={choiceIndex}
                      questionType={item.type}
                      onRemove={() => removeChoice(questionIndex, choiceIndex)}
                      getChoiceInput={getChoiceInput}
                      changeCorrectStatus={handleCorrectStatus}
                      getGridChoiceInput={getGridChoiceInput}
                      actionType={actionType}
                    />
                  ))}

                {/*  --- for shortAnswer and Paragraph --- */}
                {(item?.type === "shortAnswer" ||
                  item?.type === "paragraph") && (
                  <>
                    <br />
                    <AnswerTextArea
                      isLong={item.type === "paragraph" ? true : false}
                      disabled={true}
                      placeholder={
                        item.type === "paragraph"
                          ? t("enhancedQuiz.longAnswerText")
                          : t("enhancedQuiz.shortAnswerText")
                      }
                    />
                  </>
                )}

                {item?.type != "shortAnswer" &&
                  item?.type != "paragraph" && (
                    <div className={styles.addChoices}>
                      <TooltipWrapper title={t("enhancedQuiz.addChoice")}>
                        <img
                          src={"/Image/plus-circle-custom.svg"}
                          alt="add choices"
                          onClick={
                            item?.type === "trueFalse" &&
                            questionsList[questionIndex].choices.length == 2
                              ? () =>
                                  dispatch(
                                    handleErrorMessage(
                                      t("enhancedQuiz.twoChoices")
                                    )
                                  )
                              : () => addChoice(questionIndex)
                          }
                        />
                      </TooltipWrapper>
                    </div>
                  )}
              </OuterQuestionContainer>
            ))}
          <div
            className={styles.addQuestionCard}
            onClick={() => toggleAddQuestionModal()}
          >
            <div className={styles.innerBorder}>
              <div className={styles.addQuestionText}>
                <img
                  alt=""
                  src="/Image/CourseMonetisation/plus_icon.svg"
                  className={styles.plus}
                />
                <span style={{ marginLeft: 10 }}>
                  {t("enhancedQuiz.addQuestion")}
                </span>
              </div>
            </div>
          </div>
        </div>
      {
        isMobile ?
            <div className={styles.btnPosition} >
            <Button
                type="solid"
                // label={t("assignment.save")}
                // clickHandler={saveTemplate}
                label={
                  isLoadingBtn ? (
                <CircularProgress color="inherit" size={20} />
                ) : (
                  t("assignment.save")
                  )
                }
                clickHandler={saveTemplate}
                disable={isLoadingBtn ? true : false}
            />
            </div>
            :
            ''
      }
          </div>
    </div>
  );
};

export default CreateQuizTemplatePage;
