import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styles from "./AttemptQuizComponent.module.css";
import IconTitle from "../../../components/commons/IconTitle";
import ButtonMUI from "@mui/material/Button";
import LinearProgressComponent from "../../CourseLecturePlan/Components/LinearProgressComponent";
import Pagination from "@mui/material/Pagination";
import shuffle from "lodash.shuffle";
import Countdown, { CountdownApi } from 'react-countdown';
import QuestionChoiceComponent from "./QuestionChoiceComponent";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from 'lodash.clonedeep'
import { handleErrorMessage } from "../../../redux/actions/classes";
import { getIndividualQuiz, returnQuizSubmission, updateQuizMarks } from "../../../redux/actions/enhancedQuiz";
import { CircularProgress } from "@mui/material";
import Modal from "../../../components/commons/Modal";
import ConfirmSubmissionModal from "../Modals/ConfirmSubmissionModal";

const AttemptQuizComponent = (props) => {
  const isMobile = window.innerWidth < 768;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const countdownRef = useRef();
  const [questionNumber, setQuestionNumber] = useState(1);
  const [attemptedQuestionCount, setAttemptedQuestionCount] = useState(0);
  const [submitTrigger, setSubmitTrigger] = useState(0);
  const [currentTime, setCurrentTime] = useState(
    props.currentQuiz?.is_timed ? props.currentQuiz?.duration : 0
  );

  // Backend is sending the duration in minutes but the Countdown component expects it in milliseconds
  const [countdownDuration, setCountdownDuration] = useState(
    props.currentQuiz?.is_timed
      ? Date.now() + props.currentQuiz?.duration * 60 * 1000
      : 0
  );

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [revealAnswers, setRevealAnswers] = useState(false);

  const isRevealing = (props.currentQuiz?.show_answers && revealAnswers) || props.isGrading;
  // const [countdownDuration, setCountdownDuration] = useState(
  //   props.currentQuiz?.is_timed
  //     ? Date.now() + 2.5 * 60 * 1000
  //     : 0
  // );


  /**
   * We're using the questionMarksKey object to keep track of the marks given by quizOwner for each subjective question,
   * the format for questionMarksKey will be like this
   * {
   *    question_id : question_marks_given_by_quiz_owner,
   * }
   * 
   */
  const [questionMarksKey, setQuestionMarksKey] = useState({})

  const [questions, setQuestions] = useState(
      props.isPreview
    ? props.currentQuizTemplate?.questions
    : props.isGrading 
    ? props.currentQuiz?.template?.questions
    ?.map((item) =>{ 
      if(item.question_type === "ShortAnswer" || item.question_type === "Paragraph"){
        item.userObtainedMarks = "";
      } 
      return item;
    })
    : props.currentQuiz?.question_randomized
    ? shuffle(props.currentQuiz?.template?.questions)
    : props.currentQuiz?.template?.questions
  );

  useEffect(()=>{
    if(props.isGrading){
      let obj = {};
      props.currentQuiz?.template?.questions?.forEach((item) =>{ 
        if(item.question_type === "ShortAnswer" || item.question_type === "Paragraph"){
          obj[item.id] = null;
        }
      });
      
      setQuestionMarksKey(obj);
    }
  }, [props.isGrading])


  const studentSubmissionID = props.currentQuiz?.user_submission?.id;
  const userAnswers = props.isGrading ? props.userAnswers : props.currentQuiz?.user_submission?.user_answers

  let isQuestionSubjective = (questions?.[questionNumber - 1]?.question_type === "ShortAnswer" || questions?.[questionNumber - 1]?.question_type === "Paragraph");

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 40,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    padding: "8px 15px",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "25px",
      padding: "17px",
      backgroundColor: "#ED1E24"
    },
  };

  


  // const handleEditOrSubmit = () => {
  //   if (props.isPreview) {
  //     history.push({
  //       pathname: `/template/create/${props.classOrGroupId}`,
  //       state: {
  //         templateId: props.currentQuizTemplate?.id,
  //         actionType: "edit",
  //         routeType: props.routeType,
  //       },
  //     });
  //   } else {
  //     console.log("submitQuiz");
  //     setSubmitTrigger((submitTrigger) => submitTrigger + 1);
  //     if(props.currentQuiz?.is_timed) {
  //       countdownRef.current.pause();
  //     }
  //   }
  // };

  const handleEdit = () => {
    history.push({
      pathname: `/template/create/${props.classOrGroupId}`,
      state: {
        templateId: props.currentQuizTemplate?.id,
        actionType: "edit",
        routeType: props.routeType,
      },
    });
  }

  const handleSubmit = () => {
    console.log("HandleSubmitCalled")
    setSubmitTrigger((submitTrigger) => submitTrigger + 1);
    setShowConfirmationModal(false);
    if(props.currentQuiz?.is_timed) {
        countdownRef.current.pause();
    }    
  }

  const validateMarks = (marks) => {
    let errorMessage = null;
    switch (marks) {
      case null:
      case undefined:
        errorMessage = "errorMessageTranslations.enterValidNumber";
        break;
      default:
        if (!isNaN(marks)) {
          if (marks < 0) {
            errorMessage = "enhancedQuiz.marksLessThanZero";
          } else if (marks > questions?.[questionNumber - 1]?.marks ) {
            errorMessage = "enhancedQuiz.marksCantBeGreater";
          }
        } else {
          errorMessage = "errorMessageTranslations.enterValidNumber";
        }
    }

    if (errorMessage) {
      dispatch(handleErrorMessage(`${t(errorMessage)}`));      
      return false;
    } else {
      return true;
    }
  };

  const handleMarksUpdate = (questionId, marks) => {
    let isMarksValid = validateMarks(marks);
    
    if(isMarksValid){
      let updatedQuestions = cloneDeep(questions);   
      updatedQuestions[questionNumber - 1].userObtainedMarks = marks;
      
      setQuestions(updatedQuestions);
      setQuestionMarksKey({...questionMarksKey,[questionId]: marks});
    } 
  }

 const handleSaveMarks = () => {
  setIsLoadingBtn(true);
  let totalMarks = parseInt(props.userMarks)
  Object.keys(questionMarksKey)?.forEach((key) => {
    if (questionMarksKey[key] === null) {
      dispatch(handleErrorMessage(`${t("enhancedQuiz.gradeRemainingQuestions")}`));
      setIsLoadingBtn(false);
      totalMarks = null;        
    } 
  });

  if(totalMarks != null) {    
    Object.keys(questionMarksKey)?.forEach((key)=>{
      totalMarks += questionMarksKey[key]      
    })
    dispatch(updateQuizMarks(props.userSubmissionId, totalMarks))
      .then(()=>dispatch(returnQuizSubmission(props.userSubmissionId)))
      .then(()=>setIsLoadingBtn(false))
      .then(()=>history.goBack());
  }
 }

 const handleShowAnswers = () => {
  setIsLoadingBtn(true);
  dispatch(getIndividualQuiz(props.currentQuiz?.id)).then(() =>{
    setRevealAnswers(true);
    setQuestionNumber(1);
    setIsLoadingBtn(false);
  })
 }

 const handleModal = () => {
  if(props.currentQuiz?.template?.ques_count === attemptedQuestionCount) {
    setModalData(modalOptions.areYouSure);
  } else {
    setModalData(modalOptions.questionLeft);
  }
  setShowConfirmationModal(true);
}

 
 const modalOptions = {
   areYouSure : {
     img: "/Image/lecturePlan/error_outline.svg",
     mainText: t("enhancedQuiz.areYouSure"),
     subText: t("enhancedQuiz.thisFinalSubmission"),
     btn1Text: t("common.cancel"),
     btn2Text: t("enhancedQuiz.submitQuiz"),
     btn1Handler: ()=>setShowConfirmationModal(false),
     btn2Handler: handleSubmit,
    },    
    questionLeft : {
      img: "/Image/lecturePlan/error_outline.svg",
      mainText: `${props.currentQuiz?.template?.ques_count - attemptedQuestionCount} ${t("enhancedQuiz.questionLeft")}`,
      subText: t("enhancedQuiz.wantToProceed"),
      btn1Text: t("enhancedQuiz.answerQuestion"),
      btn2Text: t("enhancedQuiz.submitQuiz"),
      btn1Handler: ()=>setShowConfirmationModal(false),
      btn2Handler: handleSubmit,
    },
    // successful : {
    //   img: "/Image/lecturePlan/error_outline.svg",
    //   mainText: 'Quiz Submitted',
    //   subText: 'You have successfuly submitted the quiz',
    //   btn1Text: 'OK',
    //   btn1Handler: () => {
    //     setSubmitTrigger((submitTrigger) => submitTrigger + 1);
    //     if(props.currentQuiz?.is_timed) {
    //       countdownRef.current.pause();
    //     }
    //   }
    // }    
  }
  
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalData, setModalData] = useState(modalOptions.areYouSure);

  return (
    <div className={props.isCourse ? `${styles.quiz_outer_container} ${styles.quiz_modal_container}` : `${styles.quiz_outer_container}`}>
      {showConfirmationModal && (
        <Modal
          card={
            <ConfirmSubmissionModal
              modalData={modalData}                          
            />
          }
        />
      )}

      <div className={styles.quiz_header}>
        <div className={styles.quiz_header_one}>
          {props.isCourse && <img src={"/Image/lecturePlan/left-arrow.svg"} onClick={props.onClose}/>}
          <IconTitle
            title={
              (props.isPreview
                ? `${
                    props.currentQuizTemplate?.title + " - " + t("common.preview")
                  }`
                : props.currentQuiz?.title) ?? ""
            }
            img="/Image/assignment/White/Quiz.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />
        </div>
        <div className={styles.quiz_sub_header}>
          {!isMobile && !props.isEnterprise && ( <ButtonMUI
            variant="outlined"
            disableRipple
            onClick={props.isGrading ? handleSaveMarks : props.isPreview ? handleEdit : handleModal}
            sx={styleButtonMUI}
            disabled={
              props.isPreview
                ? !props.currentQuizTemplate?.is_editable
                : (isRevealing && !props.isGrading)
            }
          >
            <span className={styles.btn_label}>
              {isLoadingBtn ? (
                <CircularProgress color="inherit" size={15} />
              ) : t(
                  `${
                    props.isPreview
                      ? "enhancedQuiz.editTemplate" 
                      : props.isGrading 
                      ? "enhancedQuiz.saveMarks"
                      : "enhancedQuiz.submitMyAnswers"
                  }`
              )}
            </span>
          </ButtonMUI>)}

          {
            isMobile ? 
            (
              !(props.isGrading || props.isPreview) && props.currentQuiz?.is_timed && (
                <div className={currentTime < 2 ? `${styles.timer_container} ${styles.time_running_out}` : `${styles.timer_container}`}>
                  <img src="/Image/AssignmentAndQuizzes/DueDate.svg" />
                  <Countdown
                    date={countdownDuration}
                    ref={countdownRef}
                    daysInHours={true}
                    zeroPadTime={2}
                    onTick={(timeObject) =>
                      setCurrentTime(Number((timeObject?.total / 60000).toFixed(2)))
                    } //converting from milliseconds to minutes
                    onComplete={(timeObject)=>{
                      // handleEditOrSubmit()
                      handleSubmit();
                    }}
                  />
                </div>
              )
            ) 
            : 
            ''
          }

          {
            isMobile ? 
            (questions?.[questionNumber - 1]?.is_mandatory && 
              <span className={styles.required_label_mobile}>*{t("quizTwo.Required")}</span> ) 
            : 
            '' 
          }
        </div>
      </div>

      <div className={styles.progress_and_timer_container}>
        <div className={styles.progress_container}>
          <span>
            {`${props.isPreview ? questionNumber : attemptedQuestionCount} ${t(
              "common.of"
            )} ${
              props.isPreview
                ? props.currentQuizTemplate?.ques_count || 0
                : props.currentQuiz?.template?.ques_count || 0
            } ${t(
              `${
                props.isPreview
                  ? "enhancedQuiz.questionsViewed"
                  : "enhancedQuiz.questionsAnswered"
              }`
            )}`}
          </span>
          {/* progress and time to be setup */}
        </div>
        {/* <div className={styles.progress_bar}>
          <LinearProgressComponent
            progress={
              props.isPreview
                ? Math.floor((questionNumber / questions?.length) * 100)
                : Math.floor((attemptedQuestionCount / questions?.length) * 100)
            }
          />
        </div> */}
        <div className={styles.progress_container_right}>
        {isMobile ? '' : (
          !(props.isGrading || props.isPreview) && props.currentQuiz?.is_timed && (
            <div className={currentTime < 2 ? `${styles.timer_container} ${styles.time_running_out}` : `${styles.timer_container}`}>
              <img src="/Image/AssignmentAndQuizzes/DueDate.svg" />
              <Countdown
                date={countdownDuration}
                ref={countdownRef}
                daysInHours={true}
                zeroPadTime={2}
                onTick={(timeObject) =>
                  setCurrentTime(Number((timeObject?.total / 60000).toFixed(2)))
                } //converting from milliseconds to minutes
                onComplete={(timeObject)=>{
                  // handleEditOrSubmit()
                  handleSubmit();
                }}
              />
            </div>
          )
        )}

        {props.isGrading &&
          <>
          <span className={styles.grade_label}>{t("profile.grade")}:</span>
          <div className={styles.grade_input_container}>
            <input
              type="number"
              className={styles.grade_input}
              // value={questions?.[questionNumber - 1]?.userObtainedMarks}
              value={isQuestionSubjective ? questions?.[questionNumber - 1]?.userObtainedMarks : userAnswers?.filter((item)=>item.question == questions?.[questionNumber - 1]?.id)[0]?.user_marks || 0}
              disabled = {!isQuestionSubjective}
              onChange={(e) => {
                handleMarksUpdate(questions[questionNumber - 1].id, parseInt(e.target.value))
              }}
            />
            <div className={styles.grade_input_inner_container}>
              &nbsp; / &nbsp;
              <span>
                {questions?.[questionNumber - 1]?.marks ?? ""} &nbsp;            
              </span>
            </div>
          </div>        
          </>
        }

        {isMobile ? '' : (questions?.[questionNumber - 1]?.is_mandatory && 
          <span className={styles.required_label}>*{t("quizTwo.Required")}</span> )}
        </div>
      </div>
      {/* <h2 style={{ margin: "15px" }}>Question Number: {questionNumber}</h2>
      {props.currentQuiz?.template?.questions[questionNumber-1]?.text} */}
      {/* {console.log("userObtainedMarks", questions[questionNumber - 1].userObtainedMarks)} */}
      {console.log("questionsHere", questions, questionNumber)}
      {console.log("questionMarksKey", questionMarksKey)}
      {console.log("currentQuestion", questions[questionNumber - 1])}
      {console.log("currentQuiz in Attempt Component", props.currentQuiz)}
      
      <div className={styles.question_container}>
        <div className={styles.question_header}>
          <span>{questions?.[questionNumber - 1]?.text ?? ""}</span>
          <span>
            {questions?.[questionNumber - 1]?.marks ?? ""} &nbsp;
            {t(
              `enhancedQuiz.${
                questions?.[questionNumber - 1]?.marks > 1 ? "marks" : "mark"
              }`
            )}
          </span>
        </div>
        <QuestionChoiceComponent
          // isPreview={props.isPreview}
          type={questions?.[questionNumber - 1]?.question_type}
          questionID={questions?.[questionNumber - 1]?.id}
          choices={questions?.[questionNumber - 1]?.choice}
          getAttemptedQuestionCount={(count) =>
            setAttemptedQuestionCount(count)
          }
          studentSubmissionID={studentSubmissionID}
          trigger={submitTrigger}
          currentTime={currentTime}
          classOrGroupId={props.classOrGroupId}
          routeType={props.routeType}
          isCourse = {props.isCourse}
          onClose = {props.onClose} // only valid when isCourse:true
          isGrading={props.isGrading}
          userAnswers={userAnswers} // Only valid when isGrading:true and isRevealing:true
          isRevealing={isRevealing}
          isShowAnswers={props.currentQuiz?.show_answers} // Whether show_answers is true in the quiz settings
          handleShowAnswers={handleShowAnswers}
        />
      </div>

      <div className={styles.pagination_container}>
        <Pagination
          count = {
            props.isPreview
            ? props.currentQuizTemplate?.ques_count
            : props.isGrading 
            ? questions?.length
            : props.currentQuiz?.template?.ques_count
          }
          page={questionNumber}
          onChange={(event, value) => {
            setQuestionNumber(value);
          }}
        />
      </div>

      {
        isMobile && !props.isEnterprise &&  
        ( <ButtonMUI
          variant="outlined"
          disableRipple
          // onClick={props.isGrading ? handleSaveMarks : handleEditOrSubmit}
          onClick={props.isGrading ? handleSaveMarks : props.isPreview ? handleEdit : handleModal}
          sx={styleButtonMUI}
          disabled={
            props.isPreview
              ? !props.currentQuizTemplate?.is_editable
              : (isRevealing && !props.isGrading)
          }
        >
          <span className={styles.btn_label}>
            {isLoadingBtn ? (
              <CircularProgress color="inherit" size={15} />
            ) : t(
                `${
                  props.isPreview
                    ? "enhancedQuiz.editTemplate" 
                    : props.isGrading 
                    ? "enhancedQuiz.saveMarks"
                    : "enhancedQuiz.submitMyAnswers"
                }`
            )}
          </span>
        </ButtonMUI>)         
        }

    </div>
  );
};

export default AttemptQuizComponent;
