import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import styles from "../../../Profile.module.css";

class CoverFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    };

    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  render() {
    return (
      <div
        style={{
          // backgroundColor: '#5555555A',
          height: 180,
          borderRadius: 8,
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <div style={{ position: "relative", width: "100%", height: 180, overflow: "hidden", borderRadius: 10 }}>
          <img src={this.props.coverPicUrlview ?? "/Image/apps/profile-resume-edit-view/cover-image.png"} className={styles.coverPicture} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state.profile.user || "",
    coverPicUrl: state.profile.userCoverPic || "/Image/apps/profile-resume-edit-view/cover-image.png",
    coverPicUrlview: state.profile.userCoverPicView || "/Image/apps/profile-resume-edit-view/cover-image.png",
  };
};

export default connect(mapStateToProps, null)(CoverFeed);
