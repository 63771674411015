export const GET_CLASS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const GET_GROUP_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/`;
export const GET_CLASSES_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const GET_GROUPS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/`;
export const POST_CLASS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const POST_GROUPS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/`;
export const GET_DASHBOARD_POST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/post/`;
export const POST_LIKE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/like/`;
export const COMMENT_LIKE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/commentlike/`;
export const POST_COMMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/comment/`;
export const POST_COURSE_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/course/`;
export const POST_MEMBERS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/members/`;
export const UPDATE_CLASS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const GET_USER_SUGGESTIONS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/suggestions/`;
export const GET_CLASS_ID_BY_CODE = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/code/`;
export const GET_GROUP_ID_BY_CODE = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/code/`;

export const DELETE_MEMBER_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/members/`;
export const GET_USER_SETTINGS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/user/setting/`;
export const PUT_USER_SETTINGS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/user/setting/`;
export const PUT_CLASS_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/class/`;
export const PUT_GROUP_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/group/`;

export const PAYMENT_CREATE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/payments/`;
export const PAYMENT_SUCCESS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/payments/success/`;
export const PAYMENT_FAILURE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/payments/failure/`;

export const TRANSLATE_DATA_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/translations/translate/`;
export const CONNECTIONS_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/connections/`;
export const GET_STUDENT_ASSIGNMENT_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/assignment-list/`;
export const GET_STUDENT_INDIVIDUAL_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const GET_ALL_ASSIGNMENTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const SUBMIT_ASSIGNMENT_SOLUTION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
export const GET_STUDENT_SUBMISSION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
export const UPDATE_STUDENT_ASSIGNMENT_SOLUTION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
// Assignment and Quiz

export const POST_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const UPDATE_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const GET_ASSIGNMENT_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const PUT_ASSIGNMENT_DETAILS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;

export const GET_INDIVIDUAL_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const GET_STUDENT_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/student_list/`;
export const DELETE_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/`;
export const SAVE_ASSIGNMENT_MARKS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
export const RETURN_ASSIGNMENT_MARKS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/graded/`;
export const GET_ALL_ASSIGNMENT_SUBMISSIONS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;
export const GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/`;

export const GET_QUIZ_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const GET_STUDENT_INDIVIDUAL_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const GET_STUDENT_QUIZ_SUBMISSION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;
export const SUBMIT_QUIZ_SOLUTION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;
export const UPDATE_STUDENT_QUIZ_SOLUTION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;

export const POST_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const GET_INDIVIDUAL_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const PUT_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const DELETE_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/`;
export const GET_QUIZ_STUDENT_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/student_list/`;
export const RETURN_QUIZ_MARKS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/graded/`;
export const SAVE_QUIZ_MARKS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;

export const GET_ALL_QUIZ_SUBMISSIONS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;
export const GET_INDIVIDUAL_QUIZ_SUBMISSIONS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/`;

export const GET_STUDENT_QUIZ_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz/quiz_list/`;
export const GET_ALL_ASSIGNMENT_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment/quiz_assign_order/`;

export const POST_ASS_COMMENTS = `${process.env.REACT_APP_API_BASE_URL}api/v1/assign-comments/`;
export const POST_QUIZ_COMMENTS = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-comments/`;
export const GET_ASS_COMMENTS = `${process.env.REACT_APP_API_BASE_URL}api/v1/assign-comments/?assign_id`;
export const GET_QUIZ_COMMENTS = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-comments/?quiz_id`;

export const ASSIGN_STUDENTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/enrol-students/`;
export const UNASSIGN_STUDENTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/unassign-all/`;
export const GRADE_ZERO_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/grade-zero/`;
export const GRADE_ZERO_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/quiz-submission/grade-zero/`;

export const GET_INDIVIDUAL_ASSIGNMENT_SUBMISSION_ID_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/assignment-submission/get-assignment-submission/`;


// ------- gradebook --------

export const DOWNLOAD_STUDENT_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/gradebook/student-list-pdf/`;
export const DOWNLOAD_GRADEBOOK_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/gradebook/pdf/`;
export const DOWNLOAD_INDIVIDUAL_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/gradebook/asg-pdf/`;
export const DOWNLOAD_INDIVIDUAL_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/gradebook/quiz-pdf/`;


// -------- boostPost/boostProfile ---------
export const GET_MY_POSTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/post/`;

// ------------ Profile Page -----------
export const ADD_PUBLICATION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/publications/`
export const UPDATE_PUBLICATION_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/publications/`

export const ADD_TRAINING_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/training_and_conferences/`
export const UPDATE_TRAINING_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/training_and_conferences/`

export const ADD_RESEARCH_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/researches/`
export const UPDATE_RESEARCH_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/researches/`

export const ADD_PROJECT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/projects/`
export const UPDATE_PROJECT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/projects/`
export const TOGGLE_SECTION_VISIBILITY_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/`


// ------------------- live Chat ------------------------

export const GET_CHAT_LIST_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/channel-subscriptions/`
export const SEND_MESSAGE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/messages/`
export const GET_CHAT_MESSAGES_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/store_msg/`
export const GET_SEARCH_RESULT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results/`
export const CREATE_CHANNEL_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/channels/`




// `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results/?search=${search_query}


//  ----------------------- enterpriseDashboard ------------------
export const GET_DASHBOARD_DATA_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/`
export const ADD_ENTERPRISE_FACULTY_STUDENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/deptSubscription/`
export const DELETE_ENTERPRISE_FACULTY_STUDENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/deptSubscription/deleteDeptSubs/`
export const ACTIVE_CLASS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/deptSubscription/activeClass/`
export const INACTIVE_CLASS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/deptSubscription/inactiveClass/`

export const ACTIVE_GROUP_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/deptSubscription/activeGroup/`
export const INACTIVE_GROUP_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/deptSubscription/inactiveGroup/`

// ------------------ Facuty/Student Dashboard ----------------

export const GET_FACULTY_DASHBOARD_CLASSES_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/subs-dashboard-classes/`
export const GET_FACULTY_DASHBOARD_CLASS_STUDENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/fac-classes-members/`
export const GET_FACULTY_DASHBOARD_GROUP_MEMBERS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/fac-groups-members/`
export const GET_FACULTY_DASHBOARD_CLASS_ASSIGNMENT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/subs-classes-assignments`
export const GET_FACULTY_DASHBOARD_CLASS_QUIZ_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/subs-classes-quizzes`
export const GET_FACULTY_DASHBOARD_CLASS_COURSE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/subs-classes-courses`
export const GET_FACULTY_DASHBOARD_CLASS_POSTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/subs-classes-posts`

export const GET_FACULTY_DASHBOARD_GROUPS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/subs-dashboard-groups/`
export const GET_FACULTY_DASHBOARD_GROUP_POSTS_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/subs-groups-posts`


// --------------------------------  Course Review  --------------------------------


export const DOWNLOAD_COURSE_CERTIFICATE_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/courses/get_certificate/`


// --------------------- openToWork ----------------------

export const ADD_OPEN_TO_WORK_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/open_to_work/`;
export const EDIT_OPEN_TO_WORK_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/open_to_work/`;
export const DELETE_OPEN_TO_WORK_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/open_to_work/`;


// ------------------- opportunites filter -----------------

export const GET_DROPDOWN_CATEGORIES_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/filters/drop_down/`;


export const GET_OPPORTUNITIES_FILTER_RESULT_API = `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/filters/dashboard/`;
