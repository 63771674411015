import React, { useState, useEffect } from "react";
import styles from "./css/ReattemptQuizPage.module.css";
import { useTranslation } from "react-i18next";
import AttemptQuizComponent from "./Components/AttemptQuizComponent";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import {
  getIndividualQuiz,
  getIndividualQuizTemplate,
} from "../../redux/actions/enhancedQuiz";
import { CircularProgress } from "@mui/material";
import IconTitle from "../../components/commons/IconTitle";
import DueDate from "../../components/assignmentAndQuiz/DueDate";
import ButtonMUI from "@mui/material/Button";
import ReattemptConfirmationModal from "./Modals/ReattemptConfirmationModal";
import Modal from "../../components/commons/Modal";

const ReattemptQuizPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);


  const classOrGroupId = location?.state?.classOrGroupId;
  const quiz = location?.state?.quiz;

  const isReattempting = location?.state?.quiz?.user_submission.attempt > 0 
  

  const routeType = location?.state?.routeType || "class";
  const currentClassOrGroupName = useSelector((state) =>
    routeType == "class"
      ? state?.classes?.class?.name
      : state?.groups?.group?.name
  );


  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    padding: "12px",
    // marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "25px",
      padding: "18px 32px",
      width: "50%",
      margin: "auto"
    },
  };

  const handleAttempt = () => {
    history.push({
      pathname: `/quiz/attempt/${quiz?.classes?.id ?? quiz?.group?.id}`,
      state: {
        routeType: location.state.routeType,
        isOwner: location.state.isOwner,
        quizID: quiz.id,
        quizTemplateID: quiz?.template?.id,
        classOrGroupId: quiz?.classes?.id ?? quiz?.group?.id,
        quiz: quiz,
      },
    });
  };

  if (isLoading && false) {
    return (
      <div className={styles.loader_style}>
        <CircularProgress sx={{ color: "#00d498" }} size={50} />
      </div>
    );
  } else {
    return (
      <div className={styles.main_container}>
        {console.log("TheQuiz", location?.state?.quiz)}
        {console.log("TheRoute", location?.state?.routeType)}
        {showConfirmModal && (
        <Modal
          card={
            <ReattemptConfirmationModal
              onYes={handleAttempt}
              onGoBack={() => {
                setShowConfirmModal(false);                
              }}
            />
          }
          onOutsideClick={() => setShowConfirmModal(false)}
        />
      )}
        <h5
          className={styles.back_btn}
          onClick={() => {
            if (classOrGroupId) {
              history.push({
                pathname: `/${routeType}/${classOrGroupId}`,
              });
            } else {
              history.push({
                pathname: `/user`,
              });
            }
          }}
        >
          <img src="/Image/assignment/back_arrow.svg" alt="" />
          &nbsp; &nbsp;
          {currentClassOrGroupName ?? t("courseMonetisation.goBackBtn")}
        </h5>
        <div className={styles.content_container}>
          <div className={styles.title_container}>
            <div className={styles.title_container_heading}>
            <IconTitle
              title={quiz.title || ""}
              img="/Image/assignment/assignment-2.svg"
              notClickable={true}
              type="ASSIGNMENT"
            />
            </div>

            <div className={styles.due_date}>
              <span className={styles.duedate_component}>
                <DueDate date={quiz.due_date} />
              </span>
            </div>
          </div>

          <div className={styles.middle_container}>
            <div className={styles.left_container}>
              <p>{quiz.details || ""}</p>
            </div>

            <div className={styles.right_container}>
              <div className={styles.attempt_container}>
                <span className={styles.max_attempt}>
                  {t("enhancedQuiz.maxAttempts")}&nbsp;&nbsp; : &nbsp;&nbsp;{" "}
                  {(quiz.reattempt + 1) || 0}
                </span>
                <span className={styles.your_attempt}>
                  {t("enhancedQuiz.yourAttempts")}&nbsp;&nbsp; : &nbsp;&nbsp;{" "}
                  {quiz.user_submission?.attempt || 0}
                </span>

                <ButtonMUI
                  variant="outlined"
                  disableRipple
                  disabled={(quiz.reattempt + 1) === quiz.user_submission.attempt}
                  onClick={isReattempting ? ()=>setShowConfirmModal(true) : handleAttempt}
                  sx={styleButtonMUI}
                >
                  <span className={styles.btn_label}>
                    {t(`${isReattempting ? "enhancedQuiz.reattempt" : "enhancedQuiz.attempt"}`)}
                  </span>
                </ButtonMUI>
              </div>

              <div className={styles.grade_container}>
                <span className={styles.grade_label}>
                  {t("assignment.grade")}
                </span>
                <p className={styles.marks_div}>
                  {`${quiz.user_submission?.is_returned ? (quiz.user_submission.marks || 0) : '__'} / ${quiz.max_marks || 0}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ReattemptQuizPage;
