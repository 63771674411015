import React from "react";

import styles from "../../../../Profile.module.css";
import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

export default class AddRecommendations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      askfrom: "",
      organization: "",
      relationship: "",
      position: "",
      message: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    this.props.changeEditState(false);
    document.body.style.overflow = "visible";
  }

  ScrollBody() {
    return (
      <div>
        <div
          style={{
            // borderBottom: '1px solid #5555555A',
            paddingBottom: 20,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading}>Who do you want to ask ?</p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder="Who do you want to ask ?"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.askfrom}
              onChange={(event) => {
                this.setState({ askfrom: event.target.value });
              }}
            />
            <br />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className={styles.modal_heading}>How do you know {this.state.askfrom}</p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder="Select a relationship"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.relationship}
              onChange={(event) => {
                this.setState({ relationship: event.target.value });
              }}
            />
            <br />
          </div>
          {/* <p style={{ textAlign: 'right', fontSize: 12, color: '#555555', fontWeight: 500, marginRight: 10 }}>Learn More</p> */}
        </div>

        <p className={styles.modal_heading}>Message to {this.state.askfrom}</p>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <textarea
            className={styles.modal_para}
            id={styles.textarea}
            cols={55}
            rows={8}
            placeholder="Ex : Write something about yourself"
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 10,
            }}
            value={this.state.desc}
            onChange={(event) => {
              this.setState({ desc: event.target.value });
            }}
          />
        </div>
      </div>
    );
  }

  SaveButtonOnClick() {
    this.props.changeEditState(false);
    document.body.style.overflow = "visible";
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.title} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />
      </div>
    );
  }
}
