import React from "react";

import DpicFeed from "../../../../../components/commons/DpicFeed";
import EditDescFeed from "./EditDescFeed";
import SocailFeed from "./SocialFeed";
import InstituteCode from "./InstituteCode";
import IconSectionFeed from "../../../../../components/commons/IconSectionFeed";
import Tags from "./Tags";
import styles from "../../../Profile.module.css";

export default class LeftSideBar extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <DpicFeed props={this.props} editpage={false} />
        </div>

        <div
          style={{
            width: "100%",
          }}
        >
          <EditDescFeed editpage={this.props.props.editpage} />
          <br />
          <br />
        </div>

        <div
          style={{
            width: "100%",
          }}
        >
          <InstituteCode editpage={this.props.props.editpage} />
          <br />
          {/* <br /> */}
        </div>

        <div
          style={{
            width: "100%",
          }}
        >
          <SocailFeed editpage={this.props.props.editpage} />
          <br />
          <br />
        </div>
        <div
          style={{
            width: "100%",
          }}
        >
          <Tags editpage={this.props.props.editpage} />
          <br />
          <br />
        </div>

        <div
          className={styles.icon_section_feed}
          style={{
            width: "100%",
          }}
        >
          <IconSectionFeed />
        </div>
      </div>
    );
  }
}
