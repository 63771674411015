import React from "react";
import styles from "./css/AssignmentHeading.module.css";

function AssignmentHeading(props) {
  return (
    <div className={styles.headingDiv}>
      <img src={props.type == 'quiz'? "/Image/assignment/White/Quiz.svg" : "/Image/assignment/White/Assignment.svg"} alt="" />
      <h4>{props.title}</h4>
    </div>
  );
}

export default AssignmentHeading;
