import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch, connect } from "react-redux";
import IconTitle from "../../components/commons/IconTitle";
import styles from "./css/ViewQuizStatus.module.css";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import AssignmentListIndividual from "../../components/assignmentAndQuiz/AssignmentListIndividual";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { formatDate } from "../../redux/constants/commonFunctions";
import ErrorFourZeroFour from "../../components/ErrorFourZeroFour";

import StudentAssignmentSubmissionList from "../../components/assignmentAndQuiz/StudentAssignmentSubmissionList";
import Modal from "../../components/commons/Modal";
import MarksReturnModal from "./Modals/MarksReturnModal";
import {
  getAllFilteredAssignmentSubmissions,
  getIndividualAssignment,
  getStudentList,
  returnAssignmentMarks,
  assignStudentToAssignment,
  unassignStudentFromAssignment,
  gradeZero,
} from "../../redux/actions/assignment";
import DueDate from "../../components/assignmentAndQuiz/DueDate";
import {
  getAllFilteredQuizSubmissions,
  getIndividualQuiz,
  getQuizStudentList,
  returnQuizMarks,
} from "../../redux/actions/quiz";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getAllQuizSubmissions, unassignStudentFromQuiz, assignStudentToQuiz, gradeStudentZeroForQuiz } from "../../redux/actions/enhancedQuiz";

const ViewQuizStatus = (props) => {
  // PropsUsed: assignmentClass, dueDate,

  // const student_list = useSelector((state)=>props.assignmentType == "ASSIGNMENT"
  // ? state?.assignment?.student_list
  // : state?.quiz?.student_list || [])

  const student_list = useSelector(state => state?.enhancedQuiz?.quizSubmissions ) || []

  const unassigned_student_list = useSelector(state => state?.enhancedQuiz?.unassignedStudents) || [];

  const assignment = useSelector(state => state?.enhancedQuiz?.individualQuiz) || {}

  // const assignment = useSelector((state)=>props.assignmentType == "ASSIGNMENT"
  // ? state?.assignment?.assignment
  // : state?.quiz?.quizInd || {})

  // const filtered_submissions = useSelector((state)=>props.assignmentType == "ASSIGNMENT"
  // ? state?.assignment?.filtered_assignment_submissions
  // : state?.quiz?.filtered_quiz_submissions || [])

  const isIdValid = useSelector((state)=>state?.errors?.statusCode === 400 ? false : true)

  const dispatch = useDispatch();


  const [activeSection, setActiveSection] = useState("Assigned");
  const [marksReturnModal, setMarksReturnModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const current_assignment = assignment;
  const routeType = location?.state?.routeType;
  const isAssignment = window.location.pathname.split("/")[1] === "assignment";
  const isOwner =  location?.state?.isOwner ?? false;
  const showFourZeroFour = !isOwner || !isIdValid 
  const hasDueDatePassed = assignment?.due_date <  moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ") ?? false;

  // console.log("RouteType viewAsgSTATUS", routeType);
  // console.log("dueDatePassed", hasDueDatePassed);

  const toggleMarksReturnModal = () => {
    setMarksReturnModal(!marksReturnModal);
  };

  const changeActiveSect = (selectedSection) => {
    setActiveSection(selectedSection);
    setReturnList([]);
  };

  const assignmentId = window.location.pathname.split("/")[3];

  useEffect(() => {
    if (props.assignmentType == "QUIZ") {
      dispatch(getAllQuizSubmissions(assignmentId)).then(()=>{setIsLoading(false)});

      // if (current_assignment?.id == undefined) {
      //   dispatch(getIndividualQuiz(assignmentId)).then(()=>{setIsLoading(false)});
      // }
      // dispatch(getQuizStudentList(assignmentId)).then(()=>{setIsLoading(false)});
      // dispatch(getAllFilteredQuizSubmissions(assignmentId))
    } 
  }, []);

  const [returnList, setReturnList] = useState([]);

  const addToReturnList = (id, isChecked) => {
    let index = returnList?.indexOf(id);
    if ( (isChecked === false) && (index >= 0) ) {
      let listCopy = [...returnList];
      listCopy.splice(index, 1);
      setReturnList(listCopy);
    } else if (isChecked === true) {
       setReturnList([...returnList, id]);
    }

  };
  console.log("RETURN-LIST:", returnList);

  const returnAssignmentMarksList = () => {
    // console.log("List Returned", returnList);
    let data = {};
    data.submission_list = returnList;
    if (props.assignmentType == "ASSIGNMENT") {
        if(returnList?.length > 0 && hasDueDatePassed && activeSection=='Assigned'){
          dispatch(gradeZero(returnList));

        } else if (returnList?.length > 0 && activeSection=='Submitted') {
          dispatch(returnAssignmentMarks(data));

        } else {
          dispatch(assignStudentToAssignment(returnList, assignmentId));          
        }
      
    } else {
      // dispatch(returnQuizMarks(data));

      if(returnList?.length > 0 && hasDueDatePassed && activeSection=='Assigned'){
        setIsLoading(true);
        dispatch(gradeStudentZeroForQuiz(returnList))
        .then(()=>dispatch(getAllQuizSubmissions(assignmentId)))
        .then(()=>{setIsLoading(false)})
      } else {
        setIsLoading(true);
        dispatch(assignStudentToQuiz(assignment?.id, returnList))
        .then(()=>dispatch(getAllQuizSubmissions(assignmentId)))
        .then(()=>{setIsLoading(false)})
      }
    }
    toggleMarksReturnModal();
  };

  const handleUnassign = (studentId) => {
    // console.log("Unassign this guy", studentId)    
    let unassignList = [studentId]
    // Call unassign action here
    setIsLoading(true);
    dispatch(unassignStudentFromQuiz(unassignList))
    .then(()=>dispatch(getAllQuizSubmissions(assignmentId)))
    .then(()=>{setIsLoading(false)})
  }

  const handleAssign = (studentId) => {
    setIsLoading(true);
    let assignList = [studentId]
    dispatch(assignStudentToQuiz(assignment?.id, assignList))
    .then(()=>dispatch(getAllQuizSubmissions(assignmentId)))
    .then(()=>{setIsLoading(false)})
  }

  // ---- UnAssign ALl Dropdown ----------
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const unassignAllClickHandler = () => {
    setIsLoading(true);
    dispatch(unassignStudentFromQuiz(returnList))
    .then(()=>dispatch(getAllQuizSubmissions(assignmentId)))
    .then(()=>{setIsLoading(false)})
  }

  return (
    <>
      { isLoading ? (
      <div className={styles.loader_style}>
        <CircularProgress sx={{color: "#ED1E24"}} size={50} />
      </div>
    ) : showFourZeroFour ? <ErrorFourZeroFour/> :
        <div className={styles.main_container}>
          {marksReturnModal ? (
            <Modal
              card={
                <MarksReturnModal
                  stdCount={returnList?.length}
                  changeEdit={toggleMarksReturnModal}
                  isQuiz={props.assignmentType == "QUIZ"}
                  returnMarks={returnAssignmentMarksList}
                  returnList={returnList}
                  modalType={hasDueDatePassed && activeSection=='Assigned' ? 'gradeZero' :
                             activeSection=='Submitted' ? 'return' :
                             activeSection=='NotAssigned' ? 'assign' : 
                             null
                            }
                />
              }
            />
          ) : null}
    
          <p
            className={styles.back_btn}
            onClick={() => {
              if (routeType === "group") {
                history.push({
                  pathname: `/group/${assignment?.group?.id}`,
                  state: { activeSection: "Assignments" },
                });
              } else {
                history.push({
                  pathname: `/class/${assignment?.classes?.id}`,
                  state: { activeSection: "Assignments" },
                });
              }
            }}
          >
            <img alt="" src="/Image/assignment/back_arrow.svg" />
            &nbsp; &nbsp;
            {current_assignment?.classes?.name ||
              current_assignment?.group?.name ||
              t("common.back")}
          </p>
    
          <div className={styles.feed_container}>
            <div className={styles.title_container}>
              <IconTitle
                title={current_assignment?.title}
                img="/Image/assignment/assignment-2.svg"
                notClickable={true}
                type="ASSIGNMENT"
              />
    
              <div className={styles.dueDate}>
                <span className={styles.duedate_component}>
                  <DueDate date={current_assignment.due_date} />
                </span>
              </div>
            </div>

            {/* ---- Submissions Container ---- */}
    
            <div className={styles.submissions_container}>
              <div className={styles.choice}>
                <div
                  className={styles.status}
                  onClick={() => changeActiveSect("Assigned")}
                >
                  <span
                    className={styles.count}
                    style={{
                      color: activeSection == "Assigned" ? "#ED1E24" : "black",
                    }}
                  >
                    {/* {current_assignment.assigned ?? 0} */}
                    {
                      student_list?.filter((sub_status) =>
                        sub_status.submission_status?.includes("AS")
                      ).length ?? 0
                    }
                  </span>
                  <span
                    className={styles.options}
                    style={{
                      color: activeSection == "Assigned" ? "#ED1E24" : "#7b7b7b",
                    }}
                  >
                    {t("viewAssignment.assigned")}
                  </span>
                </div>
    
                <div
                  className={styles.status}
                  onClick={() => changeActiveSect("Submitted")}
                >
                  <span
                    className={styles.count}
                    style={{
                      color: activeSection == "Submitted" ? "#ED1E24" : "black",
                    }}
                  >
                    {/* {current_assignment.submitted} */}
                    {
                      student_list?.filter(
                        (sub_status) =>
                          sub_status.submission_status == "DL" ||
                          sub_status.submission_status == "SUB"
                      ).length ?? 0
                    }
                  </span>
                  <span
                    className={styles.options}
                    style={{
                      color: activeSection == "Submitted" ? "#ED1E24" : "#7b7b7b",
                    }}
                  >
                    {t("viewAssignment.submitted")}
                  </span>
                </div>
    
                <div
                  className={styles.status}
                  onClick={() => changeActiveSect("Graded")}
                >
                  <span
                    className={styles.count}
                    style={{
                      color: activeSection == "Graded" ? "#ED1E24" : "black",
                    }}
                  >
                    {/* {current_assignment.graded} */}
                    {
                      student_list?.filter((sub_status) =>
                        sub_status.submission_status?.includes("GRD")
                      ).length ?? 0
                    }
                  </span>
                  <span
                    className={styles.options}
                    style={{
                      color: activeSection == "Graded" ? "#ED1E24" : "#7b7b7b",
                    }}
                  >
                    {t("viewAssignment.graded")}
                  </span>
                </div>

                {/* -------  Not Assigned Section -------- */}
                <div
                  className={styles.status}
                  onClick={() => changeActiveSect("NotAssigned")}
                >
                  <span
                    className={styles.count}
                    style={{
                      color: activeSection == "NotAssigned" ? "#ED1E24" : "black",
                    }}
                  >
                    
                    {
                      unassigned_student_list?.length ?? 0
                    }
                  </span>
                  <span
                    className={styles.options}
                    style={{
                      color: activeSection == "NotAssigned" ? "#ED1E24" : "#7b7b7b",
                    }}
                  >
                    {t("viewAssignment.notAssigned")}
                  </span>
                </div>
              </div>

              <div className={styles.view_ass_btn_span}>
                <Button
                  label={
                    isAssignment
                      ? t("viewAssignment.viewAssignment")
                      : t("viewAssignment.viewQuiz")
                  }
                  clickHandler={() => {
                    // history.goBack();
                    const type = isAssignment ? "assignment" : "quiz";
                    const assignmentId = window.location.pathname.split("/")[3];
                    history.push({
                      pathname: `/${type}/view/${assignmentId}`,
                      state: {routeType: routeType, isOwner: isOwner},
                    });
                  }}
                />
              </div>
              <div className={styles.view_ass_btn_span_mobile}>
                <Button
                  label={t("viewAssignment.view")}
                  clickHandler={() => {
                    history.goBack();
                  }}
                />
              </div>
            </div>

            {/* ------- Bottom Container -------- */}
    
            <div className={styles.bottom_container}>
              <div className={styles.return_btn}>
                {( (returnList?.length > 0 && hasDueDatePassed && activeSection=='Assigned') ||
                   (returnList?.length > 0 && activeSection=='Submitted') ||
                   (returnList?.length > 0 && activeSection=='NotAssigned') 

                 ) ? (
                  <Button
                    label={t(`${activeSection=='NotAssigned' ? "assignment.assign" : (hasDueDatePassed && activeSection=='Assigned') ? "assignment.gradeZero" : "viewAssignment.return"}`)}
                    type="solid"
                    clickHandler={() => {
                      toggleMarksReturnModal();
                    }}
                  />
                ) : (
                  <Button
                  label={t(`${activeSection=='NotAssigned' ? "assignment.assign" : (hasDueDatePassed && activeSection=='Assigned') ? "assignment.gradeZero" : "viewAssignment.return"}`)}
                    type="solid"
                    disable={true}
                  />
                )}
                {/* ------ unassign all drop down ----*/}
                {(returnList?.length > 0 && hasDueDatePassed && activeSection=='Assigned') &&
                  <div className={styles.dropdown}>
                  <img
                    className={styles.unassign_dropdown}
                    src="/Image/assignment/dropdown.svg"
                    alt=""
                    onClick={handleClick}
                  />
    
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      style: {
                        boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
                        fontFamily: "Montserrat"
                      },
                    }}
                  >
                    <MenuItem onClick={unassignAllClickHandler}>
                      {t("assignment.unassign")}
                    </MenuItem>
                  </Menu>
                </div>

                }
              </div>

              {/* ------- Student List Container ------- */}
    
              <div className={styles.student_list_container}>
                <div className={styles.student_list_header}>
                  {/* <input type="checkbox" className={styles.checkbox_round} /> */}
                  {/* {"  "} */}
                  <div className={styles.student_list_heading}>
                    {t("viewAssignment.studentName")}
                  </div>
                  <div className={styles.student_list_date} style={{visibility:`${activeSection=='NotAssigned' ? "hidden" : "visible"}`}}>
                    {t("viewAssignment.submittedOn")}
                  </div>
                  <div className={styles.student_list_rmk} style={{visibility:`${activeSection=='NotAssigned' ? "hidden" : "visible"}`}}>
                    {t("viewAssignment.submissionRemark")}
                  </div>
                  <div className={styles.student_list_grade} style={{visibility:`${activeSection=='NotAssigned' ? "hidden" : "visible"}`}}>
                    {t("viewAssignment.grade")}
                  </div>
                   <div className={styles.assign}>
                    {t("courseMonetisation.Action")}
                   </div> 
                  
                </div>
                
                <div className={styles.inner_list_container}>
                {student_list?.length >= 0 ? (
                  <>
                    {activeSection == "Graded"
                      ? student_list
                          .filter((sub_status) =>
                            sub_status.submission_status?.includes("GRD")
                          )
                          .map((item) => (
                            <StudentAssignmentSubmissionList
                              studentName={item.students.name}
                              profilePic={item.students.profile_pic}
                              submissionDate={
                                item.submission_status != "AS" &&
                                item.submission_date != null &&
                                item.submission_date != undefined
                                  ? formatDate(item.submission_date)
                                  : "_"
                              }
                              submissionRemark={item.submission_status}
                              grade={item.marks}
                              maxMarks={current_assignment.max_marks}
                              submission_id={item.id ? item.id : null}
                              assignment_id={assignmentId}
                              addToReturnList={addToReturnList}
                              assignmentTitle={current_assignment.title}
                              className={
                                current_assignment?.classes?.name ||
                                current_assignment?.group?.name
                              }
                              // filtered_submissions={filtered_submissions}
                              isOwner={isOwner}
                              routeType={routeType}
                              assignmentType={window.location.pathname
                                ?.split("/")[1]
                                ?.toUpperCase()}
                              handleUnassign={handleUnassign}
                              currentQuiz={current_assignment}
                              userAnswers={item.user_answers}
                              userMarks={item.marks}
                              userSubmissionId={item.id}
                              classOrGroupId={current_assignment?.classes?.id || current_assignment?.group?.id}
                            />
                          ))
                      : activeSection == "Submitted"
                      ? student_list
                          .filter(
                            (sub_status) =>
                              sub_status.submission_status == "DL" ||
                              sub_status.submission_status == "SUB"
                          )
                          .map((item) => (
                            <StudentAssignmentSubmissionList
                              studentName={item.students.name}
                              profilePic={item.students.profile_pic}
                              submissionDate={
                                item.submission_status != "AS"
                                  ? formatDate(item.submission_date)
                                  : "_"
                              }
                              submissionRemark={item.submission_status}
                              grade={item.marks}
                              maxMarks={current_assignment.max_marks}
                              submission_id={item.id ? item.id : null}
                              assignment_id={assignmentId}
                              addToReturnList={addToReturnList}
                              assignmentTitle={current_assignment.title}
                              className={
                                current_assignment?.classes?.name ||
                                current_assignment?.group?.name
                              }
                              // filtered_submissions={filtered_submissions}
                              isOwner={isOwner}
                              routeType={routeType}
                              assignmentType={window.location.pathname
                                ?.split("/")[1]
                                ?.toUpperCase()}
                              handleUnassign={handleUnassign}
                              currentQuiz={current_assignment}
                              userAnswers={item.user_answers}
                              userMarks={item.marks}
                              userSubmissionId={item.id}
                              classOrGroupId={current_assignment?.classes?.id || current_assignment?.group?.id}
                            />
                          ))
                      : activeSection == "Assigned" 
                      ? student_list.filter((sub_status) =>
                      sub_status.submission_status?.includes("AS")
                    )
                      .map((item) => (
                          <StudentAssignmentSubmissionList
                            studentName={item.students.name}
                            profilePic={item.students.profile_pic}
                            submissionDate={
                              item.submission_status != "AS"
                                ? formatDate(item.submission_date)
                                : "_"
                            }
                            submissionRemark={item.submission_status}
                            grade={item.marks}
                            maxMarks={current_assignment.max_marks}
                            submission_id={item.id ? item.id : null}
                            assignment_id={assignmentId}
                            addToReturnList={addToReturnList}
                            assignmentTitle={current_assignment.title}
                            className={
                                current_assignment?.classes?.name ||
                                current_assignment?.group?.name
                            }
                            // filtered_submissions={filtered_submissions}
                            isOwner={isOwner}
                            routeType={routeType}
                            assignmentType={window.location.pathname
                              ?.split("/")[1]
                              ?.toUpperCase()}
                            handleUnassign={handleUnassign}
                            currentQuiz={current_assignment}
                            userAnswers={item.user_answers}
                            userMarks={item.marks}
                            userSubmissionId={item.id}
                            classOrGroupId={current_assignment?.classes?.id || current_assignment?.group?.id}
                          />
                        )) :
                        unassigned_student_list?.length > 0 && unassigned_student_list.map((item) => (
                          <StudentAssignmentSubmissionList
                            studentName={item.name}
                            profilePic={item.profile_pic}
                            notAssigned={true}
                            handleAssign={handleAssign}
                            studentId={item.id}
                            isNewMember={true}
                            addToReturnList={addToReturnList}
                            submissionRemark={item.submission_status}
                            assignmentType={props.assignmentType}
                            grade={item.marks}
                            // submissionDate={
                            //   item.submission_status != "AS"
                            //     ? formatDate(item.submission_date)
                            //     : "___"
                            // }
                            // submissionRemark={item.submission_status}
                            // grade={item.marks}
                            // maxMarks={current_assignment.max_marks}
                            // submission_id={item.id ? item.id : null}
                            // assignment_id={assignmentId}
                            // addToReturnList={addToReturnList}
                            // assignmentTitle={current_assignment.title}
                            // className={
                            //   current_assignment.class_name ||
                            //   current_assignment.group_name
                            // }
                            // filtered_submissions={filtered_submissions}
                            // isOwner={isOwner}
                            // routeType={routeType}
                            // assignmentType={window.location.pathname
                            //   ?.split("/")[1]
                            //   ?.toUpperCase()}
                          />
                        ))
                      }
                  </>
                ) : (
                  <div className={styles.no_std_msg}>
                    {routeType=='group'? t("viewAssignment.noStudentsMsgGrp") : t("viewAssignment.noStudentsMsg")}
                  </div>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
    
  );
};

export default ViewQuizStatus;