import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import AttachmentContainer from "../../components/commons/assignmentAndQuiz/AttachmentContainer";
import IconTitle from "../../components/commons/IconTitle";
import styles from "./css/StudentAssignmentDetailsPage.module.css";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import {
  getStudentSubmission,
  studentIndividualAssignment,
  submitAssignmentSolution,
  updateStudentAssignmentSolution,
  clearAssignmentSubmission,
  getIndividualAssignmentSubmissionId
} from "../../redux/actions/assignment";
import DueDate from "../../components/assignmentAndQuiz/DueDate";
import FileTypeDropDown from "../../components/assignmentAndQuiz/FileTypeDropDown";
import { useHistory, withRouter } from "react-router-dom";
import AssignmentHeading from "../../components/assignmentAndQuiz/AssignmentHeading";
import { CircularProgress } from "@mui/material";
import CircularProgressWithLabel from "@mui/material/CircularProgress";
import { handleErrorMessage } from "../../redux/actions/classes";
import IndividualComment from "../../components/assignmentAndQuiz/IndividualComment";
import LinkModal from "./Modals/LinkModal";
import Modal from "../../components/commons/Modal";
import {
  isValidUrl,
  validateFileSize,
} from "../../redux/constants/commonFunctions";
import {
  addPrivateComment,
  getPrivateComment,
  getQuizStudentSubmission,
  studentIndividualQuiz,
  submitQuizSolution,
  updateStudentQuizSolution,
  clearQuizSubmission
} from "../../redux/actions/quiz";
import ReactHtmlParser from 'react-html-parser'; 

class StudentAssignmentDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      submissionFile: null,
      submissionFileUrl: null,
      assignmentId: null,
      resubmitBtn: false,
      isLoading: true,
      submissionLink: null,
      fileDeleted: false,
      privateComment: "",
      isCommentLoading: true,
      linkModal: false,
      linkVal: "",
      linkDeleted: false,
      percent: null,
    };
  }

  componentDidMount() {
    if (this.props.type === "ASSIGNMENT") {
      this.props.getIndividualAssignmentSubmissionId(parseInt(window.location.pathname.split("/").pop()))
      .then(()=> 
      this.props
      .studentIndividualAssignment(
        this.props.history.location.pathname.split("/").pop()
      )
      )
        .then(() =>
          this.setState({
            submissionFile: this.props.submission?.id > 0
              ? this.props.submission?.file_submitted
              : null,
            submissionLink: this.props.submission?.id > 0
              ? this.props.submission?.url
              : null,
            isLoading: false,
          })
        );
      
      

      this.props
        .getPrivateComment(
          "ASSIGNMENT",
          this.props.history.location.pathname.split("/").pop(),
          this.props.profileID
        )
        .then(() =>
          this.setState({
            isCommentLoading: false,
          })
        );
    } else {
      this.props
        .studentIndividualQuiz(
          this.props.history.location.pathname.split("/").pop()
        )
        .then(() => {
          this.setState({
            submissionFile: this.props.submission?.id > 0
              ? this.props.submission?.file_submitted
              : null,
            submissionLink: this.props.submission?.id > 0
              ? this.props.submission?.url
              : null,
            isLoading: false,
          });
        });
      this.props
        .getPrivateComment(
          "QUIZ",
          this.props.history.location.pathname.split("/").pop(),
          this.props.profileID
        )
        .then(() =>
          this.setState({
            isCommentLoading: false,
          })
        );
    }
    this.setState({
      assignmentId: this.props.history.location.pathname.split("/").pop(),
    });
  }

  componentWillUnmount(){
    if (this.props.type === "ASSIGNMENT"){
      this.props.clearAssignmentSubmission();
    } else {
      this.props.clearQuizSubmission();
    }
  }

  handleDropDownMenu = () => {
    this.setState({
      showDropDown: !this.state.showDropDown,
    });
  };

  handleCommentChange = (e) => {
    this.setState({
      privateComment: e.target.value,
    });
  };

  handleCommentSubmission = (e) => {
    this.props
      .addPrivateComment(
        this.props.type,
        this.state.privateComment,
        this.state.assignmentId,
        this.props.submission?.id
      )
      .then(() => {
        this.props.getPrivateComment(
          this.props.type,
          this.state.assignmentId,
          this.props.profileID
          // this.props.submission?.id
        );
      });
    this.setState({
      privateComment: "",
    });
  };

  handleSubmissionFileUpload = (e) => {
    const errorMessage = validateFileSize(e.target.files[0], 25600);
    errorMessage
      ? this.props.handleErrorMessage(errorMessage)
      : this.setState({
        submissionFile: e.target.files[0],
        submissionFileUrl: URL.createObjectURL(e.target.files[0]),
        showDropDown: false,
      });
  };

  removeSubmittedFile = (filetype) => {
    if (filetype === "FILE") {
      if (this.state.submissionFile != null) {
        this.setState({
          submissionFile: null,
          fileDeleted: true,
        });
      }
    } else {
      if (this.state.submissionLink != null) {
        this.setState({
          submissionLink: null,
          linkDeleted: true,
        });
      }
    }
  };

  handleSubmission = () => {
    if (this.props.submission?.id) {
      const data = {
        submissionId: this.props?.submission?.id,
        file: this.state.submissionFile,
        url: this.state.submissionLink,
      };
      if (
        (this.state.submissionFile === null &&
          this.props?.submission?.file_submitted !== null) ||
        (this.state.submissionLink === null &&
          this.props?.submission?.url !== null)
      ) {
        this.props.handleErrorMessage(
          this.props.t("errorMessageTranslations.cantDeleteUploadedFile")
        );
      } else {
        if (this.props.type === "ASSIGNMENT") {
          this.props.updateStudentAssignmentSolution(data).then(() => {
            this.setState({
              resubmitBtn: false,
            });
          });
        } else {
          this.props.updateStudentQuizSolution(data).then(() => {
            this.setState({
              resubmitBtn: false,
            });
          });
        }
      }
    } else {
      const data = {
        assignmentId: this.state.assignmentId,
        file: this.state.submissionFile,
        url: this.state.submissionLink,
        subId: this.props?.assignmentSubmissionId ?? 0,
      };
      if (this.props.type === "ASSIGNMENT") {
        this.props.submitAssignmentSolution(data).then(() => {
          this.setState({
            resubmitBtn: false,
          });
        });
      } else {
        this.props.submitQuizSolution(data).then(() => {
          this.setState({
            resubmitBtn: false,
          });
        });
      }
    }
  };

  handleResubmission = () => {
    this.setState({
      resubmitBtn: true,
      submissionFile: this?.props?.submission?.file_submitted,
      submissionLink: this.props?.submission?.url,
    });
  };

  openLinkModal = () => {
    this.setState({ linkModal: true });
  };
  closeLinkModal = () => {
    this.setState({ linkModal: false });
  };

  changeLink = (e) => {
    this.setState({ linkVal: e.target.value });
  };
  submitLink = () => {
    if (isValidUrl(this.state.linkVal)) {
      this.setState({
        submissionLink: this.state.linkVal,
        linkModal: false,
        showDropDown: false,
        linkVal: "",
      });
    } else {
      this.props.handleErrorMessage(this.props.t("errorMessageTranslations.validUrl"));
      this.setState({
        linkVal: "",
      })
    }
  };

  render() {
    return this.state.isLoading ? (
      <div className={styles.loader_style}>
        <CircularProgress sx={{color: "#ED1E24"}} size={50} />
      </div>
    ) : (
      <>
        <h5
          className={styles.back_btn}
          onClick={() => this.props.history.goBack()}
        >
          <img src="/Image/assignment/back_arrow.svg" />
          &nbsp; &nbsp;
          {this.props?.assignmentInd?.class_name ||
            this.props?.assignmentInd?.group_name ||
            this.props.t("common.back")}
        </h5>
        <div className={styles.main_container}>
          <LinkModal
            show={this.state.linkModal}
            shareModalCloser={this.closeLinkModal}
            linkVal={this.state.linkVal}
            changeLink={this.changeLink}
            submitLink={this.submitLink}
          />
          <div className={styles.title_container}>
            {/* <AssignmentHeading title= {this.props?.assignmentInd?.title} /> */}
            <IconTitle
              title={this.props?.assignmentInd?.title}
              img="/Image/assignment/White/Assignment.svg"
              notClickable={true}
              type="ASSIGNMENT"
            />
            <DueDate date={this.props?.assignmentInd?.due_date} />
          </div>

          <div className={styles.assignment_details_container}>
            {/* ------------- LEFT-CONTAINER --------------- */}

            <div className={styles.assignment_details_left_container}>
              <div className={styles.assignment_details_description}>
                {/* <p>{this.props?.assignmentInd?.details}</p> */}
                <p>{this.props.type === "ASSIGNMENT" ? ReactHtmlParser(this.props?.assignmentInd?.details) : this.props?.assignmentInd?.details}</p>
              </div>
              {this.props?.assignmentInd?.file !== null ? (
                <div className={styles.attachment_container_div}>
                  <AttachmentContainer
                    key="fileContainerOne"
                    assignmentFileType="FILE"
                    fileName={this.props?.assignmentInd?.file?.split("/").pop()}
                    file={this.props?.assignmentInd?.file}
                    pageType=""
                  />
                </div>
              ) : null}
              {this.props?.assignmentInd?.url !== null ? (
                <div className={styles.attachment_container_div}>
                  <AttachmentContainer
                    key="fileContainerTwo"
                    assignmentFileType="LINK"
                    linkName={this.props?.assignmentInd?.url?.split("/").pop()}
                    link={this.props?.assignmentInd?.url}
                    pageType=""
                  />
                </div>
              ) : null}
              <br />
              <br />
              {this.props?.comments?.length > 0 ? (
                <div className={styles.comment_container}>
                  <p className={styles.comment_heading}>
                  {this.props.t("common.comments")}
                  </p>
                  {this.props?.comments?.map((comment) => (
                    <IndividualComment
                      comment={comment?.description}
                      key={comment?.id}
                      senderName={comment?.sender_name}
                      profilePic={comment?.owner_pic}
                      time={comment?.timestamp}
                      isCommentLoading={this.state.isCommentLoading}
                    />
                  ))}
                </div>
              ) : null}

              {/* 
              {this.props?.comments.length > 0 ? (
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#E5E5E5",
                  }}
                />
              ) : null}

              <br />
              {this.props?.comments?.map((comment) => (
                <IndividualComment
                  comment={comment?.description}
                  key={comment?.id}
                  senderName={comment?.send_name}
                  profilePic={comment?.owner_pic}
                />
              ))} */}
            </div>

            {/* ------------- RIGHT-CONTAINER --------------- */}

            <div className={styles.assignment_details_right_container}>
              <div className={styles.work_div}>
                <h6 className={`${styles.yourWorkHead} ${styles.sub_heading}`}>
                  {/* Your work */}
                  {this.props.t("assignment.yourWork")}
                </h6>

                {/* --- FILE attachmentContainer --- */}

                <div className={styles.margin_bottom}>
                  {(this.state.submissionFile !== null &&
                    this.state.submissionFile !== undefined) ||
                    (this.state.submissionFile === null &&
                      this.props?.submission?.file_submitted !== null &&
                      this.props?.submission?.file_submitted !== undefined &&
                      !this.state.fileDeleted) ? (
                    this.state.resubmitBtn ? (
                      <AttachmentContainer
                        key="fileContainerThree"
                        assignmentFileType="FILE"
                        fileName={`${this.state.submissionFile?.name
                          ? this.state.submissionFile?.name?.substr(0, 8)
                          : this.state.submissionFile
                            .split("/")
                            .pop()
                            .substr(0, 8)
                          }`}
                        fileSize={`${this.state.submissionFile?.size
                          ? Math.floor(
                            this.state.submissionFile?.size / 1024
                          ) + " KB"
                          : ""
                          }`}
                        file={
                          this.state?.submissionFileUrl ||
                          this.state.submissionFile
                        }
                        pageType="Submission"
                        closeBtn={true}
                        removeSubmittedFile={this.removeSubmittedFile}
                      />
                    ) : this.props?.submission &&
                      this?.props?.submission !== null ? (
                      <AttachmentContainer
                        key="fileContainerFour"
                        assignmentFileType="FILE"
                        fileName={`${this.props?.submission.file_submitted
                          ?.split("/")
                          .pop()
                          .substr(0, 8)}`}
                        file={this.props?.submission.file_submitted}
                        pageType="Submission"
                      />
                    ) : (
                      <AttachmentContainer
                        key="fileContainerFive"
                        assignmentFileType="FILE"
                        fileName={`${this.state.submissionFile?.name.substr(
                          0,
                          8
                        )}`}
                        fileSize={`${Math.floor(
                          this.state.submissionFile?.size / 1024
                        )} KB`}
                        file={this.state.submissionFileUrl}
                        pageType="Submission"
                        closeBtn={true}
                        removeSubmittedFile={this.removeSubmittedFile}
                      />
                    )
                  ) : null}
                </div>

                {/* --- LINK attachmentContainer --- */}

                <div className={styles.margin_bottom}>
                  {(this.state.submissionLink !== null &&
                    this.state.submissionLink !== undefined) ||
                    (this.state.submissionLink === null &&
                      this.props?.submission?.url !== null &&
                      this.props?.submission?.url !== undefined &&
                      !this.state.linkDeleted) ? (
                    this.state.resubmitBtn ? (
                      <AttachmentContainer
                        key="fileContainerSix"
                        assignmentFileType="LINK"
                        link={this.state.submissionLink}
                        pageType="Submission"
                        closeBtn={true}
                        removeSubmittedFile={this.removeSubmittedFile}
                      />
                    ) : this.props?.submission &&
                      this?.props?.submission !== null ? (
                      <AttachmentContainer
                        key="fileContainerSeven"
                        assignmentFileType="LINK"
                        link={this.props?.submission?.url}
                        pageType="Submission"
                      />
                    ) : (
                      <AttachmentContainer
                        key="fileContainerEight"
                        assignmentFileType="LINK"
                        link={this.state.submissionLink}
                        pageType="Submission"
                        closeBtn={true}
                        removeSubmittedFile={this.removeSubmittedFile}
                      />
                    )
                  ) : null}
                </div>

                {/* -------- Buttons Container ------- */}

                <div className={styles.button_contain_div}>
                  <div className={styles.assignment_submission_buttons}>
                    {!this.state.resubmitBtn ? (
                      (this.state.submissionFile !== null &&
                        this.state.submissionFile !== undefined) ||
                        (this.state.submissionLink !== null &&
                          this.state.submissionLink !== undefined) ||
                        (this.props?.submission &&
                          this?.props?.submission !== null) ? (
                        this.props?.submission &&
                          this?.props?.submission !== null ? (
                          <Button
                            key="btn1"
                            label={this.props.t("assignment.resubmit")}
                            type="solid"
                            fullWidth={true}
                            clickHandler={this.handleResubmission}
                            disable={
                              this.props?.submission?.marks != null
                                ? true
                                : false
                            }
                          />
                        ) : (
                          <Button
                          key="btn2"
                          label={this.props?.isLoading ? <CircularProgress sx={{
                            color: "#ED1E24",
                          }} size={20} /> : this.props.t("assignment.submit")}
                            type="solid"
                            fullWidth={true}
                            clickHandler={this.handleSubmission}
                            disable={this.props?.isLoading ? true : false}
                          />
                        )
                      ) : (
                        <Button
                          key="btn3"
                          label={this.props.t("assignment.addOrCreate")}
                          type="solid"
                          fullWidth={true}
                          clickHandler={this.handleDropDownMenu}
                        />
                      )
                    ) : this.state.submissionFile !== null ||
                      this.state.submissionLink !== null ? (
                      <Button
                      key="btn4"
                      label={this.props?.isLoading ? <CircularProgress sx={{
                        color: "#ED1E24",
                      }} size={20} /> : this.props.t("assignment.submit")}
                        type="solid"
                        fullWidth={true}
                        clickHandler={this.handleSubmission}
                        disable={this.props?.isLoading ? true : false}
                      />
                    ) : (
                      <Button
                        key="btn5"
                        label={this.props.t("assignment.addOrCreate")}
                        type="solid"
                        fullWidth={true}
                        clickHandler={this.handleDropDownMenu}
                      />
                    )}

                    {/* ---- FIle/link Dropdown Menu */}

                    {this.state.showDropDown ? (
                      <div className={styles.drop_down_outer}>
                        <div className={styles.drop_down}>
                          <FileTypeDropDown
                            handleSubmissionFileUpload={
                              this.handleSubmissionFileUpload
                            }
                            openLinkModal={this.openLinkModal}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* {(this.state.submissionFile===null && !this.props?.submission && this.state.submissionLink===null) ? 
                (<div className={styles.assignment_submission_buttons}>
                    <Button label={this.props.t("assignment.markAsDone")} fullWidth={true}/>
                </div>) : null} */}
                  {(this.props?.submission &&
                    this?.props?.submission !== null &&
                    this.state.resubmitBtn) ||
                    !(
                      this.props?.submission && this?.props?.submission !== null
                    ) ? (
                    (this.state.submissionFile === null &&
                      this.state.submissionLink !== null) ||
                      (this.state.submissionLink === null &&
                        this.state.submissionFile !== null) ? (
                      <div className={styles.assignment_submission_buttons}>
                        <Button
                          key="btn6"
                          label={this.props.t("assignment.addOrCreate")}
                          fullWidth={true}
                          clickHandler={this.handleDropDownMenu}
                        />
                      </div>
                    ) : null
                  ) : null}
                </div>
              </div>

              {/* ----------Grade Container --------- */}

              <div className={styles.grade_container}>
                <p>{this.props.t("assignment.grade")}</p>
                <div className={styles.grade_div}>
                  {this.props?.submission?.marks != null
                    ? this.props?.submission?.marks
                    : ""}{" "}
                  / {this.props?.assignmentInd?.max_marks}
                </div>
              </div>

              {/* BELOW CODE COMMENTED, TO BE USED LATER  */}

              {/* x------------x */}

              <div
                className={`${styles.work_div} ${styles.private_comment_div}`}
              >
                <h6 className={styles.sub_heading}>
                  {this.props.t("assignment.addComment")}
                </h6>
                <input
                  type="text"
                  className={styles.private_comment_input}
                  onChange={(e) => this.handleCommentChange(e)}
                  value={this.state.privateComment}
                />
                <div className={styles.assignment_add_button}>
                  <Button
                    label={this.props.t("common.add")}
                    clickHandler={this.handleCommentSubmission}
                  />
                </div>
              </div>

              {/* x------------x */}

              {/* </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  // U can destructure here to get only the required attributes from the state
  return {
    assignmentInd:
      ownProps.type === "ASSIGNMENT"
        ? state?.assignment?.assignmentInd
        : state?.quiz?.quizInd,
    className: state?.classes?.class?.name,
    submission:
      ownProps.type === "ASSIGNMENT"
        ? state?.assignment?.submission
        : state?.quiz?.submission,
    comments:
      ownProps.type === "ASSIGNMENT"
        ? state?.assignment?.comments
        : state?.quiz?.comments,
    progressBarCount:
      ownProps.type === "ASSIGNMENT"
        ? state?.assignment?.progressBarCountAsg
        : state?.quiz?.progressBarCountQuiz,
    isLoading:
      ownProps.type === "ASSIGNMENT"
        ? state?.assignment?.isLoading
        : state?.quiz?.isLoading,
    assignmentSubmissionId: 
      ownProps.type === "ASSIGNMENT" && state?.assignment?.individualAssignmentPreSubmissionData?.id, 
    profileID: state?.profile?.profileId,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    studentIndividualAssignment: (id) =>
      dispatch(studentIndividualAssignment(id)),
    submitAssignmentSolution: (data) =>
      dispatch(submitAssignmentSolution(data)),
    getStudentSubmission: (submissionId) =>
      dispatch(getStudentSubmission(submissionId)),
    updateStudentAssignmentSolution: (data) =>
      dispatch(updateStudentAssignmentSolution(data)),
    handleErrorMessage: (message) => dispatch(handleErrorMessage(message)),
    studentIndividualQuiz: (id) => dispatch(studentIndividualQuiz(id)),
    submitQuizSolution: (data) => dispatch(submitQuizSolution(data)),
    updateStudentQuizSolution: (data) =>
      dispatch(updateStudentQuizSolution(data)),
    // addQuizPrivateComment: (comment, quiz_id, student_id) =>
    //   dispatch(addQuizPrivateComment(comment, quiz_id, student_id)),
    getQuizStudentSubmission: (submissionId) =>
      dispatch(getQuizStudentSubmission(submissionId)),
    clearAssignmentSubmission: () => dispatch(clearAssignmentSubmission()),
    clearQuizSubmission: () => dispatch(clearQuizSubmission()),
    getPrivateComment: (type, asgId, studentId) =>
      dispatch(getPrivateComment(type, asgId, studentId)),
    addPrivateComment: (type, comment, asgId, studentId) =>
      dispatch(addPrivateComment(type, comment, asgId, studentId)),
    getIndividualAssignmentSubmissionId: (asgId) => 
      dispatch(getIndividualAssignmentSubmissionId(asgId)),
  };
}

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(StudentAssignmentDetailsPage))
);
