import React, { useState, useEffect, useRef } from "react";
import styles from "./css/CreateCoursePage.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import IconTitle from "../../components/commons/IconTitle";
import Button from "../../components/assignmentAndQuiz/Button/Button";
import MultipleSelectChip from "../AssignmentAndQuiz/Components/MultipleSelectChip";
import { handleErrorMessage } from "../../redux/actions/classes";
import { validateFileSize } from "../../redux/constants/commonFunctions";
import { CircularProgress } from "@mui/material";
import {
  createCourse,
  clearCourses,
  updateCourse,
} from "../../redux/actions/courses";
import Modal from "../../components/commons/Modal";
import ConfirmAddContentModal from "./Modals/ConfirmAddContentModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TooltipWrapper from "../CourseMonetization/Components/TooltipWrapper";

const CreateCoursePage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const uploadButtonRef = useRef();

  const isEditing = location?.state?.edit;
  const currentCourse = location?.state?.currentCourse;
  const courseType = location?.state?.courseType; // "Public" or "Private"
  const courseTitleType = location?.state?.courseTitleType; // "Create" or "Edit"

  const [thumbnail, setThumbnail] = useState(
    courseTitleType == "Edit" && currentCourse?.thumbnail
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSaveBtn, setIsLoadingSaveBtn] = useState(false);
  const [isLoadingNextBtn, setIsLoadingNextBtn] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [courseName, setCourseName] = useState(
    courseTitleType == "Edit" ? currentCourse?.name : ""
  );
  const [courseSubtitle, setCourseSubtitle] = useState(
    courseTitleType == "Edit" ? currentCourse?.subtitle : ""
  );
  const [courseDescription, setCourseDescription] = useState(
    courseTitleType == "Edit" ? currentCourse?.description : ""
  );

  const [isFree, setIsFree] = useState(
    courseTitleType == "Edit" ? currentCourse?.free : true
  );

  const [coursePriceValue, setCoursePriceValue] = useState(
    courseTitleType == "Edit" ? currentCourse?.price : ""
  );

  const title = `${
    courseTitleType == "Create" ? t("common.create") : t("common.edit")
  } ${courseType == "Public" ? t("common.Public") : t("common.Private")} ${t(
    "common.course"
  )}`;

  // const profileId = useSelector((state) => state?.profile?.profileId) || 0;
  // const classes = useSelector((state) => state.classes.classes).filter(
  //   (item) => item.class_owner == profileId
  // );
  const classes = location?.state?.classes;

  const classTags = props.classTags ?? [];
  const classLoggedIn = useSelector((state) => state.classes.class);
  const currentClass =
    props.courseFeedCourse === true ? [classLoggedIn.name] : [];

  const [selectedClasses, setSelectedClasses] = useState(
    courseTitleType === "Edit"
      ? currentCourse?.classes?.map((item) => item.name)
      : currentClass
  );

  const getSelectedClasses = (value) => {
    setSelectedClasses(value);
  };

  const handleThumbnail = (e) => {
    let errorMessage = validateFileSize(e.target.files[0], 5120);
    if (errorMessage) {
      dispatch(handleErrorMessage(errorMessage));
    } else {
      setThumbnail(e.target.files[0]);
      // setThumbnailUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleNextAndSave = (btnType) => {
    const data = {
      courseType: courseType,
      thumbnail: thumbnail,
      classes: selectedClasses
        ?.map((e) => classes.find((item) => item.name === e))
        ?.map((x) => x?.id),
      courseName: courseName,
      courseSubtitle: courseSubtitle,
      courseDescription: courseDescription,
      isFree: courseTitleType == "Edit" ? currentCourse?.free : isFree,
      priceValue: isFree ? 0 : Number(coursePriceValue),
      active: courseTitleType == "Edit" ? currentCourse?.active : true,
    };
    console.log("inputData", data);

    if (
      data.courseName.trim() == "" ||
      data.courseSubtitle.trim() == "" ||
      data.courseDescription.trim() == "" ||
      (data.courseType === "Private" && data.classes?.length === 0) ||
      (!isFree && coursePriceValue === "")
    ) {
      dispatch(
        handleErrorMessage(t("errorMessageTranslations.requiredFields"))
      );
      setIsLoadingNextBtn(false);
      setIsLoadingSaveBtn(false);
    } else if (!isFree && !validatePrice(data.priceValue)) {
      setIsLoadingNextBtn(false);
      setIsLoadingSaveBtn(false);
    } else if (courseTitleType == "Edit") {
      data.id = currentCourse?.id;

      if (thumbnail) {
        data.thumbnail = thumbnail;
      }

      dispatch(updateCourse(data, currentCourse?.is_draft)).then(() => {
        const cc = JSON.parse(localStorage.getItem("currentCourse"));
        if (btnType == "next" && cc?.id) {
          setIsLoadingNextBtn(false);
          history.push({
            pathname: `/course/content/${cc.id}`,
          });
        } else {
          setIsLoadingNextBtn(false);
          history.push({
            pathname: `/academics`,
            state: { switchSection: "course" },
          });
        }
      });
    } else {
      dispatch(createCourse(data)).then(() => {
        const cc = JSON.parse(localStorage.getItem("currentCourse"));
        if (btnType == "next" && cc?.id) {
          setIsLoadingNextBtn(false);
          history.push({
            pathname: `/course/content/${cc.id}`,
          });
        } else {
          setIsLoadingSaveBtn(false);
          history.push({
            pathname: `/academics`,
            state: { switchSection: "course" },
          });
        }
      });
    }
  };

  const validatePrice = (price) => {
    let errorMessage = null;
    switch (price) {
      case null:
      case undefined:
      case "":
        errorMessage = "errorMessageTranslations.enterValidNumber";
        break;
      default:
        if (!isNaN(price)) {
          if (price < 0) {
            errorMessage = "courseMonetisation.priceCantBeNegative";
          } else if (price === 0) {
            errorMessage = "courseMonetisation.priceCantBeZero";
          }
        } else {
          errorMessage = "errorMessageTranslations.enterValidNumber";
        }
    }

    if (errorMessage) {
      dispatch(handleErrorMessage(t(errorMessage)));
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem("currentCourse");
      dispatch(clearCourses());
    };
  }, []);

  return (
    <div className={styles.main}>
      {showConfirmModal && (
        <Modal
          card={
            <ConfirmAddContentModal
              onYes={() => {
                setIsLoadingNextBtn(true);
                handleNextAndSave("next");
              }}
              onLater={() => {
                setIsLoadingSaveBtn(true);
                handleNextAndSave("save");
              }}
            />
          }
          onOutsideClick={() => setShowConfirmModal(false)}
        />
      )}
      <IconTitle
        title={title}
        img="/Image/CourseMonetisation/booklet-line-2.svg"
        notClickable={true}
        type="ASSIGNMENT"
      />
      {/* add thumbnail upload here */}
      <div className={styles.thumbnail_area}>
        <div className={styles.upload_box}>
          <input
            onChange={(e) => {
              handleThumbnail(e);
            }}
            type="file"
            accept="image/*"
            className={styles.file_upload}
            ref={uploadButtonRef}
          />
          <img
            src="/Image/CreateCourse/upload.svg"
            alt="Photos"
            className={styles.upload_img}
          />
          <p className={styles.upload_txt}>
            {t("createCourse.uploadThumbnail")}
          </p>
          <p className={styles.support_txt}>
            {t("createCourse.supportJpegPngFiles")}
          </p>
        </div>
        {thumbnail && (
          <img
            src={
              typeof thumbnail === "string"
                ? thumbnail
                : URL.createObjectURL(thumbnail)
            }
            className={styles.thumbnail_pic}
          />
        )}

        {thumbnail && (
          <div
            className={styles.change_thumbnail}
            onClick={() => uploadButtonRef.current.click()}
          >
            <img
              src={"/Image/DashImage/camera.svg"}
              className={styles.camera_img}
              alt=""
              title={t("createCourse.changeThumbnail")}
            />
          </div>
        )}
      </div>

      <div className={styles.input_label}>
        <span>{t("createCourse.publishTo")}*</span>
        <TooltipWrapper
          title={`${
            courseType == "Public" ? t("createCourse.publishPublicTooltip") : t("createCourse.publishPrivateTooltip")
          }`}
        >
          <img src="/Image/Icons/Iicon.svg" className={styles.tooltip_icon} />
        </TooltipWrapper>
      </div>

      <MultipleSelectChip
        courseFeedCourse={props.courseFeedCourse}
        courseTitleType={courseTitleType}
        getSelectedClasses={getSelectedClasses}
        notDisabled="true"
        id="publishTo"
        classTags={currentCourse?.classes} // in case of Edit, the already selected classes.
        assignmentClass={classes} // classes is an array of all the classes where the logged-in user is the owner.
      />

      {console.log(
        "id from location",
        location?.state?.courseID,
        location?.state?.edit
      )}

      <span className={styles.input_label}>
        {t("createCourse.courseName")}*
      </span>
      <input
        type="text"
        value={courseName}
        className={styles.input_field}
        placeholder={t("createCourse.courseNamePH")}
        onChange={(e) => setCourseName(e.target.value)}
      />
      <span className={styles.input_label}>
        {t("createCourse.courseSubtitle")}*
      </span>
      <input
        type="text"
        value={courseSubtitle}
        className={styles.input_field}
        placeholder={t("createCourse.courseSubtitlePH")}
        onChange={(e) => setCourseSubtitle(e.target.value)}
      />
      <span className={styles.input_label}>
        {t("courseMonetisation.description")}*
      </span>
      <textarea
        rows="4"
        type="text"
        value={courseDescription}
        className={styles.textbox_field}
        placeholder={t("createCourse.descPH")}
        onChange={(e) => setCourseDescription(e.target.value)}
        defaultValue={props.courseDescription}
      />

      <div className={styles.radio_group}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue={
              courseTitleType == "Edit"
                ? currentCourse?.free
                  ? "free"
                  : "paid"
                : "free"
            }
            onChange={(e) => {
              setIsFree(e.target.value == "free");
            }}
          >
            <FormControlLabel
              sx={{ fontFamily: "Montserrat" }}
              disabled={courseTitleType == "Edit"}
              value={"free"}
              control={
                <Radio
                  size="small"
                  sx={{
                    "&.Mui-checked": { color: "#ED1E24" },
                    marginRight: "0px",
                    fontFamily: "Montserrat",
                    "&.Mui-disabled": {
                      color: "#959595",
                    },
                  }}
                />
              }
              label={t("common.free")}
            />

            <FormControlLabel
              sx={{ fontFamily: "Montserrat" }}
              disabled={courseTitleType == "Edit"}
              value={"paid"}
              control={
                <Radio
                  size="small"
                  sx={{
                    "&.Mui-checked": { color: "#ED1E24" },
                    marginRight: "0px",
                    fontFamily: "Montserrat",
                    "&.Mui-disabled": {
                      color: "#959595",
                    },
                  }}
                />
              }
              label={t("common.paid")}
            />
          </RadioGroup>
        </FormControl>
      </div>

      {!isFree && (
        <div>
          <h3 for="description" className={styles.input_label}>
            {t("createCourse.price")}*
          </h3>
          <div className={styles.price_div}>
            <input
              type="text"
              id="priceType"
              className={styles.price_type_input}
              value={`${t("createCourse.USD")}`}
              disabled
            />
            <input
              type="number"
              id="price"
              onChange={(e) => setCoursePriceValue(e.target.value)}
              className={styles.price_input}
              placeholder={"123"}
              value={coursePriceValue}
              disabled={courseTitleType == "Edit"}
            />
          </div>
        </div>
      )}

      <div className={styles.btn_div}>
        {courseTitleType != "Edit" && (
          <Button
            type="solid"
            // label={t("assignment.save")}
            label={
              isLoadingSaveBtn ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                t("assignment.save")
              )
            }
            disable={isLoadingNextBtn || isLoadingSaveBtn}
            clickHandler={() => {
              // setIsLoadingSaveBtn(true);
              // handleNextAndSave("save");
              setShowConfirmModal(true);
            }}
          />
        )}
        <Button
          type="solid"
          label={
            isLoadingNextBtn ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              t("createCourse.next")
            )
          }
          disable={isLoadingNextBtn || isLoadingSaveBtn}
          clickHandler={
            courseTitleType != "Edit"
              ? () => setShowConfirmModal(true)
              : () => {
                  setIsLoadingNextBtn(true);
                  handleNextAndSave("next");
                }
          }
        />
      </div>
    </div>
  );
};

export default CreateCoursePage;
