import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import { CONNECTIONS_BASE_URL } from "../constants/apis";
// import { formatName } from '../constants/commonFunctions'

export function viewSentConnection(action) {
  return async (dispatch) => {
    const headers = {
      // Authorization: `Token ${token}`
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(`${CONNECTIONS_BASE_URL}sent/`, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          const connections = res.data.data;
          // user.name=formatName(user.name)
          dispatch({
            type: action,
            payload: connections,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error)=> {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
        // console.log(err);
        // return { val: false, message: "Some error occured, try again in seome time" };
      });
  };
}
export function viewConnectionInvitations(action) {
  return async (dispatch) => {
    const headers = {
      // Authorization: `Token ${token}`
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(`${CONNECTIONS_BASE_URL}invitations/`, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          const connections = res.data.data;
          // user.name=formatName(user.name)
          dispatch({
            type: action,
            payload: connections,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error)=> {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
export function acceptConnectionRequest(action, id, data) {
  console.log('inside acceptC', action, id || 3.5, data)
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .put(`${CONNECTIONS_BASE_URL}${id}/accept/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Connected successfully!"))
          dispatch({
            type: action,
            payload: data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error)=> {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
// export function acceptConnectionRequest(action, token, id) {
//     return async dispatch => {
//         const headers = {
//             Authorization: `Token ${token}`
//         }
//         return axios.put(`${CONNECTIONS_BASE_URL}${id}/accept/`, { headers: headers }).then(res => {
//             if (res.status >= 200 && res.status < 300) {
//                 const connections = res.data.data
//                 // user.name=formatName(user.name)
//                 dispatch({
//                     type: action,
//                     payload: connections

//                 })
//                 return { val: true }
//             }
//             return { val: false, message: `Some error occured - ${res.data.message}` }
//         }).catch(err => {
//             console.log(err)
//             return { val: false, message: "Some error occured, try again in seome time" }
//         })

//     }
// }
export function viewMyConnections(action) {
  return async (dispatch) => {
    const headers = {
      // Authorization: `Token ${token}`
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(`${CONNECTIONS_BASE_URL}`, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          const connections = res.data.data;
          // user.name=formatName(user.name)
          dispatch({
            type: action,
            payload: connections,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error)=> {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
export function withdrawConnectionRequest(action, id, data) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .delete(`${CONNECTIONS_BASE_URL}${id}/withdraw/`, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Successfully Withdrawn request"))
          dispatch({
            type: action,
            payload: data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error)=> {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
export function removeConnection(action, id, data) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .delete(`${CONNECTIONS_BASE_URL}${id}/remove/`, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Successfully removed"))
          dispatch({
            type: action,
            payload: data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error)=> {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
export function ignoreConnectionRequest(action, id, data) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .put(`${CONNECTIONS_BASE_URL}${id}/ignore/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Request ignored"))
          dispatch({
            type: action,
            payload: data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error)=> {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
export function sendConnectionRequest(action, id, data) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .post(`${CONNECTIONS_BASE_URL}${id}/request/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Request sent"))
          dispatch({
            type: action,
            payload: data,
          });
          return { val: true };
        }
        // return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error)=> {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
