import React, { useState, useEffect } from 'react'
import styles from './css/CourseFeed.module.css'
// import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
// import Modal from "../../../components/commons/Modal"
import CourseFeedPost from './CourseFeedPost';
import PickPublicPrivateCourseModal from '../Modals/PickPublicPrivateCourseModal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourses, clearCourses } from '../../../redux/actions/courses';
import { thumbnailList } from '../../../redux/constants/commonFunctions';

const CourseFeed = (props) => {
  const {t} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const toggleAddCourseModal = () => {
    setShowModal(!showModal);
  }

  //--- useSelector Section
  const courses = useSelector((state)=>state?.courses?.courseFeed) || [];
  const profileId = useSelector((state)=>state?.profile?.profileId)|| 0;
  const isFaculty = useSelector((state)=>state?.profile?.profileId == state?.classes?.class?.class_owner);
  const hasNextPage = useSelector((state)=>state?.courses?.hasNextPage)
  const classId = props?.classId;
  // const [isLoading, setIsLoading] = useState(true);


  const hasAccess = useSelector((state) => state?.classes?.class?.hasAccess)

  const handleScroll = (e) => {
    console.log("hasNextValue", hasNextPage)
    if ((window.innerHeight + window.scrollY > (document.body.scrollHeight)-20) && (hasNextPage != false)) {
      console.log("callingAction")
      // dispatch(getAllCourses(props?.classId, null, null, null, true));
      dispatch(getAllCourses(props?.classId, null, null, isFaculty, true));
    }
  };  

  // useEffect(() => {
  //   console.log('theEffect')
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   }
  // }, [hasNextPage, ])
  

  return (
    <div className={styles.myCoursesFeedContainer}>
      {showModal && (
        <PickPublicPrivateCourseModal
          handleClose={toggleAddCourseModal}
          courseFeedCourse={true}
        />
      )}

      {
        hasAccess && <div className={styles.addCourseCard} onClick={()=> toggleAddCourseModal()}>
          <div className={styles.innerBorder}>
            <div className={styles.addCourseText}>
              <img
                alt=""
                src="/Image/CourseMonetisation/plus_icon.svg"
                className={styles.plus}
              />
              <span style={{ marginLeft: 10 }}>{t("common.createNewCourse")}</span>
            </div>
          </div>
        </div> 
      }

      {courses?.length > 0 &&
        (courses.map((course, index) =>
          <CourseFeedPost
            key={course?.id}
            id={course?.id}
            coverImage={course?.thumbnail ?? thumbnailList[index % 13]}
            title={course?.name}
            subtitle={course?.subtitle}
            courseDescription={course?.description}
            classes={course?.classes}
            isActive={course?.active}
            isFree={course.free}
            mediaFiles={course?.files}
            urls={course?.urls}
            isInstructor={course?.owner?.id == profileId}
            isPublic={course?.public}
            price={course?.price}
            instructorName={course?.owner?.name}
            enrolledStudentsCount={course?.members_count}
            isEnrolled={course?.is_enrolled}
            lectureCount={course?.total_lecs}
            courseLength={course?.course_mins}
            currentCourse={course}
            isDraft={course?.is_draft}
          />
        ))}
    </div>
  )
}

export default CourseFeed;