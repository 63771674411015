import * as React from "react";
import { Theme, useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import styles from "./MultipleSelectChip.module.css";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //   width: 250,
    },
  },
};

// const classes = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

function getStyles(name, selectedClasses, theme) {
  return {
    fontWeight:
    selectedClasses.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export default function MultipleSelectChip(props) {
  // props - assignmentClass
  const { t } = useTranslation();
  const theme = useTheme();
  const classLoggedIn = useSelector(state => state.classes.class);
  const currentClass = (props.courseFeedCourse === true || props.isAssignmentQuiz === true) ? [classLoggedIn.name] : [];
  const [selectedClasses, setSelectedClasses] = React.useState(props.courseTitleType === t("common.edit") ? props.classTags.map(e => e.name) : currentClass);
  
  // const [selectedClasses, setSelectedClasses] = React.useState(props.courseTitleType === t("common.edit") ? props.classTags.map(e => e.name) : props?.isAssignmentQuiz ? currentClass : currentClass ); 
  
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedClasses(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    )
    props.getSelectedClasses(value);
  };
  const colortheme = createTheme(
    {
      palette: {
        primary: { main: '#ED1E24' },
      },
    },
  );

  return (
    <div>
      <ThemeProvider theme={colortheme}>
      <FormControl className={styles.input_field} sx={{ m: 1, margin: 0 }}>
        <Select
          readOnly={props.notDisabled ? false : true}
          className={styles.select_field}
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={selectedClasses}
          onChange={handleChange}
          sx={props.notDisabled ? null : {backgroundColor: '#f6f6f6'}}
          // sx={{backgroundColor: '#f6f6f6', "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {padding: "16.5px 5px  !important", margin: "0px !important"}}}
          SelectDisplayProps= {{ style: { paddingLeft: 5 } }}
          input={<OutlinedInput id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.length < 2 ? 
                <>
                  {selected.map((value) => (
                <Chip className={styles.class_chip} label={value} key={value}/>
              ))} 
                </>  : 
                <>
                  <Chip className={styles.class_chip} label={selected[0]} key={selected[0]}/>
                  <p className={styles.classTagPara}>+ {selected.length - 1} {t("courseMonetisation.more")}</p>
                </>
              }
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {props?.assignmentClass?.map((e) => (
            <MenuItem
              key={e.name}
              value={e.name}
              style={getStyles(e.name, selectedClasses, theme)}
            > 
            {e.name} 
            </MenuItem>
          ))}
          {/* {props.assignmentClass.length > 0 ? <> {props.assignmentClass.map((e) => (
            <MenuItem
              key={e.name}
              value={e.name}
              style={getStyles(e.name, selectedClasses, theme)}
            > 
            {e.name} 
            </MenuItem>
          ))}</> : <p className={styles.defaultMsg}>No class available to display....</p>} */}
          
          {/* <MenuItem
            key={props.assignmentClass}
            value={props.assignmentClass}
            // style={getStyles(name, selectedClasses, theme)}
          >
            {props.assignmentClass}
          </MenuItem> */}
        </Select>
      </FormControl>

      </ThemeProvider>
    </div>
  );
}
