import React, { useState, useEffect, useRef } from 'react';
import styles from "./css/PublishCourseModal.module.css";
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import UploadCourseFilesModal from "./UploadCourseFilesModal";
import AttachmentContainer from '../../../components/commons/assignmentAndQuiz/AttachmentContainer';
import { useDispatch } from "react-redux";
import { createCourse, editCourse } from '../../../redux/actions/courses';
import { CircularProgress } from "@mui/material";
import ReactHtmlParser from 'react-html-parser'; 

const PublishCourseModal = (props) => {
    const { t } = useTranslation();
    const dispatchReduxAction = useDispatch();

    const routeType = window.location.pathname.split('/')[1];

    useEffect(() => {
        document.body.style.overflow = "hidden"
        return () => {
            document.body.style.overflow = "auto"
        }
    }, [])

    
    const thumbnail = props.thumbnailForDisplay ?? "/Image/defaultCourseProfile.svg";
    const [style, setStyle] = useState(styles.popupModalBox);
    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(!showModal);
    }
    const goBackClicked = useRef(true);
    const [isLoading, setIsLoading] = useState(false);

    const handlePublishOrSave = () => {

        const data = {
          thumbnail : props.thumbnail,
          courseName : props.courseName,
          courseType : props.courseType,
          classes : props?.classIds,
          courseSubtitle : props.courseSubtitle,
          courseDescription : props.courseDescription,
          FreeOrPaid : props.isCourseFree ?? props.isCourseFreeEdit,
          priceType : props.coursePriceType,
          priceValue : props.coursePriceValue,
          mediaFiles : props.filesInfo.uploadFiles,
          urls : props.filesInfo.uploadLinks,
          courseId : props.courseId,
          deleteMediaFiles : props.deletedFiles,
          deleteUrls : props.deletedUrls,
          active: props.isActive
        }
        if( props.courseTitleType === t("common.edit") ) {
          setIsLoading(true)
          dispatchReduxAction(editCourse(data)).then(()=> 
          {      
            setIsLoading(false)
            if(isLoading === false){
                props.handleClose();
            }
          });
        }
        else {
          setIsLoading(true)
          dispatchReduxAction(createCourse(data, routeType)).then(()=>{      
            setIsLoading(false)
            if(isLoading === false){
                props.handleClose();
            }
          });
        }
      }


    return <>
        {showModal && (
            <UploadCourseFilesModal
                handleClose={handleModal}
                isCourseFree={props.isCourseFree === "PAID" ? "PAID" : "FREE"}
                courseType={props.courseType}
                courseName={props.courseName}
                coursePriceType={props.coursePriceType}
                coursePriceValue={props.coursePriceValue}
                courseSubtitle={props.courseSubtitle}
                courseDescription={props.courseDescription}
                classIds={props.classIds}
                classTags={props.classTags}
                thumbnail={props.thumbnail}
                thumbnailForDisplay={thumbnail}
                courseTitleType={props.courseTitleType}
                currentMediaFiles={props.currentMediaFiles}
                currentUrls={props.currentUrls}
                isCourseFreeEdit={props.isCourseFreeEdit}
                filesInfo={props.filesInfo}
                goBackClicked={goBackClicked.current}
                courseId={props.courseId}
                isActive={props?.isActive}
            />
        )}
        <div className={style}>
            <div className={styles.publishCourse}>
                <img src={thumbnail} id={styles.topHeaderImage} />
                <div className={styles.verticalScrollbarContanier}>
                    <div className={styles.publishCourseHeading}>
                        <img src="/Image/Icons/note.svg" alt="topIcon" />
                        <h4>{props.courseName}</h4>
                    </div>
                    <div className={styles.courseType}>
                        <h4>{t("courseMonetisation.courseType")}</h4>
                        <p>{props.courseType}</p>
                    </div>
                    <h4 id={styles.publishingTo}>{t("courseMonetisation.publishingTo")}</h4>
                    <div className={styles.inputTags}>
                        <Stack direction="row" spacing={1}>
                            {props.classTags.map((item) => (
                                <Chip label={item} key={item} variant="outlined" style={{
                                    backgroundColor: "#ED1E24",
                                    border: "none",
                                    color: "#FFFFFF",
                                    fontWeight: "600",
                                    fontFamily: "Montserrat",
                                    fontSize: "12px",
                                }} />
                            ))}
                        </Stack>
                    </div>
                    <div className={styles.course}>
                        <h4>{t("courseMonetisation.courseSubtitle")}</h4>
                        <p>{props.courseSubtitle}</p>
                    </div>
                    <div className={styles.courseDescription}>
                        <h4>{t("courseMonetisation.description")}</h4>
                        <p>{ ReactHtmlParser (props.courseDescription) }</p>
                    </div>
                    { props.courseTitleType === t("common.edit") ? <div className={styles.coursePrice}>
                        <h4>{t("courseMonetisation.price")}</h4>
                        { props.isCourseFreeEdit === "FREE" ? <p>{t("common.free")}</p> : <p>${props.coursePriceValue}</p> }
                    </div> : <div className={styles.coursePrice}>
                        <h4>{t("courseMonetisation.price")}</h4>
                        { props.isCourseFree === "PAID" ? <p>${props.coursePriceValue}</p> : <p>{t("common.free")}</p> }
                    </div> }
                    <div className={styles.courseFiles}>
                        <h4>{t("common.files")}</h4>
                    </div>
                    <div className={styles.courseFile}>
                        {props.filesInfo.uploadFiles.length > 0 &&
                            (props.filesInfo.uploadFiles.map((file) =>
                                <AttachmentContainer
                                assignmentFileType="FILE"
                                fileName={file.name}
                                file={file.toString()}
                                pageType="Post"
                                closeBtn={true}
                                id={file.name}
                                key={file}
                                />
                        ))}
                    </div>
                    {props.courseTitleType === t("common.edit") && <div className={styles.courseFile}>
                        {props.filesInfo.uploadExistingFiles?.length > 0 &&
                            (props.filesInfo.uploadExistingFiles.map((file) =>
                                <AttachmentContainer
                                assignmentFileType="FILE"
                                fileName={file.split('/')[7].split('-')[5]}
                                file={file.toString()}
                                pageType="Post"
                                closeBtn={true}
                                id={file}
                                key={file}
                                />
                        ))}
                    </div>}
                    <div className={styles.courseFile}>
                        {props.filesInfo.uploadLinks?.length > 0 &&
                            (props.filesInfo.uploadLinks.map((link) =>
                                <AttachmentContainer
                                    assignmentFileType="LINK"
                                    linkName={link}
                                    link={link}
                                    pageType="Post"
                                    closeBtn={true}
                                    id={link}
                                    key={link}
                                />
                        ))}
                    </div>
                    {props.courseTitleType === t("common.edit") && <div className={styles.courseFile}>
                        {props.filesInfo.uploadExistingUrls?.length > 0 &&
                            (props.filesInfo.uploadExistingUrls.map((link) =>
                                <AttachmentContainer
                                assignmentFileType="LINK"
                                linkName={link}
                                link={link}
                                pageType="Post"
                                closeBtn={true}
                                id={link}
                                key={link}
                                />
                        ))}
                    </div>}
                    <div className={styles.courseButtons}>
                        {/* <button id={styles.cbtn1} onClick={() => {
                            setStyle(styles.hidePopupModalBox);
                            handleModal();
                        }}>{t("courseMonetisation.goBackBtn")}</button> */} 
                        <button id={styles.cbtn2} onClick={() => {
                            handlePublishOrSave();
                            }}>{ isLoading === true ? ( 
                                    <CircularProgress sx={{color: "#ffffff"}} size={15} />
                            ) : <p>{props.courseTitleType === t("common.edit") ? t("common.save") : t("courseMonetisation.publishBtn")}</p>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default PublishCourseModal;