import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import styles from "../../../../Profile.module.css";

// import SaveButton from "../../../../../../components/commons/SaveButton";
import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
export default class AddFeatured extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: [],
      selectId: [],
    };

    this.expContent = [
      {
        key: 0,
        id: 0,
        img: "/Image/apps/profile-resume-edit-view/dummy/award1.png",
        heading: "Singapore Business Events",
        subheading: "1,073,422 followings",
        desc: "Chrome versions of famous logos by Martin Naumann. Go give Martin a follow on Instagram via username @mnaumanndesign #logos #lego #adidas #nike #nasa #apple #reebok #logos #lego #adidas #nike #nasa #apple",
      },

      {
        key: 1,
        id: 1,
        img: "/Image/apps/profile-resume-edit-view/dummy/slack.png",
        heading: "Singapore Business Events",
        subheading: "1,073,422 followings",
        desc: "Chrome versions of famous logos by Martin Naumann. Go give Martin a follow on Instagram via username @mnaumanndesign #logos #lego #adidas #nike #nasa #apple #reebok #logos #lego #adidas #nike #nasa #apple",
      },

      {
        key: 2,
        id: 2,
        img: "/Image/apps/profile-resume-edit-view/dummy/award1.png",
        heading: "Singapore Business Events",
        subheading: "1,073,422 followings",
        desc: "Chrome versions of famous logos by Martin Naumann. Go give Martin a follow on Instagram via username @mnaumanndesign #logos #lego #adidas #nike #nasa #apple #reebok #logos #lego #adidas #nike #nasa #apple",
      },
    ];

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    this.props.changeEdit(false);
    document.body.style.overflow = "visible";
  }

  ScrollBody() {
    return (
      <div
        style={{
          padding: 20,
          borderBottom: "1px solid #5555551A",
        }}
      >
        {this.expContent.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                overflow: "hidden",
                border: "1px solid #5555555A",
                marginTop: idx == this.expContent.length - 1 || idx != 0 ? 20 : 0,
                cursor: "pointer",
              }}
              onClick={() => {
                if (this.state.selectId.includes(idx)) {
                  let selectids = this.state.selectId;
                  const index = selectids.indexOf(idx);
                  if (index > -1) {
                    selectids.splice(index, 1);
                  }
                  this.setState({ selectId: [...selectids] });
                } else {
                  let selectids = this.state.selectId;
                  this.setState({ selectId: [idx, ...selectids] });
                }
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between", padding: 10, paddingBottom: 0 }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={item.img} height="50px" width="50px" style={{ borderRadius: 40, marginRight: 15 }} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p className={styles.section_title}>
                          {item.heading}
                          <br />
                          <span className={styles.followers}>{item.subheading}</span>
                        </p>
                      </div>
                    </div>
                    <p className={styles.followers} style={{ opacity: 0.7, color: "#555555", marginBottom: 6 }}>
                      Jan 2011 - Nov 2015
                    </p>
                  </div>
                </div>
                {this.state.selectId.includes(idx) ? (
                  <div>
                    <CheckCircleIcon style={{ color: "#236AE8" }} />
                  </div>
                ) : null}
              </div>
              <p className={styles.section_desc} style={{ padding: 10 }}>
                {item.desc}
              </p>
              <img src="/Image/apps/profile-resume-edit-view/AddFeatured.png" style={{ width: "100%", marginBottom: -10 }} />
            </div>
          );
        })}
      </div>
    );
  }

  SaveButtonOnClick() {
    let img = [];
    let newcontent = [];
    this.expContent.forEach((elm) => {
      if (this.state.selectId.includes(elm.id)) {
        let e = {
          img: elm.img,
          title: elm.heading,
          desc: elm.desc,
          islink: false,
        };
        img.push(elm.img);
        newcontent.push(e);
      }
    });

    this.props.changeState(img);
    this.props.changeEdit(false);
    this.props.addFeaturedContent(newcontent);
    document.body.style.overflow = "visible";
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.title} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />
      </div>
    );
  }
}
