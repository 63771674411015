import React, { useEffect, useState } from 'react';
import styles from './css/Academics.module.css';
import MyClasses from '../../ClassesGroups/Classes/MyClasses';
import MyGroups from '../../ClassesGroups/Groups/MyGroups';
import MyCourses from '../../CourseMonetization/Pages/MyCourses';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/assignmentAndQuiz/Button/Button';
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

const Academics = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    // const isFaculty = useSelector((state)=>state?.profile?.user?.user_subtype === "FACULTY");

    const isFaculty = JSON.parse(localStorage.getItem("userData")).user_category === "TEACHER";

    const [academicsObject, setAcademicsObject] = useState({
        classHeadingStyle: styles.academicsHeadingOnClick,
        groupHeadingStyle: styles.academicsHeading,
        courseHeadingStyle: styles.academicsHeading,
        classHideOrShowStyle: styles.academicMyClasses,
        groupHideOrShowStyle: styles.hideAcademicMyGroups,
        courseHideOrShowStyle: styles.hideAcademicMyCourses,
    });

    const changeStyleForClass = () => {
        setClicked(false);
        setAcademicsObject({
            classHeadingStyle: styles.academicsHeadingOnClick,
            groupHeadingStyle: styles.academicsHeading,
            courseHeadingStyle: styles.academicsHeading,
            classHideOrShowStyle: styles.academicMyClasses,
            groupHideOrShowStyle: styles.hideAcademicMyGroups,
            courseHideOrShowStyle: styles.hideAcademicMyCourses,
        })
    }

    const changeStyleForGroup = () => {
        setClicked(false);
        setAcademicsObject({
            classHeadingStyle: styles.academicsHeading,
            groupHeadingStyle: styles.academicsHeadingOnClick,
            courseHeadingStyle: styles.academicsHeading,
            classHideOrShowStyle: styles.hideAcademicMyClasses,
            groupHideOrShowStyle: styles.academicMyGroups,
            courseHideOrShowStyle: styles.hideAcademicMyCourses,
        })
    }

    const [clicked, setClicked] = useState(false);
    const changeStyleForCourse = () => {
        setClicked(true);
        setAcademicsObject({
            classHeadingStyle: styles.academicsHeading,
            groupHeadingStyle: styles.academicsHeading,
            courseHeadingStyle: styles.academicsHeadingOnClick,
            classHideOrShowStyle: styles.hideAcademicMyClasses,
            groupHideOrShowStyle: styles.hideAcademicMyGroups,
            courseHideOrShowStyle: styles.academicMyCourses,
        })
    }

    useEffect(()=>{
        if (location?.state?.switchSection == 'course'){
            changeStyleForCourse();
        } else if (location?.state?.switchSection == 'class'){
            changeStyleForClass();
        } else if (location?.state?.switchSection == 'group'){
            changeStyleForGroup();
        }
    }, [location?.state?.switchSection])
    // location.state.switchSection expects - 'course', 'class' or 'group'


    return (
        <div>
            <div className={styles.headWrapper}>
                <div className={styles.academicsContainer}>
                    <span className={academicsObject.classHeadingStyle} onClick={changeStyleForClass}>{t("common.myClasses")}</span>
                    <span className={academicsObject.groupHeadingStyle} onClick={changeStyleForGroup}>{t("common.myGroups")}</span>
                    <span className={academicsObject.courseHeadingStyle} onClick={changeStyleForCourse}>{t("common.myCourses")}</span>
                </div>
                { (clicked === true && isFaculty === false) && 
                <div className={styles.acBtn} onClick={() => history.push("/courses")}><Button type="solid" label={t("courseMonetisation.exploreBtn")}/></div> 
                }
            </div>
            <div className={academicsObject.classHideOrShowStyle}><MyClasses /></div>
            <div className={academicsObject.groupHideOrShowStyle}><MyGroups /></div>
            <div className={academicsObject.courseHideOrShowStyle}><MyCourses /></div>
        </div>

    )
}

export default Academics;