import React, { Component } from "react";
import styles from "./CSSFiles/suggestionCard.module.css";
import { useTranslation } from 'react-i18next';

// import Image1 from './Image/DashboardImages/DashCommonImages/suggestions1.png';
// import Pic from './Image/DashboardImages/DashCommonImages/sampleProfilePic1.png'

function SuggestionCard({ Background, Pic, Topic, Tag, Following }) {

  const {t} = useTranslation()
  return (
    <div className={styles.container}>
      <img src={Background} width="100%" style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }} />
      <div className={styles.innerContainer}>
        <img src={Pic} className={styles.pic} />
        <div className={styles.contentcontainer}>
          {Topic}
          <div className={styles.following}>{Following} {t("common.following")}</div>
          <a href={Tag} target="_blank" style={{ textDecoration: "none", color: "inherit" }}>
            <div className={styles.button}>{t("common.knowMore")}</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default SuggestionCard;
