import React from "react";
import styles from "../../apps/Profile/Profile.module.css";

export default class IconTitle extends React.Component {
  render() {
    return (
      <div key={this.props.idx || null}>
        {
          this.props?.notClickable ? 
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              // padding: "0px 20px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={this.props.type=='ASSIGNMENT'? styles.icon_container_assignment : styles.icon_container}>
                {/* img prop */}
                <img className={this.props.type=='ASSIGNMENT'? styles.inner_icon_assignment : styles.inner_icon} src={this.props.img} alt=" "  />
              </div>
              <div>
                {/* title prop */}
                <p className={this.props.heading ? styles.icon_text_large : styles.icon_text} style={this.props.upcomingItem ? {fontWeight:500} : null}>{this.props.title}</p>
              </div>
            </div>
          </div> 
        :
        <a
          //title prop
          href={`#${this.props.title}`}
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: "#000",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0px 15px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={styles.icon_container}>
                {/* img prop */}
                <img className={styles.inner_icon} src={this.props.img} alt=" " />
              </div>
              <div>
                {/* title prop */}
                <p className={styles.icon_text}>{this.props.title}</p>
              </div>
            </div>
          </div>
        </a> 
        }
        
      </div>
    );
  }
}
