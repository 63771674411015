import {
  GET_FACULTYDASHBOARD_FACULTY,
  GET_FACULTYDASHBOARD_CLASS_STUDENT,
  GET_FACULTYDASHBOARD_CLASS_ASSIGNMENT,
  GET_FACULTYDASHBOARD_CLASS_QUIZ,
  GET_FACULTYDASHBOARD_CLASS_POST,
  GET_FACULTYDASHBOARD_CLASS_COURSE,
  GET_FACULTYDASHBOARD_GROUP_ASSIGNMENT,
  GET_FACULTYDASHBOARD_GROUP_POST,
  GET_FACULTYDASHBOARD_DATA_COUNT,
  GET_FACULTYDASHBOARD_CHART_DATA,
  GET_FACULTYDASHBOARD_SEARCH_RESULT,
  GET_FACULTYDASHBOARD_CLASS_LIST,
  GET_FACULTYDASHBOARD_GROUP_LIST,
  GET_FACULTYDASHBOARD_GROUP_MEMBER,
  CLEAR_FACULTYDASHBOARD,
} from "../constants/actionTypes";

import cloneDeep from "lodash.clonedeep";

const defaultState = {
  count: 0,
  // dashboard_faculty: [],
  // dashboard_class: [],
  // dashboard_group: [],
  dashboard_posts: [],
  
  dashboard_class_post: [],
  dashboard_class_assignment: [],
  dashboard_class_quiz: [],
  dashboard_class_course: [],
  dashboard_class_student: [],

  dashboard_group_post: [],
  dashboard_group_assignment: [],
  dashboard_group_quiz: [],
  dashboard_group_member: [],
  
  dashboard_chart_data: {},
  dashboard_search_result: [],

  dashboard_class_list: [],
  dashboard_group_list: []
  
};

let data = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    // case GET_FACULTYDASHBOARD_FACULTY:
    //   data = { ...state };
    //   data.dashboard_faculty = action.payload;
    //   return data;

    case GET_FACULTYDASHBOARD_CLASS_STUDENT:
      data = { ...state };
      data.dashboard_class_student = action.payload;
      return data;

    case GET_FACULTYDASHBOARD_GROUP_MEMBER:
      data = { ...state };
      data.dashboard_group_member = action.payload;
      return data;


    // case GET_FACULTYDASHBOARD_CLASS:
    //   data = { ...state };
    //   data.dashboard_class = action.payload;
    //   return data;

    // case GET_FACULTYDASHBOARD_GROUP:
    //   data = { ...state };
    //   data.dashboard_group = action.payload;
    //   return data;

    case GET_FACULTYDASHBOARD_CLASS_ASSIGNMENT:
      data = { ...state };
      data.dashboard_class_assignment = action.payload;
      return data;

    case GET_FACULTYDASHBOARD_CLASS_QUIZ:
      data = { ...state };
      data.dashboard_class_quiz = action.payload;
      return data;

    case GET_FACULTYDASHBOARD_CLASS_POST:
      data = { ...state };
      data.dashboard_class_post = action.payload;
      return data;

    case GET_FACULTYDASHBOARD_CLASS_COURSE:
      data = { ...state };
      data.dashboard_class_course = action.payload;
      return data;

    // case GET_FACULTYDASHBOARD_GROUP_ASSIGNMENT:
    //   data = { ...state };
    //   data.dashboard_group_assignment = action.payload;
    //   return data;

    // case GET_FACULTYDASHBOARD_GROUP_QUIZ:
    //   data = { ...state };
    //   data.dashboard_group_quiz = action.payload;
    //   return data;

    case GET_FACULTYDASHBOARD_GROUP_POST:
      data = { ...state };
      data.dashboard_group_post = action.payload;
      return data;

    case GET_FACULTYDASHBOARD_DATA_COUNT:
      data = {...state};
      data.count = action.payload;
      return data; 
    
    case GET_FACULTYDASHBOARD_CHART_DATA:
      data = {...state};
      data.dashboard_chart_data = action.payload;
      return data; 

    case GET_FACULTYDASHBOARD_SEARCH_RESULT:
      data = { ...state };
      data.dashboard_search_result = action.payload;
      return data;

    case GET_FACULTYDASHBOARD_CLASS_LIST:
      data = { ...state };
      data.dashboard_class_list = action.payload;
      return data;

    case GET_FACULTYDASHBOARD_GROUP_LIST:
      data = { ...state };
      data.dashboard_group_list = action.payload;
      return data;

    case CLEAR_FACULTYDASHBOARD:
      return {...defaultState};

    default:
      return { ...state };
  }
};
