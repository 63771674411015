import React from "react";

import styles from "../../../Profile.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class AchievementsFeed extends React.Component {
  state = {
    seeall: false,

    endIdx: 2,
  };

  render() {
    const {t} = this.props;
    return (
      <div>
        <div className={styles.profile_title}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconTitle img="/Image/apps/profile-resume-edit-view/experience.svg" title={t("profile.achievements")} />
          </div>
        </div>

        <br />
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              // padding: this.state.expContent.length <= 2 ? 0 : 20,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              // borderBottom: this.state.expContent.length <= 2 ? 'none' : '1px solid #5555551A'
            }}
          >
            {this.props.achContent.length == 0 ? (
              <div>{t("profile.NoInformationExistsForAchievements")}</div>
            ) : (
              this.props.achContent.slice(0, this.state.endIdx).map((item, idx) => {
                return (
                  <div
                    style={{
                      borderBottom: this.props.achContent.length == 1 || (this.props.achContent.length == 2 && idx == 1) ? "none" : "1px solid #5555551A",
                      paddingBottom: 15,
                      marginTop: idx == 0 ? 0 : 20,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <img src={item.upload_img} height="50px" width="50px" style={{ borderRadius: 40, marginRight: 15 }} />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p className={styles.section_title}>
                              {item.name}
                              <br />
                              <span className={styles.followers}>{item.issuer}</span>
                            </p>
                          </div>
                        </div>
                        <p className={styles.followers} style={{ opacity: 0.7, color: "#555555", marginBottom: 6 }}>
                          {item.issue_date}
                        </p>
                        <p className={styles.section_desc}>{item.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {this.props.achContent.length <= 2 ? null : (
            <div
              style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10 }}
              onClick={() => {
                if (this.state.endIdx > 2) {
                  this.setState({ endIdx: 2 });
                } else {
                  this.setState({ endIdx: this.state.expContent.length });
                }

                if (this.state.seeall) {
                  this.setState({ seeall: false });
                } else {
                  this.setState({ seeall: true });
                }
              }}
            >
              <btn style={{ fontSize: 12, fontWeight: 600, color: "#555555", margin: 10 }}>
                {this.props.achContent.length <= 2 ? null : this.state.seeall ? t("common.collapse") : t("common.seeAll")}
              </btn>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    achContent: state.profile?.achievementView || [],
  };
}

export default withTranslation()(connect(mapStateToProps, null)(AchievementsFeed));
