import React from "react";

import styles from "../../../Profile.module.css";

import SaveButton from "../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../components/commons/ScrollDivBody";

import { connect } from "react-redux";
import { updateProfileData } from "../../../../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../components/commons/HandleApiError";
import { withTranslation } from "react-i18next";
import { Country, }  from 'country-state-city';
import SelectCountryDropdown from "./SelectCountryDropdown";
import { handleErrorMessage } from "../../../../../redux/actions/classes";

class EditProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      username: this.props.username,
      // designation: this.props.designation,
      // location: this.props.location,
      phone: this.props.phone,
      email: this.props.email,
      // dob: this.props.dob,
      // address: this.props.address

      // email: this.props.email
      name: this.props.name,
      address: this.props.address,
      designation: this.props.designation,
      dob: this.props.dob,
      location: this.props.location,
      organisation: this.props.organisation,
      country: this.props.country,
      country_code: this.props.country_code,

      error: false,
      message: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  ModalHeaderOnClick() {
    this.props.changeEdit();
    // this.props.changeStates(this.props.name,this.props.username, this.props.designation, this.props.location, false, this.props.phone, this.props.email, this.props.address, this.props.dob)
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    // this.props.changeStates(this.state.name,this.state.username, this.state.designation, this.state.location, false, this.state.phone, this.state.email, this.state.address, this.state.dob)
    // document.body.style.overflow = 'visible'

    // const profileState = {
    //     name: this.state.name,
    //     address: this.state.address,
    //     designation: this.state.designation,
    //     dob: this.state.dob,
    //     location: this.state.location,
    //     organisation: this.state.organisation

    // }

    if(this.state.dob === "") {
      this.props.handleErrorMessage(
        this.props.t("errors.enterDOB")
      );
      return null;
    }

    let data = new FormData();
    data.append("name", this.state.name);
    data.append("address", this.state.address);
    data.append("designation", this.state.designation);
    data.append("dob", this.state.dob);
    data.append("location", this.state.location);
    data.append("organisation", this.state.organisation);
    data.append("country_code", this.state.country_code);
    data.append("country", this.state.country);

    this.props.updateProfileData(EDIT_PROFILE_DATA, data).then((res) => {
      //
      if (res.val) {
        this.props.changeEdit();
        document.body.style.overflow = "visible";
        return;
      }
      this.setState({ error: true, message: res.message });
    });
  }

  ScrollBody() {
    const {t} = this.props
    return (
      <div>
        <div
          style={{
            paddingBottom: 20,
          }}
        >
          {/* <div
                        style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}
                    >
                        <p className={styles.modal_heading} style={{ padding: '10px 15px' }}>Email</p>
                        <input className={styles.modal_para} type="text" placeholder="Ex : hello@example.com"
                            style={{
                                border: '1px solid #5555555A',
                                padding: 10,
                                fontSize: 15,
                                borderRadius: 50
                            }}
                            // value={this.state.email}
                            value={this.props?.user?.email
                                ?this.props.user.email
                                : "Other"}
                            onChange={(event) => {
                                this.setState({ email: event.target.value })
                            }}
                        />
                        <br />
                    </div> */}
          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading} style={{ padding: "10px 15px" }}>
              {t("common.name")}
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExJohnDoe")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              // value={this.state.username}
              // value={this.props?.user?.first_name && this.props?.user?.last_name
              //     ? this.props.user.first_name + " " + this.props.user.last_name
              //     : ""}
              value={this?.state?.name ?? ""}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
            <br />
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading} style={{ padding: "10px 15px" }}>
            {t("common.location")}
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExIndia")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this?.state?.location ?? "India"}
              onChange={(event) => {
                this.setState({ location: event.target.value });
              }}
            />
            <br />
          </div>

          {/* <div
                        style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}
                    >
                        <p className={styles.modal_heading} style={{ padding: '10px 15px' }}>Phone</p>
                        <input className={styles.modal_para} type="text" placeholder="Ex : +91-123456789"
                            style={{
                                border: '1px solid #5555555A',
                                padding: 10,
                                fontSize: 15,
                                borderRadius: 50
                            }}
                            value={this.state.phone}
                            onChange={e => {
                                this.setState({ phone: e.target.value.replace(/[^\d]/g, '').slice(0, 10) })
                            }}
                        />
                        <br />
                    </div> */}

          {/* <div
                        style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}
                    >
                        <p className={styles.modal_heading} style={{ padding: '10px 15px' }}>Email</p>
                        <input className={styles.modal_para} type="text" placeholder="Ex : hello@example.com"
                            style={{
                                border: '1px solid #5555555A',
                                padding: 10,
                                fontSize: 15,
                                borderRadius: 50
                            }}
                            value={this.state.email}
                            onChange={(event) => {
                                this.setState({ email: event.target.value })
                            }}
                        />
                        <br />
                    </div> */}

          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading} style={{ padding: "10px 15px" }}>
            {t("common.dob")}
            </p>
            <input
              className={styles.modal_para}
              type="date"
              placeholder={t("profile.modalPlaceholder.EnterYourDOB")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.dob}
              onChange={(event) => {
                this.setState({ dob: event.target.value });
              }}
            />
            <br />
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading} style={{ padding: "10px 15px" }}>
            {t("common.address")}
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExAddress")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              // value={this.state.address}
              // value={this.props?.user?.profile_id?.address
              //     ?this.props.user.profile_id.address
              //     : "Address"}
              value={this?.state?.address ?? "Address"}
              onChange={(event) => {
                this.setState({ address: event.target.value });
              }}
            />
            <br />
          </div>

          <div  style={{ display: "flex", flexDirection: "row", marginBottom: 15, gap: '1rem', alignItems: 'center' }}>
          <p className={styles.modal_heading} style={{ padding: "10px 15px" }}>
            {t("openToWork.selectCountry")}:
            </p>
          <SelectCountryDropdown categoryName={t("openToWork.selectCountry")} getCountry={(code)=> {
            let countryInfo = Country.getCountryByCode(code);
            this.setState({ country_code: code, country: countryInfo?.name });
          }} />
          </div>


          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading} style={{ padding: "10px 15px" }}>
            {t("common.designation")}
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExPhysicsTeacher")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              // value={this.props?.user?.profile_id?.designation
              //     ?this.props.user.profile_id.designation
              //     : "Other"}
              value={this?.state?.designation ?? "designation"}
              onChange={(event) => {
                this.setState({ designation: event.target.value });
              }}
            />
            <br />
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading} style={{ padding: "10px 15px" }}>
            {t("common.organisation")}
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExOrganisation")}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              // value={this.props?.user?.profile_id?.designation
              //     ?this.props.user.profile_id.designation
              //     : "Other"}
              value={this?.state?.organisation ?? "organisation"}
              onChange={(event) => {
                this.setState({ organisation: event.target.value });
              }}
            />
            <br />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.title} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    name: state?.profile?.name || "",
    designation: state?.profile?.designation || "",
    address: state?.profile?.address || "",
    dob: state?.profile?.dob || "",
    location: state?.profile?.location || "",
    organisation: state?.profile?.organisation || "",
  };
};
function mapDispatchToProps(dispatch) {
  return {
    updateProfileData: (action, data) => dispatch(updateProfileData(action, data)),
    handleErrorMessage: (text) => dispatch(handleErrorMessage(text)),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditProfile));
