import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";

class AboutFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      text: "No information found for about.",
    };

    this.changeText = this.changeText.bind(this);
  }

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  changeText(text) {
    this.setState({ text: text });
  }

  render() {
    const {t} = this.props;
    return (
      <div className={styles.about_div}>
        <IconTitle img="/Image/apps/profile-resume-edit-view/about.svg" title={t("profile.about")} />
        <br />

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <p className={styles.section_desc}>{this.props.about || t("profile.noInformationFoundForAbout")}</p>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    tagContent: state?.profile?.tags || [],
    about: state?.profile?.aboutView,
  };
}

export default withTranslation()(connect(mapStateToProps, null)(AboutFeed));
