import React from "react";
import styles from "../../apps/Profile/Profile.module.css";

export default class ModalHeader extends React.Component {
  render() {
    return (
      <div id={styles.header} className={`${styles.modal_header}`}>
        <div className={styles.center_div}>
          <img src="/Image/apps/profile-resume-edit-view/add.svg" height={30} />
          <p className={styles.modal_title}>{this.props.title}</p>
        </div>
        <div className={`${styles.center_div} ${styles.pointer_div}`} onClick={this.props.onClick}>
          <img src="/Image/apps/profile-resume-edit-view/cross.svg" height={30} />
        </div>
      </div>
    );
  }
}
