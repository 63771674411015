import React from "react";
import styles from "../../../../Profile.module.css";
import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import { connect } from "react-redux";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { UPDATE_EDUCATION, ADD_EDUCATION } from "../../../../../../redux/constants/actionTypes";
import { updateEducation, addEducation } from "../../../../../../redux/actions/education";
import { withTranslation } from "react-i18next";
class EditEducation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: this.props.modalTitle,
      id: this.props.id,
      upload_img: this.props.upload_img,
      school: this.props.school,
      description: this.props.description,
      start_date: this.props.start_date,
      end_date: this.props.end_date,
      participations: this.props.participations,
      field_of_study: this.props.field_of_study,
      degree: this.props.degree,
      grade: this.props.grade,
      pictureAsFile: this.props.upload_img,
      error: false,
      message: "",

      uploadImageFlag: false,
    };
    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }
  componentDidMount() {
    if (this.state.upload_img == "") {
      fetch("/Image/apps/profile-resume-edit-view/default-education.png")
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "ed.png", blob);
          this.setState({ pictureAsFile: file, upload_img: "/Image/apps/profile-resume-edit-view/default-education.png" });
        });
    }
  }
  handleImageChange = (event) => {
    this.setState({
      upload_img: URL.createObjectURL(event.target.files[0]),
      pictureAsFile: event.target.files[0],
      uploadImageFlag: true,
    });
  };
  ModalHeaderOnClick() {
    this.props.changeEdit();
    document.body.style.overflow = "visible";
  }
  SaveButtonOnClick() {
    //
    const edState = {
      id: this.state.id,
      upload_img: this.state.upload_img,
      school: this.state.school,
      description: this.state.description,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      participations: this.state.participations,
      field_of_study: this.state.field_of_study,
      degree: this.state.degree,
      grade: this.state.grade,
      pictureAsFile: this.state.pictureAsFile,
      uploadImageFlag: this.state.uploadImageFlag,
    };
    this.props.changeEdit();
    if (this.props.add) {
      this.props.addEducation(edState, ADD_EDUCATION).then((res) => {
        if (res.val) {
          
          document.body.style.overflow = "visible";
          return;
        }
        this.setState({ error: true, message: res.message });
      });
    } else {
      this.props.updateEducation(edState, UPDATE_EDUCATION).then((res) => {
        if (res.val) {
          document.body.style.overflow = "visible";
        }
        this.setState({ error: true, message: res.message });
      });
    }
  }
  ScrollBody() {
    const {t} = this.props;
    return (
      <div>
        <div
          style={{
            // borderBottom: '1px solid #5555555A',
            paddingBottom: 20,
          }}
        >
          <div className={styles.imageUpload}>
            <p className={styles.modal_heading}>{t("profile.media")}*</p>
            <div style={{ display: "flex", borderRadius: "5px" }}>
              <img src={this.state.upload_img} style={{ width: "96px", height: "96px", borderRadius: "10px", objectFit: "cover" }} />
              <div style={{ marginLeft: "20px" }}>
                <p className={styles.sub_heading}>{t("profile.addImageOfYourCompany")}</p>
                <label style={{ marginLeft: 30 }} >
                  <div style={{  border: "2px solid #ED1E24", padding: "12px 60px", borderRadius: 40, cursor: "pointer" }} >
                    <p style={{ margin: 0, fontWeight: 600, textTransform: "uppercase", textAlign: "center", color: "#ED1E24" }} >
                    {t("common.upload")}
                    </p>
                  </div>
                  <input type="file" onChange={this.handleImageChange} style={{ cursor: "pointer", display: "none" }} accept="image/png, image/jpeg, image/svg" />
                </label>
              </div>
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/school.svg"/>{t("profile.school")}*</div></p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExShardaUniversity")}
              maxlength="50"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.school}
              onChange={(event) => {
                this.setState({ school: event.target.value });
              }}
            />
            <br />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/degree.svg"/>{t("profile.degree")}*</div></p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExBachelors")}
              maxlength="50"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.degree}
              onChange={(event) => {
                this.setState({ degree: event.target.value });
              }}
            />
            <br />
          </div>
          {/* <p style={{ textAlign: 'right', fontSize: 12, color: '#555555', fontWeight: 500, marginRight: 10 }}>Learn More</p> */}
        </div>

        <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
          <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/subject.svg"/>{t("profile.fieldOfStudy")}*</div></p>
          <input
            className={styles.modal_para}
            type="text"
            placeholder={t("profile.modalPlaceholder.ExComputerScience")}
            maxlength="50"
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 50,
            }}
            value={this.state.field_of_study}
            onChange={(event) => {
              this.setState({ field_of_study: event.target.value });
            }}
          />
          <br />
        </div>
        <div className={styles.modal_date}>
          <div>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/date.svg"/>{t("profile.StartDate")}*</div></p>
            <input
              className={styles.modal_para}
              type="date"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.start_date}
              onChange={(event) => {
                this.setState({ start_date: event.target.value });
              }}
            />
            <br />
          </div>
          <div>
            <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/date.svg"/>{t("profile.EndDate")}*</div></p>
            <input
              className={styles.modal_para}
              type="date"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.end_date}
              onChange={(event) => {
                this.setState({ end_date: event.target.value });
              }}
            />
            <br />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}>
          <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/grade.svg"/>{t("profile.grade")}*</div></p>
          <input
            className={styles.modal_para}
            type="text"
            placeholder= {t("profile.modalPlaceholder.ExA")}
            maxlength="1"
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 50,
            }}
            value={this.state.grade}
            onChange={(event) => {
              this.setState({ grade: event.target.value });
            }}
          />
          <br />
        </div>
        <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/description.svg"/>{t("profile.activitiesAndSocieties")}</div></p>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <textarea
            className={styles.modal_para}
            id={styles.textarea}
            cols={60}
            rows={8}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 10,
              marginBottom: 15,
            }}
            value={this.state.participations}
            onChange={(event) => {
              this.setState({ participations: event.target.value });
            }}
          />
        </div>
        <p className={styles.modal_heading}><div style={{display: "flex", alignItems: "center"}}><img style={{marginRight: "10px"}} src="/Image/modalIcon/description.svg"/>{t("common.description")}</div></p>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <textarea
            className={styles.modal_para}
            id={styles.textarea}
            cols={60}
            rows={8}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 10,
            }}
            value={this.state.description}
            onChange={(event) => {
              this.setState({ description: event.target.value });
            }}
          />
        </div>
      </div>
    );
  }
  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.modalTitle} onClick={this.ModalHeaderOnClick} />
        <ScrollDivBody body={this.ScrollBody} />
        <SaveButton onClick={this.SaveButtonOnClick} />
        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    updateEducation: (edState, action) => dispatch(updateEducation(edState, action)),
    addEducation: (edState, action) => dispatch(addEducation(edState, action)),
  };
} 
export default withTranslation()(connect(null, mapDispatchToProps)(EditEducation));
