import React from "react";
import Modal from "../../../../../components/commons/Modal";
import EditEducation from "./Modals/EditEducation";

import styles from "../../../Profile.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class EducationFeed extends React.Component {
  state = {
    edit: false,

    modalTitle: "",
    id: null,
    upload_img: "",
    school: "",
    description: "",
    start_date: null,
    end_date: null,
    participations: "",
    field_of_study: "",
    degree: "",
    grade: "",

    seeall: false,
    add: false,

    endIdx: 2,

    edContent: [],
  };

  changeEdit() {
    this.setState({ edit: false });
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <IconTitle title={t("profile.education")} img="/Image/apps/profile-resume-edit-view/experience.svg" />
        <br />
        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          {this.props.editpage ? (
            <div
              style={{
                // borderBottom: '1px solid #5555551A',
                padding: 10,
              }}
            >
              <p className={styles.edit_info}>{t("profile.addYourEducation")}</p>
              <button
                className={styles.btn}
                style={{
                  border: "2px solid #ED1E24",
                  padding: "12px 20px",
                  borderRadius: 40,
                  cursor: "pointer",
                }}
                onClick={() => {
                  this.setState({
                    modalTitle: t("profile.addEducation"),
                    edit: true,
                    add: true,
                    id: null,
                    upload_img: "",
                    school: "",
                    description: "",
                    start_date: null,
                    end_date: null,
                    participations: "",
                    field_of_study: "",
                    degree: "",
                    grade: "",
                  });
                  document.body.style.overflow = "hidden";
                }}
              >
                <p
                  className={styles.btn_text}
                  style={{
                    margin: 0,
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  + {t("profile.addEducation")}
                </p>
              </button>
            </div>
          ) : null}
          <div
            style={{
              // padding: this.state.edContent.length <= 2 ? 0 : 20,
              paddingTop: 20,
              paddingLeft: 20,
              paddingRight: 20,
              // borderBottom: this.state.edContent.length <= 2 ? 'none' : '1px solid #5555551A'
            }}
          >
            {this.props.edContent.slice(0, this.state.endIdx).map((item, idx) => {
              return (
                <div
                  key={idx}
                  style={{
                    borderBottom: this.props.edContent.length == 1 || (this.props.edContent.length == 2 && idx == 1) ? "none" : "1px solid #5555551A",
                    paddingBottom: 15,
                    marginTop: idx == 0 ? 0 : 20,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <img src={item.upload_img} height="50px" width="50px" style={{ borderRadius: 40, marginRight: 15 }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <p className={styles.section_title}>
                            {item.school}
                            <br />
                            <span className={styles.followers}>
                              {item.degree}, {item.field_of_study}
                            </span>
                          </p>
                        </div>
                      </div>
                      <p className={styles.followers} style={{ opacity: 0.7, color: "#555555", marginBottom: 6 }}>
                        {item.start_date} - {item.end_date}
                      </p>
                      <p className={styles.section_desc}>{item.participations}</p>
                      <p className={styles.section_desc}>{item.description}</p>
                    </div>
                  </div>
                  <div>
                    <img
                      src="/Image/Chalkmate_Edit.svg"
                      style={{
                        height: 20,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          modalTitle: t("profile.editEducation"),
                          edit: true,
                          add: false,
                          id: item.id,
                          upload_img: item.upload_img,
                          school: item.school,
                          description: item.description,
                          start_date: item.start_date,
                          end_date: item.end_date,
                          participations: item.participations,
                          field_of_study: item.field_of_study,
                          degree: item.degree,
                          grade: item.grade,
                        });
                        document.body.style.overflow = "hidden";
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          {this.props.edContent.length <= 2 ? null : (
            <div
              style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: 10 }}
              onClick={() => {
                if (this.state.endIdx > 2) {
                  this.setState({ endIdx: 2 });
                } else {
                  this.setState({ endIdx: this.props.edContent.length });
                }

                if (this.state.seeall) {
                  this.setState({ seeall: false });
                } else {
                  this.setState({ seeall: true });
                }
              }}
            >
              <btn style={{ fontSize: 12, fontWeight: 600, color: "#555555", margin: 10 }}>
                {this.props.edContent.length <= 2 ? null : this.state.seeall ? t("common.collapse") : t("common.seeAll")}
              </btn>
            </div>
          )}
        </div>

        {this.state.edit ? (
          <Modal
            card={
              <EditEducation
                id={this.state.id}
                modalTitle={this.state.modalTitle}
                school={this.state.school}
                start_date={this.state.start_date}
                end_date={this.state.end_date}
                participations={this.state.participations}
                field_of_study={this.state.field_of_study}
                upload_img={this.state.upload_img}
                description={this.state.description}
                degree={this.state.degree}
                grade={this.state.grade}
                add={this.state.add}
                changeEdit={this.changeEdit.bind(this)}
              />
            }
          />
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    edContent: state.profile?.education || [],
  };
}

export default withTranslation()(connect(mapStateToProps, null)(EducationFeed));
