import React, { useEffect, useState } from 'react'
import { getClassDetails } from '../../../redux/actions/classes';
import { getGroupDetails } from '../../../redux/actions/groups';
import ViewAssignmentPage from '../ViewAssignmentPage';
import ErrorFourZeroFour from "../../../components/ErrorFourZeroFour";
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import styles from "./OpenAsgFromMail.module.css";
import { getIndividualAssignment } from '../../../redux/actions/assignment';
import { getIndividualQuiz } from '../../../redux/actions/quiz';

const OpenAsgFromMail = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const routeType = window.location.pathname.split('/')[1];
  const asgId = window.location.pathname.split("/")[4]; 

  useEffect(() => {    
    if(routeType==='class'){
      if(props.assignmentType==="ASSIGNMENT"){
        props.getIndividualAssignment(asgId)
         .then(()=>{ 
          if(props.classId != undefined){
          props.getClassDetails(props.classId).then(()=>setIsLoading(false))}
        })
         
      } else {
        props.getIndividualQuiz(asgId)
         .then(()=>{ 
          if(props.classId != undefined){
          props.getClassDetails(props.classId).then(()=>setIsLoading(false))}
        })         
      }
    } else {
      if(props.assignmentType==="ASSIGNMENT"){
        props.getIndividualAssignment(asgId)
         .then(()=>{ 
          if(props.groupId != undefined){
          props.getGroupDetails(props.groupId).then(()=>setIsLoading(false))}
        })         
      } else {
        props.getIndividualQuiz(asgId)
         .then(()=>{ 
          if(props.groupId != undefined){
          props.getGroupDetails(props.groupId).then(()=>setIsLoading(false))}
        })              
      }
    }
  }, [props.classId,props.groupId])  

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
      console.log('This will run after 15 second!')
    }, 15000);
    return () => clearTimeout(timer);
  }, []);

  
  return (
    <> 
      {isLoading ?
        <div className={styles.loader_style}>
          <CircularProgress sx={{color: "#ED1E24"}} size={50} />
          {/* {setTimeout(setIsLoading(false),3000)} */}
        </div> :
       props.hasAccess ?
        <ViewAssignmentPage assignmentType={props.assignmentType} routeType={routeType} isOwner={props.hasAccess} viaMail={true} /> :
        <ErrorFourZeroFour/> 
      }      
    </>
  )
}

const mapStateToProps = (state,props) => {
  return {
    classId: (props?.assignmentType === "ASSIGNMENT") ? (state?.assignment?.assignment?.classes) : (state?.quiz?.quizInd?.classes),
    groupId: (props?.assignmentType === "ASSIGNMENT") ? (state?.assignment?.assignment?.groups) : (state?.quiz?.quizInd?.groups),
    isOwner: (window.location.pathname.split('/')[1] === "group") ? (state?.groups?.group?.group_owner === state?.profile?.profileId) : (state?.profile?.user?.user_subtype == "FACULTY"),
    hasAccess: window.location.pathname.split('/')[1] === "group" ? (state?.groups?.group?.hasAccess) : (state?.classes?.class?.hasAccess),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getClassDetails: (id)=>dispatch(getClassDetails(id)),
    getGroupDetails: (id)=>dispatch(getGroupDetails(id)),
    getIndividualAssignment: (id)=>dispatch(getIndividualAssignment(id)),
    getIndividualQuiz: (id)=>dispatch(getIndividualQuiz(id)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(OpenAsgFromMail);