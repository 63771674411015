import React from "react";
import styles from "../../../../Profile.module.css";

import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";

export default class AddFeaturedLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      heading: "",
      link: "",
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  SaveButtonOnClick() {
    let e = [
      {
        img: "/Image/apps/profile-resume-edit-view/upskilling1.jpg",
        title: this.state.heading,
        desc: this.state.link,
        islink: true,
      },
    ];

    this.props.addFeaturedContent(e);
    this.props.changeEditLink(false);
    document.body.style.overflow = "visible";
  }

  ScrollBody() {
    return (
      <div
        style={{
          paddingBottom: 20,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 20 }}>
          <p className={styles.modal_heading}>Enter Heading</p>
          <input
            type="text"
            className={styles.modal_para}
            placeholder="Enter a heading"
            value={this.state.heading}
            onChange={(e) => {
              this.setState({ heading: e.target.value });
            }}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 50,
            }}
          />
          <br />
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <p className={styles.modal_heading}>Enter a link</p>
          <input
            type="text"
            className={styles.modal_para}
            value={this.state.link}
            onChange={(e) => {
              this.setState({ link: e.target.value });
            }}
            placeholder="Paste or type a link to a file or video"
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 50,
            }}
          />
          <br />
        </div>
      </div>
    );
  }

  ModalHeaderOnClick() {
    this.props.changeEditLink(false);
    document.body.style.overflow = "visible";
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader title={this.props.title} onClick={this.ModalHeaderOnClick} />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />
      </div>
    );
  }
}
