import {
  GET_ALL_STUDENT_DETAILS,
  GET_ASSIGNMENT_QUIZ_RESULTS,
  DOWNLOAD_GRADEBOOK,
  DOWNLOAD_STUDENT_LIST,
  DOWNLOAD_INDIVIDUAL_ASSIGNMENT,
  DOWNLOAD_INDIVIDUAL_QUIZ,
} from "../constants/actionTypes";

import {
  GET_ALL_CLASS_STUDENT_DETAILS_QUERY,
  GET_ALL_GROUP_STUDENT_DETAILS_QUERY,
  GET_CLASS_ASSIGNMENT_QUIZ_RESULTS_QUERY,
  GET_GROUP_ASSIGNMENT_QUIZ_RESULTS_QUERY,
} from "../../graphql/queries/gradebook.js";

import {
  DOWNLOAD_STUDENT_LIST_API,
  DOWNLOAD_GRADEBOOK_API,
  DOWNLOAD_INDIVIDUAL_ASSIGNMENT_API,
  DOWNLOAD_INDIVIDUAL_QUIZ_API,
} from "../constants/apis.js";

import { errorMessageFromResponse } from "../constants/commonFunctions";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import { request, gql, GraphQLClient } from "graphql-request";
import axios from "axios";

// --------- getAllStudentDetails ----------

export const getAllStudentDetails = (classOrGroupId, isClass) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  let variables = {
    id: classOrGroupId,
  };

  const query = isClass
    ? GET_ALL_CLASS_STUDENT_DETAILS_QUERY
    : GET_ALL_GROUP_STUDENT_DETAILS_QUERY;

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        dispatch({
          type: GET_ALL_STUDENT_DETAILS,
          payload: response?.member_list, // Payload
        });
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

export const getAsgQuizResults = (
  studentProfileId,
  classOrGroupId,
  isClass
) => {
  const token = store.getState()?.profile?.user?.token;
  const endpoint = process.env.REACT_APP_GRAPHQL_BASE_URL;

  const client = new GraphQLClient(endpoint, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });

  let variables = {
    profileId: studentProfileId,
    id: classOrGroupId,
  };

  const query = isClass
    ? GET_CLASS_ASSIGNMENT_QUIZ_RESULTS_QUERY
    : GET_GROUP_ASSIGNMENT_QUIZ_RESULTS_QUERY;

  return async (dispatch) => {
    await client
      .request(query, variables)
      .then((response) => {
        dispatch({
          type: GET_ASSIGNMENT_QUIZ_RESULTS,
          payload: response?.CombinedSubmission, // Payload
        });
      })
      .catch(async (error) => {
        dispatch(
          handleErrorMessage(
            errorMessageFromResponse(
              error?.response?.errors[0]?.formatted_message
            )
          )
        );
        return error;
      });
  };
};

// --------- downloadStudentList ----------

export const downloadStudentList = (classOrGroupId, isClass) => {
  const token = store.getState()?.profile?.user?.token;
  let lang = window.localStorage.getItem("i18nextLng");
  if (lang == "en-US") {
    lang = "en";
  }
  const endpoint = `${DOWNLOAD_STUDENT_LIST_API}?${isClass ? 'class_id' : 'group_id'}=${classOrGroupId}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        responseType: 'blob',
        headers: {
          "content-type": "application/pdf",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.status >= 200 &&
          response.status < 300
        ) {
          const file = new Blob([response.data], { type: 'application/pdf' });  
          const fileURL = URL.createObjectURL(file);  
          window.open(fileURL,"_blank");
          // dispatch({
          //   type: DOWNLOAD_STUDENT_LIST,
          //   payload: response.data.data,
          // });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const downloadGradebook = (classOrGroupId, profileId, isClass) => {
  const token = store.getState()?.profile?.user?.token;
  let lang = window.localStorage.getItem("i18nextLng");
  if (lang == "en-US") {
    lang = "en";
  }
  const endpoint = `${DOWNLOAD_GRADEBOOK_API}?profile_id=${profileId}&${isClass ? 'class_id' : 'group_id'}=${classOrGroupId}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        responseType: 'blob',
        headers: {
          "content-type": "application/pdf",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.status >= 200 &&
          response.status < 300
        ) {
          const file = new Blob([response.data], { type: 'application/pdf' });  
          const fileURL = URL.createObjectURL(file);  
          window.open(fileURL,"_blank");
          // dispatch({
          //   type: DOWNLOAD_GRADEBOOK,
          //   payload: response.data.data,
          // });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ----- download Individual Assignment Quiz Gradebook -----

export const downloadIndividualAsgQuizGradebook = (asgQuizSubmissionId, isAsg) => {
  const token = store.getState()?.profile?.user?.token;
  let lang = window.localStorage.getItem("i18nextLng");
  if (lang == "en-US") {
    lang = "en";
  }
  const endpoint = `${isAsg ? DOWNLOAD_INDIVIDUAL_ASSIGNMENT_API : DOWNLOAD_INDIVIDUAL_QUIZ_API}?${isAsg ? 'asg_sub_id' : 'quiz_sub_id'}=${asgQuizSubmissionId}`;

  return async (dispatch) => {
    await axios
      .get(endpoint, {
        responseType: 'blob',
        headers: {
          "content-type": "application/pdf",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response.status >= 200 &&
          response.status < 300
        ) {
          const file = new Blob([response.data], { type: 'application/pdf' });  
          const fileURL = URL.createObjectURL(file);  
          window.open(fileURL,"_blank");
          // dispatch({
          //   type: DOWNLOAD_STUDENT_LIST,
          //   payload: response.data.data,
          // });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};