import React from "react";

const IconBgTitle = ({ icon, title }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: '0.9375rem',
    }}
    >
      <img
        src={icon}
        style={{
            width: "2.25rem",
            height: "2.25rem",
        }}
        />
      <p
        style={{
            fontFamily: "Montserrat",
            fontSize: "1.125rem",
            fontWeight: 600,
            lineHeight: '1.375rem',
        }}
      >
        {title}
      </p>
    </div>
  );
};

export default IconBgTitle;
