import React, { Component } from "react";
import styles from "./css/ChatList.module.css";
import ChatSmall from "./ChatSmall";
import ProfilePicture from "./images/humanplaceholder.jpeg";

export default class ChatList extends Component {
  render() {
    return (
      <div id="div-chatlist-container" className={styles.container}>
        <ChatSmall openChat={this.props.openChat} profilePicture="/Image/chat/Ellipse 16.png" last="You: Thanks" name="Ananya Chandra" lastDate="Sept 4 2021" />
        <ChatSmall openChat={this.props.openChat} profilePicture="/Image/chat/Ellipse 15.png" last="You: Thanks" name="Nicole Brigg" lastDate="Aug 14 2021" />
        <ChatSmall openChat={this.props.openChat} profilePicture="/Image/chat/Ellipse 14.png" last="You: Thanks" name="Ananya Chandra" lastDate="Jul 12 2021" />
        <ChatSmall openChat={this.props.openChat} profilePicture="/Image/chat/Ellipse 12.png" last="You: Thanks" name="Nicole Brigg" lastDate="Jun 2 2021" />
        <ChatSmall openChat={this.props.openChat} profilePicture="/Image/chat/Ellipse -1.png" last="You: Thanks" name="Ananya Chandra" lastDate="Feb 28 2021" />
        <ChatSmall openChat={this.props.openChat} profilePicture="/Image/chat/Ellipse -2.png" last="You: Thanks" name="Nicole Brigg" lastDate="Nov 21 2020" />
      </div>
    );
  }
}
