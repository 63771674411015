import React, { useState, useEffect } from "react";
import styles from "./TrainingFeed.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import AddPatentResearchTrainingModal from "./Modals/AddPatentResearchTrainingModal";
import Modal from "../../../../../components/commons/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toggleSectionVisibility } from "../../../../../redux/actions/profile";
import TooltipWrapper from "../../../../CourseMonetization/Components/TooltipWrapper";

const TrainingFeed = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expand, setExpand] = React.useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editTarget, setEditTarget] = useState(null);

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const isTrainingFeedVisible = useSelector((state) => state?.profile?.trainingAndconference_visibility);

  const [isVisible, setIsVisible] = useState(isTrainingFeedVisible);
  
  // "owner" is for the logged in user, "view" is for the user who's profile is being viewed by the logged in user
  const storedTrainingAndConferenceOwner = useSelector(
    (state) => state?.profile?.trainingAndConference
  );
  const storedTrainingAndConferenceView = useSelector(
    (state) => state?.profile?.trainingAndConferenceView
  );

  const trainingAndConference = props.isEditPage ? storedTrainingAndConferenceOwner : storedTrainingAndConferenceView;

  const profileId = useSelector((state) => state?.profile?.profileId);

  const handleToggle = (event) => {
    setIsLoadingBtn(true);
    setIsVisible(event.target.checked);
    let data = new FormData();
    data.append("trainingAndconference_visibility", event.target.checked);
    
    dispatch(toggleSectionVisibility(data, profileId))
    .then(()=>setIsLoadingBtn(false));
  };

  useEffect(() => {
    setIsVisible(isTrainingFeedVisible);
  }, [isTrainingFeedVisible])

  

  const styleSwitch = {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ED1E24",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#ED1E24",
    },
  };

  const closeModal = () => {
    setIsEditing(false);
    setEditTarget(null);
    setShowModal(false);
  }

  return (
    <div className={styles.main}>
      {showModal && (
        <Modal
          card={
            <AddPatentResearchTrainingModal
              onClose={closeModal}
              modalType={"training"}
              isEditing={isEditing}
              targetId={editTarget?.id}
              title={editTarget?.title}
              organisation={editTarget?.organization_name}
              date={editTarget?.issue_date}
              description={editTarget?.description}
              url={editTarget?.credential_url}
              image={editTarget?.image}
            />
          }
        />
      )}
      <div className={styles.header}>
        <IconTitle
          title={t("profile.trainingAndConferences")}
          img="/Image/profile_page/Training (2).svg"
        />
         {props.isEditPage && 
          <TooltipWrapper title={t("profile.invisibleTooltipMessage")}>          
          <Switch
            checked={isVisible}
            disabled={isLoadingBtn}
            sx={styleSwitch}
            onChange={handleToggle}
            inputProps={{ "aria-label": "controlled" }}
            />
          </TooltipWrapper>        
        }
      </div>

      <div className={styles.card}>
        {props.isEditPage && <>
        <p className={styles.description}>
          {t("profile.addTrainingAndConferences")}
        </p>
        <button className={styles.add_btn} onClick={() => setShowModal(true)}>
          <p className={styles.btn_label}>
            &#65291; {t("profile.addTrainingAndConferences")}
          </p>
        </button>
        </>}

        {console.log("inTraining", trainingAndConference)}
        {console.log("isEditPage", props.isEditPage)}

        {trainingAndConference?.length > 0 ?
          trainingAndConference?.map((item) => (
            <div className={styles.publication_container} key={item?.id}>
              <img src={item?.image || "/Image/apps/profile-resume-edit-view/default-certificate.png"} className={styles.media} />
              <div className={styles.text_wrapper}>
                <div className={styles.title_header}>
                  <p className={styles.title}>{item?.title}</p>
                 {props.isEditPage && <img
                    src="/Image/Chalkmate_Edit.svg"
                    onClick={() =>{
                      setEditTarget(item);
                      setIsEditing(true);
                      setShowModal(true);
                    }}
                  />}
                </div>
                <p className={styles.publisher_and_date}>
                  {item?.organization_name} &#x2022; {item?.issue_date}
                </p>
                {item?.credential_url && (
                  <div
                    className={styles.url}
                    onClick={() => window.open(item?.credential_url, "_blank")}
                  >
                    {t("profile.showCredentials")}
                    <img src="/Image/redirect.png" />
                  </div>
                )}

                <p className={styles.description}>{item?.description}</p>
              </div>
            </div>
          )) : <p>{t("profile.noInfoFound")}</p>}
      </div>
    </div>
  );
};

export default TrainingFeed;
