import i18next from 'i18next';
import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import "./SignNav.css"

function SignNav() {
    const initLang = localStorage.getItem("i18nextLng") || navigator.language || navigator.userLanguage;
    const [lang, setLang] = useState(initLang);
    const [showLangMenu, setShowLangMenu] = useState(false);

    useEffect(()=>{
      if(initLang == "uz"){
        setLang("O'zbek")
      }
      else if(initLang == "ru"){
        setLang("Pусский")
      }
      else{
        setLang("English")
      }

    }, [])

    useEffect(() => {
      let handler = (event) => {
        if (!navNode.current.contains(event.target)) {
          setShowLangMenu(false);
        }
      };
      document.addEventListener("mousedown", handler);
      return () => {
        document.removeEventListener("mousedown", handler);
      };
    }, []);
  
    const navNode = useRef();

    const handleLangMenu = () => {
        setShowLangMenu(!showLangMenu)
    }

    const changeSelectedLanguage = (selectedLang) => {
        i18next.changeLanguage(selectedLang)
        localStorage.setItem("i18nextLng", selectedLang)
        if(selectedLang == "uz"){
          setLang("O'zbek")
        }
        else if(selectedLang == "ru"){
          setLang("Pусский")
        }
        else{
          setLang("English")
        }
      }


  return (
      <div className="headerSign">
          <div>
    <Link to="/">
          <img className="sign-logo" src="Image/signImage/logo.svg" alt="sign-logo" />
    </Link>
    </div>
    <div ref={navNode} className='sign-right-logo'
         onClick={() => handleLangMenu()}>
        <img src="Image/languageBlack.png" alt="languageIcon" />
        <div className='languageShow'>{lang}</div>
        {
            showLangMenu ?(  
            <div className="dropdownLanguag">
            <ul className="dropdownList">
              <div>
                
                  <li className={lang == "en" ? "activeList" : "inactiveList"} onClick={() => changeSelectedLanguage("en")}>English</li>
                
                  <hr/>
                  <li className={lang == "ru" ? "activeList" : "inactiveList"} onClick={() => changeSelectedLanguage("ru")}>Pусский</li>
              
                <hr/>
                <li className={lang == "uz" ? "activeList" : "inactiveList"} onClick={() => changeSelectedLanguage("uz")}>
                O'zbek
                </li>
              </div>
            </ul>
          </div>):null
          }
    </div>

    </div>
  )
}

export default SignNav