import React from 'react'
import { LineChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from 'recharts'

 

const LineChartComponent = (props) => {

  if(props.data?.length < 1){
    return (
      <div>
          <img
            // className={styles.no_data_image}
            src="/Image/EnterpriseDashboard/empty-chart-state.svg"
          />
        </div>
    )
  }
  return (
    <ResponsiveContainer width="100%" height="100%">
      {console.log("insideLine", props.data)}
        <LineChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="posts" stroke="#ed4d50" />
        </LineChart>
      </ResponsiveContainer>
  )
}

export default LineChartComponent