import {
  GET_ALL_STUDENT_DETAILS,
  GET_ASSIGNMENT_QUIZ_RESULTS,
  DOWNLOAD_GRADEBOOK,
  DOWNLOAD_STUDENT_LIST,
  DOWNLOAD_INDIVIDUAL_ASSIGNMENT,
  DOWNLOAD_INDIVIDUAL_QUIZ,
} from "../constants/actionTypes";

const defaultState = {
  totalStudentCount: null,
  studentList: [],
  result: null,
  studentListPDF: null,
  gradebookPDF: null,
  individualQuizPDF: null,
  individualAssignment: null,
};

let data = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_ALL_STUDENT_DETAILS:
      data = { ...state };
      data.totalStudentCount = action.payload?.totalStudents;
      data.studentList = action.payload?.memberStudents;
      return data;

    case GET_ASSIGNMENT_QUIZ_RESULTS:
      data = { ...state };
      data.result = action.payload;
      return data;

    case DOWNLOAD_STUDENT_LIST:
      data = { ...state };
      data.studentListPDF = action.payload;
      return data;

    case DOWNLOAD_GRADEBOOK:
      data = { ...state };
      data.gradebookPDF = action.payload;
      return data;

    case DOWNLOAD_INDIVIDUAL_QUIZ:
      data = { ...state };
      data.individualQuizPDF = action.payload;
      return data;

    case DOWNLOAD_INDIVIDUAL_ASSIGNMENT:
      data = { ...state };
      data.individualAssignment = action.payload;
      return data;

    default:
      return { ...state };
  }
};
