import { Avatar } from "@material-ui/core";
import ClassIcon from "@material-ui/icons/Class";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import GroupIcon from "@material-ui/icons/Group";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import styles from "../Components/CSSFiles/Sidebar.module.css";
import { Component } from "react";
import MiddleContain from "../../ClassesGroups/ClassModal/MiddleContain";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StartLive from "../../../components/commons/StartLive";
import SidebarClass from "./SidebarClass";
import SidebarGroup from "./SidebarGroup";
import { getAboutUser } from "../../../redux/actions/about";
import SaveButton from "../../../components/commons/SaveButton";
import MyClass from "../Components/MyClass";
import MyGroup from "../Components/MyGroup";
import MyCourse from "../Components/MyCourse";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import {fetchSearchResultsData} from "../../../redux/actions/profile"
import { fetchPeopleYouMayKnow } from "../../../redux/actions/peopleyoumayknow";
import { FETCH_SEARCH_RESULTS, GET_PROFILE_DATA_EDIT } from "../../../redux/constants/actionTypes";
import { withRouter } from "react-router";
import {withTranslation} from "react-i18next"
import { fetchProfileDataEdit } from "../../../redux/actions/profile";
// import { ModalWithHeader } from "../../../components/shared/ui/Modal/Modal";
import { getAllCourses } from '../../../redux/actions/courses';
import { thumbnailList } from '../../../redux/constants/commonFunctions';

class Sidebar extends Component {
  constructor() {
    super();

    this.state = {
      showMenu1: false,
      search: "",
    };

    this.state = {
      showMenu2: false,
    };

    this.state = {
      showMenu3: false,
    };

    // this.state = {
    //   showClassModal: false,
    // };
    this.state = {
      showShareModal: false,
    };

    this.state = {
      showShareGroupModal: false,
    };

    this.state = {
      img: "./Image/DashImage/live-session.svg",
    };
  }

  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = () => {
    this.setState({ showShareModal: true });
  };

  shareGroupModalCloser = () => {
    this.setState({ showShareGroupModal: false });
  };

  shareGroupModalOpener = () => {
    this.setState({ showShareGroupModal: true });
  };

  toogleMenu1 = () => {
    this.setState({
      showMenu1: !this.state.showMenu1,
    });
  };

  toogleMenu2 = () => {
    this.setState({
      showMenu2: !this.state.showMenu2,
    });
  };

  toogleMenu3 = () => {
    this.setState({
      showMenu3: !this.state.showMenu3,
    });
  };
  
  searchResult = () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    this.props.fetchSearchResultsData(FETCH_SEARCH_RESULTS,`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results?search=`)
    // this.props.fetchPeopleYouMayKnow(FETCH_SEARCH_RESULTS, token)
    .then((res) => {
      if (res.val) {
        this.props.history.push("/search/results");
        return;
      }
      this.setState({ error: true, message: res.message });
    });
  }

  componentDidMount() {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    // this.props.fetchProfileDataEdit(GET_PROFILE_DATA_EDIT, token, JSON.parse(localStorage.userData).profile_id);
    document.addEventListener("click", this.handleClickOutside, true);
    // this.props?.getAllCourses(null,true,null,null,null,null,3);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        showMenu1: false,
        showMenu2: false,
      });
    }
  };
  render() {
    const profileStatus = [
      { name: "complete", val: this.props.profile_completion_status * 100 },
      { name: "pending", val: 100 - this.props.profile_completion_status * 100 },
    ];
    // console.log(profileStatus)
    return (
      <div className={styles.sidebar}>
        <div className={styles.profile_section}>
          <div className={styles.sidebar_top}>
            <img src={this.props.coverPicUrl ?? "/Image/apps/profile-resume-edit-view/cover-image.png"} alt="" />
            
            <img
              src={this.props.profilePicUrl ?? "/Image/apps/profile-resume-edit-view/default-user.png"}
              className={styles.sidebar_avatar}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100%",
              }}
              />
              {/* --- for OTW --- */}
              {this.props.showOTW && <div style={{ position: "absolute", width: 160, height: 160, overflow: "hidden", borderRadius: 100 }}>
              <img src={"/Image/otw/otw_dashboard_105x105.svg"} style={{ width: "100%", height: "100%", objectFit: "none", top:0 , left: 0, opacity: 1, marginTop: '1.5rem' }} />
            </div>}

            <div className={styles.name}>
              <img
                src="./Image/DashImage/badge.svg"
                data-tip={this.props?.user?.user_subtype ? this.props.user.user_subtype : "Other"}
                style={{ width: 32, height: 32, cursor: "pointer" }}
              />
              <h2 style={{ textTransform: "capitalize" }}>
                {this.props.name ?? this.props.user.first_name + " " + this.props.user.last_name ?? ""}
                {/* {this.props?.first_name??"User"+" "+ this.props.last_name??"Name"} */}
              </h2>
            </div>
            <p style={{display: this.props.designation === "" ? "none" : "block"}}>
              {/* Director-Corporate Affairs <br />| J kakehasti Services Pvt Ltd. */}
              {this.props.designation}
              <br />
              {this.props.organisation}
            </p>
          </div>
          <hr className={styles.Hr} />
          <div style={{ paddingTop: "10px",  textAlign: "left", marginLeft: "10px", display: "flex", alignItems: "center", marginRight: "10px", marginBottom: "10px"}}>
            <div style={{ height: "36px", width: "50px" }}>
              <div style={{ fontSize: 15, height: "100%" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie data={profileStatus} dataKey="val" startAngle={450} endAngle={90} cx="50%" cy="50%" innerRadius={10} outerRadius={18} fill="#82ca9d">
                      <Cell key={`cell-1`} fill={"#ED1E24"} />
                      <Cell key={`cell-2`} fill={"#F2F2F2"} />

                      {/* <LabelList dataKey="name" stroke="rgb(85, 85, 85)" position="outside" lableline="true"></LabelList> */}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div>
              <p style={{fontSize: "14px"}}>{this.props.t("dashboard.profileComplete", {completionPercent: parseInt(this.props.profile_completion_status * 100)})}</p>
              <Link to={`/profile/${JSON.parse(localStorage?.userData || "{}").profile_id}/edit`} style={{ textDecoration: "none", display: "flex", width: "fit-content" }}>
                <p style={{ color: "#ED1E24", fontSize: "14px" }}>
                  <u style={{ cursor: "pointer" }}>{this.props.t("dashboard.completeProfile")}</u>
                </p>
              </Link>
            </div>
          </div>
          <hr className={styles.Hr} />
          <div className={styles.view}>
            <p style={{fontSize: "14px"}}>{this.props.t("dashboard.viewedProfile")}</p>
            <p style={{ color: "#ED1E24", fontSize: "14px" }}>{this.props.viewsProfile}</p>
          </div>

          <div className={styles.view}>
            <p style={{fontSize: "14px"}}>
              {this.props.t("dashboard.myConnections")} <br />{" "}
              <a
                className={styles.connection_text_sidebar}
                style={{
                  color: "#ED1E24",
                  textDecorationLine: "underline",
                  cursor: "pointer",
                }} onClick={() => this.props.history.push("/connections")}>
                {this.props.t("dashboard.growNetwork")}{" "}
              </a>
            </p>
            <p style={{ color: "#ED1E24", fontSize: "14px" }}>{this.props.connections.length}</p>
          </div>

          <hr className={styles.Hr} />

          <div onClick={this.props.PremiumModalOpener} className={`${styles.premium_view} ${styles.view}`}>
            <img src="./Image/DashImage/diamond.svg" style={{ width: 24, height: 27, marginTop: 14 }} />
            <p style={{ marginLeft: 2, marginBottom: 5, cursor: "pointer", fontSize: "13px" }}>
              {this.props.t("dashboard.tryPremiumDesc")} <br /> <span className={styles.premium_text_sidebar} style={{ color: "#ED1E24", fontSize: "13px" }}>{this.props.t("dashboard.tryPremium")}</span>
            </p>
          </div>

        </div>
        <StartLive source="DASHBOARD" />

        <div className={styles.sidebar_mycontent}>
          <div className={styles.side}>
            <img src="./Image/DashImage/Chalkmate_MyClasses.svg" className={styles.sidebar_icon1} style={{ width: 35, height: 35, cursor: "pointer" }} />
            <h3 style={{ marginLeft: 15 }}>{this.props.t("common.myClasses")}</h3>
          </div>
          <div className={styles.navbar_menu1}>
            <MoreHorizIcon onClick={this.toogleMenu1} className={styles.sidebar_icon2} style={{ marginTop: 7, cursor: "pointer" }} />
            {this.state.showMenu1 ? <MyClass /> : <></>}
          </div>
        </div>

        <div className={styles.sidebar_stats}>
          <SidebarClass />
          {/* <div className={styles.sidebar_stat1}>
            <p onClick={this.props.ClassModalOpener}>
              <AddIcon style={{ width: 24, height: 24 }} />
              <span style={{ marginLeft: 5, textTransform: "uppercase" }}>{this.props.t("common.createClass")}</span>
            </p>
          </div> */}
        </div>

        <div className={styles.sidebar_mycontent}>
          <div className={styles.side}>
            <img src="./Image/DashImage/Chalkmate_Mygroup.svg" className={styles.sidebar_icon1} style={{ width: 35, height: 35, cursor: "pointer" }} />
            <h3 style={{ marginLeft: 15}}>{this.props.t("common.myGroups")}</h3>
          </div>
          <div className={styles.navbar_menu1}>
            <MoreHorizIcon onClick={this.toogleMenu2} className={styles.sidebar_icon2} style={{ marginTop: 7, cursor: "pointer" }} />
            {this.state.showMenu2 ? <MyGroup /> : <></>}
          </div>
        </div>

        <div className={styles.sidebar_stats}>
          <SidebarGroup />
          {/* <div className={styles.sidebar_stat1}>
            <p onClick={this.props.GroupModalOpener}>
              <AddIcon style={{ width: 24, height: 24 }} />
              <span style={{ marginLeft: 5, textTransform: "uppercase"}}>{this.props.t("common.createGroup")}</span>
            </p>
          </div> */}
        </div>

        {/* Left Sidebar MyCourses */}

        {/* <div className={styles.sidebar_mycontent}>
          <div className={styles.side}>
            <img src="/Image/DashImage/Chalkmate_Courses.svg" className={styles.sidebar_icon1} style={{ width: 35, height: 35, cursor: "pointer" }} />
            <h3 style={{ marginLeft: 15}}>{this.props.t("common.myCourses")}</h3>
          </div>
        </div>

        <div className={styles.sidebar_stats}>
          { this.props.publicCourses?.length > 0 &&
            this.props.publicCourses?.map((course, index) => ( 
              <>
                <div className={styles.courseFeedData} key={course?.id}>
                <img src={course?.thumbnail ?? thumbnailList[index % 13]}></img>
                <p>{course?.name}</p>
                </div>
                <hr className={styles.Hr3}/>
              </>
            ))
          }

          <span style={{ marginLeft: 5, textTransform: "uppercase"}}><Link to="/courses" className={styles.sidebar_stat2}>{this.props.t("common.viewallcourses")}</Link></span>
        </div> */}

        {/* Left Sidebar MyCourses */}

        <div className={styles.sidebar_mycontent}>
          <div className={styles.side}>
            <img src="./Image/DashImage/Chalkmate_Bookmark.svg" className={styles.sidebar_icon1} style={{ width: 35, height: 35, cursor: "pointer" }} />
            <h3 style={{ marginLeft: 15 }}>{this.props.t("dashboard.topBookmarks")}</h3>
          </div>
        </div>

        <div className={styles.sidebar_stats}>
          <div className={styles.sidebar_stat} style={{ padding: 10 }}>
            <p>{this.props.t("dashboard.hashtag")}</p>
            <p style={{ color: "#ED1E24" }}>8</p>
          </div>
          <div className={styles.sidebar_stat}>
            <span className={styles.hastagsParagraph}>#Physics #machine learning #science #maths #quantum...</span>
          </div>
        </div>
        <ReactTooltip />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state?.profile?.user,
    first_name: state?.profile?.user?.first_name,
    last_name: state?.profile?.user?.last_name,
    profilePicUrl: state?.profile?.userProfilePic,
    coverPicUrl: state?.profile?.userCoverPic,
    designation: state?.profile?.designation,
    connections: state?.profile?.myConnections || 0,
    organisation: state?.profile?.organisation,
    name: state?.profile?.name,
    interests: state?.profile?.interests || [],
    profile_completion_status: state?.profile?.profile_completion_status || "",
    viewsProfile: state?.profile?.viewsProfile || 0,
    publicCourses: state?.courses?.publicCourses || [],
    showOTW: state.profile?.open_to_work?.visibility || false, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPeopleYouMayKnow: (action, token) => dispatch(fetchPeopleYouMayKnow(action, token)),
    fetchSearchResultsData: (action, url) => dispatch(fetchSearchResultsData(action, url)),
    // fetchProfileDataEdit: (action, token, id) => dispatch(fetchProfileDataEdit(action, token, id)),
    getAllCourses: (classId,isPublic,isEnrolled,isFaculty,isFeed,isExploring,limit) => dispatch(getAllCourses(classId,isPublic,isEnrolled,isFaculty,isFeed,isExploring,limit)), 
  };
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar)));
