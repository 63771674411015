import React, { Component } from "react";
import { useSelector } from "react-redux";
import styles from "./commoncss/subjectCard.module.css";

function SubjectCard({ eachSubject, getSubjectDetails, editClassModalOpener, editClassId, deleteClassModalOpener }) {
  const { name, grade, image_url, subject, id, code } = eachSubject;

  const class_owner = useSelector((state) => state.profile.profileId);

  const handleClassEdit = () => {
    editClassModalOpener()
    editClassId(eachSubject)
  }

  const handleClassDelete = () => {
    deleteClassModalOpener()
    /* editClassId(name, grade, image_url, subject, id, code) */
    editClassId(eachSubject)
  }

  return (
    <div className={styles.container}>
        <div onClick={() => getSubjectDetails(id)}>
        <div className={styles.imageContainer}>
          <img src={image_url} className={styles.subjectImage} />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.nameText}>{name}</div>
          <div className={styles.gradeText}>{grade}</div>
          <div className={styles.gradeText}>{subject}</div>
          <div className={styles.subjectText}>{code}</div>
        </div>
      </div>
      {
        class_owner == eachSubject.class_owner
        ?   <div style={{display: "flex", justifyContent: "space-between", backgroundColor: "white", padding: "0 50px 20px 50px", borderRadius: "0px 0px 10px 10px"}}>
              <img onClick={handleClassEdit} src="/Image/class/edit.svg" style={{height:"25px", width:"auto", margin: "0 10px", cursor: "pointer"}}/>
              <img onClick={handleClassDelete} src="/Image/class/delete.svg" style={{height:"25px", width:"auto", margin: "0 10px", cursor: "pointer"}}/>
            </div>
        :   null
      }
    </div>
  );
}

export default SubjectCard;
