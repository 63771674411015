import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: 7,
            borderRadius: 10,
            backgroundColor: "#E1F4EA",
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#ED1E24",
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="#000000" sx={{fontFamily:"Montserrat", fontWeight:'500'}}>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearProgressComponent(props) {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel value={props.progress || 0} />
    </Box>
  );
}
