import React from "react";

import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Sector,
  Cell,
  Tooltip,
  Legend,
} from "recharts";

const PieChartComponent = (props) => {
  const pieChartDataOld = [
    {
      name: "Classes",
      value: props.data?.classes || 0,
    },
    {
      name: "Courses",
      value: props.data?.courses || 0,
    },
    {
      name: "Groups",
      value: props.data?.groups || 0,
    },
  ];

  const pieChartData = [];

  if(props.data?.classes) {
    pieChartData?.push({
      name: "Classes",
      value: props.data?.classes || 0,
    })
  }
  if(props.data?.courses) {
    pieChartData?.push({
      name: "Courses",
      value: props.data?.courses || 0,
    })
  }
  if(props.data?.groups) {
    pieChartData?.push({
      name: "Groups",
      value: props.data?.groups || 0,
    })
  }



  // const COLORS = ["#FF8042", "#0088FE", "#00C49F"];
  const COLORS = ["#47cc4d", "#e61e24", "#fdb45b"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`  ${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


  if(props.data?.groups === 0 && props.data?.classes === 0 && props.data?.courses === 0){
    return (
      <div>
          <img
            // className={styles.no_data_image}
            src="/Image/EnterpriseDashboard/empty-chart-state.svg"
          />
        </div>
    )
  }

  return (
    <ResponsiveContainer width="100%" height="100%" aspect={1}>
      {console.log("insidePi", props.data)}
      <PieChart>
        {/* <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data01}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label
          /> */}
        <Pie
          dataKey="value"
          data={pieChartData}
          cx="50%"
          cy="50%"
          labelLine={false}
          innerRadius={50}
          outerRadius={120}
          label={renderCustomizedLabel}
          fill="#82ca9d"
          // label
        >
          {pieChartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {/* <Tooltip />  */}
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
