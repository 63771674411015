import React, { useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styles from "./EnterpriseDropDownFilter.module.css";
import {
  Theme,
  useTheme,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useTranslation } from "react-i18next";

// Expected Props: option, label
// options - An array of strings
// minWidth - an integer value for width size (eg: 120)

const EnterpriseDropDownFilter = (props) => {
  const [selection, setSelection] = React.useState("");
  const { t } = useTranslation();

  // handleChaneg function isn't complete, it needs modification to capture the selected option
  const handleChange = (event, type) => {
    console.log('targetInside',event.target.value);
    setSelection(event.target.value);
    props.getSelection(event.target.value?.id);
  };

  const colortheme = createTheme({
    palette: {
      primary: { main: "#ED1E24" },
    },
  });

  useEffect(()=>{
    setSelection("");
  },[props.resetTrigger])

  // useEffect(()=>{
    
  //   if(props.type == 'order'){
  //     console.log('inside useEffect order ', props.value)
  //     props.value ? setSelection(props.options[1]) : setSelection(props.options[0]);
  //   } else if (props.type == 'reveal') {
  //     console.log('inside useEffect reveal ', props.value)
  //     props.value ? setSelection(props.options[1]) : setSelection(props.options[0]);
  //   } else {
  //     console.log('inside useEffect attempt ', props.value)
  //     setSelection(props.value)
  //   }
  // },[props?.value])

  console.log('inside dropdown component', selection, props.options)

  return (
    <div className={styles.main}>
      <ThemeProvider theme={colortheme}>
        <Select
          value={selection}
          fullWidth
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            borderRadius: "0.63rem",
            height: "2.8rem",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "400",
            background: "linear-gradient(180.00deg, rgb(255, 236, 236),rgb(255, 246, 246) 93.835%)",
          }}
          renderValue={(selection) => {
            if (selection) {
              return selection.name;
            }
            return 'All';
          }}
        >
          <MenuItem className={styles.text} value="">
            {/* {props.label ?? props.options?.[0]} */}
            All
          </MenuItem>
          {props.options?.map((item, index) => (
            <MenuItem
              key={`${item?.id} ${index}`}
              className={styles.text}
              value={item}
              sx={{
                fontFamily: "Montserrat",
                fontSize: "0.875rem",
                fontWeight: "400",
              }}
            >
              {/* {typeof item == "string" ? t(`${item}`) : item} */}
              {item?.name || ""}
            </MenuItem>
          ))}
        </Select>
      </ThemeProvider>
    </div>
  );
};

export default EnterpriseDropDownFilter;
