import React, { useState, useEffect } from "react";
import styles from "./css/GradebookView.module.css";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { CircularProgress } from "@mui/material";
import { downloadStudentList, getAllStudentDetails } from "../../redux/actions/gradebook";
import IconTitle from "../../components/commons/IconTitle";
import GradebookTableRow from "./Components/GradebookTableRow";
import ButtonMUI from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import ButtonMenuComponent from "./Components/ButtonMenuComponent";
// import {users} from "./temp-data";

const GradebookView = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const storedStudentList = useSelector(
    (state) => state?.gradebook?.studentList
  );
  const [studentList, setStudentList] = useState(storedStudentList || []);

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [isPrinting, setIsPrinting] = useState(false);

  const totalStudentCount =
    useSelector((state) => state?.gradebook?.totalStudentCount) || 0;

  const classOrGroupId = location?.state?.classOrGroupId;
  const routeType = location?.state?.routeType || "class";

  // const currentClassOrGroupName = useSelector((state) =>
  //   routeType === "class"
  //     ? state?.classes?.class?.name
  //     : state?.groups?.group?.name
  // );
  const currentClassOrGroupName = location?.state?.classOrGroupName;

  useEffect(() => {
    const isClass = routeType === "class";
    dispatch(getAllStudentDetails(parseInt(classOrGroupId), isClass)).then(() =>
      setIsLoading(false)
    );
  }, [classOrGroupId]);

  useEffect(() => {
    if (query.length >= 0) {
      let searchResult = storedStudentList?.filter((student) =>
        student.name.toLowerCase().includes(query)
      );

      setStudentList(searchResult);
    }
  }, [query]);

  useEffect(() => {
    setStudentList(storedStudentList);
  }, [storedStudentList]);

  const styleButtonMUI = {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    backgroundColor: "#ED1E24",
    borderColor: "#ED1E24",
    color: "#fff",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    marginRight: "auto",
    "&:hover": {
      backgroundColor: "#fff",
      borderColor: "#ED1E24",
      color: "#ED1E24",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;",
    },
    "&.Mui-disabled": {
      backgroundColor: "#f6f6f6",
      color: "#959595",
      borderColor: "#f6f6f6",
    },
    "@media (max-width: 500px)": {
      height: "25px",
      padding: "6.756px 6.647px 8px 10px",
    },
  };

  const handleRedirect = (id) => {
    history.push({
      pathname: "/gradebook/student/",
      state: {
        profileId: id,
        routeType: routeType,
        classOrGroupId: classOrGroupId,
        classOrGroupName: currentClassOrGroupName,
      },
    });
  };

  //  ---- TBD -----
  const handlePrint = () => {
    setIsPrinting(true);
    console.log("Print this doc.")
    const isClass = routeType === "class";
    dispatch(downloadStudentList(parseInt(classOrGroupId), isClass))
    .then(()=>setIsPrinting(false));
  }

  if (isLoading) {
    return (
      <div className={styles.loader_style}>
        <CircularProgress sx={{ color: "#ED1E24" }} size={50} />
      </div>
    );
  } else {
    return (
      <div className={styles.main_container}>
        <h5
          className={styles.back_btn}
          onClick={() => {
            if (classOrGroupId) {
              history.push({
                pathname: `/${routeType}/${classOrGroupId}`,
              });
            } else {
              history.push({
                pathname: `/user`,
              });
            }
          }}
        >
          <img src="/Image/assignment/back_arrow.svg" alt="" />
          &nbsp; &nbsp;
          {currentClassOrGroupName ?? t("courseMonetisation.goBackBtn")}
        </h5>

        <div className={styles.gradebook_outer_container}>
          <IconTitle
            title={t("gradebook.gradebook")}
            img="/Image/Gradebook/star-white2.svg"
            notClickable={true}
            type="ASSIGNMENT"
          />

          <div className={styles.header}>
            <div className={styles.count_container}>
              <p className={styles.count_label}>
                {t("gradebook.totalStudents")}
              </p>
              <p className={styles.count}>{totalStudentCount}</p>
            </div>

            <div className={styles.searchbar}>
              <img src="/Image/Gradebook/menu.svg" alt="icon" className={styles.menu_icon} />
              <input
                type="text"
                onChange={(e) => setQuery(e.target.value.toLowerCase())}
                placeholder={t("gradebook.searchByName") + ". . ."}
              />

              <img src="/Image/Gradebook/search.svg" alt="icon" />
            </div>
          </div>

          <div className={styles.label_and_button_container}>
            <p className={styles.student_details_label}>
              {t("gradebook.studentDetails")}
            </p>
            {/* <div className={styles.print_btn} onClick={handlePrint}>
              <img src="/Image/Gradebook/pdf-red.svg" alt="icon"/>
              <p>{t("common.print")}</p>
            </div> */}
             <ButtonMenuComponent onPrint={handlePrint} isPrinting={isPrinting} />
          </div>

          <div className={styles.table_container}>
            <div className={styles.table_header}>
              <p>{t("common.sno")}</p>
              <p>{t("common.name")}</p>
              <p className={styles.hide}>{t("assignment.assignments")}</p>
              <p className={styles.hide}>{t("quiz.quizzes")}</p>
              <p className={styles.hide}>{t("common.average")}</p>
              <p className={styles.hideBtn}>{"buttons"}</p>
            </div>

            {/* map the table row here */}
            {studentList?.length > 0 ? (
              studentList
                ?.slice((page - 1) * 10, page * 10)
                ?.map((student, index) => (
                  <GradebookTableRow
                    key={student?.id}
                    index={index + 1}
                    studentDetails={student || {}}
                    onClick={handleRedirect}
                    page={page}
                  />
                ))
            ) : (
              <p className={styles.no_results}>
                {t("gradebook.noResultsFound")}
              </p>
            )}
            
          </div>

          <div className={styles.pagination_container}>
            <Pagination
              count={Math.ceil(studentList?.length / 10) || 1}
              page={page}
              onChange={(event, value) => {
                setPage(parseInt(value));
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default GradebookView;
