import React, { useEffect, useState } from "react";
import styles from "./Opportunities.module.css";
import IconTitle from "../../components/commons/IconTitle";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getDropdownCategories,
  getOpportunitiesFilterResult,
} from "../../redux/actions/opportunities";
import CheckboxDropdown from "./CheckboxDropdown";
import ProfileSearchResultComponent from "./ProfileSearchResultComponent";
import ConnectionRightFeedComp from "../../components/commons/ConnectionRightFeedComp";
import { CircularProgress } from "@mui/material";
import cloneDeep from "lodash.clonedeep";
import Pagination from "@mui/material/Pagination";
import { Country, State } from "country-state-city";
import ProfileFrame from "./ProfileFrame";
import { produce } from "immer";
import IconBgTitle from "../../components/commons/IconBgTitle";
import useWindowWidth from "../../hooks/useWindowWidth";

const Opportunities = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let filterResult = useSelector((state) => state?.opportunities?.filterResult);
  let originalDropdownCategories = useSelector(
    (state) => state?.opportunities?.dropdownCategories
  );

  let dropdownCategories = {
    ...originalDropdownCategories,
    countries: Country.getAllCountries(),
  };

  const [isLoading, setIsLoading] = useState(true);

  const [selectedOptions, setSelectedOptions] = useState({
    tags: [],
    companies: [],
    categories: [],
    languages: [],
    designations: [],
    countries: [],
    states: [],
  });

  const [totalFilterCount, setTotalFilterCount] = useState(0);
  const [isOpenToWork, setIsOpenToWork] = useState(false);

  const [counts, setCounts] = useState({
    tags: 0,
    companies: 0,
    categories: 0,
  });

  const [page, setPage] = React.useState(1);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;


  const width = useWindowWidth();

  const isMobile = width <= 800; 

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    dispatch(getDropdownCategories()).then(() => setIsLoading(false));
  }, []);

  // const getSelection = (category, selection, filterCount) => {
  //   let data = cloneDeep(selectedOptions);
  //   data[category] = [...selection];

  //   let countObj = { ...counts };

  //   countObj[category] = filterCount;

  //   let total = 0;

  //   Object.values(countObj)?.forEach((x) => (total += x));
  //   setSelectedOptions(data);
  //   setTotalFilterCount(total);
  //   setCounts(countObj);

  //   if (total >= 0) {
  //     getResult(data);
  //   }

  //   console.log("getSelection", {
  //     category,
  //     selection,
  //     data,
  //     filterCount,
  //     total,
  //     countObj,
  //   });
  // };

  const getResult = (data) => {
    let payload = {
      otw: isOpenToWork,
      company: [...data?.companies],
      category: [...data?.categories],
      tags: [...data?.tags],
      state_code: [...data?.states],
      country_code: [...data?.countries],
      languages: [...data?.languages],
      designation: [...data?.designations],
    };
    dispatch(getOpportunitiesFilterResult(payload));
  };

  useEffect(() => {
    getResult(selectedOptions);
  }, [isOpenToWork, selectedOptions]);

  const handleDropdownChange = (category, event) => {
    const {
      target: { value },
    } = event;
    // setSelectedOptions(
    //   typeof value === "string" ? value.split(",") : value
    // );
    setSelectedOptions(
      produce(selectedOptions, (draft) => {
        draft[category] = [...event.target.value];
      })
    );
  };

  const clearFilter = (category, value) => {
    console.log("inside clearTag", category, value);

    setSelectedOptions(
      produce(selectedOptions, (draft) => {
        draft[category] = draft[category]?.filter((x) => x != value);
      })
    );
  };

  console.log("insideOpp", dropdownCategories, filterResult);

  console.log("top", selectedOptions);

  if (isLoading) {
    return (
      <div className={styles.main}>
        <div className={styles.loader}>
          <CircularProgress sx={{ color: "#ED1E24" }} size={40} />
        </div>
      </div>
    );
  }

  {
    console.log("tags", Object.entries(selectedOptions));
  }

  return (
    <div className={styles.main}>
      <div className={styles.top_container}>
        <p className={styles.selected}>{t("openToWork.people")}</p>

        <p
          onClick={() => setIsOpenToWork(!isOpenToWork)}
          className={isOpenToWork ? styles.selected : styles.select}
        >
          {t("openToWork.openToWork")}
        </p>
        {Object.entries(dropdownCategories || {})?.map((item) =>
          item[0] === "language" ? null : (
            <CheckboxDropdown
              categoryName={item[0]}
              // options={item[1].flat()}
              options={
                typeof item[1][0][0] === "string"
                  ? item[1]
                      .flat()
                      .flat()
                      .map((x) => {
                        return {
                          code: x,
                          name: x,
                        };
                      })
                  : item[1]
              }
              selectedOptions={selectedOptions}
              handleDropdownChange={handleDropdownChange}
              isMobile={isMobile}
            />
          )
        )}
      </div>

      {/* <div className={styles.tags_container}>
        {Object.values(selectedOptions || {})?.flat()?.map((item) => (
          <>
            {item?.length > 0 && (
              <div className={styles.tags} onClick={()=>}>
                <p>{item}</p>
                <img src="/Image/apps/profile-resume-edit-view/cross.svg" />
              </div>
            )}
          </>
        ))}
      </div> */}

      <div className={styles.tags_container}>
        {Object.entries(selectedOptions || {})?.map((item) => (
          <>
            {item[1]?.flat()?.length > 0 &&
              item[1]?.flat()?.map((x) => (
                <div
                  className={styles.tags}
                  onClick={() => clearFilter(item[0], x)}
                >
                  <p>{x}</p>
                  <img src="/Image/apps/profile-resume-edit-view/cross.svg" />
                </div>
              ))}
          </>
        ))}
      </div>

      <div className={styles.search_header}>
      <IconBgTitle icon={"/Image/otw/search results.svg"} title={t("enterpriseDashboard.searchResult")} />
      </div>

      <div className={styles.middle_container}>
        <div className={styles.search_section}>
          {filterResult?.length > 0 ? (
            filterResult
              ?.slice(indexOfFirstItem, indexOfLastItem)
              ?.map((item) => (
                <ProfileSearchResultComponent
                  key={item?.id}
                  id={item?.id}
                  name={item?.name || ""}
                  profilePic={item?.profile_pic || ""}
                  designation={item?.designation || ""}
                  state={item?.state || ""}
                  country={item?.country || ""}
                />
              ))
          ) : (
            <div className={styles.noResults}>
              {/* <p>{t("openToWork.noResultsFound")}</p> */}
              <img src="Image/otw/no profile found illustration.svg" />
            </div>
          )}

          {filterResult?.length > 0 && (
            <div className={styles.pagination_container}>
              <Pagination
                count={Math.ceil(filterResult?.length / 10) || 1}
                page={page}
                onChange={(event, value) => {
                  setPage(parseInt(value));
                }}
              />
            </div>
          )}
        </div>

        <div className={styles.connections_section}>
          <IconBgTitle icon={"/Image/otw/search results.svg"} title={t("dashboard.myConnections")} />
          <ConnectionRightFeedComp showIcon={true} />
        </div>
      </div>

      {/* <ProfileFrame imageUrl={"https://github.com/shadcn.png"} /> */}
    </div>
  );
};

export default Opportunities;
