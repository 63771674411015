import React from "react";
import styles from "./ProfileSearchResultComponent.module.css";
import { useTranslation } from "react-i18next";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useWindowWidth from "../../hooks/useWindowWidth";

const ProfileSearchResultComponent = (props) => {
  const { t } = useTranslation();
  const history  = useHistory()

  const width = useWindowWidth();

  const isMobile = width <= 800; 

  console.log("inside profileSearch", props, width)
  return (
    <div className={styles.main}>
      {/* <div className={styles.left_container}>
        <img src={props.profilePic} />
      </div> */}
      <Avatar src={props.profilePic || ""} />
      <div className={styles.right_container}>
        <div>
          <p className={styles.name}>{props.name}</p>
          <p className={styles.designation}>{props.designation}</p>
          <p className={styles.location}>
            {props.state
              ? `${props.state}, ${props.country}`
              : `${props.country}`}{" "}
          </p>
        </div>

        {isMobile ? <img  onClick={()=>history.push(`/profile/${props.id}`)} src="/Image/otw/View profile mobile.svg" className={styles.view_icon} /> : 
        <p onClick={()=>history.push(`/profile/${props.id}`)} className={styles.button}>{t("header.viewProfile")}</p>
        }

        


      </div>
    </div>
  );
};

export default ProfileSearchResultComponent;

