import React from "react";
import RightFeed from "../RightFeed";

import styles from "../../../../Profile.module.css";

export default class HamburgerView extends React.Component {
  render() {
    return (
      <div
        id={styles.hamburger_card}
        style={{
          backgroundColor: "#fff",
          height: "100%",
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <div id={styles.header} style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p Name={styles.modal_title}></p>
          </div>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => {
              this.props.changeEdit("none");
              document.body.style.overflow = "visible";
            }}
          >
            <img src="/Image/apps/profile-resume-edit-view/cross.svg" height={30} />
          </div>
        </div>

        <div
          id={styles.scroll_div}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <RightFeed hamburgerDisplay viewMyPosts={this.props.viewMyPosts} closeHamburgerMenu={() => {
              this.props.changeEdit("none");
              document.body.style.overflow = "visible";
            }} />
          </div>
        </div>
      </div>
    );
  }
}
