import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ModalWithoutHeader } from "../shared/ui/Modal/Modal";
import styles from "./css/welcome.module.css";

export default class WelcomeModal extends Component {
  render() {
    return (
      <div>
        <ModalWithoutHeader maxWidth={this.props.maxWidth} closeHandler={this.props.closeModal} show={this.props.show}>
          <div className={styles.outerContainer}>
            <div className={styles.comingSoon}>
              <p>
                {" "}
                Welcome to <br /> Mathematics
              </p>
            </div>
            <span onClick={this.props.closeModal} className={styles.close}>
              X
            </span>
            <div className={styles.bottomContainer}>
              <p style={{ font: "normal normal normal 24px/29px Montserrat", textAlign: "left", color: "#000000", paddingBottom: 10 }}>What's next?</p>
              <p style={{ font: "normal normal normal 14px/18px Montserrat", textAlign: "left", color: "#707070", paddingBottom: 30 }}>
                We recommend adding students now so they can start participating in your class.
              </p>

              <div className={styles.bottom_details}>
                <img src="./Image/DashImage/add-student.svg" style={{ height: 26, width: 26, paddingRight: 10 }} />
                <div>
                  <p style={{ font: "normal normal normal 18px/22px Montserrat", textAlign: "left", color: "#000000" }}>Add students</p>
                  <p style={{ font: "normal normal normal 14px/18px Montserrat", textAlign: "left", color: "#707070", paddingBottom: 30 }}>
                    Create accounts of your students or invite them to you class.
                  </p>
                </div>
              </div>

              <Link to="/class" style={{ textDecoration: "none", color: "inherit" }}>
                <div className={styles.bottom_details}>
                  <img src="./Image/DashImage/explore.svg" style={{ height: 26, width: 26, paddingRight: 10 }} />
                  <div>
                    <p style={{ font: "normal normal normal 18px/22px Montserrat", textAlign: "left", color: "#000000" }}>Explore class</p>
                    <p style={{ font: "normal normal normal 14px/18px Montserrat", textAlign: "left", color: "#707070", paddingBottom: 30 }}>
                      Check out class features before adding your students.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className={styles.bottom} style={{ backgroundColor: "#ED1E24", height: 54 }}></div>
          </div>
        </ModalWithoutHeader>
      </div>
    );
  }
}
