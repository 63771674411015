import {
  ADD_PRIVATE_COMMENT,
  DISPLAY_PRIVATE_COMMENT_QUIZ,
  CREATE_CLASS_QUIZ,
  DELETE_CLASS_QUIZ,
  GET_ALL_FILTERED_QUIZ_SUBMISSIONS,
  // GET_ALL_QUIZ_SUBMISSIONS,
  GET_INDIVIDUAL_QUIZ,
  GET_INDIVIDUAL_QUIZ_SUBMISSIONS,
  GET_QUIZ_LIST,
  GET_QUIZ_STUDENT_LIST,
  GET_STUDENT_INDIVIDUAL_QUIZ,
  GET_STUDENT_QUIZ_SUBMISSION,
  REMOVE_STUDENT_SUBMISSION,
  SAVE_QUIZ_MARKS,
  SUBMIT_QUIZ_SOLUTION,
  UPDATE_CLASS_QUIZ,
  UPDATE_STUDENT_QUIZ_SOLUTION,
  RETURN_QUIZ_MARKS,
  GET_STUDENT_QUIZ_LIST,
  PROGRESS_BAR_COUNT_QUIZ,
  IS_LOADING_QUIZ,
  CLEAR_QUIZ_SUBMISSION
} from "../constants/actionTypes";
import cloneDeep from "lodash.clonedeep";

const defaultState = {
  quizzes: [],
  quizList: [],
  quizInd: {},
  comments: [],
  student_list: [],
  graded_quiz: [],
  quiz_submission: {},
  quiz_submissions: [],
  filtered_quiz_submissions: [],
  progressBarCountQuiz: 0,
  isLoading: false,
};

let quizData = {};
let index = 0;

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_QUIZ_LIST:
      quizData = { ...state };
      quizData.quizzes = action.payload;
      return quizData;
    case GET_STUDENT_INDIVIDUAL_QUIZ:
      quizData = { ...state };
      quizData.quizInd = action.payload;
      return quizData;
    case REMOVE_STUDENT_SUBMISSION:
      quizData = { ...state };
      quizData.submission = action.payload;
      return quizData;
    case SUBMIT_QUIZ_SOLUTION:
      quizData = { ...state };
      quizData.submission = action.payload;
      return quizData;
    case UPDATE_STUDENT_QUIZ_SOLUTION:
      quizData = { ...state };
      quizData.submission = action.payload;
      return quizData;
    case ADD_PRIVATE_COMMENT:
      quizData = { ...state };
      quizData.comments.push(action.payload);
      return quizData;
    case DISPLAY_PRIVATE_COMMENT_QUIZ:
      quizData = { ...state };
      quizData.comments = action.payload;
      return quizData;
    case CREATE_CLASS_QUIZ:
      quizData = { ...state };
      quizData.quizzes.push(action.payload);
      return quizData;
    case GET_INDIVIDUAL_QUIZ:
      quizData = { ...state };
      quizData.quizInd = action.payload;
      return quizData;
    case UPDATE_CLASS_QUIZ:
      quizData = { ...state };
      index = quizData.quizzes.findIndex(function (quiz) {
        return quiz.id === action.payload.id;
      });
      if (index === -1) {
        return quizData;
      }
      quizData.quizzes[index] = {
        ...quizData.quizzes[index],
        ...action.payload,
      };
      return quizData;
    case DELETE_CLASS_QUIZ:
      quizData = { ...state };
      index = quizData.quizzes.findIndex(function (quiz) {
        return quiz.id === action.payload;
      });
      if (index !== -1) {
        quizData.quizzes.splice(index, 1);
      }
      return quizData;
    case GET_QUIZ_STUDENT_LIST:
      quizData = { ...state };
      quizData.student_list = action.payload;
      return quizData;
    case SAVE_QUIZ_MARKS:
      quizData = { ...state };
      quizData.graded_quiz.push(action.payload);
      return quizData;
    case GET_STUDENT_QUIZ_SUBMISSION:
      quizData = { ...state };
      quizData.submission = action.payload;
      return quizData;
    case CLEAR_QUIZ_SUBMISSION:
      // quizData = { ...state };
      quizData = cloneDeep(state);
      quizData.submission = {};
      quizData.comments = [];
      return quizData;
    // case GET_ALL_QUIZ_SUBMISSIONS:
    //   quizData = { ...state };
    //   quizData.quiz_submissions = action.payload;
    //   return quizData;
    case GET_INDIVIDUAL_QUIZ_SUBMISSIONS:
      quizData = { ...state };
      quizData.quiz_submission = action.payload;
      return quizData;
    case GET_ALL_FILTERED_QUIZ_SUBMISSIONS:
      quizData = { ...state };
      quizData.filtered_quiz_submissions = action.payload;
      return quizData;

    case RETURN_QUIZ_MARKS:
      quizData = { ...state };
      let oldStudentList = quizData.student_list;
      let oldFilteredQuizSubmissionList = quizData.filtered_quiz_submissions;
      let submissionIdList = action.payload;

      submissionIdList.map((subID) => {
        let index = oldStudentList.map((item) => item.id).indexOf(subID);
        oldStudentList[index].submission_status = "GRD";

        index = oldFilteredQuizSubmissionList
          .map((item) => item.id)
          .indexOf(subID);
        oldFilteredQuizSubmissionList.splice(index, 1);
      });

      quizData.student_list = oldStudentList;
      quizData.filtered_quiz_submissions = oldFilteredQuizSubmissionList;
      return quizData;

    case GET_STUDENT_QUIZ_LIST:
      quizData = { ...state };
      quizData.quizList = action.payload;
      return quizData;

    case PROGRESS_BAR_COUNT_QUIZ:
      quizData = { ...state };
      quizData.progressBarCountQuiz = action.payload;
      return quizData;

    case IS_LOADING_QUIZ:
      quizData = { ...state };
      quizData.isLoading = action.payload;
      return quizData;

    default:
      return { ...state };
  }
};
