import { Avatar } from "@material-ui/core";
import React, { useState } from "react";
import styles from "./CSSFiles/MessageSender.module.css";
import InputOptionFromSource from "./InputOptionFromSource";
import { useSelector } from "react-redux";
// import AddPhotoAlternateIcon from "./Image/DashboardImages/DashCommonImages/Chalkmate_Addphoto.svg";
// import LibraryAddIcon from "./Image/DashboardImages/DashCommonImages/Chalkmate_Addfromlibrary.svg";
// import VisibilityIcon from './Image/DashboardImages/DashCommonImages/Chalkmate_Visibility.svg';
// import VideoIcon from './Image/DashboardImages/DashCommonImagesChalkmate_Video.svg';
// import ArticleIcon from './Image/DashboardImages/DashCommonImages/Chalkmate_Article.svg';
import DiscussionModal from "../postModal/DiscussionModal";
import { useTranslation } from "react-i18next";
// import Popup from "./Popup";

function ClassMessageSender({ routeType }) {
  const [input, setInput] = useState("");
  const [showModal, toggleModal] = useState(false);
  const profilePicUrl = useSelector((state) => state.profile.userProfilePic);
  const {t} = useTranslation()

  // const handleSubmit = e => {
  //   e.preventDefault();

  //   setInput("");
  // };
  const OpenModal = () => {
    // alert("works")
    // this.setState({showModal:true})
    toggleModal(true);
  };

  const closeModal = () => {
    toggleModal(false);
  };
  return (
    <div className={styles.MessageSender}>
      <div className={styles.messageSender_top}>
        <div className={styles.message_input}>
          <DiscussionModal showModal={showModal} closeModal={closeModal} routeType={routeType} />
          <img src={profilePicUrl || "/Image/DashImage/profile.png"} style={{ width: 50, height: 50, borderRadius: "100%" }} />
          <form onClick={OpenModal}>
            <input
              // value={input}
              // onChange={e => setInput(e.target.value)}
              // onClick={<Popup />}
              
              className={styles.input}
              placeholder={t("class.startDiscussion")}
            />
            {/* <button
              onClick={handleSubmit}
              type="submit"
              style={{ color: "blue" }}
            >
              <b>SHARE</b>
            </button> */}
          </form>
        </div>
        <hr className={styles.messageSenderDivider} />
        <div className={styles.messageSender_bottom}>
          <div onClick={OpenModal} className={styles.messageSender_rightDiv}>
            <InputOptionFromSource Icon="/Image/DashboardImages/DashCommonImages/Chalkmate_Addphoto.svg" title={t("common.photo")} />
            <InputOptionFromSource Icon="/Image/DashboardImages/DashCommonImages/Chalkmate_Video.svg" title={t("common.video")} />
            <InputOptionFromSource Icon="/Image/DashboardImages/DashCommonImages/file.svg" title={t("common.files")} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassMessageSender;
