import React from 'react';
import styles from "./css/PublicCourseCard.module.css";
import CourseTitle from './CourseTitle';
import InstructorName from './InstructorName';
import StudentCount from './StudentCount';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from "../../../../src/components/assignmentAndQuiz/Button/Button"
import ReactHtmlParser from 'react-html-parser';
import CourseLength from './CourseLength';

const PublicCourseCard = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const handleEnrollOrView = () => {
        history.push({
          pathname: `/course/view/${props?.id}`
        })
      }

    return (
        <div className={styles.publicCourseCardContainer} onClick={handleEnrollOrView}>
                <div className={styles.imageContainer}>
                    <img src={props?.image} alt="" className={styles.courseImage} />
                </div>
                <div className={styles.smallContanier}>
                    <div id={styles.courseTitle}><CourseTitle title={props?.title} /></div>
                    <div id={styles.descCont}><span className={styles.description}>{ReactHtmlParser(props?.description)}</span></div>
                    <div id={styles.instructor}><InstructorName instructor={props?.instructor} /></div>
                    <StudentCount count={props.studentCount} />
                    <CourseLength courseLength={props.courseLength ?? 0} lectureCount={props.lectureCount ?? 0}/>
                    <div className={styles.priceAndEnrollContainer}>
                        <span className={styles.publicCoursePrice}>{props?.price > 0 ? `$${props?.price}`  : t("common.free") }</span>
                        {/* <button onClick={handleEnrollOrView} className={styles.publicCourseButton}>{props.isEnrolled ? t("common.view") : t("courseMonetisation.enroll")}</button> */}
                        <Button 
                        type="solid"
                        label={props.isEnrolled ? t("common.view") : t("courseMonetisation.enroll")}
                        clickHandler={handleEnrollOrView}
                        disable={!props.isActive && !props.isEnrolled ? true : false}
                        />
                    </div>
                </div>
            </div>
    )    
}

export default PublicCourseCard;