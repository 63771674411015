import { CHANGE_GROUP_ACTIVE_SECTION, DELETE_GROUP, GET_GROUPS, GET_GROUP_DETAILS, GET_GROUP_POST, POST_GROUP_DETAILS, POST_GROUP_MISSION, PUT_GROUP_DETAILS, UPDATE_GROUP_COVER, GET_ALL_GROUP_OWNER_CODES, } from "../constants/actionTypes";

const defaultState = {
  groups: [],
  group: {},
  courses: [],
  members: [],
  posts: [],
  meetings:[],
  groupOwners: [],
};
let groupData = {};
let index = 0;
export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_GROUPS:
      groupData = { ...state };
      groupData.groups = action.payload;
      return groupData;
    case GET_GROUP_DETAILS:
      groupData = {
        groups: [...state.groups],
        group: { ...state.group },
        courses: state.courses.map((course) => {
          return { ...course };
        }),
        members: [...state.members],
        posts: [...state.posts],
      };
      groupData.group = action.payload.group;
      groupData.members = action.payload.members;
      // groupData.posts = action.payload.posts;
      groupData.courses = action.payload.courses;
      groupData.meetings = action.payload.meetings;
      return groupData;
    case POST_GROUP_DETAILS:
      return { ...state };
    case PUT_GROUP_DETAILS: 
      groupData = {...state}
    index = groupData.groups.findIndex(function(groupss){
      return groupss.id === action.payload.id;
   })
   groupData.groups[index] = {...groupData.groups[index], ...action.payload}
   return groupData;
    case DELETE_GROUP:
      groupData = {...state};
        index = groupData.groups.findIndex(function(group){
        return group.id === action.payload;
   })
   if (index !== -1) groupData.groups.splice(index, 1);
      
  return groupData;

  case GET_GROUP_POST:
    groupData = {...state}
    groupData.posts = action.payload
    return groupData;
  
  case POST_GROUP_MISSION:
    groupData = {...state}
    groupData.group.groupVision = action.payload
    return groupData;
  
  case UPDATE_GROUP_COVER:
    groupData = { ...state };
    groupData.group.cover_image = action.payload
    return groupData;
  
  case GET_ALL_GROUP_OWNER_CODES:
    groupData = {...state};
    groupData.groupOwners = action.payload;
    return groupData;
  
    default:
      return { ...state };
  }
};
