import {
  GET_FACULTYDASHBOARD_FACULTY,
  GET_FACULTYDASHBOARD_CLASS_STUDENT,
  GET_FACULTYDASHBOARD_CLASS,
  GET_FACULTYDASHBOARD_GROUP,
  GET_FACULTYDASHBOARD_CLASS_ASSIGNMENT,
  GET_FACULTYDASHBOARD_CLASS_QUIZ,
  GET_FACULTYDASHBOARD_CLASS_POST,
  GET_FACULTYDASHBOARD_CLASS_COURSE,
  GET_FACULTYDASHBOARD_GROUP_ASSIGNMENT,
  GET_FACULTYDASHBOARD_GROUP_POST,
  GET_FACULTYDASHBOARD_DATA_COUNT,
  GET_FACULTYDASHBOARD_CHART_DATA,
  GET_FACULTYDASHBOARD_SEARCH_RESULT,
  GET_FACULTYDASHBOARD_CLASS_LIST,
  GET_FACULTYDASHBOARD_GROUP_LIST,
  GET_FACULTYDASHBOARD_GROUP_MEMBER,
  CLEAR_FACULTYDASHBOARD,
} from "../constants/actionTypes";

import axios from "axios";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import { errorMessageFromResponse } from "../constants/commonFunctions";

import {
  GET_FACULTY_DASHBOARD_CLASSES_API,
  GET_FACULTY_DASHBOARD_CLASS_ASSIGNMENT_API,
  GET_FACULTY_DASHBOARD_CLASS_COURSE_API,
  GET_FACULTY_DASHBOARD_CLASS_QUIZ_API,
  GET_FACULTY_DASHBOARD_GROUPS_API,
  GET_FACULTY_DASHBOARD_CLASS_STUDENT_API,
  GET_FACULTY_DASHBOARD_GROUP_MEMBERS_API,
  GET_FACULTY_DASHBOARD_CLASS_POSTS_API,
  GET_FACULTY_DASHBOARD_GROUP_POSTS_API,
  GET_DASHBOARD_DATA_API,
} from "../constants/apis";

// --------- types -----------

// dashboard_main
// dashboard_class
// dashboard_class_student
// dashboard_class_post
// dashboard_class_assignment
// dashboard_class_quiz

// dashboard_group
// dashboard_group_member
// dashboard_group_post

// ----------- get Faculty Enterprise Classes and Groups -----

export const getFacultyStudentDashboardClassesAndGroups = (
  type,
  deptId,
  userId
) => {
  console.log("insideFacultyAction", type, deptId, userId);
  const token = store.getState()?.profile?.user?.token;
  let url = "";

  if (type === "dashboard_class") {
    url = `${GET_FACULTY_DASHBOARD_CLASSES_API}?dept_id=${deptId}&user_id=${userId}`;
  }
  if (type === "dashboard_group") {
    url = `${GET_FACULTY_DASHBOARD_GROUPS_API}?dept_id=${deptId}&user_id=${userId}`;
  }

  if (!deptId || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if (type === "dashboard_class") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_LIST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group") {
            dispatch({
              type: GET_FACULTYDASHBOARD_GROUP_LIST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// -------------------- Assignment Quiz -------------------

export const getFacultyStudentDashboardClassAssignments = (
  type,
  deptId,
  userId
) => {
  console.log("insideFacultyAction", type, deptId, userId);
  const token = store.getState()?.profile?.user?.token;

  let url = `${GET_FACULTY_DASHBOARD_CLASS_ASSIGNMENT_API}?dept_id=${deptId}&user_id=${userId}`;

  if (!deptId || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          dispatch({
            type: GET_FACULTYDASHBOARD_CLASS_ASSIGNMENT,
            payload: response?.data?.data,
          });
          dispatch({
            type: GET_FACULTYDASHBOARD_DATA_COUNT,
            payload: response?.data?.count,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

export const getFacultyStudentDashboardClassQuiz = (type, deptId, userId) => {
  console.log("insideFacultyAction", type, deptId, userId);
  const token = store.getState()?.profile?.user?.token;

  let url = `${GET_FACULTY_DASHBOARD_CLASS_QUIZ_API}?dept_id=${deptId}&user_id=${userId}`;

  if (!deptId || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          dispatch({
            type: GET_FACULTYDASHBOARD_CLASS_QUIZ,
            payload: response?.data?.data,
          });
          dispatch({
            type: GET_FACULTYDASHBOARD_DATA_COUNT,
            payload: response?.data?.count,
          });
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ----------------- get ClassStudent / GroupMembers ----------------------

export const getFacultyStudentDashboardClassStudentOrGroupMembers = (
  type,
  deptId,
  userId
) => {
  console.log("insideFacultyAction", type, deptId, userId);
  const token = store.getState()?.profile?.user?.token;
  let url = "";

  if (type === "dashboard_class_student") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_STUDENT_API}?dept_id=${deptId}&user_id=${userId}`;
  }

  if (type === "dashboard_group_member") {
    url = `${GET_FACULTY_DASHBOARD_GROUP_MEMBERS_API}?dept_id=${deptId}&user_id=${userId}`;
  }

  if (!deptId || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if (type === "dashboard_class_student") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_STUDENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }

          if (type === "dashboard_group_member") {
            dispatch({
              type: GET_FACULTYDASHBOARD_GROUP_MEMBER,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ---------------------- Filter by class or group ---------------------

export const getFilteredContent = (
  type,
  deptId,
  userId,
  classGroupId
) => {
  console.log("insideFilteredAc", type, deptId, classGroupId);
  const token = store.getState()?.profile?.user?.token;
  let url = "";

  // ----- class url -----
  if (type === "dashboard_class_student") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_STUDENT_API}?dept_id=${deptId}&user_id=${userId}&class_id=${classGroupId}`;
  }
  if (type === "dashboard_class_assignment") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_ASSIGNMENT_API}?dept_id=${deptId}&user_id=${userId}&class_id=${classGroupId}`;
  }
  if (type === "dashboard_class_quiz") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_QUIZ_API}?dept_id=${deptId}&user_id=${userId}&class_id=${classGroupId}`;
  }
  if (type === "dashboard_class_post") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_POSTS_API}?dept_id=${deptId}&user_id=${userId}&class_id=${classGroupId}`;
  }
  if (type === "dashboard_class_course") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_COURSE_API}?dept_id=${deptId}&user_id=${userId}&class_id=${classGroupId}`;
  }


  // ----- group url -----
  if (type === "dashboard_group_member") {
    url = `${GET_FACULTY_DASHBOARD_GROUP_MEMBERS_API}?dept_id=${deptId}&user_id=${userId}&group_id=${classGroupId}`;
  }

  if (type === "dashboard_group_post") {
    url = `${GET_FACULTY_DASHBOARD_GROUP_POSTS_API}?dept_id=${deptId}&user_id=${userId}&group_id=${classGroupId}`;
  }

  if (!deptId || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {

          if (type === "dashboard_class_student") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_STUDENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_assignment") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_ASSIGNMENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_quiz") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_QUIZ,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_post") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_course") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_COURSE,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group_member") {
            dispatch({
              type: GET_FACULTYDASHBOARD_GROUP_MEMBER,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group_post") {
            dispatch({
              type: GET_FACULTYDASHBOARD_GROUP_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};


// ---------------------- Search Result ---------------------

export const getEnterpriseFacultySearchResult = (
  type,
  deptId,
  classGroupId,
  userId,
  searchQuery
) => {
  console.log("insideSearchResult FacultyEnt", type, deptId, searchQuery);
  const token = store.getState()?.profile?.user?.token;
  let url = "";

  // ----- class url ----
  if (type === "dashboard_class_student") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_STUDENT_API}?dept_id=${deptId}&user_id=${userId}&search=${searchQuery}`;
  }
  if (type === "dashboard_class_assignment") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_ASSIGNMENT_API}?dept_id=${deptId}&user_id=${userId}&search=${searchQuery}`;
  }
  if (type === "dashboard_class_quiz") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_QUIZ_API}?dept_id=${deptId}&user_id=${userId}&search=${searchQuery}`;
  }
  if (type === "dashboard_class_post") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_POSTS_API}?dept_id=${deptId}&user_id=${userId}&search=${searchQuery}`;
  }
  if (type === "dashboard_class_course") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_COURSE_API}?dept_id=${deptId}&user_id=${userId}&search=${searchQuery}`;
  }


  // ----- group url ---
  if (type === "dashboard_group_member") {
    url = `${GET_FACULTY_DASHBOARD_GROUP_MEMBERS_API}?dept_id=${deptId}&user_id=${userId}&search=${searchQuery}`;
  }

  if (type === "dashboard_group_post") {
    url = `${GET_FACULTY_DASHBOARD_GROUP_POSTS_API}?dept_id=${deptId}&user_id=${userId}&search=${searchQuery}`;
  }

  if (!deptId || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  // add classGroupId to url
  url = !classGroupId ? url : `${url}&${
    type.includes("class") ? "class_id" : "group_id"
  }=${classGroupId}`

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          if (type === "dashboard_class_student") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_STUDENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }

          if (type === "dashboard_class_assignment") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_ASSIGNMENT,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_quiz") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_QUIZ,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_post") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_class_course") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_COURSE,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group_member") {
            dispatch({
              type: GET_FACULTYDASHBOARD_GROUP_MEMBER,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group_post") {
            dispatch({
              type: GET_FACULTYDASHBOARD_GROUP_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// ---------- classPost and groupPost -----------

export const getFacultyStudentDashboardClassGroupPost = (
  type,
  deptId,
  userId
) => {
  
  const token = store.getState()?.profile?.user?.token;

  let url = "";

  if (type === "dashboard_class_post") {
    url = `${GET_FACULTY_DASHBOARD_CLASS_POSTS_API}?dept_id=${deptId}&user_id=${userId}`;
  }

  if (type === "dashboard_group_post") {
    url = `${GET_FACULTY_DASHBOARD_GROUP_POSTS_API}?dept_id=${deptId}&user_id=${userId}`;
  }


  if (!deptId || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          
          if(type === "dashboard_class_post") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }

          if(type === "dashboard_group_post") {
            dispatch({
              type: GET_FACULTYDASHBOARD_GROUP_POST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

// -------------- mainDashboard and Chart Data -------------

export const getFacultyStudentMainDashboard = (
  type,
  deptId,
  userId
) => {
  
  const token = store.getState()?.profile?.user?.token;

  let url = "";

  if (type === "dashboard_main") {
    url = `${GET_DASHBOARD_DATA_API}ent-dashboard/?dept_id=${deptId}&user_id=${userId}&is_enterprise_admin=false`
  }


  if (!deptId || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {
          
          if(type === "dashboard_main") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CHART_DATA,
              payload: response?.data?.data,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

 export const clearFacultyDashboard = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_FACULTYDASHBOARD,
    });
  }
}

// ---------------------------------- getActiveInactive Filter result ------------

export const getActiveInactiveFilteredContent = (
  type,
  deptId,
  userId,
  isActive
) => {
  console.log("insideFilteredAc", type, deptId, isActive);
  const token = store.getState()?.profile?.user?.token;
  let url = "";

  if (type === "dashboard_class") {
    url = `${GET_FACULTY_DASHBOARD_CLASSES_API}?dept_id=${deptId}&user_id=${userId}&is_active=${isActive}`;
  }
  if (type === "dashboard_group") {
    url = `${GET_FACULTY_DASHBOARD_GROUPS_API}?dept_id=${deptId}&user_id=${userId}&is_active=${isActive}`;
  }

  if (!deptId || !type) {
    return async (dispatch) => dispatch({ type: "nowhere" });
  }

  return async (dispatch) => {
    await axios
      .get(url, {
        headers: {
          "content-type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (
          response?.status >= 200 &&
          response?.status < 300 &&
          response?.data?.status == "1"
        ) {

          if (type === "dashboard_class") {
            dispatch({
              type: GET_FACULTYDASHBOARD_CLASS_LIST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
          if (type === "dashboard_group") {
            dispatch({
              type: GET_FACULTYDASHBOARD_GROUP_LIST,
              payload: response?.data?.data,
            });
            dispatch({
              type: GET_FACULTYDASHBOARD_DATA_COUNT,
              payload: response?.data?.count,
            });
          }
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
};

