import React from "react";
import styles from "./ChatBubble.module.css";
import moment from "moment";

const ChatBubble = ({ messsage, isSender, timestamp }) => {
  const time = moment(timestamp).format("hh:mmA");
  return (
    <div className={isSender ? styles.sender : styles.main}>
      <p className={styles.message}>{messsage}</p>
      <div className={styles.date_wrap}>
        <p>{time}</p>
      </div>
    </div>
  );
};

export default ChatBubble;
