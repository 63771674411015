import {React, useEffect, useState} from 'react';
import styles from './css/PublicCourses.module.css';
import PublicCourseCard from '../Components/PublicCourseCard';
import { useTranslation } from 'react-i18next';
// import PublicCourseCards from '../Components/PublicCourseCards.json';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCourses, clearCourses} from '../../../redux/actions/courses';
import { CircularProgress } from "@mui/material";
import { thumbnailList } from '../../../redux/constants/commonFunctions';

const PublicCourses = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const publicCourses = useSelector((state)=> state?.courses?.publicCourses) || [];
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(getAllCourses(null, true)).then(()=>setIsLoading(false));
        return () => {
            dispatch(clearCourses());
          }
    }, [])

    return (
        <>
            {isLoading ? (
                <div className={styles.loader_style}>
                    <CircularProgress sx={{color: "#ED1E24"}} size={50} />
                </div>
            ) : 
                <div className={styles.CoursesContainer}>
                <div className={styles.CourseHeadingContainer}>
                 <img src="/Image/CourseMonetisation/publicCourse.svg" alt="" />
                  <div className={styles.CourseHeading}>{t("courseMonetisation.publicCourses")}</div>
                </div>
                
            <div className={styles.CourseCardsVerticalScroll}>
                { publicCourses.map((course, index) => (
                    <PublicCourseCard 
                        key={course?.id} 
                        id={course?.id} 
                        image={course?.thumbnail ?? thumbnailList[index % 13]} 
                        title={course?.name} 
                        instructor={course?.owner.name} 
                        description={course?.description} 
                        studentCount={course?.members_count} 
                        price={course?.price} 
                        isEnrolled={course?.is_enrolled}
                        isActive={course?.active}
                        lectureCount={course?.total_lecs}
                        courseLength={course?.course_mins}
                    />))}
                 </div>
             </div>   
                     
           }
        </>
        
    )
    

    
}

export default PublicCourses;