import React, { useState, useEffect } from "react";
import styles from "./ProjectFeed.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import Modal from "../../../../../components/commons/Modal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import AddProjectModal from "./Modals/AddProjectModal";
import { useDispatch, useSelector } from "react-redux";
import { toggleSectionVisibility } from "../../../../../redux/actions/profile";
import TooltipWrapper from "../../../../CourseMonetization/Components/TooltipWrapper";

const ProjectFeed = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [expand, setExpand] = React.useState(false);
  const [showModal, setShowModal] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editTarget, setEditTarget] = useState(null);

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const isProjectFeedVisible = useSelector((state) => state?.profile?.project_visibility);

  const [isVisible, setIsVisible] = useState(isProjectFeedVisible);

  // "owner" is for the logged in user, "view" is for the user who's profile is being viewed by the logged in user
  const storedProjectOwner = useSelector((state) => state?.profile?.project);
  const storedProjectView = useSelector((state) => state?.profile?.projectView);

  const project = props.isEditPage ? storedProjectOwner : storedProjectView;

  const profileId = useSelector((state) => state?.profile?.profileId);

  const handleToggle = (event) => {
    setIsLoadingBtn(true);
    setIsVisible(event.target.checked);
    let data = new FormData();
    data.append("project_visibility", event.target.checked);
    
    dispatch(toggleSectionVisibility(data, profileId))
    .then(()=>setIsLoadingBtn(false));
  };

  useEffect(() => {
    setIsVisible(isProjectFeedVisible);
  }, [isProjectFeedVisible])

  const styleSwitch = {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ED1E24",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#ED1E24",
    },
  };

  const closeModal = () => {
    setIsEditing(false);
    setEditTarget(null);
    setShowModal(false);
  }


  return (
    <div className={styles.main}>
      {showModal && (
        <Modal 
          card={
            <AddProjectModal 
              onClose={closeModal}
              isEditing={isEditing}
              targetId={editTarget?.id}
              title={editTarget?.name}
              description={editTarget?.description}
              startDate={editTarget?.start_date}
              endDate={editTarget?.end_date}
              image={editTarget?.image}
            />} 
        />
      )}
      <div className={styles.header}>
        <IconTitle
          title={t("profile.consultancyAndProjects")}
          img="/Image/profile_page/Consultancy.svg"
        />
        {props.isEditPage && 
          <TooltipWrapper title={t("profile.invisibleTooltipMessage")}>        
            <Switch
              checked={isVisible}
              disabled={isLoadingBtn}
              sx={styleSwitch}
              onChange={handleToggle}
              inputProps={{ "aria-label": "controlled" }}
            />
          </TooltipWrapper>}
      </div>

      <div className={styles.card}>
        {props.isEditPage && <>
        <p className={styles.description}>
          {t("profile.addConsultancyAndProjects")}
        </p>
        <button className={styles.add_btn} onClick={() => setShowModal(true)}>
          <p className={styles.btn_label}>
            &#65291; {t("profile.addConsultancyAndProjects")}
          </p>
        </button>
        </>}

        {console.log("isEditPage", props.isEditPage)}

        {project?.length > 0 ?
          project?.map((item) => (
            <div className={styles.publication_container} key={item?.id}>
              <img src={item?.image || "/Image/apps/profile-resume-edit-view/default-certificate.png"} className={styles.media} />
              <div className={styles.text_wrapper}>
                <div className={styles.title_header}>
                  <p className={styles.title}>{item?.name}</p>
                  {props.isEditPage && <img
                    src="/Image/Chalkmate_Edit.svg"
                    onClick={() => {
                      setEditTarget(item);
                      setIsEditing(true);
                      setShowModal(true);
                    }}
                  />}
                </div>
                <p className={styles.publisher_and_date}>
                  {item?.start_date} &ndash; {item?.end_date}
                </p>

                <p className={styles.description}>{item?.description}</p>
              </div>
            </div>
          )) : <p>{t("profile.noInfoFound")}</p>}
      </div>
    </div>
  );
};

export default ProjectFeed;
