import React, { useState, useEffect } from "react";
import styles from "./PublicationFeed.module.css";
import IconTitle from "../../../../../components/commons/IconTitle";
import { useTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";
import AddPatentResearchTrainingModal from "./Modals/AddPatentResearchTrainingModal";
import Modal from "../../../../../components/commons/Modal";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { toggleSectionVisibility } from "../../../../../redux/actions/profile";
import TooltipWrapper from "../../../../CourseMonetization/Components/TooltipWrapper";

const PublicationFeed = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const [checked, setChecked] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [editTarget, setEditTarget] = useState(null);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const isPublicationVisible = useSelector((state) => state?.profile?.publications_visibility);

  const [isVisible, setIsVisible] = useState(isPublicationVisible);

  // "owner" is for the logged in user, "view" is for the user who's profile is being viewed by the logged in user
  const storedPublicationsOwner = useSelector((state) => state?.profile?.publications);
  const storedPublicationView = useSelector((state) => state?.profile?.publicationsView);

  const publications = props.isEditPage ? storedPublicationsOwner : storedPublicationView;

  const profileId = useSelector((state) => state?.profile?.profileId);

  const handleToggle = (event) => {
    setIsLoadingBtn(true);
    setIsVisible(event.target.checked);
    let data = new FormData();
    data.append("publications_visibility", event.target.checked);
    
    dispatch(toggleSectionVisibility(data, profileId))
    .then(()=>setIsLoadingBtn(false));
  };

  useEffect(() => {
    setIsVisible(isPublicationVisible);
  }, [isPublicationVisible])
  

  const styleSwitch = {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#ED1E24",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#ED1E24",
    },
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleSelection = (type) => {
    setModalType(type);
    setAnchorEl(null);
    setShowModal(true);
  };

  const closeModal = () => {
    setIsEditing(false);
    setEditTarget(null);
    setShowModal(false);
  }

  const styleMenuItem = {
    'color': '#000',
    'fontFamily': 'Montserrat',
    'fontWeight': '500',
    'fontSize': '0.875rem',
    '&:hover': {
      backgroundColor: '#FFCFD0',
   },
  }

  return (
    <div className={styles.main}>
      {showModal && (
        <Modal
          card={
            <AddPatentResearchTrainingModal
              onClose={closeModal}
              modalType={modalType}
              isEditing={isEditing}
              targetId={editTarget?.id}
              title={editTarget?.title}
              organisation={editTarget?.publication}
              date={editTarget?.publication_date}
              description={editTarget?.description}
              publicationType={editTarget?.pub_type}
              url={editTarget?.url}
              image={editTarget?.image}
            />
          }
        />
      )}

      <div className={styles.header}>
        <IconTitle
          title={t("profile.patentsAndPublications")}
          img="/Image/profile_page/patent (2).svg"
        />
        {props.isEditPage && 

        <TooltipWrapper title={t("profile.invisibleTooltipMessage")}>        
          <Switch
            checked={isVisible}
            disabled={isLoadingBtn}
            sx={styleSwitch}
            onChange={handleToggle}
            inputProps={{ "aria-label": "controlled" }}
          />
        </TooltipWrapper>
        
        }
      </div>
      <div className={styles.card}>
        {props.isEditPage && <>
        <p className={styles.description}>
          {t("profile.addPatentsAndPublications")}
        </p>
        <button
          className={styles.add_btn}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          >
          <p className={styles.btn_label}>
            &#65291; {t("profile.addPatentsAndPublications")}
          </p>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              boxShadow:'0px 0px 20px rgba(63, 63, 65, 0.102)',
              borderRadius: '1rem',
            }
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",            
          }}
          >
          <MenuItem sx={styleMenuItem} onClick={() => handleSelection("book")}>Book</MenuItem>
          <MenuItem sx={styleMenuItem} onClick={() => handleSelection("patent")}>Patent</MenuItem>
          <MenuItem sx={styleMenuItem} onClick={() => handleSelection("paper")}>Paper</MenuItem>
          <MenuItem sx={styleMenuItem} onClick={() => handleSelection("caseStudy")}>
            Case Study
          </MenuItem>
        </Menu>
        </>}

        {publications?.length > 0 ?
          publications?.map((item) => (
            <div className={styles.publication_container} key={item?.id}>
              <img src={item?.image || "/Image/apps/profile-resume-edit-view/default-certificate.png"} className={styles.media} />
              <div className={styles.text_wrapper}>
                <div className={styles.title_header}>
                  <p className={styles.title}>{item?.title}</p>
                  {props.isEditPage && <img
                    src="/Image/Chalkmate_Edit.svg"
                    onClick={() => {
                      setEditTarget(item);
                      setIsEditing(true);
                      setShowModal(true);
                      let type = item?.pub_type === "case_study" ? "caseStudy" : item.pub_type;
                      setModalType(type);
                    }}
                  />}
                </div>
                <p className={styles.publisher_and_date}>
                  {item?.publication} &#x2022; {item?.publication_date}
                </p>
                {item?.url && (
                  <div
                    className={styles.url}
                    onClick={() => window.open(item?.url, "_blank")}
                  >
                    {t(
                      item?.pub_type === "book"
                        ? "profile.showPublication"
                        : item?.pub_type === "patent"
                        ? "profile.showPatent"
                        : item?.pub_type === "paper"
                        ? "profile.showPaper"
                        : "profile.showCaseStudy"
                    )}
                    <img src="/Image/redirect.png" />
                  </div>
                )}

                <p className={styles.description}>{item?.description}</p>
              </div>
            </div>
          )) : <p>{t("profile.noInfoFound")}</p>}
      </div>

      {console.log("inside Pub", publications)}
      {console.log("isEditPage", props.isEditPage)}
    </div>
  );
};

export default PublicationFeed;
