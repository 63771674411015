import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";


export function fetchExperiences(action) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };
    return axios
      .get(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/experience/`, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch({
            type: action,
            payload: res.data.data,
          });
          return { val: true };
        }
        return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catchcatch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function addExperience(expState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("title", expState.title);
    data.append("description", expState.description);
    data.append("start_date", expState.start_date);
    data.append("end_date", expState.end_date);
    data.append("employment_type", expState.employment_type);
    data.append("company", expState.company);
    data.append("profile_id", JSON.parse(localStorage.getItem("userData")).profile_id);
    data.append("upload_img", expState.pictureAsFile, expState.pictureAsFile.name);

    // validations
    if (expState.start_date == null || expState.end_date == null) {
      return { val: false, message: "error at adding experience - Empty startdate and end date" };
    } else if (expState.start_date > expState.end_date) {
      return { val: false, message: "error at adding experience - start date must be less than end date" };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .post(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/experience/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Successfully added experience"))
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
        return { val: false, message: `Some error occured - ${res.data.message}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateExperience(expState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("title", expState.title);
    data.append("description", expState.description);
    data.append("start_date", expState.start_date);
    data.append("end_date", expState.end_date);
    data.append("employment_type", expState.employment_type);
    data.append("company", expState.company);
    if (expState.uploadImageFlag) {
      data.append("upload_img", expState.pictureAsFile, expState.pictureAsFile.name);
    }

    // validaitions
    if (expState.start_date == null || expState.end_date == null) {
      return { val: false, message: "error at adding experience - Empty upload image" };
    } else if (expState.start_date > expState.end_date) {
      return { val: false, message: "error at adding experience - start date must be less than end date" };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(`${process.env.REACT_APP_API_BASE_URL}api/v1/profile/experience/${expState.id}/`, data, { headers: headers })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          let value = Object.fromEntries(data.entries());
          if (expState.uploadImageFlag) {
            value = {...value, id: expState.id, upload_img: URL.createObjectURL(expState.pictureAsFile)}
          }
          else{
            value = {...value, id: expState.id, upload_img: expState.pictureAsFile}
          }
          dispatch(handleSuccessMessage("Updated successfully"))
          dispatch({
            type: action,
            payload: value,
          });
          return { val: true };
        }
        return { val: false, message: `Some error occured - ${res.data}` };
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
