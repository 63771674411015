
import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { store } from "../store";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";
import i18n from "../../i18nextConf";
import { DOWNLOAD_COURSE_CERTIFICATE_API } from "../constants/apis";

export const downloadCertificate = (id) => {
    const token = store.getState()?.profile?.user?.token;
    let lang = window.localStorage.getItem("i18nextLng");
    if (lang == "en-US") {
      lang = "en";
    }
    const endpoint = `${DOWNLOAD_COURSE_CERTIFICATE_API}`;

    let data = new FormData();
    data.append("course_id", id);
  
    return async (dispatch) => {
          await axios({
          method: "post",
          url: endpoint,
          data: data,
          responseType: 'blob',
          headers: {
            "content-type": "application/pdf",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (
            response.status >= 200 &&
            response.status < 300
          ) {
            const file = new Blob([response.data], { type: 'application/pdf' });  
            const fileURL = URL.createObjectURL(file);  
            window.open(fileURL,"_blank");
          }
        })
        .catch(async (error) => {
          dispatch(handleErrorMessage(errorMessageFromResponse(error)));
          return error;
        });
    };
  };