import React, { Component } from "react";
import styles from "./css/BottomContainer.module.css";
// import Photo from './Image/DashboardImages/postModImages/photo.svg'
// import Briefcase from './Image/DashboardImages/postModImages/briefcase.svg'
// import Video from './Image/DashboardImages/postModImages/video.svg'
// import Doc from './Image/DashboardImages/postModImages/document.svg'
import DropBox from "./DropBox";
import { withTranslation } from "react-i18next";
class BottomContainer extends Component {
  state = {
    selectedOption: "Anyone",
    options: ["Anyone", "Groups Members", "Connection Only"], 
    value: "",
  };

  changeActiveElement = (item) => {
    this.setState({ selectedOption: item });
  };

  render() {
    return (
      <div className={styles.container}>
        {
          this.props.actionType=="EDIT" || this.props.actionType=="ADD"
          ? <div className={styles.innerBox}>
              <div className={styles.uploadBox} style={{ position: "relative", cursor: "pointer" }}>
                <input onChange={(e) => this.props.fileInput(e)} type="file" accept="image/*" className={styles.fileUpload} />
                <img src="./Image/DashboardImages/postModImages/photo.svg" alt="Photos" className={styles.photo} />
              </div>
              <div className={styles.uploadBox} style={{ position: "relative" }}>
                <input onChange={this.props.videoInput} type="file" accept="video/mp4,video/x-m4v,video/*" className={styles.fileUpload} />
                <img src="./Image/DashboardImages/postModImages/video.svg" alt="Video" className={styles.photo} />
              </div>
              <div className={styles.uploadBox} style={{ position: "relative" }}>
                <input
                  onChange={this.props.mediaFileInput}
                  type="file"
                  accept=".doc,.docx,application/msword,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  className={styles.fileUpload}
                />
                <img src="./Image/DashboardImages/postModImages/filePost.svg" alt="Files" className={styles.photo} />
              </div>
            </div>
          : null
        }
          <div className={styles.rightInner}>
          {/* <div className={styles.dropDiv}>
            <DropBox
              drop
              light
              title={this.state.selectedOption}
              options={this.state.options}
              changeActiveElement={this.changeActiveElement}
            />
          </div> */}
          <div onClick={this.props.actionType=='EDIT' ? this.props.postEdit : this.props.actionType=='DELETE' ? this.props.postDelete : this.props.onPost} className={styles.post}>
          {this.props.actionType=='EDIT' ? this.props.t("common.edit") : this.props.actionType=='DELETE' ? this.props.t("common.delete") : this.props.t("common.add")}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(BottomContainer)

//filter: invert(53%) sepia(66%) saturate(962%) hue-rotate(195deg) brightness(99%) contrast(87%)

/* 



*/
