import React from "react";
import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { updateCoverPic } from "../../../../../redux/actions/user_profile";
import { UPDATE_COVER_PIC } from "../../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../../components/commons/HandleApiError";

class CoverFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coverPicUrl: this.props.coverPicUrl || "/Image/apps/profile-resume-edit-view/cover-image.png",
      file: null,
      error: false,
      message: "",
    };

    this.handleImageChange = this.handleImageChange.bind(this);
  }

  handleImageChange(event) {
    this.setState({
      coverPicUrl: URL.createObjectURL(event.target.files[0]),
    });
    if (event.target.files[0] != null)
      this.props.updateCoverPic(event.target.files[0], "UPDATE_COVER_PIC").then((res) => {
        if (res.val) {
          //
          return;
        }
        this.setState({ error: true, message: res.message });
      });
  }

  render() {
    return (
      <div
        style={{
          // backgroundColor: '#5555555A',
          height: 180,
          borderRadius: 8,
          display: "flex",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", width: "100%", height: 180, overflow: "hidden", borderRadius: 10 }}>
          <img src={this.props.coverPicUrl} className={styles.coverPicture} />
        </div>

        {this.props.editpage ? (
          <div
            style={{
              padding: 8,
              backgroundColor: "#fff",
              borderRadius: 30,
              cursor: "pointer",
              height: 15,
              width: 15,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 10,
              position: "absolute",
            }}
          >
            <label for="iconimage-cover">
              <div>
                <img src="/Image/apps/profile-resume-edit-view/camera.svg" alt=" " height="15px" width="15px" style={{ cursor: "pointer" }} />
              </div>
            </label>

            <input
              type="file"
              className={`${styles.inputfield_cover} ${styles.input_foc}`}
              id="iconimage-cover"
              onChange={this.handleImageChange}
              style={{ display: "none", cursor: "pointer" }}
              accept="/Image/png, /Image/jpeg"
            />
          </div>
        ) : null}

        <HandleApiError error={this.state.error} message={this.state.message} onClose={() => this.setState({ error: false })} />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    coverPicUrl: state.profile.userCoverPic || "/Image/apps/profile-resume-edit-view/cover-image.png",
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateCoverPic: (coverPic, action) => dispatch(updateCoverPic(coverPic, action)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverFeed);