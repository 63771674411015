import React from "react";
import Modal from "../../../../../components/commons/Modal";
import AddTags from "./Modals/AddTags";
import IconTitle from "../../../../../components/commons/IconTitle";

import styles from "../../../Profile.module.css";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Tags extends React.Component {
  state = {
    edit: false,
  };

  changeEdit(edit) {
    this.setState({ edit: edit });
  }

  render() {
    const {t} = this.props;
    return (
      <div
        id="Tags"
        style={{
          scrollMargin: "120px 0px 0px 0px",
        }}
      >
        <IconTitle title={t("profile.tags")} img="/Image/apps/profile-resume-edit-view/tag.svg" />
        <br />

        <div
          className={styles.desc}
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: 10,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: 5,
            }}
          >
            {/* <Stack direction="row" spacing={1}>
              {
                this.props.tagContent?.split(",").map(item=>{
                  return <Chip label={item} variant="outlined" />
                }) || <p>No tags</p>
              }
            </Stack> */}

            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              {this.props.tagContent?.split(",").map((item, idx) => {
                return (
                  <>
                  {item != "" ?
                  <li key={idx} className={styles.tagList}>
                    {item}
                  </li>
                  : 
                  null
                  }
                  </>
                );
              })}
            </ul>
          </div>
          <div
            style={{
              cursor: "pointer",
              borderTop: "1px solid rgba(85, 85, 85, 0.353)",
              marginTop: 10,
            }}
            onClick={() => {
              this.setState({ edit: true });
              document.body.style.overflow = "hidden";
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: 12,
                textTransform: "uppercase",
                fontWeight: 600,
                color: "#555555",
                margin: 23,
              }}
            >
              {t("profile.editTags")}
            </p>
          </div>
        </div>
        {this.state.edit ? <Modal card={<AddTags title={t("profile.addTags")} changeEdit={this.changeEdit.bind(this)} />} /> : null}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tagContent: state?.profile?.tags || "",
  };
}

export default withTranslation()(connect(mapStateToProps, null)(Tags));
