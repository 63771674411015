import { MenuItem } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import { React, Component } from "react";
import { connect } from "react-redux";
import { getUserSuggestions, handleErrorMessage, postMembers } from "../../../redux/actions/classes";
import { ModalWithHeader } from "../../Dashboard/shared/ui/Modal/Modal";
import AutosuggestionComponent from "./AutosuggestionComponent";
import styles from "./classcss/InvitePeopleModal.module.css";
import { handleSuccessMessage } from "../../../redux/actions/success";
import { withTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";

const MemberComponent = ({ user, removeMember }) => {
  return (
    <div className={styles.userBox}>
      <p style={{ paddingLeft: 20, marginBottom: 5, marginTop: 5 }}>
        {user.name}({user.id})
      </p>
      <button
        onClick={() => removeMember(user)}
        style={{
          position: "relative",
          cursor: "pointer",
          borderRadius: "100%",
          border: "none",
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <img src="/Image/DashboardImages/postModImages/cross.svg" alt="X" style={{ width: 15, height: 15 }} />
      </button>
    </div>
  );
};
const MemberSuggestionComponent = ({ memberSuggestions }) => {
  return (
    <div>
      {memberSuggestions.map((user, index) => {
        return (
          <MenuItem key={user.name} value={user.name}>
            {user.name}
          </MenuItem>
        );
      })}
    </div>
  );
};
class InvitePeopleModal extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: "groupCode",
      members: [],
      memberUsersSuggestions: [],
    };
  }

  openInviteCode = (evt) => {
    this.setState({ activeTab: "groupCode" });
  };
  openInviteUsers = (evt) => {
    this.setState({ activeTab: "groupInviteUsers" });
  };
  addMemberUsers = (user) => {
    const suggestions = this.state.memberUsersSuggestions.filter((userName) => userName.id !== user.id);
    this.setState({ memberUsersSuggestions: suggestions });
    this.setState({ members: [...this.state.members, user] });
  };
  removeMemberUsers = (user) => {
    const suggestions = this.state.members.filter((userName) => userName.id !== user.id);
    this.setState({ members: suggestions });
  };
  componentDidMount() {
    // console.log(this.props.isMemberInStore.length, "isMemberInStore")
    if (this.props.isMemberInStore.length == 0){
      this.props.getUserSuggestions().then(() => this.setState({ memberUsersSuggestions: this.props.memberSuggestions }));
    }
    else {
      this.setState({ memberUsersSuggestions: this.props.memberSuggestions })
    }
  }
  postMembers() {
    if(this.state.members.length != 0){
      const data = this.state.members.map((member) => {
        let memberData = {
          member_users: member.id,
          privilige: "",
        };
        if (this.props.routeType === "Class") {
          memberData = { ...memberData, class_members: this.props.class_id };
        } else {
          memberData = { ...memberData, group_members: this.props.class_id };
        }
        return memberData;
      });
      this.props.InvitePeopleModalCloser();
      this.props.postMembers(data).then(() => {
        this.setState({ members: [] });
        this.props.getActiveClassDetails(this.props.class_id);
      });
    }
   else{
     this.props.handleErrorMessage("Please enter the username of eligible member")
   }

  }
  render() {
    const { t } = this.props;
    const groupTabStyle = this.state.activeTab === "groupCode" ? { opacity: 1, borderBottom: "2px solid #ED1E24" } : { opacity: 0.5 };
    const usersTabStyle = this.state.activeTab !== "groupCode" ? { opacity: 1, borderBottom: "2px solid #ED1E24" } : { opacity: 0.5 };
    return (
      <ModalWithHeader
        title={"Invite People"}
        maxWidth={530}
        minWidth={350}
        closeHandler={this.props.InvitePeopleModalCloser}
        show={this.props.showInvitePeopleModal}
      >
        <div className={styles.tab}>
          <button
            className={styles.tablinks}
            onClick={(evt) => this.openInviteCode(evt)}
            style={groupTabStyle}
          >
            {t("class.shareClassCode")}
          </button>
          <button
            className={styles.tablinks}
            onClick={(evt) => this.openInviteUsers(evt)}
            style={usersTabStyle}
          >
            {t("class.inviteUsername")}
          </button>
        </div>
        <div className={styles.modalContainer}>
          {this.state.activeTab == "groupCode" ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "50%",
                }}
              >
                <div
                  style={{
                    marginTop: 45,
                    marginBottom: 40,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <h3 style={{ textTransform: "uppercase" }}>
                    {this.props.subjectCode}
                  </h3>
                  <CopyToClipboard
                    text={`${this.props.subjectCode}`}
                    onCopy={() => this.props.handleSuccessMessage(t("common.copied"))}
                  >
                    <FileCopy
                      style={{ marginLeft: 40, cursor: "pointer" }}
                    />
                  </CopyToClipboard>
                  {/* <FileCopy
                    onClick={() => {
                      window.navigator.clipboard.writeText(`${window.location.origin}/${this.props.routeType}/${this.props.subjectCode}`);
                    }}
                    style={{ marginLeft: 40, cursor: "pointer" }}
                  /> */}
                </div>
                <p style={{ textAlign: "center" }}>
                  {t("class.shareClassCodeDesc")}
                </p>
              </div>
              <button
                className={styles.groupinviteButton}
                onClick={() => this.props.InvitePeopleModalCloser()}
              >
                {t("common.done")}
              </button>
            </div>
          ) : (
            <div style={{ marginTop: 45, marginBottom: 40 }}>
              <p style={{ textAlign: "center" }}>
                {t("class.inviteUsernameDesc")}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginLeft: 20,
                  marginTop: 20,
                }}
              >
                {this.state.members.map((member) => (
                  <MemberComponent
                    user={member}
                    removeMember={this.removeMemberUsers}
                  />
                ))}
              </div>
              <AutosuggestionComponent
                memberSuggestions={this.state.memberUsersSuggestions}
                inputLabel={styles.inputLabel}
                addMemberUsers={this.addMemberUsers}
              />
              <button
                className={styles.groupinviteButton}
                onClick={() => {
                  this.postMembers();
                }}
              >
                {t("common.done")}
              </button>
              {/* <button className={styles.groupinviteButton} onClick={()=>this.props.InvitePeopleModalCloser()}>Done</button> */}
            </div>
          )}
        </div>
      </ModalWithHeader>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isMemberInStore : state?.users,
    memberSuggestions: state.users.filter((user) => !state.classes.members.find((member) => member.id === user.id)),
    //memberSuggestions:[{name:'Anmol',id:1},{id:2,name:'Himani'},{id:3,name:'Shivam'},{id:4,name:'Harsh'},{id:5,name:'Anshika'},{id:6,name:'Medha'}],
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserSuggestions: () => dispatch(getUserSuggestions()),
    postMembers: (members) => dispatch(postMembers(members)),
    handleSuccessMessage: (successMsg) => dispatch(handleSuccessMessage(successMsg)),
    handleErrorMessage: (error) => dispatch(handleErrorMessage(error)),
  };
};
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InvitePeopleModal));
